// @flow
import styles from './Group.module.css';

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import type { BuilderProps, ElementModel, OnUngroup } from 'components/Sheet2/types';
import { ELEMENT_TYPE, LIBRARY } from 'constants/sheet';

import IconButton from 'components/buttons/IconButton';
import { BuilderComponents } from 'components/Sheet2/elements/ElementComponents';
import { ReactComponent as CloneIcon } from 'images/icons/CloneIcon.svg';
import { ReactComponent as DragIcon } from 'images/icons/DragIcon.svg';
import { ReactComponent as DuplicateIcon } from 'images/icons/DuplicateIcon.svg';
import { ReactComponent as DuplicateNotIcon } from 'images/icons/DuplicateNotIcon.svg';
import { ReactComponent as GearIcon } from 'images/icons/GearIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';
import { ReactComponent as UngroupIcon } from 'images/icons/UngroupIcon.svg';
type Props = {
  ...BuilderProps,
  onUngroup: OnUngroup,
  element: {
    ...ElementModel,
    items: ElementModel[],
  },
};

export function GroupBuilder(props: Props) {
  const {
    colors,
    element: group,
    images,
    isDragging,
    onAddImage,
    onAdvancedPanelOpen,
    onChangeGroup,
    onClone,
    onRemove,
    onRemoveImage,
    onUngroup,
    provided,
    sectionId,
    setInnerRef,
    sheetGuid,
  } = props;
  const { items } = group;
  const [isDroppableDisabled, setIsDroppableDisabled] = useState(!!isDragging);
  const classNames = [styles.builderContainer, styles.gridContainer];

  if (isDragging) classNames.push(styles.noHover);
  useEffect(() => {
    if (!isDragging) setIsDroppableDisabled(false);
  }, [isDragging]);

  const onAddElementImage = (id, sectionId, elementId, groupElementId, image, callback) => {
    if (onAddImage) onAddImage(id, sectionId, group.id, elementId, image, callback);
  };

  const onAdvancedPanelClick = (options) => {
    onAdvancedPanelOpen({ sectionId, elementId: group.id });
  };

  const onAdvancedPanelElementClick = useCallback(
    (options) => {
      onAdvancedPanelOpen({ ...options, groupId: group.id });
    },
    [group.id, onAdvancedPanelOpen]
  );

  const onChangeElement = useCallback(
    (id, sectionId, element, callback) => {
      if (!onChangeGroup) return;
      onChangeGroup(id, sectionId, group.id, element, callback);
    },
    [group.id, onChangeGroup]
  );

  const onCloneElement = useCallback(
    (sectionId: string, elementId: string) => {
      onClone(sectionId, elementId, group.id);
    },
    [group.id, onClone]
  );

  const onRemoveElement = useCallback(
    (sectionId: string, elementId: string) => {
      onRemove(sectionId, elementId, group.id);
    },
    [group.id, onRemove]
  );

  const onRemoveElementImage = useCallback(
    (id, sectionId, elementId, groupElementId, imageId, callback) => {
      if (onRemoveImage) onRemoveImage(id, sectionId, group.id, elementId, imageId, callback);
    },
    [group.id, onRemoveImage]
  );

  return (
    <div
      {...provided.draggableProps}
      ref={(el) => setInnerRef(el, group.id, provided.innerRef)}
      className={classNames.join(' ')}
    >
      <header className={styles.headerArea}>
        <div
          {...provided.dragHandleProps}
          onMouseUp={() => setIsDroppableDisabled(false)}
          onMouseDown={() => setIsDroppableDisabled(true)}
          className={styles.dragHandle}
        >
          <DragIcon />
        </div>
        <div className={styles.elementType}>{LIBRARY[group.elementType].title}</div>
        <div className={styles.headerActions}>
          {group.metadata?.collection ? <DuplicateIcon /> : <DuplicateNotIcon />}
          <IconButton
            buttonSize={18}
            iconSize={18}
            activeBackground="none"
            background="none"
            className={styles.settingsButton}
            label="Advanced Options"
            onClick={onAdvancedPanelClick}
            children={<GearIcon />}
            showLabelOnHover
            showAsTooltip
          />
        </div>
      </header>
      <Droppable droppableId={`group${group.id}`} isDropDisabled={isDroppableDisabled}>
        {(droppableProvided) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} className={styles.elements}>
            {items.map((innerElement, index) => {
              const BuilderComponent = BuilderComponents[innerElement.elementType];
              if (!BuilderComponent) return null;
              const allowImageProps = innerElement.elementType === ELEMENT_TYPE.image;
              return (
                <Draggable key={innerElement.id} draggableId={innerElement.id} index={index}>
                  {(draggableProvided) => (
                    <BuilderComponent
                      colors={colors}
                      element={innerElement}
                      groupId={group.id}
                      images={allowImageProps ? images : null}
                      isDragging={isDragging}
                      key={innerElement.id}
                      onAdvancedPanelOpen={onAdvancedPanelElementClick}
                      onChange={onChangeElement}
                      onClone={onCloneElement}
                      onRemove={onRemoveElement}
                      onAddImage={allowImageProps ? onAddElementImage : null}
                      onRemoveImage={allowImageProps ? onRemoveElementImage : null}
                      provided={draggableProvided}
                      setInnerRef={setInnerRef}
                      sectionId={sectionId}
                      sheetGuid={sheetGuid}
                    />
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
      <footer className={styles.footerArea}>
        {items.length > 0 && (
          <button className={`button-reset ${styles.actionButton}`} onClick={() => onUngroup(sectionId, group.id)}>
            <UngroupIcon />
          </button>
        )}
        <button className={`button-reset ${styles.actionButton}`} onClick={() => onClone(sectionId, group.id)}>
          <CloneIcon />
        </button>
        <button
          className={`button-reset ${styles.actionButton} ${styles.deleteButton}`}
          onClick={() => onRemove(sectionId, group.id)}
        >
          <TrashIcon />
        </button>
      </footer>
    </div>
  );
}

const MemoizedGroupBuilder = memo(GroupBuilder);
MemoizedGroupBuilder.displayName = 'MemoizedGroupBuilder';

export default MemoizedGroupBuilder;
