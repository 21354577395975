import styles from './Divider.module.css';

import clsx from 'clsx';

function Divider({ className, style }: { className?: string; style?: any }) {
  return (
    <div className={clsx(styles.container, className)} style={style}>
      <hr />
    </div>
  );
}

export default Divider;
