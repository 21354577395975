import styles from './PlayPanel.module.css';

import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import { ChannelEvent } from 'models/Channel';
import { AssetAction, ErrorAction, RoomAction, RoomDocumentAction, TableAction } from 'store/actions';
import {
  AssetSelector,
  AudioPlayerSelector,
  ErrorSelector,
  GameSelector,
  ProductSelector,
  RoomUserSelector,
  SessionSelector,
  TableSelector,
  TwilioSelector,
} from 'store/selectors';

import { MusicalNoteIcon } from '@heroicons/react/24/solid';
import AssetLibraryModal from 'components/AssetLibraryModal';
import AssetTray from 'components/AssetTray';
import IconButton from 'components/buttons/IconButton';
import EditMapAssetModal from 'components/EditMapAssetModal';
import EditTokenAssetModal from 'components/EditTokenAssetModal';
import UserMenu2 from 'components/layouts/UserMenu2';
import ProductList from 'components/ProductList';
import RenameAssetModal from 'components/RenameAssetModal';
import DiceRoller from 'components/TableBar/DiceRoller';
import TableChat from 'components/TableChat';
import UploadAssetModal from 'components/UploadAssetModal';
import { ReactComponent as DiceIcon } from 'images/dice/D20.svg';
import { ReactComponent as BooksIcon } from 'images/icons/BooksIcon.svg';
import { ReactComponent as ChatIcon } from 'images/icons/ChatIcon.svg';
import { ReactComponent as HidePanelIcon } from 'images/icons/HidePanelIcon.svg';
import { ReactComponent as ImageIcon } from 'images/icons/ImageIcon.svg';
import { ReactComponent as MapIcon } from 'images/icons/MapIcon.svg';
import { ReactComponent as ObrIcon } from 'images/icons/ObrIcon.svg';
import { ReactComponent as TagIcon } from 'images/icons/PriceTagIcon.svg';
import { ReactComponent as TokenIcon } from 'images/icons/TokenIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import ObrRoomInUseModal from 'modals/ObrRoomInUseModal';
import PatronFeatureModal from 'modals/PatronFeatureModal';
import { Controls } from 'room/AudioPlayer/AudioPlayer';
import AudioPlayer from 'room/AudioPlayer/AudioPlayer';

interface Props {
  room?: any;
  theme?: any;
}

const PANEL_TABS = ['Dice', 'Assets', 'Images', 'Maps', 'Tokens', 'Audio', 'Chat', 'Store'];
const MOBILE_PANEL_TABS = ['Dice', 'Assets', 'Chat', 'Store'];
const OBR_ORIGIN = process.env.REACT_APP_OBR_ORIGIN;
const OBR_CLIENT_ID = process.env.REACT_APP_OBR_CLIENT_ID;

const PlayPanel: FunctionComponent<Props> = ({ room, theme }) => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);

  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const previewGame = useSelector((state) => GameSelector.getBySlug(state, previewRoom));
  const gameId = useMemo(() => room?.gameId ?? previewGame?.id, [previewGame?.id, room?.gameId]);
  const products = useSelector((state) => (gameId ? ProductSelector.getLockedByGame(state, gameId) : []));

  const [panelTabs, setPanelTabs] = useState(PANEL_TABS);
  const [currentTab, setCurrentTab] = useState(null);
  const currentUser = useSelector(SessionSelector.currentUser);
  const isShowingPanel = useSelector(TableSelector.isPlayPanelOpen);
  const currentTabContent = useSelector(TableSelector.getPlayPanelContent);
  const roomGuid = room?.guid;
  const unreadTotal = useSelector((state) => (roomGuid ? TwilioSelector.getUnreadTotal(state, roomGuid) : null));
  const isCurrentUserMember = useSelector((state) =>
    roomGuid ? !!RoomUserSelector.get(state, roomGuid, currentUser.id) : false
  );
  const isHost = currentUser?.id === room?.userId;
  const twilioToken = useSelector((state) => TwilioSelector.getToken(state));
  const currentAudioAsset = useSelector(AudioPlayerSelector.getActiveAudioAsset);

  const showChatTab = isCurrentUserMember && twilioToken && !previewRoom;
  const showAudioTab = isCurrentUserMember && isHost && twilioToken && !previewRoom;
  const showStoreTab = !!gameId && products.length > 0;

  const assetToRename = useSelector(AssetSelector.getAssetToRename);
  const mapAssetToEdit = useSelector(AssetSelector.getAssetMapToEdit);
  const tokenAssetToEdit = useSelector(AssetSelector.getAssetTokenToEdit);
  const showAssetUploadModal = useSelector(AssetSelector.showUploadModal);
  const showLibraryModal = useSelector(AssetSelector.showLibraryModal);

  const isPatron = currentUser?.isPatron;
  const hasObrConnected = !!room?.obrUrl;
  const isObrActive = useSelector(TableSelector.isObrActive);
  const obrError = useSelector((state) => ErrorSelector.get(state, RoomAction.UPDATE))?.message?.includes(
    'Obr url already attached'
  );
  const [showPatronModal, setShowPatronModal] = useState('');

  const onAssetLibraryModalDismiss = () => {
    batch(() => {
      dispatch(AssetAction.showMenu(null));
      dispatch(AssetAction.showLibrary(false));
    });
  };

  const tabButtons = useMemo(() => {
    if (size.isMd)
      return [
        {
          name: 'Dice',
          label: 'Dice Roller',
          children: <DiceIcon />,
        },
        {
          name: 'Assets',
          label: 'Books & Apps',
          children: <BooksIcon style={{ marginRight: '-2px' }} />,
        },
        {
          name: 'Images',
          label: 'Images',
          children: <ImageIcon style={{ marginRight: '-1px' }} />,
        },
        {
          name: 'Maps',
          label: 'Maps',
          children: <MapIcon style={{ marginRight: '-1px' }} />,
        },
        {
          name: 'Tokens',
          label: 'Tokens',
          children: <TokenIcon />,
        },
        {
          name: 'Audio',
          label: 'Audio Player',
          children: <MusicalNoteIcon />,
        },
        {
          name: 'Chat',
          label: 'Chat',
          children: (
            <>
              {unreadTotal > 0 && <div className={styles.unreadIndicator} />}
              <ChatIcon style={{ marginLeft: '2px', marginRight: '1px', marginTop: '3px' }} />
            </>
          ),
        },
        {
          name: 'Store',
          label: 'Store',
          children: <TagIcon style={{ marginLeft: '2px', marginTop: '1px' }} />,
        },
      ].filter(Boolean);
    else
      return [
        {
          name: 'Dice',
          label: 'Dice Roller',
          children: <DiceIcon />,
        },
        {
          name: 'Assets',
          label: 'Books & Apps',
          children: <BooksIcon style={{ marginRight: '-2px' }} />,
        },
        {
          name: 'Chat',
          label: 'Chat',
          children: (
            <>
              {unreadTotal > 0 && <div className={styles.unreadIndicator} />}
              <ChatIcon style={{ marginLeft: '2px', marginRight: '1px', marginTop: '3px' }} />
            </>
          ),
        },
        {
          name: 'Store',
          label: 'Store',
          children: <TagIcon style={{ marginLeft: '2px', marginTop: '1px' }} />,
        },
      ].filter(Boolean);
  }, [size.isMd, unreadTotal]);

  const onClickTab = useCallback(
    (i) => {
      setCurrentTab(i);
      dispatch(TableAction.setPlayPanelContent(panelTabs[i] === currentTabContent ? null : panelTabs[i]));
    },
    [currentTabContent, dispatch, panelTabs]
  );

  useEffect(() => {
    // React to outside tab changes
    const contentTab = panelTabs.indexOf(currentTabContent);
    if (currentTab !== contentTab) setCurrentTab(contentTab);
  }, [currentTab, currentTabContent, panelTabs]);

  useEffect(() => {
    setPanelTabs(size.isMd ? PANEL_TABS : MOBILE_PANEL_TABS);
  }, [dispatch, size.isMd]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === OBR_ORIGIN) {
        const { data } = event;
        if (data.id === 'OBR_ROOM_SELECTED' && typeof data.href === 'string') {
          dispatch(
            RoomAction.update(
              roomGuid,
              {
                obr_url: data.href,
              },
              () => {
                dispatch(TableAction.setObrActive(true));
                presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: true });
              }
            )
          );
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch, presenceChannel, roomGuid]);

  const onClickObr = (event) => {
    event.stopPropagation();

    if (!hasObrConnected && !isPatron) {
      setShowPatronModal('obr');
      return;
    }

    const isHost = room?.userId === currentUser?.id;

    if (!hasObrConnected) {
      if (!isHost) {
        alert('Only the host can connect Owlbear Rodeo to the room.');
        return;
      }

      const url = new URL(OBR_ORIGIN);
      url.pathname = '/embed/access';
      url.searchParams.append('client_id', OBR_CLIENT_ID);
      const width = 450;
      const height = 650;
      const left = window.screenX + window.outerWidth / 2 - width / 2;
      const top = window.screenY + window.outerHeight / 2 - height / 2;
      window.open(
        url.href,
        '_blank',
        `width=${width},height=${height},left=${left},top=${top},menubar=0,toolbar=0,location=0,personalbar=0,status=0`
      );
      return;
    }

    if (isObrActive) {
      dispatch(TableAction.setObrActive(false));
      if (isHost) presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: false });
    } else {
      dispatch(TableAction.setObrActive(true));
      if (isHost) presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: true });
    }
  };

  return (
    <div className={styles.panel}>
      {!size.isMd && (
        <Tab.Group vertical selectedIndex={currentTab}>
          <Tab.List className={clsx(styles.buttons, !currentUser && styles.preview)}>
            {tabButtons.map((tab, i) => {
              // beware the sins of our ancestors
              return (
                <Tab as={Fragment} key={`${tab.name}-button`}>
                  {({ selected }) => (
                    <IconButton
                      onClick={() => onClickTab(i)}
                      children={tab.children}
                      buttonSize={42}
                      iconSize={22}
                      label={tab.label}
                      borderRadius={!selected || !isShowingPanel ? 40 : 14}
                      background="rgb(var(--color-theme-button))"
                      activeBackground="rgb(var(--color-theme-button))"
                      activeHover="rgb(var(--color-theme-accent))"
                      activeLabel="Close Panel"
                      activeColor="inherit"
                      activeChildren={
                        <HidePanelIcon
                          style={{ transform: 'rotate(180deg)', marginRight: '-3px', height: '17px', width: '17px' }}
                        />
                      }
                      hoverBackground="rgb(var(--color-theme-accent))"
                      color={
                        selected && isShowingPanel ? 'rgb(var(--color-theme-accent)))' : 'rgb(var(--color-theme-text))'
                      }
                      isActive={selected && isShowingPanel}
                      className={clsx(
                        ((tab.name === 'Chat' && !showChatTab) || (tab.name === 'Store' && !showStoreTab)) &&
                          styles.hidden,
                        selected && isShowingPanel && styles.isActiveTab
                      )}
                    />
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels as={Fragment}>
            <Tab.Panel className={styles.content} unmount={false}>
              <DiceRoller room={room} />
            </Tab.Panel>

            <Tab.Panel className={styles.content} unmount={false}>
              <div className={styles.assetsMobile}>
                <AssetTray room={room} className={styles.assetTrayMobile} theme={theme} />
                {currentAudioAsset && <Controls className={styles.audioControls} />}
              </div>
            </Tab.Panel>

            <Tab.Panel className={clsx(styles.content, !showChatTab && styles.hidden)} unmount={false}>
              {showChatTab && <TableChat room={room} className={styles.chat} />}
            </Tab.Panel>

            <Tab.Panel className={clsx(styles.content, !showStoreTab && styles.hidden)} unmount={false}>
              {showStoreTab && (
                <div className={styles.storeContainer}>
                  <h3 className={styles.title}>Store</h3>
                  <ProductList
                    isPlayPanel
                    isSmall
                    products={products}
                    roomGuid={roomGuid}
                    className={styles.storePanel}
                  />
                </div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}

      {size.isMd && (
        <Tab.Group vertical selectedIndex={currentTab}>
          <Tab.List className={clsx(styles.buttons, !currentUser && styles.preview)}>
            <UserMenu2 theme={room?.theme} className={styles.userMenu} />

            {tabButtons.map((tab, i) => {
              if (tab.name === 'Maps') {
                return (
                  <Tab as={Fragment} key={`${tab.name}-button`}>
                    {({ selected }) => (
                      <div className={clsx(styles.comboButtons, isObrActive && styles.isObrActive)}>
                        <IconButton
                          onClick={() => onClickTab(i)}
                          children={tab.children}
                          buttonSize={42}
                          iconSize={22}
                          label={tab.label}
                          borderRadius={!selected || !isShowingPanel ? 40 : 14}
                          background="rgb(var(--color-theme-button))"
                          activeBackground="rgb(var(--color-theme-button))"
                          activeHover="rgb(var(--color-theme-accent))"
                          activeLabel="Close Panel"
                          activeColor="rgb(var(--color-theme-accent))"
                          activeChildren={
                            <HidePanelIcon
                              style={{
                                transform: 'rotate(180deg)',
                                marginRight: '-3px',
                                height: '17px',
                                width: '17px',
                              }}
                            />
                          }
                          hoverBackground="rgb(var(--color-theme-accent))"
                          color={
                            selected && isShowingPanel
                              ? 'rgb(var(--color-theme-accent)))'
                              : 'rgb(var(--color-theme-text))'
                          }
                          isActive={selected && isShowingPanel}
                          className={clsx(
                            ((tab.name === 'Chat' && !showChatTab) ||
                              (tab.name === 'Audio' && !showAudioTab) ||
                              (tab.name === 'Store' && !showStoreTab)) &&
                              styles.hidden,
                            selected && isShowingPanel && styles.isActiveTab
                          )}
                        />
                        <IconButton
                          onClick={onClickObr}
                          children={<ObrIcon className={clsx(styles.obrIcon)} />}
                          buttonSize={42}
                          iconSize={26}
                          label="Owlbear Rodeo"
                          borderRadius={!isObrActive ? 40 : 14}
                          background="rgb(var(--color-theme-button))"
                          hoverBackground="var(--color-orange)"
                          activeBackground="var(--color-orange)"
                          activeHover="rgb(var(--color-theme-accent))"
                          activeLabel="Close Owlbear Rodeo"
                          activeColor="var(--color-white)"
                          activeChildren={
                            <span className={styles.obrActiveIcons}>
                              <ObrIcon className={clsx(styles.obrIcon, styles.isActiveIcon)} />
                              <XIcon
                                className={styles.isActiveHoverIcon}
                                style={{
                                  height: '14px',
                                  width: '14px',
                                }}
                              />
                            </span>
                          }
                          color={isObrActive ? 'rgb(var(--color-theme-accent)))' : 'rgb(var(--color-theme-text))'}
                          isActive={isObrActive}
                          className={clsx(styles.obrButton, isObrActive && styles.isActiveTab)}
                        />
                      </div>
                    )}
                  </Tab>
                );
              }

              // beware the sins of our ancestors
              return (
                <Tab as={Fragment} key={`${tab.name}-button`}>
                  {({ selected }) => (
                    <IconButton
                      onClick={() => {
                        if (tab.name === 'Audio' && !isPatron) setShowPatronModal('audio');
                        else onClickTab(i);
                      }}
                      children={tab.children}
                      buttonSize={42}
                      iconSize={22}
                      label={tab.label}
                      borderRadius={!selected || !isShowingPanel ? 40 : 14}
                      background="rgb(var(--color-theme-button))"
                      activeBackground="rgb(var(--color-theme-button))"
                      activeHover="rgb(var(--color-theme-accent))"
                      activeLabel="Close Panel"
                      activeColor="rgb(var(--color-theme-accent))"
                      activeChildren={
                        <HidePanelIcon
                          style={{ transform: 'rotate(180deg)', marginRight: '-3px', height: '17px', width: '17px' }}
                        />
                      }
                      hoverBackground="rgb(var(--color-theme-accent))"
                      color={
                        selected && isShowingPanel ? 'rgb(var(--color-theme-accent)))' : 'rgb(var(--color-theme-text))'
                      }
                      isActive={selected && isShowingPanel}
                      className={clsx(
                        ((tab.name === 'Chat' && !showChatTab) ||
                          (tab.name === 'Audio' && !showAudioTab) ||
                          (tab.name === 'Store' && !showStoreTab)) &&
                          styles.hidden,
                        selected && isShowingPanel && styles.isActiveTab
                      )}
                    />
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels as={Fragment}>
            <Tab.Panel className={styles.content} unmount={false}>
              <DiceRoller room={room} />
            </Tab.Panel>

            {PANEL_TABS.slice(1, 5).map((tab, i) => (
              <Tab.Panel className={styles.content} unmount={false} key={tab}>
                <AssetTray room={room} category={tab.toLowerCase()} theme={theme} />
              </Tab.Panel>
            ))}

            <Tab.Panel className={clsx(styles.content)} unmount={false}>
              {showAudioTab && <AudioPlayer room={room} />}
            </Tab.Panel>

            <Tab.Panel className={clsx(styles.content, !showChatTab && styles.hidden)} unmount={false}>
              {showChatTab && <TableChat room={room} className={styles.chat} />}
            </Tab.Panel>

            <Tab.Panel className={clsx(styles.content, !showStoreTab && styles.hidden)} unmount={false}>
              {showStoreTab && (
                <div className={styles.storeContainer}>
                  <h3 className={styles.title}>Store</h3>
                  <ProductList isPlayPanel products={products} roomGuid={roomGuid} className={styles.storePanel} />
                </div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}

      {room && showAssetUploadModal && !showLibraryModal && (
        <UploadAssetModal
          room={room}
          onComplete={({ id }) => dispatch(RoomDocumentAction.create(roomGuid, id))}
          onDismiss={() => dispatch(AssetAction.showUpload(false))}
        />
      )}
      {room && showLibraryModal && <AssetLibraryModal roomGuid={room.guid} onDismiss={onAssetLibraryModalDismiss} />}
      {mapAssetToEdit && (
        <EditMapAssetModal asset={mapAssetToEdit} onDismiss={() => dispatch(AssetAction.editMap(null))} />
      )}
      {tokenAssetToEdit && (
        <EditTokenAssetModal asset={tokenAssetToEdit} onDismiss={() => dispatch(AssetAction.editToken(null))} />
      )}
      {assetToRename && <RenameAssetModal asset={assetToRename} />}
      {obrError && (
        <ObrRoomInUseModal
          onDismiss={() => {
            dispatch(ErrorAction.remove(RoomAction.UPDATE));
          }}
        />
      )}
      {showPatronModal && showPatronModal === 'obr' && (
        <PatronFeatureModal
          title="Owlbear Rodeo is for Patrons"
          including="Owlbear Rodeo integration"
          onDismiss={() => setShowPatronModal('')}
        />
      )}
      {showPatronModal && showPatronModal === 'audio' && (
        <PatronFeatureModal
          title="Audio Player is for Patrons"
          including="room audio player"
          onDismiss={() => setShowPatronModal('')}
        />
      )}
    </div>
  );
};

export default PlayPanel;
