import _ from 'lodash';
import { createSelector } from 'reselect';

import { EDITOR_OPTIONS, PERMISSIONS, VIEWER_OPTIONS } from 'constants/room_sheet';

import RoomSelector from './RoomSelector';
import SessionSelector from './SessionSelector';

const orderByName = (obj) => obj.name.toLowerCase();

export default class RoomSheetSelector {
  static get = (state, guid, sheetId) => state.roomSheets[`${guid}-${sheetId}`];

  static getByRoom = createSelector([(state) => state.roomSheets, (_, guid) => guid], (roomSheets, guid) => {
    const filtered = Object.values(roomSheets).filter((o) => o.guid === guid);
    return _.orderBy(filtered, ['isPrimary', orderByName], ['desc', 'asc']);
  });

  static getByRoomAndUser = createSelector(
    [(state) => state.roomSheets, (_, guid) => guid, (_, __, userId) => userId],
    (roomSheets, guid, userId) => {
      const filtered = Object.values(roomSheets).filter((o) => o.guid === guid && o.userId === userId);
      return _.orderBy(filtered, ['isPrimary', orderByName], ['desc', 'asc']);
    }
  );

  static getEditableByRoomAndUser = createSelector(
    [(state) => state.roomSheets, (_, guid) => guid, (_, __, userId) => userId],
    (roomSheets, guid, userId) => {
      const filtered = Object.values(roomSheets).filter((o) => {
        const isViewer =
          o.permissions.viewers?.includes(PERMISSIONS.everyone) || o.permissions.viewers?.includes(userId);
        return (
          o.guid === guid &&
          o.userId !== userId &&
          isViewer &&
          (o.permissions.editors?.includes(PERMISSIONS.everyone) || o.permissions.editors?.includes(userId))
        );
      });
      return _.orderBy(filtered, ['isPrimary', orderByName], ['desc', 'asc']);
    }
  );

  static getViewableByRoomAndUser = createSelector(
    [(state) => state.roomSheets, (_, guid) => guid, (_, __, userId) => userId],
    (roomSheets, guid, userId) => {
      const filtered = Object.values(roomSheets).filter((o) => {
        const isEditor =
          o.permissions.editors?.includes(PERMISSIONS.everyone) || o.permissions.editors?.includes(userId);
        return (
          o.guid === guid &&
          o.userId !== userId &&
          !isEditor &&
          (o.permissions.viewers?.includes(PERMISSIONS.everyone) || o.permissions.viewers?.includes(userId))
        );
      });
      return _.orderBy(filtered, ['isPrimary', orderByName], ['desc', 'asc']);
    }
  );

  static getForTile = createSelector(
    [this.getByRoomAndUser, SessionSelector.currentUser],
    (roomSheets, currentUser) => {
      const filtered = roomSheets.filter((o) => {
        const isEditor =
          o.permissions.editors?.includes(PERMISSIONS.everyone) || o.permissions.editors?.includes(currentUser.id);
        const isViewer =
          o.permissions.viewers?.includes(PERMISSIONS.everyone) || o.permissions.viewers?.includes(currentUser.id);
        return isEditor || isViewer;
      });
      return filtered;
    }
  );

  static getEditors = createSelector([RoomSelector.get, this.get], (room, roomSheet) => {
    if (!room || !roomSheet) return EDITOR_OPTIONS.find((o) => o.value === '');
    else if (roomSheet.permissions.editors?.[0] === PERMISSIONS.everyone)
      return EDITOR_OPTIONS.find((o) => o.value === PERMISSIONS.everyone);
    else if (roomSheet.permissions.editors?.[0] === room.userId)
      return EDITOR_OPTIONS.find((o) => o.value === PERMISSIONS.host);
    else return EDITOR_OPTIONS.find((o) => o.value === '');
  });

  static getViewers = createSelector([RoomSelector.get, this.get], (room, roomSheet) => {
    if (!room || !roomSheet) return VIEWER_OPTIONS.find((o) => o.value === '');
    else if (roomSheet.permissions.viewers?.[0] === PERMISSIONS.everyone)
      return VIEWER_OPTIONS.find((o) => o.value === PERMISSIONS.everyone);
    else if (roomSheet.permissions.viewers?.[0] === room.userId)
      return VIEWER_OPTIONS.find((o) => o.value === PERMISSIONS.host);
    else return VIEWER_OPTIONS.find((o) => o.value === '');
  });

  static canEdit = createSelector([this.get, (_, __, ___, userId) => userId], (roomSheet, userId) => {
    if (!roomSheet) return false;
    return (
      roomSheet.userId === userId ||
      roomSheet.permissions.editors?.includes(PERMISSIONS.everyone) ||
      roomSheet.permissions.editors?.includes(userId)
    );
  });

  static canView = createSelector([this.get, (_, __, userId) => userId], (roomSheet, userId) => {
    if (!roomSheet) return false;
    return (
      roomSheet.userId === userId ||
      roomSheet.permissions.viewers?.includes(PERMISSIONS.everyone) ||
      roomSheet.permissions.viewers?.includes(userId)
    );
  });
}
