import styles from './TableUser.module.css';

import { Portal } from '@headlessui/react';
import clsx from 'clsx';
import { first } from 'lodash';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Participant } from 'twilio-video';

import useWindowSize from 'hooks/useWindowSize';
import { RoomUserAction, TableAction } from 'store/actions';
import { RoomUserSelector, SessionSelector, SheetSelector, TableSelector } from 'store/selectors';
import type { RoomModel, RoomUserModel } from 'types/common';
import { RoomTab, TableViewMode } from 'types/room';
import { linearGradient } from 'utilities/color';
import useMainParticipant from 'video/hooks/useMainParticipant';
import useParticipantNetworkQualityLevel from 'video/hooks/useParticipantNetworkQualityLevel';
import usePublications from 'video/hooks/usePublications';
import useScreenShareParticipant from 'video/hooks/useScreenShareParticipant';

import NetworkQuality from './NetworkQuality';
import TableUserDice from './TableUserDice';
import Avatar from 'components/Avatar';
import IconButton from 'components/buttons/IconButton';
import DefaultPlayerAvatar from 'images/DefaultPlayerAvatar.jpg';
import { ReactComponent as PinIcon } from 'images/icons/PinIcon.svg';
import { ReactComponent as UnpinIcon } from 'images/icons/PinNotIcon.svg';
import { ReactComponent as SoundOffIcon } from 'images/icons/SoundOffIcon.svg';
import { ReactComponent as VideoOffIcon } from 'images/icons/VideoOffIcon.svg';
import Publication from 'video/Publication/Publication';
import { isScreenShareTrack } from 'video/utils';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

interface TableUserProps {
  className?: string;
  isSelf?: boolean;
  participant?: Participant;
  room: RoomModel;
  tileWidth: number;
  totalTiles: number;
  user: RoomUserModel;
}

export default function TableUser({
  className,
  isSelf = false,
  participant,
  room,
  tileWidth,
  totalTiles,
  user,
}: TableUserProps) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector(SessionSelector.currentUser);
  // @ts-ignore RoomUserSelector is not typed yet
  const status = useSelector((state) => RoomUserSelector.getStatus(state, user.guid, user.userId));
  const isFocusMode = useSelector(TableSelector.isFocusMode);

  const [menuReferenceElement, setMenuReferenceElement] = useState<HTMLDivElement | null>(null);
  const [menuPopperElement, setMenuPopperElement] = useState<HTMLUListElement | null>(null);

  const isShowingSheet = useSelector(TableSelector.isShowingSheet);
  const isShowingSheetTOC = useSelector(TableSelector.isShowingSheetTOC);

  const { styles: menuPopperStyles, attributes: menuAttributes } = usePopper(menuReferenceElement, menuPopperElement, {
    placement: 'top-start',
  });

  const [containerReferenceElement, setContainerReferenceElement] = useState<HTMLDivElement | null>(null);
  const [diceRollPopperElement, setDiceRollPopperElement] = useState<HTMLDivElement | null>(null);

  const {
    styles: diceRollPopperStyles,
    attributes: diceRollAttributes,
    update,
  } = usePopper(containerReferenceElement, diceRollPopperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const isSelectedParticipant = selectedParticipant === participant;
  const isMainParticipant = mainParticipant === participant;

  // @ts-ignore RoomUserSelector is not typed yet
  const colors = useSelector((state) => RoomUserSelector.getColors(state, room.guid, user.userId));
  const viewMode: TableViewMode = useSelector(TableSelector.getViewMode);
  const isObrActive = useSelector(TableSelector.isObrActive);
  const sheet: any = useSelector((state) =>
    isSelf
      ? // @ts-ignore SheetSelector is not typed yet
        first(SheetSelector.getByRoomAndUser(state, room.guid, currentUser.id))
      : // @ts-ignore SheetSelector is not typed yet
        SheetSelector.getForTile(state, room.guid, user.userId)
  );
  const userMenuId = useSelector(TableSelector.getUserMenuId);

  const publications = usePublications(participant);
  const videoPublication = publications.find((pub) => pub.kind === 'video' && !isScreenShareTrack(pub));
  const audioPublications = publications.filter((pub) => pub.kind === 'audio');
  const mainAudioPublication = audioPublications.find((pub) => !pub.trackName.startsWith('shared'));
  const networkQuality = useParticipantNetworkQualityLevel(participant);

  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const forceSpeakerMode = viewMode === TableViewMode.Speaker || !!activeRoomDocument || isObrActive;
  const isFullscreen =
    forceSpeakerMode &&
    isMainParticipant &&
    (isSelectedParticipant || (!screenShareParticipant && !activeRoomDocument && !isObrActive));

  // Subtract 16 to compensate for grid gap
  const isSmall =
    !isFullscreen &&
    (tileWidth - 16 <= 320 ||
      (forceSpeakerMode && !isMainParticipant) ||
      !!activeRoomDocument ||
      isObrActive ||
      (screenShareParticipant && !isSelectedParticipant && viewMode === TableViewMode.Speaker));
  const avatarSize = isSmall ? 36 : 48;
  const isHost = room.userId === user.userId;
  const isVideoEnabled = videoPublication?.isTrackEnabled;
  const isAudioEnabled = mainAudioPublication?.isTrackEnabled;
  const hasSheet = !!sheet;

  useEffect(() => {
    let i = 0;
    while (i < 400) {
      setTimeout(() => update?.(), i);
      i += 50;
    }
  }, [
    isShowingSheetTOC,
    isShowingSheet,
    isFocusMode,
    mainParticipant,
    isSelectedParticipant,
    activeRoomDocument,
    totalTiles,
    update,
  ]);

  useEffect(() => {
    dispatch(RoomUserAction.updateStatus(user.guid, user.userId, { isVideoEnabled, isAudioEnabled }));
  }, [dispatch, isAudioEnabled, isVideoEnabled, user.guid, user.userId]);

  const onNameClick = useCallback(() => {
    if (!isSelf) return;
    const id = userMenuId && userMenuId === user.userId ? null : user.userId;
    dispatch(TableAction.showUserMenu(id));
  }, [dispatch, isSelf, user.userId, userMenuId]);

  const onChangeNameClick = useCallback(() => {
    dispatch(TableAction.showUserSettings(true));
    onNameClick();
  }, [dispatch, onNameClick]);

  const onClickUnpin = useCallback(() => {
    setSelectedParticipant(null);
  }, [setSelectedParticipant]);

  const onClickMaximize = useCallback(() => {
    // Maximizing a user should force us to speaker mode
    dispatch(TableAction.updateViewMode(TableViewMode.Speaker));
    dispatch(TableAction.setActiveRoomDocument(null));
    setSelectedParticipant(participant);
  }, [dispatch, participant, setSelectedParticipant]);

  const onSheetClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      batch(() => {
        dispatch(TableAction.setActiveSheetUser(sheet?.userId));
        dispatch(TableAction.setActiveSheet(sheet?.id));
        dispatch(TableAction.showSheet(true));
        if (!size.isMd) dispatch(TableAction.setCurrentTab(RoomTab.Sheets));
      });
    },
    [dispatch, sheet?.id, sheet?.userId, size.isMd]
  );

  return (
    <div
      className={clsx(
        className,
        styles.tile,
        hasSheet && styles.hasSheet,
        isSmall && styles.isSmall,
        isFullscreen && styles.mainParticipant,
        isSelf && styles.isSelf,
        isFullscreen && isSelectedParticipant && styles.isPinned,
        !size.isMd && styles.isSmallScreen,
        isFocusMode && !status?.isVideoEnabled && !status?.isAudioEnabled && styles.isHidden
      )}
      style={{
        // @ts-ignore
        '--tableuser-avatar-size': `${avatarSize}px`,
        '--border-from': colors[0],
        '--border-to': colors[1],
        order: isHost ? -1 : undefined,
      }}
      ref={setContainerReferenceElement}
    >
      {!videoPublication?.trackSid && (
        <div className={styles.avatar}>
          <img src={user.avatarUrl || DefaultPlayerAvatar} alt={`${user.username}'s' Avatar`} />
        </div>
      )}
      <Publication key={videoPublication?.trackSid} publication={videoPublication} isLocalParticipant={isSelf} />
      {audioPublications.map((publication) => (
        <Publication key={publication?.trackSid} publication={publication} isLocalParticipant={isSelf} />
      ))}

      {(!forceSpeakerMode || isFullscreen) && (
        <TableUserDice className={styles.userDice} colors={colors} user={user} room={room} />
      )}
      {forceSpeakerMode && !isFullscreen && size.isMd && (
        <Portal>
          <div
            className={clsx(styles.userDicePopperContainer)}
            ref={setDiceRollPopperElement}
            style={diceRollPopperStyles.popper}
            {...diceRollAttributes.popper}
          >
            <TableUserDice
              className={clsx(
                styles.userDice,
                styles.speakerMode,
                isFocusMode && !status?.isVideoEnabled && !status?.isAudioEnabled && styles.isHidden
              )}
              colors={colors}
              user={user}
              room={room}
            />
          </div>
        </Portal>
      )}

      {hasSheet && isSmall && (
        <div onClick={onSheetClick} className={styles.sheetAvatar}>
          <Avatar
            borderColors={colors}
            borderSize={2}
            avatarUrl={sheet.avatarUrl}
            shape="squircle"
            size={avatarSize}
            showSheetDefault
            isDdbSheet={!!sheet.ddbId}
          />
        </div>
      )}

      {isHost && isSmall && (
        <div className={styles.hostLabel} style={{ background: linearGradient(colors) }}>
          <div>Host</div>
        </div>
      )}

      <div ref={setMenuReferenceElement} className={clsx(styles.metadata)}>
        {(hasSheet || isHost) && !isSmall && (
          <div className={styles.metadataSheet}>
            {hasSheet && !isSmall && (
              <div onClick={onSheetClick} className={styles.sheetAvatar}>
                <Avatar
                  borderColors={colors}
                  borderSize={2}
                  avatarUrl={sheet.avatarUrl}
                  shape="squircle"
                  size={avatarSize}
                  showSheetDefault
                  isDdbSheet={!!sheet.ddbId}
                />
              </div>
            )}
            {isHost && !isSmall && (
              <div className={styles.hostLabel} style={{ background: linearGradient(colors) }}>
                <div>Host</div>
              </div>
            )}
          </div>
        )}
        <div onClick={onNameClick} title={user.displayName} className={clsx('button-reset', styles.nameButton)}>
          {user.displayName}
        </div>
        {room.isHostPatron && (!isVideoEnabled || !isAudioEnabled) && (
          <div className={styles.devices}>
            {!isVideoEnabled && <VideoOffIcon title="Camera Off" className={styles.deviceIcon} />}
            {!isAudioEnabled && <SoundOffIcon title="Mic Off" className={styles.deviceIcon} />}
          </div>
        )}
        {networkQuality && networkQuality > 0 && networkQuality <= 2 && <NetworkQuality quality={networkQuality} />}
        {isSelectedParticipant && (
          <IconButton
            className={styles.unpinButton}
            buttonSize={32}
            iconSize={18}
            borderRadius={40}
            color="var(--color-white)"
            background="var(--color-teal)"
            hoverBackground="var(--color-alert-red)"
            children={
              <>
                <PinIcon className={styles.pinIcon} />
                <UnpinIcon className={styles.unpinIcon} />
              </>
            }
            onClick={onClickUnpin}
            label={`Unpin ${user.displayName}`}
          />
        )}
      </div>

      {forceSpeakerMode && participant && (
        <div className={styles.buttons} onClick={isSelectedParticipant ? undefined : onClickMaximize}>
          {!isFullscreen && (
            <IconButton
              buttonSize={40}
              iconSize={18}
              borderRadius={40}
              color="var(--color-white)"
              background="rgb(var(--color-theme-button))"
              hoverBackground="rgb(var(--color-theme-accent))"
              children={<PinIcon />}
              label={`Pin ${user.displayName}`}
            />
          )}
        </div>
      )}

      {isSelf && userMenuId === user.userId && (
        <Portal>
          <ul
            ref={setMenuPopperElement}
            style={menuPopperStyles.popper}
            {...menuAttributes.popper}
            onClick={(e) => e.stopPropagation()}
            className={`box-shadow unstyled ${styles.menu}`}
          >
            <li className={styles.menuItem}>
              <button onClick={onChangeNameClick} className={`button-reset heading2 ${styles.menuLink}`}>
                Change Display Name
              </button>
              <div className={styles.menuBorder} />
            </li>
          </ul>
        </Portal>
      )}
    </div>
  );
}
