import _ from 'lodash';

import { MapLineAction, MapTokenAction } from 'models/Map';
import { ProductAction, RoomDocumentAction } from 'store/actions';

const INITIAL_STATE = {};

export default function roomDocumentReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case RoomDocumentAction.CREATE:
    case RoomDocumentAction.FETCH:
    case RoomDocumentAction.UPDATE:
      return { ...state, [payload.guidId]: payload };

    case RoomDocumentAction.UPDATE_LINE:
      switch (payload.lineAction) {
        case MapLineAction.ADD:
          return addLineState(state, payload);
        case MapLineAction.REMOVE:
          return removeLineState(state, payload);
        case MapLineAction.UPDATE:
          return updateLineState(state, payload);
        default:
          return state;
      }

    case RoomDocumentAction.UPDATE_TOKEN:
      switch (payload.tokenAction) {
        case MapTokenAction.ADD:
          return addTokenState(state, payload);
        case MapTokenAction.REMOVE:
          return removeTokenState(state, payload);
        case MapTokenAction.UPDATE:
          return updateTokenState(state, payload);
        default:
          return state;
      }

    case RoomDocumentAction.DELETE:
    case RoomDocumentAction.REMOVE:
      return _.omit(state, payload.guidId);

    case RoomDocumentAction.FETCH_ALL:
    case ProductAction.FETCH_DOCUMENTS:
      return { ...state, ..._.mapKeys(payload, 'guidId') };

    default:
      return state;
  }
}

const addLineState = (state, payload) => {
  const { roomDocument: rd, line } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const lines = roomDocument.data.lines || [];
  const data = { ...roomDocument.data, lines: [...lines, line] };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};

const removeLineState = (state, payload) => {
  const { roomDocument: rd, line } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const lines = roomDocument.data.lines || [];
  const data = { ...roomDocument.data, lines: lines.filter((o) => o.id !== line.id) };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};

const updateLineState = (state, payload) => {
  const { roomDocument: rd, line } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const lines = roomDocument.data.lines || [];
  const data = { ...roomDocument.data, lines: lines.map((o) => (o.id === line.id ? line : o)) };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};

const addTokenState = (state, payload) => {
  const { roomDocument: rd, token } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const tokens = roomDocument.data.tokens || [];
  const data = { ...roomDocument.data, tokens: [...tokens, token] };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};

const removeTokenState = (state, payload) => {
  const { roomDocument: rd, token } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const tokens = roomDocument.data.tokens || [];
  const data = { ...roomDocument.data, tokens: tokens.filter((o) => o.id !== token.id) };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};

const updateTokenState = (state, payload) => {
  const { roomDocument: rd, token } = payload;
  const roomDocument = state[rd.guidId];
  if (!roomDocument) return state;
  const tokens = roomDocument.data.tokens || [];
  const data = { ...roomDocument.data, tokens: tokens.map((o) => (o.id === token.id ? token : o)) };
  return { ...state, [roomDocument.guidId]: { ...roomDocument, data } };
};
