// @flow
import React from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';
import Field from './Field';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function FieldBuilder(props: Props) {
  const { element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;
  const fieldInput = items.find((item) => item.inputType === INPUT_TYPE.field);
  const fieldValue = fieldInput?.defaultValue ?? '';

  const onFieldChange = (value) => {
    if (!fieldInput) return;
    const updatedItems = items.map((o) => (o.id === fieldInput.id ? { ...fieldInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      <Field value={fieldValue} onChange={onFieldChange} />
    </Builder>
  );
}

const MemoizedFieldBuilder = React.memo(FieldBuilder);
MemoizedFieldBuilder.displayName = 'MemoizedFieldBuilder';

export default MemoizedFieldBuilder;
