//@flow
import styles from 'components/layouts/SessionPage.module.css';

import React from 'react';
import { Link } from 'react-router-dom';

import type { location } from 'types/common';

import requireNoAuth from 'hocs/requireNoAuth';

import AuthForm from 'components/AuthForm/AuthForm';
import SessionPage from 'components/layouts/SessionPage';
import Metatags from 'components/Metatags';

type Props = {
  handleSubmit: (a: any) => void,
  location: location,
  submitFailed: boolean,
};

function SignUpPage(props: Props) {
  const {
    location: { search },
  } = props;

  const redirectTo = new URLSearchParams(search).get('to');
  let loginLink = '/login';
  if (redirectTo) loginLink += `?to=${encodeURIComponent(redirectTo)}`;

  return (
    <>
      <Metatags title="Create Account" />

      <SessionPage title="Role Sign Up and Create Account">
        <div className={styles.signUpHeader}>
          <h1>Create a new account</h1>
          <span>Enjoy a better way to play your favorite tabletop RPGs with Role.</span>
        </div>
        <AuthForm isLogIn={false} showTabs={false} />
        <div className={styles.footerLink}>
          <Link to={loginLink}>
            Already a member? <span className={styles.highlight}>Log In</span>
          </Link>
        </div>
      </SessionPage>
    </>
  );
}

export default requireNoAuth(SignUpPage);
