// @flow
import styles from './TableOfContents.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import type { TableOfContentsMeta } from './types';

import { ReactComponent as DragIcon } from 'images/icons/DragIcon.svg';

type DragMeta = {
  id?: string,
  sourceIndex?: number,
  destinationIndex?: number,
  height?: number,
};

type NavItemProps = {
  id: string,
  index: number,
  isActive: boolean,
  onClick: (string) => void,
  readOnly: boolean,
  title: string,
  onDrag: (any) => void,
  dragMeta: DragMeta,
  onDragComplete: (number, number) => void,
};

type TableOfContentsProps = {
  children: Node,
  className?: ?string,
  meta?: ?TableOfContentsMeta,
};

const DragTypes = {
  TOC: 'toc',
};

const NavItem = (props: NavItemProps) => {
  const { id, index, isActive, onClick, readOnly, title, onDrag, dragMeta, onDragComplete } = props;
  const { height, sourceIndex, destinationIndex } = dragMeta;
  const ref = useRef(null);
  const [nudgeValue, setNudgeValue] = useState(0);

  useEffect(() => {
    if (_.isEmpty(dragMeta) || destinationIndex === sourceIndex) setNudgeValue(0);
    else if (destinationIndex >= index && index > sourceIndex) setNudgeValue(-1);
    else if (destinationIndex <= index && index < sourceIndex) setNudgeValue(1);
  }, [title, dragMeta, index, destinationIndex, sourceIndex]);

  const [{ isDragging }, dragHandle, dragPreview] = useDrag(
    () => ({
      type: DragTypes.TOC,
      item: () => {
        const height = ref.current?.getBoundingClientRect()?.height;
        onDrag({ id, sourceIndex: index, destinationIndex: index, height });
        return { id, sourceIndex: index, destinationIndex: index, height };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          onDrag({});
        }
      },
    }),
    [id, index]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DragTypes.TOC,
      hover() {
        if (index !== destinationIndex) {
          onDrag((prevState) => ({ ...prevState, destinationIndex: index }));
        }
      },
      drop() {
        onDragComplete(dragMeta.sourceIndex, dragMeta.destinationIndex);
      },
    }),
    [index, dragMeta, title, destinationIndex]
  );

  dragPreview(ref);

  return (
    <div
      ref={drop}
      className={clsx(
        styles.navItem,
        readOnly && styles.readOnly,
        isDragging && styles.isDragging,
        isActive && styles.isActive
      )}
    >
      <div ref={dragHandle} className={styles.dragHandle}>
        <DragIcon />
      </div>
      <button className={`button-reset ${styles.navButton}`} onClick={() => onClick(id)}>
        <div
          ref={ref}
          style={{
            transform: `translate(0px, ${(height ?? 0) * nudgeValue}px)`,
            transition: !_.isEmpty(dragMeta) ? 'transform 0.2s ease-in-out' : null,
          }}
        >
          {title || `Section ${index + 1}`}
        </div>
      </button>
    </div>
  );
};

NavItem.defaultProps = {
  readOnly: false,
};

export { NavItem };

function TableOfContents(props: TableOfContentsProps): Node {
  const { children, className, meta } = props;

  return (
    <>
      <div className={clsx('scrollbars-dark', styles.container, className)}>
        <div className={styles.meta}>
          {meta && (
            <>
              {meta.name && <h3 className={styles.heading}>{meta.name}</h3>}
              {meta.username && <div className={styles.author}>Created by {meta.username}</div>}
            </>
          )}
        </div>
        <DndProvider backend={HTML5Backend}>
          <nav className={styles.nav}>{children}</nav>
        </DndProvider>
      </div>
      <div className={styles.background}></div>
    </>
  );
}

export default TableOfContents;
