// @flow
import styles from './Field.module.css';

import React, { useRef } from 'react';
import type { Node } from 'react';

type Props = {
  className?: ?string,
  onBlur?: ?() => void,
  onChange: (string) => void,
  onFocus?: ?() => void,
  readOnly: boolean,
  value?: ?string,
};

function Field(props: Props): Node {
  const { className, onBlur: propOnBlur, onChange, onFocus, readOnly, value } = props;
  const inputRef = useRef(null);

  const onBlur = (event) => {
    onChange(event.currentTarget.value.trim());
    if (propOnBlur) propOnBlur();
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13 && inputRef.current) inputRef.current.blur();
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="Enter your info"
        value={value}
        readOnly={readOnly}
        onBlur={onBlur}
        onChange={(e) => onChange(e.currentTarget.value)}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

Field.defaultProps = {
  readOnly: false,
};

export default Field;
