import styles from './Tag.module.css';

import clsx from 'clsx';

import { minutesToHours } from 'utilities/numbers';

import Tag from './Tag';
import { ReactComponent as PieChartIcon } from 'images/icons/PieChartIcon.svg';
import { ReactComponent as PlayerIcon } from 'images/icons/PlayerIcon.svg';

const COMPLEXITY_LABEL = Object.freeze({
  rules_light: 'Rules-Light',
  moderate: 'Moderate',
  complex: 'Complex',
});

function renderComplexity(complexity) {
  return (
    <span className={styles.gameTag} title={COMPLEXITY_LABEL[complexity]}>
      <span
        className={clsx(
          styles.complexity,
          complexity === 'moderate' && styles.moderate,
          complexity === 'complex' && styles.complex
        )}
      >
        <span className={styles.bar} />
        <span className={styles.bar} />
        <span className={styles.bar} />
      </span>
      <span>{COMPLEXITY_LABEL[complexity]}</span>
    </span>
  );
}

function renderPlayerCount(minPlayers, maxPlayers) {
  return (
    <span className={styles.gameTag} title={`${minPlayers} to ${maxPlayers} Players`}>
      <PlayerIcon className={styles.icon} />
      <span>
        {minPlayers}-{maxPlayers} Players
      </span>
    </span>
  );
}

function makeSessionLengthTitle(sessionLength) {
  return (
    <span className={styles.gameTag} title={`${minutesToHours(sessionLength)} Hours`}>
      <PieChartIcon className={styles.icon} />
      <span>{minutesToHours(sessionLength)} Hours</span>
    </span>
  );
}

function GameTag({ game, gameTagType }) {
  const tagChildren = () => {
    switch (gameTagType) {
      case 'complexity':
        return renderComplexity(game.complexity);
      case 'player-count':
        return renderPlayerCount(game.minPlayers, game.maxPlayers);
      case 'session-length':
      default:
        return makeSessionLengthTitle(game.sessionLength);
    }
  };

  return (
    <Tag color="var(--color-dashboard-mid)" backgroundColor="var(--color-dashboard-light-grey)">
      {tagChildren()}
    </Tag>
  );
}

export default GameTag;
