import { batch } from 'react-redux';

import { createAction, handleError } from './utilities';
import { SheetTemplateSelector } from 'store/selectors';

import DocumentAction from './DocumentAction';
import GameAction from './GameAction';
import OrganizationAction from './OrganizationAction';
import ProductAction from './ProductAction';
import role from 'apis/role';

function processSheetTemplateResponse({ data, included }) {
  const sheetTemplates = data.map(extractObj);
  const games = [];
  const products = [];
  const organizations = [];
  const documents = [];

  for (const { id, type, attributes } of included ?? []) {
    const obj = { id, ...attributes };
    if (type === 'game') games.push(obj);
    else if (type === 'product') products.push(obj);
    else if (type === 'organization') organizations.push(obj);
    else if (type === 'document') documents.push(obj);
  }

  return { sheetTemplates, games, products, organizations, documents };
}

export const extractObj = ({
  id,
  attributes,
  relationships: { playkits, products, games, ancestorGames, ancestorProducts, documents },
}) => {
  const playkitIds = playkits.data.map((o) => o.id);
  const productIds = products.data.map((o) => o.id);
  const gameIds = games.data.map((o) => o.id);
  const ancestorGameIds = ancestorGames.data.map((o) => o.id);
  const ancestorProductIds = ancestorProducts.data.map((o) => o.id);
  const documentIds = documents.data.map((o) => o.id);
  return { id, ...attributes, playkitIds, productIds, gameIds, ancestorGameIds, ancestorProductIds, documentIds };
};

export default class SheetTemplateAction {
  static ADD_IMAGE = 'SheetTemplateAction.ADD_IMAGE';
  static CREATE = 'SheetTemplateAction.CREATE';
  static DELETE = 'SheetTemplateAction.DELETE';
  static DUPLICATE = 'SheetTemplateAction.DUPLICATE';
  static FETCH = 'SheetTemplateAction.FETCH';
  static FETCH_ALL = 'SheetTemplateAction.FETCH_ALL';
  static FETCH_SAVED = 'SheetTemplateAction.FETCH_SAVED';
  static PUBLISH = 'SheetTemplateAction.PUBLISH';
  static REQUEST_REMOVE = 'SheetTemplateAction.REQUEST_REMOVE';
  static REQUEST_SHARE = 'SheetTemplateAction.REQUEST_SHARE';
  static REMOVE_IMAGE = 'SheetTemplateAction.REMOVE_IMAGE';
  static SAVE = 'SheetTemplateAction.SAVE';
  static UNPUBLISH = 'SheetTemplateAction.UNPUBLISH';
  static UNSAVE = 'SheetTemplateAction.UNSAVE';
  static UPDATE = 'SheetTemplateAction.UPDATE';
  static UPDATE_AND_FORGET = 'SheetTemplateAction.UPDATE_AND_FORGET';
  static UPDATE_LOCAL = 'SheetTemplateAction.UPDATE_LOCAL';

  static CREATE_DICE = 'SheetTemplateAction.CREATE_DICE';
  static DELETE_DICE = 'SheetTemplateAction.DELETE_DICE';

  static CHANGE = 'SheetTemplateAction.CHANGE';
  static CHANGE_ELEMENT = 'SheetTemplateAction.CHANGE_ELEMENT';
  static CHANGE_GROUP_ELEMENT = 'SheetTemplateAction.CHANGE_GROUP_ELEMENT';
  static CHANGE_SECTION = 'SheetTemplateAction.CHANGE_SECTION';

  static UPDATE_ELEMENT = 'SheetTemplateAction.UPDATE_ELEMENT';
  static UPDATE_GROUP_ELEMENT = 'SheetTemplateAction.UPDATE_GROUP_ELEMENT';
  static UPDATE_SECTION = 'SheetTemplateAction.UPDATE_SECTION';

  static addImage = (guidSlug, sectionId, elementId, groupElementId, image, callback) => async (dispatch) => {
    const formData = new FormData();
    formData.append('sheet_template[section_id]', sectionId);
    formData.append('sheet_template[element_id]', elementId);
    formData.append('sheet_template[group_element_id]', groupElementId);
    formData.append('sheet_template[image]', image);

    return role.post(`/sheet_templates/${guidSlug}/add_image`, formData).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.ADD_IMAGE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.ADD_IMAGE, response)
    );
  };

  static create = (sheetTemplate, callback) => async (dispatch) => {
    return role.post('/sheet_templates', sheetTemplate).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.CREATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.CREATE, response)
    );
  };

  static delete = (guidSlug) => async (dispatch) => {
    return role.delete(`/sheet_templates/${guidSlug}`).then(
      (response) => dispatch(createAction(SheetTemplateAction.DELETE, guidSlug)),
      ({ response }) => handleError(dispatch, SheetTemplateAction.DELETE, response)
    );
  };

  static duplicate = (guidSlug, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/duplicate`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.DUPLICATE, obj));
        callback?.(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.DUPLICATE, response)
    );
  };

  static fetch = (guidSlug, callback) => async (dispatch) => {
    return role.get(`/sheet_templates/${guidSlug}`).then(
      (response) => {
        const { sheetTemplates, games, products, organizations, documents } = processSheetTemplateResponse({
          data: [response.data.data],
          included: response.data.included,
        });
        batch(() => {
          dispatch(createAction(SheetTemplateAction.FETCH, sheetTemplates[0]));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
          dispatch(createAction(DocumentAction.FETCH_ALL, documents));
        });
        callback?.(sheetTemplates[0]);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.FETCH, response)
    );
  };

  static fetchAll = (callback) => async (dispatch) => {
    return role.get('/sheet_templates').then(
      (response) => {
        const { sheetTemplates, games, products, documents } = processSheetTemplateResponse(response.data);
        batch(() => {
          dispatch(createAction(SheetTemplateAction.FETCH_ALL, sheetTemplates));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(DocumentAction.FETCH_ALL, documents));
        });
        callback?.(sheetTemplates);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.FETCH_ALL, response)
    );
  };

  static fetchSaved = (callback) => async (dispatch) => {
    return role.get('/sheet_templates/saved').then(
      (response) => {
        const objs = response.data.data.map((obj) => extractObj(obj));
        dispatch(createAction(SheetTemplateAction.FETCH_SAVED, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.FETCH_SAVED, response)
    );
  };

  static publish = (guidSlug, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/publish`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.PUBLISH, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.PUBLISH, response)
    );
  };

  static unpublish = (guidSlug, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/unpublish`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.UNPUBLISH, obj));
        callback?.(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.UNPUBLISH, response)
    );
  };

  static removeAvatar = (guidSlug, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/remove_avatar`).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.UPDATE, response)
    );
  };

  static removeImage = (guidSlug, sectionId, elementId, groupElementId, imageId, callback) => async (dispatch) => {
    const sheet_template = {
      section_id: sectionId,
      element_id: elementId,
      group_element_id: groupElementId,
      image_id: imageId,
    };
    return role.post(`/sheet_templates/${guidSlug}/remove_image`, { sheet_template }).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.REMOVE_IMAGE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.REMOVE_IMAGE, response)
    );
  };

  static createDice = (guidSlug, documentId, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/dice`, { document_id: documentId }).then(
      (response) => {
        const { sheetTemplates, documents } = processSheetTemplateResponse({
          data: [response.data.data],
          included: response.data.included,
        });
        batch(() => {
          dispatch(createAction(SheetTemplateAction.CREATE_DICE, sheetTemplates[0]));
          dispatch(createAction(DocumentAction.FETCH_ALL, documents));
        });
        callback?.(sheetTemplates[0]);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.CREATE_DICE, response)
    );
  };

  static save = (guidSlug, callback) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/save`).then(
      (response) => {
        dispatch(createAction(SheetTemplateAction.SAVE, guidSlug));
        callback?.();
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.SAVE, response)
    );
  };

  static unsave = (guidSlug) => async (dispatch) => {
    return role.post(`/sheet_templates/${guidSlug}/unsave`).then(
      (response) => dispatch(createAction(SheetTemplateAction.UNSAVE, guidSlug)),
      ({ response }) => handleError(dispatch, SheetTemplateAction.UNSAVE, response)
    );
  };

  static update = (guidSlug, template, callback) => async (dispatch) => {
    const data = {
      name: template.name,
      privacy: template.privacy,
      data: template.data,
      draft_data: template.draftData,
    };
    return role.patch(`/sheet_templates/${guidSlug}`, { sheet_template: data }).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.UPDATE, response)
    );
  };

  static updateAndForget =
    (template, callback = null, type = SheetTemplateAction.UPDATE_AND_FORGET) =>
    async (dispatch) => {
      const data = {
        name: template.name,
        privacy: template.privacy,
        data: template.data,
        draft_data: template.draftData,
      };
      return role.patch(`/sheet_templates/${template.guidSlug}`, { sheet_template: data }).then(
        (response) => {
          const obj = extractObj(response.data.data);
          dispatch(createAction(type, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, type, response)
      );
    };

  static updateAvatar = (guidSlug, avatar, callback) => async (dispatch) => {
    const formData = new FormData();
    if (avatar) formData.append('sheet_template[avatar]', avatar);

    return role.patch(`/sheet_templates/${guidSlug}`, formData).then(
      (response) => {
        const obj = extractObj(response.data.data);
        dispatch(createAction(SheetTemplateAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, SheetTemplateAction.UPDATE, response)
    );
  };

  static updateLocal = (template) => {
    return createAction(SheetTemplateAction.UPDATE_LOCAL, template);
  };

  static change = (model) => {
    return createAction(SheetTemplateAction.CHANGE, model);
  };

  static changeElement = (id, sectionId, element) => {
    return createAction(SheetTemplateAction.CHANGE_ELEMENT, { id, sectionId, element });
  };

  static changeGroupElement = (id, sectionId, groupId, element) => {
    return createAction(SheetTemplateAction.CHANGE_GROUP_ELEMENT, { id, sectionId, groupId, element });
  };

  static changeSection = (id, section) => {
    return createAction(SheetTemplateAction.CHANGE_SECTION, { id, section });
  };

  static updateElement = (id, sectionId, element, callback) => async (dispatch, getState) => {
    const model = SheetTemplateSelector.get(getState(), id);
    const updatedModel = {
      ...model,
      draftData: {
        ...model.draftData,
        sections: model.draftData.sections.map((o) => {
          if (o.id === sectionId) {
            return { ...o, elements: o.elements.map((e) => (e.id === element.id ? element : e)) };
          }
          return o;
        }),
      },
    };
    return dispatch(SheetTemplateAction.updateAndForget(updatedModel, callback, SheetTemplateAction.UPDATE_ELEMENT));
  };

  static updateGroupElement = (id, sectionId, groupId, element, callback) => async (dispatch, getState) => {
    const model = SheetTemplateSelector.get(getState(), id);
    const updatedModel = {
      ...model,
      draftData: {
        ...model.draftData,
        sections: model.draftData.sections.map((o) => {
          if (o.id === sectionId) {
            return {
              ...o,
              elements: o.elements.map((e) => {
                if (e.id === groupId) {
                  return { ...e, items: e.items.map((ge) => (ge.id === element.id ? element : ge)) };
                }
                return e;
              }),
            };
          }
          return o;
        }),
      },
    };
    return dispatch(
      SheetTemplateAction.updateAndForget(updatedModel, callback, SheetTemplateAction.UPDATE_GROUP_ELEMENT)
    );
  };

  static updateSection = (id, section, callback) => async (dispatch, getState) => {
    const model = SheetTemplateSelector.get(getState(), id);
    const updatedModel = {
      ...model,
      draftData: {
        ...model.draftData,
        sections: model.draftData.sections.map((o) => (o.id === section.id ? section : o)),
      },
    };
    return dispatch(SheetTemplateAction.updateAndForget(updatedModel, callback, SheetTemplateAction.UPDATE_SECTION));
  };

  /***
    UI Actions
   */

  static requestRemove = (guid) => {
    return createAction(SheetTemplateAction.REQUEST_REMOVE, guid);
  };

  static requestShare = (guid) => {
    return createAction(SheetTemplateAction.REQUEST_SHARE, guid);
  };
}
