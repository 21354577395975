import styles from './TableMenu.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { batch, useDispatch, useSelector } from 'react-redux';

import { GeneralAction, TableAction } from 'store/actions';
import { GeneralSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';
import { stylizeTheme } from 'utilities/color';

import { Popover, Portal } from '@headlessui/react';
import IconButton from 'components/buttons/IconButton';
import MenuList from 'components/MenuList';
import { ReactComponent as GearIcon } from 'images/icons/GearIcon.svg';

type Props = {
  className?: ?string,
  hostId: string,
  roomGuid: string,
  theme?: ?any,
};

function TableMenu({ className, hostId, roomGuid, theme }: Props): Node {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const isActive = useSelector(GeneralSelector.isShowingTableMenu);
  const isOwner = hostId === currentUser.id;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const onClick = useCallback(() => {
    batch(() => {
      dispatch(GeneralAction.showTableMenu(!isActive));
      dispatch(GeneralAction.showUserMenu(false));
    });
  }, [dispatch, isActive]);

  const onManagePlayersClick = useCallback(() => dispatch(TableAction.showManageUsers(true)), [dispatch]);

  const onMySettingsClick = useCallback(() => {
    logEvent('table - open my room user settings', {
      'table guid': roomGuid,
    });

    dispatch(TableAction.showUserSettings(true));
  }, [dispatch, roomGuid]);

  const onRemoveClick = useCallback(() => dispatch(TableAction.remove(roomGuid)), [dispatch, roomGuid]);

  const onTableSettingsClick = useCallback(() => {
    logEvent('table - open room settings', {
      'table guid': roomGuid,
    });
    dispatch(TableAction.showSettings(true));
  }, [dispatch, roomGuid]);

  const menuItems = useMemo(() => {
    const items = [];
    if (isOwner) items.push({ label: 'Room Settings', onClick: onTableSettingsClick });
    items.push({ label: 'My Settings', onClick: onMySettingsClick });
    if (isOwner) items.push({ label: 'Manage Players', onClick: onManagePlayersClick });
    items.push({ label: isOwner ? 'Delete Room' : 'Leave Room', onClick: onRemoveClick });
    return items;
  }, [isOwner, onManagePlayersClick, onMySettingsClick, onRemoveClick, onTableSettingsClick]);

  return (
    <Popover className={clsx(styles.container, className)}>
      <div className={styles.content} onClick={onClick} ref={setReferenceElement}>
        <IconButton
          background="rgb(var(--color-theme-button))"
          activeBackground="rgb(var(--color-theme-accent))"
          hoverBackground="rgb(var(--color-theme-accent))"
          color="rgb(var(--color-theme-text))"
          children={<GearIcon />}
          iconSize={18}
          label="Table Settings"
          isActive={isActive}
        />
      </div>

      {isActive && (
        <Portal>
          <Popover.Panel
            static
            className={styles.popper}
            ref={setPopperElement}
            style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
            {...attributes.popper}
          >
            <MenuList className={styles.menu} items={menuItems} isActive={isActive} variant="theme" />
          </Popover.Panel>
        </Portal>
      )}
    </Popover>
  );
}

export default TableMenu;
