import { DeckCard, Suit } from 'models/Card';

import Spades1 from 'images/cards/StandardDeck/Spades_1.png';
import Spades2 from 'images/cards/StandardDeck/Spades_2.png';
import Spades3 from 'images/cards/StandardDeck/Spades_3.png';
import Spades4 from 'images/cards/StandardDeck/Spades_4.png';
import Spades5 from 'images/cards/StandardDeck/Spades_5.png';
import Spades6 from 'images/cards/StandardDeck/Spades_6.png';
import Spades7 from 'images/cards/StandardDeck/Spades_7.png';
import Spades8 from 'images/cards/StandardDeck/Spades_8.png';
import Spades9 from 'images/cards/StandardDeck/Spades_9.png';
import Spades10 from 'images/cards/StandardDeck/Spades_10.png';
import Spades11 from 'images/cards/StandardDeck/Spades_11.png';
import Spades12 from 'images/cards/StandardDeck/Spades_12.png';
import Spades13 from 'images/cards/StandardDeck/Spades_13.png';

import Hearts1 from 'images/cards/StandardDeck/Hearts_1.png';
import Hearts2 from 'images/cards/StandardDeck/Hearts_2.png';
import Hearts3 from 'images/cards/StandardDeck/Hearts_3.png';
import Hearts4 from 'images/cards/StandardDeck/Hearts_4.png';
import Hearts5 from 'images/cards/StandardDeck/Hearts_5.png';
import Hearts6 from 'images/cards/StandardDeck/Hearts_6.png';
import Hearts7 from 'images/cards/StandardDeck/Hearts_7.png';
import Hearts8 from 'images/cards/StandardDeck/Hearts_8.png';
import Hearts9 from 'images/cards/StandardDeck/Hearts_9.png';
import Hearts10 from 'images/cards/StandardDeck/Hearts_10.png';
import Hearts11 from 'images/cards/StandardDeck/Hearts_11.png';
import Hearts12 from 'images/cards/StandardDeck/Hearts_12.png';
import Hearts13 from 'images/cards/StandardDeck/Hearts_13.png';

import Clubs1 from 'images/cards/StandardDeck/Clubs_1.png';
import Clubs2 from 'images/cards/StandardDeck/Clubs_2.png';
import Clubs3 from 'images/cards/StandardDeck/Clubs_3.png';
import Clubs4 from 'images/cards/StandardDeck/Clubs_4.png';
import Clubs5 from 'images/cards/StandardDeck/Clubs_5.png';
import Clubs6 from 'images/cards/StandardDeck/Clubs_6.png';
import Clubs7 from 'images/cards/StandardDeck/Clubs_7.png';
import Clubs8 from 'images/cards/StandardDeck/Clubs_8.png';
import Clubs9 from 'images/cards/StandardDeck/Clubs_9.png';
import Clubs10 from 'images/cards/StandardDeck/Clubs_10.png';
import Clubs11 from 'images/cards/StandardDeck/Clubs_11.png';
import Clubs12 from 'images/cards/StandardDeck/Clubs_12.png';
import Clubs13 from 'images/cards/StandardDeck/Clubs_13.png';

import Diamonds1 from 'images/cards/StandardDeck/Diamonds_1.png';
import Diamonds2 from 'images/cards/StandardDeck/Diamonds_2.png';
import Diamonds3 from 'images/cards/StandardDeck/Diamonds_3.png';
import Diamonds4 from 'images/cards/StandardDeck/Diamonds_4.png';
import Diamonds5 from 'images/cards/StandardDeck/Diamonds_5.png';
import Diamonds6 from 'images/cards/StandardDeck/Diamonds_6.png';
import Diamonds7 from 'images/cards/StandardDeck/Diamonds_7.png';
import Diamonds8 from 'images/cards/StandardDeck/Diamonds_8.png';
import Diamonds9 from 'images/cards/StandardDeck/Diamonds_9.png';
import Diamonds10 from 'images/cards/StandardDeck/Diamonds_10.png';
import Diamonds11 from 'images/cards/StandardDeck/Diamonds_11.png';
import Diamonds12 from 'images/cards/StandardDeck/Diamonds_12.png';
import Diamonds13 from 'images/cards/StandardDeck/Diamonds_13.png';

export default [
  new DeckCard({ name: 'A', suit: Suit.SPADES, value: 1, image: Spades1 }),
  new DeckCard({ name: '2', suit: Suit.SPADES, value: 2, image: Spades2 }),
  new DeckCard({ name: '3', suit: Suit.SPADES, value: 3, image: Spades3 }),
  new DeckCard({ name: '4', suit: Suit.SPADES, value: 4, image: Spades4 }),
  new DeckCard({ name: '5', suit: Suit.SPADES, value: 5, image: Spades5 }),
  new DeckCard({ name: '6', suit: Suit.SPADES, value: 6, image: Spades6 }),
  new DeckCard({ name: '7', suit: Suit.SPADES, value: 7, image: Spades7 }),
  new DeckCard({ name: '8', suit: Suit.SPADES, value: 8, image: Spades8 }),
  new DeckCard({ name: '9', suit: Suit.SPADES, value: 9, image: Spades9 }),
  new DeckCard({ name: '10', suit: Suit.SPADES, value: 10, image: Spades10 }),
  new DeckCard({ name: 'J', suit: Suit.SPADES, value: 11, image: Spades11 }),
  new DeckCard({ name: 'Q', suit: Suit.SPADES, value: 12, image: Spades12 }),
  new DeckCard({ name: 'K', suit: Suit.SPADES, value: 13, image: Spades13 }),

  new DeckCard({ name: 'A', suit: Suit.HEARTS, value: 1, image: Hearts1 }),
  new DeckCard({ name: '2', suit: Suit.HEARTS, value: 2, image: Hearts2 }),
  new DeckCard({ name: '3', suit: Suit.HEARTS, value: 3, image: Hearts3 }),
  new DeckCard({ name: '4', suit: Suit.HEARTS, value: 4, image: Hearts4 }),
  new DeckCard({ name: '5', suit: Suit.HEARTS, value: 5, image: Hearts5 }),
  new DeckCard({ name: '6', suit: Suit.HEARTS, value: 6, image: Hearts6 }),
  new DeckCard({ name: '7', suit: Suit.HEARTS, value: 7, image: Hearts7 }),
  new DeckCard({ name: '8', suit: Suit.HEARTS, value: 8, image: Hearts8 }),
  new DeckCard({ name: '9', suit: Suit.HEARTS, value: 9, image: Hearts9 }),
  new DeckCard({ name: '10', suit: Suit.HEARTS, value: 10, image: Hearts10 }),
  new DeckCard({ name: 'J', suit: Suit.HEARTS, value: 11, image: Hearts11 }),
  new DeckCard({ name: 'Q', suit: Suit.HEARTS, value: 12, image: Hearts12 }),
  new DeckCard({ name: 'K', suit: Suit.HEARTS, value: 13, image: Hearts13 }),

  new DeckCard({ name: 'A', suit: Suit.CLUBS, value: 1, image: Clubs1 }),
  new DeckCard({ name: '2', suit: Suit.CLUBS, value: 2, image: Clubs2 }),
  new DeckCard({ name: '3', suit: Suit.CLUBS, value: 3, image: Clubs3 }),
  new DeckCard({ name: '4', suit: Suit.CLUBS, value: 4, image: Clubs4 }),
  new DeckCard({ name: '5', suit: Suit.CLUBS, value: 5, image: Clubs5 }),
  new DeckCard({ name: '6', suit: Suit.CLUBS, value: 6, image: Clubs6 }),
  new DeckCard({ name: '7', suit: Suit.CLUBS, value: 7, image: Clubs7 }),
  new DeckCard({ name: '8', suit: Suit.CLUBS, value: 8, image: Clubs8 }),
  new DeckCard({ name: '9', suit: Suit.CLUBS, value: 9, image: Clubs9 }),
  new DeckCard({ name: '10', suit: Suit.CLUBS, value: 10, image: Clubs10 }),
  new DeckCard({ name: 'J', suit: Suit.CLUBS, value: 11, image: Clubs11 }),
  new DeckCard({ name: 'Q', suit: Suit.CLUBS, value: 12, image: Clubs12 }),
  new DeckCard({ name: 'K', suit: Suit.CLUBS, value: 13, image: Clubs13 }),

  new DeckCard({ name: 'A', suit: Suit.DIAMONDS, value: 1, image: Diamonds1 }),
  new DeckCard({ name: '2', suit: Suit.DIAMONDS, value: 2, image: Diamonds2 }),
  new DeckCard({ name: '3', suit: Suit.DIAMONDS, value: 3, image: Diamonds3 }),
  new DeckCard({ name: '4', suit: Suit.DIAMONDS, value: 4, image: Diamonds4 }),
  new DeckCard({ name: '5', suit: Suit.DIAMONDS, value: 5, image: Diamonds5 }),
  new DeckCard({ name: '6', suit: Suit.DIAMONDS, value: 6, image: Diamonds6 }),
  new DeckCard({ name: '7', suit: Suit.DIAMONDS, value: 7, image: Diamonds7 }),
  new DeckCard({ name: '8', suit: Suit.DIAMONDS, value: 8, image: Diamonds8 }),
  new DeckCard({ name: '9', suit: Suit.DIAMONDS, value: 9, image: Diamonds9 }),
  new DeckCard({ name: '10', suit: Suit.DIAMONDS, value: 10, image: Diamonds10 }),
  new DeckCard({ name: 'J', suit: Suit.DIAMONDS, value: 11, image: Diamonds11 }),
  new DeckCard({ name: 'Q', suit: Suit.DIAMONDS, value: 12, image: Diamonds12 }),
  new DeckCard({ name: 'K', suit: Suit.DIAMONDS, value: 13, image: Diamonds13 }),
]
