import styles from './TableMediaTools.module.css';

import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useUnmount from 'hooks/useUnmount';
import Color from 'models/Color';
import AssetAction from 'store/actions/AssetAction';
import MediaAction from 'store/actions/MediaAction';
import { TableSelector } from 'store/selectors';
import AssetSelector from 'store/selectors/AssetSelector';
import MediaSelector from 'store/selectors/MediaSelector';
import { MediaMode } from 'types/room';

import AddTokenModal from 'components/AddTokenModal';
import { ReactComponent as MinusIcon } from 'images/icons/MinusIcon.svg';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as TokenIcon } from 'images/icons/TokenIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';

const MAX_ZOOM_IN = 5.0;
const MAX_ZOOM_OUT = 0.25;
const ZOOM_INCREMENT = 0.25;

interface TableMediaToolsProps {
  room?: any;
  asset: any;
  roomDocument?: any;
  className?: string;
}

export default function TableMediaTools({ room, asset, roomDocument, className }: TableMediaToolsProps) {
  const dispatch = useDispatch();

  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const canvasScale = useSelector(MediaSelector.getCanvasScale);
  const isDrawing = useSelector(MediaSelector.isDrawing);
  const isDeleting = useSelector(MediaSelector.isDeleting);
  const penColor = useSelector(MediaSelector.getPenColor);
  const showAddTokenModal = useSelector(AssetSelector.showAddTokenModal);
  const tokenAssetToEdit = useSelector(AssetSelector.getAssetTokenToEdit);

  const onAddTokenModalDismiss = useCallback(() => {
    dispatch(AssetAction.showAddToken(false));
  }, [dispatch]);

  const onTokensClick = useCallback(() => {
    batch(() => {
      dispatch(MediaAction.setMode(MediaMode.None));
      dispatch(AssetAction.showAddToken(true));
    });
  }, [dispatch]);

  const onDrawClick = useCallback(() => {
    dispatch(MediaAction.setMode(isDrawing ? MediaMode.None : MediaMode.Drawing));
  }, [dispatch, isDrawing]);

  const onColorClick = useCallback(
    (color: string) => {
      batch(() => {
        dispatch(MediaAction.setMode(MediaMode.Drawing));
        dispatch(MediaAction.setPenColor(color));
      });
    },
    [dispatch]
  );

  const onDeleteClick = useCallback(() => {
    dispatch(MediaAction.setMode(isDeleting ? MediaMode.None : MediaMode.Deleting));
  }, [dispatch, isDeleting]);

  const onZoomInClick = useCallback(() => {
    dispatch(MediaAction.setCanvasScale(Math.min(canvasScale + ZOOM_INCREMENT, MAX_ZOOM_IN)));
  }, [canvasScale, dispatch]);

  const onZoomOutClick = useCallback(() => {
    dispatch(MediaAction.setCanvasScale(Math.max(canvasScale - ZOOM_INCREMENT, MAX_ZOOM_OUT)));
  }, [canvasScale, dispatch]);

  useUnmount(() => {
    batch(() => {
      dispatch(MediaAction.setCanvasScale(1));
      dispatch(MediaAction.setMode(MediaMode.None));
    });
  });

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div className={styles.background} />
      <div className={styles.gradient} />
      <div className={styles.content}>
        {!previewRoom && (
          <>
            <div className={styles.buttonContainer}>
              <button onClick={onTokensClick} className={`button-reset ${styles.button}`}>
                <TokenIcon />
                Tokens
              </button>
            </div>

            <div className={`${styles.buttonContainer} ${styles.drawContainer} ${isDrawing ? styles.isActive : ''}`}>
              <button onClick={onDrawClick} className={`button-reset ${styles.button}`}>
                <span className={styles.buttonGlow} />
                <PenIcon />
                <span>Draw</span>
              </button>

              <div className={styles.colors}>
                <button
                  onClick={() => onColorClick(Color.RED)}
                  className={`button-reset text-overflow ${styles.color} ${styles.isRed} ${
                    isDrawing && penColor === Color.RED ? styles.isActive : ''
                  }`}
                >
                  Red
                </button>
                <button
                  onClick={() => onColorClick(Color.GREEN)}
                  className={`button-reset text-overflow ${styles.color} ${styles.isGreen} ${
                    isDrawing && penColor === Color.GREEN ? styles.isActive : ''
                  }`}
                >
                  Green
                </button>
                <button
                  onClick={() => onColorClick(Color.TEAL)}
                  className={`button-reset text-overflow ${styles.color} ${styles.isTeal} ${
                    isDrawing && penColor === Color.TEAL ? styles.isActive : ''
                  }`}
                >
                  Teal
                </button>
                <button
                  onClick={() => onColorClick(Color.ORANGE)}
                  className={`button-reset text-overflow ${styles.color} ${styles.isOrange} ${
                    isDrawing && penColor === Color.ORANGE ? styles.isActive : ''
                  }`}
                >
                  Orange
                </button>
              </div>
            </div>

            <div className={`${styles.buttonContainer} ${isDeleting ? styles.isActive : ''}`}>
              <button onClick={onDeleteClick} className={`button-reset ${styles.button}`}>
                <span className={styles.buttonGlow} />
                <TrashIcon />
                Delete
              </button>
            </div>
          </>
        )}

        <div className={styles.buttonContainer}>
          <div className={styles.zoomContainer}>
            <button onClick={onZoomInClick} className={`button-reset ${styles.button} ${styles.zoomButton}`}>
              <PlusIcon />
            </button>
            <button onClick={onZoomOutClick} className={`button-reset ${styles.button} ${styles.zoomButton}`}>
              <MinusIcon />
            </button>
          </div>
          <span>Zoom</span>
        </div>
      </div>

      {!tokenAssetToEdit && showAddTokenModal && (
        <AddTokenModal room={room} asset={asset} roomDocument={roomDocument} onDismiss={onAddTokenModalDismiss} />
      )}
    </div>
  );
}
