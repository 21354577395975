import styles from './VideoSettingsModal.module.css';

import { Component, createRef } from 'react';
import { LocalAudioTrack } from 'twilio-video';

const MAX_LEVEL = 100;

class MicLevel extends Component<{ track?: LocalAudioTrack; start?: boolean }> {
  audioContext: AudioContext | null = null;
  timer: number | undefined;
  loadingBarRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.audioContext = new AudioContext();
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    if (this.audioContext?.state === 'running') this.audioContext.suspend();
  }

  setup() {
    const { track } = this.props;
    if (this.audioContext && track) {
      const stream = new MediaStream([track.mediaStreamTrack]);
      this.audioContext.resume().then(() => {
        const analyser = this.createAnalyser(stream);
        const samples = new Uint8Array(analyser.frequencyBinCount);
        const streamTrack = stream.getTracks()[0];
        let level: number | null = null;

        if (this.timer) clearInterval(this.timer);

        this.timer = window.setInterval(() => {
          analyser.getByteFrequencyData(samples);

          const rms = this.rootMeanSquare(samples);
          const log2Rms = rms && Math.log2(rms);
          const newLevel = Math.ceil((MAX_LEVEL * log2Rms) / 8);

          if (level !== newLevel) {
            level = newLevel <= 0 ? 0 : newLevel;
            if (this.loadingBarRef.current)
              this.loadingBarRef.current.style.clipPath = `polygon(0 0, ${level}% 0, ${level}% 100%, 0 100%)`;
          }

          if (streamTrack.readyState === 'ended') {
            if (this.loadingBarRef.current) this.loadingBarRef.current.style.clipPath = 'polygon(0 0, 0 0, 0 0, 0 0)';
            clearInterval(this.timer);
          }
        }, 50);
      });
    }
  }

  createAnalyser(stream: MediaStream) {
    const analyser = this.audioContext!.createAnalyser();
    analyser.fftSize = 1024;
    analyser.smoothingTimeConstant = 0.6;
    const source = this.audioContext!.createMediaStreamSource(stream);
    source.connect(analyser);
    return analyser;
  }

  rootMeanSquare(samples: Uint8Array) {
    const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
    return Math.sqrt(sumSq / samples.length);
  }

  render() {
    return (
      <div className={styles.levelsContainer}>
        <div ref={this.loadingBarRef} className={styles.levels} />
      </div>
    );
  }
}

export default MicLevel;
