// @flow
import styles from './Sheet.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { memo, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import type { OnChangeAvatar } from 'components/Sheet2/types';
import useFontSize from 'hooks/useFontSize';
import useWindowSize from 'hooks/useWindowSize';
import { RoomSheetAction, SheetAction, TableAction } from 'store/actions';
import { SessionSelector, TableSelector } from 'store/selectors';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import MoreMenuButton from 'components/buttons/MoreMenuButton';
import GradientDot from 'components/GradientDot';
import type { MenuItemProps } from 'components/MenuList/MenuList';
import { ReactComponent as HidePanelIcon } from 'images/icons/HidePanelIcon.svg';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';

type Props = {
  id: string,
  avatarUrl: ?string,
  canCollapse: boolean,
  canEdit: boolean,
  colors: string[],
  isDdbSheet?: boolean,
  isDefaultAvatar: boolean,
  isCollapsed: boolean,
  isMenuActive: boolean,
  isPreview: boolean,
  menuItems?: ?(MenuItemProps[]),
  name: string,
  onChangeAvatar: OnChangeAvatar,
  onChangeCollapse: (boolean) => void,
  onChangeName: (string) => void,
  onMenuClick?: (boolean) => void,
  userName: string,
  theme?: any,
};

const FontSize = {
  '60': 20,
  '90': 16,
};

const COLLAPSED_HEADER_SIZE = 60;
const HEADER_SIZE = 146;
const SM_HEADER_SIZE = 50;

function Header(props: Props): Node {
  const {
    id,
    avatarUrl,
    canCollapse,
    canEdit,
    colors,
    isDdbSheet,
    isDefaultAvatar,
    isCollapsed,
    isMenuActive,
    isPreview,
    menuItems,
    name,
    onChangeAvatar: propOnChangeAvatar,
    onChangeCollapse,
    onChangeName,
    onMenuClick,
    userName,
    theme,
  } = props;

  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector((state) => (isPreview ? SessionSelector.currentUser(state) : null));
  const previewSheet = useSelector((state) => (isPreview ? TableSelector.getPreviewSheet(state) : null));
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const fontSizes = size.isMd
    ? {
        '60': 20,
        '90': 16,
      }
    : {
        '99': 16,
      };
  const nameFontSize = useFontSize(inputRef.current ? inputRef.current.value.length : '', fontSizes);

  useEffect(() => {
    if (!inputRef.current) return;
    if (isEditingName) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditingName]);

  const classNames = [styles.header];
  if (canEdit) classNames.push(styles.canEdit);
  if (isEditingName) classNames.push(styles.isEditingName);
  if (isDdbSheet) classNames.push(styles.ddbSheet);
  const inputTitle = 'Sheet Name';
  const avatarSize = isCollapsed ? COLLAPSED_HEADER_SIZE : size.isMd ? HEADER_SIZE : SM_HEADER_SIZE;

  const onChangeAvatar = (file) => {
    setIsUploadingAvatar(true);
    propOnChangeAvatar(id, file, () => setIsUploadingAvatar(false));
  };

  const onNameBlur = (event) => {
    setIsEditingName(false);
    const value = event.currentTarget.value.trim();
    onChangeName(value);
  };

  const onNameClick = () => {
    if (!canEdit) return;
    setIsEditingName(true);
  };

  const onNameKeyDown = (event) => {
    if (event.keyCode === 13) inputRef.current.blur();
  };

  const onPlayClick = () => {
    if (!previewSheet) return;
    const {
      sheet,
      room_guid,
      playkit,
      product,
      isTemplate,
      is_playkit_template,
      is_game_template,
      game_id,
      game_title,
      product_ids,
    } = previewSheet;
    let payload = {
      sheet: {
        name: sheet.name,
        sheet_template_id: isTemplate ? sheet.id : sheet.sheetTemplateId,
        data: sheet.data,
      },
      room_guid,
      playkit,
      product,
    };
    if (isTemplate) {
      payload = { ...payload, is_game_template, is_playkit_template, game_id, game_title, product_ids };
    }

    setIsLoading(true);
    dispatch(
      SheetAction.create(payload, ({ id }) => {
        batch(() => {
          dispatch(TableAction.setPreviewSheet(null));
          dispatch(RoomSheetAction.fetchAll(room_guid));
          dispatch(TableAction.addSheet(false));
          dispatch(TableAction.setActiveSheetUser(currentUser.id));
          dispatch(TableAction.setActiveSheet(id));
        });
        setIsLoading(false);
      })
    );
  };

  return (
    <header className={classNames.join(' ')}>
      {isDdbSheet ? (
        <div className={styles.ddbHeader}>
          <div>
            Imported character sheets are <strong>read only</strong>. To edit, open Beyond in a separate tab.{' '}
            <strong>Search and menu buttons are disabled.</strong>
          </div>
        </div>
      ) : (
        <>
          <Avatar
            className={styles.avatar}
            shape="squircle"
            avatarUrl={avatarUrl}
            avatarAlt={`${name}'s Avatar'`}
            borderColors={colors}
            size={avatarSize}
            isDdbSheet={isDdbSheet}
            showSheetDefault
            isLoading={isUploadingAvatar}
            canEdit={canEdit && !isCollapsed}
            canDelete={canEdit && !isDefaultAvatar}
            onChange={onChangeAvatar}
          />
          <div className={styles.headerMeta}>
            <div className={clsx(styles.headerMetaContent, isPreview && styles.headerOptions)}>
              <div className={styles.nameContainer} onClick={onNameClick}>
                <IconButton
                  className={styles.editButton}
                  label="Edit Name"
                  color="rgb(var(--color-sheet-text))"
                  background="none"
                  activeBackground="none"
                  children={<PenIcon />}
                />
                {canEdit && (isEditingName || name === '') ? (
                  <TextareaAutosize
                    ref={inputRef}
                    className={`heading1 ${styles.nameInput}`}
                    style={{ fontSize: nameFontSize }}
                    value={name}
                    placeholder="Enter a name"
                    title={inputTitle}
                    aria-label={inputTitle}
                    onBlur={onNameBlur}
                    onChange={(e) => onChangeName(e.currentTarget.value)}
                    onKeyDown={onNameKeyDown}
                  />
                ) : (
                  <h2 className={`heading1 ${styles.name}`} style={{ fontSize: nameFontSize }}>
                    {name}
                  </h2>
                )}
              </div>
              <div className={`heading2 ${styles.status}`} style={{ color: 'rgb(var(--color-sheet-text))' }}>
                <GradientDot className={styles.dot} colors={colors} />
                {userName}
              </div>
            </div>
            {isPreview && (
              <div className={styles.previewActions}>
                <Button
                  variant="cancel"
                  color="var(--color-white)"
                  hoverColor="var(--color-dark-text)"
                  onClick={() => dispatch(TableAction.setPreviewSheet(null))}
                  className={styles.cancelButton}
                >
                  Back
                </Button>
                {!previewRoom && (
                  <Button
                    className={styles.playButton}
                    primaryBackground="rgb(var(--color-sheet-accent))"
                    onClick={onPlayClick}
                    isLoading={isLoading}
                  >
                    Play
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {canCollapse && (
        <IconButton
          className={[styles.togglePanel, isCollapsed ? styles.isActive : ''].join(' ')}
          color="rgb(var(--color-sheet-text))"
          background="rgb(var(--color-sheet-button))"
          activeBackground="rgb(var(--color-sheet-button))"
          hoverBackground="rgb(var(--color-sheet-accent))"
          label="Toggle Panel"
          children={<HidePanelIcon />}
          activeChildren={<HidePanelIcon className={styles.isCollapsedActive} />}
          isActive={isCollapsed}
          onClick={() => onChangeCollapse(!isCollapsed)}
        />
      )}
      {menuItems && (
        <MoreMenuButton
          className={clsx(styles.settings, isMenuActive && styles.isActive)}
          items={menuItems}
          isActive={isMenuActive}
          onClick={onMenuClick}
          variant="theme"
          theme={theme}
          menuAlignment="right-start"
          buttonProps={{
            className: styles.settingsButton,
            color: 'rgb(var(--color-sheet-text))',
            background: 'rgb(var(--color-sheet-button))',
            activeBackground: 'rgb(var(--color-sheet-button))',
            hoverBackground: 'rgb(var(--color-sheet-accent))',
          }}
        />
      )}
    </header>
  );
}

Header.defaultProps = {
  canCollapse: false,
  canEdit: false,
  isCollapsed: false,
  isDdbSheet: false,
  isMenuActive: false,
  isPreview: false,
};

export default memo(Header);
