import _ from 'lodash'
import { CardView, ShuffleMode } from 'models/Card';
import CardAction from 'store/actions/CardAction';
import RoomAction from 'store/actions/RoomAction';

import standard52Cards from 'data/decks/standard52';
import standard54Cards from 'data/decks/standard54';
// import swordsfallTarotCards from '../data/decks/swordsfallTarot'
// TODO: this will eventually load from server
// TODO: instead of isTarot give decks sizes / aspect ratios
const decks = [
  { id: 'standard52', name: 'Standard 52', cards: standard52Cards, isTarot: false },
  { id: 'standard54', name: 'Standard 54', cards: standard54Cards, isTarot: false },
  // { id: 'swordsfallTarot', name: 'Swordsfall Tarot', cards: swordsfallTarotCards, isTarot: true },
]

const INITIAL_STATE = {
  templates: _.mapKeys(decks, 'id'),
  decks: {},
  currentDeck: null,
  currentView: CardView.PLAYER,
  isShuffling: false,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case CardAction.CANCEL_SHUFFLE:
      return { ...state, isShuffling: false }

    case CardAction.CONFIRM_SHUFFLE:
      return shuffleDeckState(state, payload)

    case CardAction.DISCARD_CARD:
      return discardCardState(state, payload)

    case CardAction.DRAW_CARD:
      return drawCardState(state, payload)

    case CardAction.DRAW_DISCARDED_CARD:
      return drawDiscardedCardState(state, payload)

    case CardAction.FLIP_CARD:
      return flipCardState(state, payload)

    case CardAction.ROTATE_CARD:
      return rotateCardState(state, payload)

    case CardAction.SELECT_DECK:
      // return selectDeckState(state, payload)
      return { ...state, currentDeck: payload }

    case CardAction.SELECT_SHUFFLE:
      return { ...state, isShuffling: true }

    case CardAction.SELECT_VIEW:
      return { ...state, currentView: payload }

    case RoomAction.CREATE_DECK:
    case RoomAction.FETCH_DECK:
    case RoomAction.UPDATE_DECK:
      return { ...state, decks: { ...state.decks, [payload.deckId]: payload } }

    case RoomAction.FETCH_DECKS:
      return { ...state, decks: { ..._.mapKeys(payload, 'deckId') } }

    default:
      return state
  }
}

const shuffle = cards => {
  return _.shuffle(cards).map((card, i) => {
    return { ...card, updatedAt: Date.now() + i }
  })
}

const discardCardState = (state, payload) => {
  const { deck, card } = getDeckAndCardState(state, payload)
  card.isDrawn = false
  card.isDiscarded = true
  card.userId = null
  card.isFlipped = false
  card.updatedAt = Date.now()
  return updateCardState(state, deck, card)
}

const drawCardState = (state, payload) => {
  const { userId } = payload
  const { deck, card } = getDeckAndCardState(state, payload)
  card.isDrawn = true
  card.userId = userId
  card.updatedAt = Date.now()
  return updateCardState(state, deck, card)
}

const drawDiscardedCardState = (state, payload) => {
  const { userId } = payload
  const { deck, card } = getDeckAndCardState(state, payload)
  card.isDrawn = true
  card.isDiscarded = false
  card.userId = userId
  card.updatedAt = Date.now()
  return updateCardState(state, deck, card)
}

const flipCardState = (state, payload) => {
  const { deck, card } = getDeckAndCardState(state, payload)
  card.isFlipped = !card.isFlipped
  return updateCardState(state, deck, card)
}

const getDeckAndCardState = (state, payload) => {
  const { deckId, cardId } = payload
  const deck = state.decks[deckId]
  const card = { ...deck.cards[cardId] }
  return { deck, card }
}

const rotateCardState = (state, payload) => {
  const { deck, card } = getDeckAndCardState(state, payload)
  card.isRotated = !card.isRotated
  return updateCardState(state, deck, card)
}

// const selectDeckState = (state, payload) => {
//   const newState = { ...state, currentDeck: payload }
//   const deck = state.decks[payload]
//
//   if (Object.values(deck.cards).length === 0) {
//     let cards = state.templates[payload].cards.map(card => {
//       const { name, value, suit, image } = card
//       const id = `${deck.id}-${suit ? suit : 'tarot'}-${value}`
//       const isFlipped = false
//       const isRotated = Math.random() >= 0.5
//       return new Card({ id, name, value, suit, image, isFlipped, isRotated })
//     })
//     cards = shuffle(cards)
//
//     return { ...newState, decks: { ...state.decks, [payload]: { ...deck, cards: _.mapKeys(cards, 'id') } } }
//   }
//
//   return newState
// }

const shuffleDeckState = (state, payload) => {
  const newState = { ...state, isShuffling: false }
  const deck = state.decks[state.currentDeck]
  const shuffleDeck = payload[ShuffleMode.DECK]
  const shuffleDiscard = payload[ShuffleMode.DISCARD]
  const shuffleTable = payload[ShuffleMode.TABLE]
  const shufflePlayer = payload[ShuffleMode.PLAYER]
  let cards = Object.values(deck.cards)
  let deckCards, discardedCards, tableCards, playerCards, mergedCards

  if (shuffleDeck && !shuffleDiscard) {
    if (shuffleTable && !shufflePlayer) {
      // Shuffling table into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      tableCards = cards
        .filter(c => c.isDrawn && !c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false }
        })
      mergedCards = shuffle([...deckCards, ...tableCards])
      cards = [...cards, ...mergedCards]
    } else if (!shuffleTable && shufflePlayer) {
      // Shuffling players into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      playerCards = cards
        .filter(c => c.isDrawn && c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, userId: null }
        })
      mergedCards = shuffle([...deckCards, ...playerCards])
      cards = [...cards, ...mergedCards]
    } else if (shuffleTable && shufflePlayer) {
      // Shuffling table and players into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      tableCards = cards
        .filter(c => c.isDrawn && !c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false }
        })
      playerCards = cards
        .filter(c => c.isDrawn && c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, userId: null }
        })
      mergedCards = shuffle([...deckCards, ...tableCards, ...playerCards])
      cards = [...cards, ...mergedCards]
    } else {
      // Shuffling only deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      deckCards = shuffle(deckCards)
      cards = [...cards, ...deckCards]
    }
  } else if (!shuffleDeck && shuffleDiscard) {
    if (shuffleTable && !shufflePlayer) {
      // Shuffling table into discard
      discardedCards = cards.filter(c => c.isDiscarded)
      tableCards = cards
        .filter(c => c.isDrawn && !c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, isDiscarded: true }
        })
      mergedCards = shuffle([...discardedCards, ...tableCards])
      cards = [...cards, ...mergedCards]
    } else if (!shuffleTable && shufflePlayer) {
      // Shuffling players into discard
      discardedCards = cards.filter(c => c.isDiscarded)
      playerCards = cards
        .filter(c => c.isDrawn && c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, userId: null, isDiscarded: true }
        })
      mergedCards = shuffle([...discardedCards, ...playerCards])
      cards = [...cards, ...mergedCards]
    } else if (shuffleTable && shufflePlayer) {
      // Shuffling table and players into discard
      discardedCards = cards.filter(c => c.isDiscarded)
      tableCards = cards
        .filter(c => c.isDrawn && !c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, isDiscarded: true }
        })
      playerCards = cards
        .filter(c => c.isDrawn && c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, userId: null, isDiscarded: true }
        })
      mergedCards = shuffle([...discardedCards, ...tableCards, ...playerCards])
      cards = [...cards, ...mergedCards]
    } else {
      // Shuffling only discard
      discardedCards = cards.filter(c => c.isDiscarded)
      discardedCards = shuffle(discardedCards)
      cards = [...cards, ...discardedCards]
    }
  } else if (shuffleDeck && shuffleDiscard) {
    if (shuffleTable && !shufflePlayer) {
      // Shuffling table and discard into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      discardedCards = cards
        .filter(c => c.isDiscarded)
        .map(card => {
          return { ...card, isDiscarded: false }
        })
      tableCards = cards
        .filter(c => c.isDrawn && !c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false }
        })
      mergedCards = shuffle([...deckCards, ...discardedCards, ...tableCards])
      cards = [...cards, ...mergedCards]
    } else if (!shuffleTable && shufflePlayer) {
      // Shuffling players and discard into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      discardedCards = cards
        .filter(c => c.isDiscarded)
        .map(card => {
          return { ...card, isDiscarded: false }
        })
      playerCards = cards
        .filter(c => c.isDrawn && c.userId)
        .map(card => {
          return { ...card, isFlipped: false, isDrawn: false, userId: null }
        })
      mergedCards = shuffle([...deckCards, ...discardedCards, ...playerCards])
      cards = [...cards, ...mergedCards]
    } else if (shuffleTable && shufflePlayer) {
      // Shuffling table, players, and discard into deck
      cards = shuffle(cards).map(card => {
        return { ...card, isFlipped: false, isDiscarded: false, isDrawn: false, userId: null }
      })
    } else {
      // Shuffling discard into deck
      deckCards = cards.filter(c => !c.isDrawn && !c.isDiscarded)
      discardedCards = cards
        .filter(c => c.isDiscarded)
        .map(card => {
          return { ...card, isDiscarded: false }
        })
      mergedCards = shuffle([...deckCards, ...discardedCards])
      cards = [...cards, ...mergedCards]
    }
  }

  return { ...newState, decks: { ...state.decks, [state.currentDeck]: { ...deck, cards: _.mapKeys(cards, 'id') } } }
}

const updateCardState = (state, deck, card) => {
  const cards = { ...deck.cards, [card.id]: card }
  return { ...state, decks: { ...state.decks, [deck.deckId]: { ...deck, cards } } }
}
