// @flow
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import type { InputModel, Link } from 'components/Sheet2/types';
import usePrevious from 'hooks/usePrevious';
import { SheetAction } from 'store/actions/';
import { SheetSelector, TableSelector } from 'store/selectors';
import { findInputPath } from 'utilities/sheet';

export default function useToggleLinks(sheetId: string, toggleInput: ?InputModel, links: Link[]) {
  const prevIsToggled = usePrevious(!!toggleInput && toggleInput.value);
  const sheet = useSelector((state) => SheetSelector.get(state, sheetId));
  const roomGuid = useSelector(TableSelector.getRoomGuid);
  const dispatch = useDispatch();

  const debouncedUpdateElement = useCallback(
    _.debounce((id, sectionId, element) => dispatch(SheetAction.updateElement(id, sectionId, element, roomGuid)), 300),
    [dispatch, roomGuid]
  );

  const debouncedUpdateGroupElement = useCallback(
    _.debounce(
      (id, sectionId, groupId, element) =>
        dispatch(SheetAction.updateGroupElement(id, sectionId, groupId, element, roomGuid)),
      300
    ),
    [dispatch, roomGuid]
  );

  const onChange = useCallback(
    (id, sectionId, element) => {
      batch(() => {
        dispatch(SheetAction.changeElement(id, sectionId, element));
        debouncedUpdateElement(id, sectionId, element);
      });
    },
    [debouncedUpdateElement, dispatch]
  );

  const onChangeGroup = useCallback(
    (id, sectionId, groupId, element) => {
      batch(() => {
        dispatch(SheetAction.changeGroupElement(id, sectionId, groupId, element));
        debouncedUpdateGroupElement(id, sectionId, groupId, element);
      });
    },
    [debouncedUpdateGroupElement, dispatch]
  );

  useEffect(() => {
    if (sheet && toggleInput && toggleInput.value && !prevIsToggled) {
      links
        .filter((o) => !!o.elementId && !!o.inputId)
        .forEach(({ inputId }) => {
          const { section, element, groupElement, input } = findInputPath(sheet.data.sections, inputId);
          if (!section || !element || !input) return;
          if (groupElement) {
            if (!!element.metadata.collection) {
              const updatedCollection = element.metadata.collection.map((o) => {
                if (o.items.find((e) => e.id === groupElement.id)) {
                  return {
                    ...o,
                    items: o.items.map((e) => {
                      if (e.id === groupElement.id) {
                        return { ...e, items: e.items.map((ei) => (ei.id === input.id ? { ...ei, value: true } : ei)) };
                      }
                      return e;
                    }),
                  };
                }
                return o;
              });
              const updatedGroup = { ...element, metadata: { ...element.metadata, collection: updatedCollection } };
              onChange(sheetId, section.id, updatedGroup);
            } else {
              const updatedItems = groupElement.items.map((o) => (o.id === input.id ? { ...o, value: true } : o));
              onChangeGroup(sheetId, section.id, element.id, { ...groupElement, items: updatedItems });
            }
          } else {
            const updatedItems = element.items.map((o) => (o.id === input.id ? { ...o, value: true } : o));
            onChange(sheetId, section.id, { ...element, items: updatedItems });
          }
        });
    }
  }, [links, onChange, onChangeGroup, prevIsToggled, sheet, sheetId, toggleInput]);
}
