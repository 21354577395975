import { useCallback, useEffect, useState } from 'react';

import { getDeviceInfo } from 'video/utils';

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState<Awaited<ReturnType<typeof getDeviceInfo>>>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  });

  const getDevices = useCallback(() => getDeviceInfo().then((devices) => setDeviceInfo(devices)), []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, [getDevices]);

  return [deviceInfo, getDevices] as const;
}
