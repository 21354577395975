import styles from './MobileDisabledPage.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import SessionAction from 'store/actions/SessionAction';

import SessionPage from 'components/layouts/SessionPage';

class MobileDisabledPage extends Component {
  onLogout = () => {
    this.props.signOut();
  };

  render() {
    return (
      <SessionPage title="Role">
        <h3 className={`heading2 is-centered ${styles.title}`}>Desktop / Tablet Required</h3>
        <p>This page is not currently supported on mobile browers. Please log in on a Desktop or Tablet to continue.</p>
        <button onClick={this.onLogout} className={`button-reset ${styles.button}`}>
          Logout
        </button>
      </SessionPage>
    );
  }
}

const mapDispatchToProps = {
  signOut: SessionAction.signOut,
};

export default connect(null, mapDispatchToProps)(MobileDisabledPage);
