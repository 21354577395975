import { cloneDeep, mapKeys, merge, omit, uniq } from 'lodash';

import { ProductAction, RoomAction, SheetAction } from 'store/actions';

const INITIAL_STATE = {
  editId: null,
  editing: {},
  menuId: null,
  models: {},
  removeSheetId: null,
  transferId: null,
  updateSheetId: null,
};

export default function sheetReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case SheetAction.CONFIRM_TRANSFER:
      return { ...state, transferId: payload };

    case SheetAction.COPY:
    case SheetAction.CREATE:
    case SheetAction.FETCH:
    case SheetAction.TRANSFER:
    case SheetAction.UPDATE:
    case SheetAction.UPDATE_LOCAL:
    case SheetAction.UPDATE_TEMPLATE:
      return { ...state, models: { ...state.models, [payload.id]: payload } };

    case SheetAction.DELETE:
      return { ...state, models: omit(state.models, payload) };

    case SheetAction.EDIT:
      return { ...state, editId: payload };

    case SheetAction.FETCH_ALL:
    case RoomAction.FETCH_PLAYKIT_SHEETS:
    case ProductAction.FETCH_SHEETS:
      return { ...state, models: merge({}, state.models, mapKeys(payload, 'id')) };

    case SheetAction.INPUT_BLURRED:
      return inputBlurredState(state, payload);

    case SheetAction.INPUT_FOCUSED:
      return inputFocusedState(state, payload);

    case SheetAction.UPDATE_INPUT:
      return {
        ...state,
        models: {
          ...state.models,
          [payload.sheet.id]: { ...cloneDeep(state.models[payload.sheet.id]), updatedAt: payload.sheet.updatedAt },
        },
      };

    case SheetAction.UPDATE_LOCAL_INPUT:
      const current = cloneDeep(state.models[payload.sheet.id]);
      current.data.sections[payload.sectionId].blocks[payload.blockId].inputs[payload.input.id] = payload.input;
      return { ...state, models: { ...state.models, [payload.sheet.id]: current } };

    case SheetAction.REQUEST_REMOVE:
      return { ...state, removeSheetId: payload };

    case SheetAction.REQUEST_UPDATE:
      return { ...state, updateSheetId: payload };

    case SheetAction.REMOVE:
      return { ...state, models: omit(state.models, payload.id) };

    case SheetAction.SHOW_MENU:
      return { ...state, menuId: payload };

    case SheetAction.CHANGE:
      return { ...state, models: { ...state.models, [payload.id]: payload } };

    case SheetAction.CHANGE_ELEMENT:
      return changeElement(state, payload);

    case SheetAction.CHANGE_GROUP_ELEMENT:
      return changeGroupElement(state, payload);

    case SheetAction.CHANGE_SECTION:
      return changeSection(state, payload);

    default:
      return state;
  }
}

const changeElement = (state, payload) => {
  const { id, sectionId, element } = payload;
  const model = state.models[id];
  if (!model) return state;
  const section = model.data.sections.find((o) => o.id === sectionId);
  if (!section) return state;
  if (!section.elements.find((o) => o.id === element.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => {
            if (o.id === section.id) {
              return { ...section, elements: section.elements.map((e) => (e.id === element.id ? element : e)) };
            }
            return o;
          }),
        },
      },
    },
  };
};

const changeGroupElement = (state, payload) => {
  const { id, sectionId, groupId, element } = payload;
  const model = state.models[id];
  if (!model) return state;
  const section = model.data.sections.find((o) => o.id === sectionId);
  if (!section) return state;
  const group = section.elements.find((o) => o.id === groupId);
  if (!group) return state;
  if (!group.items.find((o) => o.id === element.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => {
            if (o.id === section.id) {
              return {
                ...section,
                elements: section.elements.map((e) => {
                  if (e.id === groupId) {
                    return { ...e, items: e.items.map((ge) => (ge.id === element.id ? element : ge)) };
                  }
                  return e;
                }),
              };
            }
            return o;
          }),
        },
      },
    },
  };
};

const changeSection = (state, payload) => {
  const { id, section } = payload;
  const model = state.models[id];
  if (!model) return state;
  if (!model.data.sections.find((o) => o.id === section.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        data: {
          ...model.data,
          sections: model.data.sections.map((o) => (o.id === section.id ? section : o)),
        },
      },
    },
  };
};

const inputBlurredState = (state, payload) => {
  const { id, inputId } = payload;
  const model = state.editing[id] || [];
  return { ...state, editing: { ...state.editing, [id]: model.filter((o) => o !== inputId) } };
};

const inputFocusedState = (state, payload) => {
  const { id, inputId } = payload;
  const model = state.editing[id] || [];
  return { ...state, editing: { ...state.editing, [id]: uniq([...model, inputId]) } };
};
