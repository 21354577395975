import useDominantSpeaker from './useDominantSpeaker';
import useVideoContext from './useVideoContext';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

export default function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;

  return selectedParticipant || dominantSpeaker || localParticipant;
}
