//@flow
import styles from './TemplateCard.module.css';

import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from 'browserHistory';
import type { TemplateModel } from 'components/Sheet2/types';
import { PRIVACY } from 'constants/template';
import useWindowSize from 'hooks/useWindowSize';
import { SheetTemplateAction } from 'store/actions';
import { SheetTemplateSelector } from 'store/selectors';
import SessionSelector from 'store/selectors/SessionSelector';
import { getDate, getDateAndTime, logEvent } from 'utilities';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { CloseButton } from 'components/buttons/IconButton';
import { ReactComponent as CloneIcon } from 'images/icons/CloneIcon.svg';
import { ReactComponent as ShareIcon } from 'images/icons/ShareIcon.svg';

type Props = {
  className?: ?string,
  template: TemplateModel,
  searchMeta?: {
    requestId: string,
    query: string,
  },
};

const avatarColors = ['var(--color-red)', 'var(--color-purple)'];

export default function TemplateCard({ className, searchMeta, template }: Props) {
  const {
    avatarUrl,
    guid,
    guidSlug,
    isPaid,
    isPublished,
    lastEditedAt,
    lastPublishedAt,
    name,
    privacy,
    ownerType,
    ownerId,
    ownerName,
    productIds,
  } = template;

  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector((state) => SessionSelector.currentUser(state));
  const isSaved = useSelector((state) => SheetTemplateSelector.isSaved(guid)(state));

  // TODO: Account for org members here when checking ownership
  const isOwner = ownerType === 'User' && currentUser.id === ownerId;
  const lastEditedDate = lastEditedAt ? new Date(lastEditedAt) : new Date();
  const publishedDate = isPublished && lastPublishedAt ? new Date(lastPublishedAt) : new Date();
  // Since publishing also updates edited_at, we check to make sure the difference between them is less than the time the rails server takes to update them
  const hasEditsBeenPublished = isPublished && lastEditedDate.getTime() - publishedDate.getTime() < 500;

  const onMainActionClick = () => {
    history.push(!isOwner ? `/sheet-templates/${guidSlug}}` : `/sheet-templates/${guidSlug}/edit`);
  };

  const onShareSheet = () => {
    dispatch(SheetTemplateAction.requestShare(guid));
  };

  const onDuplicateClick = () => {
    dispatch(
      SheetTemplateAction.duplicate(guidSlug, (newTemplate) => {
        logEvent('template - duplicated', { isCreator: isOwner });
        history.push(`/sheet-templates/${newTemplate.guidSlug}`);
      })
    );
  };

  const onDeleteTemplate = () => {
    dispatch(SheetTemplateAction.requestRemove(guid));
  };

  return (
    <div className={clsx(styles.templateCardWrapper, !size.isMd && styles.isSmall, className)}>
      {productIds.length === 0 && (
        <CloseButton
          color="var(--color-white)"
          activeColor="var(--color-white)"
          background="var(--color-light-main)"
          activeBackground="var(--color-light-dark)"
          className={styles.deleteButton}
          onClick={onDeleteTemplate}
          buttonSize={24}
        />
      )}
      <div className={styles.templateCard}>
        <Avatar
          borderColors={avatarColors}
          className={styles.avatar}
          avatarUrl={avatarUrl ?? null}
          shape="squircle"
          size={size.isMd ? 140 : 100}
          showSheetDefault
        />
        <div className={styles.templateCardInfo}>
          <div className={styles.header}>
            <div className={styles.templateInfo}>
              <h3 className={styles.name}>{name}</h3>
              <div className={styles.userAvatarContainer} title={ownerName}>
                <Avatar
                  borderColors={avatarColors}
                  borderSize={2}
                  className={styles.userAvatar}
                  avatarUrl={template.ownerImageUrl}
                  shape="circle"
                  size={24}
                  showPlayerDefault
                />
                <span className={styles.templateUsername}>{ownerName}</span>
              </div>
              <div className={styles.templateBody}>
                {!isOwner && (isSaved || isPaid) && (
                  <div className={styles.infoContainer}>
                    <label>Updated</label>
                    <span>{getDate(new Date(lastPublishedAt))}</span>
                  </div>
                )}
                {isOwner && (
                  <>
                    <div className={styles.infoContainer} title={getDateAndTime(new Date(lastPublishedAt))}>
                      <label>Published</label>
                      <span>{isPublished ? getDate(new Date(lastPublishedAt)) : 'No'}</span>
                    </div>
                    <div
                      className={styles.infoContainer}
                      title={
                        lastEditedAt
                          ? `${getDateAndTime(lastEditedDate)}${hasEditsBeenPublished ? '' : ' (Unpublished)'}`
                          : undefined
                      }
                    >
                      <label>Edited</label>
                      <span>{lastEditedAt ? getDate(lastEditedDate) : 'N/A'}</span>
                    </div>
                    <div className={clsx(styles.infoContainer, styles.publicContainer)}>
                      <label title="Public templates will be searchable by others for use in their games">Public</label>
                      <span>{privacy === PRIVACY.public ? 'Yes' : 'No'}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.templateActions}>
              <Button
                className={styles.editButton}
                primaryBackground={'var(--color-white)'}
                color={'var(--color-dashboard-mid)'}
                onClick={onMainActionClick}
                hoverColor={'var(--color-white)'}
                hoverPrimaryBackground={'var(--color-accent-main)'}
              >
                {!isOwner ? 'View' : 'Edit'}
              </Button>
              <Button
                className={styles.templateActionButton}
                primaryBackground={'var(--color-light-light)'}
                color={'var(--color-dashboard-mid)'}
                onClick={onShareSheet}
                hoverColor={'var(--color-white)'}
                hoverPrimaryBackground={'var(--color-primary-dark)'}
                icon={<ShareIcon />}
                isSimpleIcon
                isSmall
              >
                Share
              </Button>
              <Button
                className={styles.templateActionButton}
                primaryBackground={'var(--color-light-light)'}
                color={'var(--color-light-dark)'}
                onClick={onDuplicateClick}
                hoverColor={'var(--color-white)'}
                hoverPrimaryBackground={'var(--color-primary-dark)'}
                icon={<CloneIcon />}
                isSimpleIcon
                isSmall
              >
                Duplicate
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateCard.defaultProps = {
  isSavedTemplate: false,
};
