import styles from './AudioVideoControls.module.css';

import { Popover, Portal } from '@headlessui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { batch, useDispatch, useSelector } from 'react-redux';
import { TwilioError } from 'twilio-video';

import usePrevious from 'hooks/usePrevious';
import useUnmount from 'hooks/useUnmount';
import useWindowSize from 'hooks/useWindowSize';
import { ErrorAction, GeneralAction, TableAction } from 'store/actions';
import { ErrorSelector, GeneralSelector, SessionSelector, TableSelector, TwilioSelector } from 'store/selectors';
import { TableViewMode } from 'types/room';
import { DeviceError } from 'types/video';
import { isMobile } from 'utilities';
import { stylizeTheme } from 'utilities/color';
import useLocalAudioToggle from 'video/hooks/useLocalAudioToggle';
import useLocalVideoToggle from 'video/hooks/useLocalVideoToggle';
import useRoomState from 'video/hooks/useRoomState';
import useVideoContext from 'video/hooks/useVideoContext';

import IconButton from 'components/buttons/IconButton';
import MediaError, { createDeviceError } from 'components/errors/MediaError';
import PopoverError from 'components/errors/PopoverError';
import LoadingSpinner from 'components/LoadingSpinner';
import MenuList from 'components/MenuList';
import VideoSettingsModal from 'components/modals/VideoSettingsModal';
import { ReactComponent as GridViewIcon } from 'images/icons/GridViewIcon.svg';
import { ReactComponent as MoreIcon } from 'images/icons/MoreIcon.svg';
import { ReactComponent as ScreenshareOffIcon } from 'images/icons/ScreenshareOffIcon.svg';
import { ReactComponent as ScreenshareOnIcon } from 'images/icons/ScreenshareOnIcon.svg';
import { ReactComponent as SoundOffIcon } from 'images/icons/SoundOffIcon.svg';
import { ReactComponent as SoundOnIcon } from 'images/icons/SoundOnIcon.svg';
import { ReactComponent as SpeakerViewIcon } from 'images/icons/SpeakerViewIcon.svg';
import { ReactComponent as VideoOffIcon } from 'images/icons/VideoOffIcon.svg';
import { ReactComponent as VideoOnIcon } from 'images/icons/VideoOnIcon.svg';
import PatronFeatureModal from 'modals/PatronFeatureModal';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

interface Props {
  room: any; // TODO: Type a Game Room
}

const VIDEO_ERROR_KEY = 'video_error';
const AUDIO_ERROR_KEY = 'mic_error';

export default function AudioVideoControls({ room }: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const deviceSettings = useSelector(TwilioSelector.getSettings);
  const prevDeviceSettings = usePrevious(deviceSettings);

  const viewMode = useSelector(TableSelector.getViewMode);

  const showVideoSettingsModal = useSelector(TableSelector.showVideoSettingsModal);

  const { isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const isLoading = isAcquiringLocalTracks || isConnecting;
  const videoRoomState = useRoomState();
  const [, setSelectedParticipant] = useSelectedParticipant();

  // @ts-ignore need to create a useAppSelector wth proper types when we get to that
  const videoError = useSelector((state) => ErrorSelector.get(state, VIDEO_ERROR_KEY));
  // @ts-ignore need to create a useAppSelector wth proper types when we get to that
  const audioError = useSelector((state) => ErrorSelector.get(state, AUDIO_ERROR_KEY));

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(
    useCallback(
      (error: TwilioError) => {
        console.dir(error);
        dispatch(ErrorAction.create(createDeviceError(VIDEO_ERROR_KEY, error.name as DeviceError, 'camera')));
      },
      [dispatch]
    )
  );
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(
    useCallback(
      (error: TwilioError) => {
        console.dir(error);
        dispatch(ErrorAction.create(createDeviceError(AUDIO_ERROR_KEY, error.name as DeviceError, 'microphone')));
      },
      [dispatch]
    )
  );

  const [shouldPublish, setShouldPublish] = useState(false);
  const [showPatronModal, setShowPatronModal] = useState(false);

  const onSettingsClick = useCallback(() => {
    dispatch(TableAction.showVideoSettings(true));
    setShouldPublish(false);
  }, [dispatch]);

  const onToggleCameraClick = useCallback(() => {
    if (!room.isHostPatron) {
      setShowPatronModal(true);
      return;
    }

    if (!deviceSettings.videoDevice) {
      setShouldPublish(true);
      onSettingsClick();
    } else toggleVideoEnabled();
  }, [deviceSettings.videoDevice, onSettingsClick, room.isHostPatron, toggleVideoEnabled]);

  const onToggleMicClick = useCallback(() => {
    if (!room.isHostPatron) {
      setShowPatronModal(true);
      return;
    }

    if (!deviceSettings.audioDevice) {
      setShouldPublish(true);
      onSettingsClick();
    } else toggleAudioEnabled();
  }, [deviceSettings.audioDevice, onSettingsClick, room.isHostPatron, toggleAudioEnabled]);

  const toggleViewMode = useCallback(() => {
    dispatch(
      TableAction.updateViewMode(viewMode === TableViewMode.Speaker ? TableViewMode.Grid : TableViewMode.Speaker)
    );
    if (viewMode === TableViewMode.Speaker) dispatch(TableAction.setActiveRoomDocument(null));
    setSelectedParticipant(null);
  }, [dispatch, setSelectedParticipant, viewMode]);

  // Publish track automatically when settings accessed via toggle button
  useEffect(() => {
    if (shouldPublish && (!isVideoEnabled || !isAudioEnabled)) {
      if (!isVideoEnabled && deviceSettings.videoDevice) toggleVideoEnabled();
      if (!isAudioEnabled && deviceSettings.audioDevice) toggleAudioEnabled();
      setShouldPublish(false);
    }
  }, [
    deviceSettings.audioDevice,
    deviceSettings.videoDevice,
    isAudioEnabled,
    isVideoEnabled,
    shouldPublish,
    toggleAudioEnabled,
    toggleVideoEnabled,
  ]);

  // If our device changes, unpublish and republish our tracks. If we remove a device, don't republish it.
  useEffect(() => {
    if (isAudioEnabled && prevDeviceSettings?.audioDevice !== deviceSettings.audioDevice) {
      toggleAudioEnabled(!!deviceSettings?.audioDevice);
    }
    if (isVideoEnabled && prevDeviceSettings?.videoDevice !== deviceSettings?.videoDevice) {
      toggleVideoEnabled(!!deviceSettings?.videoDevice);
    }
  }, [deviceSettings, isAudioEnabled, isVideoEnabled, prevDeviceSettings, toggleAudioEnabled, toggleVideoEnabled]);

  useUnmount(
    useCallback(() => {
      batch(() => {
        dispatch(ErrorAction.remove(VIDEO_ERROR_KEY));
        dispatch(ErrorAction.remove(AUDIO_ERROR_KEY));
      });

      if (isAudioEnabled) toggleAudioEnabled();
      if (isVideoEnabled) toggleVideoEnabled();
    }, [dispatch, isAudioEnabled, isVideoEnabled, toggleAudioEnabled, toggleVideoEnabled])
  );

  const [errorRef, setErrorRef] = useState<HTMLButtonElement | null>(null);

  if (!room) return null;

  return (
    <>
      {showPatronModal && (
        <PatronFeatureModal title="A/V Features are for Patrons" onDismiss={() => setShowPatronModal(false)} />
      )}
      {showVideoSettingsModal && <VideoSettingsModal />}
      {errorRef && (audioError || videoError) && (
        <PopoverError
          referenceElement={errorRef}
          onDismissError={() => {
            batch(() => {
              dispatch(ErrorAction.remove(VIDEO_ERROR_KEY));
              dispatch(ErrorAction.remove(AUDIO_ERROR_KEY));
            });
          }}
        >
          <MediaError audioError={audioError} videoError={videoError} />
        </PopoverError>
      )}
      <IconButton
        className={styles.controlButton}
        buttonSize={40}
        iconSize={20}
        activeSize={20}
        borderRadius={40}
        color="var(--color-white)"
        background="var(--color-teal)"
        activeBackground="var(--color-alert-red)"
        activeHover="var(--color-alert-red)"
        isDisabled={room.isHostPatron && (isLoading || videoRoomState === 'disconnected')}
        children={isLoading ? <LoadingSpinner size={18} color="var(--color-dark-text)" /> : <VideoOnIcon />}
        activeChildren={<VideoOffIcon />}
        isActive={!isLoading && !isVideoEnabled}
        onClick={onToggleCameraClick}
        label={!isLoading && !isVideoEnabled ? 'Turn On Camera' : 'Turn Off Camera'}
      />
      <IconButton
        className={styles.controlButton}
        ref={setErrorRef}
        buttonSize={40}
        iconSize={20}
        borderRadius={40}
        color="var(--color-white)"
        background="var(--color-teal)"
        activeBackground="var(--color-alert-red)"
        activeHover="var(--color-alert-red)"
        isDisabled={room.isHostPatron && (isLoading || videoRoomState === 'disconnected')}
        children={isLoading ? <LoadingSpinner size={18} /> : <SoundOnIcon />}
        activeChildren={<SoundOffIcon />}
        isActive={!isLoading && !isAudioEnabled}
        onClick={onToggleMicClick}
        label={!isLoading && !isAudioEnabled ? 'Turn On Mic' : 'Turn Off Mic'}
      />
      {!isMobile && room.userId === currentUser.id && <ScreenShareButton isHostPatron={room.isHostPatron} />}
      <IconButton
        className={styles.controlButton}
        buttonSize={40}
        iconSize={18}
        borderRadius={40}
        color="var(--color-white)"
        background="var(--color-dark-light)"
        children={viewMode === TableViewMode.Speaker ? <GridViewIcon /> : <SpeakerViewIcon />}
        onClick={toggleViewMode}
        label={viewMode === TableViewMode.Speaker ? 'Grid Mode' : 'Speaker Mode'}
      />
      <VideoControlsMenu onSettingsClick={onSettingsClick} room={room} />
    </>
  );
}

interface ScreenShareProps {
  disabled?: boolean;
  isHostPatron?: boolean;
}

function ScreenShareButton({ disabled = false, isHostPatron = false }: ScreenShareProps) {
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const [, setSelectedParticipant] = useSelectedParticipant();
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = disabled || !isScreenShareSupported;
  const [showPatronModal, setShowPatronModal] = useState(false);

  const onToggleScreenshareClick = useCallback(() => {
    if (!isHostPatron) {
      setShowPatronModal(true);
      return;
    }

    toggleScreenShare();
    setSelectedParticipant(null);
  }, [isHostPatron, setSelectedParticipant, toggleScreenShare]);

  return (
    <>
      {showPatronModal && (
        <PatronFeatureModal title="Screen sharing is for Patrons" onDismiss={() => setShowPatronModal(false)} />
      )}
      <IconButton
        className={styles.controlButton}
        buttonSize={40}
        iconSize={24}
        borderRadius={40}
        color="var(--color-white)"
        background="var(--color-alert-red)"
        activeBackground="var(--color-teal)"
        activeHover="var(--color-teal)"
        isDisabled={isDisabled}
        children={<ScreenshareOffIcon />}
        activeChildren={<ScreenshareOnIcon />}
        isActive={isSharingScreen}
        onClick={onToggleScreenshareClick}
        label={!isSharingScreen ? 'Share Screen' : 'Stop Sharing Screen'}
      />
    </>
  );
}

function VideoControlsMenu({ onSettingsClick, room }: { onSettingsClick: () => void; room: any }) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  // @ts-ignore TableSelector is not yet typed
  const theme = useSelector((state) => TableSelector.getTheme(state, room.guid));

  const isActive = useSelector(GeneralSelector.isShowingAudioVideoMenu);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const isFocusMode = useSelector(TableSelector.isFocusMode);

  const menuItems = useMemo(() => {
    const items = [];
    if (room.isHostPatron) {
      items.push({
        label: 'Audio & Video Settings',
        onClick: () => {
          onSettingsClick();
        },
      });
    }
    if (size.isMd) {
      items.push({
        label: 'Focus Mode',
        isActive: isFocusMode,
        onToggle: () => {
          dispatch(TableAction.setFocusMode(!isFocusMode));
        },
      });
    }
    return items;
  }, [dispatch, isFocusMode, onSettingsClick, room.isHostPatron, size.isMd]);

  const onClick = useCallback(() => {
    dispatch(GeneralAction.showAudioVideoMenu(!isActive));
  }, [dispatch, isActive]);

  return (
    <Popover className={styles.menuContainer}>
      <div className={styles.menuButton} ref={setReferenceElement} onClick={onClick}>
        <IconButton
          className={styles.controlButton}
          buttonSize={40}
          iconSize={18}
          borderRadius={40}
          color="var(--color-white)"
          background="var(--color-dark-light)"
          children={<MoreIcon />}
          label="Advanced"
        />
      </div>
      {isActive && (
        <Portal>
          <Popover.Panel
            static
            className={styles.popper}
            ref={setPopperElement}
            style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
            {...attributes.popper}
          >
            <MenuList className={styles.menu} items={menuItems} isActive={isActive} variant="theme" />
          </Popover.Panel>
        </Portal>
      )}
    </Popover>
  );
}
