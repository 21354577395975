import styles from './AddTokenModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Document from 'models/Document';
import { MapTokenAction } from 'models/Map';
import RoomDocumentAction from 'store/actions/RoomDocumentAction';
import DocumentSelector from 'store/selectors/DocumentSelector';
import SessionSelector from 'store/selectors/SessionSelector';

import Asset from 'components/Asset';
import { TitleModal } from 'components/Modal';

class AddTokenModal extends Component {
  onTokenClick = (t) => {
    const token = {
      id: uuid(),
      assetId: t.id,
      x: 0,
      y: 0,
    };
    const { room, asset, roomDocument, updateRoomDocumentToken, onDismiss } = this.props;
    const room_document = { token_action: MapTokenAction.ADD, token };
    updateRoomDocumentToken(room.guid, asset.id, { room_document, user_id: roomDocument.ownerId }, () => onDismiss());
  };

  renderTokens = () => {
    return this.props.tokens.map((asset) => {
      const doc = new Document(asset);
      return <Asset key={asset.id} asset={doc} onClick={this.onTokenClick} />;
    });
  };

  render() {
    const { onDismiss } = this.props;
    return (
      <TitleModal title="Tokens" subtitle="Click any token to drop it on this map or image:" onDismiss={onDismiss}>
        <div className={styles.tokens}>{this.renderTokens()}</div>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const currentUser = SessionSelector.currentUser(state);
  const getTokensByRoomAndUser = DocumentSelector.getTokensByRoomAndUser(currentUser.id);

  return {
    tokens: getTokensByRoomAndUser(state, room.guid),
  };
};

const mapDispatchToProps = {
  updateRoomDocumentToken: RoomDocumentAction.updateToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTokenModal);
