import useLatest from './useLatest';

/**
 * Note that the ref (not ref.current) returned from this hook should be used in a dep array.
 * Because it's a ref it's a permanent pointer to the same object and therefore won't actually
 * trigger rerenders. We need to include it so that eslint is happy about exhaustive deps
 * (and making sure deps are exhaustive will prevent bugs, so it's worth this little compromise)
 * @param callback Callback to make a ref
 * @returns ref
 */
export default function useCallbackAsRef<T extends Function>(callback: T) {
  return useLatest(callback);
}
