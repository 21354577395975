//@flow
import styles from './SessionPage.module.css';

import clsx from 'clsx';
import React from 'react';

import Page from './Page';

type Props = {
  children: Array<React$Element<any>> | React$Element<any>,
  header?: ?Node,
  title: string,
};

export default function SessionPage({ children, header, title }: Props) {
  return (
    <Page>
      {header ?? (
        <header>
          <h1 className={clsx('text-overflow', styles.logo)}>{title}</h1>
          <h2 className={clsx('heading2', 'is-centered', styles.subtitle)}>You Can Play With Us</h2>
        </header>
      )}

      <article>
        <div className={styles.content}>{children}</div>
      </article>

      <footer>
        <p className={styles.copyright}>
          &copy; {new Date().getFullYear()} Role, Inc. All rights reserved.
          <br />
          <a
            href="https://www.playrole.com/tos"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles.link, styles.isLight)}
          >
            Terms of Service
          </a>
          <a
            href="https://www.playrole.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles.link, styles.isLight)}
          >
            Privacy Policy
          </a>
          <a
            href="https://www.playrole.com/guidelines"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles.link, styles.isLight)}
          >
            Community Guidelines
          </a>
        </p>
      </footer>
    </Page>
  );
}
