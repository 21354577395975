import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import { SessionAction } from 'store/actions';
import { SessionSelector } from 'store/selectors';

import PageLoading from 'components/PageLoading';

function AuthRoute({ children, location, authOptional, onIsAuthed, onIsUnauthed, ...rest }) {
  const isAuthed = useSelector(SessionSelector.isAuthed);
  const currentUser = useSelector(SessionSelector.currentUser);
  const to = encodeURIComponent(`${location.pathname}${location.search}`);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthed && !currentUser) dispatch(SessionAction.fetchCurrentUser());
  }, [currentUser, dispatch, isAuthed]);

  useEffect(() => {
    if (isAuthed) onIsAuthed?.();
    else onIsUnauthed?.();
  }, [currentUser, history, isAuthed, onIsAuthed, onIsUnauthed]);

  if (authOptional) {
    return <Route {...rest}>{children}</Route>;
  } else if (isAuthed) {
    if (!currentUser) return <PageLoading />;
    return <Route {...rest}>{children}</Route>;
  } else {
    history.replace(`/login?to=${to}`);
    return null;
  }
}

export default AuthRoute;
