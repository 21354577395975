import './styles/global.css';
import './styles/rui.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import amplitude from 'amplitude-js';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

import store from './store';
import { VERSION } from 'models/Constants';

import App from './App';
import SimpleReactLightbox from 'simple-react-lightbox';

const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
if (amplitudeKey) amplitude.getInstance().init(amplitudeKey, null, { includeUtm: true, includeReferrer: true });

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (googleAnalyticsId) ReactGA.initialize(googleAnalyticsId);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: VERSION,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
