import styles from './CreateTableModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import history from 'browserHistory';
import { ErrorAction, GameAction, PlaykitAction, RoomAction } from 'store/actions';
import { ErrorSelector, GameSelector, PlaykitSelector } from 'store/selectors';
import { randomColor, randomPlaykitColor } from 'utilities';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

class CreateTableModal extends Component {
  state = { isLoading: false };

  componentDidMount() {
    const { playkit, playkitId, fetchPlaykit, fetchGame, gameSlug, game } = this.props;
    if (!playkit && playkitId) fetchPlaykit(playkitId);
    if (!game && gameSlug) fetchGame(gameSlug);
  }

  componentWillUnmount() {
    this.props.removeAllErrors();
  }

  onSubmit = (formValues) => {
    const { createTable, removeAllErrors, playkitId, playkit, game } = this.props;
    removeAllErrors();
    let colors = {};
    let payload = {};
    if (game) {
      const firstColor = game?.colors?.length ? randomPlaykitColor(game.colors) : randomColor();
      const secondColor = game?.colors?.length ? randomPlaykitColor(game.colors, firstColor) : randomColor(firstColor);
      game?.colors?.length ? (colors.hex = [firstColor, secondColor]) : (colors.css = [firstColor, secondColor]);
      payload = { game_id: game.id, game_title: game.title };
    } else if (playkit) {
      const firstColor = playkit?.colors?.users?.length ? randomPlaykitColor(playkit.colors.users) : randomColor();
      const secondColor = playkit?.colors?.users?.length
        ? randomPlaykitColor(playkit.colors.users, firstColor)
        : randomColor(firstColor);
      playkit?.colors?.users?.length
        ? (colors.hex = [firstColor, secondColor])
        : (colors.css = [firstColor, secondColor]);
      payload = { playkit_id: playkitId, playkit_title: playkit?.title };
    } else {
      const firstColor = randomColor();
      const secondColor = randomColor(firstColor);
      colors.css = [firstColor, secondColor];
    }
    this.setState({ isLoading: true });
    return createTable(
      { room: { ...formValues, game_id: payload.game_id }, colors, ...payload },
      (room) => history.push(`/rooms/${room.guid}`),
      () => this.setState({ isLoading: false })
    );
  };

  renderErrors = () => {
    return <p className={styles.error}>{this.props.error?.message}. </p>;
  };

  renderInput = ({ input, type, label, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <label className={styles.inputContainer}>
        {label}
        <TextInput
          input={input}
          meta={meta}
          type={type}
          placeholder={placeholder}
          autoComplete={type === 'password' ? 'new-password' : 'off'}
          showError={submitFailed}
          ignore1p
        />
      </label>
    );
  };

  get roomType() {
    const { playkit, game } = this.props;

    if (playkit) return 'game';
    else if (game) return `${game.title} game`;
    else return '';
  }

  render() {
    const { isLoading } = this.state;
    const { error, onDismiss, handleSubmit, playkit, game } = this.props;

    return (
      <TitleModal
        title={`Create a New ${this.roomType} Room`}
        headerImage={game?.previewImageUrl || playkit?.logoUrl}
        actions={
          <>
            <Button variant="cancel" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" isLoading={isLoading} onClick={handleSubmit(this.onSubmit)}>
              Create
            </Button>
          </>
        }
        onDismiss={onDismiss}
      >
        <div className={styles.content}>
          <p>
            {game &&
              `You'll find everything you need to play, including any related materials you've purchased, in the play
              panel on the right. `}
            Rooms are persistent, and can be visited any time once they have been created. You'll be able to invite
            players after you name this room, and can restrict access by setting an optional password.
          </p>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          {game && !game.previewImageUrl && (
            <div className={styles.playkit}>
              <img src={game.logoUrl} alt={game.title} />
            </div>
          )}
          <Field
            component={this.renderInput}
            name="title"
            type="text"
            label={
              <span className={styles.label}>
                <strong>Room Name</strong>
              </span>
            }
            placeholder="Your game name"
          />
          <Field
            component={this.renderInput}
            name="password"
            type="password"
            label={
              <span className={styles.label}>
                <strong>Password</strong> (Optional)
              </span>
            }
            placeholder="Add room password"
          />
          {error && this.renderErrors()}
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: ErrorSelector.get(state, RoomAction.CREATE),
    playkit: PlaykitSelector.get(state, ownProps.playkitId),
    game: GameSelector.getBySlug(state, ownProps.gameSlug),
  };
};

const mapDispatchToProps = {
  createTable: RoomAction.create,
  fetchPlaykit: PlaykitAction.fetch,
  fetchGame: GameAction.fetch,
  removeAllErrors: ErrorAction.removeAll,
};

const validate = (values) => {
  const errors = {};
  if (!values.title || values.title.trim() === '') errors.title = 'Required';
  return errors;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'createTable', validate })
)(CreateTableModal);
