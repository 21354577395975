// @flow
import styles from './Builder.module.css';

import type { Node } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuid } from 'uuid';

import type { BuilderProps, InputModel } from 'components/Sheet2/types';
import { ELEMENT_ALIGNMENT, ELEMENT_TYPE, INPUT_TYPE, LIBRARY } from 'constants/sheet';
import { createInput } from 'utilities/sheet';

import IconButton from 'components/buttons/IconButton';
import EditIcon from 'components/Sheet2/atoms/EditIcon';
import LinkedIcon from 'components/Sheet2/atoms/LinkedIcon';
import Markdown from 'components/Sheet2/atoms/Markdown';
import { ReactComponent as AlignCenterIcon } from 'images/icons/AlignCenterIcon.svg';
import { ReactComponent as AlignJustifyIcon } from 'images/icons/AlignJustifyIcon.svg';
import { ReactComponent as AlignLeftIcon } from 'images/icons/AlignLeftIcon.svg';
import { ReactComponent as AlignRightIcon } from 'images/icons/AlignRightIcon.svg';
import { ReactComponent as CloneIcon } from 'images/icons/CloneIcon.svg';
import { ReactComponent as DragIcon } from 'images/icons/DragIcon.svg';
import { ReactComponent as GearIcon } from 'images/icons/GearIcon.svg';
import { ReactComponent as MarkdownIcon } from 'images/icons/MarkdownIcon.svg';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as OneColumnIcon } from 'images/icons/OneColumnIcon.svg';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';
import { ReactComponent as TwoColumnIcon } from 'images/icons/TwoColumnIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';

export default function Builder(props: BuilderProps): Node {
  const {
    children,
    className,
    element,
    isDragging,
    onAdvancedPanelOpen,
    onChange,
    onClone,
    onRemove,
    provided,
    sectionId,
    setInnerRef,
    sheetGuid,
  } = props;
  const { columns, links, elementType, isCollapsed, items, metadata } = element;

  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const labelInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  useEffect(() => {
    if (!labelInputRef.current) return;
    if (isEditingLabel) {
      const length = labelInputRef.current.value.length;
      labelInputRef.current.focus();
      labelInputRef.current.setSelectionRange(length, length);
    }
  }, [isEditingLabel]);

  useEffect(() => {
    if (!descriptionInputRef.current) return;
    if (isEditingDescription) {
      const length = descriptionInputRef.current.value.length;
      descriptionInputRef.current.focus();
      descriptionInputRef.current.setSelectionRange(length, length);
    }
  }, [isEditingDescription]);

  const hasToggle = !!items.find((i) => i.inputType === INPUT_TYPE.toggle);
  const isTwoColumns = columns === 2;
  const isTextElement = elementType === ELEMENT_TYPE.text;
  const showAlignment = isTextElement || elementType === ELEMENT_TYPE.image;
  const showJustify = elementType === ELEMENT_TYPE.image;
  const alignment = metadata.alignment;
  const classNames = [styles.container];

  if (isDragging) classNames.push(styles.noHover);
  if (isCollapsed) classNames.push(styles.isCollapsed);
  if (showAlignment) classNames.push(styles.hasAlignment);
  if (isEditingLabel) classNames.push(styles.isEditingLabel);
  if (isEditingDescription) classNames.push(styles.isEditingDescription);
  isTwoColumns ? classNames.push(styles.twoColumns) : classNames.push(styles.oneColumn);
  if (isTwoColumns || [ELEMENT_TYPE.text, ELEMENT_TYPE.divider].includes(elementType))
    classNames.push(styles.autoHeight);
  if (className) classNames.push(className);

  const headingInput = items.find((item) => item.inputType === INPUT_TYPE.heading);
  const paragraphInput = items.find((item) => item.inputType === INPUT_TYPE.paragraph);
  const label = headingInput?.defaultValue ?? '';
  const description = paragraphInput?.defaultValue ?? '';

  const onAlignmentChange = (value) => {
    onChange(sheetGuid, sectionId, { ...element, metadata: { ...metadata, alignment: value } });
  };

  const onAddDescription = () => {
    const input: InputModel = paragraphInput ?? createInput(uuid(), INPUT_TYPE.paragraph);
    input.defaultValue = '';
    onChange(sheetGuid, sectionId, { ...element, items: [...items, input] });
    setIsEditingDescription(true);
  };

  const onAddLabel = () => {
    const input: InputModel = headingInput ?? createInput(uuid(), INPUT_TYPE.heading);
    input.defaultValue = '';
    onChange(sheetGuid, sectionId, { ...element, items: [...items, input] });
    setIsEditingLabel(true);
  };

  const onCollapseChange = (isCollapsed: boolean) => {
    onChange(sheetGuid, sectionId, { ...element, isCollapsed });
  };

  const onColumnChange = (columns: number) => {
    onChange(sheetGuid, sectionId, { ...element, columns });
  };

  const onDescriptionBlur = (event) => {
    setIsEditingDescription(false);
    if (!paragraphInput) return;
    const defaultValue = event.currentTarget.value.trim();
    let updatedItems;
    if (defaultValue !== '')
      updatedItems = items.map((o) => (o.id === paragraphInput.id ? { ...paragraphInput, defaultValue } : o));
    else updatedItems = items.filter((o) => o.id !== paragraphInput.id);
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onDescriptionChange = (event) => {
    if (!paragraphInput) return;
    const defaultValue = event.currentTarget.value;
    const updatedItems = items.map((o) => (o.id === paragraphInput.id ? { ...paragraphInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onDescriptionClick = () => {
    if (!isEditingDescription) setIsEditingDescription(true);
  };

  const onDescriptionKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) descriptionInputRef.current.blur();
  };

  const onLabelBlur = (event) => {
    setIsEditingLabel(false);
    if (!headingInput) return;
    const defaultValue = event.currentTarget.value.trim();
    let updatedItems;
    if (defaultValue !== '')
      updatedItems = items.map((o) => (o.id === headingInput.id ? { ...headingInput, defaultValue } : o));
    else updatedItems = items.filter((o) => o.id !== headingInput.id);
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onLabelChange = (event) => {
    if (!headingInput) return;
    const defaultValue = event.currentTarget.value;
    const updatedItems = items.map((o) => (o.id === headingInput.id ? { ...headingInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onLabelClick = () => {
    if (!isEditingLabel) setIsEditingLabel(true);
  };

  const onLabelKeyDown = (event) => {
    if (event.keyCode === 13) labelInputRef.current.blur();
  };

  const onAdvancedPanelClick = useCallback(() => {
    const advancedOptions = {
      elementId: element.id,
      sectionId: sectionId,
    };
    onAdvancedPanelOpen(advancedOptions);
  }, [element.id, sectionId, onAdvancedPanelOpen]);

  return (
    <div
      {...provided.draggableProps}
      ref={(el) => setInnerRef(el, element.id, provided.innerRef)}
      className={classNames.join(' ')}
    >
      <header className={styles.header}>
        <div {...provided.dragHandleProps} className={styles.dragHandle}>
          <DragIcon />
        </div>
        <div className={styles.elementType}>{LIBRARY[elementType].title}</div>
        <div className={styles.headerActions}>
          <button className={`button-reset ${styles.collapseButton}`} onClick={() => onCollapseChange(!isCollapsed)}>
            {isCollapsed ? <NotVisibleIcon /> : <VisibleIcon />}
          </button>
          <IconButton
            buttonSize={18}
            iconSize={18}
            activeBackground="none"
            background="none"
            className={styles.settingsButton}
            label="Advanced Options"
            onClick={onAdvancedPanelClick}
            children={<GearIcon />}
            showLabelOnHover
            showAsTooltip
          />
          {links.length > 0 && <LinkedIcon className={styles.linkedIcon} />}
        </div>
      </header>

      <div className={styles.content}>
        <div className={`${styles.toggleAndLabelContainer} ${headingInput || hasToggle ? styles.hasLabel : ''}`}>
          {hasToggle && <div className={`${styles.iconToggle} ${isTextElement ? styles.isText : ''}`} />}
          {!isTextElement &&
            (headingInput ? (
              <div className={styles.labelInputContainer}>
                {isEditingLabel ? (
                  <TextareaAutosize
                    ref={labelInputRef}
                    className={styles.labelInput}
                    value={label}
                    placeholder="Add Label"
                    onBlur={onLabelBlur}
                    onChange={onLabelChange}
                    onKeyDown={onLabelKeyDown}
                  />
                ) : (
                  <div className={styles.label} onClick={onLabelClick}>
                    {label}
                  </div>
                )}
                <EditIcon
                  className={styles.editLabelButton}
                  isEditing={isEditingLabel}
                  onClick={() => {
                    setIsEditingLabel((prev) => !prev);
                  }}
                />
              </div>
            ) : (
              <button className={`button-reset ${styles.addLabelButton}`} onClick={onAddLabel}>
                Add Label <PenIcon />
              </button>
            ))}
        </div>

        <div className={styles.element}>{children}</div>

        {elementType !== ELEMENT_TYPE.text && (
          <div className={`${styles.descriptionContainer} ${paragraphInput ? styles.hasDescription : ''}`}>
            {paragraphInput && (
              <div className={styles.descriptionInputContainer}>
                {isEditingDescription ? (
                  <TextareaAutosize
                    ref={descriptionInputRef}
                    className={styles.descriptionInput}
                    value={description}
                    placeholder="Add Description"
                    minRows={2}
                    onBlur={onDescriptionBlur}
                    onChange={onDescriptionChange}
                    onKeyDown={onDescriptionKeyDown}
                  />
                ) : (
                  <div className={styles.description} onClick={onDescriptionClick}>
                    <Markdown className={styles.descriptionMarkdown}>{description}</Markdown>
                  </div>
                )}
                <EditIcon
                  className={styles.editDescriptionButton}
                  isEditing={isEditingDescription}
                  onClick={() => {
                    setIsEditingDescription((prev) => !prev);
                  }}
                />
                {isEditingDescription && <MarkdownIcon className={styles.markdownIcon} />}
              </div>
            )}
            {!paragraphInput && (
              <button className={`button-reset ${styles.addDescriptionButton}`} onClick={onAddDescription}>
                Add Description <PenIcon />
              </button>
            )}
          </div>
        )}
      </div>

      <footer className={styles.footer}>
        {showAlignment && (
          <div className={`${styles.actions} ${styles.alignment} ${showJustify ? styles.hasJustify : ''}`}>
            <button
              className={`button-reset ${styles.actionButton} ${styles.alignmentButton} ${
                alignment && alignment === ELEMENT_ALIGNMENT.left ? styles.isActive : ''
              }`}
              onClick={() => {
                onAlignmentChange(ELEMENT_ALIGNMENT.left);
              }}
            >
              <AlignLeftIcon />
            </button>
            <button
              className={`button-reset ${styles.actionButton} ${styles.alignmentButton} ${
                alignment && alignment === ELEMENT_ALIGNMENT.center ? styles.isActive : ''
              }`}
              onClick={() => {
                onAlignmentChange(ELEMENT_ALIGNMENT.center);
              }}
            >
              <AlignCenterIcon />
            </button>
            <button
              className={`button-reset ${styles.actionButton} ${styles.alignmentButton} ${
                alignment && alignment === ELEMENT_ALIGNMENT.right ? styles.isActive : ''
              }`}
              onClick={() => {
                onAlignmentChange(ELEMENT_ALIGNMENT.right);
              }}
            >
              <AlignRightIcon />
            </button>
            {showJustify && (
              <button
                className={`button-reset ${styles.actionButton} ${styles.alignmentButton} ${
                  alignment && alignment === ELEMENT_ALIGNMENT.fill ? styles.isActive : ''
                }`}
                onClick={() => {
                  onAlignmentChange(ELEMENT_ALIGNMENT.fill);
                }}
              >
                <AlignJustifyIcon />
              </button>
            )}
          </div>
        )}
        <div className={`${styles.actions} ${styles.columns}`}>
          <button
            className={`button-reset ${styles.actionButton} ${styles.columnButton}`}
            onClick={() => onColumnChange(2)}
          >
            <OneColumnIcon />
          </button>
          <button
            className={`button-reset ${styles.actionButton} ${styles.columnButton}`}
            onClick={() => onColumnChange(1)}
          >
            <TwoColumnIcon />
          </button>
        </div>
        <div className={styles.spacer} />
        <div className={`${styles.actions} ${styles.other}`}>
          <button
            className={`button-reset ${styles.actionButton} ${styles.cloneButton}`}
            onClick={() => onClone(sectionId, element.id)}
          >
            <CloneIcon />
          </button>
        </div>
        <div className={`${styles.actions} ${styles.single}`}>
          <button
            className={`button-reset ${styles.actionButton} ${styles.deleteButton}`}
            onClick={() => onRemove(sectionId, element.id)}
          >
            <TrashIcon />
          </button>
        </div>
      </footer>
    </div>
  );
}
