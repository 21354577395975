// @flow
import type { Node } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children?: ?Node,
  defaultTitle: string,
  description: string,
  image?: string,
  lang: string,
  meta: any[],
  title?: string,
  titleTemplate: string,
};

function Metatags(props: Props): Node {
  const { children, defaultTitle, description, image, lang, meta, title, titleTemplate } = props;
  return (
    <Helmet
      htmlAttributes={{ lang }}
      defaultTitle={defaultTitle}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title || defaultTitle,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        image && {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '@RoleApp',
        },
        {
          name: 'twitter:title',
          content: title || defaultTitle,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ]
        .filter(Boolean)
        .concat(meta)}
    >
      {children}
    </Helmet>
  );
}

Metatags.defaultProps = {
  defaultTitle: 'Role',
  description: 'The easiest place to play and create any tabletop roleplaying game.',
  lang: 'en',
  meta: [],
  titleTemplate: '%s | Role',
};

export default Metatags;
