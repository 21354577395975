import { FILTER } from 'constants/asset';

export const DocumentType = {
  NONE: '',
  APP: 'app',
  AUDIO: 'audio',
  DICE: 'dice',
  GIF: 'gif',
  IMAGE: 'image',
  LINK: 'link',
  MAP: 'map',
  PDF: 'pdf',
  THEME: 'theme',
  TOKEN: 'token',
};

export const filterToType = (filter) => {
  switch (filter) {
    case FILTER.apps:
      return DocumentType.APP;
    case FILTER.images:
      return DocumentType.IMAGE;
    case FILTER.maps:
      return DocumentType.MAP;
    case FILTER.pdfs:
      return DocumentType.PDF;
    case FILTER.tokens:
      return DocumentType.TOKEN;
    case FILTER.audio:
      return DocumentType.AUDIO;
    default:
      return DocumentType.NONE;
  }
};

export default class Document {
  constructor(asset) {
    this.id = asset.id;
    this.name = asset.name;
    this.type = asset.type;
    this.ownerId = asset.ownerId;
    this.ownerType = asset.ownerType;
    this.productIds = asset.productIds;
    this.gameIds = asset.gameIds;
    this.playkitIds = asset.playkitIds;
    this.fileUrl = asset.fileUrl;
    this.fileSizeFormatted = asset.fileSizeFormatted;
    this.thumbnailUrl = asset.thumbnailUrl;
    this.downloadUrl = asset.downloadUrl;
    this.path = asset.path;
    this.roomPath = asset.roomPath;
    this.isGif = asset.isGif;
    this.isPublic = asset.isPublic;
    this.isShared = asset.isShared;
    this.metadata = asset.metadata;
    this.data = asset.data;
    this.externalUrl = asset.externalUrl;
    this.isGameMaterial = asset.productIds.length > 0;
  }

  get userId() {
    return this.ownerType === 'User' ? this.ownerId : undefined;
  }

  isApp = () => this.type.toLowerCase() === DocumentType.APP;
  isDice = () => this.type.toLowerCase() === DocumentType.DICE;
  isImage = () => this.type.toLowerCase() === DocumentType.IMAGE;
  isLink = () => this.type.toLowerCase() === DocumentType.LINK;
  isMap = () => this.type.toLowerCase() === DocumentType.MAP;
  isPdf = () => this.type.toLowerCase() === DocumentType.PDF;
  isToken = () => this.type.toLowerCase() === DocumentType.TOKEN;
  isTheme = () => this.type.toLowerCase() === DocumentType.THEME;
  isAudio = () => this.type.toLowerCase() === DocumentType.AUDIO;
}

export class DocumentMenuItem {
  constructor(params) {
    this.label = params.label;
    this.externalUrl = params.externalUrl || null;
    this.onClick = params.onClick || null;
  }
}
