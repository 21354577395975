import styles from './TableView.module.css';

import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import useUnmount from 'hooks/useUnmount';
import useTableHeartbeat from 'room/hooks/useHeartbeat';
import { RoomUserAction, TableAction } from 'store/actions';
import { PlaykitSelector, TableSelector } from 'store/selectors';
import { TableViewMode } from 'types/room';
import { useParticipantConnected } from 'video/hooks/useRoomEvent';
import useScreenShareParticipant from 'video/hooks/useScreenShareParticipant';
import useVideoContext from 'video/hooks/useVideoContext';

import FTE from 'components/FTE/FTE';
import RoomLogo from 'components/RoomLogo';
import AudioVideoControls from 'room/AudioVideoControls';
import TableLobby from 'room/TableLobby';
import TablePing from 'room/TablePing';
import TableShareView from 'room/TableShareView';
import TableUsers from 'room/TableUsers';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

interface Props {
  room: any;
}

export default function TableView({ room }: Props) {
  const dispatch = useDispatch();
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const isShowingPing = useSelector(TableSelector.isShowingPing) as boolean;
  const isObrActive = useSelector(TableSelector.isObrActive);
  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  // @ts-ignore PlaykitSelector is not typed yet
  const playkits = useSelector((state) => PlaykitSelector.getByRoom(state, room.guid));
  const backgroundArt = useMemo(
    () => room.backgroundArtUrl || playkits.find((o) => !!o.backgroundArt)?.backgroundArt,
    [playkits, room.backgroundArtUrl]
  );

  const entered = useSelector(TableSelector.getEntered);
  const { room: videoRoom } = useVideoContext();
  const screenShareParticipant = useScreenShareParticipant();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const isReady = videoRoom || entered;

  useParticipantConnected(
    useCallback(({ identity }) => dispatch(RoomUserAction.fetch(room.guid, identity)), [dispatch, room.guid])
  );

  useUnmount(() => videoRoom?.disconnect());
  useTableHeartbeat(room.guid);

  useEffect(() => {
    if (isReady) {
      dispatch(RoomUserAction.fetchAll(room.guid));
    }
  }, [dispatch, isReady, room.guid]);

  useEffect(() => {
    batch(() => {
      if (screenShareParticipant) {
        dispatch(TableAction.updateViewMode(TableViewMode.Speaker));
        dispatch(TableAction.setActiveRoomDocument(null));
      }
    });
  }, [dispatch, screenShareParticipant]);

  useEffect(() => {
    if (activeRoomDocument && selectedParticipant) setSelectedParticipant(null);
  }, [activeRoomDocument, selectedParticipant, setSelectedParticipant]);

  return (
    <div
      className={styles.container}
      style={backgroundArt ? { backgroundImage: `url("${backgroundArt}")` } : undefined}
    >
      <RoomLogo />
      <div className={clsx(styles.viewMode, isReady && styles.isConnected)}>
        {room && !isReady && (
          <>
            <TableLobby
              className={clsx((!!activeRoomDocument || isObrActive) && styles.smallLobby)}
              room={room}
              isSmall={!!activeRoomDocument}
            />
            <TableShareView room={room} />
          </>
        )}
        {room && isReady && (
          <TableShareView room={room}>
            {({ content, previews }) => (
              <TableUsers
                className={styles.viewModeContent}
                room={room}
                children={content}
                previewChildren={previews}
              />
            )}
          </TableShareView>
        )}
      </div>
      <FTE refEl={referenceElement} stepId="av" />
      {isReady && (
        <div ref={setReferenceElement} className={styles.controls}>
          <AudioVideoControls room={room} />
        </div>
      )}
      <div id="table-modal" className={styles.modal} />
      {isShowingPing && <TablePing className={styles.ping} />}
    </div>
  );
}
