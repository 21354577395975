// @flow
import _ from 'lodash';
import type { Node } from 'react';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import type { ElementModel, ElementProps, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import { ChannelEvent } from 'models/Channel';
import { RoomSheetSelector, TableSelector } from 'store/selectors';
import { createInput } from 'utilities/sheet';

import Slots from './Slots';
import Element from 'components/Sheet2/elements/Element';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function SlotsElement(props: Props): Node {
  const { colors, element, onChange, readOnly, roomGuid, sectionId, sheetGuid } = props;
  const { id, canEdit, columns, items } = element;

  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));

  const slotInputs = items.filter((o) => o.inputType === INPUT_TYPE.slot);
  const values = slotInputs.map((o) => {
    if (o.value !== null && typeof o.value === 'boolean') return o.value;
    if (o.defaultValue !== null && typeof o.defaultValue === 'boolean') return o.defaultValue;
    return false;
  });

  const onSlotChange = (value, index) => {
    const updatedSlotInputs = slotInputs.map((o, i) => ({
      ...o,
      value: i === index ? value : o.value,
    }));
    const updatedItems = [...updatedSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onCountChange = (value: string) => {
    const count = parseInt(value);
    if (isNaN(count)) return;
    const total = slotInputs.length;
    if (count === total) return;

    if (count < total) {
      const updatedSlotInputs = slotInputs.slice(0, count);
      const updatedItems = [...updatedSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    } else {
      const newSlotInputs = _.times(count - total, () => ({
        ...createInput(uuid(), INPUT_TYPE.slot),
        defaultValue: false,
        canEdit: true,
      }));
      const updatedItems = [...slotInputs, ...newSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    }
  };

  const onCountBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onCountFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  return (
    <Element {...props}>
      <Slots
        items={values}
        columns={columns}
        canEdit={canEdit}
        readOnly={readOnly}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onChange={onSlotChange}
        onCountBlur={onCountBlur}
        onCountFocus={onCountFocus}
        onCountChange={onCountChange}
      />
    </Element>
  );
}

export default memo(SlotsElement);
