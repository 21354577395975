// @flow
import _ from 'lodash';

export const ELEMENT_ALIGNMENT: { [string]: string } = Object.freeze({
  left: 'left',
  center: 'center',
  right: 'right',
  fill: 'fill',
});

export const ELEMENT_TYPE: { [string]: string } = Object.freeze({
  buff: 'buff',
  clock: 'clock',
  counter: 'counter',
  dicePool: 'dice-pool',
  divider: 'divider',
  dropdown: 'dropdown',
  field: 'field',
  fieldBuff: 'field-buff',
  group: 'group',
  image: 'image',
  note: 'note',
  reference: 'reference',
  slots: 'slots',
  text: 'text',
});

export const INPUT_TYPE: { [string]: string } = Object.freeze({
  buff: 'buff',
  clockSlice: 'clock-slice',
  counterCurrent: 'counter-current',
  counterMax: 'counter-max',
  diceCount: 'dice-count',
  diceColor: 'dice-color',
  diceSides: 'dice-sides',
  diceType: 'dice-type',
  dropdown: 'dropdown',
  field: 'field',
  heading: 'heading',
  image: 'image',
  note: 'note',
  paragraph: 'paragraph',
  reference: 'reference',
  slot: 'slot',
  toggle: 'toggle',
});

export const LIBRARY_TYPE: { [string]: string } = Object.freeze({
  gameplay: 'gameplay',
  general: 'general',
});

export const LIBRARY: { [string]: any } = Object.freeze(
  _.mapKeys(
    [
      {
        id: ELEMENT_TYPE.buff,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Buff',
        tooltip: 'Adds to a final die roll result.',
        description: `A Buff adds to the final result of a player's die roll. When a player selects this element, the number inside will be added to their die roller. This can be done both before and after a completed roll.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.fieldBuff,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Field & Buff',
        tooltip: 'Adds to a final die roll, with extra space for short text.',
        description: `A Field & Buff provides all the functionality of a Buff while also allowing space for players to write short-form text. When a player selects this element, the Buff number will be added to their die roller result.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.clock,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Clock',
        tooltip: 'Tracks progress over turns/time.',
        description: `A Clock tracks the progress of turns, time, and other progress markers in the game. The clock can be set for as many "slices" as you want. Clicking on slices will fill the visualizer in a clockwise progession.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.counter,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Points',
        tooltip: 'Tracks point values up & down with a progress bar.',
        description: `Points counters can be used to track large number values that frequently shift up & down during play. A visual progress bar accompanies the number values for quick reference.`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.dicePool,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Pool',
        tooltip: 'Adds a set number of specific dice before a roll.',
        description: `A Pool adds a set number of specific dice to a die roll. The number of dice, as well as the specific die being rolled, can each be set within this element. When a player selects this element, those dice will be added to their current roll.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.divider,
        libraryType: LIBRARY_TYPE.general,
        title: 'Divider',
        tooltip: 'Visual divider for sheet layout.',
        description: `A Divider provides a simple visual break in a sheet's layout.`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.dropdown,
        libraryType: LIBRARY_TYPE.general,
        title: 'Dropdown',
        tooltip: 'Selectable list of pre-defined text options.',
        description: `A Dropdown contains a pre-set list of text options that a player can choose between at any time. You can add options to a Dropdown here in the editor!`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.field,
        libraryType: LIBRARY_TYPE.general,
        title: 'Field',
        tooltip: 'Open space for writing numbers & short text.',
        description: `A Field provides a short open space for players to write numbers and simple text.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.group,
        libraryType: LIBRARY_TYPE.general,
        title: 'Group',
        tooltip: 'Collect elements together for organization.',
        description: `Groups allow you to gather any combination of elements into an organized collection for easy layout management. Groups can be re-arranged & collapsed, and support optional duplication & numbering.`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.image,
        libraryType: LIBRARY_TYPE.general,
        title: 'Image',
        tooltip: 'Upload an embedded image.',
        description: `Upload an Image to add visuals to a sheet. Common Image formats are supported, including animated GIFs!`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.note,
        libraryType: LIBRARY_TYPE.general,
        title: 'Note',
        tooltip: 'Large open space for writing long-form text.',
        description: `A Note provides a large open text area for players to write long-form text. As a player writes more text, the Note element will expand to fit.`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.reference,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Reference',
        tooltip: 'Adds a number to compare the final roll result against.',
        description: `A Reference displays a set number for the die roller to be compared against. When a player selects this element, the Reference number will be added to their current displayed roll, separate from the total result, for easy comparison.`,
        columns: 1,
      },
      {
        id: ELEMENT_TYPE.slots,
        libraryType: LIBRARY_TYPE.gameplay,
        title: 'Slots',
        tooltip: 'Group of on/off switches for tracking individual resources.',
        description: `Slots display a row of "on/off" switches that can be individually marked to visually track critical points & resources. Each Slot can be switched on & off independently, in any order.`,
        columns: 2,
      },
      {
        id: ELEMENT_TYPE.text,
        libraryType: LIBRARY_TYPE.general,
        title: 'Text',
        tooltip: 'Static descriptive text & titles.',
        description: `Add Text to a sheet template whenever you need to write additional static text and/or titles within a section.`,
        columns: 1,
      },
    ],
    'id'
  )
);

export const LINK_ACTION_TYPE: { [string]: string } = Object.freeze({
  edit: 'edit',
  remove: 'remove',
  create: 'create',
});

export const LINK_TYPE: { [string]: string } = Object.freeze({
  toggle: 'toggle',
  gameplay: 'gameplay',
});

export const DICE_ROLLER_TYPE: { [string]: string } = Object.freeze({
  value: 'dice',
  label: 'Dice Roller',
});

export const GAMEPLAY_ELEMENT_TYPES = [
  ELEMENT_TYPE.buff,
  ELEMENT_TYPE.dicePool,
  ELEMENT_TYPE.fieldBuff,
  ELEMENT_TYPE.reference,
];
