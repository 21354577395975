import styles from './dashboard.module.css';
import modalStyles from 'components/PurchaseSuccessModal/PurchaseSuccessModal.module.css';

import clsx from 'clsx';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import useQuery from 'hooks/useQuery';
import { ErrorAction } from 'store/actions';
import RedeemCodeAction, { RedeemCode } from 'store/actions/RedeemCodeAction';
import { ErrorSelector, GameSelector, OrganizationSelector, ProductSelector, SessionSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import Button from 'components/buttons/Button';
import KickstarterSupplement from 'components/kickstarter/KickstarterSupplement';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import { getBundleItemIcon } from 'components/ProductDetails/BundleItemIcon';
import PurchaseSuccessModal from 'components/PurchaseSuccessModal';
import TitlePanel from 'components/TitlePanel';

export default function RedeemPage() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRedeem, setIsLoadingRedeem] = useState(false);
  const [inputValue, setInputValue] = useState(query.get('code') ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState<RedeemCode>();

  const currentUser = useSelector(SessionSelector.currentUser);
  // @ts-ignore It complains that getByIds should only have 1 param
  const products = useSelector((state) => ProductSelector.getByIds(state, redeemCode?.productIds ?? []));
  const isValid = inputValue.length === 36 && validateUUID(inputValue) && !!redeemCode;
  const hasError = errorMessage.length > 0;
  const isSuccess = !!redeemCode && showSuccessModal;

  useEffect(() => {
    dispatch(ErrorAction.removeAll());
    if (inputValue.length === 36) {
      if (validateUUID(inputValue)) {
        setIsLoading(true);
        dispatch(
          RedeemCodeAction.fetch(inputValue, (redeemCode) => {
            setIsLoading(false);
            if (redeemCode?.isRedeemed) {
              setRedeemCode(undefined);
              setErrorMessage('This code has already been redeemed.');
            } else {
              setRedeemCode(redeemCode);
            }
          })
        );
      } else {
        setRedeemCode(undefined);
        setErrorMessage('Invalid redeem code');
      }
    } else {
      setRedeemCode(undefined);
      setErrorMessage('');
    }
  }, [dispatch, inputValue]);

  const apiError = useSelector(
    (state) => ErrorSelector.get(state, RedeemCodeAction.FETCH) || ErrorSelector.get(state, RedeemCodeAction.REDEEM)
  );
  useEffect(() => {
    if (apiError) {
      setIsLoading(false);
      setRedeemCode(undefined);
      setErrorMessage(apiError);
    }
  }, [apiError]);

  const onRedeem = useCallback(() => {
    if (redeemCode) {
      setIsLoadingRedeem(true);
      dispatch(
        RedeemCodeAction.redeem(redeemCode.code, () => {
          setIsLoadingRedeem(false);
          setShowSuccessModal(true);
        })
      );
    }
  }, [dispatch, redeemCode]);

  const onDismissModal = useCallback(() => {
    history.push('/games');
  }, [history]);

  /**
   * Kickstarter Redemption Code
   */
  const [ksProduct, setKsProduct] = useState(null);
  const [ksIsAll, setKsIsAll] = useState(false);
  // @ts-ignore
  const ksOrganization = useSelector((state) => OrganizationSelector.getById(state, ksProduct?.ownerId));
  // @ts-ignore
  const ksGame = useSelector((state) => GameSelector.get(state, ksProduct?.gameId));
  const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] = useState(false);
  const showKSPanel =
    currentUser?.kickstarterRewards?.extras.length > 0 || currentUser?.kickstarterRewards?.options.length > 0;
  // @ts-ignore
  const ksGameArt = ksProduct?.coverArtUrl ?? ksGame?.coverArtUrl;

  useEffect(() => {
    if (ksProduct || ksIsAll) setShowPurchaseSuccessModal(true);
  }, [ksIsAll, ksProduct]);

  return (
    <DashboardLayout>
      <Metatags title="Redeem" description="Redeem a product code and add it to your account!" />

      {showPurchaseSuccessModal && !ksIsAll && (
        <PurchaseSuccessModal
          onDismissModal={() => setShowPurchaseSuccessModal(false)}
          product={ksProduct}
          productArtUrl={ksGameArt}
          organization={ksOrganization?.title}
          game={ksGame}
          source="games"
        />
      )}

      {showKSPanel && (
        <KickstarterSupplement
          {...currentUser.kickstarterRewards}
          selectProduct={setKsProduct}
          selectAll={setKsIsAll}
        />
      )}

      <div className={styles.content}>
        <TitlePanel title="Redeem Code">
          <div className={styles.redeemInputContainer}>
            <label className={styles.redeemLabel}>
              <span>
                <strong>Hey {currentUser.username}! Paste or type in your redeem code:</strong>
              </span>
              <input
                autoFocus
                className={clsx(styles.redeemInput, isValid && styles.isValid, hasError && styles.hasError)}
                readOnly={isValid}
                placeholder="Enter redeem code here"
                defaultValue={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value)}
                maxLength={36}
                spellCheck="false"
              />
            </label>
            {hasError && <div className={styles.redeemError}>{errorMessage}</div>}
          </div>

          {isLoading && <LoadingSpinner color="var(--color-dark-text)" />}

          {redeemCode && (
            <div className={styles.withRedeemCode}>
              <h4 className={styles.gamesMsg}>
                Click "Redeem" below to add the following {products.length} {pluralize(products.length, 'product')} to
                your library:
              </h4>
              <div className={styles.redeemProductList}>
                {products.map((product) => (
                  <ProductPreview key={product.id} product={product} />
                ))}
              </div>
              {isSuccess && (
                <div className={styles.redeemSuccess}>
                  <p>{`${products.length} ${pluralize(products.length, 'product')} successfully redeemed!`}</p>
                  <Button variant="primary" onClick={onDismissModal}>
                    Go to Games
                  </Button>
                </div>
              )}
              {!isSuccess && (
                <Button
                  className={styles.redeemButton}
                  variant="primary"
                  onClick={onRedeem}
                  isLoading={isLoadingRedeem}
                  isDisabled={isSuccess}
                >
                  Redeem
                </Button>
              )}
            </div>
          )}
        </TitlePanel>
      </div>
    </DashboardLayout>
  );
}

const ProductPreview: FunctionComponent<Record<string, any>> = ({ product }) => {
  // @ts-ignore
  const game = useSelector((state) => GameSelector.get(state, product.gameId));
  // @ts-ignore
  const organization = useSelector((state) => OrganizationSelector.getById(state, game.organizationId));

  return (
    <div className={modalStyles.content}>
      <div className={clsx(modalStyles.body, styles.redeemProduct)}>
        <div className={modalStyles.image}>
          <img src={product.coverArtUrl || game.coverArtUrl} alt={product.title} />
        </div>
        <div className={modalStyles.info}>
          <div className={styles.redeemProductTitle}>
            <h4>{product.title}</h4>
            <div>By {organization.title}</div>
          </div>
          {(product.itemsList.platform || product.itemsList.download) && (
            <>
              <h4>Includes:</h4>
              <ul>
                {product.itemsList.platform &&
                  Object.entries(product.itemsList.platform).map(([item, label], i) => (
                    <li key={i}>
                      {getBundleItemIcon(item)}
                      {label}
                    </li>
                  ))}
                {product.itemsList.download &&
                  Object.entries(product.itemsList.download).map(([item, label], i) => (
                    <li key={i}>
                      {getBundleItemIcon(item)}
                      {label}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
