import { createAction } from './utilities';

export default class RollAction {
  static ADD_DIE = 'RollAction.ADD_DIE';
  static REMOVE_ALL_DICE = 'RollAction.REMOVE_ALL_DICE';
  static REMOVE_DIE = 'RollAction.REMOVE_DIE';
  static UPDATE_DIE = 'RollAction.UPDATE_DIE';

  static ADD_MODIFIER = 'RollAction.ADD_MODIFIER';
  static REMOVE_ALL_MODIFIERS = 'RollAction.REMOVE_ALL_MODIFIERS';
  static REMOVE_MODIFIER = 'RollAction.REMOVE_MODIFIER';
  static UNSELECT_ALL_MODIFIERS = 'RollAction.UNSELECT_ALL_MODIFIERS';
  static UPDATE_MODIFIER = 'RollAction.UPDATE_MODIFIER';

  static ADD_USER_DIE = 'RollAction.ADD_USER_DIE';
  static ADD_USER_MODIFIER = 'RollAction.ADD_USER_MODIFIER';
  static SHOW_USER_ROLL = 'RollAction.SHOW_USER_ROLL';
  static REMOVE_ALL_USER_DICE = 'RollAction.REMOVE_ALL_USER_DICE';
  static REMOVE_ALL_USER_MODIFIERS = 'RollAction.REMOVE_ALL_USER_MODIFIERS';
  static REMOVE_USER_DIE = 'RollAction.REMOVE_USER_DIE';
  static REMOVE_USER_MODIFIER = 'RollAction.REMOVE_USER_MODIFIER';

  // ========================================
  // DICE
  // ========================================

  static addDie = (die) => {
    return createAction(RollAction.ADD_DIE, die);
  };

  static removeAllDice = () => {
    return createAction(RollAction.REMOVE_ALL_DICE);
  };

  static removeDie = (id) => {
    return createAction(RollAction.REMOVE_DIE, id);
  };

  static updateDie = (die) => {
    return createAction(RollAction.UPDATE_DIE, die);
  };

  // ========================================
  // MODIFIERS
  // ========================================

  static addModifier = (modifier) => {
    return createAction(RollAction.ADD_MODIFIER, modifier);
  };

  static removeAllModifiers = () => {
    return createAction(RollAction.REMOVE_ALL_MODIFIERS);
  };

  static removeModifier = (id) => {
    return createAction(RollAction.REMOVE_MODIFIER, id);
  };

  static updateModifier = (modifier) => {
    return createAction(RollAction.UPDATE_MODIFIER, modifier);
  };

  static unselectAllModifiers = () => {
    return createAction(RollAction.UNSELECT_ALL_MODIFIERS);
  };

  // ========================================
  // USERS ROLLS
  // ========================================

  static addUserDie = (userId, die) => {
    return createAction(RollAction.ADD_USER_DIE, { userId, die });
  };

  static addUserModifier = (userId, modifier) => {
    return createAction(RollAction.ADD_USER_MODIFIER, { userId, modifier });
  };

  static showUserRoll = (userId, show) => {
    return createAction(RollAction.SHOW_USER_ROLL, { userId, show });
  };

  static removeAllUserDice = (userId) => {
    return createAction(RollAction.REMOVE_ALL_USER_DICE, userId);
  };

  static removeAllUserModifiers = (userId) => {
    return createAction(RollAction.REMOVE_ALL_USER_MODIFIERS, userId);
  };

  static removeUserDie = (userId, dieId) => {
    return createAction(RollAction.REMOVE_USER_DIE, { userId, dieId });
  };

  static removeUserModifier = (userId, modifierId) => {
    return createAction(RollAction.REMOVE_USER_MODIFIER, { userId, modifierId });
  };
}
