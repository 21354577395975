// @flow
import styles from './InviteModal.module.css';

import type { Node } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ErrorAction, RoomAction, TableAction } from 'store/actions';
import { ErrorSelector, RoomSelector, SessionSelector, TableSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import { ReactComponent as CheckIcon } from 'images/icons/CheckIcon.svg';
import { ReactComponent as CloneIcon } from 'images/icons/CloneIcon.svg';
import { ReactComponent as SendIcon } from 'images/icons/SendIcon.svg';

type Props = {
  onDismiss: () => void,
};

function InviteModal({ onDismiss }: Props): Node {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const roomGuid = useSelector(TableSelector.getRoomGuid);
  const room = useSelector((state) => RoomSelector.get(state, roomGuid));
  const error = useSelector((state) => ErrorSelector.get(state, RoomAction.INVITE));
  const linkRef = useRef(null);
  const [emails, setEmails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const isOwner = room.userId === currentUser.id;

  useEffect(() => {
    if (isLoading && error) setIsLoading(false);
  }, [error, isLoading]);

  const onCopyClick = () => {
    const el = linkRef.current.inputRef.current;
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  const onSendClick = () => {
    if (isSuccess) return;
    const parsedEmails = emails
      .split('\n')
      .map((l) =>
        l
          .split(',')
          .map((w) => w.split(' '))
          .flat()
      )
      .flat()
      .map((e) => e.trim())
      .filter((e) => e !== '');
    if (parsedEmails.length === 0) return;
    setIsLoading(true);
    batch(() => {
      dispatch(ErrorAction.remove(RoomAction.INVITE));
      dispatch(
        RoomAction.invite(roomGuid, parsedEmails, ({ sent }) => {
          setIsLoading(false);
          setIsSuccess(true);
          logEvent('table - invites sent', {
            count: sent,
            'is owner': room.userId === currentUser.id,
          });
        })
      );
    });
  };

  return (
    <TitleModal title="Play with Friends" subtitle="Invite friends to join your room!" onDismiss={onDismiss}>
      <div className={styles.container}>
        <section>
          <h4>Room Link</h4>
          {room.hasPassword ? (
            <p>
              This link will allow anyone with your room password to join.{' '}
              {isOwner && (
                <>
                  You can{' '}
                  <button
                    className={`button-reset ${styles.passwordButton}`}
                    onClick={() => dispatch(TableAction.showSettings(true))}
                  >
                    edit your password here
                  </button>
                  .
                </>
              )}
            </p>
          ) : (
            <p>
              This link will allow anyone to join.{' '}
              {isOwner && (
                <>
                  You can{' '}
                  <button
                    className={`button-reset ${styles.passwordButton}`}
                    onClick={() => dispatch(TableAction.showSettings(true))}
                  >
                    add a password here
                  </button>
                  .
                </>
              )}
            </p>
          )}
          <div className={styles.inputRow}>
            <TextInput
              ref={linkRef}
              input={{ value: room.inviteUrl }}
              meta={{}}
              type="text"
              autoComplete="off"
              readOnly
              variant="simple"
              className={styles.input}
            />
            <Button onClick={onCopyClick} variant="primary" className={styles.button}>
              <CloneIcon />
            </Button>
          </div>
        </section>
        <hr />
        <section>
          <h4>Invite by Email</h4>
          <p>Enter your friend's email to invite them to join your room.</p>
          <div className={styles.inputRow}>
            <textarea
              value={emails}
              onChange={(e) => setEmails(e.currentTarget.value)}
              placeholder="Add multiple emails separated by commas"
              className={`${styles.input} ${styles.textarea}`}
            />
            <Button
              onClick={onSendClick}
              variant="primary"
              primaryBackground={isSuccess && 'var(--color-green)'}
              secondaryBackground={isSuccess && 'var(--color-teal)'}
              isLoading={isLoading}
              className={styles.button}
            >
              {isSuccess ? <CheckIcon /> : <SendIcon />}
            </Button>
          </div>
          {error && <p className={styles.error}>{error.message}</p>}
        </section>
      </div>
    </TitleModal>
  );
}

export default InviteModal;
