//@flow
import styles from 'components/layouts/SessionPage.module.css';

import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';

import { ErrorAction, SessionAction } from 'store/actions';
import { ErrorSelector, SessionSelector, TableSelector } from 'store/selectors';
import { logEvent } from 'utilities';
import * as fbq from 'utilities/fbq';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import TabsBar from 'components/TabsBar/TabsBar';

type Props = {
  buttonBackground?: string,
  handleSubmit: (a: any) => void,
  isLogIn: boolean,
  onChangeTab: () => void,
  redirectTo?: string,
  showTabs: boolean,
  submitFailed: boolean,
};

const TABS = ['Create Account', 'Log In'];
const validate = (values, props) => {
  const errors = {};
  if (!props.isLogIn && (!values.username || values.username.trim() === '')) errors.username = 'Required';
  if (!values.email || values.email.trim() === '') errors.email = 'Required';
  if (!values.password) errors.password = 'Required';
  return errors;
};

function AuthForm({
  buttonBackground,
  handleSubmit,
  isLogIn,
  onChangeTab,
  redirectTo: propRedirectTo,
  showTabs,
  submitFailed,
}: Props) {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const signUpErrors = useSelector(SessionSelector.getSignUpErrors);
  const logInError = useSelector((state) => ErrorSelector.get(state, SessionAction.SIGN_IN));

  const [showUsernameRule, setShowUsernameRule] = useState(false);
  const [showPasswordRule, setShowPasswordRule] = useState(false);

  const redirectTo = propRedirectTo || new URLSearchParams(search).get('to');
  const usernameRuleClassNames = [styles.signUpRule];
  if (showUsernameRule) usernameRuleClassNames.push(styles.activeRule);
  const passwordRuleClassNames = [styles.signUpRule];
  if (showPasswordRule) passwordRuleClassNames.push(styles.activeRule);

  useEffect(() => {
    dispatch(reset('authForm'));
    setShowUsernameRule(false);
    setShowPasswordRule(false);
  }, [dispatch, isLogIn]);

  useEffect(() => {
    if (fbq.enabled()) {
      fbq.pageView();
    }
    const code = new URLSearchParams(search).get('invite');
    if (code) dispatch(SessionAction.fetchInvite(code));
    return () => dispatch(SessionAction.clearInvite);
  }, [dispatch, search]);

  const onSignUpSubmit = (formValues) => {
    if (signUpErrors.length > 0) dispatch(ErrorAction.removeAll);

    const errors = {};
    if (formValues.email && formValues.email.trim().match('^\\S+@\\S+\\.\\S+$') === null) {
      errors.email = 'Invalid Email';
    }
    if (formValues.username && formValues.username.trim().match('[A-Za-z0-9_.]{2,32}') === null) {
      errors.username = 'Invalid Username';
    }
    if (formValues.password && formValues.password.match('.{6,}') === null) {
      errors.password = 'Invalid Password';
    }
    if (!isEmpty(errors)) throw new SubmissionError(errors);

    return dispatch(
      SessionAction.signUp(formValues, redirectTo, (user) => {
        const from = redirectTo ? decodeURIComponent(redirectTo).split('/')[1] : null;
        logEvent('onboarding - create account', { 'has avatar': !!user.avatarUrl, from, is_preview: !!previewRoom });
        if (fbq.enabled()) fbq.track('CompleteRegistration');
      })
    );
  };

  const onLoginSubmit = (formValues) => {
    if (logInError) dispatch(ErrorAction.removeAll);

    const errors = {};
    if (formValues.email && formValues.email.trim().match('^\\S+@\\S+\\.\\S+$') === null) {
      errors.email = 'Invalid Email';
    }
    if (formValues.password && formValues.password.match('.{6,}') === null) {
      errors.password = 'Invalid Password';
    }
    if (!isEmpty(errors)) throw new SubmissionError(errors);

    logEvent('onboarding - click login', { is_preview: !!previewRoom });
    return dispatch(SessionAction.signIn({ user: { ...formValues } }, redirectTo));
  };

  const renderInput = useMemo(
    () =>
      ({ input, type, placeholder, meta, meta: { touched, error } }) => {
        return (
          <TextInput
            input={input}
            meta={meta}
            placeholder={placeholder}
            showError={(touched && !!error) || submitFailed}
            type={type}
            variant="simple"
          />
        );
      },
    [submitFailed]
  );

  return (
    <div className={styles.authFormContainer}>
      {showTabs && (
        <TabsBar
          variant="theme"
          tabs={TABS}
          currentTab={TABS[isLogIn ? 1 : 0]}
          tabWidth={180}
          className={styles.authFormTabs}
          onChange={onChangeTab}
        />
      )}
      {isLogIn ? (
        <form onSubmit={handleSubmit(onLoginSubmit)} className={styles.signUpForm}>
          <div className={styles.fieldContainer}>
            <Field component={renderInput} name="email" type="text" placeholder="Email Address" />
          </div>
          <div className={styles.fieldContainer}>
            <Field component={renderInput} name="password" type="password" placeholder="Password" />
            <Link to="/forgot-password" className={`${styles.forgotLink} ${styles.link}`}>
              Forgot password?
            </Link>
          </div>
          {logInError && <p className={styles.error}>{logInError.message}</p>}
          <Button variant="primary" className={styles.ctaButton} type="submit" primaryBackground={buttonBackground}>
            Log In
          </Button>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSignUpSubmit)} className={styles.signUpForm}>
          <div className={styles.fieldContainer}>
            <Field component={renderInput} name="email" type="text" placeholder="Email Address" />
          </div>
          <div className={styles.fieldContainer}>
            <Field
              component={renderInput}
              name="username"
              type="text"
              placeholder="Username"
              onFocus={(e) => {
                setShowUsernameRule(true);
              }}
            />
            <p className={usernameRuleClassNames.join(' ')}>
              Your username must be between 2-32 characters. Only letters, numbers, underscores, and periods allowed.
            </p>
          </div>
          <div className={styles.fieldContainer}>
            <Field
              component={renderInput}
              name="password"
              type="password"
              placeholder="Password"
              onFocus={(e) => {
                setShowPasswordRule(true);
              }}
            />
            <p className={passwordRuleClassNames.join(' ')}>Your password must be at least 6 characters long.</p>
          </div>
          <Button variant="primary" className={styles.ctaButton} type="submit" primaryBackground={buttonBackground}>
            Create Account
          </Button>
        </form>
      )}
    </div>
  );
}

AuthForm.defaultProps = {
  isLogIn: true,
  showTabs: false,
};

const mapStateToProps = (state, props) => {
  const search = window.location.search;
  const code = new URLSearchParams(search).get('invite');
  const invite = SessionSelector.getInvite(state);

  return {
    initialValues: {
      code,
      email: invite?.email,
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'authForm',
    validate,
    enableReinitialize: true,
  })
)(AuthForm);
