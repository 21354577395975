import { DeviceError } from 'types/video';

import InlineError from './InlineError';

interface MediaErrorProps {
  audioError?: ReduxError;
  videoError?: ReduxError;
}

export function createDeviceError(key: string, error: DeviceError, device = 'device') {
  let message = '';
  switch (error) {
    case DeviceError.NotAllowedError:
      message = `We do not have permission to access your ${device}. Please update your browser permissions and refresh the page to continue.`;
      break;
    case DeviceError.NotReadableError:
    case DeviceError.AbortError:
    default:
      message = `We are having trouble connecting to your ${device}. It might be in use by another application. Please ensure that it is not in use by another application and refresh the page to continue.`;
      break;
  }
  return { id: key, title: error, message };
}

export default function MediaError({ audioError, videoError }: MediaErrorProps) {
  const hasBoth = audioError && videoError && audioError.title === videoError.title; // If both errors are the same type of error

  let message = audioError?.message ?? videoError?.message;

  if (hasBoth) {
    if (videoError.title === DeviceError.NotAllowedError && audioError.title === DeviceError.NotAllowedError)
      message =
        'We do not have permission to access your camera and microphone. Please update your browser permissions and refresh the page to continue.';
    else
      message =
        'We are having an issue accessing your camera and microphone. They might be in use by another application. Please ensure that they are not in use by another application and refresh the page to continue.';
  }

  return (
    <>
      {hasBoth && message ? (
        <InlineError message={message} />
      ) : (
        [audioError, videoError].filter(Boolean).map((error) => <InlineError key={error.id} message={error.message} />)
      )}
    </>
  );
}
