import styles from './PatronFeatureModal.module.css';

import Modal from './Modal';
import Button from 'components/buttons/Button';

export default function PatronFeatureModal({
  title,
  including,
  onDismiss,
}: {
  title?: string;
  including?: string;
  onDismiss?: () => void;
}) {
  return (
    <Modal className={styles.container} onDismiss={onDismiss}>
      <Modal.Header>
        <Modal.Icon width={25} height={25}>
          <span className={styles.icon}>✪</span>
        </Modal.Icon>
        <Modal.Title>{title ?? 'This Feature is for Patrons!'}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <div>
          <p>
            We aim to keep Role as free & open as possible, so that everyone can play & create together. However,
            hosting video calls, file uploads, and external integrations can be expensive! By becoming a Role Patron,
            you're supporting our ability to provide these services for you and your players.
          </p>
          <p>
            Become a Patron today, and unlock the full Role experience - including{' '}
            {including && (
              <>
                <strong>{including}</strong> and{' '}
              </>
            )}
            a <strong>5% discount</strong> on the Role Marketplace!
          </p>
        </div>

        <Modal.Actions>
          <Button variant="primary" to="/patron" target="_blank" isLinkAsButton className={styles.button}>
            Become a Patron
          </Button>
        </Modal.Actions>
      </Modal.Body>
    </Modal>
  );
}
