import { createAction } from './utilities';

export default class GeneralAction {
  static SHOW_AUDIO_VIDEO_MENU = 'GeneralAction.SHOW_AUDIO_VIDEO_MENU';
  static SHOW_PLAYER_SAFETY = 'GeneralAction.SHOW_PLAYER_SAFETY';
  static SHOW_TABLE_MENU = 'GeneralAction.SHOW_TABLE_MENU';
  static SHOW_USER_MENU = 'GeneralAction.SHOW_USER_MENU';
  static SHOW_WELCOME = 'GeneralAction.SHOW_WELCOME';

  static showAudioVideoMenu = (show) => createAction(GeneralAction.SHOW_AUDIO_VIDEO_MENU, show);

  static showPlayerSafety = (show) => {
    return createAction(GeneralAction.SHOW_PLAYER_SAFETY, show);
  };

  static showTableMenu = (show) => {
    return createAction(GeneralAction.SHOW_TABLE_MENU, show);
  };

  static showUserMenu = (show) => {
    return createAction(GeneralAction.SHOW_USER_MENU, show);
  };

  static showWelcome = (show) => {
    return createAction(GeneralAction.SHOW_WELCOME, show);
  };
}
