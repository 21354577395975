import _ from 'lodash';

export const Suit = {
  NONE: '',
  CLUBS: 'clubs',
  DIAMONDS: 'diamonds',
  HEARTS: 'hearts',
  SPADES: 'spades',
};

export const CardView = {
  PLAYER: 'player',
  TABLE: 'table',
};

export const ShuffleMode = {
  DECK: 'deck',
  DISCARD: 'discard',
  PLAYER: 'hands',
  TABLE: 'table',
};

export class DeckCard {
  constructor(params) {
    this.name = params.name;
    this.value = params.value;
    this.suit = params.suit;
    this.image = params.image;
  }
}

export class Card extends DeckCard {
  constructor(params) {
    super(params);
    this.id = params.id;
    this.isFlipped = params.isFlipped;
    this.isRotated = params.isRotated;
    this.isDiscarded = params.isDiscarded;
    this.isDrawn = params.isDrawn;
    this.userId = params.userId;
    this.updatedAt = Date.now();
  }
}

export const shuffle = (cards) => {
  return _.shuffle(cards).map((card, i) => {
    return { ...card, updatedAt: Date.now() + i };
  });
};

export const DEFAULT_CARDS_SETTINGS = {
  enabled: true,
};
