import { useEffect } from 'react';
import { Room } from 'twilio-video';

export default function useHandleTrackPublicationFailed(room: Room | null) {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', (error) => console.error(error));
      return () => {
        room.localParticipant.off('trackPublicationFailed', (error) => console.error(error));
      };
    }
  }, [room]);
}
