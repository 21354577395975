import videoStyles from 'room/TableUser/TableUser.module.css';

import { useMemo } from 'react';
import { ReactNode } from 'react-markdown';
import { useSelector } from 'react-redux';

import Document from 'models/Document';
import { DocumentSelector, TableSelector } from 'store/selectors';
import { TableViewMode } from 'types/room';
import useScreenShareParticipant from 'video/hooks/useScreenShareParticipant';

import TableMediaView, { TableMediaPreview } from 'room/TableMediaView';
import TableAppView from 'room/TableMediaView/TableAppView';
import TableObrView from 'room/TableMediaView/TableObrView';
import TableScreenshareView from 'room/TableMediaView/TableScreenshareView';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

type ChildrenFunction = (children: { content: ReactNode; previews: ReactNode }) => NonNullable<JSX.Element>;

interface TableShareViewProps {
  children?: ReactNode | ChildrenFunction;
  room: any;
}

export default function TableShareView({ children, room }: TableShareViewProps) {
  const isObrActive = useSelector(TableSelector.isObrActive);

  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);

  // @ts-ignore DocumentSelector is not typed yet
  const activeDoc = useSelector((state) => DocumentSelector.get(state, activeRoomDocument?.id));
  // @ts-ignore
  const sharedDoc = useSelector((state) => DocumentSelector.get(state, sharedRoomDocument?.id));
  // @ts-ignore
  const privateDoc = useSelector((state) => DocumentSelector.get(state, privateRoomDocument?.id));
  const activeAsset = useMemo(() => activeDoc && new Document(activeDoc), [activeDoc]);

  const screenShareParticipant = useScreenShareParticipant();
  const [selectedParticipant] = useSelectedParticipant();

  const viewMode = useSelector(TableSelector.getViewMode);

  const showSharedDocPreview =
    sharedDoc && (!activeDoc || activeRoomDocument.guidId !== sharedRoomDocument.guidId || isObrActive);
  const showPrivateDocPreview =
    privateDoc && (!activeDoc || activeRoomDocument.guidId !== privateRoomDocument.guidId || isObrActive);
  const showScreenSharePreview =
    screenShareParticipant && (activeDoc || selectedParticipant || viewMode === TableViewMode.Grid || isObrActive);

  const showScreenShare =
    screenShareParticipant &&
    viewMode === TableViewMode.Speaker &&
    !activeAsset &&
    !selectedParticipant &&
    !isObrActive;
  const [content, previews] = useMemo(
    () => [
      <>
        {!isObrActive &&
          activeAsset &&
          (activeAsset.isApp() ? (
            <TableAppView key={activeAsset.id} room={room} asset={activeAsset} roomDocument={activeRoomDocument} />
          ) : (
            <TableMediaView key={activeAsset.id} room={room} asset={activeAsset} roomDocument={activeRoomDocument} />
          ))}
        {showScreenShare && <TableScreenshareView room={room} />}
        {isObrActive && <TableObrView room={room} />}
      </>,
      <>
        {showScreenSharePreview && <TableScreenshareView className={videoStyles.tile} isPreview room={room} />}
        {showSharedDocPreview && (
          <TableMediaPreview className={videoStyles.tile} roomDocument={sharedRoomDocument} room={room} />
        )}
        {showPrivateDocPreview && (
          <TableMediaPreview className={videoStyles.tile} roomDocument={privateRoomDocument} room={room} />
        )}
      </>,
    ],
    [
      activeAsset,
      activeRoomDocument,
      isObrActive,
      privateRoomDocument,
      room,
      sharedRoomDocument,
      showPrivateDocPreview,
      showScreenShare,
      showScreenSharePreview,
      showSharedDocPreview,
    ]
  );

  return typeof children === 'function' ? (
    children({ content, previews })
  ) : (
    <>
      {content}
      {previews}
    </>
  );
}
