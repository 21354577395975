import styles from './AssetCard.module.css';

import { Popover, Portal } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, MouseEvent, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentType } from 'models/Document';
import { AssetAction } from 'store/actions';
import { AssetSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import type { DocumentModel } from 'types/common';
import { stylizeTheme } from 'utilities/color';

import Card from './Card';
import { PauseCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import IconButton from 'components/buttons/IconButton';
import MenuList from 'components/MenuList';
import type { MenuItemProps } from 'components/MenuList/MenuList';
import Tag from 'components/Tag';
import { ReactComponent as MoreIcon } from 'images/icons/MoreIcon.svg';

type AssetTag = {
  color?: string;
  title: string;
};

interface AssetCardProps {
  asset: DocumentModel;
  roomDocument?: any;
  className?: string;
  isClickable: boolean;
  menuItems: MenuItemProps[];
  onMainClick?(event: MouseEvent): void;
  onDeleteClick?(event: MouseEvent): void;
  showActions: boolean;
  showClose: boolean;
  tags: AssetTag[];
  theme?: any;
  variant: 'light' | 'theme';
}

function AssetCard({
  asset,
  roomDocument,
  className,
  isClickable,
  menuItems: propMenuItems = [],
  onDeleteClick: propOnDeleteClick,
  onMainClick: propOnMainClick,
  showActions,
  showClose,
  tags = [],
  theme,
  variant = 'light',
}: AssetCardProps) {
  const { id, name, fileUrl, thumbnailUrl, type } = asset;

  const currentUser = useSelector(SessionSelector.currentUser);
  const ownerUser = useSelector((state) =>
    // @ts-ignore RoomUserSelector is not typed yet
    RoomUserSelector.get(state, roomDocument?.guid, roomDocument?.ownerType === 'User' ? roomDocument?.ownerId : null)
  );
  const dispatch = useDispatch();
  const activeAssetMenuGuidOrId = useSelector(AssetSelector.menuAssetId);
  const isMenuActive =
    activeAssetMenuGuidOrId &&
    (roomDocument ? activeAssetMenuGuidOrId === roomDocument?.guidId : activeAssetMenuGuidOrId === id);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const onMenuClick = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      dispatch(AssetAction.showMenu(isMenuActive ? null : roomDocument?.guidId ?? id));
    },
    [dispatch, id, isMenuActive, roomDocument?.guidId]
  );

  const typeLabel = useMemo(() => {
    if (type.toLowerCase() === 'app') return 'Web App';
    else return type;
  }, [type]);

  const menuItems = useMemo(() => {
    const onDeleteClick = (event: MouseEvent) => {
      event.stopPropagation();
      if (propOnDeleteClick) propOnDeleteClick(event);
    };
    if (showClose) {
      return [
        ...propMenuItems,
        {
          label: 'Delete',
          onClick: onDeleteClick,
        },
      ];
    } else {
      return propMenuItems;
    }
  }, [propMenuItems, propOnDeleteClick, showClose]);

  // Right now is only used for audio, but we can expand this
  const onMainClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (propOnMainClick) propOnMainClick();
      else onMenuClick(event);
    },
    [onMenuClick, propOnMainClick]
  );

  return (
    <Popover as={Fragment}>
      <Card
        className={clsx(
          styles.container,
          className,
          isMenuActive && styles.isMenuActive,
          type.toLowerCase() === DocumentType.TOKEN && styles.isToken,
          type.toLowerCase() === DocumentType.MAP && styles.isMap,
          type.toLowerCase() === DocumentType.AUDIO && styles.isAudio
        )}
        forceBorder={!showActions}
        onClick={isClickable ? onMenuClick : undefined}
        isPressed={isClickable && isMenuActive}
      >
        {showActions && (
          <>
            <IconButton
              label="More Options"
              children={<MoreIcon />}
              className={styles.menuButton}
              background="var(--color-dashboard-white)"
              color="var(--color-dashboard-main)"
              activeBackground="var(--color-dashboard-light)"
              hoverBackground="var(--color-dashboard-light)"
              activeColor="var(--color-white)"
              ref={setReferenceElement}
            />

            {isMenuActive && (
              <Portal>
                <Popover.Panel
                  static
                  className={styles.popper}
                  ref={setPopperElement}
                  style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
                  {...attributes.popper}
                >
                  <MenuList items={menuItems} isActive={isMenuActive} className={styles.popperMenu} variant={variant} />
                </Popover.Panel>
              </Portal>
            )}
          </>
        )}
        <div className={styles.preview}>
          {type.toLowerCase() !== DocumentType.AUDIO && <img src={thumbnailUrl ?? undefined} alt={name} />}
          {type.toLowerCase() === DocumentType.AUDIO && fileUrl && (
            <AudioPreview onClickPlay={roomDocument ? onMainClick : undefined} src={fileUrl} />
          )}
        </div>
        <div className={styles.content}>
          <h3 className={styles.name} title={name}>
            {name}
          </h3>
          <div className={styles.tags}>
            <Tag title={typeLabel} />
            {tags.map(({ title, color }, i) => (
              <Tag color={color} title={title} key={i} />
            ))}
          </div>
        </div>
        {roomDocument && ownerUser && roomDocument.ownerId !== currentUser.id && (
          <p className={styles.ownerTag} title={`Added by ${ownerUser.username}`}>
            Added by {ownerUser.username}
          </p>
        )}
      </Card>
    </Popover>
  );
}

function AudioPreview({ onClickPlay, src }: { onClickPlay?: Function; src: string }) {
  const ref = useRef<HTMLAudioElement>(null);
  const [paused, setPaused] = useState(true);

  const onClick = useCallback<MouseEventHandler>(
    (event) => {
      if (onClickPlay) return onClickPlay(event);

      const audio = ref.current;
      event.stopPropagation();
      if (!audio) return;
      audio.currentTime = 0;

      if (audio.paused) audio.play();
      else audio.pause();
      setPaused((p) => !p);
    },
    [onClickPlay]
  );

  const onEnded = useCallback(() => {
    setPaused(true);
  }, []);

  return (
    <div className={styles.audioWrapper}>
      <IconButton
        iconSize={48}
        buttonSize={72}
        background="transparent"
        color="var(--color-dashboard-main)"
        activeColor="var(--color-dashboard-light)"
        children={paused ? <PlayCircleIcon /> : <PauseCircleIcon />}
        onClick={onClick}
      />
      {<audio ref={ref} src={src} onEnded={onEnded} />}
    </div>
  );
}

export default AssetCard;
