// @flow
import styles from './Element.module.css';

import React from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';

import { ELEMENT_TYPE, INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { SheetSelector } from 'store/selectors';
import useToggleLinks from 'hooks/useToggleLinks';

import Markdown from 'components/Sheet2/atoms/Markdown';
import Toggle from 'components/Sheet2/atoms/Toggle';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

// NOTE: TextElement handles its own inputs and toggles and
// do NOT rely on the inputs and onToggleChange function here.
function Element(props: Props): Node {
  const { children, className, colors, element, onChange, readOnly, sectionId, sheetGuid, metaSpace } = props;
  const { id, columns, elementType, items, links } = element;

  const isSomeoneEditing = useSelector((state) => SheetSelector.isSomeoneEditing(state, sheetGuid, id));

  const isText = elementType === ELEMENT_TYPE.text;
  const isTwoColumns = columns === 2;

  const headingInput = !isText ? items.find((o) => o.inputType === INPUT_TYPE.heading) : null;
  const paragraphInput = !isText ? items.find((o) => o.inputType === INPUT_TYPE.paragraph) : null;
  const toggleInput = !isText ? items.find((o) => o.inputType === INPUT_TYPE.toggle) : null;

  const heading = headingInput?.defaultValue ?? '';
  const paragraph = paragraphInput?.defaultValue ?? '';

  const classNames = [styles.container];
  if (isTwoColumns) classNames.push(styles.twoColumns);
  if (isTwoColumns || [ELEMENT_TYPE.text, ELEMENT_TYPE.divider].includes(elementType))
    classNames.push(styles.autoHeight);
  if (isSomeoneEditing) classNames.push(styles.isSomeoneEditing);
  if (className) classNames.push(className);

  useToggleLinks(sheetGuid, toggleInput, links);

  const onToggleChange = (value) => {
    if (!toggleInput) return;
    const updatedItems = element.items.map((o) => (o.id === toggleInput.id ? { ...toggleInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  return (
    <div className={classNames.join(' ')}>
      {!isText && metaSpace && (
        <div className={styles.meta}>
          {toggleInput && (
            <Toggle
              className={styles.toggle}
              value={toggleInput.value ?? toggleInput.defaultValue}
              canEdit={toggleInput.canEdit}
              readOnly={readOnly}
              primaryColor={colors[0]}
              secondaryColor={colors[1]}
              onChange={onToggleChange}
            />
          )}
          {heading && (
            <div className={styles.label} title={heading}>
              {heading}
            </div>
          )}
        </div>
      )}
      <div className={styles.element}>
        {children}
        {isSomeoneEditing && <div className={styles.editing}>Currently being edited by someone else</div>}
      </div>
      {paragraph && <Markdown className={styles.description}>{paragraph}</Markdown>}
    </div>
  );
}

export default Element;
