import { batch } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { createAction, handleError } from './utilities';
import { extractObj as extractSheetObj } from 'store/actions/SheetAction';
import { extractObj as extractSheetTemplateObj } from 'store/actions/SheetTemplateAction';
import { CardSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import GameAction from './GameAction';
import OrganizationAction from './OrganizationAction';
import RoomUserAction from './RoomUserAction';
import role from 'apis/role';

function processRoomResponse({ data, included }) {
  if (Array.isArray(data)) {
    const rooms = data.map(({ id, attributes, relationships }) => {
      const { playkits, users, unlockedProducts } = relationships;
      const playkitIds = playkits.data.map((o) => o.id);
      const userIds = users.data.map((o) => o.id);
      const productIds = unlockedProducts.data.map((o) => o.id);
      return { id, ...attributes, playkitIds, productIds, userIds };
    });
    const roomUsers = [];
    const games = [];
    const organizations = [];

    for (const include of included) {
      const { id, type, attributes } = include;
      const obj = { id, ...attributes };
      if (type === 'roomUser') roomUsers.push(obj);
      else if (type === 'game') games.push(obj);
      else if (type === 'organization') organizations.push(obj);
    }

    return { rooms, roomUsers, games, organizations };
  }

  const { playkits, users, unlockedProducts } = data.relationships;
  const playkitIds = playkits.data.map((o) => o.id);
  const userIds = users.data.map((o) => o.id);
  const productIds = unlockedProducts.data.map((o) => o.id);
  const room = { id: data.id, ...data.attributes, playkitIds, userIds, productIds };
  const roomUsers = [];
  let game = null;
  let organization = null;

  for (const include of included) {
    const { id, type, attributes } = include;
    const obj = { id, ...attributes };
    if (type === 'roomUser') roomUsers.push(obj);
    else if (type === 'game') game = obj;
    else if (type === 'organization') organization = obj;
  }

  return { room, roomUsers, game, organization };
}

class RoomAction {
  static CREATE = 'RoomAction.CREATE';
  static DELETE = 'RoomAction.DELETE';
  static FETCH = 'RoomAction.FETCH';
  static FETCH_ALL = 'RoomAction.FETCH_ALL';
  static JOIN = 'RoomAction.JOIN';
  static LEAVE = 'RoomAction.LEAVE';
  static UPDATE = 'RoomAction.UPDATE';
  static UPDATE_SID = 'RoomAction.UPDATE_SID';
  static INVITE = 'RoomAction.INVITE';

  static CREATE_DECK = 'RoomAction.CREATE_DECK';
  static FETCH_DECK = 'RoomAction.FETCH_DECK';
  static FETCH_DECKS = 'RoomAction.FETCH_DECKS';
  static UPDATE_DECK = 'RoomAction.UPDATE_DECK';

  static FETCH_SHEET_TEMPLATES = 'RoomAction.FETCH_SHEET_TEMPLATES';
  static FETCH_PLAYKITS = 'RoomAction.FETCH_PLAYKITS';
  static FETCH_PLAYKIT_SHEETS = 'RoomAction.FETCH_PLAYKIT_SHEETS';
  static FETCH_PLAYKIT_SHEET_TEMPLATES = 'RoomAction.FETCH_PLAYKIT_SHEET_TEMPLATES';
  static SET_ROOM_THEME = 'RoomAction.SET_ROOM_THEME';
  static SET_ROOM_BACKGROUND = 'RoomAction.SET_ROOM_BACKGROUND';

  static create =
    (formValues, callback = null, errorCallback = null) =>
    async (dispatch) => {
      return role.post('/rooms', formValues).then(
        (response) => {
          const { room, game, organization } = processRoomResponse(response.data);
          batch(() => {
            dispatch(createAction(RoomAction.CREATE, room));
            if (game) dispatch(createAction(GameAction.FETCH, game));
            if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
          });
          logEvent('table - create table', {
            'has password': room.hasPassword,
            'has playkits': room.playkitIds.length > 0,
            'playkit ids': room.playkitIds,
            'playkit title': formValues.playkit_title,
            'game id': room.gameId,
            'game title': formValues.game_title,
            'is_preview': formValues.is_preview,
          });
          callback?.(room);
        },
        ({ response }) => {
          handleError(dispatch, RoomAction.CREATE, response);
          if (errorCallback) errorCallback(formValues);
        }
      );
    };

  static delete = (guid, callback) => async (dispatch) => {
    return role.delete(`/rooms/${guid}`).then(
      (response) => {
        dispatch(createAction(RoomAction.DELETE, guid));
        logEvent('table - delete table');
        if (callback) callback(guid);
      },
      ({ response }) => handleError(dispatch, RoomAction.DELETE, response)
    );
  };

  static fetch = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}`).then(
      (response) => {
        const { room, roomUsers, game, organization } = processRoomResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomAction.FETCH, room));
          dispatch(createAction(RoomUserAction.FETCH_ALL, roomUsers));
          if (game) dispatch(createAction(GameAction.FETCH, game));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
        });
        callback?.(room);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH, response)
    );
  };

  static fetchAll = (callback) => async (dispatch) => {
    return role.get('/rooms').then(
      (response) => {
        const { rooms, roomUsers, games, organizations } = processRoomResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomAction.FETCH_ALL, rooms));
          dispatch(createAction(RoomUserAction.FETCH_ALL, roomUsers));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });
        callback?.(rooms);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_ALL, response)
    );
  };

  static join = (guid, formValues, callback) => async (dispatch) => {
    return role.post(`/rooms/${guid}/join`, formValues).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomAction.JOIN, obj));
        logEvent('table - join table');
        if (callback) callback(obj);
      },
      ({ response }) => {
        handleError(dispatch, RoomAction.JOIN, response);
        throw new SubmissionError(response?.data ?? { password: 'Invalid' });
      }
    );
  };

  static leave = (guid, callback) => async (dispatch) => {
    return role.post(`/rooms/${guid}/leave`).then(
      (response) => {
        dispatch(createAction(RoomAction.LEAVE, guid));
        logEvent('table - leave table');
        if (callback) callback(guid);
      },
      ({ response }) => handleError(dispatch, RoomAction.LEAVE, response)
    );
  };

  static removeBackgroundArt = (guid, callback) => async (dispatch, getState) => {
    const data = { socket_id: SessionSelector.getSocketId(getState()) };
    return role.post(`/rooms/${guid}/remove_background_art`, data).then(
      (response) => {
        const { room, game } = processRoomResponse(response.data);
        dispatch(createAction(RoomAction.UPDATE, room));
        if (game) dispatch(createAction(GameAction.FETCH, game));
        callback?.(room);
      },
      ({ response }) => handleError(dispatch, RoomAction.UPDATE, response)
    );
  };

  static update = (guid, formValues, callback) => async (dispatch, getState) => {
    const data = { ...formValues, socket_id: SessionSelector.getSocketId(getState()) };
    return role.patch(`/rooms/${guid}`, data).then(
      (response) => {
        const { room, game, organization } = processRoomResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomAction.UPDATE, room));
          if (game) dispatch(createAction(GameAction.FETCH, game));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
        });
        callback?.(room);
      },
      ({ response }) => handleError(dispatch, RoomAction.UPDATE, response)
    );
  };

  static updateBackgroundArt = (guid, image, callback) => async (dispatch, getState) => {
    const formData = new FormData();
    if (image) formData.append('room[background_art]', image);
    formData.append('socket_id', SessionSelector.getSocketId(getState()));

    return role.patch(`/rooms/${guid}`, formData).then(
      (response) => {
        const { room, game, organization } = processRoomResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomAction.UPDATE, room));
          if (game) dispatch(createAction(GameAction.FETCH, game));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
        });
        callback?.(room);
      },
      ({ response }) => handleError(dispatch, RoomAction.UPDATE, response)
    );
  };

  static updateSid = (guid, sid) => {
    return createAction(RoomAction.UPDATE_SID, { guid, sid });
  };

  static invite = (guid, emails, callback) => async (dispatch) => {
    return role.post(`/rooms/${guid}/invite`, { emails }).then(
      (response) => callback?.(response.data),
      ({ response }) => handleError(dispatch, RoomAction.INVITE, response)
    );
  };

  // ========================================
  // DECKS
  // ========================================

  static createDeck =
    (guid, deck, callback = null) =>
    async (dispatch) => {
      return role.post(`/rooms/${guid}/decks`, deck).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomAction.CREATE_DECK, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, RoomAction.CREATE_DECK, response)
      );
    };

  static fetchDeck = (guid, id, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/decks/${id}`).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomAction.FETCH_DECK, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_DECK, response)
    );
  };

  static fetchDecks = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/decks`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          const { id, attributes } = obj;
          return { id, ...attributes };
        });
        dispatch(createAction(RoomAction.FETCH_DECKS, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_DECKS, response)
    );
  };

  static updateCard =
    (guid, card, callback = null) =>
    async (dispatch, getState) => {
      const state = getState();
      const deckId = CardSelector.getCurrentDeck(state);
      const deck = CardSelector.getDeck(state, deckId);
      const cards = { ...deck.cards, [card.id]: card };
      const data = { room_deck: { cards }, socket_id: SessionSelector.getSocketId(state) };
      return role.patch(`/rooms/${guid}/decks/${deck.id}`, data).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomAction.UPDATE_DECK, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, RoomAction.UPDATE_DECK, response)
      );
    };

  static updateDeckCard =
    (guid, deckId, card, callback = null) =>
    async (dispatch, getState) => {
      const state = getState();
      const deck = CardSelector.getDeck(state, deckId);
      const cards = { ...deck.cards, [card.id]: card };
      const data = { room_deck: { cards }, socket_id: SessionSelector.getSocketId(state) };
      return role.patch(`/rooms/${guid}/decks/${deck.id}`, data).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomAction.UPDATE_DECK, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, RoomAction.UPDATE_DECK, response)
      );
    };

  static updateDeckCards =
    (guid, deckId, callback = null) =>
    async (dispatch, getState) => {
      const deck = CardSelector.getDeck(getState(), deckId);
      const data = { room_deck: { cards: deck.cards }, socket_id: SessionSelector.getSocketId(getState()) };
      return role.patch(`/rooms/${guid}/decks/${deck.id}`, data).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomAction.UPDATE_DECK, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, RoomAction.UPDATE_DECK, response)
      );
    };

  // ========================================
  // SHEET TEMPLATES
  // ========================================

  static fetchSheetTemplates = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/sheet_templates`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          return extractSheetTemplateObj(obj);
        });
        dispatch(createAction(RoomAction.FETCH_SHEET_TEMPLATES, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_SHEET_TEMPLATES, response)
    );
  };

  // ========================================
  // PLAYKITS
  // ========================================

  static fetchPlaykits = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/playkits`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          const { id, attributes } = obj;
          return { id, ...attributes };
        });
        dispatch(createAction(RoomAction.FETCH_PLAYKITS, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_PLAYKITS, response)
    );
  };

  static fetchPlaykitSheets = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/playkits/sheets`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          return extractSheetObj(obj);
        });
        dispatch(createAction(RoomAction.FETCH_PLAYKIT_SHEETS, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_PLAYKIT_SHEETS, response)
    );
  };

  static fetchPlaykitSheetTemplates = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/playkits/sheet_templates`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          return extractSheetTemplateObj(obj);
        });
        dispatch(createAction(RoomAction.FETCH_PLAYKIT_SHEET_TEMPLATES, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomAction.FETCH_PLAYKIT_SHEET_TEMPLATES, response)
    );
  };

  static setRoomTheme = (theme) => createAction(RoomAction.SET_ROOM_THEME, theme);

  static setRoomBackground = (backgroundArtUrl) => createAction(RoomAction.SET_ROOM_BACKGROUND, backgroundArtUrl);
}

export default RoomAction;
