// @flow
import React from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';
import Note from './Note';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function NoteBuilder(props: Props) {
  const { element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;

  const noteInput = items.find((item) => item.inputType === INPUT_TYPE.note);
  const noteValue = noteInput?.defaultValue ?? '';

  const onNoteChange = (value) => {
    if (!noteInput) return;
    const updatedItems = items.map((o) => (o.id === noteInput.id ? { ...noteInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      <Note value={noteValue} onChange={onNoteChange} />
    </Builder>
  );
}

const MemoizedNoteBuilder = React.memo(NoteBuilder);
MemoizedNoteBuilder.displayName = 'MemoizedNoteBuilder';

export default MemoizedNoteBuilder;
