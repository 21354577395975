import styles from './DieIcon.module.css';

import clsx from 'clsx';

import { D2, D4, D6, D8, D10, D12, D20, D100, DICE, DieType } from 'models/Die';

import { ReactComponent as D2Icon } from 'images/dice/D2.svg';
import { ReactComponent as D4Icon } from 'images/dice/D4.svg';
import { ReactComponent as D6Icon } from 'images/dice/D6.svg';
import { ReactComponent as D8Icon } from 'images/dice/D8.svg';
import { ReactComponent as D10Icon } from 'images/dice/D10.svg';
import { ReactComponent as D12Icon } from 'images/dice/D12.svg';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';
import { ReactComponent as D100Icon } from 'images/dice/D100.svg';
import { ReactComponent as DFIcon } from 'images/dice/DF.svg';
import { ReactComponent as HexIcon } from 'images/dice/HexIcon.svg';

export default function DieIcon({ die, className }: { die: any; className?: string }) {
  if (die.type === DieType.FATE) {
    return (
      <span className={clsx(className)} data-die="DF">
        <DFIcon />
      </span>
    );
  }

  const IconComponent = getIconComponent(die.sides);
  const isCustom = !DICE.find((d) => d.sides === die.sides && d.type === die.type);

  return (
    <span className={clsx(styles.container, className)}>
      <IconComponent />
      {isCustom && <span className={styles.number}>{die.sides}</span>}
    </span>
  );
}

function getIconComponent(sides: number) {
  switch (sides) {
    case D2.sides:
      return D2Icon;
    case D4.sides:
      return D4Icon;
    case D6.sides:
      return D6Icon;
    case D8.sides:
      return D8Icon;
    case D10.sides:
      return D10Icon;
    case D12.sides:
      return D12Icon;
    case D20.sides:
      return D20Icon;
    case D100.sides:
      return D100Icon;
    default:
      return HexIcon;
  }
}
