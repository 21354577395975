// @flow
import styles from './FieldBuff.module.css';

import React, { memo } from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Buff } from 'components/Sheet2/elements/BuffElement';
import { Builder } from 'components/Sheet2/elements/Element';
import { Field } from 'components/Sheet2/elements/FieldElement';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function FieldBuffBuilder(props: Props) {
  const { colors, element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;

  const buffInput = items.find((item) => item.inputType === INPUT_TYPE.buff);
  const buffValue = buffInput?.defaultValue ?? '';
  const fieldInput = items.find((item) => item.inputType === INPUT_TYPE.field);
  const fieldValue = fieldInput?.defaultValue ?? '';

  const onBuffChange = (value) => {
    if (!buffInput) return;
    const updatedItems = items.map((o) => (o.id === buffInput.id ? { ...buffInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onFieldChange = (value) => {
    if (!fieldInput) return;
    const updatedItems = items.map((o) => (o.id === fieldInput.id ? { ...fieldInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      <div className={styles.container}>
        <Field className={styles.field} value={fieldValue} onChange={onFieldChange} />
        <Buff
          className={styles.buff}
          value={buffValue}
          canEdit={buffInput?.canEdit}
          isBuilding={true}
          primaryColor={colors[0]}
          secondaryColor={colors[1]}
          onChange={onBuffChange}
        />
      </div>
    </Builder>
  );
}

const MemoizedFieldBuffBuilder = memo(FieldBuffBuilder);
MemoizedFieldBuffBuilder.displayName = 'MemoizedFieldBuffBuilder';

export default MemoizedFieldBuffBuilder;
