//@flow
import styles from './dashboard.module.css';

import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DocumentSelector, SessionSelector, SheetSelector, SheetTemplateSelector } from 'store/selectors';

export const MATERIALTYPES = {
  sheet: 'sheet',
  template: 'sheet-template',
  asset: 'asset',
  purchases: 'purchases',
};

type Props = {
  activeMaterial: string,
};

function MaterialsSidebar({ activeMaterial }: Props) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const sheets = useSelector((state) => (currentUser ? SheetSelector.getAllByUser(currentUser.id)(state) : []));
  const createdTemplates = useSelector(SheetTemplateSelector.getUsersTemplates);
  const savedTemplates = useSelector(SheetTemplateSelector.getSavedTemplates);
  const unlockedTemplates = useSelector(SheetTemplateSelector.getUnlockedTemplates);
  const assets = useSelector((state) => (currentUser ? DocumentSelector.getAllByUser(currentUser.id)(state) : []));
  const purchasedAssets = useSelector(DocumentSelector.getUserUnlockedDocuments);
  const sheetsCount = sheets.length;
  const createdTemplatesCount = createdTemplates.length;
  const savedTemplatesCount = savedTemplates.length;
  const templatesCount = createdTemplatesCount + savedTemplatesCount + unlockedTemplates.length;
  const assetsCount = assets.length;
  const purchasedCount = purchasedAssets.length;

  return (
    <nav>
      <Link
        to="/purchases"
        className={clsx(styles.sidebarLink, activeMaterial === MATERIALTYPES.purchases && styles.isActive)}
      >
        <span className={styles.sidebarLinkLabel}>Purchased Assets</span>
        <span className={styles.sidebarLinkStat}>{purchasedCount}</span>
        <span className={styles.sidebarLinkButton}>View</span>
      </Link>
      <Link
        to="/assets"
        className={clsx(styles.sidebarLink, activeMaterial === MATERIALTYPES.asset && styles.isActive)}
      >
        <span className={styles.sidebarLinkLabel}>Your Assets</span>
        <span className={styles.sidebarLinkStat}>{assetsCount}</span>
        <span className={styles.sidebarLinkButton}>View</span>
      </Link>
      <Link
        to="/sheet-templates"
        className={clsx(styles.sidebarLink, activeMaterial === MATERIALTYPES.template && styles.isActive)}
      >
        <span className={styles.sidebarLinkLabel}>Templates</span>
        <span className={styles.sidebarLinkStat}>{templatesCount}</span>
        <span className={styles.sidebarLinkButton}>View</span>
      </Link>
      <Link
        to="/sheets"
        className={clsx(styles.sidebarLink, activeMaterial === MATERIALTYPES.sheet && styles.isActive)}
      >
        <span className={styles.sidebarLinkLabel}>Sheets</span>
        <span className={styles.sidebarLinkStat}>{sheetsCount}</span>
        <span className={styles.sidebarLinkButton}>View</span>
      </Link>
    </nav>
  );
}

export default MaterialsSidebar;
