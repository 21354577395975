import { createSelector } from 'reselect';

import SessionAction from 'store/actions/SessionAction';
import { capitalize } from 'utilities';

export default class SessionSelector {
  static currentUser = (state) => state.session.user;

  static getInvite = (state) => state.session.invite;

  static getRedirectToPath = (state) => state.session.redirectToPath;

  static getSocketId = (state) => state.session.socketId;

  static getToken = (state) => state.session.token;

  static isAuthed = (state) => !!state.session.token;

  static getSignUpErrors = createSelector(
    (state) => state.errors[SessionAction.SIGN_UP],
    (errors) => (errors ? Object.entries(errors.message.errors) : [])
  );

  static getPasswordResetError = createSelector(
    (state) => state.errors[SessionAction.PASSWORD_RESET],
    (serverError) =>
      serverError
        ? Object.entries(serverError.message.errors).map(([key, value]) => {
            if (key === 'reset_password_token')
              return 'Your reset password link has expired. Please request a new reset password link.';
            else return `${capitalize(key)} ${value[0]}`;
          })
        : []
  );

  static getUserFte = createSelector(
    (state) => state.session.user?.metadata?.fte,
    (fte) => ({
      showMultiStep: fte?.showMultiStep ?? false,
      step: fte?.step ?? 0,
      genre: fte?.genre ?? null,
      showRoomCreate: fte?.showRoomCreate ?? false,
      gameQuery: fte?.gameQuery ?? null,
    })
  );

  static showSPGBanner = createSelector(
    (state) => state.session.user?.metadata,
    (metadata) => metadata?.febSPGBanner ?? true
  );

  static getMetadataItem = (state, key) => this.currentUser(state)?.metadata?.[key];
}
