// @flow
import React from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';
import Counter from './Counter';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function CounterBuilder(props: Props) {
  const { colors, element, onChange: propOnChange, sectionId, sheetGuid } = props;
  const { columns, items } = element;

  const currentInput = items.find((o) => o.inputType === INPUT_TYPE.counterCurrent);
  const maxInput = items.find((o) => o.inputType === INPUT_TYPE.counterMax);

  const currentValue = currentInput?.defaultValue ?? '0';
  const maxValue = maxInput?.defaultValue;
  const values = [currentValue];
  if (maxInput) values.push(maxValue);

  const onChange = (values: string[]) => {
    if (!currentInput && !maxInput) return;
    const updatedItems = items.map((o) => {
      if (currentInput && o.id === currentInput.id) {
        return { ...currentInput, defaultValue: values[0] };
      } else if (maxInput && o.id === maxInput.id && values.length === 2) {
        return { ...maxInput, defaultValue: values[1] };
      } else {
        return o;
      }
    });
    const updatedElement = { ...element, items: updatedItems };
    propOnChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      <Counter
        values={values}
        columns={columns}
        canEditCurrent
        canEditMax={!!maxInput && maxInput.canEdit}
        isBuilding={true}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onChange={onChange}
      />
    </Builder>
  );
}

const MemoizedCounterBuilder = React.memo(CounterBuilder);
MemoizedCounterBuilder.displayName = 'MemoizedCounterBuilder';

export default MemoizedCounterBuilder;
