import styles from './NetworkQuality.module.css';

import clsx from 'clsx';
import type { Node } from 'react';

export default function NetworkQuality({ quality }: { quality: number }): Node {
  const qualityClassName = `networkQuality${quality}`;
  let title = '';
  switch (quality) {
    case 5:
      title = 'Excellent';
      break;
    case 4:
      title = 'Great';
      break;
    case 3:
      title = 'Good';
      break;
    case 2:
      title = 'Poor';
      break;
    case 1:
      title = 'Very Poor';
      break;
    case 0:
      title = 'Reconnecting';
      break;
    default:
      title = '';
  }

  return (
    <div title={title} className={clsx(styles.networkQuality, styles[qualityClassName])}>
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
      <div className={styles.networkQualityBar} />
    </div>
  );
}
