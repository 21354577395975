// @flow
import styles from './IconButton.module.css';

import type { Node } from 'react';
import React from 'react';

import type { IconButtonProps } from './IconButton';
import IconButton from './IconButton';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

type ArrowButtonProps = {
  ...IconButtonProps,
  direction: 'up' | 'down',
  disabled: ?boolean,
};

function ArrowButton(props: ArrowButtonProps): Node {
  const classNames = [styles.arrowIcon];
  if (props.direction === 'up') {
    classNames.push(styles.upArrow);
  } else {
    classNames.push(styles.downArrow);
  }
  if (props.className) classNames.push(props.className);
  if (props.disabled) classNames.push(styles.disabled);

  return (
    <IconButton
      {...props}
      className={classNames.join(' ')}
      isActive={!props.disabled}
      activeBackground="none"
      background="none"
      children={<SingleArrowIcon />}
    />
  );
}

ArrowButton.defaultProps = {
  direction: 'up',
};

export default ArrowButton;
