import styles from './PageLoading.module.css'

import React, { Component } from 'react'

import LoadingSpinner from 'components/LoadingSpinner';
import Panel from 'components/Panel';

class PageLoading extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Panel className={styles.content}>
          <LoadingSpinner color="var(--color-dark-text)" />
          <div className={`heading2 is-centered ${styles.heading}`}>Loading...</div>
        </Panel>
      </div>
    )
  }
}

export default PageLoading
