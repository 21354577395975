import styles from './Asset.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AssetImage from './AssetImage';

import AssetAction from 'store/actions/AssetAction';
import AssetSelector from 'store/selectors/AssetSelector';
import SessionSelector from 'store/selectors/SessionSelector';

import { ReactComponent as LinkExternalIcon } from 'images/icons/LinkExternalIcon.svg';

class Asset extends Component {
  getTypeStyle = () => {
    const {
      asset: { type },
    } = this.props;
    const className = `is${type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}`;
    return styles[className];
  };

  onClick = (event) => {
    event.stopPropagation();
    const {
      asset: { id },
      isMenuOpen,
      menuItems,
      showMenu,
    } = this.props;
    if (menuItems.length === 0) return;
    isMenuOpen ? showMenu(null) : showMenu(id);
  };

  renderMenu = () => {
    return (
      <ul onClick={(e) => e.stopPropagation()} className={`box-shadow unstyled ${styles.menu}`}>
        {this.renderMenuItems()}
      </ul>
    );
  };

  renderMenuItems = () => {
    return this.props.menuItems.map((item, i) => {
      return (
        <li key={i} className={styles.menuItem}>
          {this.renderMenuItem(item)}
          <div className={styles.menuBorder} />
        </li>
      );
    });
  };

  renderMenuItem = (item) => {
    const { label, externalUrl, onClick } = item;

    if (externalUrl) {
      return (
        <a href={externalUrl} className={`heading2 ${styles.menuLink}`} target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    } else {
      return (
        <button onClick={onClick} className={`button-reset heading2 ${styles.menuLink}`}>
          {label}
        </button>
      );
    }
  };

  render() {
    const { currentUser, asset, isMenuOpen, showOwnership, className, onClick } = this.props;
    const { externalUrl, name, type, thumbnailUrl } = asset;
    const isShared = asset.userId !== currentUser.id;
    const classNames = [styles.container, this.getTypeStyle()];
    if (isShared) classNames.push(styles.isShared);
    if (className) classNames.push(className);

    return (
      <div onClick={onClick ? () => onClick(asset) : this.onClick} className={classNames.join(' ')} tabIndex="0">
        {!!externalUrl && (
          <span className={styles.externalUrlIndicator}>
            <LinkExternalIcon />
          </span>
        )}
        <AssetImage name={name} url={thumbnailUrl} />
        <div className={styles.title}>{name}</div>
        {!asset.isToken() && <div className={styles.type}>{externalUrl ? 'External Link' : type}</div>}
        {showOwnership && <div className={styles.ownership}>{isShared ? 'Shared' : 'Yours'}</div>}
        {isMenuOpen && this.renderMenu()}
      </div>
    );
  }
}

Asset.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

Asset.defaultProps = {
  showOwnership: false,
  menuItems: [],
};

const mapStateToProps = (state, props) => {
  const menuAssetId = AssetSelector.menuAssetId(state);
  return {
    currentUser: SessionSelector.currentUser(state),
    isMenuOpen: menuAssetId && menuAssetId === props.asset.id,
  };
};

const mapDispatchToProps = {
  showMenu: AssetAction.showMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
