import { batch } from 'react-redux';

import { createAction, handleError } from './utilities';

import GameAction from './GameAction';
import ProductAction from './ProductAction';
import role from 'apis/role';

function processOrderResponse({ data, included }) {
  if (Array.isArray(data)) {
    const orders = data.map(({ id, attributes, relationships }) => {
      const productIds = relationships.products.data.map(({ id }) => id);
      const obj = { id, ...attributes, productIds };
      return obj;
    });
    const games = [];
    const products = [];

    for (const { id, type, attributes, relationships } of included) {
      const productIds = relationships?.products?.data.map(({ id }) => id);
      const obj = { id, ...attributes, productIds };

      if (type === 'game') games.push(obj);
      else if (type === 'product') products.push(obj);
    }

    return { orders, games, products };
  }
}

export default class OrderAction {
  static FETCH_ALL = 'OrderAction.FETCH_ALL';

  static fetchAll = (callback) => (dispatch) =>
    role.get('/orders').then(
      (response) => {
        const { orders, games, products } = processOrderResponse(response.data);

        batch(() => {
          // TODO: Add orders to redux?
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
        });

        callback?.({ orders, games, products });
      },
      ({ response }) => handleError(dispatch, OrderAction.FETCH_ALL, response)
    );
}
