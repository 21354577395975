// @flow
import styles from './dashboard.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import history from 'browserHistory';
import { DEFAULT_COLORS } from 'models/Color';
import {
  DocumentAction,
  RoomAction,
  RoomSheetAction,
  RoomUserAction,
  SheetAction,
  SheetTemplateAction,
} from 'store/actions';
import { RoomSelector, RoomUserSelector, SessionSelector, SheetSelector, SheetTemplateSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import SheetCard from 'components/cards/SheetCard';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import RemoveSheetModal from 'components/RemoveSheetModal';
import Sheet from 'components/Sheet2';
import TitlePanel from 'components/TitlePanel';
import UpdateSheetModal from 'components/UpdateSheetModal/UpdateSheetModal';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import MaterialsSidebar, { MATERIALTYPES } from 'pages/dashboard/MaterialsSidebar';

const SPINNER_COLOR = 'var(--color-dark-text)';

function SheetsPage(props): Node {
  const {
    match: {
      params: { sheetId },
    },
  } = props;

  const dispatch = useDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTOCOpen, setIsTOCOpen] = useState(false);
  const [isCopyingSheet, setIsCopyingSheet] = useState(false);

  const currentUser = useSelector(SessionSelector.currentUser);
  const sheets = useSelector((state) => (currentUser ? SheetSelector.getAllByUser(currentUser.id)(state) : []));
  const sheetsCount = sheets.length;
  const sheet = useSelector((state) => (sheetId ? SheetSelector.get(state, sheetId) : null));
  const template = useSelector((state) =>
    sheet && sheet.sheetTemplateGuidSlug ? SheetTemplateSelector.get(state, sheet.sheetTemplateGuidSlug) : null
  );
  const sheetMeta = template ? { name: template.name, username: template.ownerName } : null;
  const sheetRooms = useSelector((state) => (sheet ? RoomSelector.getBySheet(state, sheet.id) : []));
  const sheetColors = useSelector((state) => {
    if (sheet) {
      if (sheetRooms.length > 0) {
        return RoomUserSelector.getColors(state, sheetRooms[0].guid, currentUser.id);
      } else {
        return DEFAULT_COLORS;
      }
    } else {
      return null;
    }
  });
  const sheetToRemove = useSelector(SheetSelector.getSheetToRemove);
  const sheetToUpdate = useSelector(SheetSelector.getSheetToUpdate);
  const isDdbSheet = !!sheet?.ddbId;

  const onRemoveSheetModalDismiss = () => dispatch(SheetAction.requestRemove(false));
  const onUpdateSheetModalDismiss = () => dispatch(SheetAction.requestUpdate(false));

  useEffect(() => {
    setIsLoading(true);
    batch(() => {
      dispatch(
        RoomAction.fetchAll((rooms) => {
          const guids = rooms.map((o) => o.guid);
          guids.forEach((guid) => dispatch(RoomSheetAction.fetchAll(guid)));
          guids.forEach((guid) => dispatch(RoomUserAction.fetchAll(guid)));
        })
      );
      dispatch(
        SheetAction.fetchAll(null, () => {
          setIsLoading(false);
          setHasFetched(true);
        })
      );
      dispatch(SheetTemplateAction.fetchAll());
      dispatch(SheetTemplateAction.fetchSaved());
      dispatch(DocumentAction.fetchAll());
    });
  }, [dispatch]);

  useEffect(() => {
    if (sheet && !sheet.data) {
      dispatch(SheetAction.fetch(sheetId));
    }
  }, [dispatch, sheet, sheetId]);

  const onChangeAvatar = (id, file, callback = null) => {
    if (file) dispatch(SheetAction.updateAvatar(id, file, callback));
    else dispatch(SheetAction.removeAvatar(id, callback));
  };

  const debouncedUpdateSheet = useMemo(
    () =>
      _.debounce(
        (model, roomGuid, callback = null) => dispatch(SheetAction.updateAndForget(model, roomGuid, callback)),
        300
      ),
    [dispatch]
  );
  const onChangeSheet = (model, callback = null) => {
    batch(() => {
      dispatch(SheetAction.change(model));
      debouncedUpdateSheet(model, null, callback);
    });
  };

  const debouncedUpdateElement = useMemo(
    () =>
      _.debounce(
        (id, sectionId, element, roomGuid, callback) =>
          dispatch(SheetAction.updateElement(id, sectionId, element, roomGuid, callback)),
        300
      ),
    [dispatch]
  );
  const onChangeElement = (id, sectionId, element, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeElement(id, sectionId, element));
      debouncedUpdateElement(id, sectionId, element, null, callback);
    });
  };

  const debouncedUpdateGroupElement = useMemo(
    () =>
      _.debounce(
        (id, sectionId, groupId, element, roomGuid, callback) =>
          dispatch(SheetAction.updateGroupElement(id, sectionId, groupId, element, roomGuid, callback)),
        300
      ),
    [dispatch]
  );
  const onChangeGroupElement = (id, sectionId, groupId, element, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeGroupElement(id, sectionId, groupId, element));
      debouncedUpdateGroupElement(id, sectionId, groupId, element, null, callback);
    });
  };

  const debouncedUpdateSection = useMemo(
    () =>
      _.debounce(
        (id, section, roomGuid, callback) => dispatch(SheetAction.updateSection(id, section, roomGuid, callback)),
        300
      ),
    [dispatch]
  );
  const onChangeSection = (id, section, callback = null) => {
    batch(() => {
      dispatch(SheetAction.changeSection(id, section));
      debouncedUpdateSection(id, section, null, callback);
    });
  };

  const onCopySheetClick = () => {
    if (isCopyingSheet) return;
    setIsCopyingSheet(true);
    dispatch(
      SheetAction.copy(sheet.id, (newSheet) => {
        setIsCopyingSheet(false);
        history.push(`/sheets/${newSheet.id}`);
      })
    );
  };

  const menuItems = [{ label: 'Close', onClick: () => history.push('/sheets') }];
  if (!isDdbSheet) menuItems.unshift({ label: 'Create Copy', onClick: onCopySheetClick });

  const onCreateClick = useCallback(() => history.push('/sheet-templates/new'), []);

  return (
    <DashboardLayout sidebarChildren={<MaterialsSidebar activeMaterial={MATERIALTYPES.sheet} />}>
      <Metatags title="Your Sheets" />

      {sheet && sheet.data && (
        <Sheet
          className={styles.sheet}
          model={sheet}
          colors={sheetColors}
          tocMeta={sheetMeta}
          userName={currentUser.username}
          menuItems={menuItems}
          isTOCOpen={isTOCOpen}
          onChangeAvatar={onChangeAvatar}
          onChange={onChangeSheet}
          onChangeElement={onChangeElement}
          onChangeGroupElement={onChangeGroupElement}
          onChangeSection={onChangeSection}
          onChangeTOCOpen={setIsTOCOpen}
        />
      )}

      <div
        className={clsx(
          styles.content,
          sheet && styles.hasSheet,
          sheet && 'dashboard_has-sheet',
          isTOCOpen && styles.isShowingToc,
          styles.hasSidebar
        )}
      >
        <TitlePanel
          title="Your Sheets"
          subtitle={sheetsCount > 0 && `You've created ${sheetsCount} ${pluralize(sheetsCount, 'sheet')}`}
        >
          {sheetsCount > 0 && (
            <div className={styles.cards}>
              {sheets.map((o) => (
                <SheetCard key={o.id} sheet={o} isViewing={sheet && sheet.id === o.id} showActions showClose />
              ))}
            </div>
          )}
          {hasFetched && sheetsCount === 0 && (
            <div className={styles.empty}>
              <h4 className={styles.emptyHeader}>You have not created any sheets yet</h4>
              <p className={styles.emptyBody}>
                To enjoy playing RPGs on Role, start by creating a new sheet template below
              </p>
              <Button variant="primary" className={styles.emptyCta} onClick={onCreateClick} icon={<PlusIcon />}>
                Create Sheet Template
              </Button>
            </div>
          )}
          {isLoading && sheetsCount === 0 && <LoadingSpinner color={SPINNER_COLOR} />}
        </TitlePanel>
      </div>

      {sheetToRemove && <RemoveSheetModal sheet={sheetToRemove} onDismiss={onRemoveSheetModalDismiss} />}
      {sheetToUpdate && <UpdateSheetModal sheet={sheetToUpdate} onDismiss={onUpdateSheetModalDismiss} />}
    </DashboardLayout>
  );
}

export default compose(requireAuth, requireCurrentUser)(SheetsPage);
