import { createSelector } from 'reselect';

interface StateStub {
  audioPlayer: {
    guidId: string | null;
  };
}

export default class AudioPlayerSelector {
  static getActiveAudioAsset = createSelector(
    [(state: StateStub) => state.audioPlayer.guidId, (state) => state.roomDocuments, (state) => state.documents],
    (guidId, roomDocuments, documents) => (guidId ? documents[roomDocuments[guidId]?.id] : null)
  );
}
