// @flow
import styles from './TopBar.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import { VERSION } from 'models/Constants';

type Props = {
  username: ?string,
  rainbowOnly?: boolean,
};

function TopBar({ username, rainbowOnly }: Props): Node {
  return (
    <div className={clsx(styles.container, rainbowOnly && styles.rainbowOnly)}>
      {!rainbowOnly && (
        <div className={styles.section}>
          <div className={styles.title}>Early Access</div>
          <a
            className={styles.subtitle}
            href="https://www.playrole.com/changelog"
            target="_blank"
            rel="noopener noreferrer"
          >
            v{VERSION}
          </a>
        </div>
      )}
      {username && (
        <div className={styles.section}>
          <div className={styles.greeting}>Hello,</div>
          <div className={styles.username}>{username}</div>
        </div>
      )}
    </div>
  );
}

export default TopBar;
