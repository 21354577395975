import { createSelector } from 'reselect';

export default class GeneralSelector {
  static isShowingAudioVideoMenu = createSelector(
    (state) => state.general.showAudioVideoMenu,
    (show) => show
  );

  static isShowingPlayerSafety = createSelector(
    (state) => state.general.showPlayerSafety,
    (show) => show
  );

  static isShowingTableMenu = createSelector(
    (state) => state.general.showTableMenu,
    (show) => show
  );

  static isShowingUserMenu = createSelector(
    (state) => state.general.showUserMenu,
    (show) => show
  );

  static isShowingWelcome = createSelector(
    (state) => state.general.showWelcome,
    (show) => show
  );
}
