import styles from './LoadingSpinner.module.css'

import React, { Component } from 'react'
import { ReactComponent as RoleIcon } from 'images/icons/RoleIcon.svg';

class LoadingSpinner extends Component {
  render() {
    const { color, size, className } = this.props
    const style = { width: size, height: size, '--loadingspinner-color': color }

    return (
      <span className={`${styles.container} ${className || ''}`}>
        <RoleIcon className={styles.icon} style={style} />
      </span>
    )
  }
}

LoadingSpinner.defaultProps = {
  color: 'var(--color-white)',
  size: 36,
}

export default LoadingSpinner
