// @flow
import styles from './atoms.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import { ReactComponent as Icon } from 'images/icons/LinkIcon.svg';

type Props = {
  className?: ?string,
};

export default function LinkedIcon(props: Props): Node {
  const { className } = props;
  return (
    <div className={clsx(className)}>
      <Icon />
    </div>
  );
}
