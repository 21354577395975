// @flow
import styles from './GameUpsell.module.css';

import { PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';

import { GameSelector, ProductSelector, SessionSelector, TableSelector } from 'store/selectors';

import AuthForm from 'components/AuthForm/AuthForm';
import CreateRoomForm from 'components/CreateRoomForm';
import { ReactComponent as RoleIcon } from 'images/icons/RoleIcon.svg';

interface Props extends PropsWithChildren {
  handleSubmit?: (a: any) => void;
  redirectTo?: string;
  submitFailed?: boolean;
  title?: string;
}

function GameUpsell({ children, handleSubmit, redirectTo: propRedirectTo, submitFailed, title }: Props) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  // @ts-ignore GameSelector is not yet typed
  const game = useSelector((state) => GameSelector.getBySlug(state, previewRoom));
  const product = useSelector(
    // @ts-ignore ProductSelector is not yet typed
    (state) => game && ProductSelector.get(state, game.defaultPaidProductGuid || game.defaultFreeProductGuid)
  );
  const isPurchaser = currentUser && game && currentUser.unlockedGameIds.includes(game.id);

  const redirectTo = propRedirectTo || `/games/${previewRoom}/preview`;

  const [isLogIn, setIsLogIn] = useState(false);

  if (!game) return null;

  return (
    <div className={styles.container}>
      <div className={styles.upsell}>
        <div className={styles.header}>
          {game.logoUrl && <img src={game.logoUrl} alt={`${game.title} Logo`} />}
          <h3>{title || `Welcome to ${game.title}`}</h3>
          {!currentUser && <span>It looks like you are not signed in yet.</span>}
        </div>
        <hr />
        {currentUser ? (
          <div className={styles.createRoom}>
            <h3>Create a New Room</h3>
            <p>
              Rooms are automatically saved to your Role account once they have been created and you can visit them
              anytime. Invite players after naming this room - you can even restrict access by setting an optional
              password. You'll be able to use video/audio functionality, upload images and PDFs, share custom sheets,
              and more!
            </p>
            {product.warning && !isPurchaser && (
              <p className={styles.warning}>
                <strong>CONTENT WARNING:</strong> {product.warning}
              </p>
            )}
            <CreateRoomForm gameSlug={previewRoom} />
          </div>
        ) : (
          <div className={styles.previewLobbyUpsell}>
            <AuthForm
              showTabs={true}
              isLogIn={isLogIn}
              onChangeTab={() => setIsLogIn((l) => !l)}
              buttonBackground="rgb(var(--color-theme-accent))"
              redirectTo={redirectTo}
            />
            <div className={styles.upsellCopy}>
              <h4>Sign up for a free account to unlock these features:</h4>
              <ul>
                <li>
                  <RoleIcon />
                  Create rooms and invite friends to play
                </li>
                <li>
                  <RoleIcon />
                  Play with video/audio functionality
                </li>
                <li>
                  <RoleIcon />
                  Create custom sheets and share with party
                </li>
                <li>
                  <RoleIcon />
                  Upload custom images, maps, PDFs
                </li>
                <li>
                  <RoleIcon />
                  Share maps and tokens with party
                </li>
                <li>
                  <RoleIcon />
                  Chat with friends in rooms
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default GameUpsell;
