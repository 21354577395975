// @flow
import styles from './Avatar.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import { conicGradient, linearGradient } from 'utilities/color';

import LoadingSpinner from 'components/LoadingSpinner';
import BeyondSheetAvatar from 'images/BeyondSheetAvatar.png';
import DefaultPlayerAvatar from 'images/DefaultPlayerAvatar.jpg';
import DefaultSheetAvatar from 'images/DefaultSheetAvatar.jpg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';
import { ReactComponent as UploadIcon } from 'images/icons/UploadIcon.svg';

type Props = {
  avatarAlt: string,
  avatarUrl?: string,
  background?: string,
  borderColors?: string[],
  borderSize: number,
  canEdit: boolean,
  canDelete: boolean,
  className?: string,
  isLoading: boolean,
  isConic: boolean,
  isDdbSheet: boolean,
  onChange?: (file: ?any) => void,
  placeholderLabel: string,
  shape?: ('circle' | 'rectangle' | 'squircle'),
  showPlayerDefault: boolean,
  showSheetDefault: boolean,
  size: number,
};

const Avatar = (props: Props): Node => {
  const {
    avatarAlt,
    avatarUrl,
    background,
    borderColors,
    borderSize,
    canDelete,
    canEdit,
    className,
    isConic,
    isDdbSheet,
    isLoading,
    onChange,
    placeholderLabel,
    shape,
    showPlayerDefault,
    showSheetDefault,
    size,
  } = props;

  let gradient = null;
  if (borderColors) {
    gradient = isConic ? conicGradient(borderColors) : linearGradient(borderColors, '180deg');
  }
  const showImage = avatarUrl || isDdbSheet || showPlayerDefault || showSheetDefault;
  const imgSrc =
    avatarUrl ||
    (isDdbSheet && BeyondSheetAvatar) ||
    (showPlayerDefault && DefaultPlayerAvatar) ||
    (showSheetDefault && DefaultSheetAvatar);

  return (
    <div
      className={clsx(styles.container, className && className, shape && styles[shape])}
      style={shape !== 'rectangle' ? { width: size, height: size } : {}}
    >
      {gradient && <div className={styles.gradient} style={{ background: gradient }} />}
      <div className={styles.content} style={{ inset: borderColors ? borderSize : 0 }}>
        <div className={styles.background} style={{ background }} />
        {showImage && <img className={styles.avatar} src={imgSrc} alt={avatarAlt} title={avatarAlt} />}
        {canEdit && !isLoading && (
          <div className={styles.inputContainer}>
            <label className={styles.label}>
              <input
                className={styles.input}
                type="file"
                accept="image/jpeg, image/jpg, image/png, image/gif"
                onChange={(event) => onChange(event.currentTarget.files[0])}
              />
              <span className={styles.placeholder}>
                <span className={styles.placeholderBackground} />
                <span className={styles.placeholderContent}>
                  <span className={styles.placeholderLabel}>{placeholderLabel}</span>
                  <UploadIcon className={styles.placeholderIcon} />
                </span>
              </span>
            </label>
            {canDelete && avatarUrl && (
              <button type="button" className={`button-reset ${styles.deleteButton}`} onClick={() => onChange(null)}>
                <TrashIcon />
              </button>
            )}
          </div>
        )}
        {isLoading && (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingBackground} />
            <div className={styles.placeholderContent}>
              <LoadingSpinner size={size / 2} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Avatar.defaultProps = {
  avatarAlt: 'Avatar',
  canEdit: false,
  canDelete: false,
  borderSize: 3,
  isConic: false,
  isDdbSheet: false,
  isLoading: false,
  placeholderLabel: 'Update Avatar',
  showPlayerDefault: false,
  showSheetDefault: false,
};

export default Avatar;
