import { batch } from 'react-redux';

import { createAction, handleError } from './utilities';

import OrganizationAction from './OrganizationAction';
import ProductAction from './ProductAction';
import role from 'apis/role';

function processGamesResponse({ data, included }) {
  // For now we skip relationships
  const games = data.map(({ id, attributes }) => ({ id, ...attributes }));
  const products = [];
  const organizations = [];

  for (const include of included) {
    const { id, type, attributes } = include;

    const obj = { id, ...attributes };

    if (type === 'product') products.push(obj);
    else if (type === 'organization') organizations.push(obj);
  }
  return { games, products, organizations };
}

export default class GameAction {
  static FETCH = 'GameAction.FETCH';
  static FETCH_ALL = 'GameAction.FETCH_ALL';

  static fetch = (slug, callback) => async (dispatch) =>
    role.get(`/games/${slug}`).then((response) => {
      const { games, products, organizations } = processGamesResponse({
        data: [response.data.data],
        included: response.data.included,
      });
      batch(() => {
        dispatch(createAction(GameAction.FETCH_ALL, games));
        dispatch(createAction(ProductAction.FETCH_ALL, products));
        dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
      });
      callback?.({ games, products, organizations });
    });

  static fetchAll = (callback) => async (dispatch) =>
    role.get('/games').then(
      (response) => {
        const { games, products, organizations } = processGamesResponse(response.data);
        batch(() => {
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });
        callback?.({ games, products, organizations });
      },
      ({ response }) => handleError(dispatch, GameAction.FETCH_ALL, response)
    );
}
