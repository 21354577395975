//@flow
import styles from 'components/layouts/SessionPage.module.css';

import React, { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import ErrorAction from 'store/actions/ErrorAction';
import SessionAction from 'store/actions/SessionAction';

import requireNoAuth from 'hocs/requireNoAuth';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import SessionPage from 'components/layouts/SessionPage';
import Metatags from 'components/Metatags';

type Props = {
  handleSubmit: (a: any) => void,
  submitFailed: boolean,
};

const validate = (values) => {
  const errors = {};
  if (!values.email || values.email.trim() === '') errors.email = 'Required';
  return errors;
};

function ForgotPasswordPage(props: Props) {
  const { handleSubmit, submitFailed } = props;

  const dispatch = useDispatch();
  const requestedPasswordReset = useSelector((state) => state.session.requestedPasswordReset);

  useEffect(() => {
    return () => {
      dispatch(SessionAction.clearRequestedPasswordReset);
    };
  }, [dispatch]);

  const onSubmit = (formValues) => {
    batch(() => {
      dispatch(ErrorAction.removeAll);
      dispatch(SessionAction.clearRequestedPasswordReset);
      dispatch(SessionAction.requestPasswordReset({ user: { ...formValues } }));
    });
  };

  const renderInput = useMemo(
    () =>
      ({ input, type, placeholder, meta }) => {
        return (
          <TextInput
            input={input}
            meta={meta}
            placeholder={placeholder}
            showError={submitFailed}
            type={type}
            variant="simple"
          />
        );
      },
    [submitFailed]
  );

  return (
    <>
      <Metatags title="Forgot Password" />

      <SessionPage title="Role Forgot Password">
        <div className={styles.signUpHeader}>
          <h1>Forgot your password?</h1>
          <span>Enter your email below and we'll send you instructions on how to reset it.</span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.signUpForm}>
          <div className={styles.fieldContainer}>
            <Field component={renderInput} name="email" type="text" placeholder="Email Address" />
          </div>
          {requestedPasswordReset && (
            <p className={styles.intro}>
              An email will be sent to you shortly with instructions for resetting your password.
            </p>
          )}
          <Button variant="primary" className={styles.ctaButton} type="submit">
            Request Reset
          </Button>
        </form>
        <div className={styles.footerLink}>
          <Link to={'/login'}>
            Remember your password? <span className={styles.highlight}>Log in here!</span>
          </Link>
        </div>
      </SessionPage>
    </>
  );
}

export default compose(requireNoAuth, reduxForm({ form: 'forgotPassword', validate }))(ForgotPasswordPage);
