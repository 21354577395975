import { createAction } from './utilities'

export default class AssetAction {
  static DELETE = 'AssetAction.DELETE'
  static EDIT_MAP = 'AssetAction.EDIT_MAP'
  static EDIT_TOKEN = 'AssetAction.EDIT_TOKEN'
  static RENAME = 'AssetAction.RENAME'
  static SHOW_ADD_TOKEN = 'AssetAction.SHOW_ADD_TOKEN'
  static SHOW_LIBRARY = 'AssetAction.SHOW_LIBRARY'
  static SHOW_MENU = 'AssetAction.SHOW_MENU'
  static SHOW_UPLOAD = 'AssetAction.SHOW_UPLOAD'

  static delete = id => {
    return createAction(AssetAction.DELETE, id)
  }

  static editMap = id => {
    return createAction(AssetAction.EDIT_MAP, id)
  }

  static editToken = id => {
    return createAction(AssetAction.EDIT_TOKEN, id)
  }

  static rename = id => {
    return createAction(AssetAction.RENAME, id)
  }

  static showAddToken = show => {
    return createAction(AssetAction.SHOW_ADD_TOKEN, show)
  }

  static showLibrary = show => {
    return createAction(AssetAction.SHOW_LIBRARY, show)
  }

  static showMenu = id => {
    return createAction(AssetAction.SHOW_MENU, id)
  }

  static showUpload = show => {
    return createAction(AssetAction.SHOW_UPLOAD, show)
  }
}
