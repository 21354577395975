import Pusher, { Channel, Members } from 'pusher-js';
import { useCallback, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PERMISSIONS } from 'constants/room_sheet';
import useCallbackAsRef from 'hooks/useCallbackAsRef';
import { ChannelEvent, roomChannel, roomPresenceChannel } from 'models/Channel';
import {
  DocumentAction,
  ProductAction,
  RollAction,
  RoomAction,
  RoomDocumentAction,
  RoomSheetAction,
  RoomUserAction,
  SessionAction,
  SheetAction,
  TableAction,
} from 'store/actions';
import { RollSelector, SessionSelector, TableSelector } from 'store/selectors';

interface RoomStub {
  guid: string;
  userIds: string[];
}

interface DiePusherData {
  userId: string;
  die: any;
  dieId: string;
}

interface ModPusherData {
  userId: string;
  modifier: any;
  modifierId: string;
}

interface ObrActivePusherData {
  active: boolean;
}

interface SheetPusherData {
  sheetId: string;
  inputId: string;
}

export default function useTablePusher({ guid, userId: hostId, userIds }: Partial<RoomStub> = { userIds: [] }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore TableSelector is not typed yet
  const activeSheet = useSelector((state) => TableSelector.getActiveSheet(state, guid));
  // @ts-ignore TableSelector is not typed yet
  const activeSheetUser = useSelector((state) => TableSelector.getActiveSheetUser(state, guid));
  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);
  const isObrActive = useSelector(TableSelector.isObrActive);

  const currentUser = useSelector(SessionSelector.currentUser);
  const token = useSelector(SessionSelector.getToken);

  const pusher = useRef<Pusher>();

  /**
   * Presence Channel
   */
  const presenceChannel = useRef<Channel>();
  const onSubscriptionSucceeded = useCallbackAsRef(
    useCallback(
      (members: Members) => {
        batch(() => {
          members.each((m: any) => dispatch(TableAction.addUser(m.id)));
        });
      },
      [dispatch]
    )
  );
  const onMemberAdded = useCallbackAsRef(
    useCallback(
      (member: any) => {
        batch(() => {
          dispatch(TableAction.addUser(member.id));
          if (guid) {
            // TODO: check RoomUsers instead of room.userIds
            if (!userIds!.includes(member.id))
              dispatch(RoomAction.fetch(guid, () => dispatch(RoomUserAction.fetchAll(guid))));
            else dispatch(RoomUserAction.fetch(guid, member.id));
          }
        });

        if (hostId === currentUser.id && isObrActive) {
          presenceChannel.current?.trigger(ChannelEvent.OBR_ACTIVE, { active: true });
        }

        // TODO: resend current dice that is being shown?
      },
      [currentUser.id, dispatch, guid, hostId, isObrActive, userIds]
    )
  );
  const onMemberRemoved = useCallbackAsRef(
    useCallback(
      (member: any) => {
        batch(() => {
          dispatch(RollAction.removeAllUserDice(member.id));
          dispatch(RollAction.removeAllUserModifiers(member.id));
          dispatch(TableAction.removeUser(member.id));
        });
      },
      [dispatch]
    )
  );
  const onDieAdded = useCallbackAsRef(
    useCallback((data: DiePusherData) => dispatch(RollAction.addUserDie(data.userId, data.die)), [dispatch])
  );
  const onDieRemoved = useCallbackAsRef(
    useCallback((data: DiePusherData) => dispatch(RollAction.removeUserDie(data.userId, data.dieId)), [dispatch])
  );
  const onModifierAdded = useCallbackAsRef(
    useCallback((data: ModPusherData) => dispatch(RollAction.addUserModifier(data.userId, data.modifier)), [dispatch])
  );
  const onModifierRemoved = useCallbackAsRef(
    useCallback(
      (data: ModPusherData) => dispatch(RollAction.removeUserModifier(data.userId, data.modifierId)),
      [dispatch]
    )
  );
  const onObrActive = useCallbackAsRef(
    useCallback((data: ObrActivePusherData) => dispatch(TableAction.setObrActive(data.active)), [dispatch])
  );
  const onRollCompleted = useCallbackAsRef(
    useCallback(
      (data: DiePusherData) =>
        batch(() => {
          if (currentUser.id === data.userId) {
            dispatch(RollAction.removeAllDice());
            dispatch(RollAction.unselectAllModifiers());
          } else {
            dispatch(RollAction.removeAllUserDice(data.userId));
            dispatch(RollAction.removeAllUserModifiers(data.userId));
          }
        }),
      [currentUser.id, dispatch]
    )
  );
  const onSheetInputBlurred = useCallbackAsRef(
    useCallback((data: SheetPusherData) => dispatch(SheetAction.inputBlurred(data.sheetId, data.inputId)), [dispatch])
  );
  const onSheetInputFocused = useCallbackAsRef(
    useCallback((data: SheetPusherData) => dispatch(SheetAction.inputFocused(data.sheetId, data.inputId)), [dispatch])
  );
  const onTablePing = useCallbackAsRef(useCallback(() => dispatch(TableAction.showPing(true)), [dispatch]));

  /**
   * Room Channel
   */
  const channel = useRef<Channel>();
  const onDeckUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomAction.fetchDeck(guid, data.deckId)), [dispatch, guid])
  );
  const onDocumentAdded = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomDocumentAction.fetch(data.guid, data.documentId, data.ownerId)), [dispatch])
  );
  const onDocumentRemoved = useCallbackAsRef(
    useCallback(
      (data: any) => dispatch(RoomDocumentAction.remove(data.guid, data.documentId, data.ownerId)),
      [dispatch]
    )
  );
  const onDocumentUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(DocumentAction.fetch(data.documentId, { room_guid: guid })), [dispatch, guid])
  );
  const onRoomRefresh = useCallbackAsRef(
    useCallback(
      (data: any) =>
        batch(() => {
          dispatch(SessionAction.fetchCurrentUser());
          data.productGuids?.forEach((guid: string) => dispatch(ProductAction.fetch(guid)));
          dispatch(RoomAction.fetch(data.roomGuid));
          dispatch(SheetAction.fetchAll({ room_guid: data.roomGuid }));
          dispatch(RoomAction.fetchSheetTemplates(data.roomGuid));
          dispatch(
            RoomDocumentAction.fetchAll(data.roomGuid, () => dispatch(TableAction.setPlayPanelContent('Assets')))
          );
        }),
      [dispatch]
    )
  );
  const onRoomDocumentUpdated = useCallbackAsRef(
    useCallback(
      (data: any) => {
        dispatch(
          RoomDocumentAction.fetch(guid, data.documentId, data.ownerId, ({ roomDocument }: any) => {
            const { guidId, isShared } = roomDocument;
            batch(() => {
              if (isShared) {
                dispatch(TableAction.setSharedRoomDocument(guidId));
                if (privateRoomDocument && privateRoomDocument.guidId === guidId)
                  dispatch(TableAction.setPrivateRoomDocument(null));
                if (!sharedRoomDocument || sharedRoomDocument.guidId !== guidId) {
                  dispatch(TableAction.setActiveRoomDocument(guidId));
                }
              } else if (sharedRoomDocument && sharedRoomDocument.guidId === guidId) {
                if (activeRoomDocument && activeRoomDocument.guidId === guidId) {
                  dispatch(TableAction.setActiveRoomDocument(null));
                }
                dispatch(TableAction.setSharedRoomDocument(null));
              }
            });
          })
        );
      },
      [activeRoomDocument, dispatch, guid, privateRoomDocument, sharedRoomDocument]
    )
  );
  const onRoomDocumentsUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomDocumentAction.fetchAll(data.guid)), [dispatch])
  );
  const onRoomSheetUpdated = useCallbackAsRef(
    useCallback(
      (data: any) =>
        dispatch(
          RoomSheetAction.fetch(data.guid, data.sheetId, (roomSheet: any) => {
            if (activeSheet?.id === roomSheet.id) {
              const canView =
                roomSheet.userId === currentUser.id ||
                roomSheet.permissions.viewers?.includes(PERMISSIONS.everyone) ||
                roomSheet.permissions.viewers?.includes(currentUser.id);
              const canEdit =
                canView &&
                (roomSheet.userId === currentUser.id ||
                  roomSheet.permissions.editors?.includes(PERMISSIONS.everyone) ||
                  roomSheet.permissions.editors?.includes(currentUser.id));
              if (!canEdit && !canView) dispatch(TableAction.setActiveSheet(null));
            }
          })
        ),
      [activeSheet?.id, currentUser.id, dispatch]
    )
  );
  const onRoomSheetsUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomSheetAction.fetchAll(data.guid)), [dispatch])
  );
  const onRoomUpdated = useCallbackAsRef(useCallback(() => dispatch(RoomAction.fetch(guid)), [dispatch, guid]));
  const onRoomUserDeleted = useCallbackAsRef(
    useCallback(
      (data: any) => {
        if (currentUser.id === data.userId) return history.push('/rooms');
        if (activeSheetUser && activeSheetUser.id === data.userId)
          batch(() => {
            dispatch(TableAction.setActiveSheet(null));
            dispatch(TableAction.setActiveSheetUser(currentUser.id));
          });
        dispatch(RoomUserAction.remove(guid, data.userId));
      },
      [activeSheetUser, currentUser.id, dispatch, guid, history]
    )
  );
  const onRoomUserUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomUserAction.fetch(data.roomGuid, data.userId)), [dispatch])
  );
  const onSheetAdded = useCallbackAsRef(
    useCallback(() => {
      batch(() => {
        dispatch(SheetAction.fetchAll({ room_guid: guid }));
        dispatch(RoomSheetAction.fetchAll(guid));
      });
    }, [dispatch, guid])
  );
  const onSheetRemoved = useCallbackAsRef(
    useCallback(
      (data: any) =>
        batch(() => {
          if (activeSheet && activeSheet.id === data.sheetId) dispatch(TableAction.setActiveSheet(null));
          dispatch(RoomSheetAction.remove(guid, data.sheetId));
          dispatch(RoomSheetAction.fetchAll(guid));
          dispatch(SheetAction.remove(guid, data.sheetId));
          dispatch(SheetAction.fetchAll({ room_guid: guid }));
        }),
      [activeSheet, dispatch, guid]
    )
  );

  const onSheetTransferred = useCallbackAsRef(
    useCallback(
      (data: any) =>
        dispatch(
          SheetAction.fetch(data.sheetId, { room_guid: guid }, (sheet: any) =>
            dispatch(
              RoomSheetAction.fetchAll(
                guid,
                () =>
                  activeSheet &&
                  activeSheetUser &&
                  activeSheet.id === sheet.id &&
                  activeSheetUser.id !== sheet.userId &&
                  dispatch(TableAction.setActiveSheetUser(sheet.userId))
              )
            )
          )
        ),
      [activeSheet, activeSheetUser, dispatch, guid]
    )
  );
  const onSheetUpdated = useCallbackAsRef(
    useCallback((data: any) => dispatch(SheetAction.fetch(data.sheetId, { room_guid: guid })), [dispatch, guid])
  );
  const onVideoRoomCreated = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomAction.updateSid(data.guid, data.sid)), [dispatch])
  );
  const onVideoRoomUserConnected = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomUserAction.updateSid(data.guid, data.userId, data.sid)), [dispatch])
  );
  const onVideoRoomUserDisconnected = useCallbackAsRef(
    useCallback((data: any) => dispatch(RoomUserAction.updateSid(data.guid, data.userId, null)), [dispatch])
  );

  useEffect(
    () => {
      if (guid && process.env.REACT_APP_PUSHER_APP_KEY) {
        pusher.current = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
          cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
          authEndpoint: `${process.env.REACT_APP_SERVER_URL}/rooms/${guid}/presence/auth`,
          auth: { headers: { Authorization: `Bearer ${token}` } },
          forceTLS: true,
        });

        pusher.current.connection.bind(ChannelEvent.PUSHER_CONNECTED, () => {
          dispatch(SessionAction.setSocketId(pusher.current!.connection.socket_id));
        });

        presenceChannel.current = pusher.current.subscribe(roomPresenceChannel(guid));
        presenceChannel.current.bind(ChannelEvent.PUSHER_SUBSCRIPTION_SUCCEEDED, (members: Members) =>
          onSubscriptionSucceeded.current(members)
        );
        presenceChannel.current.bind(ChannelEvent.PUSHER_MEMBER_ADDED, (member: any) => onMemberAdded.current(member));
        presenceChannel.current.bind(ChannelEvent.PUSHER_MEMBER_REMOVED, (member: any) =>
          onMemberRemoved.current(member)
        );
        presenceChannel.current.bind(ChannelEvent.DIE_ADDED, (data: DiePusherData) => onDieAdded.current(data));
        presenceChannel.current.bind(ChannelEvent.DIE_REMOVED, (data: DiePusherData) => onDieRemoved.current(data));
        presenceChannel.current.bind(ChannelEvent.MODIFIER_ADDED, (data: ModPusherData) =>
          onModifierAdded.current(data)
        );
        presenceChannel.current.bind(ChannelEvent.MODIFIER_REMOVED, (data: ModPusherData) =>
          onModifierRemoved.current(data)
        );
        presenceChannel.current.bind(ChannelEvent.OBR_ACTIVE, (data: ObrActivePusherData) => onObrActive.current(data));
        presenceChannel.current.bind(ChannelEvent.ROLL_COMPLETED, (data: DiePusherData) =>
          onRollCompleted.current(data)
        );
        presenceChannel.current.bind(ChannelEvent.SHEET_INPUT_BLURRED, (data: SheetPusherData) =>
          onSheetInputBlurred.current(data)
        );
        presenceChannel.current.bind(ChannelEvent.SHEET_INPUT_FOCUSED, (data: SheetPusherData) =>
          onSheetInputFocused.current(data)
        );
        presenceChannel.current.bind(ChannelEvent.TABLE_PING, () => onTablePing.current());
        dispatch(TableAction.setPresenceChannel(presenceChannel.current));

        channel.current = pusher.current.subscribe(roomChannel(guid));
        channel.current.bind(ChannelEvent.DECK_UPDATED, (data: any) => onDeckUpdated.current(data));
        channel.current.bind(ChannelEvent.DOCUMENT_ADDED, (data: any) => onDocumentAdded.current(data));
        channel.current.bind(ChannelEvent.DOCUMENT_REMOVED, (data: any) => onDocumentRemoved.current(data));
        channel.current.bind(ChannelEvent.DOCUMENT_UPDATED, (data: any) => onDocumentUpdated.current(data));
        channel.current.bind(ChannelEvent.ROOM_REFRESH, (data: any) => onRoomRefresh.current(data));
        channel.current.bind(ChannelEvent.ROOM_DOCUMENT_UPDATED, (data: any) => onRoomDocumentUpdated.current(data));
        channel.current.bind(ChannelEvent.ROOM_DOCUMENTS_UPDATED, (data: any) => onRoomDocumentsUpdated.current(data));
        channel.current.bind(ChannelEvent.ROOM_SHEET_UPDATED, (data: any) => onRoomSheetUpdated.current(data));
        channel.current.bind(ChannelEvent.ROOM_SHEETS_UPDATED, (data: any) => onRoomSheetsUpdated.current(data));
        channel.current.bind(ChannelEvent.ROOM_UPDATED, () => onRoomUpdated.current());
        channel.current.bind(ChannelEvent.ROOM_USER_DELETED, (data: any) => onRoomUserDeleted.current(data));
        channel.current.bind(ChannelEvent.ROOM_USER_UPDATED, (data: any) => onRoomUserUpdated.current(data));
        channel.current.bind(ChannelEvent.SHEET_ADDED, () => onSheetAdded.current());
        channel.current.bind(ChannelEvent.SHEET_REMOVED, (data: any) => onSheetRemoved.current(data));
        channel.current.bind(ChannelEvent.SHEET_TRANSFERRED, (data: any) => onSheetTransferred.current(data));
        channel.current.bind(ChannelEvent.SHEET_UPDATED, (data: any) => onSheetUpdated.current(data));
        channel.current.bind(ChannelEvent.VIDEO_ROOM_CREATED, (data: any) => onVideoRoomCreated.current(data));
        channel.current.bind(ChannelEvent.VIDEO_ROOM_USER_CONNECTED, (data: any) =>
          onVideoRoomUserConnected.current(data)
        );
        channel.current.bind(ChannelEvent.VIDEO_ROOM_USER_DISCONNECTED, (data: any) =>
          onVideoRoomUserDisconnected.current(data)
        );
      }
      return () => {
        if (guid) {
          dispatch(SessionAction.setSocketId(null));
          dispatch(TableAction.setPresenceChannel(null));

          presenceChannel.current?.unbind();
          pusher.current?.unsubscribe(presenceChannel.current!.name);

          channel.current?.unbind();
          pusher.current?.unsubscribe(channel.current!.name);

          pusher.current?.connection.unbind();
          pusher.current?.disconnect();
        }
      };
    },
    // The rest of the deps don't matter because they're refs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, guid, token]
  );
}
