// @flow
import styles from './Dropdown.module.css';

import type { Node } from 'react';
import React, { useEffect, useState } from 'react';

import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

type Props = {
  className?: ?string,
  onChange: (string) => void,
  options: string[],
  readOnly: boolean,
  value: string,
};

function Dropdown({ className, onChange, options, readOnly, value: propValue }: Props): Node {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const classNames = [styles.container];
  if (className) classNames.push(className);

  const onValueChange = (event) => {
    if (!readOnly) onChange(event.currentTarget.value);
  };

  return (
    <div className={classNames.join(' ')}>
      <select className={styles.select} value={value} onChange={onValueChange}>
        {options.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
      <SingleArrowIcon className={styles.selectArrowIcon} />
    </div>
  );
}

Dropdown.defaultProps = {
  readOnly: false,
};

export default Dropdown;
