import styles from './CreateDiceModal.module.css';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DocumentType } from 'models/Document';
import { DocumentAction } from 'store/actions';

import Modal from './Modal';
import Button from 'components/buttons/Button/Button';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';

export default function CreateDiceModal({
  onSubmit,
  onDismiss,
}: {
  onSubmit?: (obj: any) => void;
  onDismiss?: () => void;
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [sides, setSides] = useState('2');
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const onClickAdd = () => {
    setErrors({});

    const nameStr = name.trim();
    const sidesInt = parseInt(sides);
    const errors: any = {};

    if (isNaN(sidesInt)) {
      errors.sides = 'Must be a number';
    } else if (sidesInt < 2 || sidesInt > 999) {
      errors.sides = 'Must be 2 to 999 sides';
    }

    if (nameStr.length < 2 || nameStr.length > 10) {
      errors.name = 'Must be 2 to 10 characters';
    }

    if (!isEmpty(errors)) {
      setErrors(errors);
      return;
    }

    setIsLoading(true);
    const data = {
      document: { name: nameStr, doc_type: DocumentType.DICE, metadata: { type: 'number', sides: sidesInt } },
    };
    dispatch(
      // @ts-ignore
      DocumentAction.createJson(data, (obj) => onSubmit?.(obj))
    );
  };

  return (
    <Modal className={styles.container} onDismiss={onDismiss}>
      <Modal.Header>
        <Modal.Icon width={25} height={25}>
          <PlusIcon />
        </Modal.Icon>
        <Modal.Title subtitle="Create">Custom Dice</Modal.Title>
      </Modal.Header>

      <p className={styles.copy}>
        Create unique dice with a custom number of sides.
        <br />
        <span className={styles.copyExtra}>(Custom dice with images coming soon!)</span>
      </p>

      <div className={styles.form}>
        <Input
          label="Dice Name"
          placeholder="2 to 10 characters"
          value={name}
          error={errors.name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <Input
          label="Number of Sides"
          placeholder="2 to 999 sides"
          value={sides}
          type="number"
          min={2}
          error={errors.sides}
          onChange={(e) => setSides(e.currentTarget.value)}
        />
      </div>

      <Modal.Actions>
        <Button variant="primary" onClick={onClickAdd} isLoading={isLoading}>
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function Input({
  label,
  value,
  placeholder,
  type = 'text',
  min,
  max,
  error,
  onChange,
}: {
  label: string;
  placeholder?: string;
  value?: string | number;
  type?: string;
  error?: string;
  min?: number;
  max?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <label className={clsx(styles.inputContainer, error && styles.hasError)}>
      <span className={styles.inputLabel}>{label}</span>
      <input
        className={styles.input}
        placeholder={placeholder}
        type={type}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
      {error && <span className={styles.inputError}>{error}</span>}
    </label>
  );
}
