import SheetTemplateBuilderAction from 'store/actions/SheetTemplateBuilderAction';

const INITIAL_STATE = {
  addAfterBlockId: null,
  addAfterSectionId: null,
  addBlockSectionId: null,
  addInputBlockId: null,
  addInputSectionId: null,
  addLinkBlockId: null,
  addLinkInputId: null,
  addLinkIsToggle: null,
  addLinkSectionId: null,
  advancedBlockId: null,
  advancedInputId: null,
  advancedSectionId: null,
  deleteBlockId: null,
  deleteBlockSectionId: null,
  deleteInputBlockId: null,
  deleteInputId: null,
  deleteInputSectionId: null,
  deleteSectionId: null,
  editLinkIndex: null,
  isAddingBlock: false,
  isAddingInput: false,
  isAddingLink: false,
  isAddingSection: false,
  isShowingPublishConfirmation: false,
  isShowingPublished: false,
  isShowingRename: false,
  isShowingSettingsMenu: false,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action
  if (error) return state

  switch (type) {
    case SheetTemplateBuilderAction.ADD_BLOCK:
      return {
        ...state,
        isAddingBlock: payload.isAdding,
        addBlockSectionId: payload.sectionId,
        addAfterBlockId: payload.id,
      }

    case SheetTemplateBuilderAction.ADD_INPUT:
      return {
        ...state,
        isAddingInput: payload.isAdding,
        addInputSectionId: payload.sectionId,
        addInputBlockId: payload.blockId,
      }

    case SheetTemplateBuilderAction.ADD_LINK:
      return {
        ...state,
        isAddingLink: payload.isAdding,
        addLinkSectionId: payload.sectionId,
        addLinkBlockId: payload.blockId,
        addLinkInputId: payload.inputId,
        addLinkIsToggle: payload.isToggle,
      }

    case SheetTemplateBuilderAction.ADD_SECTION:
      return { ...state, isAddingSection: payload.isAdding, addAfterSectionId: payload.id }

    case SheetTemplateBuilderAction.DELETE_BLOCK:
      return { ...state, deleteBlockSectionId: payload.sectionId, deleteBlockId: payload.id }

    case SheetTemplateBuilderAction.DELETE_INPUT:
      return {
        ...state,
        deleteInputSectionId: payload.sectionId,
        deleteInputBlockId: payload.blockId,
        deleteInputId: payload.id,
      }

    case SheetTemplateBuilderAction.DELETE_SECTION:
      return { ...state, deleteSectionId: payload }

    case SheetTemplateBuilderAction.EDIT_LINK:
      return {
        ...state,
        isEditingLink: payload.isEditing,
        addLinkSectionId: payload.sectionId,
        addLinkBlockId: payload.blockId,
        addLinkInputId: payload.inputId,
        editLinkIndex: payload.editLinkIndex,
        addLinkIsToggle: payload.isToggle,
      }

    case SheetTemplateBuilderAction.SHOW_ADVANCED_OPTIONS:
      return {
        ...state,
        advancedSectionId: payload.sectionId,
        advancedBlockId: payload.blockId,
        advancedInputId: payload.inputId,
      }

    case SheetTemplateBuilderAction.SHOW_PUBLISH_CONFIRMATION:
      return { ...state, isShowingPublishConfirmation: payload }

    case SheetTemplateBuilderAction.SHOW_PUBLISHED:
      return { ...state, isShowingPublished: payload }

    case SheetTemplateBuilderAction.SHOW_RENAME:
      return { ...state, isShowingRename: payload }

    case SheetTemplateBuilderAction.SHOW_SETTINGS_MENU:
      return { ...state, isShowingSettingsMenu: payload }

    default:
      return state
  }
}
