// @flow
import styles from './Modal.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import useWindowSize from 'hooks/useWindowSize';

import Modal from './Modal';

type Props = {
  actions?: Node,
  children?: Node,
  onDismiss: () => void,
  className?: string,
  maxWidth?: string,
  subtitle?: ?Node,
  title: string,
  width?: string,
  headerImage?: string,
};

export default function TitleModal(props: Props): Node {
  const { actions, children, maxWidth, onDismiss, subtitle, title, width, headerImage, className } = props;
  const [isScrollable, setScrollable] = useState(false);
  const bodyRef = useRef(null);
  const scrollableRef = useRef(null);
  const size = useWindowSize();

  useEffect(() => {
    setScrollable(
      scrollableRef.current && bodyRef.current && scrollableRef.current.scrollHeight > bodyRef.current.offsetHeight
    );
  }, [size.width, size.height]);

  return ReactDOM.createPortal(
    <Modal width={width} maxWidth={maxWidth} onDismiss={onDismiss}>
      {headerImage && <img src={headerImage} alt="header" className={styles.headerImage} />}
      <header className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </header>
      {children && (
        <div
          ref={bodyRef}
          className={clsx(styles.body, isScrollable && styles.isScrollable, actions && styles.hasActions, className)}
        >
          <hr className={styles.hr} />
          <div ref={scrollableRef} className={styles.scrollable}>
            {children}
          </div>
        </div>
      )}
      {actions && (
        <>
          <hr className={styles.hr} />
          <div className={styles.actions}>{actions}</div>
        </>
      )}
    </Modal>,
    document.getElementById('modal')
  );
}
