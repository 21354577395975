import styles from './inputs.module.css';

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import IconButton from 'components/buttons/IconButton/IconButton';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';

class TextInput extends Component {
  inputRef = React.createRef();
  state = { showPassword: false };

  render() {
    const {
      input,
      meta: { touched, error } = {},
      type: propInputType,
      min,
      max,
      placeholder,
      autoComplete,
      readOnly,
      showError,
      isDark,
      className,
      onChange: propOnChange,
      onBlur: propOnBlur,
      variant,
      ignore1p,
    } = this.props;
    const hasError = showError && error && touched;
    const inputContainerClassNames = [styles.inputContainer];
    if (isDark) inputContainerClassNames.push(styles.isDark);
    if (!error && touched && input.value !== '') inputContainerClassNames.push(styles.isValid);
    if (hasError) inputContainerClassNames.push(styles.isInvalid);
    if (className) inputContainerClassNames.push(className);
    if (variant === 'simple') inputContainerClassNames.push(styles.simpleInputContainer);
    if (variant === 'theme') inputContainerClassNames.push(styles.theme);
    const onChange = propOnChange || input.onChange;
    const onBlur = propOnBlur || input.onBlur;

    const inputClassNames = [styles.input];
    if (variant === 'simple') inputClassNames.push(styles.simpleInput);
    if (propInputType === 'password') inputClassNames.push(styles.passwordInput);

    let type = propInputType;
    if (propInputType === 'password') {
      type = this.state.showPassword ? 'text' : 'password';
    }

    const onPasswordToggle = (e) => {
      e.preventDefault();
      this.setState({ showPassword: !this.state.showPassword });
    };

    return (
      <>
        {variant === 'simple' && placeholder && (
          <label htmlFor={`${placeholder}-input`} className={styles.inputLabel}>
            {placeholder}
          </label>
        )}
        <span className={inputContainerClassNames.join(' ')}>
          <input
            ref={this.inputRef}
            {...input}
            onChange={(e) => (onChange ? onChange(e) : null)}
            onBlur={(e) => (onBlur ? onBlur(e) : null)}
            type={type}
            min={min}
            max={max}
            placeholder={variant !== 'simple' ? placeholder : ''}
            autoComplete={autoComplete}
            readOnly={readOnly}
            className={inputClassNames.join(' ')}
            id={`${placeholder}-input`}
            data-type={propInputType}
            data-1p-ignore={!!ignore1p}
          />
          {propInputType === 'password' && (
            <div className={styles.passwordVisibilityToggle}>
              <IconButton
                buttonSize={23}
                iconSize={23}
                activeBackground="none"
                background="none"
                activeColor="var(--color-dark-accent)"
                color="var(--color-light-main)"
                onClick={onPasswordToggle}
                children={this.state.showPassword ? <VisibleIcon /> : <NotVisibleIcon />}
              />
            </div>
          )}
          {hasError && <span className={styles.inputError}>{error}</span>}
        </span>
      </>
    );
  }
}

TextInput.propTypes = {
  isDark: PropTypes.bool,
  readOnly: PropTypes.bool,
  showError: PropTypes.bool,
  variant: PropTypes.oneOf(['inline', 'simple', 'theme']),
};

TextInput.defaultProps = {
  isDark: false,
  readOnly: false,
  showError: false,
  variant: 'inline',
};

export default TextInput;
