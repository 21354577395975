// @flow

export const FILTER: { [string]: string } = Object.freeze({
  all: 'all',
  created: 'created',
  saved: 'saved',
  unlocked: 'purchased',
});

export const FILTERS: string[] = Object.freeze(Object.values(FILTER).map((o) => String(o)));

export const PRIVACY: { [string]: string } = Object.freeze({
  public: 'public',
  unlisted: 'unlisted',
});
