// @flow
import styles from './Sheet.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { memo, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import type { OnChangeAvatar, OnPublishClick, OnUnpublishClick } from 'components/Sheet2/types';
import { PRIVACY } from 'constants/template';
import useFontSize from 'hooks/useFontSize';
import { getFullDate } from 'utilities';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import MoreMenuButton from 'components/buttons/MoreMenuButton';
import GradientDot from 'components/GradientDot';
import InfoTooltip from 'components/Sheet2/atoms/InfoTooltip';
import ToggleSwitch from 'components/ToggleSwitch';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';
import { ReactComponent as UploadIcon } from 'images/icons/UploadIcon.svg';

type Props = {
  guidSlug: string,
  avatarUrl: ?string,
  canEdit: boolean,
  colors: string[],
  isPrivacyLocked: boolean,
  isPublished: boolean,
  lastPublishedAt: ?string,
  lastEditedAt: ?string,
  privacy: $Values<typeof PRIVACY>,
  name: string,
  onChangeAvatar: OnChangeAvatar,
  onChangeName: (string) => void,
  onChangePrivacy: (boolean) => void,
  onPublishClick: OnPublishClick,
  onUnpublishClick: OnUnpublishClick,
  showMenu: boolean,
  updatedAt: string,
};

const FontSize = {
  '60': 20,
  '90': 16,
};

function TemplateHeader({
  guidSlug,
  avatarUrl,
  canEdit,
  colors,
  isPublished,
  isPrivacyLocked,
  lastEditedAt,
  lastPublishedAt,
  name,
  onChangeAvatar: propOnChangeAvatar,
  onChangeName,
  onChangePrivacy,
  onPublishClick,
  onUnpublishClick,
  privacy,
  showMenu,
  updatedAt,
}: Props): Node {
  const inputRef = useRef(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const nameFontSize = useFontSize(inputRef.current ? inputRef.current.value.length : '', FontSize);

  useEffect(() => {
    if (!inputRef.current) return;
    if (isEditingName) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditingName]);

  const classNames = [styles.header];
  if (canEdit) classNames.push(styles.canEdit);
  if (isEditingName) classNames.push(styles.isEditingName);
  const inputTitle = 'Template Name';

  const menuItems = [
    // { label: 'Last Published', onClick: () => {} },
    // { label: 'Revert to Last', onClick: () => {} },
    { label: 'Unpublish', onClick: onUnpublishClick },
  ];

  const onChangeAvatar = (file) => {
    setIsUploadingAvatar(true);
    propOnChangeAvatar(guidSlug, file, () => setIsUploadingAvatar(false));
  };

  const onNameBlur = (event) => {
    setIsEditingName(false);
    const value = event.currentTarget.value.trim();
    onChangeName(value);
  };

  const onNameClick = () => {
    if (!canEdit) return;
    setIsEditingName(true);
  };

  const onNameKeyDown = (event) => {
    if (event.keyCode === 13) inputRef.current?.blur();
  };

  return (
    <header className={classNames.join(' ')}>
      <Avatar
        className={styles.avatar}
        shape="squircle"
        avatarUrl={avatarUrl}
        avatarAlt={`${name}'s Avatar'`}
        borderColors={colors}
        size={146}
        showSheetDefault
        canEdit={canEdit}
        canDelete
        isLoading={isUploadingAvatar}
        onChange={onChangeAvatar}
      />
      <div className={styles.headerMeta}>
        <div className={styles.headerMetaContent}>
          <div className={styles.nameContainer} onClick={onNameClick}>
            <IconButton
              className={styles.editButton}
              label="Edit Name"
              background="none"
              activeBackground="none"
              children={<PenIcon />}
            />
            {canEdit && (isEditingName || name === '') ? (
              <TextareaAutosize
                ref={inputRef}
                className={`heading1 ${styles.nameInput}`}
                style={{ fontSize: nameFontSize }}
                value={name}
                placeholder="Enter a name"
                title={inputTitle}
                aria-label={inputTitle}
                onBlur={onNameBlur}
                onChange={(e) => onChangeName(e.currentTarget.value)}
                onKeyDown={onNameKeyDown}
              />
            ) : (
              <h2 className={`heading1 ${styles.name}`} style={{ fontSize: nameFontSize }}>
                {name}
              </h2>
            )}
          </div>
          <div className={`heading2 ${styles.status}`}>
            <GradientDot className={styles.dot} colors={isPublished && lastPublishedAt && colors} />
            {isPublished && lastPublishedAt ? `Published: ${getFullDate(new Date(lastPublishedAt))}` : 'Unpublished'}
          </div>
          {canEdit && (
            <div className={`heading2 ${styles.lastUpdated}`}>
              Edited: {lastEditedAt ? getFullDate(new Date(lastEditedAt)) : 'N/A'}
            </div>
          )}
        </div>
        {canEdit && (
          <Button
            className={styles.publishButton}
            primaryBackground={colors[0]}
            secondaryBackground={colors[1]}
            onClick={onPublishClick}
            icon={<UploadIcon style={{ marginTop: '-2px' }} />}
          >
            Publish
          </Button>
        )}
      </div>
      {showMenu && (
        <MoreMenuButton className={clsx(styles.settings, styles.templateSettings)} items={menuItems} variant="theme" />
      )}
      {canEdit && (
        <div className={styles.privacyToggle}>
          <ToggleSwitch
            label="Public"
            checked={privacy === PRIVACY.public}
            disabled={isPrivacyLocked}
            variant="theme"
            colors={colors}
            onChange={onChangePrivacy}
          />
          <InfoTooltip
            text={
              isPrivacyLocked ? (
                <>Templates duplicated from paid products cannot be made public.</>
              ) : (
                <>
                  Public templates will be searchable
                  <br />
                  by others for use in their games.
                </>
              )
            }
          />
        </div>
      )}
    </header>
  );
}

TemplateHeader.defaultProps = {
  canEdit: false,
  isPrivacyLocked: false,
  showMenu: false,
};

export default memo(TemplateHeader);
