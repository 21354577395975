import styles from './TableSheet.module.css';

import React, { Component } from 'react';

import Button from 'components/buttons/Button';

class Empty extends Component {
  render() {
    const { className, isSelf, user } = this.props;
    const { onAddSheetClick } = this.props;

    return (
      <div className={`${styles.emptyContainer} ${className || ''}`}>
        {isSelf ? (
          <>
            <p>You don't have any sheets added to this room yet!</p>
            <Button variant="primary" onClick={onAddSheetClick}>
              Add or Create Sheet
            </Button>
          </>
        ) : (
          <p>{user.displayName} has no public sheets.</p>
        )}
      </div>
    );
  }
}

Empty.defaultProps = {
  isSelf: false,
};

export default Empty;
