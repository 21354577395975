import styles from 'room/TableUser/TableUser.module.css';

import { useEffect, useRef } from 'react';
import { LocalVideoTrack, RemoteVideoTrack, Track } from 'twilio-video';

interface VideoTrackProps {
  track: LocalVideoTrack | RemoteVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

export default function VideoTrack({ track, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if ('setPriority' in track && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;

      if ('setPriority' in track && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);
  return <video ref={ref} className={styles.video} />;
}
