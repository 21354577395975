// @flow
import styles from './Counter.module.css';

import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useState } from 'react';

import { clamp } from 'utilities';
import { linearGradient } from 'utilities/color';

import Stepper from 'components/Sheet2/atoms/Stepper';

type Props = {
  canEditCurrent: boolean,
  canEditMax: boolean,
  className?: ?string,
  columns: number,
  isBuilding: boolean,
  onBlur?: ?() => void,
  onChange: (string[]) => void,
  onFocus?: ?() => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
  values: string[],
};

function Counter(props: Props): Node {
  const {
    canEditCurrent,
    canEditMax,
    columns,
    className,
    isBuilding,
    onBlur,
    onChange,
    onFocus,
    primaryColor,
    readOnly,
    secondaryColor,
    values,
  } = props;

  const [isDragging, setIsDragging] = useState(false);

  const isCompact = columns === 1;
  const hasMax = values.length === 2;
  const parsedCurrentValue = parseInt(values[0]);
  const parsedMaxValue = hasMax ? parseInt(values[1]) : -1;
  const hasCurrentValue = !isNaN(parsedCurrentValue);
  const hasMaxValue = hasMax && !isNaN(parsedMaxValue);
  const progress =
    hasMax && hasMaxValue && hasCurrentValue ? clamp((parsedCurrentValue / parsedMaxValue) * 100.0, 0, 100) : 0;
  const gradientBackground = linearGradient([primaryColor, secondaryColor], '98deg');

  // Manually setting the dependencies because linter cannot auto detect them.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSetProgressBar = useCallback(
    _.throttle((pageX, offsetLeft, offsetWidth) => {
      const left = pageX - offsetLeft;
      const progress = left / offsetWidth;
      const value = clamp(Math.round(parsedMaxValue * progress), 0, parsedMaxValue);
      onChange([`${value}`, values[1]]);
    }, 50),
    [onChange, parsedMaxValue, values]
  );

  const onProgressBarDown = (event) => {
    if (readOnly) return;
    setIsDragging(true);
    const left = event.currentTarget.getBoundingClientRect().left + window.scrollX;
    onSetProgressBar(event.pageX, left, event.currentTarget.offsetWidth);
  };

  const onProgressBarMove = (event) => {
    if (!isDragging) return;
    const left = event.currentTarget.getBoundingClientRect().left + window.scrollX;
    onSetProgressBar(event.pageX, left, event.currentTarget.offsetWidth);
  };

  return (
    <div className={`${className || ''}`}>
      {hasMax && (
        <div
          className={styles.progressBar}
          onMouseDown={onProgressBarDown}
          onMouseUp={() => setIsDragging(false)}
          onMouseMove={onProgressBarMove}
          onMouseLeave={() => setIsDragging(false)}
        >
          <div className={styles.progressBarFill} style={{ width: `${progress}%`, background: gradientBackground }} />
        </div>
      )}
      <Stepper
        values={values}
        canEditCurrent={canEditCurrent}
        canEditMax={canEditMax || isBuilding}
        isCompact={isCompact}
        readOnly={readOnly}
        onCurrentBlur={onBlur}
        onCurrentFocus={onFocus}
        onChange={onChange}
      />
    </div>
  );
}

Counter.defaultProps = {
  canEditCurrent: false,
  canEditMax: false,
  isBuilding: false,
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Counter;
