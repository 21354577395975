import styles from './Asset.module.css'

import React, { Component } from 'react'

class AssetImage extends Component {
  render() {
    const { name, url, className, onClick } = this.props

    return (
      <div onClick={onClick} className={`box-shadow ${styles.thumbnail} ${className || ''}`}>
        <img src={url} alt={name} />
        <div className={styles.overlay} />
      </div>
    )
  }
}

export default AssetImage
