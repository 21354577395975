import styles from './TableMediaView.module.css';

import _ from 'lodash';
import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useCallbackAsRef from 'hooks/useCallbackAsRef';
import useLatest from 'hooks/useLatest';
import useResizeObserver from 'hooks/useResizeObserver';
import { HEX_SIDE_LENGTH, MapLineAction, MapTokenAction, SQUARE_SIDE_LENGTH } from 'models/Map';
import { RoomDocumentAction, TableAction } from 'store/actions';
import { DocumentSelector, MediaSelector, RoomDocumentSelector, SessionSelector, TableSelector } from 'store/selectors';
import useRoomState from 'video/hooks/useRoomState';

import TableViewOverlay from './TableViewOverlay';

const DEFAULT_GRID_SIZE = 10;
const BRUSH_SIZE = 4;
const SELECT_SIZE = BRUSH_SIZE * 5;
const TOKEN_SELECT_LEEWAY = 1.2;

interface TableMediaViewProps {
  asset: any;
  className?: string;
  room?: any;
  roomDocument?: any;
}

interface Point {
  x: number;
  y: number;
  size: number;
}
interface Line {
  id: string;
  points: Point[];
  color: any;
}

export default function TableMediaView({ asset, className, room, roomDocument }: TableMediaViewProps) {
  const dispatch = useDispatch();

  const currentUser = useSelector(SessionSelector.currentUser);

  const sharedRoomDocument: any | undefined = useSelector(TableSelector.getSharedRoomDocument);
  // @ts-ignore DocumentSelector is not typed yet
  const sharedAsset = useSelector((state) => DocumentSelector.get(state, sharedRoomDocument?.id));
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  // @ts-ignore DocumentSelector is not typed yet
  const privateAsset = useSelector((state) => DocumentSelector.get(state, privateRoomDocument?.id));
  const assetData = useSelector((state) =>
    // @ts-ignore RoomDocumentSelector is not typed yet
    RoomDocumentSelector.getData(state, room?.guid, asset.id, roomDocument?.ownerId)
  );
  const previewRoom = useSelector(TableSelector.getPreviewRoom);

  const canvasScale = useSelector(MediaSelector.getCanvasScale);
  const canvasScaleRef = useLatest(canvasScale);
  const isDeletingEnabled = useSelector(MediaSelector.isDeleting);
  const isDrawingEnabled = useSelector(MediaSelector.isDrawing);
  const penColor = useSelector(MediaSelector.getPenColor);
  // @ts-ignore DocumentSelector is not typed yet
  const tokenDocuments: any[] = useSelector((state) => DocumentSelector.getTokensByRoom(state, room?.guid));

  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const contentRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const canvasOffset = useRef({ x: 0, y: 0 });
  const image = useRef<HTMLImageElement>();
  const imagePos = useRef({ x: 0, y: 0 });
  const prevPos = useRef({ x: 0, y: 0 });
  const lines = useRef<Line[]>([]);
  const tokens = useRef<any[]>([]);
  const cachedTokenImages = useRef<Record<string, HTMLImageElement>>({});
  const newLine = useRef<Line | null>();
  const isDragging = useRef(false);
  const isDrawing = useRef(false);
  const selectedToken = useRef<any | null>();
  const selectedLine = useRef<Line | null>();
  const lineSelectSize = window.IS_TOUCHING ? SELECT_SIZE * 2 : SELECT_SIZE;

  useResizeObserver(
    contentRef,
    useCallback((entry) => {
      setCanvasSize({ width: contentRef.current?.offsetWidth ?? 0, height: contentRef.current?.offsetHeight ?? 0 });
    }, [])
  );

  const getDimensions = useCallbackAsRef(
    useCallback(() => {
      const cw = canvasRef.current?.width ?? 0;
      const ch = canvasRef.current?.height ?? 0;
      const iw = image.current?.width ?? 0;
      const ih = image.current?.height ?? 0;
      const scale = 1; // Math.min(cw / iw, ch / ih) // TODO: Do we want to scale images down to fit on screen by default?
      const w = iw * scale;
      const h = ih * scale;
      return { cw, ch, iw, ih, w, h };
    }, [])
  );

  const getGridScale = useCallbackAsRef(
    useCallback(() => {
      const {
        metadata: { horizontal, vertical },
      } = asset;
      const dpi = window.devicePixelRatio;
      const { w: sw, h: sh } = getDimensions.current();
      const w = sw * canvasScaleRef.current;
      const h = sh * canvasScaleRef.current;
      let gridScale = 1;

      // TODO: handle looking at height as well not just width

      // Makes sure the image is loaded
      if (w > 0) {
        if (!horizontal || !vertical) gridScale = sw / (DEFAULT_GRID_SIZE * SQUARE_SIDE_LENGTH * dpi);
        else {
          const isSquareGrid = Math.abs(w / h - horizontal / vertical) < 0.1;
          if (isSquareGrid) {
            gridScale = sw / (horizontal * SQUARE_SIDE_LENGTH * dpi);
          } else {
            const hexD2 = Math.sqrt(3) * HEX_SIDE_LENGTH;
            gridScale = sw / (horizontal * hexD2 * dpi);
          }
        }
      }

      return gridScale;
    }, [asset, canvasScaleRef, getDimensions])
  );

  const drawImage = useCallbackAsRef(
    useCallback(() => {
      const context = canvasRef.current?.getContext('2d');
      if (!context) return;
      const { cw, ch, w: sw, h: sh } = getDimensions.current();
      const w = sw * canvasScaleRef.current;
      const h = sh * canvasScaleRef.current;
      context.clearRect(0, 0, cw, ch);
      image.current &&
        context.drawImage(
          image.current,
          imagePos.current.x + canvasOffset.current.x,
          imagePos.current.y + canvasOffset.current.y,
          w,
          h
        );
    }, [canvasScaleRef, getDimensions])
  );

  const drawLines = useCallbackAsRef(
    useCallback(() => {
      if (lines.current.length === 0 && !newLine.current) return;
      const l = lines.current.concat(newLine.current ?? []);

      const dpi = window.devicePixelRatio;
      const context = canvasRef.current?.getContext('2d');
      if (!context) return;
      const { w: sw, h: sh } = getDimensions.current();
      const w = sw * canvasScaleRef.current;
      const h = sh * canvasScaleRef.current;

      for (const { points, color } of l) {
        for (let i = 0; i < points.length - 1; i++) {
          const p1 = points[i];
          const p2 = points[i + 1];

          const p1x = imagePos.current.x + w / 2 + p1.x * canvasScaleRef.current + canvasOffset.current.x;
          const p1y = imagePos.current.y + h / 2 + p1.y * canvasScaleRef.current + canvasOffset.current.y;
          const p2x = imagePos.current.x + w / 2 + p2.x * canvasScaleRef.current + canvasOffset.current.x;
          const p2y = imagePos.current.y + h / 2 + p2.y * canvasScaleRef.current + canvasOffset.current.y;

          context.save();
          context.beginPath();
          context.moveTo(p1x, p1y);
          context.lineTo(p2x, p2y);
          context.closePath();
          context.lineWidth = p1.size * canvasScaleRef.current * dpi;
          context.lineCap = 'round';
          context.strokeStyle = color;
          context.stroke();
          context.restore();
        }
      }
    }, [lines, getDimensions, canvasScaleRef])
  );

  const drawTokens = useCallbackAsRef(
    useCallback(() => {
      if (tokenDocuments.length < 1) return;

      const dpi = window.devicePixelRatio;
      const context = canvasRef.current?.getContext('2d');
      if (!context) return;
      const { w: sw, h: sh } = getDimensions.current();
      const w = sw * canvasScaleRef.current;
      const h = sh * canvasScaleRef.current;
      const gridScale = getGridScale.current();

      for (const token of tokens.current) {
        const tokenAsset = tokenDocuments.find((t) => t.id === token.assetId);
        if (!tokenAsset || !token.image) continue;
        const { baseScale, scale } = tokenAsset.metadata;
        const tw = token.image.width * baseScale * scale * canvasScaleRef.current * gridScale * dpi;
        const th = token.image.height * baseScale * scale * canvasScaleRef.current * gridScale * dpi;
        const tx = imagePos.current.x + w / 2 + token.x * canvasScaleRef.current + canvasOffset.current.x - tw / 2;
        const ty = imagePos.current.y + h / 2 + token.y * canvasScaleRef.current + canvasOffset.current.y - th / 2;
        context.save();
        context.drawImage(token.image, tx, ty, tw, th);
        context.restore();
      }
    }, [tokenDocuments, getDimensions, canvasScaleRef, getGridScale])
  );

  const draw = useCallbackAsRef(
    useCallback(() => {
      drawImage.current();
      drawLines.current();
      drawTokens.current();
    }, [drawImage, drawLines, drawTokens])
  );

  const onImageLoad = useCallbackAsRef(
    useCallback(() => {
      const { cw, ch, w, h } = getDimensions.current();
      const x = cw / 2 - w / 2;
      const y = ch / 2 - h / 2;
      imagePos.current = { x, y };
      if (assetData) lines.current = assetData.lines ?? [];
      draw.current();
    }, [assetData, draw, getDimensions])
  );

  const loadImage = useCallback(() => {
    image.current = new Image();
    image.current.onload = onImageLoad.current;
    image.current.src = asset.fileUrl;
  }, [asset.fileUrl, onImageLoad]);

  const checkForLine = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        const { w: sw, h: sh } = getDimensions.current();
        const w = sw * canvasScaleRef.current;
        const h = sh * canvasScaleRef.current;
        const cx = imagePos.current.x + w / 2 + canvasOffset.current.x;
        const cy = imagePos.current.y + h / 2 + canvasOffset.current.y;
        const clickPoint = { x: (x - cx) / canvasScaleRef.current, y: (y - cy) / canvasScaleRef.current };
        const r2d = 180 / Math.PI;

        const lines: Line[] = _.reverse([...assetData.lines]);
        const line = lines.find(({ points }) => {
          const count = points.length - 1;
          return points.find((p1, i) => {
            const A = Math.sqrt(Math.pow(p1.x - clickPoint.x, 2) + Math.pow(p1.y - clickPoint.y, 2));

            if (A <= lineSelectSize) return true;
            if (i === count) return false;

            const p2 = points[i + 1];
            const B = Math.sqrt(Math.pow(p2.x - clickPoint.x, 2) + Math.pow(p2.y - clickPoint.y, 2));
            const C = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

            if (C === 0) return false;

            const a = Math.acos((Math.pow(B, 2) + Math.pow(C, 2) - Math.pow(A, 2)) / (2 * B * C)) * r2d;
            const b = Math.acos((Math.pow(C, 2) + Math.pow(A, 2) - Math.pow(B, 2)) / (2 * C * A)) * r2d;

            if (a > 90 || b > 90) return false;

            const s = (A + B + C) / 2;
            const area = Math.sqrt(s * (s - A) * (s - B) * (s - C));

            if (area === 0) return false;

            const d = (area * 2) / C;
            return d <= lineSelectSize;
          });
        });

        return line;
      },
      [assetData.lines, canvasScaleRef, getDimensions, lineSelectSize]
    )
  );

  const checkForToken = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        const { w: sw, h: sh } = getDimensions.current();
        const w = sw * canvasScaleRef.current;
        const h = sh * canvasScaleRef.current;
        const gridScale = getGridScale.current();

        const token = _.find(_.reverse([...assetData.tokens]), (token) => {
          const tokenAsset = tokenDocuments.find((t) => t.id === token.assetId);
          if (!tokenAsset) return false;
          const { baseScale, scale } = tokenAsset.metadata;
          const tw =
            ((token.image.width * baseScale * scale * canvasScaleRef.current * gridScale * dpi) / 2) *
            TOKEN_SELECT_LEEWAY;
          const th =
            ((token.image.height * baseScale * scale * canvasScaleRef.current * gridScale * dpi) / 2) *
            TOKEN_SELECT_LEEWAY;
          const tx = imagePos.current.x + w / 2 + token.x * canvasScaleRef.current + canvasOffset.current.x;
          const ty = imagePos.current.y + h / 2 + token.y * canvasScaleRef.current + canvasOffset.current.y;
          const p1x = tx - tw;
          const p1y = ty - th;
          const p2x = tx + tw;
          const p2y = ty + th;
          return x >= p1x && x <= p2x && y >= p1y && y <= p2y;
        });

        return token;
      },
      [assetData, canvasScaleRef, getDimensions, getGridScale, tokenDocuments]
    )
  );

  const addPoint = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        if (!newLine.current) return;

        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        prevPos.current = { x, y };

        const { w: sw, h: sh } = getDimensions.current();
        const w = sw * canvasScaleRef.current;
        const h = sh * canvasScaleRef.current;
        const cx = imagePos.current.x + w / 2 + canvasOffset.current.x;
        const cy = imagePos.current.y + h / 2 + canvasOffset.current.y;
        const size = BRUSH_SIZE / 2;
        const point = { x: (x - cx) / canvasScaleRef.current, y: (y - cy) / canvasScaleRef.current, size };

        newLine.current.points.push(point);
        draw.current();
      },
      [canvasScaleRef, draw, getDimensions]
    )
  );

  const addLine = useCallbackAsRef(
    useCallback(() => {
      if (!newLine.current || newLine.current.points.length === 0) return (newLine.current = null);

      const room_document = {
        line_action: MapLineAction.ADD,
        line: { ...newLine.current, points: JSON.stringify(newLine.current.points) },
      };

      lines.current.push(newLine.current);
      newLine.current = null;
      draw.current();
      if (roomDocument)
        dispatch(RoomDocumentAction.updateLine(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId }));
    }, [asset.id, dispatch, draw, room?.guid, roomDocument])
  );

  const deleteLine = useCallbackAsRef(
    useCallback(
      (line: Line) => {
        _.remove(lines.current, (l) => l.id === line.id);
        draw.current();
        const room_document = { line_action: MapLineAction.REMOVE, line: _.pick(line, ['id']) };
        if (roomDocument)
          dispatch(
            RoomDocumentAction.updateLine(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId })
          );
      },
      [asset.id, dispatch, draw, room?.guid, roomDocument]
    )
  );

  const deleteToken = useCallbackAsRef(
    useCallback(
      (token: any) => {
        _.remove(tokens.current, (t) => t.id === token.id);
        draw.current();
        const room_document = { token_action: MapTokenAction.REMOVE, token: _.pick(token, ['id']) };
        if (roomDocument)
          dispatch(
            RoomDocumentAction.updateToken(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId })
          );
      },
      [asset.id, dispatch, draw, room?.guid, roomDocument]
    )
  );

  const updateLine = useCallbackAsRef(
    useCallback(
      (line: Line) => {
        const room_document = {
          line_action: MapLineAction.UPDATE,
          line: { ...line, points: JSON.stringify(line.points) },
        };
        if (roomDocument)
          dispatch(
            RoomDocumentAction.updateLine(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId })
          );
      },
      [asset.id, dispatch, room?.guid, roomDocument]
    )
  );

  const updateToken = useCallbackAsRef(
    useCallback(
      (token: any) => {
        const room_document = {
          token_action: MapTokenAction.UPDATE,
          token: _.pick(token, ['id', 'assetId', 'x', 'y']),
        };
        if (roomDocument)
          dispatch(
            RoomDocumentAction.updateToken(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId })
          );
      },
      [asset.id, dispatch, room?.guid, roomDocument]
    )
  );

  const onDragToken = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        const dx = x - prevPos.current.x;
        const dy = y - prevPos.current.y;
        prevPos.current = { x, y };
        selectedToken.current.x += dx / canvasScaleRef.current;
        selectedToken.current.y += dy / canvasScaleRef.current;
        draw.current();
      },
      [canvasScaleRef, draw]
    )
  );

  const onDragLine = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        const dx = x - prevPos.current.x;
        const dy = y - prevPos.current.y;
        prevPos.current = { x, y };
        selectedLine.current!.points.forEach((p) => {
          p.x += dx / canvasScaleRef.current;
          p.y += dy / canvasScaleRef.current;
        });
        draw.current();
      },
      [canvasScaleRef, draw]
    )
  );

  const onDrag = useCallbackAsRef(
    useCallback(
      ({ clientX, clientY }: ReactMouseEvent) => {
        const rect = canvasRef.current!.getBoundingClientRect();
        const dpi = window.devicePixelRatio;
        const x = (clientX - rect.left) * dpi;
        const y = (clientY - rect.top) * dpi;
        const dx = x - prevPos.current.x;
        const dy = y - prevPos.current.y;
        prevPos.current = { x, y };
        canvasOffset.current = { x: canvasOffset.current.x + dx, y: canvasOffset.current.y + dy };
        draw.current();
      },
      [draw]
    )
  );

  const onCanvasMouseDown = useCallback(
    (event: ReactMouseEvent<HTMLCanvasElement>) => {
      const rect = canvasRef.current!.getBoundingClientRect();
      const dpi = window.devicePixelRatio;
      const x = (event.clientX - rect.left) * dpi;
      const y = (event.clientY - rect.top) * dpi;
      prevPos.current = { x, y };

      if (previewRoom) return (isDragging.current = true);

      if (isDeletingEnabled) {
        const line = checkForLine.current(event);
        const token = checkForToken.current(event);
        if (line) return deleteLine.current(line);
        else if (token) return deleteToken.current(token);
      }

      if (isDrawingEnabled) {
        isDrawing.current = true;
        newLine.current = { id: uuid(), points: [], color: penColor };
      } else {
        const token = checkForToken.current(event);
        const line = checkForLine.current(event);
        if (token) selectedToken.current = token;
        else if (line) selectedLine.current = line;
        else isDragging.current = true;
      }
    },
    [checkForLine, checkForToken, deleteLine, deleteToken, isDeletingEnabled, isDrawingEnabled, penColor, previewRoom]
  );

  const onTouchDown = useCallback((event: ReactTouchEvent) => {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvasRef.current?.dispatchEvent(mouseEvent);
  }, []);

  const onCanvasMouseUp = useCallback(() => {
    if (isDrawing.current) addLine.current();
    if (selectedLine.current) updateLine.current(selectedLine.current);
    if (selectedToken.current) updateToken.current(selectedToken.current);
    isDragging.current = false;
    isDrawing.current = false;
    selectedLine.current = null;
    selectedToken.current = null;
  }, [addLine, updateLine, updateToken]);

  const onTouchUp = useCallback((event: ReactTouchEvent) => {
    const mouseEvent = new MouseEvent('mouseup', {});
    canvasRef.current?.dispatchEvent(mouseEvent);
  }, []);

  const onCanvasMouseMove = useCallback(
    (event: ReactMouseEvent<HTMLCanvasElement>) => {
      if (isDrawing.current) addPoint.current(event);
      else if (selectedToken.current) onDragToken.current(event);
      else if (selectedLine.current) onDragLine.current(event);
      else if (isDragging.current) onDrag.current(event);
    },
    [addPoint, onDrag, onDragLine, onDragToken]
  );

  const onTouchMove = useCallback((event: ReactTouchEvent) => {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvasRef.current?.dispatchEvent(mouseEvent);
  }, []);

  const onCloseClick = useCallback(() => {
    batch(() => {
      dispatch(TableAction.setActiveRoomDocument(null));
      if (previewRoom) return;

      if (privateAsset && privateRoomDocument?.guidId === roomDocument?.guidId)
        dispatch(TableAction.setPrivateRoomDocument(null));
      if (sharedAsset && sharedRoomDocument?.guidId === roomDocument?.guidId)
        dispatch(TableAction.setSharedRoomDocument(null));
      if (roomDocument?.isShared) {
        const room_document = { is_shared: false };
        dispatch(RoomDocumentAction.update(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId }));
      }
    });
  }, [
    asset.id,
    dispatch,
    previewRoom,
    privateAsset,
    privateRoomDocument?.guidId,
    room?.guid,
    roomDocument,
    sharedAsset,
    sharedRoomDocument?.guidId,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas?.addEventListener('mousedown', onCanvasMouseDown, false);
    canvas?.addEventListener('mousemove', onCanvasMouseMove, false);
    canvas?.addEventListener('mouseup', onCanvasMouseUp, false);
    canvas?.addEventListener('touchstart', onTouchDown, false);
    canvas?.addEventListener('touchmove', onTouchMove, false);
    canvas?.addEventListener('touchend', onTouchUp, false);
    return () => {
      canvas?.removeEventListener('mousedown', onCanvasMouseDown, false);
      canvas?.removeEventListener('mousemove', onCanvasMouseMove, false);
      canvas?.removeEventListener('mouseup', onCanvasMouseUp, false);
      canvas?.removeEventListener('touchstart', onTouchDown, false);
      canvas?.removeEventListener('touchmove', onTouchMove, false);
      canvas?.removeEventListener('touchend', onTouchUp, false);
    };
  }, [onCanvasMouseDown, onCanvasMouseMove, onCanvasMouseUp, onTouchDown, onTouchMove, onTouchUp]);

  useEffect(() => {
    if (!image.current) {
      loadImage();
    }
  }, [loadImage]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setCanvasSize({ width: contentRef.current?.offsetWidth, height: contentRef.current?.offsetHeight });
    }
  }, []);

  useEffect(() => {
    if (!image.current) loadImage();
    const { cw, ch, w: sw, h: sh } = getDimensions.current();
    const w = sw * canvasScaleRef.current;
    const h = sh * canvasScaleRef.current;
    const x = cw / 2 - w / 2;
    const y = ch / 2 - h / 2;
    imagePos.current = { x, y };
    draw.current();
  }, [canvasSize, canvasScale, canvasScaleRef, getDimensions, loadImage, draw]);

  useLayoutEffect(() => {
    draw.current();
  });

  useEffect(() => {
    lines.current = assetData.lines ?? [];
    tokens.current = assetData.tokens ?? [];
    tokens.current.forEach((token) => {
      const cachedImage = cachedTokenImages.current[token.id];
      if (cachedImage) return (token.image = cachedImage);
      const tokenAsset = tokenDocuments.find((t) => t.id === token.assetId);
      if (!tokenAsset) return;
      const image = new Image();
      image.onload = () => draw.current();
      token.image = image;
      token.image.src = tokenAsset.fileUrl;
      cachedTokenImages.current[token.id] = image;
    });
  }, [assetData, draw, tokenDocuments]);

  const roomState = useRoomState();

  if (!asset || (!previewRoom && !currentUser)) return null;

  const dpi = window.devicePixelRatio;
  const isShared = sharedAsset && sharedRoomDocument?.guidId === roomDocument?.guidId;
  const showCloseButton =
    !!previewRoom ||
    (roomDocument?.ownerType === 'User' && roomDocument?.ownerId === currentUser.id) ||
    room?.userId === currentUser.id ||
    (!isShared && privateAsset && privateRoomDocument?.guidId === roomDocument?.guidId);

  return (
    <TableViewOverlay
      asset={asset}
      roomDocument={roomDocument}
      className={className}
      closeLabel={isShared ? 'Stop Sharing' : 'Close'}
      onCloseClick={onCloseClick}
      showCloseButton={showCloseButton}
      showMinimizeButton={!previewRoom && roomState !== 'disconnected'}
      showMediaTools
      room={room}
    >
      <div ref={contentRef} className={styles.content}>
        <canvas
          ref={canvasRef}
          width={canvasSize.width * dpi}
          height={canvasSize.height * dpi}
          className={styles.canvas}
        />
      </div>
    </TableViewOverlay>
  );
}
