//@flow
import styles from './InfoTooltip.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import { ReactComponent as InfoIcon } from 'images/icons/InfoIcon.svg';

type Props = {
  className?: ?string,
  text: Node,
};

export default function InfoTooltip({ className, text }: Props): Node {
  return (
    <div className={clsx(styles.infoIcon, className)}>
      <div className={styles.infoTooltip}>{text}</div>
      <InfoIcon />
    </div>
  );
}
