import React, { Component } from 'react'
import { connect } from 'react-redux'

import SessionAction from 'store/actions/SessionAction';
import SessionSelector from 'store/selectors/SessionSelector';

import PageLoading from 'components/PageLoading';

export default ChildComponent => {
  class ComposedComponent extends Component {
    componentDidMount() {
      if (!this.props.currentUser) this.props.fetchCurrentUser()
    }

    render() {
      if (!this.props.currentUser) return <PageLoading />
      return <ChildComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return { currentUser: SessionSelector.currentUser(state) }
  }

  const mapDispatchToProps = {
    fetchCurrentUser: SessionAction.fetchCurrentUser,
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent)
}
