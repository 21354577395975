// @flow
import styles from './Dropdown.module.css';

import React, { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';
import Dropdown from './Dropdown';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function DropdownBuilder(props: Props) {
  const { element, onChange, sectionId, sheetGuid } = props;
  const { items, metadata } = element;

  const inputRef = useRef(null);

  const dropdownInput = items.find((item) => item.inputType === INPUT_TYPE.dropdown);
  const dropdownValue = dropdownInput?.defaultValue ?? '';
  const options = metadata.options ?? [];

  const onDropdownChange = (value) => {
    if (!dropdownInput) return;
    const updatedItems = items.map((o) => (o.id === dropdownInput.id ? { ...dropdownInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onOptionsBlur = (event) => {
    if (!dropdownInput) return;
    const updatedOptions = event.currentTarget.value
      .trim()
      .split('\n')
      .map((o) => o.trim());
    const updatedElement = { ...element, metadata: { ...metadata, options: updatedOptions } };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onOptionsChange = (event) => {
    if (!dropdownInput) return;
    const updatedOptions = event.currentTarget.value.split('\n');
    const updatedElement = { ...element, metadata: { ...metadata, options: updatedOptions } };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onOptionsKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) inputRef.current.blur();
  };

  // TODO: display dropdown element above textarea and use that to select default value
  return (
    <Builder {...props}>
      <div>
        <Dropdown
          className={styles.builderDropdown}
          value={dropdownValue}
          options={options}
          onChange={onDropdownChange}
        />
        <TextareaAutosize
          ref={inputRef}
          className={styles.builderInput}
          value={options.join('\n')}
          placeholder={`Enter each dropdown option on its own line, for example:\nOption 1\nOption 2\nOption 3`}
          minRows={3}
          maxRows={10}
          onBlur={onOptionsBlur}
          onChange={onOptionsChange}
          onKeyDown={onOptionsKeyDown}
        />
      </div>
    </Builder>
  );
}

const MemoizedDropdownBuilder = React.memo(DropdownBuilder);
MemoizedDropdownBuilder.displayName = 'MemoizedDropdownBuilder';

export default MemoizedDropdownBuilder;
