// @flow

export const FILTER: { [string]: string } = Object.freeze({
  all: 'all',
  pdfs: 'PDFs',
  maps: 'maps',
  tokens: 'tokens',
  images: 'images',
  audio: 'audio',
  apps: 'apps',
});

export const FILTERS: string[] = Object.freeze(Object.values(FILTER).map((o) => String(o)));
