import styles from './Card.module.css'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CardView } from 'models/Card';
import { randomCard } from 'utilities';
import RoomAction from 'store/actions/RoomAction';

class CardOutline extends Component {
  onClick = () => {
    const { room, updateCard, currentView, cards, userId: id, isGm, isOpenDraw } = this.props
    if (currentView === CardView.TABLE && !isGm && !isOpenDraw) return

    const card = randomCard(cards)
    if (!card) return

    let userId = null
    switch (currentView) {
      case CardView.PLAYER:
        userId = id
        break
      case CardView.TABLE:
      default:
        break
    }

    card.isDrawn = true
    card.userId = userId
    card.updatedAt = Date.now()
    updateCard(room.guid, card)
  }

  render() {
    // TEMP:
    const isTarot = this.props.currentDeck === 'swordsfallTarot'
    return (
      <div
        onClick={this.onClick}
        className={`box-shadow ${styles.container} ${styles.isOutline} ${isTarot ? styles.isTarot : ''}`}
      ></div>
    )
  }
}

CardOutline.defaultProps = {
  isGm: false,
  isOpenDraw: false,
}

const mapDispatchToProps = {
  updateCard: RoomAction.updateCard,
}

export default connect(null, mapDispatchToProps)(CardOutline)
