export const roomChannel = (guid) => `room-${guid}`;
export const roomPresenceChannel = (guid) => `presence-room-${guid}`;

export const ChannelEvent = {
  DIE_ADDED: 'client-die_added',
  DIE_REMOVED: 'client-die_removed',
  DECK_UPDATED: 'deck_updated',
  DOCUMENT_ADDED: 'document_added',
  DOCUMENT_REMOVED: 'document_removed',
  DOCUMENT_UPDATED: 'document_updated',
  MODIFIER_ADDED: 'client-modifier_added',
  MODIFIER_REMOVED: 'client-modifier_removed',
  OBR_ACTIVE: 'client-obr_active',
  PUSHER_CONNECTED: 'connected',
  PUSHER_MEMBER_ADDED: 'pusher:member_added',
  PUSHER_MEMBER_REMOVED: 'pusher:member_removed',
  PUSHER_SUBSCRIPTION_SUCCEEDED: 'pusher:subscription_succeeded',
  ROLL_COMPLETED: 'client-roll_completed',
  ROOM_DOCUMENT_UPDATED: 'room_document_updated',
  ROOM_DOCUMENTS_UPDATED: 'room_documents_updated',
  ROOM_REFRESH: 'room_refresh',
  ROOM_SHEET_UPDATED: 'room_sheet_updated',
  ROOM_SHEETS_UPDATED: 'room_sheets_updated',
  ROOM_UPDATED: 'room_updated',
  ROOM_USER_DELETED: 'room_user_deleted',
  ROOM_USER_UPDATED: 'room_user_updated',
  SHEET_ADDED: 'sheet_added',
  SHEET_REMOVED: 'sheet_removed',
  SHEET_INPUT_BLURRED: 'client-sheet_input_blurred',
  SHEET_INPUT_FOCUSED: 'client-sheet_input_focused',
  SHEET_TRANSFERRED: 'sheet_transferred',
  SHEET_UPDATED: 'sheet_updated',
  TABLE_PING: 'client-table_ping',
  VIDEO_ROOM_CREATED: 'video_room_created',
  VIDEO_ROOM_USER_CONNECTED: 'video_room_user_connected',
  VIDEO_ROOM_USER_DISCONNECTED: 'video_room_user_disconnected',
};
