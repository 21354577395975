// @flow
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

type size =
  | {
      width: number;
      height: number;
      isSm: boolean;
      isMd: boolean;
      isLg: boolean;
      isXl: boolean;
    }
  | {
      width: undefined;
      height: undefined;
      isSm: undefined;
      isMd: undefined;
      isLg: undefined;
      isXl: undefined;
    };

export default function useWindowSize(callback?: (size: size) => any) {
  const [windowSize, setWindowSize] = useState<size>({
    width: undefined,
    height: undefined,
    isSm: undefined,
    isMd: undefined,
    isLg: undefined,
    isXl: undefined,
  });

  useEffect(() => {
    const handleResize = throttle(() => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const size = {
        width,
        height,
        isSm: width >= 640,
        isMd: width >= 768,
        isLg: width >= 1024,
        isXl: width >= 1280,
      };
      setWindowSize(size);
      callback?.(size);
    }, 500);

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [callback]);

  return windowSize;
}
