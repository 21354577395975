// @flow
import React, { memo } from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';

import { INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { ChannelEvent } from 'models/Channel';
import { RoomSheetSelector, TableSelector } from 'store/selectors';

import Counter from './Counter';
import Element from 'components/Sheet2/elements/Element';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function CounterElement(props: Props): Node {
  const { colors, element, onChange: propOnChange, readOnly, roomGuid, sectionId, sheetGuid } = props;
  const { id, columns, items } = element;

  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));

  const currentInput = items.find((o) => o.inputType === INPUT_TYPE.counterCurrent);
  const maxInput = items.find((o) => o.inputType === INPUT_TYPE.counterMax);

  const currentValue = currentInput.value ?? currentInput?.defaultValue ?? '';
  const maxValue = maxInput?.value ?? maxInput?.defaultValue;
  const values = [currentValue];
  if (maxInput) values.push(maxValue);

  const onChange = (values: string[]) => {
    if (!currentInput && !maxInput) return;
    const updatedItems = items.map((o) => {
      if (currentInput && o.id === currentInput.id) {
        return { ...currentInput, value: values[0] };
      } else if (maxInput && o.id === maxInput.id && values.length === 2) {
        return { ...maxInput, value: values[1] };
      } else {
        return o;
      }
    });
    propOnChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  return (
    <Element {...props}>
      <Counter
        values={values}
        columns={columns}
        canEditCurrent
        canEditMax={!!maxInput && maxInput.canEdit}
        readOnly={readOnly}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
    </Element>
  );
}

export default memo(CounterElement);
