import { createAction } from './utilities'

export default class CardAction {
  static CANCEL_SHUFFLE = 'CardAction.CANCEL_SHUFFLE'
  static CONFIRM_SHUFFLE = 'CardAction.CONFIRM_SHUFFLE'
  static DISCARD_CARD = 'CardAction.DISCARD_CARD'
  static DRAW_CARD = 'CardAction.DRAW_CARD'
  static DRAW_DISCARDED_CARD = 'CardAction.DRAW_DISCARDED_CARD'
  static FLIP_CARD = 'CardAction.FLIP_CARD'
  static ROTATE_CARD = 'CardAction.ROTATE_CARD'
  static SELECT_DECK = 'CardAction.SELECT_DECK'
  static SELECT_SHUFFLE = 'CardAction.SELECT_SHUFFLE'
  static SELECT_VIEW = 'CardAction.SELECT_VIEW'
  static SHUFFLE_DECK = 'CardAction.SHUFFLE_DECK'

  static cancelShuffle = () => {
    return createAction(CardAction.CANCEL_SHUFFLE)
  }

  static confirmShuffle = shuffleMode => {
    return createAction(CardAction.CONFIRM_SHUFFLE, shuffleMode)
  }

  static discardCard = (deckId, cardId) => {
    return createAction(CardAction.DISCARD_CARD, { deckId, cardId })
  }

  static drawCard = (deckId, cardId, userId) => {
    return createAction(CardAction.DRAW_CARD, { deckId, cardId, userId })
  }

  static drawDiscardedCard = (deckId, cardId, userId) => {
    return createAction(CardAction.DRAW_DISCARDED_CARD, { deckId, cardId, userId })
  }

  static flipCard = (deckId, cardId) => {
    return createAction(CardAction.FLIP_CARD, { deckId, cardId })
  }

  static rotateCard = (deckId, cardId) => {
    return createAction(CardAction.ROTATE_CARD, { deckId, cardId })
  }

  static selectDeck = id => {
    return createAction(CardAction.SELECT_DECK, id)
  }

  static selectShuffle = () => {
    return createAction(CardAction.SELECT_SHUFFLE)
  }

  static selectView = id => {
    return createAction(CardAction.SELECT_VIEW, id)
  }
}
