// @flow

import _ from 'lodash';
import { createSelector } from 'reselect';

const orderByUpdatedAt = (obj) => obj.updatedAt;

export default class GameSelector {
  static get = createSelector(
    (state, id) => state.games[id],
    (game) => game
  );

  static getBySlug = createSelector(
    (state, slug) => Object.values(state.games).find((game) => game.slug === slug),
    (game) => game
  );

  static getAll = createSelector(
    (state) => state.games,
    (games) => _.orderBy(Object.values(games), [orderByUpdatedAt], ['desc'])
  );

  static getByOrg = createSelector([(state) => state.games, (state, id) => id], (games, orgId) =>
    Object.values(games).filter((game) => game.organizationId === orgId)
  );

  static getByRoom = createSelector(
    [(state) => state.rooms, (state) => state.games, (state, guid) => guid],
    (rooms, games, guid) => games[rooms[guid]?.gameId]
  );
}
