import { useEffect } from 'react';

import useCallbackAsRef from './useCallbackAsRef';

export default function useTimeout(callback: Function, delay: number | Number) {
  const ref = useCallbackAsRef(callback);

  useEffect(() => {
    const id = setTimeout(() => ref.current(), delay.valueOf());
    return () => clearTimeout(id);
  }, [delay, ref]);
}
