//@flow
import React from 'react';
import { useDispatch } from 'react-redux';

import type { SheetModel } from 'components/Sheet2/types';
import SheetAction from 'store/actions/SheetAction';

import Button from 'components/buttons/Button';
import SheetCard from 'components/cards/SheetCard';
import { TitleModal } from 'components/Modal';

type Props = {
  onDismiss: () => void,
  sheet: SheetModel,
};

export default function RemoveSheetModal(props: Props) {
  const { onDismiss, sheet } = props;

  const dispatch = useDispatch();
  const onRemoveClick = () => {
    dispatch(SheetAction.delete(sheet.id));
  };

  return (
    <TitleModal
      title="Delete this Sheet?"
      subtitle="This will permanently remove it from your account, and from any tables where it is currently in use."
      actions={
        <>
          <Button variant="cancel" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onRemoveClick}>
            Delete
          </Button>
        </>
      }
      onDismiss={onDismiss}
    >
      <div style={{ padding: '0 10px' }}>
        <SheetCard sheet={sheet} />
      </div>
    </TitleModal>
  );
}
