// @flow
import styles from './Group.module.css';

import React from 'react';
import type { Node } from 'react';

import type { ElementProps, ElementModel } from 'components/Sheet2/types';

import Group from './Group';
import GroupCollection from './GroupCollection';

type ModelProps = {
  ...ElementModel,
  items: ElementModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function GroupElement(props: Props): Node {
  const {
    element: {
      metadata: { collection },
    },
  } = props;

  return (
    <div className={styles.gridContainer}>{collection ? <GroupCollection {...props} /> : <Group {...props} />}</div>
  );
}

export default GroupElement;
