// @flow
import { useEffect, useState } from 'react';

export default function useModifierIsSelected(modifier) {
  const [isSelected, setIsSelected] = useState(!!modifier && modifier.isSelected);

  useEffect(() => {
    if (modifier) setIsSelected(modifier.isSelected);
  }, [modifier]);

  return isSelected;
}
