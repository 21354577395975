import { createAction, createError } from './utilities';

export default class ErrorAction {
  static REMOVE = 'ErrorAction.REMOVE';
  static REMOVE_ALL = 'ErrorAction.REMOVE_ALL';

  static create = (error: ReduxError) => createAction(error.id, createError(error), true);

  static remove = (id: string) => createAction(ErrorAction.REMOVE, id);

  static removeAll = () => createAction(ErrorAction.REMOVE_ALL);
}
