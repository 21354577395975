// @flow
import styles from './Buff.module.css';

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import useFontSize from 'hooks/useFontSize';

import EditIcon from 'components/Sheet2/atoms/EditIcon';
import GradientHexagon from 'components/Sheet2/atoms/GradientHexagon';
import LockedIcon from 'components/Sheet2/atoms/LockedIcon';
import Spacer from 'components/Sheet2/atoms/Spacer';
import { ReactComponent as BuffIcon } from 'images/icons/BuffIcon.svg';

type Props = {
  background: string,
  canEdit: boolean,
  className?: ?string,
  isBuilding: boolean,
  isSelected: boolean,
  onBlur?: ?() => void,
  onChange: (string) => void,
  onFocus?: ?() => void,
  onClick?: ?(boolean) => void,
  primaryColor: string,
  readOnly: boolean,
  showSpacer: boolean,
  secondaryColor: string,
  value: string,
};

const FontSize = {
  '3': 18,
  '4': 14,
  '5': 12,
};

const REGEX = /^-{0,1}\d*$/;

function Buff(props: Props): Node {
  const {
    background,
    canEdit,
    className,
    isBuilding,
    isSelected,
    onBlur: propOnBlur,
    onChange: propOnChange,
    onFocus,
    onClick: propOnClick,
    primaryColor,
    readOnly,
    showSpacer,
    secondaryColor,
    value: rawValue,
  } = props;

  const inputRef = useRef(null);
  const hoverTimerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(isBuilding);
  const [isHovering, setIsHovering] = useState(false);
  const fontSize = useFontSize(String(rawValue).replace('-', '').length, FontSize);

  useEffect(() => {
    return () => clearTimeout(hoverTimerRef.current);
  }, []);

  useEffect(() => {
    if (inputRef.current && isEditing && !isBuilding) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isBuilding, isEditing]);

  const value = parseInt(rawValue) > 0 && !isEditing ? `+${rawValue}` : rawValue;
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isEditing) classNames.push(styles.isEditing);
  if (isHovering) classNames.push(styles.isHovering);
  if (isSelected) classNames.push(styles.isSelected);

  const onBlur = (event) => {
    if (!isBuilding) setIsEditing(false);
    let value = event.currentTarget.value;
    if (value.match(REGEX)) {
      if (value === '' || value === '-') value = '0';
      propOnChange(value);
    }
    if (propOnBlur) propOnBlur();
  };

  const onChange = (event) => {
    const { value } = event.currentTarget;
    if (value.match(REGEX)) propOnChange(value);
  };

  const onClick = () => {
    if (propOnClick) propOnClick(!isSelected);
  };

  const onEditClick = () => setIsEditing((prev) => !prev);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) inputRef.current.blur();
  };

  const onMouseEnter = () => {
    clearTimeout(hoverTimerRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    hoverTimerRef.current = setTimeout(removeHover, 500);
  };

  const removeHover = () => setIsHovering(false);

  return (
    <div className={classNames.join(' ')}>
      {showSpacer && (
        <Spacer type="buff" className={styles.spacer} primaryColor={primaryColor} secondaryColor={secondaryColor} />
      )}
      <div className={styles.buff}>
        <GradientHexagon
          className={styles.gradient}
          icon={BuffIcon}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <GradientHexagon
          className={styles.background}
          icon={BuffIcon}
          primaryColor={background}
          secondaryColor={background}
        />
        <div className={styles.content} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {((canEdit && !readOnly) || isBuilding) && isEditing ? (
            <input
              ref={inputRef}
              className={styles.input}
              style={{ fontSize }}
              type="text"
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          ) : (
            <button className={`button-reset ${styles.value}`} style={{ fontSize }} onClick={onClick}>
              {value}
            </button>
          )}
          {!canEdit && <LockedIcon className={styles.lockedIcon} />}
        </div>
        {canEdit && !readOnly && !isBuilding && (
          <EditIcon
            className={styles.editButton}
            isEditing={isEditing}
            onClick={onEditClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </div>
    </div>
  );
}

Buff.defaultProps = {
  background: 'var(--color-black)',
  canEdit: false,
  isBuilding: false,
  isSelected: false,
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  showSpacer: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Buff;
