import styles from './EditMapAssetModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import DocumentAction from 'store/actions/DocumentAction';

import { AssetImage } from 'components/Asset';
import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

class EditMapAssetModal extends Component {
  onSubmit = (formValues) => {
    const { asset, updateDocument, onDismiss } = this.props;
    const document = {
      metadata: {
        horizontal: parseInt(formValues.horizontal),
        vertical: parseInt(formValues.vertical),
      },
    };
    updateDocument(asset.id, { document }, () => onDismiss());
  };

  renderInput = ({ input, type, min, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <TextInput
        input={input}
        meta={meta}
        type={type}
        min={min}
        placeholder={placeholder}
        showError={submitFailed}
        className={styles.input}
      />
    );
  };

  render() {
    const { asset, onDismiss, handleSubmit } = this.props;

    return (
      <TitleModal title="Map Size" subtitle="What are the dimensions of this map?" onDismiss={onDismiss}>
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <AssetImage name={asset.name} url={asset.thumbnailUrl} className={styles.asset} />
          <p>
            <strong>Number of Tiles</strong> (Hex or Square)
          </p>
          <div className={styles.inputs}>
            <Field component={this.renderInput} name="horizontal" type="number" min={1} placeholder="Horizontal" />
            <div className={`heading2 ${styles.by}`}>by</div>
            <Field component={this.renderInput} name="vertical" type="number" min={1} placeholder="Vertical" />
          </div>
          <Button variant="primary" type="submit" className={styles.button}>
            Set Map Size
          </Button>
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    asset: {
      metadata: { horizontal, vertical },
    },
  } = props;
  return {
    initialValues: {
      horizontal,
      vertical,
    },
  };
};

const mapDispatchToProps = {
  updateDocument: DocumentAction.update,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'editMapAsset' })
)(EditMapAssetModal);
