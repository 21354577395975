import styles from './TableChat.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';

import { CHAT_PAGE_SIZE, DICE_LOG_TOKEN } from 'models/Twilio';
import { TwilioAction } from 'store/actions';
import { RoomUserSelector, SessionSelector, TableSelector, TwilioSelector } from 'store/selectors';

import DiceMessage from './DiceMessage';
import Message from './Message';

function ColorMessage({ user, sid, message, guid, isSelf, isRoll }) {
  const colors = useSelector((state) => RoomUserSelector.getColors(state, guid, user.userId));

  if (isRoll) return <DiceMessage key={sid} message={message} user={user} isSelf={isSelf} colors={colors} />;
  else return <Message key={sid} message={message} user={user} isSelf={isSelf} colors={colors} />;
}
class EverythingConversation extends Component {
  state = { isFetchingDice: false, isFetchingEveryone: false };
  bottomRef = React.createRef();
  containerRef = React.createRef();

  componentDidMount() {
    this.setupConversations();
  }

  componentDidUpdate() {
    if (this.props.isShowingChat) this.scrollToBottom();
  }

  componentWillUnmount() {
    this.teardownConversation(this.props.everyoneConversation);
    this.teardownConversation(this.props.diceConversation);
    if (this.containerRef.current) this.containerRef.current.removeEventListener('scroll', this.throttledOnScroll);
  }

  finishConversationSetup = (conversation) => {
    const { updateUnreadCount } = this.props;
    if (this.containerRef.current) {
      this.containerRef.current.removeEventListener('scroll', this.throttledOnScroll);
      this.containerRef.current.addEventListener('scroll', this.throttledOnScroll);
    }
    conversation.on('messageAdded', this.onMessageAdded);
    if (this.props.isShowingChat) updateUnreadCount(conversation.sid, 0);
    this.scrollToBottom();
  };

  setupConversations = () => {
    const { isFetchingDice, isFetchingEveryone } = this.state;
    const { diceConversation, dicePaginator, everyoneConversation, everyonePaginator } = this.props;
    const { updateMessages, updatePaginator } = this.props;

    if (everyonePaginator) {
      this.finishConversationSetup(everyoneConversation);
    } else if (!isFetchingEveryone) {
      this.setState({ isFetchingEveryone: true }, () => {
        const conversation = everyoneConversation;
        conversation.getMessages(CHAT_PAGE_SIZE).then((paginator) => {
          const { sid } = conversation;
          const messages = paginator.items;
          updatePaginator(sid, paginator);
          updateMessages(sid, messages);
          if (conversation.lastMessage) conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
          this.setState({ isFetchingEveryone: false });
          this.finishConversationSetup(conversation);
        });
      });
    }

    if (dicePaginator) {
      this.finishConversationSetup(diceConversation);
    } else if (!isFetchingDice) {
      this.setState({ isFetchingDice: true }, () => {
        const conversation = diceConversation;
        conversation.getMessages(CHAT_PAGE_SIZE).then((paginator) => {
          const { sid } = conversation;
          const messages = paginator.items;
          updatePaginator(sid, paginator);
          updateMessages(sid, messages);
          if (conversation.lastMessage) conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
          this.setState({ isFetchingDice: false });
          this.finishConversationSetup(conversation);
        });
      });
    }
  };

  teardownConversation = (conversation) => {
    conversation.off('messageAdded', this.onMessageAdded);
    if (conversation.lastMessage) conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
  };

  scrollToBottom = (behavior = 'auto') => {
    if (this.bottomRef.current && this.containerRef.current) {
      const top = this.bottomRef.current.offsetTop;
      this.containerRef.current.scroll({ top: top, behavior });
    }
  };

  onMessageAdded = (message) => {
    const { conversation } = message;
    if (this.props.isShowingChat) {
      conversation.updateLastReadMessageIndex(message.index);
      this.scrollToBottom('smooth');
    }
  };

  onScroll = () => {
    const { isFetchingDice, isFetchingEveryone } = this.state;
    const { diceConversation, diceMessages, dicePaginator, everyoneConversation, everyoneMessages, everyonePaginator } =
      this.props;
    const { updateMessages, updatePaginator } = this.props;
    const scrollTop = this.containerRef.current.scrollTop;

    if (scrollTop > 0) return;

    if (everyonePaginator && everyonePaginator.hasPrevPage && !isFetchingEveryone) {
      this.setState({ isFetchingEveryone: true }, () => {
        const currentHeight = this.containerRef.current.scrollHeight;
        everyonePaginator.prevPage().then((newPaginator) => {
          const { sid } = everyoneConversation;
          const prevMessages = newPaginator.items;
          updatePaginator(sid, newPaginator);
          updateMessages(sid, [...prevMessages, ...everyoneMessages]);
          this.containerRef.current.scroll({ top: this.containerRef.current.scrollHeight - currentHeight });
          this.setState({ isFetchingEveryone: false });
        });
      });
    }

    if (dicePaginator && dicePaginator.hasPrevPage && !isFetchingDice) {
      this.setState({ isFetchingDice: true }, () => {
        const currentHeight = this.containerRef.current.scrollHeight;
        dicePaginator.prevPage().then((newPaginator) => {
          const { sid } = diceConversation;
          const prevMessages = newPaginator.items;
          updatePaginator(sid, newPaginator);
          updateMessages(sid, [...prevMessages, ...diceMessages]);
          this.containerRef.current.scroll({ top: this.containerRef.current.scrollHeight - currentHeight });
          this.setState({ isFetchingDice: false });
        });
      });
    }
  };
  throttledOnScroll = _.throttle(this.onScroll, 50);

  renderMessage = (message) => {
    const { currentUser, users, guid } = this.props;
    const { author, body, sid } = message;
    const user = users.find((o) => o.userId === author);

    if (!user) return null;

    const isSelf = user.userId === currentUser.id;
    const isRoll = body.includes(DICE_LOG_TOKEN);

    return <ColorMessage key={sid} {...{ user, sid, message, guid, isSelf, isRoll }} />;
  };

  render() {
    return (
      <div ref={this.containerRef} className={clsx('scrollbars-dark', styles.conversationContainer)}>
        <div className={styles.conversation}>{this.props.messages.map((message) => this.renderMessage(message))}</div>
        <div ref={this.bottomRef} className={styles.conversationBottom} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { diceConversation, everyoneConversation, room } = props;
  const { guid } = room;

  return {
    guid,
    currentUser: SessionSelector.currentUser(state),
    diceMessages: TwilioSelector.getMessages(state, diceConversation.sid),
    dicePaginator: TwilioSelector.getPaginatorForDice(state, guid),
    everyoneMessages: TwilioSelector.getMessages(state, everyoneConversation.sid),
    everyonePaginator: TwilioSelector.getPaginatorForEveryone(state, guid),
    isShowingChat: TableSelector.isShowingChat(state),
    messages: TwilioSelector.getMessagesForEverything(state, guid),
    users: RoomUserSelector.getAllByRoom(guid)(state),
  };
};

const mapDispatchToProps = {
  addMessage: TwilioAction.addMessage,
  updateMessages: TwilioAction.updateMessages,
  updatePaginator: TwilioAction.updatePaginator,
  updateUnreadCount: TwilioAction.updateUnreadCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(EverythingConversation);
