import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RemoteAudioTrack } from 'twilio-video';

import { TwilioSelector } from 'store/selectors';

interface AudioTrackProps {
  track: RemoteAudioTrack;
  isLocalParticipant?: boolean;
}

export default function AudioTrack({ track, isLocalParticipant }: AudioTrackProps) {
  const { outputDevice } = useSelector(TwilioSelector.getSettings);
  const ref = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.setAttribute('data-cy-audio-track-name', track.name);

    track.attach(el);
    return () => {
      track.detach(el);
      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [isLocalParticipant, track]);

  useEffect(() => {
    ref.current?.setSinkId?.(outputDevice);
  }, [outputDevice]);
  return <audio ref={ref} />;
}
