// @flow
import styles from './SectionBuilder.module.css';

import type { Node } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import type { OnChangeSection, OnMoveSection, SectionModel } from 'components/Sheet2/types';

import EditIcon from 'components/Sheet2/atoms/EditIcon';
import { SectionHeading } from 'components/Sheet2/Section';

type Props = {
  children: Node[],
  section: SectionModel,
  refs: {
    current: {
      id: string,
    },
  },
  index: number,
  isLastSection: boolean,
  onChangeSection: OnChangeSection,
  onMoveSection: OnMoveSection,
  onRemove: (string) => void,
  sheetGuid: string,
};

function SectionBuilder(props: Props): Node {
  const { children, section, refs, index, onChangeSection, onMoveSection, onRemove, sheetGuid, isLastSection } = props;
  const { id, title } = section;

  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const sectionClassNames = [styles.content];

  useEffect(() => {
    if (!inputRef.current) return;
    if (isEditing) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);

  const setSectionRefs = (el, id, innerRef) => {
    refs.current[id] = el;
    innerRef(el);
  };

  const onEditClick = () => {
    setIsEditing((prev) => !prev);
  };

  const onChange = (event) => {
    const newTitle = event.currentTarget.value;
    onChangeSection(sheetGuid, { ...section, title: newTitle });
  };

  const onBlur = (event) => {
    setIsEditing(false);
    const newTitle = event.currentTarget.value.trim();
    onChangeSection(sheetGuid, { ...section, title: newTitle });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) inputRef.current.blur();
  };

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <section
          {...provided.droppableProps}
          ref={(el) => setSectionRefs(el, id, provided.innerRef)}
          className={styles.section}
        >
          <SectionHeading
            className={`${styles.sectionHeading} ${isEditing ? styles.isEditing : ''}`}
            onRemove={() => onRemove(id)}
            isEditing={isEditing}
            index={index}
            onMoveSection={onMoveSection}
            isLastSection={isLastSection}
          >
            {isEditing ? (
              <>
                <input
                  ref={inputRef}
                  className={styles.input}
                  type="text"
                  value={title}
                  onChange={onChange}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                />
                <EditIcon className={styles.editButton} isEditing={isEditing} onClick={onEditClick} />
              </>
            ) : (
              <h3 className={styles.heading}>
                <span onClick={onEditClick}>{title || `Section ${index + 1}`}</span>
                <EditIcon className={styles.editButton} isEditing={isEditing} onClick={onEditClick} />
              </h3>
            )}
          </SectionHeading>
          <div className={sectionClassNames.join(' ')}>
            <div className={styles.contentInner}>{children}</div>
          </div>
          {provided.placeholder}
        </section>
      )}
    </Droppable>
  );
}

export default SectionBuilder;
