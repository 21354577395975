import styles from './TableMediaView.module.css';

import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { MediaAction, TableAction } from 'store/actions';
import { TableSelector } from 'store/selectors';
import type { DocumentModel, RoomModel } from 'types/common';
import { MediaMode, TableViewMode } from 'types/room';
import useVideoContext from 'video/hooks/useVideoContext';

import TableMediaTools from './TableMediaTools';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as MaximizeIcon } from 'images/icons/MaximizeIcon.svg';
import { ReactComponent as MinimizeIcon } from 'images/icons/MinimizeIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

type Props = {
  asset?: DocumentModel;
  roomDocument?: any;
  closeLabel?: string;
  children: ReactNode;
  className?: string;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onMaximizeClick?: () => void;
  onMinimizeClick?: () => void;
  previewLabel?: string;
  room?: RoomModel;
  showCloseButton?: boolean;
  showMinimizeButton?: boolean;
  showMaximizeButton?: boolean;
  showMediaTools?: boolean;
};

export default function TableViewOverlay({
  asset,
  children,
  className,
  closeLabel,
  onCloseClick,
  onMaximizeClick: propOnMaximizeClick,
  onMinimizeClick: propOnMinimizeClick,
  previewLabel,
  room,
  roomDocument,
  showCloseButton = true,
  showMinimizeButton = true,
  showMaximizeButton = false,
  showMediaTools,
}: Props) {
  const dispatch = useDispatch();
  const [, setSelectedParticipant] = useSelectedParticipant();
  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const { room: videoRoom } = useVideoContext();

  const onMaximizeClick = () => {
    batch(() => {
      propOnMaximizeClick?.();
      dispatch(TableAction.updateViewMode(TableViewMode.Speaker));
      dispatch(MediaAction.setMode(MediaMode.None));
      dispatch(TableAction.setActiveRoomDocument(null));
      setSelectedParticipant(null);
    });
  };

  const onMinimizeClick = () => {
    propOnMinimizeClick?.();
    batch(() => {
      dispatch(MediaAction.setMode(MediaMode.None));
      dispatch(TableAction.setActiveRoomDocument(null));
      setSelectedParticipant(null);
    });
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      {children}
      <div
        className={clsx(
          styles.buttons,
          !videoRoom && activeRoomDocument && styles.lobbyOffset,
          room?.lobbyMusicUrl && styles.lobbyMusicOffset
        )}
        onClick={showMaximizeButton ? onMaximizeClick : undefined}
      >
        {showMaximizeButton && (
          <IconButton
            buttonSize={40}
            iconSize={18}
            borderRadius={40}
            color="var(--color-white)"
            background="rgb(var(--color-theme-button))"
            hoverBackground="rgb(var(--color-theme-accent))"
            children={<MaximizeIcon />}
            label="Maximize"
          />
        )}
        {showMinimizeButton && (
          <IconButton
            buttonSize={40}
            iconSize={22} // Minimize icon appears small because of its shape
            borderRadius={40}
            color="var(--color-white)"
            background="rgb(var(--color-theme-button))"
            hoverBackground="rgb(var(--color-theme-accent))"
            children={<MinimizeIcon />}
            onClick={onMinimizeClick}
            label="Minimize"
          />
        )}
        {showCloseButton && (
          <IconButton
            className={styles.closeButton}
            buttonSize={40}
            iconSize={14}
            borderRadius={40}
            color="var(--color-white)"
            background="rgb(var(--color-theme-button))"
            hoverBackground="rgb(var(--color-theme-accent))"
            children={<XIcon />}
            onClick={onCloseClick}
            label={closeLabel ?? 'Close'}
          />
        )}
      </div>
      {previewLabel && <div className={styles.previewLabel}>{previewLabel}</div>}

      {showMediaTools && (
        <TableMediaTools room={room} asset={asset} roomDocument={roomDocument} className={styles.tools} />
      )}
    </div>
  );
}
