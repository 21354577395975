import { ConnectOptions } from 'twilio-video';

import { isMobile, isTouch } from 'utilities';

const VIDEO_DIMENSIONS = {
  fullHd: { width: 1920, height: 1080 }, // 1080p
  hd: { width: 1280, height: 720 }, // 720p
  standard: { width: 858, height: 480 }, // 480p
  low: { width: 480, height: 360 }, // 360p
};

export const CHAT_PAGE_SIZE = 50;
export const DICE_LOG_TOKEN = 'ROLE::DICE::';
export const PING_TOKEN = 'ROLE::PING::';
export const VIDEO_REFRESH_TIME = 2 * (10800000 + 3540000 + 50000); // 07:58:40

export const getLocalVideoOptions = (mode?: 'premium' | 'screenshare') => {
  if (mode === 'premium') return { ...VIDEO_DIMENSIONS.hd, frameRate: 24 };
  if (mode === 'screenshare') return { ...VIDEO_DIMENSIONS.fullHd, frameRate: 15 };
  return { ...VIDEO_DIMENSIONS.standard, frameRate: 24 };
};

export const getConnectOptions = () => {
  const options: ConnectOptions = {
    dominantSpeaker: true,
    bandwidthProfile: {
      video: {
        mode: 'grid',
        trackSwitchOffMode: 'detected',
        contentPreferencesMode: 'auto',
      },
    },

    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: 16000,

    // TODO: Adapt this codec to the platform, e.g. iOS for H264
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],

    // Stops Twilio from automatically trying to start a user's camera and microphone
    video: false,
    audio: false,
  };
  if (isMobile || isTouch) options.bandwidthProfile!.video!.maxSubscriptionBitrate = 2400000;
  return options;
};
