import React, { Component } from 'react';
import { connect } from 'react-redux';

import SessionSelector from 'store/selectors/SessionSelector';

export default function HOC(ChildComponent) {
  class ComposedComponent extends Component {
    componentDidMount() {
      const {
        history,
        isAuthed,
        location: { search },
      } = this.props;
      const redirectTo = new URLSearchParams(search).get('to');
      if (isAuthed) history.push(redirectTo || '/dashboard');
    }

    render() {
      if (this.props.isAuthed) return null;
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { isAuthed: SessionSelector.isAuthed(state) };
  }

  return connect(mapStateToProps)(ComposedComponent);
}
