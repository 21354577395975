// @flow
import styles from './Slots.module.css';

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import { linearGradient } from 'utilities/color';
import EditIcon from 'components/Sheet2/atoms/EditIcon';
import Stepper from 'components/Sheet2/atoms/Stepper';

type Props = {
  canEdit: boolean,
  className?: ?string,
  columns: number,
  isBuilding: boolean,
  items: boolean[],
  onChange: (boolean, number) => void,
  onCountBlur?: ?() => void,
  onCountChange: (string) => void,
  onCountFocus?: ?() => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
};

const MAX_SLOTS = 100;
const SLOTS_PER_ROW_MAX = 10;
const SLOTS_PER_ROW_MIN = 5;

function Slots(props: Props): Node {
  const {
    canEdit,
    className,
    columns,
    isBuilding,
    items,
    onChange: propOnChange,
    onCountBlur,
    onCountFocus,
    onCountChange: propOnCountChange,
    primaryColor,
    readOnly,
    secondaryColor,
  } = props;
  const hoverTimerRef = useRef(null);
  const total = items.length;
  const isOneColumn = columns === 1;
  const slotsPerRow = isOneColumn ? SLOTS_PER_ROW_MIN : SLOTS_PER_ROW_MAX;

  const [count, setCount] = useState(total);
  const [isEditing, setIsEditing] = useState(isBuilding);
  const [isHovering, setIsHovering] = useState(false);

  const classNames = [styles.container];
  if (isEditing) classNames.push(styles.isEditing);
  if (isHovering) classNames.push(styles.isHovering);
  if (readOnly) classNames.push(styles.readOnly);
  if (className) classNames.push(className);
  const gradient = linearGradient([primaryColor, secondaryColor], '95deg');

  useEffect(() => {
    return () => clearTimeout(hoverTimerRef.current);
  }, []);

  const onChange = (event, index) => {
    if (readOnly) return;
    propOnChange(event.currentTarget.checked, index);
  };

  const onCountChange = (values) => {
    setCount(values[0]);
    if (!isNaN(parseInt(values[0]))) propOnCountChange(values[0]);
  };

  const onEditClick = () => {
    if (isEditing) {
      setIsEditing(false);
      removeHover();
    } else {
      setIsEditing(true);
    }
  };

  const onMouseEnter = () => {
    clearTimeout(hoverTimerRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    hoverTimerRef.current = setTimeout(removeHover, 500);
  };

  const removeHover = () => setIsHovering(false);

  const renderRows = () => {
    const rows = [];
    let count = 0;
    while (count <= total - 1) {
      const countInner = count;
      const isOdd = rows.length % 2 === 1;
      const rowItems = items.slice(count, count + slotsPerRow);
      const row = (
        <div key={count} className={`${styles.row} ${isOdd ? styles.isOdd : ''}`}>
          {rowItems.map((value, i) => {
            return (
              <label key={i} className={styles.slot}>
                <input
                  className={styles.input}
                  type="checkbox"
                  checked={value}
                  onChange={(e) => onChange(e, countInner + i)}
                />
                <span className={styles.gradient} style={{ background: gradient }} />
              </label>
            );
          })}
        </div>
      );
      rows.push(row);
      count += slotsPerRow;
    }
    return rows;
  };

  return (
    <div className={classNames.join(' ')}>
      {((canEdit && !readOnly) || isBuilding) && isEditing && (
        <Stepper
          className={styles.stepper}
          values={[`${count}`, `${MAX_SLOTS}`]}
          minValue={1}
          canEditCurrent
          isCompact
          onCurrentBlur={onCountBlur}
          onCurrentFocus={onCountFocus}
          onChange={onCountChange}
        />
      )}
      <div className={styles.content}>
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {renderRows()}
        </div>
        {canEdit && !readOnly && !isBuilding && (
          <EditIcon
            className={styles.editButton}
            isEditing={isEditing}
            onClick={onEditClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </div>
    </div>
  );
}

Slots.defaultProps = {
  canEdit: false,
  isBuilding: false,
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Slots;
