import styles from './FTE.module.css';

import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { DEFAULT_COLORS, DEFAULT_ROOM_THEME } from 'models/Color';
import { ProductAction, RoomAction, UserAction } from 'store/actions';
import { GameSelector, OrganizationSelector, ProductSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';
import { stylizeTheme } from 'utilities/color';

import Button from 'components/buttons/Button';
import { CloseButton } from 'components/buttons/IconButton';
import UpdateRoomForm from 'components/CreateRoomForm/UpdateRoomForm';
import { BundleItem, getBundleItemIcon } from 'components/ProductDetails/BundleItemIcon';
import DefaultSheetAvatar from 'images/DefaultSheetAvatar.jpg';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/ArrowLeftIcon.svg';
import { ReactComponent as DoubleArrow } from 'images/icons/DoubleArrow.svg';

interface Props {
  roomGuid: string;
}

export default function RoomFTE({ roomGuid }: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const allGames = useSelector(GameSelector.getAll);

  const [selectedGame, setSelectedGame] = useState<any | null>(null);
  const [gameQuery, setGameQuery] = useState('');
  const queryFilteredGames =
    gameQuery === '' || !allGames
      ? allGames ?? []
      : allGames.filter((g) => g.title.toLowerCase().includes(gameQuery.toLowerCase()));

  const defaultGame = useMemo(
    () => ({
      title: gameQuery || 'Custom Game',
      colors: DEFAULT_COLORS,
      isDefaultGame: true,
      theme: { ...DEFAULT_ROOM_THEME, 'base': DEFAULT_ROOM_THEME.button, 'button': DEFAULT_ROOM_THEME.base },
      coverArtUrl: DefaultSheetAvatar,
    }),
    [gameQuery]
  );

  useEffect(() => {
    logEvent(`room create fte - started`);
  }, []);

  const addGameToRoom = useCallback(
    (game: any) => {
      const { theme: themeColors, backgroundArtUrl, isDefaultGame } = game || defaultGame;
      setSelectedGame(game || defaultGame);

      logEvent('room create fte - clicked play game', {
        'is default game': !!isDefaultGame,
        'game id': game?.id,
        'game title': game ? game.title : defaultGame.title,
        'game query': gameQuery,
      });

      if (!isDefaultGame) {
        batch(() => {
          dispatch(RoomAction.setRoomTheme({ guid: roomGuid, theme: themeColors }));
          dispatch(RoomAction.setRoomBackground({ guid: roomGuid, backgroundArtUrl: backgroundArtUrl }));
        });
      }
    },
    [defaultGame, dispatch, gameQuery, roomGuid]
  );

  const onUpdateRoom = useCallback(
    (game: any) => {
      logEvent('room create fte - clicked launch room', {
        'is default game': !!game.isDefaultGame,
        'game id': game.id,
        'game title': game.title,
        'game query': gameQuery,
      });

      const roomGameQuery = game.isDefaultGame && game.title.length > 0 ? game.title : null;
      const payload = {
        user: {
          metadata: {
            ...currentUser.metadata,
            fte: { ...currentUser.metadata.fte, showRoomCreate: false, gameQuery: roomGameQuery },
          },
        },
      };
      dispatch(UserAction.updateJson(currentUser.id, payload));
    },
    [currentUser.id, currentUser.metadata, dispatch, gameQuery]
  );

  const skipRoomFte = useCallback(() => {
    logEvent('room create fte - click skip');
    if (!currentUser) return;
    const payload = {
      user: { metadata: { ...currentUser.metadata, fte: { ...currentUser.metadata.fte, showRoomCreate: false } } },
    };
    dispatch(UserAction.updateJson(currentUser.id, payload));
  }, [currentUser, dispatch]);

  const onClearGameClick = useCallback(() => {
    logEvent('room create fte - click clear game');
    setSelectedGame(null);
    setGameQuery('');
    batch(() => {
      dispatch(RoomAction.setRoomTheme({ guid: roomGuid, theme: null }));
      dispatch(RoomAction.setRoomBackground({ guid: roomGuid, backgroundArtUrl: null }));
    });
  }, [dispatch, roomGuid]);

  return (
    <Transition
      appear={true}
      show={true}
      enter={styles.firstEnter}
      enterFrom={styles.firstEnterFrom}
      enterTo={styles.firstEnterTo}
    >
      <div className={styles.newFTE}>
        <h3>Welcome to Role {currentUser && currentUser.username}</h3>
        <h1>Let's get started on your first room</h1>

        <Combobox value={selectedGame} onChange={addGameToRoom} disabled={!!selectedGame}>
          {({ activeOption, open }) => (
            <>
              <div className={clsx(styles.comboInputWrapper, open && styles.comboInputWrapperOpen)}>
                <Combobox.Input
                  placeholder={selectedGame ? selectedGame.title : 'Search Games'}
                  className={clsx(styles.comboInput, selectedGame && styles.styledComboInput)}
                  onChange={(event) => setGameQuery(event.target.value)}
                />
                {!!selectedGame ? (
                  <CloseButton
                    color={selectedGame ? 'rgb(var(--color-theme-text))' : 'var(--color-white)'}
                    activeColor="var(--color-white)"
                    background="transparent"
                    activeBackground="var(--color-light-dark)"
                    className={styles.clearButton}
                    onClick={onClearGameClick}
                    buttonSize={16}
                  />
                ) : (
                  <Combobox.Button
                    onClick={() => open && setGameQuery('')}
                    className={clsx('button-reset', styles.comboButton, open && styles.comboButtonToggleOpen)}
                  >
                    <DoubleArrow className={styles.comboButtonToggle} />
                  </Combobox.Button>
                )}
              </div>
              {open && (
                <div className={styles.comboOptionsWrapper}>
                  <Combobox.Options className={styles.comboOptions}>
                    {gameQuery === '' && (
                      <Combobox.Option
                        value={null}
                        className={({ active }) => clsx(styles.comboOption, active && styles.comboOptionActive)}
                      >
                        <GameItem
                          defaultGame={defaultGame}
                          gameQuery={gameQuery}
                          setSelectedGame={setSelectedGame}
                          addGameToRoom={addGameToRoom}
                        />
                      </Combobox.Option>
                    )}
                    {queryFilteredGames.map((game) => (
                      <Combobox.Option key={game.id} value={game} className={styles.comboOption}>
                        <GameItem
                          defaultGame={defaultGame}
                          game={game}
                          gameQuery={gameQuery}
                          isActive={activeOption?.id === game.id}
                          setSelectedGame={setSelectedGame}
                          addGameToRoom={addGameToRoom}
                        />
                      </Combobox.Option>
                    ))}
                    {gameQuery !== '' && (
                      <Combobox.Option
                        value={null}
                        className={({ active }) => clsx(styles.comboOption, active && styles.comboOptionActive)}
                      >
                        <GameItem
                          defaultGame={defaultGame}
                          gameQuery={gameQuery}
                          setSelectedGame={setSelectedGame}
                          addGameToRoom={addGameToRoom}
                        />
                      </Combobox.Option>
                    )}
                  </Combobox.Options>
                </div>
              )}
              {!open && !selectedGame && (
                <Button
                  icon={<ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />}
                  isSimpleIcon
                  iconSize="10"
                  variant="cancel"
                  className={styles.skipRoomButton}
                  onClick={skipRoomFte}
                  color="var(--color-primary-main)"
                  hoverColor="var(--color-white)"
                  hoverPrimaryBackground="var(--color-primary-main)"
                >
                  Skip this, launch a blank room
                </Button>
              )}
            </>
          )}
        </Combobox>

        {selectedGame && (
          <div className={styles.selectedGame}>
            <h3>Great Pick!</h3>
            <div className={styles.selectedGameWrapper}>
              <GameItem
                defaultGame={defaultGame}
                game={selectedGame}
                gameQuery={gameQuery}
                showPlay={false}
                setSelectedGame={setSelectedGame}
                addGameToRoom={addGameToRoom}
              />
            </div>
            <h1>What do you want to call this room?</h1>
            <UpdateRoomForm
              buttonColors={
                !isEmpty(selectedGame.colors)
                  ? [selectedGame.colors[0], selectedGame.colors[1]]
                  : [defaultGame.colors[0], defaultGame.colors[1]]
              }
              gameId={selectedGame?.id}
              submitCallback={() => onUpdateRoom(selectedGame)}
              roomGuid={roomGuid}
              roomTitle={`My First ${selectedGame?.title ?? ''} Room`}
            />
          </div>
        )}
      </div>
    </Transition>
  );
}

function GameItem({
  defaultGame,
  game,
  gameQuery,
  showPlay = true,
  isActive = false,
  setSelectedGame,
  addGameToRoom,
}: {
  defaultGame: any;
  game?: any;
  gameQuery: string;
  showPlay?: boolean;
  isActive?: boolean;
  setSelectedGame: (game: any) => void;
  addGameToRoom: (game: any) => void;
}) {
  const dispatch = useDispatch();
  const allGames = useSelector(GameSelector.getAll);
  const allOrganizations = useSelector(OrganizationSelector.getAll);
  const allFreeProducts = useSelector(
    (state) =>
      allGames &&
      ProductSelector.getByGuids(
        state,
        // @ts-ignore
        Object.values(allGames).map((g) => g.defaultFreeProductGuid)
      )
  );

  const freeProduct = game && allFreeProducts.find((p) => p.gameId === game.id);
  const { theme, title, coverArtUrl, isDefaultGame } = game || defaultGame;
  const orgTitle = !isDefaultGame ? allOrganizations.find((x) => x.id === game.organizationId)?.title : null;

  useEffect(() => {
    if (freeProduct && !freeProduct.itemsList) {
      dispatch(ProductAction.fetch(freeProduct.guid));
    }
  }, [dispatch, freeProduct]);

  if (!freeProduct && !isDefaultGame) return null;

  return (
    <div
      className={clsx(styles.gameDetailCard, isActive && styles.gameActive, isDefaultGame && styles.defaultGameCard)}
      style={!isEmpty(theme) ? stylizeTheme(theme) : {}}
    >
      <div className={styles.coverArt}>
        <img src={coverArtUrl || defaultGame.coverArtUrl} alt={title} />
      </div>
      <div className={clsx(styles.gameMeta, isDefaultGame && styles.defaultGameMeta)}>
        <h2>{title}</h2>
        {orgTitle && <span>{orgTitle}</span>}

        {showPlay && (
          <Button
            primaryBackground={!isEmpty(theme) ? theme.accent : defaultGame.theme.accent}
            className={styles.playButton}
            onClick={() => {
              isDefaultGame ? setSelectedGame(defaultGame) : addGameToRoom(game);
            }}
          >
            Play
          </Button>
        )}
      </div>

      {isDefaultGame ? (
        <div className={styles.defaultGameCopy}>
          <p>Start fresh! Create a free custom {gameQuery} room and upload your own PDFs, assets, and more.</p>
        </div>
      ) : (
        <div className={styles.previewGameBundle}>
          <h4 className={styles.productCardTitle}>Game Bundle includes</h4>
          <ul className={clsx('unstyled', styles.productContentList)}>
            {freeProduct.itemsList?.platform &&
              Object.entries<string>(freeProduct.itemsList.platform).map(([item, label], i) => (
                <li className={styles.productItem} key={i}>
                  {getBundleItemIcon(item as BundleItem)}
                  {item === 'app' ? label.replace(/App/g, ' Web App') : label}
                </li>
              ))}

            {freeProduct.itemsList?.download &&
              Object.entries(freeProduct.itemsList.download).map(([item, label], i) => (
                <li className={styles.productItem} key={i}>
                  <>
                    {getBundleItemIcon(item as BundleItem)}
                    {label}
                  </>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}
