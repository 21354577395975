import styles from './OrderHistoryPage.module.css';
import dashboardStyles from 'pages/dashboard/dashboard.module.css';

import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OrderAction from 'store/actions/OrderAction';
import { GameSelector, ProductSelector } from 'store/selectors';
import { getDateAndTime } from 'utilities';
import { formatCents } from 'utilities/numbers';

import Button from 'components/buttons/Button';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import Panel from 'components/Panel';
import TitlePanel from 'components/TitlePanel';

export default function OrderHistoryPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      OrderAction.fetchAll(({ orders }) => {
        setOrders(orders);
        setIsLoading(false);
      })
    );
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Metatags title="Order History" />

      <div className={styles.content}>
        <TitlePanel title="Order History">
          {isLoading && <LoadingSpinner color="var(--color-dark-text)" />}
          {!isLoading && orders.length === 0 && (
            <div className={dashboardStyles.empty}>
              <h4 className={dashboardStyles.emptyHeader}>You have not purchased any games yet.</h4>
              <p className={dashboardStyles.emptyBody}>
                Browse our available games to find your favorites or discover new games to explore!
              </p>
              <Button
                variant="primary"
                className={clsx(styles.emptyCta, dashboardStyles.emptyCta)}
                to="/games"
                isLinkAsButton
              >
                Explore Games
              </Button>
            </div>
          )}
          {!isLoading && orders.length > 0 && orders.map((order) => <Order key={order.guid} {...order} />)}
        </TitlePanel>
      </div>
    </DashboardLayout>
  );
}

function Order({ guid, createdAt, amountTotal, originalTotal, productIds }) {
  const products = useSelector((state) => ProductSelector.getByIds(state, productIds));
  const product = products[0];
  const game = useSelector((state) => GameSelector.get(state, product?.gameId));
  const coverArtUrl = product?.coverArtUrl || game?.coverArtUrl;
  const bundles = products.filter((o) => o.isProductBundle);
  const bundledProductIds = products
    .filter((o) => !bundles.map((b) => b.id).includes(o.id) && bundles.flatMap((b) => b.productIds).includes(o.id))
    .map((o) => o.id);

  return (
    <Panel className={styles.panel}>
      <div className={styles.order}>
        <div className={styles.body}>
          <div className={styles.coverArt}>
            {coverArtUrl ? (
              <img src={coverArtUrl} alt={`${product?.title} Cover Art`} />
            ) : (
              <div className={styles.coverPlaceholder} />
            )}
          </div>

          <ul className={styles.productList}>
            {products.map((product) => (
              <Product key={product.guid} product={product} bundledProductIds={bundledProductIds} />
            ))}
          </ul>
        </div>
        <div className={styles.orderInfo}>
          <p className={styles.orderNumber}>Order #{guid}</p>
          <p className={styles.date}>{getDateAndTime(new Date(createdAt))}</p>
        </div>
        <p className={styles.amountTotal}>
          {originalTotal !== amountTotal && <span className={styles.originalTotal}>{formatCents(originalTotal)}</span>}{' '}
          {formatCents(amountTotal)}
        </p>
      </div>
    </Panel>
  );
}

function Product({ product, bundledProductIds }) {
  const game = useSelector((state) => GameSelector.get(state, product.gameId));
  const title = useMemo(
    () =>
      [game?.defaultPaidProductGuid, game?.defaultFreeProductGuid].includes(product.guid) ? game?.title : product.title,
    [game, product]
  );

  return <li>{bundledProductIds.includes(product.id) ? title : `${title} - ${formatCents(product.price ?? 0)}`}</li>;
}
