import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RoomUserAction } from 'store/actions';
import { RoomUserSelector, SessionSelector } from 'store/selectors';

import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/solid';
import IconButton from 'components/buttons/IconButton';

interface LobbyMusicProps {
  roomGuid?: string;
  url?: string;
}

export default function LobbyMusic({ roomGuid, url }: LobbyMusicProps) {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const userSettings = useSelector((state) =>
    // @ts-ignore RoomUserSelector is not typed yet
    roomGuid && currentUser ? RoomUserSelector.getSettings(state, roomGuid, currentUser.id) : {}
  );
  const audioRef = useRef<HTMLAudioElement>();
  const [muted, setMuted] = useState<boolean>(userSettings.lobbyMusicMuted ?? false);

  const onClickMute = useCallback(() => {
    setMuted((muted) => {
      const newValue = !muted;
      if (roomGuid && currentUser)
        dispatch(RoomUserAction.updateSettings(roomGuid, currentUser.id, { lobbyMusicMuted: newValue }));
      return newValue;
    });
  }, [currentUser, dispatch, roomGuid]);

  useEffect(() => {
    if (!url) return;
    const audio = document.createElement('audio');
    audio.loop = true;
    audio.volume = 0.0625; // 0.5^4
    audio.src = url;

    const clickToPlay = () => {
      audio.play();
    };

    const onCanPlay = () => {
      audio.play().catch((e) => {
        window.addEventListener('click', clickToPlay, { once: true });
      });
    };

    audio.addEventListener('canplay', onCanPlay, { once: true });
    window.addEventListener('touchstart', clickToPlay, { once: true });

    audioRef.current = audio;
    audioRef.current.muted = muted;
    return () => {
      audio.removeEventListener('canplay', onCanPlay);
      window.removeEventListener('click', clickToPlay);
      window.removeEventListener('touchstart', clickToPlay);
      audio.pause();
      audio.remove();
      audioRef.current = undefined;
    };
    // We don't care about "muted" changing for this useEffect.
    // This allows someone who has previously muted lobby music to have it stay  muted if a new audio file is added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (audioRef.current) audioRef.current.muted = muted;
  }, [muted]);

  if (!url) return null;
  return (
    <IconButton
      iconSize={24}
      background="rgb(var(--color-theme-button))"
      hoverBackground="rgb(var(--color-theme-accent))"
      activeBackground="rgb(var(--color-theme-accent))"
      color="rgb(var(--color-theme-text))"
      borderRadius={24}
      onClick={onClickMute}
      isActive={muted}
      children={muted ? <SpeakerXMarkIcon /> : <SpeakerWaveIcon />}
    />
  );
}
