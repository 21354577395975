export const PERMISSIONS = {
  everyone: 'everyone',
  host: 'host',
};

export const VIEWER_OPTIONS = [
  { value: '', label: 'Only You', description: 'Only you can view this sheet' },
  { value: 'host', label: 'You + Host', description: 'You and the room Host can view this sheet' },
  { value: 'everyone', label: 'Everyone', description: 'Everyone in this room can view this sheet' },
];

export const EDITOR_OPTIONS = [
  { value: '', label: 'Only You', description: 'Only you can edit this sheet' },
  { value: 'host', label: 'You + Host', description: 'You and the room Host can edit this sheet' },
  { value: 'everyone', label: 'Everyone', description: 'Everyone in this room can edit this sheet' },
];
