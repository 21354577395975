import { LocalTrackPublication, RemoteAudioTrack, RemoteTrackPublication, Track } from 'twilio-video';

import useTrack from 'video/hooks/useTrack';

import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';
import { isSharedAudioTrack } from 'video/utils';

interface PublicationProps {
  publication?: LocalTrackPublication | RemoteTrackPublication;
  isLocalParticipant?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function Publication({ publication, isLocalParticipant, videoPriority }: PublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'audio':
      return isLocalParticipant && !isSharedAudioTrack(track) ? null : (
        <AudioTrack track={track as RemoteAudioTrack} isLocalParticipant={isLocalParticipant} />
      );
    case 'video':
      return <VideoTrack track={track} priority={videoPriority} isLocal={isLocalParticipant} />;
    default:
      return null;
  }
}
