// @flow
import styles from './Text.module.css';

import React, { memo } from 'react';
import type { Node } from 'react';

import { ELEMENT_ALIGNMENT, INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';
import useToggleLinks from 'hooks/useToggleLinks';

import Element from 'components/Sheet2/elements/Element';
import Toggle from 'components/Sheet2/atoms/Toggle';
import Text from './Text';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function TextElement(props: Props): Node {
  const { className, colors, element, onChange, readOnly, sectionId, sheetGuid } = props;
  const { items, links, metadata } = element;

  const headingInput = items.find((o) => o.inputType === INPUT_TYPE.heading);
  const paragraphInput = items.find((o) => o.inputType === INPUT_TYPE.paragraph);
  const toggleInput = items.find((o) => o.inputType === INPUT_TYPE.toggle);

  const heading: string = headingInput?.value || headingInput?.defaultValue;
  const paragraph: string = paragraphInput?.value || paragraphInput?.defaultValue;
  const alignment: string = metadata.alignment || ELEMENT_ALIGNMENT.left;

  const classNames = [styles.content];
  if (className) classNames.push(className);
  if (heading && heading !== '') classNames.push(styles.hasHeading);
  if (toggleInput) classNames.push(styles.hasToggle);

  useToggleLinks(sheetGuid, toggleInput, links);

  const onToggleChange = (value) => {
    if (!toggleInput) return;
    const updatedItems = element.items.map((o) => (o.id === toggleInput.id ? { ...toggleInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  return (
    <Element {...props}>
      <div className={classNames.join(' ')}>
        {toggleInput && (
          <Toggle
            className={styles.toggle}
            value={toggleInput.value}
            canEdit={toggleInput.canEdit}
            readOnly={readOnly}
            primaryColor={colors[0]}
            secondaryColor={colors[1]}
            onChange={onToggleChange}
          />
        )}
        <Text heading={heading} paragraph={paragraph} alignment={alignment} hasToggle={!!toggleInput} />
      </div>
    </Element>
  );
}

export default memo(TextElement);
