import { mapKeys, merge } from 'lodash';

import { OrganizationAction } from 'store/actions';

const INITIAL_STATE = {};

export default function organizationReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case OrganizationAction.FETCH:
      return { ...state, [payload.slug]: payload };

    case OrganizationAction.FETCH_ALL:
      return merge({}, state, mapKeys(payload, 'slug'));

    default:
      return state;
  }
}
