import React, { Component } from 'react';
import { connect } from 'react-redux';

import { INPUT_TYPE } from 'constants/sheet';
import { findElementPath } from 'utilities/sheet';
import SheetSelector from 'store/selectors/SheetSelector';

class DiceModifier extends Component {
  render() {
    const { sheet, modifier } = this.props;
    const { inputId, value } = modifier;
    const { section, element, groupElement } = findElementPath(sheet.data.sections, inputId);
    const groupElementLabel = groupElement?.items.find((o) => o.inputType === INPUT_TYPE.heading)?.defaultValue;
    const elementLabel = element?.items.find((o) => o.inputType === INPUT_TYPE.heading)?.defaultValue;
    const sectionLabel = section?.title;
    const title = groupElementLabel ?? elementLabel ?? sectionLabel ?? 'Sheet';

    return (
      <div>
        {title} {value > 0 ? `+${value}` : value}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { modifier } = props;
  return {
    sheet: SheetSelector.get(state, modifier.sheetId),
  };
};

export default connect(mapStateToProps)(DiceModifier);
