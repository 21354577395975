import styles from './TableChat.module.css'

import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

class MarkdownBody extends Component {
  renderMarkdownHeading = props => {
    const { children } = props
    return <h4 className={`heading2 ${styles.mdHeading}`}>{children}</h4>
  }

  renderMarkdownLink = props => {
    const { children, href } = props

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  render() {
    const { children } = this.props

    return (
      <ReactMarkdown
        remarkPlugins={[gfm]}
        components={{
          h1: this.renderMarkdownHeading,
          h2: this.renderMarkdownHeading,
          h3: this.renderMarkdownHeading,
          h4: this.renderMarkdownHeading,
          h5: this.renderMarkdownHeading,
          h6: this.renderMarkdownHeading,
          a: this.renderMarkdownLink,
        }}
        children={children}
        className={styles.mdBody}
      />
    )
  }
}

export default MarkdownBody
