// @flow
import styles from './UserMenu2.module.css';

import { Popover, Portal } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, Node } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RAINBOW_CSS } from 'models/Color';
import { GeneralAction, SessionAction, UserAction } from 'store/actions';
import { GeneralSelector, OrganizationSelector, SessionSelector } from 'store/selectors';
import { stylizeTheme } from 'utilities/color';

import Avatar from 'components/Avatar';
import MenuList from 'components/MenuList';

function UserMenu2({ theme, className }): Node {
  const dispatch = useDispatch();
  const { guid } = useParams();
  const currentUser = useSelector(SessionSelector.currentUser);
  const isActive = useSelector(GeneralSelector.isShowingUserMenu);
  const orgSlug = useSelector(OrganizationSelector.getAllByCurrentUser)[0]?.slug;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const crRoomGuids = process.env.REACT_APP_CR_ROOMS;

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const isPatron = currentUser && currentUser.isPatron;
  const avatarUrl = currentUser && currentUser.avatarUrl;

  const onClick = useCallback(() => {
    batch(() => {
      dispatch(GeneralAction.showUserMenu(!isActive));
      dispatch(GeneralAction.showTableMenu(false));
    });
  }, [dispatch, isActive]);

  const onSafetyGuideClick = useCallback(() => dispatch(GeneralAction.showPlayerSafety(true)), [dispatch]);

  const onSignOutClick = useCallback(() => dispatch(SessionAction.signOut()), [dispatch]);

  const menuItems = useMemo(() => {
    const crRoom = crRoomGuids && guid && crRoomGuids.split(',').includes(guid);
    const showAdminOptions = currentUser?.isAdmin || crRoom;
    const onResetFteMultiStepClick = () => {
      if (!currentUser) return;
      const payload = {
        user: {
          metadata: { ...currentUser.metadata, fte: { ...currentUser.metadata.fte, showMultiStep: true, step: 0 } },
        },
      };
      dispatch(UserAction.updateJson(currentUser.id, payload));
    };

    const onResetFteRoomCreateClick = () => {
      if (!currentUser) return;
      const payload = {
        user: { metadata: { ...currentUser.metadata, fte: { ...currentUser.metadata.fte, showRoomCreate: true } } },
      };
      dispatch(UserAction.updateJson(currentUser.id, payload));
    };

    const items = [
      { label: 'Dashboard', to: '/dashboard' },
      { label: 'Patron', to: '/patron' },
      { label: 'Safety Guide', onClick: onSafetyGuideClick },
      { label: 'FAQ', url: 'https://playrole.notion.site/Role-Guide-FAQ-658f294715ed4fdc9380c9e67163eaf7' },
      { label: 'Report Bug', url: 'mailto:support@playrole.com?subject=Early%20Access%20Bug' },
    ];
    if (currentUser) {
      items.splice(1, 0, { label: 'Profile Settings', to: '/settings' });
      items.splice(3, 0, { label: 'Orders', to: '/orders' });
    }
    if (currentUser?.isHostAffiliate)
      items.splice(1, 0, { label: 'Affiliate Dashboard', to: `/affiliates/${currentUser.username}` });
    if (orgSlug) items.splice(1, 0, { label: 'Partner Dashboard', to: `/partners/${orgSlug}` });
    if (showAdminOptions) {
      items.splice(4, 0, { label: 'Reset Tour', onClick: onResetFteMultiStepClick });
      items.splice(4, 0, { label: 'Reset RoomFTE', onClick: onResetFteRoomCreateClick });
    }
    if (currentUser) items.push({ label: 'Sign Out', onClick: onSignOutClick });
    return items;
  }, [crRoomGuids, currentUser, dispatch, guid, onSafetyGuideClick, onSignOutClick, orgSlug]);

  return (
    <Popover as={Fragment}>
      <div
        className={clsx(styles.content, className, isActive && styles.isActive)}
        onClick={onClick}
        ref={setReferenceElement}
      >
        <div className={styles.message}>
          <div>Hello,</div>
          <div className={styles.username}>{currentUser?.username ?? 'You'}</div>
        </div>
        <Avatar
          className={styles.avatar}
          avatarUrl={avatarUrl}
          avatarAlt="You"
          size={54}
          borderColors={isPatron ? RAINBOW_CSS : ['transparent', 'transparent']}
          isConic
          showPlayerDefault
          shape={isActive ? 'squircle' : 'circle'}
        />
      </div>

      {isActive && (
        <Portal>
          <Popover.Panel
            static
            className={styles.popper}
            ref={setPopperElement}
            style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
            {...attributes.popper}
          >
            <MenuList items={menuItems} isActive={isActive} className={styles.userMenu} variant="theme" />
          </Popover.Panel>
        </Portal>
      )}
    </Popover>
  );
}

export default UserMenu2;
