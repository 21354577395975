import styles from './modals.dashboard.module.css';

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { SheetTemplateAction } from 'store/actions';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

function CreateTemplateModal({ handleSubmit, submitFailed }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const onCreateModalDismiss = () => history.push('/sheet-templates');
  const [isCreating, setIsCreating] = useState(false);

  const onCreateSubmit = (formValues) => {
    const { title } = formValues;
    if (!title || title.trim() === '') throw new SubmissionError({ title: 'Required' });
    setIsCreating(true);
    dispatch(
      SheetTemplateAction.create({ sheet_template: { name: title } }, (template) => {
        logEvent('template - created');
        history.push(`/sheet-templates/${template.guidSlug}`);
      })
    );
  };

  const renderCreateInput = useCallback(
    ({ input, type, label, placeholder, meta }) => {
      return (
        <label className={styles.modalInputContainer}>
          {label}
          <TextInput input={input} meta={meta} type={type} placeholder={placeholder} showError={submitFailed} />
        </label>
      );
    },
    [submitFailed]
  );

  return (
    <TitleModal
      title="Create a new Template"
      subtitle="Create a new template for use in your rooms. You can even share it with others!"
      actions={
        <>
          <Button variant="cancel" onClick={onCreateModalDismiss}>
            Cancel
          </Button>
          <Button variant="primary" isDisabled={isCreating} onClick={handleSubmit(onCreateSubmit)}>
            Create
          </Button>
        </>
      }
      onDismiss={onCreateModalDismiss}
    >
      <form onSubmit={handleSubmit(onCreateSubmit)}>
        <Field
          component={renderCreateInput}
          name="title"
          type="text"
          label={
            <span className={styles.modalLabel}>
              <strong>Template Name:</strong>
            </span>
          }
          placeholder="Give your Template a name..."
        />
      </form>
    </TitleModal>
  );
}

export default compose(reduxForm({ form: 'createSheetTemplate' }))(CreateTemplateModal);
