// @flow
import styles from './DicePool.module.css';

import { Portal } from '@headlessui/react';
import clsx from 'clsx';
import type { Node } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import useOutsideClick from 'hooks/useOutsideClick';
import { DICE_COLOR_LABELS } from 'models/Color';
import Die, { DICE } from 'models/Die';

import Button from 'components/buttons/Button';
import { DieIcon } from 'components/DiceRoller';
import EditIcon from 'components/Sheet2/atoms/EditIcon';
import GradientHexagon from 'components/Sheet2/atoms/GradientHexagon';
import LockedIcon from 'components/Sheet2/atoms/LockedIcon';
import Spacer from 'components/Sheet2/atoms/Spacer';
import DiceColorPicker from 'components/TableBar/DiceColorPicker';
import { ReactComponent as BuffIcon } from 'images/icons/BuffIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';

type Props = {
  background: string,
  canEditCount: boolean,
  canEditDie: boolean,
  canEditColor?: boolean,
  customDice?: any[],
  className?: ?string,
  diceCount: string,
  diceSides: number,
  diceColor?: string,
  diceType: string,
  id?: string,
  isBuilding: boolean,
  isSelected: boolean,
  onClick?: (boolean) => void,
  onCountBlur?: ?() => void,
  onCountChange: (string) => void,
  onCountFocus?: ?() => void,
  onDieChange: (number, string) => void,
  onColorChange: (string) => void,
  onAddClick?: () => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
};

const REGEX = /^\d*$/;

function DicePool(props: Props): Node {
  const {
    background,
    canEditCount,
    canEditDie,
    canEditColor,
    customDice,
    className,
    diceCount,
    diceSides,
    diceType,
    diceColor,
    isBuilding,
    id,
    isSelected,
    onClick: propOnClick,
    onCountBlur: propOnCountBlur,
    onCountChange: propOnCountChange,
    onCountFocus,
    onDieChange,
    onColorChange,
    onAddClick: propOnAddClick,
    primaryColor,
    readOnly,
    secondaryColor,
  } = props;

  const countInputRef = useRef(null);
  const hoverTimerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(isBuilding);
  const [isSelectingDice, setIsSelectingDice] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });

  const currentDie = useMemo(() => {
    return (
      DICE.find((o) => o.sides === diceSides && o.type === diceType) ??
      customDice?.find((o) => o.sides === diceSides && o.type === diceType) ??
      new Die({ sides: diceSides, type: diceType })
    );
  }, [customDice, diceSides, diceType]);

  useEffect(() => {
    return () => clearTimeout(hoverTimerRef.current);
  }, []);

  useEffect(() => {
    if (countInputRef.current && isEditing && !isBuilding) {
      countInputRef.current.focus();
      countInputRef.current.setSelectionRange(0, countInputRef.current.value.length);
    }
  }, [isBuilding, isEditing]);

  const onClick = (event) => {
    if (!isEditing && propOnClick) propOnClick(!isSelected);
  };

  const onCountBlur = (event) => {
    if (event.currentTarget.value.match(REGEX)) propOnCountChange(event.currentTarget.value || '0');
    if (propOnCountBlur) propOnCountBlur();
  };

  const onCountChange = (event) => {
    if (!event.currentTarget.value.match(REGEX)) return;
    propOnCountChange(event.currentTarget.value);
  };

  const onCountKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (!isBuilding) setIsEditing(false);
      countInputRef.current.blur();
    }
  };

  const onDieClick = (event, die) => {
    if ((canEditDie || isBuilding) && isEditing && isSelectingDice) {
      onDieChange(die.sides, die.type);
      setIsSelectingDice(false);
    } else onClick(event);
  };

  const onEditClick = () => {
    if (isEditing && isSelectingDice) setIsSelectingDice(false);
    setIsEditing((prev) => !prev);
  };

  const onMouseEnter = () => {
    clearTimeout(hoverTimerRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    hoverTimerRef.current = setTimeout(removeHover, 500);
  };

  const onSelectDiceClick = (event) => {
    event?.stopPropagation?.();
    if (isEditing && (canEditColor || canEditDie || isBuilding) && !isSelectingDice) setIsSelectingDice(true);
  };

  const onAddClick = () => {
    setIsSelectingDice(false);
    propOnAddClick?.();
  };

  const removeHover = () => setIsHovering(false);

  useOutsideClick(popperElement, () => {
    if (isSelectingDice) setIsSelectingDice(false);
  });

  return (
    <div
      className={clsx(
        styles.container,
        className,
        isEditing && styles.isEditing,
        isHovering && styles.isHovering,
        isSelected && styles.isSelected,
        (canEditDie || isBuilding) && isEditing && isSelectingDice && styles.isEditingDie
      )}
    >
      <Spacer type="pool" className={styles.spacer} primaryColor={primaryColor} secondaryColor={secondaryColor} />
      <div className={styles.dice} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <GradientHexagon
          className={styles.diceGradient}
          icon={BuffIcon}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <GradientHexagon
          className={styles.diceBackground}
          icon={BuffIcon}
          primaryColor={background}
          secondaryColor={background}
        />
        <div ref={setReferenceElement} className={styles.diceContent} onClick={onSelectDiceClick}>
          <button
            aria-label={`Add ${diceCount} ${diceColor ? DICE_COLOR_LABELS[diceColor] + ' ' : ''}${
              currentDie.name
            } to roll`}
            className={clsx('button-reset', styles.dieButton, styles.isSelected)}
            onClick={(e) => onDieClick(e, currentDie)}
            style={{ '--die-color': diceColor || undefined }}
          >
            <DieIcon die={currentDie} className={styles.dieIcon} />
            <span className={styles.dieLabel}>{currentDie.name}</span>
          </button>
        </div>
        {isSelectingDice && (
          <Portal>
            <div
              {...attributes.popper}
              ref={setPopperElement}
              className={clsx(styles.diceSelector, isBuilding && styles.isBuilding)}
              style={{ ...popperStyles.popper }}
            >
              {(canEditColor || isBuilding) && (
                <DiceColorPicker
                  id={id}
                  defaultValue={diceColor}
                  onChange={onColorChange}
                  className={styles.diceColorPicker}
                />
              )}
              {DICE.map((die) => (
                <button
                  key={die.name}
                  className={clsx('button-reset', styles.dieOptionButton, !canEditDie && styles.notEditable)}
                  onClick={(e) => onDieClick(e, die)}
                  style={{ '--die-color': diceColor || undefined }}
                >
                  <DieIcon die={die} className={styles.dieIcon} />
                  <span className={styles.dieLabel}>{die.name}</span>
                </button>
              ))}
              {customDice?.length > 0 && (
                <>
                  <hr />
                  {customDice?.map((die) => (
                    <button
                      key={die.name}
                      className={clsx('button-reset', styles.dieOptionButton, !canEditDie && styles.notEditable)}
                      onClick={(e) => onDieClick(e, die)}
                      style={{ '--die-color': diceColor || undefined }}
                    >
                      <DieIcon die={die} className={styles.dieIcon} />
                      <span className={styles.dieLabel}>{die.name}</span>
                    </button>
                  ))}
                </>
              )}
              {isBuilding && propOnAddClick && (
                <Button
                  className={styles.addButton}
                  primaryBackground="rgb(var(--color-theme-accent))"
                  color="var(--color-white)"
                  icon={<PlusIcon />}
                  onClick={onAddClick}
                >
                  Add Custom
                </Button>
              )}
            </div>
          </Portal>
        )}
        {!readOnly && (canEditCount || canEditDie || canEditColor) && !isBuilding && (
          <EditIcon
            className={styles.editButton}
            isEditing={isEditing}
            onClick={onEditClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
        {!(canEditDie || canEditColor) && <LockedIcon className={styles.lockedDieIcon} />}
      </div>
      <div className={styles.count} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className={styles.countBackground} />
        <div className={styles.countContent}>
          <button
            className={`button-reset ${styles.countButton} ${!canEditCount ? styles.isLocked : ''}`}
            onClick={onClick}
          >
            {!canEditCount && <LockedIcon className={styles.lockedCountIcon} />}
            {diceCount}
          </button>
          {((!readOnly && canEditCount) || isBuilding) && isEditing && (
            <input
              ref={countInputRef}
              className={styles.countInput}
              type="text"
              value={diceCount}
              onBlur={onCountBlur}
              onFocus={onCountFocus}
              onKeyDown={onCountKeyDown}
              onChange={onCountChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

DicePool.defaultProps = {
  canEditCount: false,
  canEditDie: false,
  isBuilding: false,
  isSelected: false,
  background: 'var(--color-black)',
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default DicePool;
