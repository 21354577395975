import styles from './inputs.module.css';

import clsx from 'clsx';
import React, { Component } from 'react';

class RadioInput extends Component {
  onClick = () => {
    const { input, allowUnselect } = this.props;
    if (!allowUnselect) return;
    if (input.checked) input.onChange('');
  };

  render() {
    const { input, label, className, disabled } = this.props;
    return (
      <label
        className={clsx(
          styles.radioContainer,
          className,
          input.checked && styles.isChecked,
          disabled && styles.isDisabled
        )}
      >
        <input {...input} onClick={this.onClick} type="radio" className={styles.radio} disabled={disabled} />
        <span className={styles.radioButton} />
        <span className={`heading2 ${styles.radioLabel}`}>{label}</span>
      </label>
    );
  }
}

RadioInput.defaultProps = {
  allowUnselect: false,
};

export default RadioInput;
