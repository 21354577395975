// @flow
import styles from './Text.module.css';

import React from 'react';
import type { Node } from 'react';

import type { ElementAlignment } from 'components/sheet2/types';
import Markdown from 'components/Sheet2/atoms/Markdown';

type Props = {
  alignment: ElementAlignment,
  className?: ?string,
  hasToggle: boolean,
  heading: ?string,
  paragraph: ?string,
};

function Text(props: Props): Node {
  const { alignment, className, hasToggle, heading, paragraph } = props;

  const hasHeading = heading && heading !== '';
  const hasParagraph = paragraph && paragraph !== '';

  const classNames = [styles.container, styles[alignment]];
  if (className) classNames.push(className);
  if (hasToggle) classNames.push(styles.hasToggle);

  return (
    <div className={classNames.join(' ')}>
      {hasHeading && <h4 className={styles.heading}>{heading}</h4>}
      {hasParagraph && <Markdown className={styles.paragraph}>{paragraph}</Markdown>}
    </div>
  );
}

export default Text;
