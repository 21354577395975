import styles from './RemovePlayerModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RoomUserAction, TableAction } from 'store/actions';
import { DocumentSelector, RoomDocumentSelector, RoomSheetSelector, RoomUserSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import Button from 'components/buttons/Button';
import GradientDot from 'components/GradientDot';
import { TitleModal } from 'components/Modal';
import { FILTER } from 'constants/asset';

class RemovePlayerModal extends Component {
  onDismiss = () => {
    this.props.confirmRemoveUser(null);
    this.props.showManageUsers(true);
  };

  onRemoveClick = () => {
    this.props.removeUser(this.props.room.guid, this.props.user.userId);
    this.onDismiss();
  };

  render() {
    const { assetsCount, colors, sheetsCount, tokensCount, user } = this.props;
    const { name, username } = user;

    return (
      <TitleModal
        title="Remove Player From Room?"
        subtitle="This will remove the selected player from the room. Any Sheets, Assets, and Chat Messages will also be removed."
        onDismiss={this.onDismiss}
      >
        <div className={styles.container}>
          <div className={styles.user}>
            <GradientDot colors={colors} className={styles.userDot} />
            <span>
              {username}
              {name && ` / ${name}`}
            </span>
          </div>

          <h4 className="heading3 is-centered">This will remove:</h4>
          <ul className={`unstyled ${styles.assets}`}>
            {sheetsCount > 0 && (
              <li>
                {sheetsCount} {pluralize(sheetsCount, 'Sheet')}
              </li>
            )}
            {assetsCount > 0 && (
              <li>
                {assetsCount} {pluralize(assetsCount, 'Asset')}
              </li>
            )}
            {tokensCount > 0 && (
              <li>
                {tokensCount} {pluralize(tokensCount, 'Token')}
              </li>
            )}
            <li>Their chat messages and dice log entries</li>
          </ul>
        </div>

        <div className={styles.buttons}>
          <Button variant="cancel" onClick={this.onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.onRemoveClick}>
            Remove
          </Button>
        </div>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room, user } = props;
  const { guid } = room;

  return {
    colors: RoomUserSelector.getColors(state, guid, user.id),
    assetsCount: RoomDocumentSelector.getByRoomAndUser(state, guid, user.id).length,
    sheetsCount: RoomSheetSelector.getByRoomAndUser(state, guid, user.id).length,
    tokensCount: RoomDocumentSelector.getByRoomAndUser(state, guid, user.id, FILTER.tokens).length,
  };
};

const mapDispatchToProps = {
  confirmRemoveUser: TableAction.confirmRemoveUser,
  removeUser: RoomUserAction.delete,
  showManageUsers: TableAction.showManageUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemovePlayerModal);
