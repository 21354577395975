import { createAction, handleError } from './utilities';

import role from 'apis/role';

export default class OrganizationAction {
  static FETCH = 'OrganizationAction.FETCH';
  static FETCH_ALL = 'OrganizationAction.FETCH_ALL';

  static fetch =
    (slug, callback = null) =>
    async (dispatch) =>
      role.get(`/organizations/${slug}`).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(OrganizationAction.FETCH, obj));
          callback?.(obj);
        },
        ({ response }) => handleError(dispatch, OrganizationAction.FETCH, response)
      );

  static stripeOnboard = (slug) => role.post(`/organizations/${slug}/onboard`).then((response) => response.data);

  static stripeLogin = (slug) => role.get(`/organizations/${slug}/stripe_login`).then((response) => response.data);

  static shopifySave = (shop) =>
    role.post('/shopify/save', { shopify_store: { shop } }).then((response) => response.data);

  static shopifyLogin = (slug, shop) =>
    role.post('/shopify/login', { slug, shopify_store: { shop } }).then((response) => response.data);

  static shopifyAuth = (data) => role.post('/shopify/auth_callback', data).then((response) => response.data);

  static shopifyWebhook = (data, headers) =>
    role.post('/shopify/auth_callback', data, { headers }).then((response) => response.data);

  static shopifyProducts = (slug) => role.get(`/shopify/${slug}/products`).then((response) => response.data);
}
