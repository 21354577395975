import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import history from './browserHistory';
import { CSS_COLORS } from './models/Color';
import AssetAction from './store/actions/AssetAction';
import GeneralAction from './store/actions/GeneralAction';
import SheetAction from './store/actions/SheetAction';
import SheetTemplateBuilderAction from './store/actions/SheetTemplateBuilderAction';
import TableAction from './store/actions/TableAction';
import AssetSelector from './store/selectors/AssetSelector';
import GeneralSelector from './store/selectors/GeneralSelector';
import SheetSelector from './store/selectors/SheetSelector';
import SheetTemplateBuilderSelector from './store/selectors/SheetTemplateBuilderSelector';
import TableSelector from './store/selectors/TableSelector';
import { nextUrl } from 'utilities';

import AuthRoute from './components/AuthRoute/AuthRoute';
import Metatags from './components/Metatags';
import AffiliatePage from './pages/dashboard/AffiliatePage';
import PartnerPage from './pages/dashboard/PartnerPage';
import SheetsPage from './pages/dashboard/SheetsPage';
import ShopifyCallbackPage from './pages/dashboard/ShopifyCallbackPage';
import TablesPage from './pages/dashboard/TablesPage';
import TemplatesPage from './pages/dashboard/TemplatesPage';
import EditProfilePage from './pages/EditProfilePage';
import ForgotPasswordPage, { PasswordResetPage } from './pages/ForgotPasswordPage';
import NotFoundPage from './pages/NotFoundPage'; // TODO: Update design
import PreviewPage from './pages/PreviewPage';
import SheetBuilderPage from './pages/SheetBuilderPage';
import SheetTemplatePage from './pages/SheetTemplatePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import TablePage from './pages/TablePage';
import PageLoading from 'components/PageLoading';
import PlayerSafetyModal from 'components/PlayerSafetyModal';
import RedeemPage from 'pages/dashboard/RedeemPage';
import OrderHistoryPage from 'pages/OrderHistoryPage';

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const fbPixelId = process.env.REACT_APP_FB_PIXEL_ID;

history.listen((location) => {
  if (googleAnalyticsId) {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }
  requestAnimationFrame(() => document.scrollingElement?.scrollTo({ top: 0 }));
});

class App extends Component {
  componentDidMount() {
    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        document.body.classList.add('is-touching');
        window.IS_TOUCHING = true;
      },
      { once: true }
    );
  }

  onPageClick = () => {
    const {
      isShowingAssetMenu,
      isShowingAudioVideoMenu,
      isShowingRoomSheetsMenu,
      isShowingSheetMenu,
      isShowingTableMenu,
      isShowingTableSheetMenu,
      isShowingTableUserMenu,
      isShowingTemplateSettingsrMenu,
      isShowingUserMenu,
    } = this.props;
    const {
      showAssetMenu,
      showAudioVideoMenu,
      showRoomSheetsMenu,
      showSheetMenu,
      showTableMenu,
      showTableSheetMenu,
      showTableUserMenu,
      showTemplateSettingsMenu,
      showUserMenu,
    } = this.props;

    if (isShowingAssetMenu) showAssetMenu(null);
    if (isShowingAudioVideoMenu) showAudioVideoMenu(false);
    if (isShowingRoomSheetsMenu) showRoomSheetsMenu(false);
    if (isShowingSheetMenu) showSheetMenu(null);
    if (isShowingTableMenu) showTableMenu(false);
    if (isShowingTableSheetMenu) showTableSheetMenu(false);
    if (isShowingTableUserMenu) showTableUserMenu(null);
    if (isShowingTemplateSettingsrMenu) showTemplateSettingsMenu(false);
    if (isShowingUserMenu) showUserMenu(false);
  };

  redirectToNext(path, includePath = true) {
    return (props) => {
      let url = nextUrl(path + (includePath ? props.location.pathname : '') + window.location.search);
      window.location.replace(url);
      return <PageLoading />;
    };
  }

  render() {
    const { isShowingPlayerSafety } = this.props;
    const cssColors =
      Object.entries(CSS_COLORS)
        .map((color) => `${color[0]}:${color[1]}`)
        .join(';') + ';';

    return (
      <div onClick={this.onPageClick} className="page-wrapper">
        <Metatags />
        {fbPixelId && (
          <Helmet
            script={[
              {
                type: 'text/javascript',
                innerHTML: `!function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${fbPixelId}');
                  fbq('track', 'PageView');`,
              },
            ]}
            noscript={[
              {
                innerHTML: `<img
                  height="1"
                  width="1"
                  style="display=none"
                  alt=""
                  src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"
                />`,
              },
            ]}
          />
        )}

        <style
          dangerouslySetInnerHTML={{
            __html: `
          :root {
            ${cssColors}
          }
        `,
          }}
        />

        <Router history={history}>
          <Switch>
            <Redirect from="/" to="/dashboard" exact />

            <Redirect from="/signup/guest" to="/signup" exact />
            <Route path="/signup" exact component={SignUpPage} />
            <Redirect from="/signin" to="/login" exact />
            <Route path="/login" exact component={SignInPage} />
            <Route path="/forgot-password" exact component={ForgotPasswordPage} />
            <Route path="/password-reset" exact component={PasswordResetPage} />

            <Route path="/settings" exact component={EditProfilePage} />

            <AuthRoute path="/redeem" exact>
              <RedeemPage />
            </AuthRoute>

            <Route path="/orders" exact component={OrderHistoryPage} />

            <Route path="/search" exact component={this.redirectToNext('/store', false)} />

            <AuthRoute
              path="/dashboard"
              exact
              authOptional
              onIsAuthed={() => history.replace('/rooms')}
              onIsUnauthed={() => history.replace('/games')}
            />

            <AuthRoute path="/store" exact authOptional>
              {this.redirectToNext('')}
            </AuthRoute>
            <AuthRoute path="/games" exact authOptional>
              {this.redirectToNext('/store', false)}
            </AuthRoute>
            <AuthRoute path="/games/:slug" exact authOptional>
              {this.redirectToNext('/store')}
            </AuthRoute>
            <AuthRoute path="/games/:slug/rooms/new" exact authOptional>
              <PreviewPage />
            </AuthRoute>
            <AuthRoute path="/games/:slug/preview" exact authOptional>
              <PreviewPage />
            </AuthRoute>
            <AuthRoute path="/games/:slug/:guid-:paramTitle" exact authOptional>
              {this.redirectToNext('/store')}
            </AuthRoute>
            <AuthRoute path="/games/:slug/:guid-:paramTitle/rooms/new" exact authOptional>
              <PreviewPage />
            </AuthRoute>
            <AuthRoute path="/products/:guid" exact authOptional>
              {this.redirectToNext('/store')}
            </AuthRoute>
            <AuthRoute path="/publishers/:slug" exact authOptional>
              {this.redirectToNext('/store')}
            </AuthRoute>
            <AuthRoute path="/patron" exact authOptional>
              {this.redirectToNext('/patron', false)}
            </AuthRoute>

            <AuthRoute path="/rooms" exact authOptional>
              {this.redirectToNext('/dashboard')}
            </AuthRoute>
            <Route path="/rooms/new" exact render={(props) => <TablesPage {...props} isCreatingTable />} />
            <Route path="/rooms/:guid" exact component={TablePage} />

            <AuthRoute path="/purchases" exact authOptional>
              {this.redirectToNext('/dashboard/games', false)}
            </AuthRoute>

            <AuthRoute path="/sheets" exact authOptional>
              {this.redirectToNext('/dashboard')}
            </AuthRoute>
            <Route path="/sheets/:sheetId" exact component={SheetsPage} />

            <AuthRoute path="/templates" exact authOptional>
              {this.redirectToNext('/dashboard')}
            </AuthRoute>
            <AuthRoute path="/sheet-templates" exact authOptional>
              {this.redirectToNext('/dashboard/templates', false)}
            </AuthRoute>
            <Route
              path="/sheet-templates/new"
              exact
              render={(props) => <TemplatesPage {...props} isCreatingTemplate />}
            />
            <Route path="/sheet-templates/:guidSlug" exact component={SheetTemplatePage} />
            <Route path="/sheet-templates/:guidSlug/save" exact component={SheetTemplatePage} />
            <Route path="/sheet-templates/:guidSlug/edit" exact component={SheetBuilderPage} />

            <AuthRoute path="/materials" exact authOptional>
              {this.redirectToNext('/dashboard/assets', false)}
            </AuthRoute>
            <AuthRoute path="/assets" exact authOptional>
              {this.redirectToNext('/dashboard')}
            </AuthRoute>
            <AuthRoute path="/assets/new" exact authOptional>
              {this.redirectToNext('/dashboard/assets?upload=1', false)}
            </AuthRoute>

            <AuthRoute path="/affiliates/:username">
              <AffiliatePage />
            </AuthRoute>
            <AuthRoute path="/partners/shopify/callback" authOptional>
              <ShopifyCallbackPage />
            </AuthRoute>
            <Route path="/partners/:slug" exact component={PartnerPage} />
            <AuthRoute path="/partners/:partnerSlug/games/:slug/preview" exact authOptional>
              <PreviewPage forceFetch />
            </AuthRoute>

            <Route
              path="/about"
              exact
              render={() => {
                window.location = 'https://www.playrole.com/about';
                return null;
              }}
            />
            <Route
              path="/changelog"
              exact
              render={() => {
                window.location = 'https://www.playrole.com/changelog';
                return null;
              }}
            />

            <AuthRoute path="/unsubscribe/success" exact authOptional>
              {this.redirectToNext('/unsubscribe/success', false)}
            </AuthRoute>

            <Route component={NotFoundPage} />
          </Switch>
        </Router>

        {isShowingPlayerSafety && <PlayerSafetyModal />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isShowingAssetMenu: !!AssetSelector.menuAssetId(state),
    isShowingAudioVideoMenu: GeneralSelector.isShowingAudioVideoMenu(state),
    isShowingRoomSheetsMenu: TableSelector.isShowingRoomSheetsMenu(state),
    isShowingSheetMenu: !!SheetSelector.menuId(state),
    isShowingTableMenu: GeneralSelector.isShowingTableMenu(state),
    isShowingTableUserMenu: !!TableSelector.getUserMenuId(state),
    isShowingTemplateSettingsrMenu: SheetTemplateBuilderSelector.isShowingSettingsMenu(state),
    isShowingUserMenu: GeneralSelector.isShowingUserMenu(state),
    isShowingPlayerSafety: GeneralSelector.isShowingPlayerSafety(state),
    isShowingTableSheetMenu: TableSelector.isShowingSheetMenu(state),
  };
};

const mapDispatchToProps = {
  showAssetMenu: AssetAction.showMenu,
  showAudioVideoMenu: GeneralAction.showAudioVideoMenu,
  showRoomSheetsMenu: TableAction.showRoomSheetsMenu,
  showSheetMenu: SheetAction.showMenu,
  showTableMenu: GeneralAction.showTableMenu,
  showTableUserMenu: TableAction.showUserMenu,
  showTemplateSettingsMenu: SheetTemplateBuilderAction.showSettingsMenu,
  showUserMenu: GeneralAction.showUserMenu,
  showTableSheetMenu: TableAction.showSheetMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
