import _ from 'lodash';
import { createSelector } from 'reselect';

import GameSelector from './GameSelector';
import SessionSelector from './SessionSelector';

const orderByTitle = (obj) => obj.title.toLowerCase();

export default class ProductSelector {
  static get = createSelector(
    (state, guid) => state.products[guid],
    (product) => product
  );

  static getById = createSelector(
    (state, id) => Object.values(state.products).find((o) => o.id === id),
    (product) => product
  );

  static getByIds = createSelector([(state) => state.products, (state, ids) => ids], (products, ids) =>
    Object.values(products).filter((product) => ids.includes(product.id))
  );

  static getByGuids = createSelector([(state) => state.products, (state, guids) => guids], (products, guids) =>
    _.filter(products, (_, key) => guids.includes(key))
  );

  static getAll = createSelector(
    (state) => state.products,
    (products) => _.orderBy(Object.values(products), [orderByTitle], ['asc'])
  );

  static getByGame = createSelector([(state) => state.products, (state, id) => id], (products, gameId) =>
    _.orderBy(
      Object.values(products).filter((product) => gameId === product.gameId),
      [orderByTitle],
      ['asc']
    )
  );

  static getByOrg = createSelector([(state) => state.products, (state, id) => id], (products, ownerId) =>
    _.orderBy(
      Object.values(products).filter((product) => ownerId === product.ownerId && product.ownerType === 'Organization'),
      [orderByTitle],
      ['asc']
    )
  );

  static getByPublisher = createSelector([this.getByOrg, GameSelector.getByOrg], (products, games) => {
    const filteredProducts = products.filter((product) => {
      const game = games.find(({ id }) => product.gameId === id);
      return (
        !game ||
        (product.price !== null && product.price >= 0) ||
        (product.price === null && game.defaultFreeProductGuid === product.guid && !game.defaultPaidProductGuid)
      );
    });
    return _.orderBy(filteredProducts, [orderByTitle], ['asc']);
  });

  static getDefaultProducts = createSelector(
    [(state) => Object.values(state.games), (state) => Object.values(state.products)],
    (games, products) => {
      const productGuids = games.map((game) => game.defaultPaidProductGuid || game.defaultFreeProductGuid);
      const filteredProducts = products
        .filter((product) => productGuids.includes(product.guid))
        .map((product) => {
          const game = games.filter((g) => g.id === product.gameId)[0];
          return { ...product, gameUpdatedAt: game.updatedAt };
        });
      return _.orderBy(
        filteredProducts,
        [(p) => p.price !== null && p.price !== 0, (p) => p.title.toLowerCase()],
        ['desc', 'asc']
      );
    }
  );

  static getDefaultFreeProducts = createSelector(
    [(state) => Object.values(state.games), (state) => state.products],
    (games, products) => games.map((game) => products[game.defaultFreeProductGuid]).filter(Boolean)
  );

  static getUnlockedDefaultFreeProducts = createSelector(
    [this.getDefaultFreeProducts, SessionSelector.currentUser],
    (defaultFreeProducts, currentUser) =>
      defaultFreeProducts.filter((product) => currentUser.unlockedGameIds.includes(product.gameId))
  );

  static getLockedByGame = createSelector(
    [ProductSelector.getByGame, GameSelector.get, SessionSelector.currentUser],
    (products, game, currentUser) =>
      _.orderBy(
        products.filter(
          (product) => product.price !== null && (!currentUser || !currentUser.unlockedProductIds.includes(product.id))
        ),
        [(p) => [game.defaultFreeProductGuid, game.defaultPaidProductGuid].includes(p.guid)],
        ['desc']
      )
  );
}
