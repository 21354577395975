// @flow
import styles from './UserMenu.module.css';

import type { Node } from 'react';
import React, { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { RAINBOW_CSS } from 'models/Color';
import { GeneralAction, SessionAction } from 'store/actions';
import { GeneralSelector, OrganizationSelector, SessionSelector } from 'store/selectors';

import Avatar from 'components/Avatar';
import MenuList, { MenuListPortal } from 'components/MenuList';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

function UserMenu(): Node {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const isActive = useSelector(GeneralSelector.isShowingUserMenu);
  const orgSlug = useSelector(OrganizationSelector.getAllByCurrentUser)[0]?.slug;

  const avatarUrl = currentUser && currentUser.avatarUrl;

  const onClick = useCallback(() => {
    batch(() => {
      dispatch(GeneralAction.showUserMenu(!isActive));
      dispatch(GeneralAction.showTableMenu(false));
    });
  }, [dispatch, isActive]);

  const onSafetyGuideClick = useCallback(() => dispatch(GeneralAction.showPlayerSafety(true)), [dispatch]);

  const onSignOutClick = useCallback(() => dispatch(SessionAction.signOut()), [dispatch]);

  const menuItems = useMemo(() => {
    const items = [
      { label: 'Dashboard', to: '/dashboard' },
      { label: 'Profile Settings', to: '/settings' },
      { label: 'Patron', to: '/patron' },
      { label: 'Orders', to: '/orders' },
      { label: 'Redeem', to: '/redeem' },
      { label: 'Safety Guide', onClick: onSafetyGuideClick },
      { label: 'FAQ', url: 'https://playrole.notion.site/Role-Guide-FAQ-658f294715ed4fdc9380c9e67163eaf7' },
      { label: 'Report Bug', url: 'mailto:support@playrole.com?subject=Early%20Access%20Bug' },
      { label: 'Sign Out', onClick: onSignOutClick },
    ];
    if (currentUser?.isHostAffiliate)
      items.splice(1, 0, { label: 'Affiliate Dashboard', to: `/affiliates/${currentUser.username}` });
    if (orgSlug) items.splice(1, 0, { label: 'Partner Dashboard', to: `/partners/${orgSlug}` });
    return items;
  }, [currentUser?.isHostAffiliate, currentUser?.username, onSafetyGuideClick, onSignOutClick, orgSlug]);

  return (
    <>
      <div className={styles.content} onClick={onClick}>
        <Avatar
          className={styles.avatar}
          avatarUrl={avatarUrl}
          avatarAlt="You"
          size={54}
          borderColors={RAINBOW_CSS}
          isConic
          showPlayerDefault
        />
        <div className={styles.arrow}>
          <SingleArrowIcon className={styles.arrowIcon} />
        </div>
      </div>
      <MenuListPortal right={12} top={100}>
        <MenuList items={menuItems} isActive={isActive} />
      </MenuListPortal>
    </>
  );
}

export default UserMenu;
