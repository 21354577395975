// @flow
import styles from './IconButton.module.css';

import clsx from 'clsx';
import type { CSSProperties, MouseEvent, ReactNode } from 'react';
import { forwardRef } from 'react';
import React from 'react';

export type IconButtonProps = {
  activeBackground?: string;
  activeChildren?: ReactNode;
  activeColor?: string;
  activeHover?: string;
  activeLabel?: string;
  activeSize?: number;
  background: string;
  borderRadius?: number;
  buttonRef?: any;
  buttonSize?: number;
  children?: ReactNode;
  className?: string;
  color: string;
  hoverBackground?: string;
  iconSize?: number;
  isActive?: boolean;
  isDisabled?: boolean;
  label?: string;
  onClick?(event: MouseEvent): void;
  showAsTooltip?: boolean;
  showLabelOnHover?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(props: IconButtonProps, ref) {
  const {
    activeBackground,
    activeChildren,
    activeColor,
    activeHover,
    activeLabel,
    activeSize,
    background,
    borderRadius,
    buttonRef,
    buttonSize,
    children,
    className,
    color,
    iconSize,
    isActive,
    isDisabled,
    hoverBackground,
    label,
    onClick,
    showAsTooltip,
    showLabelOnHover,
    type,
  } = props;

  const style: CSSProperties = {};
  if (borderRadius) style['--iconbutton-border-radius'] = `${borderRadius}px`;
  if (buttonSize) style['--iconbutton-button-size'] = `${buttonSize}px`;
  if (iconSize) style['--iconbutton-icon-size'] = `${iconSize}px`;
  if (activeBackground) {
    style['--iconbutton-active-background'] = activeBackground;
    if (activeHover) style['--iconbutton-active-hover-background'] = activeHover;
  }
  if (hoverBackground) style['--iconbutton-hover-background'] = hoverBackground;
  if (activeColor) style['--iconbutton-active-color'] = activeColor;
  if (activeSize) style['--iconbutton-active-size'] = `${activeSize}px`;
  if (background) style['--iconbutton-background'] = background;
  if (color) style['--iconbutton-color'] = color;

  const labelStyles = showAsTooltip ? styles.tooltip : styles.label;

  const currentLabel = isActive && activeLabel ? activeLabel : label;

  return (
    <button
      ref={buttonRef || ref}
      className={clsx('button-reset', styles.container, isActive && styles.isActive, className)}
      style={style}
      type={type || 'button'}
      title={currentLabel}
      aria-label={currentLabel}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label && showLabelOnHover && <span className={labelStyles}>{currentLabel}</span>}
      <span className={styles.background} />
      <span className={styles.icon}>{isActive && activeChildren ? activeChildren : children}</span>
    </button>
  );
});

export default IconButton;
