// @flow
import styles from './dashboard.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import history from 'browserHistory';
import { FILTER, FILTERS } from 'constants/template';
import { DocumentAction, SheetAction, SheetTemplateAction } from 'store/actions';
import { SessionSelector, SheetTemplateSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import FilterBar from 'components/FilterBar';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import { TitleModal } from 'components/Modal';
import { CreateTemplateModal, ShareTemplateModal } from 'components/modals/dashboard';
import TemplateCard from 'components/TemplateCard/TemplateCard';
import TitlePanel from 'components/TitlePanel';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import MaterialsSidebar, { MATERIALTYPES } from 'pages/dashboard/MaterialsSidebar';

const SPINNER_COLOR = 'var(--color-dark-text)';

type Props = {
  isCreatingTemplate: boolean,
};

function TemplatesPage({ isCreatingTemplate }: Props): Node {
  const dispatch = useDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(FILTER.all);
  const [order, setOrder] = useState(true);

  const currentUser = useSelector(SessionSelector.currentUser);
  const filteredTemplates = useSelector((state) => SheetTemplateSelector.filter(filter, order ? 'asc' : 'desc')(state));
  const createdTemplatesCount = useSelector((state) => SheetTemplateSelector.getUsersTemplates(state).length);

  const templateToRemove = useSelector(SheetTemplateSelector.getTemplateToRemove);
  const templateToShare = useSelector(SheetTemplateSelector.getTemplateToShare);

  const isEmpty = hasFetched && filteredTemplates.length === 0;

  useEffect(() => {
    setIsLoading(true);
    batch(() => {
      dispatch(
        SheetTemplateAction.fetchAll(() => {
          setIsLoading(false);
          setHasFetched(true);
        })
      );
      dispatch(SheetTemplateAction.fetchSaved());
      dispatch(SheetAction.fetchAll());
      dispatch(DocumentAction.fetchAll());
    });
  }, [dispatch]);

  const onDeleteClick = () => {
    dispatch(SheetTemplateAction.delete(templateToRemove.guidSlug));
    onDeleteModalDismiss();
  };

  const onDeleteModalDismiss = () => dispatch(SheetTemplateAction.requestRemove(false));

  const onNewTemplateClick = () => history.push('/sheet-templates/new');

  const onUnsaveClick = () => {
    dispatch(SheetTemplateAction.unsave(templateToRemove.guidSlug));
    onDeleteModalDismiss();
  };

  const renderDeleteModal = () => {
    const isOwner = templateToRemove.userId === currentUser.id;
    const action = isOwner ? 'Delete' : 'Remove';
    const title = `${action} this Template?`;
    const subtitle =
      'This will permanently remove it from your account. Any sheets created from this template will still be available.';
    const onConfirmClick = isOwner ? onDeleteClick : onUnsaveClick;

    return (
      <TitleModal
        title={title}
        subtitle={subtitle}
        actions={
          <>
            <Button variant="cancel" onClick={onDeleteModalDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmClick}>
              {action}
            </Button>
          </>
        }
        onDismiss={onDeleteModalDismiss}
      >
        <h4 className={`heading1 ${styles.modalName}`}>{templateToRemove.name}</h4>
      </TitleModal>
    );
  };

  return (
    <DashboardLayout sidebarChildren={<MaterialsSidebar activeMaterial={MATERIALTYPES.template} />}>
      <Metatags title="Your Templates" />

      <div className={clsx(styles.content, styles.hasSidebar)}>
        <TitlePanel
          title="Your Templates"
          subtitle={
            createdTemplatesCount > 0 &&
            `You've created ${createdTemplatesCount} ${pluralize(createdTemplatesCount, 'template')}`
          }
        >
          <FilterBar
            filters={FILTERS}
            current={filter}
            order={order}
            onFilterChange={setFilter}
            onOrderChange={setOrder}
            className={styles.filterBar}
            tabWidth={130}
          />
          {filteredTemplates.length > 0 && (
            <div className={clsx(styles.cards)}>
              {filteredTemplates.map((template) => (
                <TemplateCard key={template.guid} template={template} />
              ))}
            </div>
          )}
          {isEmpty && (
            <div className={styles.empty}>
              <h4 className={styles.emptyHeader}>
                {filter === FILTER.all && "You don't have any templates yet"}
                {filter === FILTER.created && "You haven't created any templates yet"}
                {filter === FILTER.saved && "You haven't saved any templates yet"}
                {filter === FILTER.unlocked && "You haven't purchased any templates yet"}
              </h4>
              <p className={styles.emptyBody}>
                Add support for your favorite RPGs by searching for a template above or by creating one below
              </p>
              <Button variant="primary" className={styles.emptyCta} onClick={onNewTemplateClick} icon={<PlusIcon />}>
                Create Template
              </Button>
            </div>
          )}
          {isLoading && filteredTemplates.length === 0 && <LoadingSpinner color={SPINNER_COLOR} />}
        </TitlePanel>
      </div>

      {isCreatingTemplate && <CreateTemplateModal />}
      {templateToRemove && renderDeleteModal()}
      {templateToShare && <ShareTemplateModal template={templateToShare} />}
    </DashboardLayout>
  );
}

export default compose(requireAuth, requireCurrentUser)(TemplatesPage);
