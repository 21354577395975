// @flow
import styles from './NavBar.module.css';

import clsx from 'clsx';
import { Node, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { SessionSelector } from 'store/selectors';

import Button from 'components/buttons/Button';
import UserMenu from 'components/layouts/UserMenu';
import MenuList from 'components/MenuList';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';
import { ReactComponent as RoleLogoHorizontal } from 'images/RoleLogo_Horizontal.svg';

type Props = {
  children?: ?Node,
  menu?: ?Node,
};

function NavBar({ children, menu }: Props): Node {
  const gradientId = uuid();
  const isAuthed = useSelector(SessionSelector.isAuthed);
  let location = useLocation();
  const to = encodeURIComponent(`${location.pathname}${location.search}`);

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const toggleSideMenu = () => setSideMenuOpen((open) => !open);

  return (
    <div className={clsx(styles.container, sideMenuOpen && styles.menuIsActive)}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="var(--color-red)" />
            <stop offset="100%" stopColor="var(--color-purple)" />
          </linearGradient>
        </defs>
      </svg>
      <Link to="/dashboard" className={clsx(styles.logo, styles.link, (!menu || !isAuthed) && styles.persistOnMobile)}>
        <RoleLogoHorizontal
          className={styles.roleLogo}
          style={{ '--rolelogo-fill': `url(#${gradientId})`, strokeOpacity: 0 }}
        />
      </Link>
      {menu && isAuthed && (
        <div>
          <Button
            className={clsx(styles.logo, styles.menuButton)}
            variant="cancel"
            border="transparent"
            hoverPrimaryBackground="transparent"
            hoverBorder="transparent"
            onClick={toggleSideMenu}
          >
            <div className={styles.arrow}>
              <SingleArrowIcon className={styles.arrowIcon} />
            </div>
            <RoleLogoHorizontal
              className={clsx(styles.roleLogo, styles.mobileLogo)}
              style={{ '--rolelogo-fill': `url(#${gradientId})`, strokeOpacity: 0 }}
            />
          </Button>
          <MenuList className={styles.mobileMenu} isActive={sideMenuOpen}>
            {menu}
          </MenuList>
        </div>
      )}
      {menu && <div className={clsx(styles.content, styles.menu)}>{menu}</div>}
      {children && <div className={styles.content}>{children}</div>}
      {isAuthed ? (
        <UserMenu />
      ) : (
        <div className={styles.unauthLinks}>
          <Button
            to={`/login?to=${to}`}
            variant="primary"
            primaryBackground={'var(--color-purple)'}
            isLinkAsButton
            className={styles.unauthButton}
          >
            Log In
          </Button>
          <Button to={`/signup?to=${to}`} variant="primary" isLinkAsButton className={styles.unauthButton}>
            Sign Up
          </Button>
        </div>
      )}
    </div>
  );
}

export default NavBar;
