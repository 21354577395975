import styles from './SheetTemplatePage.module.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { DEFAULT_COLORS } from 'models/Color';
import { ErrorAction, SheetTemplateAction } from 'store/actions';
import { ErrorSelector, GameSelector, ProductSelector, SessionSelector, SheetTemplateSelector } from 'store/selectors';
import { logEvent, nextUrl } from 'utilities';

import disableMobile from 'hocs/disableMobile';
import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import ProductCard from 'components/cards/ProductCard';
import MainLayout from 'components/layouts/MainLayout';
import TitleBar from 'components/layouts/TitleBar';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import { Template } from 'components/Sheet2';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

class SheetTemplatePage extends Component {
  state = { isTOCOpen: false, fromSearch: false };

  componentDidMount() {
    const {
      match: {
        params: { guidSlug },
      },
      template,
      fetch,
      fetchSaved,
    } = this.props;
    if (!template) {
      fetch(guidSlug);
      fetchSaved();
    }

    const params = new URLSearchParams(window.location.search);
    this.setState({ fromSearch: params.get('from_search') === 'true' }, () => this.shouldNavigateAway());
  }

  componentDidUpdate() {
    this.shouldNavigateAway();
  }

  componentWillUnmount() {
    this.props.removeError(SheetTemplateAction.FETCH);
  }

  shouldNavigateAway() {
    const { isSearch } = this.state;
    const { template, currentUser, fetchError, history } = this.props;
    if (fetchError) return history.replace('/sheet-templates');
    if (template) {
      if (template.ownerType === 'User' && template.ownerId === currentUser.id)
        return history.replace(`/sheet-templates/${template.guidSlug}/edit${isSearch ? '?from_search=true' : ''}`);
      if (currentUser.isAdmin) return;
      if (!template.isPublished) history.replace('/sheet-templates');
    }
  }

  onChangeTOCOpen = (isOpen) => {
    this.setState({ isTOCOpen: isOpen });
  };

  onDuplicateClick = () => {
    const { fromSearch } = this.state;
    const { currentUser, template, duplicate, history } = this.props;
    duplicate(template.guidSlug, (newTemplate) => {
      logEvent('template - duplicated', {
        isCreator: template.ownerType === 'User' && template.ownerId === currentUser.id,
        fromSearch,
      });
      history.push(`/sheet-templates/${newTemplate.guidSlug}/edit`);
    });
  };

  onCancelClick = () => {
    const { fromSearch } = this.state;

    if (fromSearch) this.props.history.goBack();
    else this.props.history.push('/sheet-templates');
  };

  onSaveClick = () => {
    const { fromSearch } = this.state;
    const { template, saveTemplate, history } = this.props;
    saveTemplate(template.guidSlug, () => {
      logEvent('template - saved', { templateId: template.id, fromSearch });
      history.push('/sheet-templates');
    });
  };

  renderDuplicateFreeInfo = () => {
    return (
      <>
        <h3 className={`heading2 ${styles.infoHeading}`}>Duplicate Template?</h3>
        <p className={styles.infoCopy}>
          You are viewing a Sheet Template from another creator. You have already saved this Template to your Library.
          Duplicate this template to customize its information and elements. While in preview mode, all interactive
          elements have been disabled.
        </p>
        <Button variant="primary" onClick={this.onDuplicateClick} className={styles.infoButton}>
          Duplicate
        </Button>
        <Button
          variant="cancel"
          color="var(--color-light-text)"
          onClick={this.onCancelClick}
          className={styles.infoButton}
        >
          Cancel
        </Button>
      </>
    );
  };

  renderDuplicatePaidInfo = () => {
    return (
      <>
        <h3 className={`heading2 ${styles.infoHeading}`}>Duplicate Template?</h3>
        <p className={styles.infoCopy}>
          You are viewing a Sheet Template from a game you purchased. Duplicate this template to customize its
          information and elements. While in preview mode, all interactive elements have been disabled.
        </p>
        <Button variant="primary" onClick={this.onDuplicateClick} className={styles.infoButton}>
          Duplicate
        </Button>
        <Button
          variant="cancel"
          color="var(--color-light-text)"
          onClick={this.onCancelClick}
          className={styles.infoButton}
        >
          Cancel
        </Button>
      </>
    );
  };

  renderSaveInfo = () => {
    return (
      <>
        <h3 className={`heading2 ${styles.infoHeading}`}>Save Template?</h3>
        <p className={styles.infoCopy}>
          You are viewing a Sheet Template from another creator. You can save this Template to your library for use in
          your games! While in preview mode, all interactive elements have been disabled.
        </p>
        <Button variant="primary" onClick={this.onSaveClick} className={styles.infoButton}>
          Save
        </Button>
        <Button
          variant="cancel"
          color="var(--color-light-text)"
          onClick={this.onCancelClick}
          className={styles.infoButton}
        >
          Cancel
        </Button>
      </>
    );
  };

  renderGameUpsell = () => {
    const { game, product } = this.props;
    const title = [game.defaultFreeProductGuid, game.defaultPaidProductGuid].includes(product.guid)
      ? game.title
      : product.title;
    return (
      <>
        <h3 className={`heading2 ${styles.infoHeading}`}>{title}</h3>
        <p>
          You are viewing a premium Sheet Template from <em>{title}</em>. Purchase the game below to unlock this
          template for use in your rooms! While in preview mode, all interactive elements have been disabled.
        </p>
        <ProductCard product={product} isSmall isPlayPanel source="sheet template page" className={styles.product} />
      </>
    );
  };

  navigateBackIfSearch = (event) => {
    const { history } = this.props;
    if (this.state.fromSearch) {
      event.preventDefault();
      history.goBack();
    }
  };

  render() {
    const { isTOCOpen, fromSearch } = this.state;
    const { template, isSaved, unlockedTemplateIds, unlockedProductIds } = this.props;
    const tocMeta = { name: template?.name, username: template?.ownerName };
    const pageMeta = template ? [{ property: 'og:image', content: template.avatarUrl }] : [];

    return (
      <MainLayout
        navChildren={
          <TitleBar
            title="Template Preview"
            leftChildren={
              <Button
                icon={<SingleArrowIcon style={{ transform: 'rotate(90deg)' }} />}
                isSimpleIcon
                iconSize={10}
                isExternalLink
                to={nextUrl('/dashboard/templates')}
                target="_self"
                onClick={this.navigateBackIfSearch}
                color="var(--color-text-grey)"
              >
                {fromSearch ? 'Search Results' : 'Your Templates'}
              </Button>
            }
          />
        }
      >
        <Metatags title={`${template?.name ?? ''} Template`} meta={pageMeta} />

        <div className={styles.container}>
          {!template && <LoadingSpinner className={styles.spinner} />}

          {template && (
            <div className={styles.content}>
              <Template
                className={styles.preview}
                model={template}
                tocMeta={tocMeta}
                colors={DEFAULT_COLORS}
                isTOCOpen={isTOCOpen}
                readOnly
                isDraft
                onChangeTOCOpen={this.onChangeTOCOpen}
                onChange={() => {}}
                onChangeAvatar={() => {}}
                onChangeElement={() => {}}
                onChangeGroupElement={() => {}}
                onChangeSection={() => {}}
                onPublishClick={() => {}}
              />
              <div className={`${styles.infoContainer} ${isTOCOpen ? styles.tocOpen : ''}`}>
                <div className={styles.info}>
                  {template.isPaid &&
                    (unlockedTemplateIds.includes(template.id)
                      ? this.renderDuplicatePaidInfo()
                      : this.renderGameUpsell())}
                  {!template.isPaid &&
                    template.hasPaidAncestor &&
                    _.intersection(template.ancestorProductIds, unlockedProductIds).length > 0 &&
                    (isSaved ? this.renderDuplicatePaidInfo() : this.renderSaveInfo())}
                  {!template.isPaid &&
                    template.hasPaidAncestor &&
                    _.intersection(template.ancestorProductIds, unlockedProductIds).length === 0 &&
                    this.renderGameUpsell()}
                  {!template.isPaid &&
                    !template.hasPaidAncestor &&
                    (isSaved || _.intersection(unlockedProductIds, template.productIds).length > 0
                      ? this.renderDuplicateFreeInfo()
                      : this.renderSaveInfo())}
                  <a
                    className={styles.reportButton}
                    href={`mailto:support@playrole.com?subject=${encodeURIComponent(
                      `Report Template (${template.guidSlug})`
                    )}&body=${encodeURIComponent(
                      `Template URL: ${window.location.href.split('?')[0]}\n\nLeave a comment (optional):`
                    )}`}
                  >
                    Report
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { guidSlug },
    },
  } = props;
  const currentUser = SessionSelector.currentUser(state);
  const template = SheetTemplateSelector.get(state, guidSlug);

  return {
    currentUser,
    fetchError: ErrorSelector.get(state, SheetTemplateAction.FETCH),
    template,
    isSaved: template
      ? SheetTemplateSelector.getSavedTemplates(state)
          .map((o) => o.id)
          .includes(template.id)
      : false,
    unlockedTemplateIds: SheetTemplateSelector.getUnlockedTemplates(state).map((o) => o.id),
    unlockedProductIds: [...currentUser.unlockedFreeProductIds, ...currentUser.unlockedProductIds],
    game: template && GameSelector.get(state, template.gameIds[0] || template.ancestorGameIds[0]),
    product: template && ProductSelector.getById(state, template.productIds[0] || template.ancestorProductIds[0]),
  };
};

const mapDispatchToProps = {
  duplicate: SheetTemplateAction.duplicate,
  fetch: SheetTemplateAction.fetch,
  fetchSaved: SheetTemplateAction.fetchSaved,
  removeError: ErrorAction.remove,
  saveTemplate: SheetTemplateAction.save,
};

export default compose(
  requireAuth,
  requireCurrentUser,
  disableMobile,
  connect(mapStateToProps, mapDispatchToProps)
)(SheetTemplatePage);
