// @flow
import styles from './TabsBar.module.css';

import type { Node } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

type Props = {
  className?: ?string,
  currentTab?: ?string,
  onChange?: ?(tab: string) => void,
  tabs: string[],
  tabWidth: number,
  variant: 'dark' | 'light' | 'theme',
};

function TabsBar(props: Props): Node {
  const { className, currentTab, onChange: propOnChange, tabs, tabWidth, variant } = props;

  const name = useRef(`tabs-${uuid()}`);
  const [current, setCurrent] = useState(currentTab || tabs[0]);

  const classNames = [styles.container, styles[variant]];
  if (className) classNames.push(className);
  const style = { '--tabsbar-tab-width': `${tabWidth}px` };

  const backgroundPosition = tabs.indexOf(current) * tabWidth;
  useEffect(() => setCurrent(currentTab || tabs[0]), [currentTab, tabs]);

  const onChange = (tab: string) => {
    setCurrent(tab);
    if (propOnChange) propOnChange(tab);
  };

  return (
    <div className={classNames.join(' ')} style={style}>
      <div className={styles.background} style={{ transform: `translateX(${backgroundPosition}px)` }} />
      {tabs.map((tab, i) => (
        <label key={i}>
          <input
            type="radio"
            name={name.current}
            value={tab}
            onChange={() => onChange(tab)}
            checked={tab === current}
          />
          <span>{tab === 'apps' ? 'Web Apps' : tab}</span>
        </label>
      ))}
    </div>
  );
}

TabsBar.defaultProps = {
  variant: 'dark',
  tabWidth: 120,
};

export default TabsBar;
