import { createSelector } from 'reselect';

import { MediaMode } from 'types/room';

interface StateStub {
  media: {
    canvasScale: number;
    mode: MediaMode;
    penColor: string;
  };
}

export default class MediaSelector {
  static getCanvasScale = createSelector(
    (state: StateStub) => state.media.canvasScale,
    (scale) => scale
  );

  static getMode = createSelector(
    (state: StateStub) => state.media.mode,
    (mode) => mode
  );

  static getPenColor = createSelector(
    (state: StateStub) => state.media.penColor,
    (color) => color
  );

  static isDeleting = createSelector(MediaSelector.getMode, (mode) => mode === MediaMode.Deleting);
  static isDrawing = createSelector(MediaSelector.getMode, (mode) => mode === MediaMode.Drawing);
}
