import { mapKeys, merge, omit } from 'lodash';

import { ProductAction, RoomAction, SessionAction, SheetTemplateAction } from 'store/actions';

const INITIAL_STATE = {
  models: {},
  savedTemplateGuids: [],
  removeTemplateGuid: null,
  shareTemplateGuid: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case SheetTemplateAction.ADD_IMAGE:
    case SheetTemplateAction.CREATE:
    case SheetTemplateAction.CREATE_DICE:
    case SheetTemplateAction.DUPLICATE:
    case SheetTemplateAction.FETCH:
    case SheetTemplateAction.PUBLISH:
    case SheetTemplateAction.UNPUBLISH:
    case SheetTemplateAction.REMOVE_IMAGE:
    case SheetTemplateAction.UPDATE:
    case SheetTemplateAction.UPDATE_LOCAL:
      return { ...state, models: { ...state.models, [payload.guid]: payload } };
    case SheetTemplateAction.DELETE:
      return { ...state, models: omit(state.models, getGuid(payload)) };

    case SheetTemplateAction.UPDATE_AND_FORGET:
    case SheetTemplateAction.UPDATE_ELEMENT:
    case SheetTemplateAction.UPDATE_GROUP_ELEMENT:
    case SheetTemplateAction.UPDATE_SECTION:
      return {
        ...state,
        models: {
          ...state.models,
          [payload.guid]: { ...state.models[payload.guid], lastEditedAt: payload.lastEditedAt },
        },
      };

    case ProductAction.FETCH_SHEET_TEMPLATES:
    case SheetTemplateAction.FETCH_ALL:
    case RoomAction.FETCH_PLAYKIT_SHEET_TEMPLATES:
    case RoomAction.FETCH_SHEET_TEMPLATES:
      return { ...state, models: merge({}, state.models, mapKeys(payload, 'guid')) };

    case SheetTemplateAction.FETCH_SAVED:
      return {
        ...state,
        models: merge({}, state.models, mapKeys(payload, 'guid')),
        savedTemplateGuids: [...state.savedTemplateGuids, ...payload.map((o) => o.guid)],
      };

    case SheetTemplateAction.SAVE:
      return { ...state, savedTemplateGuids: [...state.savedTemplateGuids, getGuid(payload)] };

    case SheetTemplateAction.UNSAVE:
      return {
        ...state,
        models: omit(state.models, payload.split('-')[0]),
        savedTemplateGuids: state.savedTemplateGuids.filter((o) => o !== getGuid(payload)),
      };

    case SheetTemplateAction.CHANGE:
      return { ...state, models: { ...state.models, [payload.guid]: payload } };

    case SheetTemplateAction.CHANGE_ELEMENT:
      return changeElement(state, payload);

    case SheetTemplateAction.CHANGE_GROUP_ELEMENT:
      return changeGroupElement(state, payload);

    case SheetTemplateAction.CHANGE_SECTION:
      return changeSection(state, payload);

    case SheetTemplateAction.REQUEST_REMOVE:
      return { ...state, removeTemplateGuid: payload };

    case SheetTemplateAction.REQUEST_SHARE:
      return { ...state, shareTemplateGuid: payload };

    case SessionAction.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;

const getGuid = (guidSlug) => guidSlug.split('-')[0];

const changeElement = (state, payload) => {
  const { id, sectionId, element } = payload;
  const model = state.models[id];
  if (!model) return state;
  const section = model.draftData.sections.find((o) => o.id === sectionId);
  if (!section) return state;
  if (!section.elements.find((o) => o.id === element.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        draftData: {
          ...model.draftData,
          sections: model.draftData.sections.map((o) => {
            if (o.id === section.id) {
              return { ...section, elements: section.elements.map((e) => (e.id === element.id ? element : e)) };
            }
            return o;
          }),
        },
      },
    },
  };
};

const changeGroupElement = (state, payload) => {
  const { id, sectionId, groupId, element } = payload;
  const model = state.models[id];
  if (!model) return state;
  const section = model.draftData.sections.find((o) => o.id === sectionId);
  if (!section) return state;
  const group = section.elements.find((o) => o.id === groupId);
  if (!group) return state;
  if (!group.items.find((o) => o.id === element.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        draftData: {
          ...model.draftData,
          sections: model.draftData.sections.map((o) => {
            if (o.id === section.id) {
              return {
                ...section,
                elements: section.elements.map((e) => {
                  if (e.id === groupId) {
                    return { ...e, items: e.items.map((ge) => (ge.id === element.id ? element : ge)) };
                  }
                  return e;
                }),
              };
            }
            return o;
          }),
        },
      },
    },
  };
};

const changeSection = (state, payload) => {
  const { id, section } = payload;
  const model = state.models[id];
  if (!model) return state;
  if (!model.draftData.sections.find((o) => o.id === section.id)) return state;
  return {
    ...state,
    models: {
      ...state.models,
      [id]: {
        ...model,
        draftData: {
          ...model.draftData,
          sections: model.draftData.sections.map((o) => (o.id === section.id ? section : o)),
        },
      },
    },
  };
};
