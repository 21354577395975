// @flow

import _ from 'lodash';
import { createSelector } from 'reselect';

import SessionSelector from './SessionSelector';

const organizations = (state) => state.organizations;

export default class OrganizationSelector {
  static get = createSelector([organizations, (state, slug) => slug], (orgs, slug) => orgs[slug]);

  static getById = createSelector(
    [(state) => state.organizations, (state, id) => id],
    (orgs, id) => orgs[_.findKey(orgs, { id })]
  );

  static getAll = (state) => Object.values(state.organizations);

  static getAllByCurrentUser = createSelector([organizations, SessionSelector.currentUser], (orgs, user) =>
    Object.values(orgs).filter(({ id }) => user?.organizationIds?.includes(id))
  );
}
