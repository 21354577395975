import { mapKeys, merge } from 'lodash';

import GameAction from 'store/actions/GameAction';

const INITIAL_STATE = {};

export default function gamesReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case GameAction.FETCH:
      return { ...state, [payload.id]: payload };

    case GameAction.FETCH_ALL:
      return merge({}, state, mapKeys(payload, 'id'));

    default:
      return state;
  }
}
