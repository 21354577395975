// @flow
import _ from 'lodash';
import React from 'react';
import { v4 as uuid } from 'uuid';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import { createInput } from 'utilities/sheet';

import Clock from './Clock';
import { Builder } from 'components/Sheet2/elements/Element';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function ClockBuilder(props: Props) {
  const { colors, element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;
  const clockInputs = items.filter((item) => item.inputType === INPUT_TYPE.clockSlice);
  const clockValues: boolean[] = clockInputs?.map((item) =>
    typeof item.defaultValue === 'boolean' ? item.defaultValue : false
  );

  const onClockChange = (index: number, value: boolean) => {
    const updatedClockInputs = value
      ? clockInputs.map((input, i) => ({ ...input, defaultValue: i <= index }))
      : clockInputs.map((input, i) => ({ ...input, defaultValue: i < index }));
    const updatedItems = [...updatedClockInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.clockSlice)];
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onCountChange = (values: string[]) => {
    const count = parseInt(values[0]);
    if (isNaN(count)) return;
    const total = clockInputs.length;
    if (count === total) return;

    if (count < total) {
      const updatedClockInputs = clockInputs.slice(0, count);
      const updatedItems = [...updatedClockInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.clockSlice)];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    } else {
      const newClockInputs = _.times(count - total, () => ({
        ...createInput(uuid(), INPUT_TYPE.clockSlice),
        defaultValue: false,
        canEdit: true,
      }));
      const updatedItems = [
        ...clockInputs,
        ...newClockInputs,
        ...items.filter((o) => o.inputType !== INPUT_TYPE.clockSlice),
      ];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    }
  };

  return (
    <Builder {...props}>
      <Clock
        items={clockValues}
        isBuilding={true}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onChange={onClockChange}
        onCountChange={onCountChange}
      />
    </Builder>
  );
}

const MemoizedClockBuilder = React.memo(ClockBuilder);
MemoizedClockBuilder.displayName = 'MemoizedClockBuilder';

export default MemoizedClockBuilder;
