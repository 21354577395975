import _ from 'lodash';

import RollAction from 'store/actions/RollAction';
import TableAction from 'store/actions/TableAction';

const INITIAL_STATE = {
  dice: {},
  modifiers: {},
  userRolls: {},
};

const INITIAL_USER_ROLL_STATE = {
  dice: {},
  isShowing: true,
  modifiers: {},
};

export default function rollReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case TableAction.RESET:
      return INITIAL_STATE;

    // ========================================
    // DICE
    // ========================================

    case RollAction.ADD_DIE:
      return { ...state, dice: { ...state.dice, [payload.id]: payload } };

    case RollAction.REMOVE_ALL_DICE:
      return { ...state, dice: INITIAL_STATE.dice };

    case RollAction.REMOVE_DIE:
      return { ...state, dice: _.omit(state.dice, payload) };

    case RollAction.UPDATE_DIE:
      return { ...state, dice: { ...state.dice, [payload.id]: payload } };

    // ========================================
    // MODIFIERS
    // ========================================

    case RollAction.ADD_MODIFIER:
      const inputModifier = Object.values(state.modifiers).find(
        (o) => o.sheetId === payload.sheetId && o.inputId === payload.inputId
      );
      if (!inputModifier || (!payload.sheetId && !payload.inputId))
        return { ...state, modifiers: { ...state.modifiers, [payload.id]: payload } };
      else return { ...state };

    case RollAction.REMOVE_ALL_MODIFIERS:
      return { ...state, modifiers: INITIAL_STATE.modifiers };

    case RollAction.REMOVE_MODIFIER:
      return { ...state, modifiers: _.omit(state.modifiers, payload) };

    case RollAction.UNSELECT_ALL_MODIFIERS:
      const modifiers = Object.values(state.modifiers).map((o) => {
        if (!o.sheetId && !o.inputId) return { ...o, value: 0, isSelected: false };
        else return { ...o, isSelected: false };
      });
      return { ...state, modifiers: { ..._.mapKeys(modifiers, 'id') } };

    case RollAction.UPDATE_MODIFIER:
      if (!payload.id) return state;
      return { ...state, modifiers: { ...state.modifiers, [payload.id]: payload } };

    // ========================================
    // USER ROLLS
    // ========================================

    case RollAction.ADD_USER_DIE:
      return addUserDie(state, payload);

    case RollAction.ADD_USER_MODIFIER:
      return addUserModifier(state, payload);

    case RollAction.SHOW_USER_ROLL:
      return showUserRoll(state, payload);

    case RollAction.REMOVE_ALL_USER_DICE:
      return removeAllUserDice(state, payload);

    case RollAction.REMOVE_ALL_USER_MODIFIERS:
      return removeAllUserModifiers(state, payload);

    case RollAction.REMOVE_USER_DIE:
      return removeUserDie(state, payload);

    case RollAction.REMOVE_USER_MODIFIER:
      return removeUserModifier(state, payload);

    default:
      return state;
  }
}

const addUserDie = (state, payload) => {
  const { userId, die } = payload;
  const roll = state.userRolls[userId] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, dice: { ...roll.dice, [die.id]: die } };
  return { ...state, userRolls: { ...state.userRolls, [userId]: updatedRoll } };
};

const addUserModifier = (state, payload) => {
  const { userId, modifier } = payload;
  const roll = state.userRolls[userId] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, modifiers: { ...roll.modifiers, [modifier.id]: modifier } };
  return { ...state, userRolls: { ...state.userRolls, [userId]: updatedRoll } };
};

const showUserRoll = (state, payload) => {
  const { userId, show } = payload;
  const roll = state.userRolls[userId] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, isShowing: show };
  return { ...state, userRolls: { ...state.userRolls, [userId]: updatedRoll } };
};

const removeAllUserDice = (state, payload) => {
  const roll = state.userRolls[payload] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, dice: INITIAL_USER_ROLL_STATE.dice };
  return { ...state, userRolls: { ...state.userRolls, [payload]: updatedRoll } };
};

const removeAllUserModifiers = (state, payload) => {
  const roll = state.userRolls[payload] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, modifiers: INITIAL_USER_ROLL_STATE.modifiers };
  return { ...state, userRolls: { ...state.userRolls, [payload]: updatedRoll } };
};

const removeUserDie = (state, payload) => {
  const { userId, dieId } = payload;
  const roll = state.userRolls[userId] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, dice: _.omit(roll.dice, dieId) };
  return { ...state, userRolls: { ...state.userRolls, [userId]: updatedRoll } };
};

const removeUserModifier = (state, payload) => {
  const { userId, modifierId } = payload;
  const roll = state.userRolls[userId] || INITIAL_USER_ROLL_STATE;
  const updatedRoll = { ...roll, modifiers: _.omit(roll.modifiers, modifierId) };
  return { ...state, userRolls: { ...state.userRolls, [userId]: updatedRoll } };
};
