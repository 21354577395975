import styles from './errors.module.css';

import { Fragment, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';

import useEventListener from 'hooks/useEventListener';

import { Popover, Portal } from '@headlessui/react';

interface PopoverErrorProps {
  referenceElement: HTMLElement; // Child of the Popover.Button
  children: ReactNode;
  onDismissError(): void;
}
export default function PopoverError({ children, referenceElement, onDismissError }: PopoverErrorProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
  });

  useEventListener('click', onDismissError, popperElement!);

  return (
    <Popover as={Fragment}>
      <Portal>
        <Popover.Panel
          className={styles.errorPopover}
          static
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {children}
        </Popover.Panel>
      </Portal>
    </Popover>
  );
}
