// @flow
import { useEffect, useState } from 'react';

// length - the length of the string you want to get a font size for
// fontSizes - a hash of thresholds to sizes
export default function useFontSize(length, fontSizes) {
  const [fontSize, setFontSize] = useState(Object.values(fontSizes)[0]);

  useEffect(() => {
    const size = Object.entries(fontSizes).find(([threshold, _]) => length <= threshold);
    if (size) {
      setFontSize(size[1]);
    } else {
      const values = Object.values(fontSizes);
      setFontSize(values[values.length - 1]);
    }
  }, [length, fontSizes]);

  return fontSize;
}
