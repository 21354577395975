export const ModifierType = {
  BUFF: 'buff',
  TARGET: 'target',
  DICE: 'dice',
}

export default class Modifier {
  constructor(params) {
    this.id = params.id
    this.sheetId = params.sheetId
    this.inputId = params.inputId
    this.type = params.type
    this.diceType = params.diceType
    this.value = params.value
    this.count = params.count || 0
    this.isSelected = params.isSelected || false
  }

  isDice = () => this.type === ModifierType.DICE
}
