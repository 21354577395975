import _ from 'lodash';
import { createSelector } from 'reselect';

import { FILTER } from 'constants/asset';
import { DocumentType } from 'models/Document';

import RoomDocumentSelector from './RoomDocumentSelector';
import RoomSelector from './RoomSelector';
import SessionSelector from './SessionSelector';
import SheetSelector from './SheetSelector';
import SheetTemplateSelector from './SheetTemplateSelector';

const orderByName = (obj) => obj.name.toLowerCase();
const orderByType = (obj) => (obj.type === 'PDF' ? 1 : 0);

export default class DocumentSelector {
  static get = createSelector([(state) => state.documents, (state, id) => id], (documents, id) => documents[id]);

  static getAllByUser = (userId) => {
    return createSelector(
      (state) => state.documents,
      (documents) => {
        const filteredDocuments = Object.values(documents).filter((o) => o.userId === userId);
        return _.orderBy(filteredDocuments, [orderByName], ['asc']);
      }
    );
  };

  static getAllByRoom = createSelector(
    [RoomSelector.get, RoomDocumentSelector.getAllByRoom, (state) => state.documents],
    (room, roomDocuments, documents) => {
      if (!room) return [];
      return _.orderBy(
        roomDocuments.map((o) => documents[o.id]),
        [orderByType, orderByName],
        ['desc', 'asc']
      );
    }
  );

  static getPlaykitAssetsByRoom = createSelector([this.getAllByRoom, RoomSelector.get], (documents, room) => {
    return documents.filter((d) => _.intersection(room.playkitIds, d.playkitIds).length > 0);
  });

  static getGameMaterials = createSelector(
    [this.getAllByRoom, SessionSelector.currentUser],
    (documents, { unlockedProductIds, unlockedFreeProductIds }) => {
      return documents.filter(
        (d) => _.intersection(unlockedProductIds.concat(unlockedFreeProductIds), d.productIds).length > 0
      );
    }
  );

  static getTokensByRoom = createSelector(this.getAllByRoom, (documents) => {
    const filteredDocuments = Object.values(documents).filter((o) => o.type.toLowerCase() === DocumentType.TOKEN);
    return _.orderBy(filteredDocuments, [orderByName], ['asc']);
  });

  static getTokensByRoomAndUser = (userId) => {
    return createSelector(
      [
        (state) => state.documents,
        (state, roomGuid) => RoomDocumentSelector.getByRoom(state, roomGuid, FILTER.tokens),
        RoomSelector.get,
      ],
      (documents, tokenRoomDocuments, room) => {
        return _.orderBy(
          tokenRoomDocuments.map((o) => documents[o.id]),
          [orderByName],
          ['asc']
        );
      }
    );
  };

  static filterAllByUser = (userId, filterType, filterOrder, includeUnlockedDocuments = true) => {
    const getAllByUser = this.getAllByUser(userId);

    return createSelector(
      [getAllByUser, this.getUserUnlockedDocuments, (state) => state.games],
      (documents, unlockedDocuments, games) => {
        const filteredDocuments = documents.concat(includeUnlockedDocuments ? unlockedDocuments : []).filter((o) => {
          const type = o.type.toLowerCase();
          if (type === DocumentType.DICE) return false;
          if (filterType === DocumentType.IMAGE) return type === DocumentType.IMAGE || type === DocumentType.GIF;
          return type === filterType || !filterType || filterType === DocumentType.NONE;
        });
        return _.orderBy(
          filteredDocuments,
          [(doc) => games[doc.gameIds?.[0]]?.title.toLowerCase() ?? '', orderByName],
          [filterOrder, filterOrder]
        );
      }
    );
  };

  static filterUnlockedDocuments = (filterType, filterOrder) =>
    createSelector([this.getUserUnlockedDocuments, (state) => state.games], (documents, games) => {
      const filteredDocuments = Object.values(documents).filter((o) => {
        const type = o.type.toLowerCase();
        if (type === DocumentType.THEME || type === DocumentType.DICE) return false;
        if (filterType === DocumentType.IMAGE) return type === DocumentType.IMAGE || type === DocumentType.GIF;
        return type === filterType || !filterType || filterType === DocumentType.NONE;
      });
      return _.orderBy(
        filteredDocuments,
        [(doc) => games[doc.gameIds?.[0]]?.title.toLowerCase() ?? '', orderByName],
        [filterOrder, filterOrder]
      );
    });

  static filterByUserNotInRoom = (userId, filterType, filterOrder) => {
    const filterAllByUser = this.filterAllByUser(userId, filterType, filterOrder);
    return createSelector(
      [filterAllByUser, (state) => state.roomDocuments, SessionSelector.currentUser, RoomSelector.get],
      (userDocuments, roomDocuments, currentUser, room) =>
        userDocuments.filter((document) => !roomDocuments[`${room.guid}-${document.id}-${currentUser.id}`])
    );
  };

  static getUserUnlockedDocuments = createSelector(
    [SessionSelector.currentUser, (state) => state.documents],
    ({ unlockedProductIds, unlockedFreeProductIds }, documents) => {
      return Object.values(documents).filter(
        (doc) =>
          doc.type.toLowerCase() !== DocumentType.THEME &&
          doc.type.toLowerCase() !== DocumentType.DICE &&
          _.intersection(doc.productIds, unlockedProductIds.concat(unlockedFreeProductIds)).length > 0
      );
    }
  );

  static getCustomDice = createSelector(
    (state) => state.documents,
    (documents) => Object.values(documents).filter((document) => document.type.toLowerCase() === DocumentType.DICE)
  );

  static getCustomDiceByTemplate = createSelector(
    [this.getCustomDice, SheetTemplateSelector.get],
    (documents, template) => documents.filter(({ id }) => template?.documentIds.includes(id))
  );

  static getCustomDiceBySheet = createSelector(
    [this.getCustomDice, SheetSelector.get, (state) => state.sheetTemplates.models],
    (documents, sheet, templates) => {
      const template = Object.values(templates).find((o) => o.id === sheet?.sheetTemplateId);
      return documents.filter(({ id }) => template?.documentIds.includes(id));
    }
  );
}
