import styles from './TableChat.module.css';

import React, { Component, Fragment } from 'react';

import { DICE_COLOR_LABELS } from 'models/Color';
import { DieType } from 'models/Die';
import { DICE_LOG_TOKEN } from 'models/Twilio';
import { getFateSymbol, getFateValue } from 'utilities/dice';

class DiceMessage extends Component {
  render() {
    const { isSelf, message, user, colors } = this.props;
    const { body, dateCreated } = message;
    let name = user.displayName;
    if (isSelf) name += ' (You) (Roll)';
    else name += ' (Roll)';
    const color = colors[0];
    const data = JSON.parse(body.split(DICE_LOG_TOKEN)[1]);
    const { refs, buffs, dice } = data;
    const total = dice.reduce(
      (total, die) => total + (die.type === DieType.FATE ? getFateValue(die.value) : die.value),
      buffs
    );

    return (
      <div className={`${styles.message} ${styles.isRoll}`}>
        <div title={dateCreated} className={styles.author} style={{ color }}>
          {name}
        </div>
        <div>
          <p>
            {refs !== 0 && `${refs} v `}
            {dice.map((die, i) => {
              const dieName = die.name || (die.type === DieType.FATE ? 'DF' : `D${die.sides}`);
              const dieValue = die.type === DieType.FATE ? getFateSymbol(die.value) : die.value;
              const dieLabel = `${die.color ? DICE_COLOR_LABELS[die.color] + ' ' : ''}${dieName}`;
              return (
                <Fragment key={i}>
                  {i > 0 && ' + '}
                  <strong>{dieValue}</strong> (
                  <span aria-label={dieLabel} title={dieLabel} style={{ color: die.color || undefined }}>
                    {dieName}
                  </span>
                  )
                </Fragment>
              );
            })}
            {buffs !== 0 && ` + ${buffs}`}
            {` = `}
            <strong>{total}</strong>
          </p>
        </div>
      </div>
    );
  }
}

DiceMessage.defaultProps = {
  isSelf: false,
};

export default DiceMessage;
