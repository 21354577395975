import { AnyAction } from 'redux';

import Color from 'models/Color';
import MediaAction from 'store/actions/MediaAction';
import { MediaMode } from 'types/room';

const INITIAL_STATE = {
  canvasScale: 1,
  penColor: Color.RED,
  mode: MediaMode.None,
};

export default function mediaReducer(state = INITIAL_STATE, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case MediaAction.SET_CANVAS_SCALE:
      return { ...state, canvasScale: payload };

    case MediaAction.SET_MODE:
      return { ...state, mode: payload };

    case MediaAction.SET_PEN_COLOR:
      return { ...state, penColor: payload };

    default:
      return state;
  }
}
