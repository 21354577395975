import styles from './SPGBanner.module.css';

import { useDispatch, useSelector } from 'react-redux';

import { UserAction } from 'store/actions';
import { SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as D4 } from 'images/dice/D4.svg';
import { ReactComponent as D6 } from 'images/dice/D6.svg';
import { ReactComponent as D10 } from 'images/dice/D10.svg';
import { ReactComponent as D20 } from 'images/dice/D20.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import SPGLogo from 'images/spg-logo.png';

export default function SPGBanner() {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);

  const onDismissBanner = () => {
    const payload = { user: { metadata: { ...currentUser.metadata, febSPGBanner: false } } };
    dispatch(UserAction.updateJson(currentUser.id, payload));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.logo} src={SPGLogo} alt="Start Playing Games Logo" />
        <div className={styles.bannerMain}>
          <div className={styles.copy}>
            <h3 className={styles.bannerTitle}>Need a GM for that new game you want to try?</h3>
            <p>
              Role has partnered with Start Playing Games to bring you the best of the best. Click learn more to get
              your next adventure started today!
            </p>
          </div>
          <Button
            variant="primary"
            to={{
              pathname: 'https://playrole.notion.site/Role-Official-Game-Masters-4e9b746f6d8f41b5a05b3e870f456450',
            }}
            onClick={() => logEvent('dashboard - click spg learn more')}
            isLinkAsButton
            isExternalLink
            className={styles.button}
          >
            Learn more
          </Button>
        </div>
        <div className={styles.diceIcons}>
          <D4 className={styles.d4} />
          <D10 className={styles.d10} />
          <D6 className={styles.d6} />
          <D20 className={styles.d20} />
        </div>
      </div>
      {currentUser && (
        <IconButton
          className={styles.closeButton}
          color="rgb(var(--color-theme-text))"
          background="transparent"
          activeBackground="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-button))"
          activeColor="var(--color-white)"
          borderRadius={16}
          onClick={onDismissBanner}
        >
          <XIcon />
        </IconButton>
      )}
    </div>
  );
}
