import styles from './TransferSheetModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { RoomSheetAction, SheetAction, TableAction } from 'store/actions';
import { RoomUserSelector, SessionSelector, SheetSelector, TableSelector } from 'store/selectors';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { DropdownInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

class TransferSheetModal extends Component {
  inviteLinkRef = React.createRef();

  onDismiss = () => {
    this.props.confirmTransferSheet(null);
  };

  onSubmit = ({ user_id }) => {
    const { activeSheet, activeSheetUser, room, sheet } = this.props;
    const { fetchRoomSheets, setActiveSheetUser, transferSheet } = this.props;
    transferSheet(sheet.id, { room_id: room.id, sheet: { user_id } }, () => {
      fetchRoomSheets(room.guid, () => {
        if (activeSheet && activeSheetUser && activeSheet.id === sheet.id && activeSheetUser.id !== user_id) {
          setActiveSheetUser(user_id);
        }
      });
    });
    this.onDismiss();
  };

  renderDropdownInput = ({ input, label, meta }) => {
    const { users, submitFailed } = this.props;
    return (
      <label className={styles.inputContainer}>
        {label}
        <DropdownInput input={input} meta={meta} showError={submitFailed}>
          <option value="">None</option>
          {users.map(({ userId, username }) => (
            <option key={userId} value={userId}>
              {username}
            </option>
          ))}
        </DropdownInput>
      </label>
    );
  };

  render() {
    const {
      sheet: { name, avatarUrl, ddbId },
      colors,
      handleSubmit,
    } = this.props;

    return (
      <TitleModal
        title={
          <>
            Give Your Sheet to
            <br />
            Another Player?
          </>
        }
        subtitle="When you give your sheet to someone else it is removed from your account completely. The selected player will have full control over the sheet."
        onDismiss={this.onDismiss}
      >
        <Avatar
          borderColors={colors}
          className={styles.avatar}
          avatarUrl={avatarUrl ?? null}
          shape="squircle"
          size={144}
          showSheetDefault
          isDdbSheet={!!ddbId}
        />
        <h4 className={`heading1 ${styles.name}`}>{name}</h4>

        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <Field
            component={this.renderDropdownInput}
            name="user_id"
            label={
              <span className={styles.label}>
                <strong>Player</strong>:
              </span>
            }
          />
          <div className={styles.buttons}>
            <Button variant="cancel" onClick={this.onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Confirm
            </Button>
          </div>
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const { guid } = room;
  const currentUser = SessionSelector.currentUser(state);
  const sheetId = SheetSelector.transferId(state);
  const getAllByRoom = RoomUserSelector.getAllByRoom(guid);
  const colors = RoomUserSelector.getColors(state, guid, currentUser.id);

  return {
    activeSheet: TableSelector.getActiveSheet(state, guid),
    activeSheetUser: TableSelector.getActiveSheetUser(state, guid),
    colors,
    sheet: SheetSelector.get(state, sheetId),
    users: getAllByRoom(state).filter((o) => o.userId !== currentUser.id),
  };
};

const mapDispatchToProps = {
  confirmTransferSheet: SheetAction.confirmTransfer,
  fetchRoomSheets: RoomSheetAction.fetchAll,
  setActiveSheetUser: TableAction.setActiveSheetUser,
  transferSheet: SheetAction.transfer,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'transferSheet' })
)(TransferSheetModal);
