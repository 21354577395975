import styles from './TableChat.module.css';

import React, { Component } from 'react';

import { PING_TOKEN } from 'models/Twilio';

import MarkdownBody from './MarkdownBody';

class Message extends Component {
  render() {
    const { isSelf, message, user, colors } = this.props;
    const { body, dateCreated } = message;
    if (body.includes(PING_TOKEN)) {
      return <div className={`${styles.message} ${styles.isPing}`}>!!!! PING !!!!</div>;
    }

    let name = user.displayName;
    if (isSelf) name += ' (You)';
    const color = colors[0];

    return (
      <div className={styles.message}>
        <div title={dateCreated} className={styles.author} style={{ color }}>
          {name}
        </div>
        <MarkdownBody children={body} />
      </div>
    );
  }
}

Message.defaultProps = {
  isSelf: false,
};

export default Message;
