import styles from './RenameAssetModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import AssetAction from 'store/actions/AssetAction';
import DocumentAction from 'store/actions/DocumentAction';

import Button from 'components/buttons/Button';
import AssetCard from 'components/cards/AssetCard';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

class RenameAssetModal extends Component {
  onDismiss = () => {
    const { onDismiss, rename } = this.props;
    rename(null);
    if (onDismiss) onDismiss();
  };

  onSubmit = (formValues) => {
    const { asset, updateDocument } = this.props;
    const document = {
      name: formValues.name,
    };
    updateDocument(asset.id, { document }, () => this.onDismiss());
  };

  renderInput = ({ input, type, min, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <TextInput
        input={input}
        meta={meta}
        type={type}
        min={min}
        placeholder={placeholder}
        showError={submitFailed}
        className={styles.input}
      />
    );
  };

  render() {
    const { asset, handleSubmit } = this.props;

    return (
      <TitleModal
        title="Rename Asset"
        actions={
          <>
            <Button variant="cancel" onClick={this.onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit(this.onSubmit)}>
              Save
            </Button>
          </>
        }
        onDismiss={this.onDismiss}
      >
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <AssetCard asset={asset} className={styles.asset} />
          <Field component={this.renderInput} name="name" type="text" placeholder="Name" />
        </form>
      </TitleModal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim() === '') errors.name = 'Required';
  return errors;
};

const mapStateToProps = (state, props) => {
  const {
    asset: { name },
  } = props;
  return {
    initialValues: {
      name,
    },
  };
};

const mapDispatchToProps = {
  rename: AssetAction.rename,
  updateDocument: DocumentAction.update,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'renameAsset', validate })
)(RenameAssetModal);
