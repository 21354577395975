// @flow
import styles from './FilterBar.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import IconButton from 'components/buttons/IconButton';
import { DropdownInput } from 'components/inputs';
import TabsBar from 'components/TabsBar';
import { ReactComponent as SortDown } from 'images/icons/SortDown.svg';
import { ReactComponent as SortUp } from 'images/icons/SortUp.svg';

type Props = {
  current: string,
  className?: ?string,
  filters: string[],
  onFilterChange: (string) => void,
  onOrderChange: (boolean) => void,
  order: boolean,
  tabWidth?: number,
};

function FilterBar(props: Props): Node {
  const { current, className, filters, order, onFilterChange, onOrderChange, tabWidth } = props;

  return (
    <div className={clsx(styles.container, className)}>
      <TabsBar
        variant="light"
        tabs={filters}
        currentTab={current}
        onChange={onFilterChange}
        className={styles.filterBar}
        tabWidth={tabWidth}
      />
      <DropdownInput options={filters} value={current} onChange={onFilterChange} className={styles.filterDropdown} />
      <IconButton
        className={styles.orderButton}
        background="var(--color-dashboard-light-grey)"
        color="var(--color-dashboard-main)"
        activeBackground="var(--color-dashboard-light)"
        hoverBackground="var(--color-dashboard-light)"
        activeColor="var(--color-white)"
        iconSize={18}
        onClick={() => onOrderChange(!order)}
      >
        {order ? <SortDown /> : <SortUp />}
      </IconButton>
    </div>
  );
}

FilterBar.defaultProps = {
  order: false,
};

export default FilterBar;
