import _ from 'lodash';
import { createSelector } from 'reselect';

import { moveItem } from 'utilities';
import { getUserColors } from 'utilities/color';

import TableSelector from './TableSelector';

const orderByName = (obj) => obj.displayName.toLowerCase();
const orderByUsername = (obj) => obj.username.toLowerCase();

export default class RoomUserSelector {
  static get = (state, guid, userId) => state.roomUsers.models[`${guid}-${userId}`];

  static getAllByRoom = (guid, orderBy = 'username', hostId = null) => {
    return createSelector(
      (state) => state.roomUsers.models,
      (users) => {
        const filtered = Object.values(users).filter((o) => o.guid === guid);
        const order = orderBy === 'username' ? orderByUsername : orderByName;
        const sortedRoomUsers = _.orderBy(filtered, [order], ['asc']);
        if (sortedRoomUsers?.length > 0 && hostId) {
          const hostIndex = sortedRoomUsers.findIndex((u) => u.userId === hostId);
          return moveItem(sortedRoomUsers, hostIndex, 0);
        } else {
          return sortedRoomUsers;
        }
      }
    );
  };

  static getAllPresentInRoom = (guid) => {
    return createSelector(
      [(state) => state.roomUsers.models, (state) => state.table.userIds],
      (roomUsers, presentUserIds) =>
        Object.values(roomUsers).filter((o) => o.guid === guid && presentUserIds.includes(o.userId))
    );
  };

  static getAllPresentAndActiveInRoom = createSelector(
    [
      (state) => state.roomUsers.models,
      (state) => state.roomUsers.statuses,
      (state) => state.table.userIds,
      (_, guid) => guid,
      TableSelector.isFocusMode,
    ],
    (models, statuses, presentUserIds, roomGuid, isFocusMode) =>
      Object.values(models).filter((model) => {
        const status = statuses[model.id];
        return (
          model.guid === roomGuid &&
          presentUserIds.includes(model.userId) &&
          (!isFocusMode || (isFocusMode && (status?.isVideoEnabled || status?.isAudioEnabled)))
        );
      })
  );

  static getColors = createSelector(this.get, getUserColors);

  static getColorsAllRooms = (rooms, userId) => {
    return createSelector(
      (state) => state.roomUsers.models,
      (users) => {
        const colors = {};
        rooms.forEach((room) => {
          const user = users[`${room.guid}-${userId}`];
          colors[room.guid] = getUserColors(user);
        });
        return colors;
      }
    );
  };

  static getSettings = createSelector(this.get, (roomUser) => roomUser?.settings ?? {});

  static getStatus = createSelector(
    [(state) => state.roomUsers.statuses, (_, guid) => guid, (_, __, userId) => userId],
    (statuses, guid, userId) => {
      const status = statuses[`${guid}-${userId}`];
      return {
        isVideoEnabled: status?.isVideoEnabled ?? false,
        isAudioEnabled: status?.isAudioEnabled ?? false,
      };
    }
  );
}
