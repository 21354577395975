import { createSelector } from 'reselect';
import _ from 'lodash';

import { ELEMENT_TYPE, INPUT_TYPE, GAMEPLAY_ELEMENT_TYPES, LINK_TYPE } from 'constants/sheet';
import { getElementLinkName } from 'pages/SheetBuilderPage/SheetBuilderPage';

export default class SheetBuilderSelector {
  static createParameterSelector = (selector) => {
    return (_, params) => selector(params);
  };
  static getModelIdParam = this.createParameterSelector((params) => params.modelId);
  static getSectionIdParam = this.createParameterSelector((params) => params.sectionId);
  static getElementIdParam = this.createParameterSelector((params) => params.elementId);
  static getGroupIdParam = this.createParameterSelector((params) => params.groupId);

  static modelsSelector = (state) => state.sheetTemplates.models;
  static modelSelector = createSelector(
    this.modelsSelector,
    this.getModelIdParam,
    (models, modelId) => models[modelId]
  );
  static sectionSelector = createSelector(this.modelSelector, this.getSectionIdParam, (model, sectionId) =>
    model.draftData.sections.find((s) => s.id === sectionId)
  );
  static elementSelector = createSelector(
    this.sectionSelector,
    this.getElementIdParam,
    this.getGroupIdParam,
    (section, elementId, groupId) => {
      if (groupId) return section.elements.find((e) => e.id === groupId)?.items.find((o) => o.id === elementId);
      else return section.elements.find((e) => e.id === elementId);
    }
  );
  static groupSelector = createSelector(this.sectionSelector, this.getGroupIdParam, (section, groupId) => {
    if (groupId) return section.elements.find((e) => e.id === groupId);
  });

  static linkableTogglesSelector = createSelector(
    this.modelSelector,
    this.elementSelector,
    this.groupSelector,
    (model, element, group) => {
      let sections, elements;
      sections = model.draftData.sections
        .map((section) => {
          elements = _.flattenDeep(
            section.elements.map((el) => {
              if (el.elementType === ELEMENT_TYPE.group) {
                const isCollection = !!el.metadata.collection;
                if (!isCollection || (isCollection && !!group && group.id === el.id)) {
                  return el.items.map((groupEl) => {
                    if (groupEl.items.find((i) => i.inputType === INPUT_TYPE.toggle)) {
                      return groupEl;
                    }
                    return null;
                  });
                }
                return null;
              } else {
                if (el.items.find((i) => i.inputType === INPUT_TYPE.toggle)) {
                  return el;
                }
                return null;
              }
            })
          ).filter((o) => !!o && o.id !== element.id);
          if (elements.length > 0) {
            return { ...section, elements: elements };
          }
          return null;
        })
        .filter((section) => section);
      return sections;
    }
  );

  static linkNamesSelector = createSelector(this.modelSelector, (model) => {
    let linkNames = {};
    model.draftData.sections.forEach((section) => {
      section.elements.forEach((element) => {
        if (element.elementType === ELEMENT_TYPE.group) {
          element.items.forEach((groupElement) => {
            linkNames[groupElement.id] = getElementLinkName(groupElement, section);
          });
        } else {
          linkNames[element.id] = getElementLinkName(element, section);
        }
      });
    });
    return linkNames;
  });

  static linkableGameplaySelector = createSelector(
    this.modelSelector,
    this.elementSelector,
    this.groupSelector,
    (model, element, group) => {
      let sections, elements;
      sections = model.draftData.sections
        .map((section) => {
          elements = _.flattenDeep(
            section.elements.map((el) => {
              if (el.elementType === ELEMENT_TYPE.group) {
                const isCollection = !!el.metadata.collection;
                if (!isCollection || (isCollection && !!group && group.id === el.id)) {
                  return el.items.map((groupEl) => {
                    if (GAMEPLAY_ELEMENT_TYPES.includes(groupEl.elementType)) {
                      return groupEl;
                    }
                    return null;
                  });
                }
                return null;
              } else if (GAMEPLAY_ELEMENT_TYPES.includes(el.elementType)) {
                return el;
              }
              return null;
            })
          ).filter((o) => !!o && o.id !== element.id);
          if (elements.length > 0) {
            return { ...section, elements: elements };
          }
          return null;
        })
        .filter((section) => section);
      return sections;
    }
  );

  static flatLinksSelector = createSelector(this.elementSelector, (element) => {
    let flatLinks = {};
    flatLinks[LINK_TYPE.toggle] = element.links
      .filter((link) => link.inputId && link.elementId && !link.dice)
      .map((t) => t.elementId);
    flatLinks[LINK_TYPE.gameplay] = element.links
      .filter((link) => (!link.inputId && link.elementId) || link.dice)
      .map((t) => t.elementId);
    return flatLinks;
  });
}
