import styles from './modals.dashboard.module.css';

import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { SheetTemplateAction } from 'store/actions';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

export default function CreateTemplateModal({ template }) {
  const dispatch = useDispatch();
  const shareLinkRef = useRef(null);

  const onShareModalDismiss = () => dispatch(SheetTemplateAction.requestShare(false));

  const onCopyLinkClick = () => {
    const el = shareLinkRef.current?.inputRef.current;
    if (!el) return;
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  return (
    <TitleModal
      title="Share Template"
      subtitle="Share this link with others to allow them to save this template to their Template Library."
      actions={
        <>
          <Button variant="cancel" onClick={onShareModalDismiss}>
            {template.isPublished ? 'Cancel' : 'Close'}
          </Button>
          {template.isPublished && (
            <Button variant="primary" onClick={onCopyLinkClick}>
              Copy Link
            </Button>
          )}
        </>
      }
      onDismiss={() => onShareModalDismiss()}
    >
      <h4 className={`heading1 ${styles.modalName}`}>{template.name}</h4>
      <div className={styles.modalForm}>
        {template.isPublished ? (
          <label className={styles.modalInputContainer}>
            <span className={styles.modalLabel}>Share Link:</span>
            <TextInput
              ref={shareLinkRef}
              input={{
                name: 'shareLink',
                value: `${window.location.origin}/sheet-templates/${template.guidSlug}/save`,
              }}
              meta={{}}
              type="text"
              className={styles.shareLink}
            />
          </label>
        ) : (
          <p>You must publish this template first before it can be shared.</p>
        )}
      </div>
    </TitleModal>
  );
}
