// @flow
import React from 'react';

import { INPUT_TYPE } from 'constants/sheet';
import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';

import { Builder } from 'components/Sheet2/elements/Element';
import Reference from './Reference';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function ReferenceBuilder(props: Props) {
  const { colors, element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;

  const referenceInput = items.find((item) => item.inputType === INPUT_TYPE.reference);
  const referenceValue = referenceInput?.defaultValue || '';

  const onReferenceChange = (value) => {
    if (!referenceInput) return;
    const updatedItems = items.map((o) =>
      o.id === referenceInput.id ? { ...referenceInput, defaultValue: value } : o
    );
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      {referenceInput && (
        <Reference
          columns={element.columns}
          value={referenceValue}
          isBuilding={true}
          canEdit={referenceInput.canEdit}
          primaryColor={colors[0]}
          secondaryColor={colors[1]}
          onChange={onReferenceChange}
        />
      )}
    </Builder>
  );
}

const MemoizedReferenceBuilder = React.memo(ReferenceBuilder);
MemoizedReferenceBuilder.displayName = 'MemoizedReferenceBuilder';

export default MemoizedReferenceBuilder;
