//@flow
import styles from './SheetCard.module.css';

import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import history from 'browserHistory';
import type { SheetModel } from 'components/Sheet2/types';
import useWindowSize from 'hooks/useWindowSize';
import { SheetAction } from 'store/actions';
import { RoomSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import { getDate } from 'utilities';
import { linearGradient } from 'utilities/color';

import Card from './Card';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';

type Props = {
  className?: ?string,
  isViewing: boolean,
  sheet: SheetModel,
  showActions: boolean,
  showClose: boolean,
};

export default function SheetCard(props: Props) {
  const {
    className,
    isViewing,
    sheet,
    sheet: { sheetTemplateUpdated },
    showActions,
    showClose,
  } = props;

  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector((state) => SessionSelector.currentUser(state));
  const rooms = useSelector((state) => RoomSelector.getBySheet(state, sheet.id));
  const allRoomColors = useSelector((state) => {
    if (rooms?.length > 0 && currentUser) {
      return RoomUserSelector.getColorsAllRooms(rooms, currentUser.id)(state);
    } else {
      return {};
    }
  });

  const avatarColors = rooms[0] ? allRoomColors[rooms[0].guid] : ['var(--color-red)', 'var(--color-purple)'];

  const onViewClick = () => {
    if (isViewing) {
      history.push('/sheets');
    } else {
      history.push(`/sheets/${sheet.id}`);
    }
  };

  const onUpdateSheet = () => {
    dispatch(SheetAction.requestUpdate(sheet.id));
  };

  const onDeleteClick = () => {
    dispatch(SheetAction.requestRemove(sheet.id));
  };

  return (
    <Card
      className={clsx(styles.sheetCardWrapper, isViewing && styles.isViewing, !size.isMd && styles.isSmall, className)}
      forceBorder={!showActions}
      showClose={showClose}
      onDeleteClick={onDeleteClick}
    >
      <Avatar
        avatarUrl={sheet.avatarUrl ?? null}
        borderColors={avatarColors}
        className={styles.avatar}
        isDdbSheet={!!sheet.ddbId}
        shape="squircle"
        showSheetDefault
        size={size.isMd ? 140 : 100}
      />
      <div className={styles.sheetCardInfo}>
        <div className={styles.header}>
          <div className={styles.sheetInfo}>
            <h3 className={styles.name}>{sheet.name}</h3>
            {sheet.templateName && <span className={styles.templateName}>{sheet.templateName}</span>}
          </div>
          {sheet.lastPlayed && (
            <div className={styles.meta}>
              <h5 className={styles.tableDate}>{getDate(new Date(sheet.lastPlayed))}</h5>
              <span>played</span>
            </div>
          )}
          {showActions && (
            <div className={styles.tableActions}>
              {sheetTemplateUpdated && (
                <Button
                  className={styles.updateButton}
                  primaryBackground={'var(--color-white)'}
                  color={'var(--color-light-dark)'}
                  onClick={onUpdateSheet}
                  hoverColor={'var(--color-white)'}
                  hoverPrimaryBackground={'var(--color-accent-main)'}
                >
                  Update
                </Button>
              )}
              {isViewing ? (
                <Button
                  className={styles.viewButton}
                  primaryBackground={'var(--color-accent-main)'}
                  color={'var(--color-white)'}
                  onClick={onViewClick}
                >
                  Close
                </Button>
              ) : (
                <Button
                  className={styles.viewButton}
                  primaryBackground={'var(--color-white)'}
                  color={'var(--color-light-dark)'}
                  onClick={onViewClick}
                  hoverColor={'var(--color-white)'}
                  hoverPrimaryBackground={'var(--color-accent-main)'}
                >
                  View
                </Button>
              )}
            </div>
          )}
        </div>

        <div className={styles.sheetTables}>
          <h4>Tables</h4>
          {rooms && (
            <ul className={styles.tableList}>
              {rooms.map((room, index) => {
                return (
                  <li key={room.id} className={styles.tablePill} title={room.title}>
                    <Link key={room.guid} to={`/rooms/${room.guid}`} className={styles.tableLink} title={room.title}>
                      <span
                        className={styles.tablePillBefore}
                        style={{ background: linearGradient(allRoomColors[room.guid], '180deg') }}
                      />
                      {room.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </Card>
  );
}

SheetCard.defaultProps = {
  isViewing: false,
  showActions: false,
  showClose: false,
};
