// @flow
/**
 * Pulled from react-router's examples: https://v5.reactrouter.com/web/example/query-parameters
 */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
