import { mapKeys, merge } from 'lodash';

import ProductAction from 'store/actions/ProductAction';

const INITIAL_STATE = {};

export default function productReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case ProductAction.FETCH:
    case ProductAction.UPDATE:
      return { ...state, [payload.guid]: payload };

    case ProductAction.FETCH_ALL:
      return merge({}, state, mapKeys(payload, 'guid'));

    default:
      return state;
  }
}
