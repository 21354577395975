import styles from './CreateRoomForm.module.css';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { RoomAction } from 'store/actions';

import Button from 'components/buttons/Button';

interface Props {
  buttonColors?: string[];
  gameId?: string;
  roomTitle?: string;
  roomGuid: string;
  submitCallback?: () => void;
}

function UpdateRoomForm({ buttonColors, gameId, roomTitle: propRoomTitle, roomGuid, submitCallback }: Props) {
  const dispatch = useDispatch();
  const [roomTitle, setRoomTitle] = useState(propRoomTitle || '');
  const [roomPassword, setPasswordName] = useState('');
  const [titleError, setTitleError] = useState<string | null>(null);

  const onSubmit = () => {
    setTitleError(null);

    if (!roomTitle || roomTitle.trim() === '') {
      setTitleError('Required');
      return;
    }

    return dispatch(
      RoomAction.update(
        roomGuid,
        {
          title: roomTitle,
          password: roomPassword,
          game_id: gameId || null,
        },
        () => {
          if (submitCallback) submitCallback();
        }
      )
    );
  };

  return (
    <div className={styles.updateRoomForm}>
      <label className={styles.labelContainer}>
        <span className={styles.label}>Room Name</span>
        <div className={styles.inputWrapper}>
          {titleError && <span className={styles.inputError}>{titleError}</span>}
          <input
            name="title"
            value={roomTitle}
            onInput={() => setTitleError(null)}
            onChange={(e) => setRoomTitle(e.target.value)}
            type="text"
            placeholder="Your game name"
            autoComplete="off"
            data-1p-ignore
          />
        </div>
      </label>

      <label className={styles.labelContainer}>
        <span className={styles.label}>
          <strong>Password</strong> (Optional)
        </span>
        <div className={styles.inputWrapper}>
          <input
            name="password"
            value={roomPassword}
            onChange={(e) => setPasswordName(e.target.value)}
            type="password"
            placeholder="Add room password"
            autoComplete="new-password"
            data-1p-ignore
          />
        </div>
      </label>
      <Button
        className={styles.updateRoomButton}
        onClick={onSubmit}
        primaryBackground={buttonColors?.[0] ?? 'var(--color-primary-main)'}
        secondaryBackground={buttonColors?.[1] ?? 'var(--color-primary-main)'}
      >
        Launch room
      </Button>
    </div>
  );
}

export default UpdateRoomForm;
