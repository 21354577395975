//@flow
import styles from './CreateRoomForm.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorAction, RoomAction } from 'store/actions';
import { ErrorSelector, GameSelector } from 'store/selectors';
import { randomColor, randomPlaykitColor } from 'utilities';
import { logEvent } from 'utilities';

import IconTitleButton from 'components/buttons/IconTitleButton';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import PatronFeatureModal from 'modals/PatronFeatureModal';

type Props = {
  gameSlug?: string,
  submitCallback?: () => void,
};

function CreateRoomForm({ gameSlug, submitCallback }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [roomTitle, setRoomTitle] = useState('');
  const [roomPassword, setPasswordName] = useState('');
  const [titleError, setTitleError] = useState(null);

  const game = useSelector((state) => gameSlug && GameSelector.getBySlug(state, gameSlug));
  const patronError = useSelector((state) => ErrorSelector.get(state, RoomAction.CREATE))?.message.includes(
    'reached the limit'
  );

  const onSubmit = () => {
    setTitleError(null);
    setIsLoading(true);

    if (!roomTitle || roomTitle.trim() === '') {
      setTitleError('Required');
      setIsLoading(false);
      return;
    }

    let colors = {};
    let payload = {};

    if (game) {
      const firstColor = game?.colors?.length ? randomPlaykitColor(game.colors) : randomColor();
      const secondColor = game?.colors?.length ? randomPlaykitColor(game.colors, firstColor) : randomColor(firstColor);
      game?.colors?.length ? (colors.hex = [firstColor, secondColor]) : (colors.css = [firstColor, secondColor]);
      payload = { game_id: game.id, game_title: game.title };
    } else {
      const firstColor = randomColor();
      const secondColor = randomColor(firstColor);
      colors.css = [firstColor, secondColor];
    }

    logEvent('preview room - click create room', {
      'game id': game.id,
      'game title': game.title,
      'room name': roomTitle,
    });

    return dispatch(
      RoomAction.create(
        {
          room: { title: roomTitle, password: roomPassword, game_id: payload.game_id },
          colors,
          is_preview: true,
          ...payload,
        },
        (room) => {
          window.location = `${window.location.origin}/rooms/${room.guid}`;
        },
        () => {
          setIsLoading(false);
          if (submitCallback) submitCallback();
        }
      )
    );
  };

  return (
    <div className={styles.createRoomForm}>
      <label className={styles.labelContainer}>
        <span className={styles.label}>Room Name</span>
        <div className={styles.inputWrapper}>
          {titleError && <span className={styles.inputError}>{titleError}</span>}
          <input
            name="title"
            value={roomTitle}
            onInput={() => setTitleError(null)}
            onChange={(e) => setRoomTitle(e.target.value)}
            type="text"
            placeholder="Your game name"
            autoComplete="off"
            data-1p-ignore
          />
        </div>
      </label>

      <label className={styles.labelContainer}>
        <span className={styles.label}>
          <strong>Password</strong> (Optional)
        </span>
        <div className={styles.inputWrapper}>
          <input
            name="password"
            value={roomPassword}
            onChange={(e) => setPasswordName(e.target.value)}
            type="password"
            placeholder="Add room password"
            autoComplete="new-password"
            data-1p-ignore
          />
        </div>
      </label>
      <IconTitleButton className={styles.createButton} variant="flat" isLoading={isLoading} onClick={onSubmit}>
        <>
          <div className={styles.icon}>
            <PlusIcon />
          </div>
          <span>
            <span>Create new room</span>
          </span>
        </>
      </IconTitleButton>

      {patronError && (
        <PatronFeatureModal
          title="Creating multiple rooms is for patrons"
          including="creating unlimited rooms"
          onDismiss={() => dispatch(ErrorAction.remove(RoomAction.CREATE))}
        />
      )}
    </div>
  );
}

export default CreateRoomForm;
