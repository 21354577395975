// @flow
import styles from './Image.module.css';

import React, { useState } from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { ELEMENT_ALIGNMENT, INPUT_TYPE } from 'constants/sheet';

import LoadingSpinner from 'components/LoadingSpinner';
import { Builder } from 'components/Sheet2/elements/Element';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';
import { ReactComponent as ImageIcon } from 'images/icons/ImageIcon.svg';
import Image from './Image';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

function ImageBuilder(props: Props) {
  const { element, images, onAddImage, onRemoveImage, sectionId, sheetGuid } = props;
  const { id, items, metadata } = element;

  const imageInput = items.find((item) => item.inputType === INPUT_TYPE.image);
  const imageValue = imageInput?.defaultValue ?? null;
  const imageUrl = imageValue ? images[imageValue] : null;
  const alignment = metadata.alignment ?? ELEMENT_ALIGNMENT.fill;

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const onChange = (event) => {
    const image = event.currentTarget.files[0];
    if (!image) return;
    setIsUploadingImage(true);
    if (onAddImage) onAddImage(sheetGuid, sectionId, id, '', image, () => setIsUploadingImage(false));
  };

  const onRemove = () => {
    if (!imageValue) return;
    setIsUploadingImage(true);
    if (onRemoveImage) onRemoveImage(sheetGuid, sectionId, id, '', imageValue, () => setIsUploadingImage(false));
  };

  return (
    <Builder {...props}>
      <div className={styles.builderContainer}>
        <input
          onChange={onChange}
          type="file"
          accept="image/jpeg, image/jpg, image/png, image/gif"
          disabled={isUploadingImage}
          className={styles.builderInput}
        />
        {imageUrl ? (
          <>
            <Image url={imageUrl} alignment={alignment} alt={''} />
            <button className={`button-reset ${styles.trashButton}`} onClick={onRemove}>
              <TrashIcon />
            </button>
          </>
        ) : (
          <div className={styles.placeholder}>
            {!isUploadingImage && <ImageIcon className={styles.imageIcon} />}
            {isUploadingImage && <LoadingSpinner />}
          </div>
        )}
      </div>
    </Builder>
  );
}

export default ImageBuilder;
