import GeneralAction from 'store/actions/GeneralAction';

const INITIAL_STATE = {
  showPlayerSafety: false,
  showTableMenu: false,
  showUserMenu: false,
  showWelcome: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GeneralAction.SHOW_PLAYER_SAFETY:
      return { ...state, showPlayerSafety: payload };
    case GeneralAction.SHOW_TABLE_MENU:
      return { ...state, showTableMenu: payload };
    case GeneralAction.SHOW_USER_MENU:
      return { ...state, showUserMenu: payload };
    case GeneralAction.SHOW_WELCOME:
      return { ...state, showWelcome: payload };
    case GeneralAction.SHOW_AUDIO_VIDEO_MENU:
      return { ...state, showAudioVideoMenu: payload };
    default:
      return state;
  }
};
