import { useCallback, useState } from 'react';
import { LocalAudioTrack, TwilioError } from 'twilio-video';

import useVideoContext from './useVideoContext';

type ErrorCallback = (error: TwilioError) => void;

function defaultCallback(error: TwilioError) {
  console.dir(error);
}

export default function useLocalAudioToggle(handleError: ErrorCallback = defaultCallback) {
  const { room, localTracks, getLocalAudioTrack, removeLocalAudioTrack } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack | undefined;
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleAudioEnabled = useCallback(
    (forceRepublish = false) => {
      if (!isPublishing) {
        if (audioTrack) {
          const localTrackPublication = localParticipant?.unpublishTrack(audioTrack);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit('trackUnpublished', localTrackPublication);
          removeLocalAudioTrack();
        }

        if (!audioTrack || forceRepublish) {
          setIsPublishing(true);
          return getLocalAudioTrack()
            .then((track) => localParticipant?.publishTrack(track, { priority: 'standard' }))
            .catch(handleError)
            .finally(() => {
              setIsPublishing(false);
            });
        }
      }
    },
    [audioTrack, getLocalAudioTrack, handleError, isPublishing, localParticipant, removeLocalAudioTrack]
  );
  return [!!audioTrack, toggleAudioEnabled] as const;
}
