import styles from './Die.module.css';

import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ChannelEvent } from 'models/Channel';
import { DICE_COLOR_LABELS } from 'models/Color';
import RollAction from 'store/actions/RollAction';
import SessionSelector from 'store/selectors/SessionSelector';
import TableSelector from 'store/selectors/TableSelector';
import { getFateSymbol } from 'utilities/dice';

import DieIcon from './DieIcon';
import GradientBorder from 'components/GradientBorder';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

const ROLL_TIME = 1000; // ms

class Die extends Component {
  state = { displayNumber: undefined, isCrit: false };

  localHasRolled = false;
  rollTimer = null;

  componentDidUpdate() {
    const { isRolling, hasRolled } = this.props.die;
    if (isRolling && !hasRolled && !this.localHasRolled) {
      this.doRoll();
    }
  }

  componentWillUnmount() {
    this.clearTimer(this.rollTimer);
  }

  clearTimer = (timer) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };

  isD100 = () => {
    return this.props.die.sides === 100;
  };

  isFate = () => this.props.die.isFate();

  // D100 ranges from [0,99], all other dies range [1,MAX]
  randomNum = () => {
    const value = Math.random() * this.props.die.sides;
    return this.isD100() ? Math.floor(value) : Math.ceil(value);
  };

  formatNum = (value) => {
    return this.isFate() ? getFateSymbol(value) : this.isD100() && value < 10 ? `0${value}` : value;
  };

  doRoll = async () => {
    const { die } = this.props;
    if (this.localHasRolled) return;

    this.localHasRolled = true;

    const values = [...new Array(10).keys()].map(this.randomNum).concat(die.value);

    for (const value of values) {
      this.setState({ displayNumber: value });
      await new Promise((res, rej) => {
        this.rollTimer = setTimeout(res, ROLL_TIME / values.length);
      });
    }

    this.endRolling();
  };

  endRolling = () => {
    const { currentUser, die, isPrivateRoll, presenceChannel, updateDie } = this.props;
    this.localHasRolled = false;
    const updatedDie = { ...die, isRolling: false, isEnding: false, hasRolled: true };

    updateDie(updatedDie);

    if (!isPrivateRoll && presenceChannel)
      presenceChannel.trigger(ChannelEvent.DIE_ADDED, {
        userId: currentUser.id,
        die: updatedDie,
      });

    this.setState({ isCrit: die.value === die.sides });
  };

  onClick = () => {
    const { die, onDieClick, updateDie } = this.props;

    if (die.isRolling || die.isEnding) return;

    const updatedDie = {
      ...die,
      isRolling: true,
      isEnding: false,
      hasRolled: false,
      value: this.randomNum(),
    };
    updateDie(updatedDie);

    if (onDieClick) onDieClick(updatedDie);
  };

  setupRefContext = (ref) => {
    const isFate = this.props.die.isFate();
    const context = ref.current.getContext('2d');
    const size = (isFate ? 36 : 18) * window.devicePixelRatio;
    const weight = isFate ? 'normal' : 'bold';
    context.font = `${weight} ${size}px Europa`;
    context.fillStyle = '#fff';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
  };

  render() {
    const { die, onIconClick } = this.props;
    const { displayNumber } = this.state;
    const hasRolled = die.hasRolled;
    const borderColor = hasRolled ? die.color || 'rgb(var(--color-theme-text))' : 'rgb(var(--color-theme-text) / 0.5)';

    const dieLabel = `${die.color ? DICE_COLOR_LABELS[die.color] + ' ' : ''}${die.name}`;

    return (
      <div className={styles.container} title={dieLabel}>
        <GradientBorder
          aria-label={`Roll ${dieLabel}`}
          role="button"
          onClick={this.onClick}
          size={54}
          color={borderColor}
          className={`${styles.numContainer} ${hasRolled ? styles.hasRolled : ''}`}
        >
          <span className={die.isFate() ? styles.isFate : ''}>{this.formatNum(displayNumber)}</span>
        </GradientBorder>
        <button
          aria-label={`Remove ${dieLabel}`}
          onClick={() => onIconClick(die)}
          className={clsx('button-reset', styles.icon, hasRolled && styles.hasRolled)}
          style={{ '--die-color': die.color || undefined }}
        >
          <DieIcon className={styles.dieIcon} die={die} />
          <XIcon className={styles.removeIcon} />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: SessionSelector.currentUser(state),
    isPrivateRoll: TableSelector.isPrivateRoll(state),
    presenceChannel: TableSelector.getPresenceChannel(state),
  };
};

const mapDispatchToProps = {
  updateDie: RollAction.updateDie,
};

export default connect(mapStateToProps, mapDispatchToProps)(Die);
