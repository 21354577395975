import styles from './dashboard.module.css';

import Bugsnag from '@bugsnag/js';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SHOPIFY_COOKIE_NAME_KEY, SHOPIFY_STORE_SHOP_KEY } from 'constants/shopify';
import useQuery from 'hooks/useQuery';
import { OrganizationAction } from 'store/actions';
import { OrganizationSelector, SessionSelector } from 'store/selectors';

import Button from 'components/buttons/Button';
import DashboardLayout from 'components/layouts/DashboardLayout';
import PageLoading from 'components/PageLoading';
import TitlePanel from 'components/TitlePanel';

export default function ShopifyCallbackPage() {
  const history = useHistory();
  const query = useQuery();
  const isAuthed = useSelector(SessionSelector.isAuthed);
  const currentUser = useSelector(SessionSelector.currentUser);
  const org = useSelector((state) =>
    // @ts-ignore OrganizationSelector not typed yet
    currentUser?.organizationIds.length > 0 ? OrganizationSelector.getById(state, currentUser.organizationIds[0]) : null
  );
  const [shopifyStoreShop, setShopifyStoreShop] = useState(Cookies.get(SHOPIFY_STORE_SHOP_KEY));

  //
  // Installing shopify app whiled unauthed.
  //
  useEffect(() => {
    if (isAuthed) return;

    //
    // Installing shopify app directly from shopify.
    // We send shop to our API and save in cookies to reference later.
    //
    if (!query.get('code') && query.get('shop')) {
      OrganizationAction.shopifySave(query.get('shop')).then(({ auth_route, cookie, shopify_store }) => {
        const date = new Date(cookie.expires);
        Cookies.set(SHOPIFY_COOKIE_NAME_KEY, cookie.name, { expires: date, secure: true });
        Cookies.set(cookie.name, cookie.value, { expires: date, secure: true });
        window.location.href = auth_route;
      });
      return;
    }

    //
    // We are coming back from the Shopify OAuth flow.
    // Save shop to cookies to reference later.
    //
    if (!shopifyStoreShop && query.get('code')) {
      const cookieName = Cookies.get(SHOPIFY_COOKIE_NAME_KEY);
      const cookies = {};
      // @ts-ignore
      cookies[cookieName] = Cookies.get(cookieName);
      const auth_query = {};
      for (const [k, v] of query.entries()) {
        // @ts-ignore
        auth_query[k] = v;
      }

      OrganizationAction.shopifyAuth({ cookies, auth_query })
        .then(({ shopifyStore }) => {
          Cookies.set(SHOPIFY_STORE_SHOP_KEY, shopifyStore.shop);
          setShopifyStoreShop(shopifyStore.shop);
          history.replace('/partners/shopify/callback');
        })
        .catch((error) => {
          Bugsnag.notify(error);
          window.location.href = `${window.location.origin}/dashboard`;
        });
      return;
    }
  }, [history, isAuthed, query, shopifyStoreShop]);

  //
  // Installing shopify app whiled authed.
  //
  useEffect(() => {
    if (!isAuthed || !org) return;

    //
    // We are coming back from the Shopify OAuth flow.
    //
    if (org.shopifyShop && query.get('code')) {
      const cookieName = Cookies.get(SHOPIFY_COOKIE_NAME_KEY);
      const cookies = {};
      // @ts-ignore
      cookies[cookieName] = Cookies.get(cookieName);
      const auth_query = {};
      for (const [k, v] of query.entries()) {
        // @ts-ignore
        auth_query[k] = v;
      }
      OrganizationAction.shopifyAuth({ cookies, auth_query })
        .then(({ redirect_url }) => {
          Cookies.remove(SHOPIFY_STORE_SHOP_KEY);
          window.location.href = redirect_url;
        })
        .catch((error) => {
          Bugsnag.notify(error);
          window.location.href = `${window.location.origin}/dashboard`;
        });
      return;
    }

    //
    // We have been installed on a Shopify store and need to assign
    // the user's org as the owner of the the store.
    //
    // OR
    //
    // We are installing our Shopify app from the partners dashboard.
    //
    const shop = query.get('shop') || shopifyStoreShop;
    if (shop) {
      OrganizationAction.shopifyLogin(org.slug, shop).then(({ auth_route, cookie }) => {
        const date = new Date(cookie.expires);
        Cookies.set(SHOPIFY_COOKIE_NAME_KEY, cookie.name, { expires: date, secure: true });
        Cookies.set(cookie.name, cookie.value, { expires: date, secure: true });
        Cookies.remove(SHOPIFY_STORE_SHOP_KEY);
        window.location.href = auth_route;
      });
      return;
    }

    //
    // We are all set, send them to the org page.
    //
    Cookies.remove(SHOPIFY_STORE_SHOP_KEY);
    window.location.href = `${window.location.origin}/partners/${org.slug}`;
  }, [isAuthed, org, query, shopifyStoreShop]);

  return shopifyStoreShop ? (
    <DashboardLayout>
      <div className={styles.content}>
        <TitlePanel title="Shopify App" subtitle="Install successful!">
          <div className={styles.shopifyInstallSuccess}>
            <p>
              You have successfully installed the Role Shopify App on your shop: <strong>{shopifyStoreShop}</strong>
            </p>
            <p>Login to connect your Shop products to your Role products</p>
            <Button to="/login?to=%2Fpartners%2Fshopify%2Fcallback" variant="primary" isLinkAsButton>
              Login
            </Button>
          </div>
        </TitlePanel>
      </div>
    </DashboardLayout>
  ) : (
    <PageLoading />
  );
}
