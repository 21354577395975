//@flow
import styles from 'components/layouts/SessionPage.module.css';

import React from 'react';
import { Link } from 'react-router-dom';

import type { location } from 'types/common';
import { logEvent } from 'utilities';

import requireNoAuth from 'hocs/requireNoAuth';

import AuthForm from 'components/AuthForm/AuthForm';
import SessionPage from 'components/layouts/SessionPage';
import Metatags from 'components/Metatags';

type Props = {
  handleSubmit: (a: any) => void,
  location: location,
};

function SignInPage(props: Props) {
  const {
    location: { search },
  } = props;

  const redirectTo = new URLSearchParams(search).get('to');
  let signupLink = '/signup';
  if (redirectTo) signupLink += `?to=${encodeURIComponent(redirectTo)}`;

  return (
    <>
      <Metatags title="Log In" />

      <SessionPage title="Role Log In">
        <div className={styles.signUpHeader}>
          <h1>Log in to Role</h1>
          <span>Welcome back. Speak friend and enter.</span>
        </div>

        <AuthForm isLogIn={true} showTabs={false} />
        <div className={styles.footerLink}>
          <Link to={signupLink} onClick={() => logEvent('onboarding - click signup')}>
            Don't have an account? <span className={styles.highlight}>Create one for FREE!</span>
          </Link>
        </div>
      </SessionPage>
    </>
  );
}

export default requireNoAuth(SignInPage);
