// @flow
import styles from './inputs.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';

type Props = {
  children?: Node,
  className?: ?string,
  onBlur?: (string) => void,
  onChange: (string) => void,
  isDisabled?: boolean,
  meta?: { touched: boolean, error: boolean },
  options?:
    | string[]
    | {
        [string]: {
          value: string,
          disabled: boolean,
        },
      },
  showError?: boolean,
  size: 'default' | 'small',
  value: string,
  variant: 'light' | 'theme' | 'dark',
  input?: Object,
};

function DropdownInput({
  className,
  onBlur: propOnBlur,
  onChange: propOnChange,
  isDisabled,
  meta,
  options,
  showError,
  size,
  value,
  variant,
  children,
  input,
}: Props): Node {
  const hasError = showError && meta?.error && meta?.touched;
  const onChange = input?.onChange || propOnChange;
  const onBlur = input?.onBlur || propOnBlur;

  return (
    <span
      className={clsx(styles.dropdownInput, styles[size], styles[variant], className, hasError && styles.isInvalid)}
    >
      <select
        value={input?.value || value}
        onChange={(e) => (input ? onChange(e) : onChange(e.target.value))}
        onBlur={onBlur}
        disabled={isDisabled}
        className={styles.select}
      >
        {children
          ? children
          : Array.isArray(options)
          ? options.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))
          : Object.keys(options).map((key, index) => (
              <option key={index} value={key} disabled={options[key].disabled}>
                {options[key].value}
              </option>
            ))}
      </select>
      <SingleArrowIcon className={styles.selectArrowIcon} />
    </span>
  );
}

DropdownInput.defaultProps = {
  size: 'default',
  variant: 'light',
};

export default DropdownInput;
