import { batch } from 'react-redux';

import { createAction, handleError } from './utilities';
import SessionSelector from 'store/selectors/SessionSelector';

import DocumentAction, { extractDocument } from './DocumentAction';
import GameAction from './GameAction';
import role from 'apis/role';
import ProductAction from './ProductAction';

export function processRoomDocumentsResponse({ data, included }) {
  if (Array.isArray(data)) {
    const roomDocuments = data.map(({ id, attributes }) => ({ id, ...attributes }));
    const documents = [];
    const games = [];
    const products = [];

    for (const include of included) {
      const { id, attributes, type } = include;
      if (type === 'game') games.push({ id, ...attributes });
      else if (type === 'product') products.push({ id, ...attributes });
      else if (type === 'document') documents.push(extractDocument(include));
    }

    return { roomDocuments, documents, games, products };
  }

  const roomDocument = { id: data.id, ...data.attributes };
  let document = null;
  let games = [];
  let products = [];

  for (const include of included) {
    const { id, attributes, type } = include;
    if (type === 'game') games.push({ id, ...attributes });
    else if (type === 'product') products.push({ id, ...attributes });
    else if (type === 'document') document = extractDocument(include);
  }

  return { roomDocument, document, games, products };
}

export default class RoomDocumentAction {
  static CREATE = 'RoomDocumentAction.CREATE';
  static DELETE = 'RoomDocumentAction.DELETE';
  static REMOVE = 'RoomDocumentAction.REMOVE';
  static FETCH = 'RoomDocumentAction.FETCH';
  static FETCH_ALL = 'RoomDocumentAction.FETCH_ALL';
  static UPDATE = 'RoomDocumentAction.UPDATE';
  static UPDATE_LINE = 'RoomDocumentAction.UPDATE_LINE';
  static UPDATE_TOKEN = 'RoomDocumentAction.UPDATE_TOKEN';

  static create = (guid, id, callback) => async (dispatch, getState) => {
    const data = { room_document_id: id, socket_id: SessionSelector.getSocketId(getState()) };
    return role.post(`/rooms/${guid}/room_documents`, data).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomDocumentAction.CREATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.CREATE, response)
    );
  };

  static delete = (guid, id, ownerId, callback) => async (dispatch, getState) => {
    const data = { socket_id: SessionSelector.getSocketId(getState()), user_id: ownerId };
    return role.delete(`/rooms/${guid}/room_documents/${id}`, { data }).then(
      (response) => {
        const obj = { guidId: `${guid}-${id}-${ownerId || ''}` };
        dispatch(createAction(RoomDocumentAction.DELETE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.DELETE, response)
    );
  };

  static remove = (guid, id, ownerId) => {
    return createAction(RoomDocumentAction.REMOVE, { guidId: `${guid}-${id}-${ownerId || ''}` });
  };

  static fetch = (guid, id, ownerId, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/room_documents/${id}`, { params: { user_id: ownerId } }).then(
      (response) => {
        const { roomDocument, document, games, products } = processRoomDocumentsResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomDocumentAction.FETCH, roomDocument));
          dispatch(createAction(DocumentAction.FETCH, document));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
        });
        callback?.({ roomDocument, document, games });
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.FETCH, response)
    );
  };

  static fetchAll = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/room_documents`).then(
      (response) => {
        const { roomDocuments, documents, games, products } = processRoomDocumentsResponse(response.data);
        batch(() => {
          dispatch(createAction(RoomDocumentAction.FETCH_ALL, roomDocuments));
          dispatch(createAction(DocumentAction.FETCH_ALL, documents));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
        });
        callback?.({ roomDocuments, documents, games });
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.FETCH_ALL, response)
    );
  };

  static update = (guid, id, formValues, callback) => async (dispatch, getState) => {
    const data = { ...formValues, socket_id: SessionSelector.getSocketId(getState()) };
    return role.patch(`/rooms/${guid}/room_documents/${id}`, data).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomDocumentAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.UPDATE, response)
    );
  };

  static updateLine = (guid, id, formValues, callback) => async (dispatch, getState) => {
    const data = { ...formValues, socket_id: SessionSelector.getSocketId(getState()) };
    return role.put(`/rooms/${guid}/room_documents/${id}/update_line`, data).then(
      (response) => {
        const {
          room_document: { line_action, line },
        } = formValues;
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(
          createAction(RoomDocumentAction.UPDATE_LINE, {
            lineAction: line_action,
            line,
            roomDocument: obj,
          })
        );
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.UPDATE_LINE, response)
    );
  };

  static updateToken = (guid, id, formValues, callback) => async (dispatch, getState) => {
    const data = { ...formValues, socket_id: SessionSelector.getSocketId(getState()) };
    return role.put(`/rooms/${guid}/room_documents/${id}/update_token`, data).then(
      (response) => {
        const {
          room_document: { token_action, token },
        } = formValues;
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(
          createAction(RoomDocumentAction.UPDATE_TOKEN, {
            tokenAction: token_action,
            token,
            roomDocument: obj,
          })
        );
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomDocumentAction.UPDATE_TOKEN, response)
    );
  };
}
