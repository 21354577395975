import { batch } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { createAction, handleError } from './utilities';
import SessionSelector from 'store/selectors/SessionSelector';

import GameAction from './GameAction';
import ProductAction from './ProductAction';
import role from 'apis/role';

export function processDocumentsResponse({ data, included }) {
  const documents = data.map(extractDocument);
  const games = [];
  const products = [];

  for (const { id, type, attributes } of included) {
    const obj = { id, ...attributes };

    if (type === 'game') games.push(obj);
    if (type === 'product') products.push(obj);
  }

  return { documents, games, products };
}

export const extractDocument = ({ id, attributes, relationships: { games, playkits, products } }) => {
  const playkitIds = playkits.data.map((o) => o.id);
  const productIds = products.data.map((o) => o.id);
  const gameIds = games.data.map((o) => o.id);
  return { id, ...attributes, playkitIds, productIds, gameIds };
};

export default class DocumentAction {
  static CREATE = 'DocumentAction.CREATE';
  static DELETE = 'DocumentAction.DELETE';
  static FETCH = 'DocumentAction.FETCH';
  static FETCH_ALL = 'DocumentAction.FETCH_ALL';
  static REMOVE = 'DocumentAction.REMOVE';
  static UPDATE = 'DocumentAction.UPDATE';

  static create =
    (formValues, callback = null) =>
    async (dispatch) => {
      const formData = new FormData();
      if (formValues.name) formData.append('document[name]', formValues.name);
      if (formValues.file && formValues.file[0]) formData.append('document[file]', formValues.file[0]);
      if (formValues.path) formData.append('document[path]', formValues.path);
      if (formValues.doc_type) formData.append('document[doc_type]', formValues.doc_type);

      return role.post('/documents', formData).then(
        (response) => {
          const obj = extractDocument(response.data.data);
          dispatch(createAction(DocumentAction.CREATE, obj));
          callback?.(obj);
        },
        ({ response }) => {
          handleError(dispatch, DocumentAction.CREATE, response);
          if (response.data.message) throw new SubmissionError({ file: response.data.message });
          else throw new SubmissionError({ file: 'Error uploading file' });
        }
      );
    };

  static createJson =
    (formValues, callback = null) =>
    async (dispatch) => {
      return role.post('/documents', formValues).then(
        (response) => {
          const obj = extractDocument(response.data.data);
          dispatch(createAction(DocumentAction.CREATE, obj));
          callback?.(obj);
        },
        ({ response }) => handleError(dispatch, DocumentAction.CREATE, response)
      );
    };

  static delete = (id, callback) => async (dispatch) => {
    return role.delete(`/documents/${id}`).then(
      (response) => {
        dispatch(createAction(DocumentAction.DELETE, id));
        if (callback) callback(id);
      },
      ({ response }) => handleError(dispatch, DocumentAction.DELETE, response)
    );
  };

  static fetch = (id, params, callback) => async (dispatch) => {
    return role.get(`/documents/${id}`, { params }).then(
      (response) => {
        const { documents, games, products } = processDocumentsResponse({
          data: [response.data.data],
          included: response.data.included,
        });
        batch(() => {
          dispatch(createAction(DocumentAction.FETCH, documents[0]));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
        });
        callback?.(documents[0]);
      },
      ({ response }) => handleError(dispatch, DocumentAction.FETCH, response)
    );
  };

  static fetchAll = (params, callback) => async (dispatch) => {
    return role.get('/documents', { params }).then(
      (response) => {
        const { documents, games, products } = processDocumentsResponse(response.data);
        batch(() => {
          dispatch(createAction(DocumentAction.FETCH_ALL, documents));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(ProductAction.FETCH_ALL, products));
        });
        callback?.(documents);
      },
      ({ response }) => handleError(dispatch, DocumentAction.FETCH_ALL, response)
    );
  };

  static remove = (id) => {
    return createAction(DocumentAction.REMOVE, id);
  };

  static update = (id, formValues, callback) => async (dispatch, getState) => {
    return role.patch(`/documents/${id}`, { ...formValues, socket_id: SessionSelector.getSocketId(getState()) }).then(
      (response) => {
        const obj = extractDocument(response.data.data);
        dispatch(createAction(DocumentAction.UPDATE, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, DocumentAction.UPDATE, response)
    );
  };
}
