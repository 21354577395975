import styles from './TableChat.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';

import { CHAT_PAGE_SIZE, DICE_LOG_TOKEN } from 'models/Twilio';
import { TwilioAction } from 'store/actions';
import { RoomUserSelector, SessionSelector, TableSelector, TwilioSelector } from 'store/selectors';

import DiceMessage from './DiceMessage';
import Message from './Message';

function ColorMessage({ user, sid, message, guid, isSelf, isRoll }) {
  const colors = useSelector((state) => RoomUserSelector.getColors(state, guid, user.userId));

  if (isRoll) return <DiceMessage key={sid} message={message} user={user} isSelf={isSelf} colors={colors} />;
  else return <Message key={sid} message={message} user={user} isSelf={isSelf} colors={colors} />;
}

class Conversation extends Component {
  state = { isFetching: false };
  bottomRef = React.createRef();
  containerRef = React.createRef();

  componentDidMount() {
    this.setupConversation(this.props.conversation);
  }

  componentDidUpdate(prevProps) {
    const { conversation } = this.props;

    if (prevProps.conversation.sid !== conversation.sid) {
      this.teardownConversation(prevProps.conversation);
      this.setState({ isFetching: false });
      this.setupConversation(conversation);
    }
  }

  componentWillUnmount() {
    this.teardownConversation(this.props.conversation);
  }

  finishConversationSetup = (conversation) => {
    const { updateUnreadCount } = this.props;
    if (this.containerRef.current) this.containerRef.current.addEventListener('scroll', this.throttledOnScroll);
    conversation.on('messageAdded', this.onMessageAdded);
    if (this.props.isShowingChat) updateUnreadCount(conversation.sid, 0);
    this.scrollToBottom();
  };

  setupConversation = (conversation) => {
    const { isFetching } = this.state;
    const { paginator, updateMessages, updatePaginator } = this.props;

    if (paginator) {
      this.finishConversationSetup(conversation);
    } else if (!isFetching) {
      this.setState({ isFetching: true }, () => {
        conversation.getMessages(CHAT_PAGE_SIZE).then((paginator) => {
          const { sid } = conversation;
          const messages = paginator.items;
          updatePaginator(sid, paginator);
          updateMessages(sid, messages);
          if (conversation.lastMessage) conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
          this.setState({ isFetching: false });
          this.finishConversationSetup(conversation);
        });
      });
    }
  };

  teardownConversation = (conversation) => {
    if (this.containerRef.current) this.containerRef.current.removeEventListener('scroll', this.throttledOnScroll);
    conversation.off('messageAdded', this.onMessageAdded);
    if (conversation.lastMessage) conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
  };

  scrollToBottom = (behavior = 'auto') => {
    if (this.bottomRef.current && this.containerRef.current) {
      const top = this.bottomRef.current.offsetTop;
      this.containerRef.current.scroll({ top: top, behavior });
    }
  };

  onMessageAdded = (message) => {
    const { isShowingChat, selectedConversation } = this.props;
    const { conversation } = message;
    if (isShowingChat && selectedConversation.sid === conversation.sid) {
      conversation.updateLastReadMessageIndex(message.index);
      this.scrollToBottom('smooth');
    }
  };

  onScroll = () => {
    const { isFetching } = this.state;
    const { conversation, messages, paginator } = this.props;
    const { updateMessages, updatePaginator } = this.props;
    const scrollTop = this.containerRef.current.scrollTop;

    if (scrollTop <= 0 && paginator && paginator.hasPrevPage && !isFetching) {
      this.setState({ isFetching: true }, () => {
        const currentHeight = this.containerRef.current.scrollHeight;
        paginator.prevPage().then((newPaginator) => {
          const { sid } = conversation;
          const prevMessages = newPaginator.items;
          updatePaginator(sid, newPaginator);
          updateMessages(sid, [...prevMessages, ...messages]);
          this.containerRef.current.scroll({ top: this.containerRef.current.scrollHeight - currentHeight });
          this.setState({ isFetching: false });
        });
      });
    }
  };
  throttledOnScroll = _.throttle(this.onScroll, 50);

  renderMessage = (message) => {
    const { currentUser, users, guid } = this.props;
    const { author, body, sid } = message;
    const user = users.find((o) => o.userId === author);

    if (!user) return null;

    const isSelf = user.userId === currentUser.id;
    const isRoll = body.includes(DICE_LOG_TOKEN);

    return <ColorMessage key={sid} {...{ user, sid, message, guid, isSelf, isRoll }} />;
  };

  render() {
    return (
      <div ref={this.containerRef} className={clsx('scrollbars-dark', styles.conversationContainer)}>
        <div className={styles.conversation}>{this.props.messages.map((message) => this.renderMessage(message))}</div>
        <div ref={this.bottomRef} className={styles.conversationBottom} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { conversation, room } = props;
  const { sid } = conversation;
  const { guid } = room;

  return {
    guid,
    currentUser: SessionSelector.currentUser(state),
    isShowingChat: TableSelector.isShowingChat(state),
    messages: TwilioSelector.getMessages(state, sid),
    paginator: TwilioSelector.getPaginator(state, sid),
    selectedConversation: TwilioSelector.getSelectedConversation(state, guid),
    users: RoomUserSelector.getAllByRoom(guid)(state),
  };
};

const mapDispatchToProps = {
  addMessage: TwilioAction.addMessage,
  updateMessages: TwilioAction.updateMessages,
  updatePaginator: TwilioAction.updatePaginator,
  updateUnreadCount: TwilioAction.updateUnreadCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
