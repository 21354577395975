import _ from 'lodash';

import { PlaykitAction, RoomAction } from 'store/actions';

const INITIAL_STATE = {};

const playkitReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case PlaykitAction.FETCH_ALL:
    case RoomAction.FETCH_PLAYKITS:
      return { ...state, ..._.mapKeys(payload, 'id') };

    case PlaykitAction.FETCH:
      return { ...state, [payload.id]: payload };

    default:
      return state;
  }
};

export default playkitReducer;
