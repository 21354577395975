import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import usePrevious from 'hooks/usePrevious';
import { ChannelEvent } from 'models/Channel';
import Die, { DieType } from 'models/Die';
import { RollAction } from 'store/actions';
import { RollSelector, SessionSelector, TableSelector } from 'store/selectors';

export default function useModifierLinks(sheetId: string, modifier?: any, links: any) {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const isPrivateRoll = useSelector(TableSelector.isPrivateRoll);
  const modifiers = useSelector(RollSelector.getModifiers);
  const prevIsSelected = usePrevious(modifier?.isSelected);
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);

  const [selectedLinkIds, setSelectedLinkIds] = useState<string[]>([]);

  const updateLinks = useCallback(() => {
    links
      .filter((o: any) => !!o.elementId && !!!o.inputId)
      .forEach(({ elementId }: any) => {
        const linkModifier = modifiers.find((o) => o.sheetId === sheetId && o.inputId === elementId);
        if (linkModifier && !linkModifier.isSelected) {
          dispatch(RollAction.updateModifier({ ...linkModifier, isSelected: true }));
          setSelectedLinkIds((ids) => ids.concat(linkModifier.id));
        }
      });
  }, [dispatch, links, modifiers, sheetId]);

  const dice = useSelector(RollSelector.getDice);
  const shouldClearAfterRoll = useSelector(TableSelector.shouldClearAfterRoll);
  const [rolledDiceIds, setRolledDiceIds] = useState<string[]>([]);

  const removeDie = useCallback(
    (die: any) => {
      dispatch(RollAction.removeDie(die.id));
      setRolledDiceIds((dice) => dice.filter((id) => id !== die.id));
      if (presenceChannel && currentUser)
        presenceChannel.trigger(ChannelEvent.DIE_REMOVED, {
          userId: currentUser.id,
          dieId: die.id,
        });
    },
    [currentUser, dispatch, presenceChannel]
  );

  const removeModifier = useCallback(
    (modifier: any) => {
      dispatch(RollAction.updateModifier({ ...modifier, isSelected: false }));
      if (presenceChannel && currentUser)
        presenceChannel.trigger(ChannelEvent.MODIFIER_REMOVED, {
          userId: currentUser.id,
          modifierId: modifier.id,
        });
    },
    [currentUser, dispatch, presenceChannel]
  );

  const updateDice = useCallback(() => {
    links
      .filter((o: any) => !!o.dice)
      .forEach(({ dice: { sides, type, count, color } }: any) => {
        const dieTemplate = new Die({ sides: parseInt(sides), type: type || DieType.NUMBER });

        const shouldClear = shouldClearAfterRoll && dice.length > 0 && dice.every((die) => die.hasRolled);
        if (shouldClear) {
          dice.forEach(removeDie);
          modifiers.filter((mod) => modifier?.id !== mod.id).forEach(removeModifier);
        }

        _.times(parseInt(count), () => {
          const die = _.clone(dieTemplate);
          die.id = uuid();
          die.color = color;
          setRolledDiceIds((dice) => dice.concat(die.id));
          dispatch(RollAction.addDie(die));
          if (!isPrivateRoll && presenceChannel && currentUser) {
            presenceChannel.trigger(ChannelEvent.DIE_ADDED, {
              userId: currentUser.id,
              die,
            });
          }
        });
      });
  }, [
    currentUser,
    dice,
    dispatch,
    isPrivateRoll,
    links,
    modifier?.id,
    modifiers,
    presenceChannel,
    removeDie,
    removeModifier,
    shouldClearAfterRoll,
  ]);

  useEffect(() => {
    if (modifier) {
      if (modifier.isSelected && !prevIsSelected) {
        updateDice();
        updateLinks();
      } else if (!modifier.isSelected && prevIsSelected) {
        for (const die of dice) {
          if (rolledDiceIds.includes(die.id)) removeDie(die);
        }
        for (const modifier of modifiers) {
          if (selectedLinkIds.includes(modifier.id)) {
            dispatch(RollAction.updateModifier({ ...modifier, isSelected: false }));
          }
        }
      }
    }
  }, [
    dice,
    dispatch,
    modifier,
    modifiers,
    prevIsSelected,
    removeDie,
    rolledDiceIds,
    selectedLinkIds,
    updateDice,
    updateLinks,
  ]);
}
