import { useCallback, useState } from 'react';
import Video, { ConnectOptions, Room } from 'twilio-video';

import useLatest from 'hooks/useLatest';

export default function useRoom(options: ConnectOptions) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const optionsRef = useLatest(options);

  const connect = useCallback((token: string) => {
    setIsConnecting(true);
    return Video.connect(token, { ...optionsRef.current }).then(
      (newRoom) => {
        setRoom(newRoom);

        const disconnect = () => newRoom.disconnect();

        // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
        // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
        newRoom.setMaxListeners(16);

        newRoom.once('disconnected', () => {
          // Reset the room only after all other `disconnected` listeners have been called.
          setTimeout(() => setRoom(null));
          window.removeEventListener('beforeunload', disconnect);
        });

        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener('beforeunload', disconnect);

        setIsConnecting(false);
      },
      (error) => {
        console.error(error);
        setIsConnecting(false);
      }
    );
  }, []);

  return { room, isConnecting, connect };
}
