// @flow
import styles from './TitlePanel.module.css';

import type { Node } from 'react';
import React from 'react';

type Props = {
  children?: ?Node,
  headerChildren?: ?Node,
  subtitle?: ?string,
  title?: ?string,
};

function TitlePanel(props: Props): Node {
  const { children, headerChildren, subtitle, title } = props;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={`${styles.headerSection} ${styles.headerTitle}`}>
          {title && <h3 className={styles.title}>{title}</h3>}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {headerChildren && <div className={styles.headerSection}>{headerChildren}</div>}
      </header>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default TitlePanel;
