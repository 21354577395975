//@flow
import styles from './UpdateSheetModal.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SheetModel } from 'components/Sheet2/types';
import SheetAction from 'store/actions/SheetAction';
import SheetTemplateSelector from 'store/selectors/SheetTemplateSelector';
import { pluralize } from 'utilities';

import Button from 'components/buttons/Button';
import { TitleModal } from 'components/Modal';

type Props = {
  onDismiss: () => void,
  sheet: SheetModel,
};

export default function UpdateSheetModal(props: Props) {
  const {
    onDismiss,
    sheet: { id, sheetTemplateChanges, sheetTemplateGuidSlug },
  } = props;

  const dispatch = useDispatch();
  const template = useSelector((state) => SheetTemplateSelector.get(state, sheetTemplateGuidSlug));
  const [isLoading, setIsLoading] = useState(false);
  const hasAnyChanges = Object.entries(sheetTemplateChanges).reduce((total, item) => total + item[1], 0) > 0;

  const onUpdateClick = () => {
    setIsLoading(true);
    dispatch(
      SheetAction.updateTemplate(id, () => {
        dispatch(SheetAction.fetchAll());
        setIsLoading(false);
        onDismiss();
      })
    );
  };

  const renderChanges = (key, title) => {
    const hasChanges = Object.values(sheetTemplateChanges[key]).reduce((total, val) => total + val, 0) > 0;
    if (!hasChanges) return;
    return (
      <ul className={`unstyled ${styles.updateModalList}`}>
        {sheetTemplateChanges[key].added > 0 && (
          <li>{`${sheetTemplateChanges[key].added} ${pluralize(sheetTemplateChanges[key].added, title)} Added`}</li>
        )}
        {sheetTemplateChanges[key].removed > 0 && (
          <li>{`${sheetTemplateChanges[key].removed} ${pluralize(
            sheetTemplateChanges[key].removed,
            title
          )} Removed`}</li>
        )}
        {sheetTemplateChanges[key].updated > 0 && (
          <li>{`${sheetTemplateChanges[key].updated} ${pluralize(
            sheetTemplateChanges[key].updated,
            title
          )} Updated`}</li>
        )}
      </ul>
    );
  };

  return (
    <TitleModal
      title="Update this Sheet?"
      subtitle={
        <>
          <span className={styles.highlight}>{template?.ownerName || "This template's creator"}</span> has updated this
          Sheet Template! You can upgrade to the latest version now. Don’t worry, you won’t lose any information in the
          process.
        </>
      }
      actions={
        <>
          <Button variant="cancel" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" isLoading={isLoading} onClick={onUpdateClick}>
            Update
          </Button>
        </>
      }
      onDismiss={onDismiss}
    >
      {renderChanges('sections', 'Section')}
      {renderChanges('elements', 'Element')}
      {renderChanges('inputs', 'Input')}
      {!hasAnyChanges && <p>There are some cosmetic changes, nothing was added or removed.</p>}
    </TitleModal>
  );
}
