// @flow
import styles from './Section.module.css';

import type { Node } from 'react';
import React, { useEffect, useState } from 'react';

import type { OnMoveSection } from 'components/Sheet2/types';

import { ArrowButton } from 'components/buttons/IconButton';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';

type SectionHeadingComponentProps = {
  children?: Node,
  className?: ?string,
  isCollapsed: boolean,
  isDraft?: boolean,
  isEditing?: boolean,
  isLastSection: boolean,
  index?: number,
  readOnly: boolean,
  onCollapseChange?: () => void,
  onMoveSection: OnMoveSection,
  onRemove?: (string) => void,
};

type SectionComponentProps = {
  id: string,
  index: number,
  children: Node,
  isCollapsed: boolean,
  isDraft: boolean,
  isLastSection: boolean,
  onCollapseChange: (string, boolean) => void,
  onMoveSection: ?OnMoveSection,
  readOnly: boolean,
  refs: any[],
  title: string,
};

const SectionHeading = (props: SectionHeadingComponentProps) => {
  const {
    children,
    className,
    isCollapsed,
    isDraft,
    isLastSection,
    index,
    isEditing,
    onCollapseChange,
    onMoveSection,
    onRemove,
    readOnly,
  } = props;

  const classNames = [styles.heading];
  if (className) classNames.push(className);
  if (!isDraft && isCollapsed) classNames.push(styles.isCollapsed);

  const showArrows = typeof index !== 'undefined' && onMoveSection && !readOnly;

  const handleMoveSection = (direction: string) => {
    if (typeof index === 'undefined') return;
    const destinationIndex = direction === 'up' ? index - 1 : index + 1;
    onMoveSection(index, destinationIndex);
  };

  return (
    <div className={classNames.join(' ')}>
      {showArrows && (
        <div className={styles.sectionArrows}>
          <ArrowButton
            color="rgb(255 255 255 / 0.3)"
            disabled={index === 0}
            onClick={() => handleMoveSection('up')}
            direction={'up'}
          />
          <ArrowButton
            color="rgb(255 255 255 / 0.3)"
            disabled={isLastSection}
            onClick={() => handleMoveSection('down')}
            direction={'down'}
          />
        </div>
      )}
      {!isEditing && (
        <div className={styles.headerActions}>
          {!isDraft && onCollapseChange && (
            <button className={`button-reset ${styles.collapseButton}`} onClick={onCollapseChange}>
              {isCollapsed ? <NotVisibleIcon /> : <VisibleIcon />}
            </button>
          )}
          {onRemove && (
            <button className={`button-reset ${styles.deleteButton}`} onClick={onRemove}>
              <TrashIcon />
            </button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

SectionHeading.defaultProps = {
  isCollapsed: false,
  readOnly: false,
};

function Section(props: SectionComponentProps): Node {
  const {
    id,
    index,
    children,
    isCollapsed: propIsCollapsed,
    isDraft,
    isLastSection,
    onCollapseChange: propOnCollapseChange,
    onMoveSection,
    readOnly,
    refs,
    title,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(propIsCollapsed);

  useEffect(() => {
    if (!readOnly) setIsCollapsed(propIsCollapsed);
  }, [propIsCollapsed, readOnly]);

  const classNames = [];
  if (!isDraft && isCollapsed) classNames.push(styles.isCollapsed);

  const onCollapseChange = (id, isCollapsed) => {
    if (readOnly) setIsCollapsed(isCollapsed);
    else propOnCollapseChange(id, isCollapsed);
  };

  return (
    <section ref={(el) => (refs.current[id] = el)} className={classNames.join(' ')}>
      <SectionHeading
        isDraft={isDraft}
        isCollapsed={isCollapsed}
        readOnly={readOnly}
        onCollapseChange={() => onCollapseChange(id, !isCollapsed)}
        onMoveSection={onMoveSection}
        isLastSection={isLastSection}
        index={index}
      >
        <h3>{title || `Section ${index + 1}`}</h3>
      </SectionHeading>
      <div className={styles.content}>
        <div className={styles.contentInner}>{children}</div>
      </div>
    </section>
  );
}

Section.defaultProps = {
  isCollapsed: false,
  readOnly: false,
};

export { SectionHeading };
export default Section;
