import React, { useEffect } from 'react';

import useCallbackAsRef from './useCallbackAsRef';

export default function useOutsideClick<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  callback: (event: MouseEvent) => void
) {
  const callbackRef = useCallbackAsRef(callback);

  useEffect(() => {
    const targetElement = target && 'current' in target ? target.current : target;

    const listener = (event: MouseEvent) => {
      if (!targetElement || targetElement.contains(event.target as Node)) return;
      callbackRef.current(event);
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [callbackRef, target]);
}
