import _ from 'lodash';
import { createSelector } from 'reselect';

import { FILTER } from 'constants/template';

import RoomSelector from './RoomSelector';

const currentUser = (state) => state.session.user;
const sheetTemplates = (state) => state.sheetTemplates.models;
const orderByName = (obj) => obj.name.toLowerCase();

export default class SheetTemplateSelector {
  static get = createSelector(
    (state, guidSlug) => state.sheetTemplates.models[guidSlug.split('-')[0]],
    (template) => template
  );

  static getAll = createSelector(sheetTemplates, (templates) =>
    _.sortBy(Object.values(templates), ['isGeneric', orderByName])
  );

  static getWithPlaykitIds = createSelector(this.getAll, (models) => models.filter((m) => m.playkitIds.length > 0));

  static getWithGameIds = createSelector(this.getAll, (models) => models.filter((m) => m.gameIds.length > 0));

  static getAllByProductId = createSelector(
    (state, productId) =>
      Object.values(state.sheetTemplates.models).filter((o) => o.productId === productId || o.isGeneric),
    (templates) => _.sortBy(templates, ['isGeneric', orderByName])
  );

  static getPublished = createSelector(sheetTemplates, (templates) =>
    _.sortBy(
      Object.values(templates).filter((o) => o.isPublished),
      [orderByName]
    )
  );

  static isSaved = (guid: string) =>
    createSelector([(state) => state.sheetTemplates.savedTemplateGuids], (guids) => guids.indexOf(guid) >= 0);

  static getUsersTemplates = createSelector([sheetTemplates, currentUser], (templates, user) =>
    _.sortBy(
      Object.values(templates).filter((o) => o.userId === user.id),
      [orderByName]
    )
  );

  static getUnlockedTemplates = createSelector([sheetTemplates, currentUser], (templates, user) => {
    const productIds = [...user.unlockedFreeProductIds, ...user.unlockedProductIds];
    return Object.values(templates).filter((template) => _.intersection(template.productIds, productIds).length > 0);
  });

  static getSavedTemplates = createSelector(
    [sheetTemplates, this.getUnlockedTemplates, (state) => state.sheetTemplates.savedTemplateGuids, currentUser],
    (templates, unlockedTemplates, guids, user) => {
      const unlockedGuids = unlockedTemplates.map((o) => o.guid);
      return _.sortBy(
        Object.values(templates).filter(
          (o) => o.userId !== user.id && guids.includes(o.guid) && !unlockedGuids.includes(o.guid)
        ),
        [orderByName]
      );
    }
  );

  static getPlaykitSheetTemplatesByRoom = createSelector(
    [RoomSelector.get, this.getWithPlaykitIds],
    (room, templates) => templates.filter((t) => _.intersection(room.playkitIds, t.playkitIds).length > 0)
  );

  static getGameSheetTemplatesByRoom = createSelector([RoomSelector.get, this.getWithGameIds], (room, sheets) => {
    return sheets.filter((s) => s.gameIds.includes(room.gameId));
  });

  static getGameSheetTemplatesByGameId = (gameId) => {
    return createSelector([this.getWithGameIds], (sheets) => {
      return sheets.filter((s) => s.gameIds.includes(gameId));
    });
  };

  static getTemplateToRemove = createSelector(
    [(state) => state.sheetTemplates.models, (state) => state.sheetTemplates.removeTemplateGuid],
    (models, guid) => (guid ? models[guid] : null)
  );

  static getTemplateToShare = createSelector(
    [(state) => state.sheetTemplates.models, (state) => state.sheetTemplates.shareTemplateGuid],
    (models, guid) => (guid ? models[guid] : null)
  );

  static filter = (filterType, filterOrder) => {
    return createSelector(
      [this.getUsersTemplates, this.getSavedTemplates, this.getUnlockedTemplates, currentUser],
      (createdTemplates, savedTemplates, unlockedTemplates, currentUser) => {
        let filtered = [];
        switch (filterType) {
          case FILTER.all:
            filtered = [...createdTemplates, ...savedTemplates, ...unlockedTemplates];
            break;
          case FILTER.created:
            filtered = createdTemplates;
            break;
          case FILTER.saved:
            filtered = savedTemplates;
            break;
          case FILTER.unlocked:
            filtered = unlockedTemplates;
            break;
          default:
            break;
        }
        return _.orderBy(_.uniqBy(filtered, 'guid'), [orderByName], [filterOrder]);
      }
    );
  };
}
