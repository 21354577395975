import AssetAction from 'store/actions/AssetAction';

const INITIAL_STATE = {
  deleteAssetId: null,
  renameAssetId: null,
  mapAssetId: null,
  menuAssetId: null,
  showAddTokenModal: false,
  showLibraryModal: false,
  showUploadModal: false,
  tokenAssetId: null,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action
  if (error) return state

  switch (type) {
    case AssetAction.DELETE:
      return { ...state, deleteAssetId: payload }

    case AssetAction.EDIT_MAP:
      return { ...state, mapAssetId: payload }

    case AssetAction.EDIT_TOKEN:
      return { ...state, tokenAssetId: payload }

    case AssetAction.RENAME:
      return { ...state, renameAssetId: payload }

    case AssetAction.SHOW_ADD_TOKEN:
      return { ...state, showAddTokenModal: payload }

    case AssetAction.SHOW_LIBRARY:
      return { ...state, showLibraryModal: payload }

    case AssetAction.SHOW_MENU:
      return { ...state, menuAssetId: payload }

    case AssetAction.SHOW_UPLOAD:
      return { ...state, showUploadModal: payload }

    default:
      return state
  }
}
