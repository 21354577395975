// @flow
import _ from 'lodash';
import React from 'react';
import { v4 as uuid } from 'uuid';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import { createInput } from 'utilities/sheet';

import Slots from './Slots';
import { Builder } from 'components/Sheet2/elements/Element';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function SlotsBuilder(props: Props) {
  const { colors, element, onChange, sectionId, sheetGuid } = props;
  const { columns, items } = element;

  const slotInputs = items.filter((item) => item.inputType === INPUT_TYPE.slot);
  const slotValues = slotInputs?.map((item) => (typeof item.defaultValue === 'boolean' ? item.defaultValue : false));

  const onSlotsChange = (value: boolean, index: number) => {
    const updatedSlotInputs = slotInputs.map((o, i) => ({
      ...o,
      defaultValue: i === index ? value : o.defaultValue,
    }));
    const updatedItems = [...updatedSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onCountChange = (value: string) => {
    const count = parseInt(value);
    if (isNaN(count)) return;
    const total = slotInputs.length;
    if (count === total) return;

    if (count < total) {
      const updatedSlotInputs = slotInputs.slice(0, count);
      const updatedItems = [...updatedSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    } else {
      const newSlotInputs = _.times(count - total, () => ({
        ...createInput(uuid(), INPUT_TYPE.slot),
        defaultValue: false,
        canEdit: true,
      }));
      const updatedItems = [...slotInputs, ...newSlotInputs, ...items.filter((o) => o.inputType !== INPUT_TYPE.slot)];
      const updatedElement = { ...element, items: updatedItems };
      onChange(sheetGuid, sectionId, updatedElement);
    }
  };

  return (
    <Builder {...props}>
      <Slots
        items={slotValues}
        columns={columns}
        isBuilding={true}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onChange={onSlotsChange}
        onCountChange={onCountChange}
      />
    </Builder>
  );
}

const MemoizedSlotsBuilder = React.memo(SlotsBuilder);
MemoizedSlotsBuilder.displayName = 'MemoizedSlotsBuilder';

export default MemoizedSlotsBuilder;
