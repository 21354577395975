import { batch } from 'react-redux';

import { createAction, handleError } from './utilities';
import { processDocumentsResponse } from 'store/actions/DocumentAction';

import DocumentAction from './DocumentAction';
import GameAction from './GameAction';
import OrganizationAction from './OrganizationAction';
import RoomDocumentAction from './RoomDocumentAction';
import SessionAction from './SessionAction';
import role from 'apis/role';

const extractSheetObj = (data) => {
  const {
    id,
    attributes,
    relationships: { playkits, products, games },
  } = data;
  const playkitIds = playkits.data.map((o) => o.id);
  const productIds = products.data.map((o) => o.id);
  const gameIds = games.data.map((o) => o.id);
  return { id, ...attributes, playkitIds, productIds, gameIds };
};

const extractSheetTemplateObj = ({
  id,
  attributes,
  relationships: { playkits, products, games, ancestorGames, ancestorProducts, documents },
}) => {
  const playkitIds = playkits.data.map((o) => o.id);
  const productIds = products.data.map((o) => o.id);
  const gameIds = games.data.map((o) => o.id);
  const ancestorGameIds = ancestorGames.data.map((o) => o.id);
  const ancestorProductIds = ancestorProducts.data.map((o) => o.id);
  const documentIds = documents.data.map((o) => o.id);
  return { id, ...attributes, playkitIds, productIds, gameIds, ancestorGameIds, ancestorProductIds, documentIds };
};

const extractRoomDocFromDoc = (data) => {
  const { externalUrl, fileSizeFormatted, thumbnailUrl, downloadUrl, isGif, playkitIds, ...rest } = data;
  return rest;
};

export function processProductResponse({ data, included }) {
  if (Array.isArray(data)) {
    const products = data.map(({ id, attributes }) => ({ id, ...attributes }));
    const games = [];
    const organizations = [];
    const shopifyProducts = [];

    for (const include of included ?? []) {
      const { id, type, attributes } = include;
      const obj = { id, ...attributes };

      if (type === 'game') games.push(obj);
      else if (type === 'organization') organizations.push(obj);
      else if (type === 'shopifyProduct') shopifyProducts.push(obj);
    }

    return { products, games, organizations, shopifyProducts };
  }

  const product = { id: data.id, ...data.attributes };
  let game = null;
  let organization = null;
  let shopifyProducts = [];

  for (const include of included ?? []) {
    const { id, type, attributes } = include;
    const obj = { id, ...attributes };

    if (type === 'game') game = obj;
    else if (type === 'organization') organization = obj;
    else if (type === 'shopifyProduct') shopifyProducts.push(obj);
  }

  return { product, game, organization, shopifyProducts };
}

export default class ProductAction {
  static FETCH = 'ProductAction.FETCH';
  static FETCH_DOCUMENTS = 'ProductAction.FETCH_DOCUMENTS';
  static FETCH_SHEETS = 'ProductAction.FETCH_SHEETS';
  static FETCH_SHEET_TEMPLATES = 'ProductAction.FETCH_SHEET_TEMPLATES';
  static FETCH_ALL = 'ProductAction.FETCH_ALL';
  static UPDATE = 'ProductAction.UPDATE';
  static REDEEM = 'ProductAction.REDEEM';

  static fetch = (guid, callback) => async (dispatch) => {
    return role.get(`/products/${guid}`).then(
      (response) => {
        const { product, game, organization } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.FETCH, product));
          dispatch(createAction(OrganizationAction.FETCH, organization));
          dispatch(createAction(GameAction.FETCH, game));
        });
        callback?.({ product, game });
      },
      ({ response }) => handleError(dispatch, ProductAction.FETCH, response)
    );
  };

  static fetchAll = (params, callback) => async (dispatch) => {
    return role.get('/products', { params }).then(
      (response) => {
        const { products, games, organizations, shopifyProducts } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });
        callback?.({ products, shopifyProducts });
      },
      ({ response }) => handleError(dispatch, ProductAction.FETCH_ALL, response)
    );
  };

  static fetchSheets = (guid, callback) => async (dispatch) => {
    return role.get(`/products/${guid}/sheets`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          return extractSheetObj(obj);
        });
        dispatch(createAction(ProductAction.FETCH_SHEETS, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, ProductAction.FETCH_SHEETS, response)
    );
  };

  static fetchTemplates = (guid, callback) => async (dispatch) => {
    return role.get(`/products/${guid}/sheet_templates`).then(
      (response) => {
        const objs = response.data.data.map((obj) => extractSheetTemplateObj(obj));
        dispatch(createAction(ProductAction.FETCH_SHEET_TEMPLATES, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, ProductAction.FETCH_SHEET_TEMPLATES, response)
    );
  };

  static fetchDocuments =
    (guid, slug = '', callback) =>
    async (dispatch) => {
      return role.get(`/products/${guid}/documents`).then(
        (response) => {
          const { documents } = processDocumentsResponse({
            ...response.data,
            included: [...response.data.data],
          });
          const roomDocuments = documents.map((obj) => {
            const guidId = `${slug}-${obj.id}-${obj.ownerId}`;
            return { ...extractRoomDocFromDoc(obj), guidId: guidId };
          });
          batch(() => {
            dispatch(createAction(RoomDocumentAction.FETCH_ALL, roomDocuments));
            dispatch(createAction(DocumentAction.FETCH_ALL, documents));
          });
          if (callback) callback(documents);
        },
        ({ response }) => handleError(dispatch, ProductAction.FETCH_DOCUMENTS, response)
      );
    };

  static update = (guid, formValues, callback) => async (dispatch) => {
    return role.patch(`/products/${guid}`, formValues).then(
      (response) => {
        const { product, game, organization, shopifyProducts } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.UPDATE, product));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
          if (game) dispatch(createAction(GameAction.FETCH, game));
        });
        callback?.({ product, shopifyProducts });
      },
      ({ response }) => handleError(dispatch, ProductAction.UPDATE, response)
    );
  };

  static createShopifyProduct = (guid, formValues, callback, errorCallback) => async (dispatch) => {
    return role.post(`/products/${guid}/shopify`, formValues).then(
      (response) => {
        const { product, game, organization, shopifyProducts } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.UPDATE, product));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
          if (game) dispatch(createAction(GameAction.FETCH, game));
        });
        callback?.({ product, shopifyProducts });
      },
      ({ response }) => {
        errorCallback?.(response);
        handleError(dispatch, ProductAction.UPDATE, response);
      }
    );
  };

  static deleteShopifyProduct = (guid, id, callback) => async (dispatch) => {
    return role.delete(`/products/${guid}/shopify/${id}`).then(
      (response) => {
        const { product, game, organization, shopifyProducts } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.UPDATE, product));
          if (organization) dispatch(createAction(OrganizationAction.FETCH, organization));
          if (game) dispatch(createAction(GameAction.FETCH, game));
        });
        callback?.({ product, shopifyProducts });
      },
      ({ response }) => handleError(dispatch, ProductAction.UPDATE, response)
    );
  };

  static checkout = (stripe_product_id, room_guid) =>
    role
      .post('/products/checkout', {
        product: { stripe_product_id },
        room_guid,
      })
      .then((response) => response.data);

  static redeem = (guid, callback) => (dispatch) =>
    role.post('/products/redeem', { guid }).then(
      (response) => {
        const { products, games, organizations } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });
        dispatch(SessionAction.fetchCurrentUser());
        callback?.({ products, games, organizations });
      },
      ({ response }) => handleError(dispatch, ProductAction.REDEEM, response)
    );
}
