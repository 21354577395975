// @flow
import type { Node } from 'react';
import React, { useCallback } from 'react';

import type { OnPublishClick, OnUnpublishClick, SheetProps, TemplateModel } from './types';
import { PRIVACY } from 'constants/template';

import Main from './Main';
import TemplateHeader from './TemplateHeader';

type Props = {
  ...SheetProps,
  model: TemplateModel,
  onPublishClick: OnPublishClick,
  onUnpublishClick: OnUnpublishClick,
  showMenu: boolean,
};

function Template(props: Props): Node {
  const { colors, model, onChangeAvatar, onChange, onPublishClick, onUnpublishClick, readOnly, isDraft, showMenu } =
    props;
  const { guidSlug, avatarUrl, hasPaidAncestor, isPublished, lastPublishedAt, lastEditedAt, name, privacy, updatedAt } =
    model;

  const onChangeName = useCallback((name) => onChange({ ...model, name }), [model, onChange]);
  const onChangePrivacy = useCallback(
    (checked) => onChange({ ...model, privacy: checked ? PRIVACY.public : PRIVACY.unlisted }),
    [model, onChange]
  );

  return (
    <Main
      {...props}
      isDraft={isDraft}
      header={
        <TemplateHeader
          guidSlug={guidSlug}
          avatarUrl={avatarUrl}
          canEdit={!readOnly}
          colors={colors}
          isPublished={isPublished}
          isPrivacyLocked={hasPaidAncestor}
          lastPublishedAt={lastPublishedAt}
          lastEditedAt={lastEditedAt}
          name={name}
          onChangeAvatar={onChangeAvatar}
          onChangeName={onChangeName}
          onChangePrivacy={onChangePrivacy}
          onPublishClick={onPublishClick}
          onUnpublishClick={onUnpublishClick}
          privacy={privacy}
          showMenu={showMenu}
          updatedAt={updatedAt}
        />
      }
    />
  );
}

Template.defaultProps = {
  idKey: 'guid',
  showMenu: false,
};

export default Template;
