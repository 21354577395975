import styles from './TableBar.module.css';

import clsx from 'clsx';
import { clone } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { ChannelEvent } from 'models/Channel';
import { DEFAULT_COLORS, DICE_COLOR_LABELS } from 'models/Color';
import Die, { DICE } from 'models/Die';
import { DocumentAction, RollAction, RoomAction, RoomDocumentAction, RoomUserAction, TableAction } from 'store/actions';
import {
  DocumentSelector,
  RollSelector,
  RoomDocumentSelector,
  RoomSelector,
  RoomUserSelector,
  SessionSelector,
  TableSelector,
} from 'store/selectors';

import DiceColorPicker from './DiceColorPicker';
import DiceTray from './DiceTray';
import Button from 'components/buttons/Button';
import { DieIcon } from 'components/DiceRoller';
import { Divider } from 'components/Sheet2/elements/DividerElement';
import ToggleSwitch from 'components/ToggleSwitch';
import { ReactComponent as CardsIcon } from 'images/icons/CardsIcon.svg';
import { ReactComponent as GearIcon } from 'images/icons/GearIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as SingleArrowIcon } from 'images/icons/SingleArrowIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import CreateDiceModal from 'modals/CreateDiceModal';

export default function DiceRoller({ room }: { room?: any }) {
  const dispatch = useDispatch();
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const currentUser = useSelector(SessionSelector.currentUser);
  const userSettings = useSelector((state) =>
    // @ts-ignore
    room && currentUser ? RoomUserSelector.getSettings(state, room.guid, currentUser.id) : {}
  );
  // @ts-ignore
  const diceSettings = useSelector((state) => RoomSelector.getDiceSettings(state, room?.guid));
  // @ts-ignore
  const cardsSettings = useSelector((state) => RoomSelector.getCardsSettings(state, room?.guid));
  const isHost = room && currentUser && room.userId === currentUser.id;
  // @ts-ignore
  const customDice = useSelector((state) => RoomDocumentSelector.getActiveCustomDice(state, room?.guid));
  const trayDice = useSelector(RollSelector.getDice);
  const modifiers = useSelector(RollSelector.getSelectedNonDiceModifiers);
  const isPrivateRoll = useSelector(TableSelector.isPrivateRoll);
  const shouldClearAfterRoll = useSelector(TableSelector.shouldClearAfterRoll);
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const [diceColor, setDiceColor] = useState(userSettings.diceColor);
  const [showSettings, setShowSettings] = useState(false);
  const dieLabel = `${diceColor ? DICE_COLOR_LABELS[diceColor] + ' ' : ''}`;

  const onChangeDiceColor = (color: string) => {
    setDiceColor(color);
    if (room && currentUser) dispatch(RoomUserAction.updateSettings(room.guid, currentUser.id, { diceColor: color }));
  };

  const onDieClick = (die: any) => {
    const shouldClear = shouldClearAfterRoll && trayDice.length > 0 && trayDice.every((die) => die.hasRolled);
    if (shouldClear) {
      for (const die of trayDice) {
        dispatch(RollAction.removeDie(die.id));
        presenceChannel?.trigger(ChannelEvent.DIE_REMOVED, {
          userId: currentUser.id,
          dieId: die.id,
        });
      }
      for (const modifier of modifiers) {
        presenceChannel?.trigger(ChannelEvent.MODIFIER_REMOVED, {
          userId: currentUser.id,
          modifierId: modifier.id,
        });
      }
      dispatch(RollAction.unselectAllModifiers());
    }

    const newDie = clone(die);
    newDie.id = uuid();
    dispatch(RollAction.addDie(newDie));
    dispatch(TableAction.setPlayPanelContent('Dice'));
    if (!isPrivateRoll && presenceChannel)
      presenceChannel.trigger(ChannelEvent.DIE_ADDED, {
        userId: currentUser.id,
        die: newDie,
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Dice Roller</h3>
        {isHost && (
          <button
            className={clsx('button-reset', styles.settingsButton)}
            onClick={() => setShowSettings((prev) => !prev)}
          >
            <GearIcon />
            <SingleArrowIcon className={clsx(showSettings && styles.isShowingSettings)} />
          </button>
        )}
      </div>

      {isHost && <DiceSettings isOpen={showSettings} roomGuid={room.guid} />}

      <div className={clsx('scrollbars-dark', styles.content, showSettings && styles.isShowingSettings)}>
        <div className={styles.trayContainer}>
          <DiceTray room={room} />
        </div>

        <div className={styles.diceButtonsContainer}>
          <Divider style={{ '--color-sheet-button': 'var(--color-theme-button)' }} />
          <h4 className={styles.title}>Add to Roll</h4>
          <div className={styles.colorPicker}>
            <DiceColorPicker defaultValue={diceColor} onChange={onChangeDiceColor} />
          </div>
          <div className={styles.allButtons}>
            {DICE.map((die) => {
              const setting = diceSettings[`${die.type}-${die.sides}`];
              die.color = diceColor || setting.color;
              return (
                setting.enabled && (
                  <DieButton
                    key={die.name}
                    die={die}
                    label={dieLabel}
                    color={die.color || undefined}
                    onClick={onDieClick}
                  />
                )
              );
            })}
            {customDice.map((roomDocument) => {
              const die = new Die({ name: roomDocument.name });
              const setting = { enabled: roomDocument.data.enabled ?? true, color: roomDocument.data.color ?? '' };
              die.color = diceColor || setting.color;
              return (
                <DieButton
                  key={die.name}
                  die={die}
                  roomDocument={roomDocument}
                  label={dieLabel}
                  color={die.color || undefined}
                  onClick={onDieClick}
                />
              );
            })}
            {!previewRoom && cardsSettings.enabled && (
              <button
                onClick={() => dispatch(TableAction.showCardDrawer())}
                className={clsx('button-reset', styles.barButton)}
              >
                <CardsIcon className={styles.barButtonIcon} />
                <span className={styles.barButtonText}>Cards</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function DieButton({
  die,
  roomDocument,
  color,
  label,
  onClick,
}: {
  die: any;
  roomDocument?: any;
  color?: string;
  label: string;
  onClick: (die: any) => void;
}) {
  // @ts-ignore
  const document = useSelector((state) => (roomDocument ? DocumentSelector.get(state, roomDocument.id) : undefined));
  if (document) {
    die.type = document.metadata.type;
    die.sides = document.metadata.sides;
  }

  return (
    <button
      key={die.name}
      onClick={() => onClick(die)}
      title={`${label} ${die.name}`}
      aria-label={`Add ${label} ${die.name} to roll`}
      className={clsx(
        'button-reset',
        styles.barButton,
        die.name.length > 5 && styles.isLongName,
        die.sides > 99 && styles.isLargeNumber
      )}
      style={{ '--color-dice-button': color }}
    >
      <DieIcon die={die} className={styles.barButtonIcon} />
      <span className={styles.barButtonText}>{die.name}</span>
    </button>
  );
}

function DiceSettings({ isOpen, roomGuid }: { isOpen: boolean; roomGuid: string }) {
  const dispatch = useDispatch();
  const room = useSelector((state) => RoomSelector.get(state, roomGuid));
  const currentUser = useSelector(SessionSelector.currentUser);
  const userColors = useSelector((state) =>
    // @ts-ignore
    roomGuid ? RoomUserSelector.getColors(state, roomGuid, currentUser.id) : DEFAULT_COLORS
  );
  // @ts-ignore
  const roomDiceSettings = useSelector((state) => RoomSelector.getDiceSettings(state, roomGuid));
  // @ts-ignore
  const roomCardSettings = useSelector((state) => RoomSelector.getCardsSettings(state, roomGuid));
  // @ts-ignore
  const customDice = useSelector((state) => RoomDocumentSelector.getCustomDice(state, roomGuid));

  const [diceSettings, setDiceSettings] = useState(roomDiceSettings);
  const [cardsSettings, setCardsSettings] = useState(roomCardSettings);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onChangeDieColor = (die: any, color: string) => {
    const id = `${die.type}-${die.sides}`;
    const dieSettings = diceSettings[id] ?? {};
    const newSettings = { ...diceSettings, [id]: { ...dieSettings, color } };
    const roomSettings = { ...room.settings, dice: newSettings };
    setDiceSettings(newSettings);
    dispatch(RoomAction.update(roomGuid, { room: { settings: roomSettings } }));
  };

  const onChangeCustomDieColor = (roomDocument: any, color: string) => {
    const data = {
      room_document: { data: { ...roomDocument.data, color } },
      user_id: currentUser.id,
    };
    dispatch(RoomDocumentAction.update(roomGuid, roomDocument.id, data));
  };

  const onToggleDie = (die: any, checked: boolean) => {
    const id = `${die.type}-${die.sides}`;
    const dieSettings = diceSettings[id] ?? {};
    const newSettings = { ...diceSettings, [id]: { ...dieSettings, enabled: checked } };
    const roomSettings = { ...room.settings, cards: cardsSettings, dice: newSettings };
    setDiceSettings(newSettings);
    dispatch(RoomAction.update(roomGuid, { room: { settings: roomSettings } }));
  };

  const onToggleCustomDie = (roomDocument: any, checked: boolean) => {
    const data = {
      room_document: { data: { ...roomDocument.data, enabled: checked } },
      user_id: currentUser.id,
    };
    dispatch(RoomDocumentAction.update(roomGuid, roomDocument.id, data));
  };

  const onToggleCards = (checked: boolean) => {
    const newSettings = { ...cardsSettings, enabled: checked };
    const roomSettings = { ...room.settings, dice: diceSettings, cards: newSettings };
    setCardsSettings(newSettings);
    dispatch(RoomAction.update(roomGuid, { room: { settings: roomSettings } }));
  };

  const onSubmit = ({ id }: { id: string }) => {
    dispatch(
      RoomDocumentAction.create(roomGuid, id, () => {
        setShowCreateModal(false);
        scrollRef.current?.scroll({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
      })
    );
  };

  const onDelete = ({ id }: { id: string }) => {
    dispatch(
      RoomDocumentAction.delete(roomGuid, id, currentUser.id, () => {
        dispatch(DocumentAction.delete(id));
      })
    );
  };

  return (
    <div className={clsx(styles.settingsContainer, isOpen && styles.isShowingSettings)}>
      <div ref={scrollRef} className={clsx('scrollbars-dark', styles.dieCards)}>
        {DICE.map((die) => {
          const id = `dice-setting-${die.name}`;
          const setting = diceSettings[`${die.type}-${die.sides}`];
          return (
            <DiceSetting
              key={id}
              id={id}
              die={die}
              setting={setting}
              onToggle={onToggleDie}
              onChangeColor={onChangeDieColor}
            />
          );
        })}

        <div className={clsx(styles.dieCard, !cardsSettings.enabled && styles.isDisabled)}>
          <div className={clsx('button-reset', styles.barButton, styles.isSettings)}>
            <CardsIcon className={styles.barButtonIcon} />
            <span className={styles.barButtonText}>Cards</span>
          </div>
          <ToggleSwitch
            ariaLabel="Toggle Cards"
            checked={cardsSettings.enabled}
            onChange={(checked: boolean) => onToggleCards(checked)}
            variant="theme"
          />
          <div></div>
        </div>

        {customDice.length > 0 && (
          <>
            <hr />
            {customDice.map((roomDocument) => {
              const id = `custom-dice-setting-${roomDocument.name}`;
              const die = new Die({ name: roomDocument.name });
              const setting = { enabled: roomDocument.data.enabled ?? true, color: roomDocument.data.color ?? '' };
              return (
                <DiceSetting
                  key={id}
                  id={id}
                  die={die}
                  setting={setting}
                  roomDocument={roomDocument}
                  onToggle={(_, checked) => onToggleCustomDie(roomDocument, checked)}
                  onChangeColor={(_, color) => onChangeCustomDieColor(roomDocument, color)}
                  onDelete={onDelete}
                />
              );
            })}
          </>
        )}
      </div>

      <Button
        primaryBackground={userColors[0]}
        secondaryBackground={userColors[1]}
        className={styles.addCustomButton}
        icon={<PlusIcon />}
        onClick={() => setShowCreateModal(true)}
      >
        Add Custom Dice
      </Button>

      {showCreateModal && <CreateDiceModal onSubmit={onSubmit} onDismiss={() => setShowCreateModal(false)} />}
    </div>
  );
}

interface DiceSettingProps {
  id: string;
  die: any;
  setting: any;
  roomDocument?: any;
  onToggle: (die: any, checked: boolean) => void;
  onChangeColor: (die: any, color: string) => void;
  onDelete?: (roomDocument: any) => void;
}

function DiceSetting({ id, die, setting, roomDocument, onToggle, onChangeColor, onDelete }: DiceSettingProps) {
  // @ts-ignore
  const document = useSelector((state) => (roomDocument ? DocumentSelector.get(state, roomDocument.id) : undefined));
  if (document) {
    die.type = document.metadata.type;
    die.sides = document.metadata.sides;
  }

  return (
    <div className={clsx(styles.dieCard, !setting.enabled && styles.isDisabled)}>
      {document && (
        <button className={clsx('button-reset', styles.deleteButton)} onClick={() => onDelete?.(roomDocument)}>
          <XIcon />
        </button>
      )}
      <div
        key={die.name}
        aria-label={die.name}
        title={die.name}
        className={clsx('button-reset', styles.barButton, styles.isSettings)}
        style={{ '--color-dice-button': setting.color || undefined }}
      >
        <DieIcon die={die} className={styles.barButtonIcon} />
        <span className={styles.barButtonText}>{die.name}</span>
      </div>
      <ToggleSwitch
        ariaLabel={`Toggle ${die.name}`}
        checked={setting.enabled}
        onChange={(checked: boolean) => onToggle(die, checked)}
        variant="theme"
      />
      <DiceColorPicker
        id={id}
        size={20}
        defaultValue={setting.color}
        onChange={(color) => onChangeColor(die, color)}
        className={styles.dieColorPicker}
      />
    </div>
  );
}
