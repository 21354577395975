import { createAction } from './utilities'

export default class SheetTemplateBuilderAction {
  static ADD_BLOCK = 'SheetTemplateBuilderAction.ADD_BLOCK'
  static ADD_INPUT = 'SheetTemplateBuilderAction.ADD_INPUT'
  static ADD_LINK = 'SheetTemplateBuilderAction.ADD_LINK'
  static ADD_SECTION = 'SheetTemplateBuilderAction.ADD_SECTION'
  static DELETE_BLOCK = 'SheetTemplateBuilderAction.DELETE_BLOCK'
  static DELETE_INPUT = 'SheetTemplateBuilderAction.DELETE_INPUT'
  static DELETE_SECTION = 'SheetTemplateBuilderAction.DELETE_SECTION'
  static EDIT_LINK = 'SheetTemplateBuilderAction.EDIT_LINK'
  static SHOW_ADVANCED_OPTIONS = 'SheetTemplateBuilderAction.SHOW_ADVANCED_OPTIONS'
  static SHOW_PUBLISH_CONFIRMATION = 'SheetTemplateBuilderAction.SHOW_PUBLISH_CONFIRMATION'
  static SHOW_PUBLISHED = 'SheetTemplateBuilderAction.SHOW_PUBLISHED'
  static SHOW_RENAME = 'SheetTemplateBuilderAction.SHOW_RENAME'
  static SHOW_SETTINGS_MENU = 'SheetTemplateBuilderAction.SHOW_SETTINGS_MENU'

  static addBlock = (isAdding, sectionId, id) => {
    return createAction(SheetTemplateBuilderAction.ADD_BLOCK, { isAdding, sectionId, id })
  }

  static addInput = (isAdding, sectionId, blockId) => {
    return createAction(SheetTemplateBuilderAction.ADD_INPUT, { isAdding, sectionId, blockId })
  }

  static addLink = (isAdding, sectionId, blockId, inputId, isToggle = false) => {
    return createAction(SheetTemplateBuilderAction.ADD_LINK, { isAdding, sectionId, blockId, inputId, isToggle })
  }

  static addSection = (isAdding, id) => {
    return createAction(SheetTemplateBuilderAction.ADD_SECTION, { isAdding, id })
  }

  static deleteBlock = (sectionId, id) => {
    return createAction(SheetTemplateBuilderAction.DELETE_BLOCK, { sectionId, id })
  }

  static deleteInput = (sectionId, blockId, id) => {
    return createAction(SheetTemplateBuilderAction.DELETE_INPUT, { sectionId, blockId, id })
  }

  static deleteSection = id => {
    return createAction(SheetTemplateBuilderAction.DELETE_SECTION, id)
  }

  static editLink = (isEditing, sectionId, blockId, inputId, editLinkIndex, isToggle = false) => {
    return createAction(SheetTemplateBuilderAction.EDIT_LINK, {
      isEditing,
      sectionId,
      blockId,
      inputId,
      editLinkIndex,
      isToggle,
    })
  }

  static showAdvancedOptions = ids => {
    return createAction(SheetTemplateBuilderAction.SHOW_ADVANCED_OPTIONS, ids)
  }

  static showPublishConfirmation = bool => {
    return createAction(SheetTemplateBuilderAction.SHOW_PUBLISH_CONFIRMATION, bool)
  }

  static showPublished = bool => {
    return createAction(SheetTemplateBuilderAction.SHOW_PUBLISHED, bool)
  }

  static showRename = bool => {
    return createAction(SheetTemplateBuilderAction.SHOW_RENAME, bool)
  }

  static showSettingsMenu = bool => {
    return createAction(SheetTemplateBuilderAction.SHOW_SETTINGS_MENU, bool)
  }
}
