// @flow
import styles from './AssetLibraryModal.module.css';

import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { FILTER, FILTERS } from 'constants/asset';
import useWindowSize from 'hooks/useWindowSize';
import Document, { DocumentType, filterToType } from 'models/Document';
import { AssetAction, DocumentAction, RoomDocumentAction } from 'store/actions';
import { DocumentSelector, GameSelector, ProductSelector, SessionSelector } from 'store/selectors';
import { capitalize } from 'utilities';

import Button from 'components/buttons/Button';
import AssetCard from 'components/cards/AssetCard';
import FilterBar from 'components/FilterBar';
import Modal from 'components/Modal';
import { ReactComponent as UploadIcon } from 'images/icons/UploadIcon.svg';

type Props = {
  roomGuid: string,
  onDismiss: () => void,
};

function AssetLibraryModal({ roomGuid, onDismiss }: Props) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const modalWidth = size.isMd ? '80%' : '100%';

  const [filter, setFilter] = useState(FILTER.all);
  const [order, setOrder] = useState(true);

  const currentUser = useSelector(SessionSelector.currentUser);
  const games = useSelector(GameSelector.getAll);
  const products = useSelector(ProductSelector.getAll);
  const assets = useSelector((state) =>
    DocumentSelector.filterByUserNotInRoom(currentUser.id, DocumentType.NONE, order ? 'asc' : 'desc')(state, roomGuid)
  ).map((o) => new Document(o));
  const filteredAssetsCount = useSelector((state) =>
    DocumentSelector.filterByUserNotInRoom(
      currentUser.id,
      filterToType(filter),
      order ? 'asc' : 'desc'
    )(state, roomGuid)
  ).length;

  useEffect(() => dispatch(DocumentAction.fetchAll()), [dispatch]);

  const onAddToTable = useCallback(
    (id) => {
      batch(() => {
        dispatch(RoomDocumentAction.create(roomGuid, id));
        dispatch(AssetAction.showUpload(false));
      });
      onDismiss();
    },
    [dispatch, onDismiss, roomGuid]
  );

  const menuItems = useCallback(
    (asset) => {
      const { id, fileUrl, externalUrl } = asset;
      const items = [
        {
          label: 'Add to Room',
          onClick: () => onAddToTable(id),
        },
      ];

      if (!asset.isAudio()) {
        items.push({
          label: 'View in New Window',
          url: externalUrl ?? fileUrl,
        });
      }
      return items;
    },
    [onAddToTable]
  );

  const renderAsset = useCallback(
    (asset) => {
      const tags = [];
      const sharedGameIds = _.intersection(asset.gameIds, currentUser.unlockedGameIds);
      const sharedProductIds = _.intersection(asset.productIds, currentUser.unlockedProductIds);

      if (games?.length > 0 && sharedGameIds.length > 0) {
        const game = games.find((game) => game.id === sharedGameIds[0]);
        if (game)
          tags.push({
            title: game.title,
            color: 'var(--color-teal)',
          });
      } else if (products?.length > 0 && sharedProductIds.length > 0) {
        const product = products.find((product) => product.id === sharedProductIds[0]);
        if (product) tags.push({ title: product.title, color: 'var(--color-teal)' });
      }

      return (
        <div key={asset.id} className={`${styles.asset} ${styles[`is${capitalize(asset.type)}`]}`}>
          <AssetCard asset={asset} tags={tags} menuItems={menuItems(asset)} showActions isClickable />
        </div>
      );
    },
    [currentUser.unlockedGameIds, games, menuItems]
  );

  return (
    <Modal maxWidth={modalWidth} width={modalWidth} onDismiss={onDismiss}>
      <header className={styles.header}>
        <h3 className={styles.title}>Your Assets</h3>
        <div className={styles.subtitle}>Choose an existing asset from your library to add to the room!</div>
      </header>
      <div className={styles.content}>
        <FilterBar
          filters={FILTERS}
          current={filter}
          order={order}
          onFilterChange={setFilter}
          onOrderChange={setOrder}
          className={styles.filterBar}
        />

        <div className={`${styles.assets} ${styles[`filterAssets${capitalize(filter)}`]}`}>
          {assets.map(renderAsset)}
        </div>

        {filteredAssetsCount === 0 && (
          <div className={styles.empty}>
            <h4 className={styles.emptyHeader}>
              You do not have any {filter === FILTER.all ? 'assets' : filter} to add to the room
            </h4>
            {filter !== FILTER.apps && (
              <>
                <p className={styles.emptyBody}>
                  Share {filter === FILTER.all ? 'maps or tokens' : filter} with your friends around the room by
                  uploading below
                </p>
                <Button
                  variant="primary"
                  className={styles.emptyCta}
                  onClick={() => onDismiss()}
                  icon={<UploadIcon style={{ marginTop: '-2px' }} />}
                >
                  Upload Asset
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AssetLibraryModal;
