export const HEX_SIDE_LENGTH = 24
export const SQUARE_SIDE_LENGTH = 48

export const MapType = {
  HEX: 'hex',
  SQUARE: 'square',
}

export const MapLineAction = {
  ADD: 'add',
  REMOVE: 'remove',
  UPDATE: 'update',
}

export const MapTokenAction = {
  ADD: 'add',
  REMOVE: 'remove',
  UPDATE: 'update',
}
