// @flow
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import Die, { DieType } from 'models/Die';
import { SheetTemplateAction } from 'store/actions';
import { DocumentSelector } from 'store/selectors';
import { generateDiceColorInput } from 'utilities/sheet';

import DicePool from './DicePool';
import { Builder } from 'components/Sheet2/elements/Element';
import CreateDiceModal from 'modals/CreateDiceModal';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
  groupId?: string,
};

export function DicePoolBuilder(props: Props) {
  const { colors, element, onChange, sectionId, groupId, sheetGuid } = props;

  const dispatch = useDispatch();
  const customDice = useSelector((state) => DocumentSelector.getCustomDiceByTemplate(state, sheetGuid)).map(
    (doc) => new Die({ name: doc.name, sides: doc.metadata.sides, type: doc.metadata.types })
  );
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { items } = element;
  const countInput = items.find((o) => o.inputType === INPUT_TYPE.diceCount);
  const sidesInput = items.find((o) => o.inputType === INPUT_TYPE.diceSides);
  const typeInput = items.find((o) => o.inputType === INPUT_TYPE.diceType);
  const colorInput = items.find((o) => o.inputType === INPUT_TYPE.diceColor);

  const diceCountValue = countInput?.defaultValue ?? 1;
  const diceSidesValue = sidesInput?.defaultValue ?? 20;
  const diceTypeValue = typeInput?.defaultValue ?? DieType.NUMBER;
  const diceColorValue = colorInput?.defaultValue ?? '';

  // If this dice pool doesn't have a colorInput, make it
  const updatingColorInput = useRef(false);
  useEffect(() => {
    if (!colorInput && !updatingColorInput.current) {
      updatingColorInput.current = true;
      const input = generateDiceColorInput();
      const newElement = { ...element, items: [...element.items, input] };
      batch(() => {
        if (groupId) {
          dispatch(SheetTemplateAction.changeGroupElement(sheetGuid, sectionId, groupId, newElement));
          dispatch(SheetTemplateAction.updateGroupElement(sheetGuid, sectionId, groupId, newElement));
        } else {
          dispatch(SheetTemplateAction.changeElement(sheetGuid, sectionId, newElement));
          dispatch(SheetTemplateAction.updateElement(sheetGuid, sectionId, newElement));
        }
      });
    }
  }, [colorInput, dispatch, element, groupId, sectionId, sheetGuid]);

  const onDiceCountChange = (value: string) => {
    if (!countInput) return;
    const updatedItems = items.map((o) => (o.id === countInput.id ? { ...countInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onDiceTypeChange = (sides: number, type: string) => {
    if (!sidesInput || !typeInput) return;
    const updatedItems = items.map((o) => {
      if (o.id === sidesInput.id) return { ...sidesInput, defaultValue: sides };
      else if (o.id === typeInput.id) return { ...typeInput, defaultValue: type };
      else return o;
    });
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onDiceColorChange = (color: string) => {
    if (!colorInput) return;
    const updatedItems = items.map((o) => (o.id === colorInput.id ? { ...o, defaultValue: color } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  const onSubmit = ({ id, metadata: { sides, type } }) => {
    dispatch(
      SheetTemplateAction.createDice(sheetGuid, id, () => {
        setShowCreateModal(false);
        if (!sidesInput || !typeInput) return;
        const updatedItems = items.map((o) => {
          if (o.id === sidesInput.id) return { ...sidesInput, defaultValue: sides };
          else if (o.id === typeInput.id) return { ...typeInput, defaultValue: type };
          else return o;
        });
        const updatedElement = { ...element, items: updatedItems };
        onChange(sheetGuid, sectionId, updatedElement);
      })
    );
  };

  return (
    <Builder {...props}>
      <DicePool
        id={element.id}
        diceCount={diceCountValue}
        diceSides={Number(diceSidesValue)}
        diceType={diceTypeValue}
        diceColor={diceColorValue}
        customDice={customDice}
        canEditCount={countInput?.canEdit}
        canEditDie={sidesInput?.canEdit}
        canEditColor={colorInput?.canEdit}
        isBuilding
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onCountChange={onDiceCountChange}
        onDieChange={onDiceTypeChange}
        onColorChange={onDiceColorChange}
        onAddClick={() => setShowCreateModal(true)}
      />
      {showCreateModal && <CreateDiceModal onSubmit={onSubmit} onDismiss={() => setShowCreateModal(false)} />}
    </Builder>
  );
}

const MemoizedDicePoolBuilder = React.memo(DicePoolBuilder);
MemoizedDicePoolBuilder.displayName = 'MemoizedDicePoolBuilder';

export default MemoizedDicePoolBuilder;
