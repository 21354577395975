import styles from './EditTokenAssetModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { HEX_SIDE_LENGTH, MapType, SQUARE_SIDE_LENGTH } from 'models/Map';
import DocumentAction from 'store/actions/DocumentAction';

import { SVG } from '@svgdotjs/svg.js';
import Button from 'components/buttons/Button';
import { RadioInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import { defineGrid, extendHex } from 'honeycomb-grid';

const GRID_SIZE = 5;

class EditTokenAssetModal extends Component {
  state = { baseScale: 1.0 };
  containerRef = React.createRef();
  canvasRef = React.createRef();

  svgDraw = null;
  image = null;
  imagePos = { x: 0, y: 0 };
  prevPos = { x: 0, y: 0 };
  isDragging = false;

  componentDidMount() {
    this.svgDraw = SVG().addTo(this.containerRef.current).size(293, 293).attr('class', styles.grid);
    this.drawGrid();
    const el = this.canvasRef.current;
    el.addEventListener('mousedown', this.onCanvasMouseDown);
    el.addEventListener('mouseup', this.onCanvasMouseUp);
    el.addEventListener('mousemove', this.onCanvasMouseMove);
    el.addEventListener('touchstart', this.onTouchDown);
    el.addEventListener('touchmove', this.onTouchMove);
    el.addEventListener('touchend', this.onTouchUp);
    if (!this.image) this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gridType !== this.props.gridType) this.drawGrid();
    this.draw(prevProps.assetScale);
  }

  componentWillUnmount() {
    const el = this.canvasRef.current;
    el.removeEventListener('mousedown', this.onCanvasMouseDown);
    el.removeEventListener('mouseup', this.onCanvasMouseUp);
    el.removeEventListener('mousemove', this.onCanvasMouseMove);
    el.removeEventListener('touchstart', this.onTouchDown);
    el.removeEventListener('touchmove', this.onTouchMove);
    el.removeEventListener('touchend', this.onTouchUp);
  }

  getGridScale = () => {
    const { gridType } = this.props;
    const dpi = window.devicePixelRatio;
    const w = 293 * dpi;
    let gridScale = 1;

    if (gridType === MapType.SQUARE) {
      gridScale = w / (GRID_SIZE * SQUARE_SIDE_LENGTH * dpi);
    } else {
      const hexD2 = Math.sqrt(3) * HEX_SIDE_LENGTH;
      gridScale = w / (GRID_SIZE * hexD2 * dpi);
    }

    return gridScale;
  };

  loadImage = () => {
    const { asset } = this.props;
    if (!asset) return;
    this.image = new Image();
    this.image.onload = this.onImageLoad;
    this.image.src = asset.fileUrl;
  };

  draw = (prevAssetScale) => {
    const { baseScale } = this.state;
    const { assetBaseScale, assetScale } = this.props;
    const prevScale = prevAssetScale || assetScale;
    const scale = assetBaseScale || baseScale;
    const gridScale = this.getGridScale();
    const el = this.canvasRef.current;
    const context = el.getContext('2d');
    const { x, y } = this.imagePos;
    const { width, height } = this.image;
    const w = width * scale * assetScale * gridScale;
    const h = height * scale * assetScale * gridScale;
    let dx = 0;
    let dy = 0;
    if (prevScale !== assetScale) {
      const pw = width * scale * prevScale * gridScale;
      const ph = height * scale * prevScale * gridScale;
      dx = pw - w;
      dy = ph - h;
    }
    this.imagePos.x = x + dx / 2;
    this.imagePos.y = y + dy / 2;
    context.clearRect(0, 0, el.width, el.height);
    context.drawImage(this.image, this.imagePos.x, this.imagePos.y, w, h);
  };

  drawGrid = () => {
    this.svgDraw.clear();
    switch (this.props.gridType) {
      case MapType.HEX:
        this.drawHexGrid();
        break;

      case MapType.SQUARE:
        this.drawSquareGrid();
        break;

      default:
        break;
    }
  };

  drawSquareGrid = () => {
    const size = SQUARE_SIDE_LENGTH * this.getGridScale();
    const tiles = GRID_SIZE;
    const corners = [
      { x: 0, y: 0 },
      { x: size, y: 0 },
      { x: size, y: size },
      { x: 0, y: size },
    ];
    const symbol = this.svgDraw
      .symbol()
      .polygon(corners.map(({ x, y }) => `${x},${y}`))
      .fill('none')
      .stroke({ width: 2, color: 'var(--color-light-grey)' });
    for (let i = 0; i < tiles; i++) {
      for (let j = 0; j < tiles; j++) {
        const x = size * i;
        const y = size * j;
        this.svgDraw.use(symbol).translate(x, y - 2);
      }
    }
  };

  drawHexGrid = () => {
    const size = HEX_SIDE_LENGTH * this.getGridScale();
    const tiles = GRID_SIZE + 3;
    const Hex = extendHex({ size: size });
    const Grid = defineGrid(Hex);
    const corners = Hex().corners();
    const symbol = this.svgDraw
      .symbol()
      .polygon(corners.map(({ x, y }) => `${x},${y}`))
      .fill('none')
      .stroke({ width: 2, color: 'var(--color-light-grey)' });
    Grid.rectangle({ width: tiles, height: tiles }).forEach((hex) => {
      const { x, y } = hex.toPoint();
      this.svgDraw.use(symbol).translate(x, y - 93);
    });
  };

  isOnImage = (event) => {
    const { baseScale } = this.state;
    const { assetBaseScale, assetScale } = this.props;
    const scale = assetBaseScale || baseScale;
    const gridScale = this.getGridScale();

    const rect = this.canvasRef.current.getBoundingClientRect();
    const dpi = window.devicePixelRatio;
    const cx = (event.clientX - rect.left) * dpi;
    const cy = (event.clientY - rect.top) * dpi;
    const { width, height } = this.image;
    const w = width * scale * assetScale * gridScale;
    const h = height * scale * assetScale * gridScale;
    const { x, y } = this.imagePos;
    return cx >= x && cx <= x + w && cy >= y && cy <= y + h;
  };

  onCanvasMouseDown = (event) => {
    const rect = this.canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.prevPos = { x, y };
    if (this.isOnImage(event)) this.isDragging = true;
  };

  onTouchDown = (event) => {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.canvasRef.current.dispatchEvent(mouseEvent);
  };

  onCanvasMouseUp = (event) => {
    this.isDragging = false;
  };

  onTouchUp = (event) => {
    const mouseEvent = new MouseEvent('mouseup', {});
    this.canvasRef.current.dispatchEvent(mouseEvent);
  };

  onCanvasMouseMove = (event) => {
    if (this.isDragging) this.onDrag(event);
  };

  onTouchMove = (event) => {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.canvasRef.current.dispatchEvent(mouseEvent);
  };

  onDrag = (event) => {
    const rect = this.canvasRef.current.getBoundingClientRect();
    const dpi = window.devicePixelRatio;
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const dx = (x - this.prevPos.x) * dpi;
    const dy = (y - this.prevPos.y) * dpi;
    this.imagePos.x += dx;
    this.imagePos.y += dy;
    this.prevPos = { x, y };
    this.draw();
  };

  onImageLoad = () => {
    const { assetBaseScale, assetScale } = this.props;
    const { width: cw, height: ch } = this.canvasRef.current;
    const iw = this.image.width;
    const ih = this.image.height;
    const baseLength = SQUARE_SIDE_LENGTH * window.devicePixelRatio;
    const scale = baseLength / ih;
    const baseScale = assetBaseScale || scale;
    const gridScale = this.getGridScale();
    const w = iw * baseScale * assetScale * gridScale;
    const h = ih * baseScale * assetScale * gridScale;
    this.imagePos = { x: cw / 2 - w / 2, y: ch / 2 - h / 2 };
    this.draw();
    this.setState({ baseScale });
  };

  onSubmit = (formValues) => {
    const { baseScale } = this.state;
    const { asset, assetBaseScale, assetScale, gridType, updateDocument, onDismiss } = this.props;
    const document = {
      metadata: {
        baseScale: (assetBaseScale || baseScale) / window.devicePixelRatio,
        scale: assetScale,
        gridType,
      },
    };
    updateDocument(asset.id, { document }, () => onDismiss());
  };

  renderRadioInput = ({ input, label }) => {
    return <RadioInput input={input} label={label} className={styles.radioInput} />;
  };

  render() {
    const { onDismiss, handleSubmit } = this.props;
    const canvasSize = 289 * window.devicePixelRatio;

    return (
      <TitleModal title="Token Scale" subtitle="How big is this token on a map?" onDismiss={onDismiss}>
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <div ref={this.containerRef} className={styles.container}>
            <canvas ref={this.canvasRef} width={canvasSize} height={canvasSize} className={styles.canvas} />
            <Field component="input" name="scale" type="range" min={1} max={800} className={styles.rangeInput} />
          </div>
          <div className={styles.options}>
            <p>
              <strong>Grid Sample</strong>
            </p>
            <Field
              component={this.renderRadioInput}
              name="gridType"
              type="radio"
              label="Square"
              value={MapType.SQUARE}
            />
            <Field component={this.renderRadioInput} name="gridType" type="radio" label="Hex" value={MapType.HEX} />
          </div>
          <Button variant="primary" type="submit" className={styles.button}>
            Set Token Scale
          </Button>
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    asset: { metadata },
  } = props;
  const assetScale = metadata.scale || 1.0;
  const assetGridType = metadata.gridType || MapType.HEX;
  const formValues = getFormValues('editTokenAsset')(state);
  const gridType = formValues ? formValues.gridType : assetGridType;
  return {
    assetBaseScale: metadata.baseScale ? metadata.baseScale * window.devicePixelRatio : null,
    assetScale: formValues ? parseInt(formValues.scale) / 100.0 : assetScale,
    gridType,
    initialValues: {
      gridType,
      scale: assetScale * 100.0,
    },
  };
};

const mapDispatchToProps = {
  updateDocument: DocumentAction.update,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'editTokenAsset' })
)(EditTokenAssetModal);
