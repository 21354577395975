import { SubmissionError } from 'redux-form';

import { createAction, handleError } from './utilities';

import { processUserResponse } from './SessionAction';
import role from 'apis/role';

export default class UserAction {
  static FETCH = 'UserAction.FETCH';
  static UPDATE = 'UserAction.UPDATE';

  static fetch = (id) => async (dispatch) => {
    return role.get(`/users/${id}`).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(UserAction.FETCH, obj));
      },
      ({ response }) => handleError(dispatch, UserAction.FETCH, response)
    );
  };

  static fetchAffiliateStats = (username) =>
    role.get(`/users/${username}/affiliate_stats`).then((response) => response.data);

  static update = (id, formValues, callback) => async (dispatch) => {
    const formData = new FormData();
    if (formValues.username) formData.append('user[username]', formValues.username);
    if (formValues.email) formData.append('user[email]', formValues.email);
    if (formValues.password) formData.append('user[password]', formValues.password);
    if (formValues.avatar && formValues.avatar[0]) formData.append('user[avatar]', formValues.avatar[0]);
    if (formValues.metadata) formData.append('user[metadata]', formValues.metadata);

    return role.patch(`/users/${id}`, formData).then(
      (response) => {
        const { user } = processUserResponse(response.data);
        dispatch(createAction(UserAction.UPDATE, user));
        if (callback) callback(user);
      },
      ({ response }) => {
        handleError(dispatch, UserAction.UPDATE, response);

        const errors = {};
        for (const [key, value] of Object.entries(response.data.errors)) {
          const val = Array.isArray(value) ? value[0] : value;
          if (key === 'email') {
            if (val.includes('taken')) errors.email = 'Email already in use';
            else errors.email = 'Invalid email';
          } else if (key === 'username') {
            if (val.includes('taken')) errors.username = 'Username already in use';
            else errors.username = 'Invalid username';
          } else if (key === 'password') {
            errors.password = 'Invalid password';
          }
        }
        throw new SubmissionError(errors);
      }
    );
  };

  static updateJson = (id, formValues, callback) => async (dispatch) => {
    return role.patch(`/users/${id}`, formValues).then(
      (response) => {
        const { user } = processUserResponse(response.data);
        dispatch(createAction(UserAction.UPDATE, user));
        if (callback) callback(user);
      },
      ({ response }) => {
        handleError(dispatch, UserAction.UPDATE, response);

        const errors = {};
        for (const [key, value] of Object.entries(response.data.errors)) {
          const val = Array.isArray(value) ? value[0] : value;
          if (key === 'email') {
            if (val.includes('taken')) errors.email = 'Email already in use';
            else errors.email = 'Invalid email';
          } else if (key === 'username') {
            if (val.includes('taken')) errors.username = 'Username already in use';
            else errors.username = 'Invalid username';
          } else if (key === 'password') {
            errors.password = 'Invalid password';
          }
        }
        throw new SubmissionError(errors);
      }
    );
  };
}
