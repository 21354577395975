import styles from './ProductList.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

import ProductCard from 'components/cards/ProductCard';
import LoadingSpinner from 'components/LoadingSpinner';

type Props = {
  roomGuid?: string,
  products: any,
  isLoading?: boolean,
  isSmall?: boolean,
  isPlayPanel?: boolean,
  className?: string,
  isDetailView?: boolean,
};

function ProductList(props: Props): Node {
  const { products, isLoading, isPlayPanel, isSmall, roomGuid, className, isDetailView } = props;

  return (
    <div className={clsx('scrollbars-dark', styles.content, isLoading && styles.isLoading, className)}>
      {isLoading && products.length === 0 && (
        <LoadingSpinner color={isPlayPanel ? 'var(--color-white)' : 'var(--color-dark-text)'} />
      )}
      {products.map((product) => (
        <ProductCard
          product={product}
          key={product.id}
          isPlayPanel={isPlayPanel}
          isDetailView={isDetailView}
          isSmall={isSmall}
          roomGuid={roomGuid}
        />
      ))}
    </div>
  );
}

export default ProductList;
