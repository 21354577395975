import styles from './ToggleSwitch.module.css';

import React, { Component } from 'react';

import { linearGradient } from 'utilities/color';

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) this.setState({ checked: this.props.checked });
  }

  onChange = (event) => {
    const checked = !this.state.checked;
    this.setState({ checked });
    this.props.onChange(checked);
  };

  render() {
    const { checked } = this.state;
    const { label, ariaLabel, colors, className, disabled, variant } = this.props;
    const classNames = [styles.container, styles[variant]];
    if (checked) classNames.push(styles.isChecked);
    if (className) classNames.push(className);
    const style = colors ? { '--switch-background': linearGradient(colors) } : null;

    return (
      <label className={classNames.join(' ')} style={style}>
        {label && <span className={styles.label}>{label}</span>}
        <span className={styles.switchContainer}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            aria-label={ariaLabel || label}
            onChange={this.onChange}
            className={styles.input}
          />
          <span className={styles.switch} />
          <span className={styles.switchGradient} />
        </span>
      </label>
    );
  }
}

ToggleSwitch.defaultProps = {
  checked: false,
  disabled: false,
  variant: 'dark',
};

export default ToggleSwitch;
