import styles from './TableSettingsModal.module.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { ChannelEvent } from 'models/Channel';
import { ErrorAction, RoomAction, RoomUserAction, TableAction } from 'store/actions';
import { GameSelector, RoomSelector, SessionSelector, TableSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import ThemeEditor from 'components/ThemeEditor/ThemeEditor';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

class TableSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userColors: this.props.colors,
      isLoading: false,
      backgroundArtUrl: this.props.room.backgroundArtUrl,
      backgroundArtFile: null,
      themeColors: this.props.themeObj,
    };
  }

  onDismiss = () => {
    this.props.showSettings(false);
  };

  onRemovePassword = (event) => {
    event.preventDefault();
    const {
      currentUser,
      room: { guid, userId },
      removeAllErrors,
      updateRoom,
    } = this.props;
    if (userId !== currentUser.id) return this.onDismiss();
    removeAllErrors();
    updateRoom(guid, { room: { password: null } });
  };

  onClickObrDisconnect = () => {
    const {
      room: { guid: roomGuid },
      updateRoom,
      setObrActive,
      presenceChannel,
    } = this.props;

    updateRoom(
      roomGuid,
      {
        obr_url: null,
      },
      () => {
        setObrActive(false);
        presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: false });
      }
    );
  };

  onSubmit = (formValues) => {
    const {
      colors: oldUserColors,
      game,
      room: { backgroundArtUrl: defaultBackgroundArtUrl, gameId, guid, settings, userIds: roomUserIds },
      removeAllErrors,
      removeBackgroundArt,
      themeObj,
      updateBackgroundArt,
      updateRoom,
      updateRoomUser,
    } = this.props;

    const eventProperties = {
      'table guid': guid,
      'has game': !!gameId,
      'game title': game?.title,
    };

    const { userColors, backgroundArtUrl, backgroundArtFile, themeColors } = this.state;
    this.setState({ isLoading: true });
    removeAllErrors();

    if (backgroundArtFile) {
      updateBackgroundArt(guid, backgroundArtFile);
    } else if (!backgroundArtFile && !backgroundArtUrl) {
      removeBackgroundArt(guid);
    }
    if (!!backgroundArtFile && defaultBackgroundArtUrl !== backgroundArtUrl) {
      eventProperties['new background art'] = true;
      eventProperties['background file type'] = backgroundArtFile?.type;
    }

    if (!_.isEqual(themeColors, themeObj)) {
      eventProperties['new theme colors'] = true;
    }
    const newSettings = { ...settings, colors: userColors, theme: themeColors };
    updateRoom(guid, { room: { ...formValues, settings: newSettings } }, () => {
      if (!_.isEqual(oldUserColors, userColors)) {
        eventProperties['new user colors'] = true;
        roomUserIds.forEach((userId) => {
          const twoUserColors = _.sampleSize(userColors, 2);
          updateRoomUser(guid, userId, { room_user: { colors: { hex: [...twoUserColors] } } });
        });
      }
      logEvent('table - update room theme', { ...eventProperties });
      this.onDismiss();
    });
  };

  renderInput = ({ ref, input, type, label, placeholder, disabled, meta, readOnly }) => {
    const { submitFailed } = this.props;
    return (
      <label className={styles.inputContainer}>
        {label}
        <TextInput
          ref={ref}
          input={{ ...input, disabled }}
          meta={meta}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          readOnly={readOnly || false}
          showError={submitFailed}
          className={styles.input}
        />
      </label>
    );
  };

  render() {
    const { userColors, backgroundArtUrl, themeColors, isLoading } = this.state;
    const {
      currentUser,
      handleSubmit,
      room: { backgroundArtUrl: defaultBackgroundArtUrl, hasPassword, plainPassword, userId, obrUrl },
    } = this.props;
    const isOwner = userId === currentUser.id;
    const passwordLabel = hasPassword ? 'Change Password' : 'Add Password';
    const passwordPlaceholder = hasPassword && !plainPassword ? '************' : 'Lock this room behind a password...';

    return (
      <TitleModal
        maxWidth={900}
        title="Welcome To Your Room"
        subtitle="This page contains your room information. You can edit the room's name, password, color themes, and background art here at any time."
        actions={
          <>
            <Button variant="cancel" onClick={this.onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" isLoading={isLoading} onClick={handleSubmit(this.onSubmit)}>
              Save
            </Button>
          </>
        }
        onDismiss={this.onDismiss}
      >
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <Field
            component={this.renderInput}
            name="title"
            type="text"
            label={
              <span className={styles.label}>
                <strong>Room Name:</strong>
              </span>
            }
            placeholder="Give your world a name..."
            disabled={!isOwner}
          />
          {isOwner && (
            <>
              <Field
                component={this.renderInput}
                name="password"
                type="password"
                label={
                  <span className={styles.label}>
                    <strong>{passwordLabel}:</strong>
                  </span>
                }
                placeholder={passwordPlaceholder}
              />
              {hasPassword && !plainPassword && (
                <button onClick={this.onRemovePassword} className={`button-reset ${styles.removePassword}`}>
                  Remove Password
                </button>
              )}
            </>
          )}
        </form>
        <ThemeEditor
          backgroundArtUrl={backgroundArtUrl}
          themeColors={themeColors}
          userColors={userColors}
          removeBackgroundArt={() => this.setState({ backgroundArtUrl: null })}
          setBackgroundArtFile={(file) => {
            this.setState({ backgroundArtFile: file }, () => {
              this.setState({ backgroundArtUrl: file ? URL.createObjectURL(file) : defaultBackgroundArtUrl });
            });
          }}
          setThemeColors={(newThemeColors) => this.setState({ themeColors: newThemeColors })}
          setUserColors={(newUserColors) => this.setState({ userColors: newUserColors })}
        />

        {!!obrUrl && (
          <div style={{ maxWidth: 500, margin: '0 auto' }}>
            <div className={styles.obrHeader}>
              <span className={styles.label}>
                <strong>Owlbear Rodeo:</strong>
              </span>
              <Button
                primaryBackground={'var(--color-primary-main)'}
                icon={<XIcon />}
                iconSize="8"
                onClick={this.onClickObrDisconnect}
                isSmall
              >
                Disconnect OBR Room
              </Button>
            </div>
            <p style={{ marginTop: 18, fontSize: 14 }}>
              The OBR room "{obrUrl.split('/').pop().split('?')[0]}" is connected to this Role room.
            </p>
          </div>
        )}
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { gameId, guid, plainPassword: password, title } = props.room;
  const themeObj = TableSelector.getTheme(state, guid);
  const presenceChannel = TableSelector.getPresenceChannel(state);

  return {
    currentUser: SessionSelector.currentUser(state),
    game: gameId ? GameSelector.get(state, gameId) : null,
    initialValues: {
      title,
      password,
    },
    theme: `${themeObj.base},${themeObj.button},${themeObj.text},${themeObj.accent}`,
    themeObj,
    colors: RoomSelector.getColors(state, guid),
    presenceChannel,
  };
};

const mapDispatchToProps = {
  removeAllErrors: ErrorAction.removeAll,
  setTheme: TableAction.setTheme,
  showSettings: TableAction.showSettings,
  removeBackgroundArt: RoomAction.removeBackgroundArt,
  updateRoom: RoomAction.update,
  updateRoomUser: RoomUserAction.update,
  updateBackgroundArt: RoomAction.updateBackgroundArt,
  setObrActive: TableAction.setObrActive,
};

const validate = (values) => {
  const errors = {};
  if (!values.title || values.title.trim() === '') errors.title = 'Required';
  return errors;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'tableSettings', validate })
)(TableSettingsModal);
