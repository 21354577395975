import _ from 'lodash';
import { createSelector } from 'reselect';

import RoomSelector from './RoomSelector';
import RoomSheetSelector from './RoomSheetSelector';
import RoomUserSelector from './RoomUserSelector';

const sheets = (state) => state.sheets.models;
const orderByName = (obj) => obj.name.toLowerCase();

export default class SheetSelector {
  static editId = createSelector(
    (state) => state.sheets.editId,
    (id) => id
  );

  static get = (state, id) => state.sheets.models[id];

  static getAll = createSelector([sheets], (sheets) => _.orderBy(Object.values(sheets), [orderByName], ['asc']));

  static getWithPlaykitIds = createSelector(this.getAll, (models) => models.filter((m) => m.playkitIds.length > 0));

  // Game Materials - Sheets
  static getWithGameIds = createSelector(this.getAll, (models) => models.filter((m) => m.gameIds.length > 0));

  static getByRoom = createSelector(
    [RoomSelector.get, RoomSheetSelector.getByRoom, sheets],
    (room, roomSheets, sheets) => {
      if (!!!room) return [];
      const sheetIds = roomSheets.map((o) => o.id);
      const filteredSheets = Object.values(sheets).filter((o) => sheetIds.includes(o.id));
      return _.orderBy(filteredSheets, [orderByName], ['asc']);
    }
  );

  static getByRoomAndUser = createSelector(
    [RoomSelector.get, RoomUserSelector.get, RoomSheetSelector.getByRoomAndUser, sheets],
    (room, user, roomSheets, sheets) => {
      if (!room || !user) return [];
      const roomSheetIds = roomSheets.map((o) => o.id);
      const filtered = Object.values(sheets).filter((o) => roomSheetIds.includes(o.id));
      return _.sortBy(filtered, (o) => roomSheetIds.indexOf(o.id));
    }
  );

  static getEditableByRoomAndUser = createSelector(
    [RoomSelector.get, RoomUserSelector.get, RoomSheetSelector.getEditableByRoomAndUser, sheets],
    (room, user, roomSheets, sheets) => {
      if (!room || !user) return [];
      const roomSheetIds = roomSheets.map((o) => o.id);
      const filtered = Object.values(sheets).filter((o) => roomSheetIds.includes(o.id));
      return _.sortBy(filtered, (o) => roomSheetIds.indexOf(o.id));
    }
  );

  static getViewableByRoomAndUser = createSelector(
    [RoomSelector.get, RoomUserSelector.get, RoomSheetSelector.getViewableByRoomAndUser, sheets],
    (room, user, roomSheets, sheets) => {
      if (!room || !user) return [];
      const roomSheetIds = roomSheets.map((o) => o.id);
      const filtered = Object.values(sheets).filter((o) => roomSheetIds.includes(o.id));
      return _.sortBy(filtered, (o) => roomSheetIds.indexOf(o.id));
    }
  );

  static getForTile = createSelector(
    [RoomSelector.get, RoomUserSelector.get, RoomSheetSelector.getForTile, sheets],
    (room, user, roomSheets, sheets) => {
      if (!room || !user) return [];
      const roomSheetIds = roomSheets.map((o) => o.id);
      const filtered = Object.values(sheets).filter((o) => roomSheetIds.includes(o.id));
      return _.sortBy(filtered, (o) => roomSheetIds.indexOf(o.id))[0];
    }
  );

  static getAllByUser = (userId) => {
    return createSelector([sheets], (sheets) => {
      const filteredSheets = Object.values(sheets).filter((o) => o.userId === userId);
      return _.orderBy(filteredSheets, [orderByName], ['asc']);
    });
  };

  static getPlaykitSheetsByRoom = createSelector([RoomSelector.get, this.getWithPlaykitIds], (room, sheets) => {
    return sheets.filter((s) => _.intersection(room.playkitIds, s.playkitIds).length > 0);
  });

  static getGameSheetsByRoom = createSelector([RoomSelector.get, this.getWithGameIds], (room, sheets) => {
    return sheets.filter((s) => s.gameIds.includes(room.gameId));
  });

  static getGameSheetsByGameId = (gameId) => {
    return createSelector([this.getWithGameIds], (sheets) => {
      return sheets.filter((s) => s.gameIds.includes(gameId));
    });
  };

  static isSomeoneEditing = createSelector(
    (state, id, inputId) => {
      const model = state.sheets.editing[id];
      return !!(model && model.find((o) => o === inputId));
    },
    (bool) => bool
  );

  static isShowingMenu = createSelector(
    (state, id) => state.sheets.menuId && state.sheets.menuId === id,
    (bool) => bool
  );

  static menuId = createSelector(
    (state) => state.sheets.menuId,
    (id) => id
  );

  static transferId = createSelector(
    (state) => state.sheets.transferId,
    (id) => id
  );

  static getSheetToRemove = createSelector(
    [(state) => state.sheets.models, (state) => state.sheets.removeSheetId],
    (models, id) => (id ? models[id] : null)
  );

  static getSheetToUpdate = createSelector(
    [(state) => state.sheets.models, (state) => state.sheets.updateSheetId],
    (models, id) => (id ? models[id] : null)
  );
}
