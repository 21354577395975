// @flow
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import type { ElementModel, ElementType, InputModel, InputType, SectionModel } from 'components/Sheet2/types';
import { ELEMENT_ALIGNMENT, ELEMENT_TYPE, INPUT_TYPE } from 'constants/sheet';
import { DieType } from 'models/Die';

export const findElementPath = (sections, elementId) => {
  let section, element, groupElement;
  section = sections.find((section) => {
    element = section.elements.find((element) => {
      if (element.elementType === ELEMENT_TYPE.group) {
        groupElement = element.items.find((groupElement) => {
          return groupElement.id === elementId;
        });
        return groupElement;
      } else {
        return element.id === elementId;
      }
    });
    return element;
  });
  return { section, element, groupElement };
};

export const findInputPath = (sections, inputId) => {
  let section, element, groupElement, input;
  section = sections.find((section) => {
    element = section.elements.find((element) => {
      if (element.elementType === ELEMENT_TYPE.group) {
        if (!!element.metadata.collection) {
          const subgroup = element.metadata.collection.find((subgroup) => {
            groupElement = subgroup.items.find((subgroupElement) => {
              input = subgroupElement.items.find(({ id }) => id === inputId);
              return input;
            });
            return groupElement;
          });
          return subgroup;
        } else {
          groupElement = element.items.find((groupElement) => {
            input = groupElement.items.find(({ id }) => id === inputId);
            return input;
          });
        }
        return groupElement;
      } else {
        input = element.items.find(({ id }) => id === inputId);
        return input;
      }
    });
    return element;
  });
  return { section, element, groupElement, input };
};

export const createSection = (id: string, title: string = '', elements: ElementModel[] = []): SectionModel => {
  return {
    id,
    title,
    elements,
    isCollapsed: false,
  };
};

export const createElement = (id: string, elementType: ElementType): ElementModel => {
  return {
    id,
    parentId: null,
    elementType,
    items: [],
    links: [],
    columns: 2,
    canEdit: false,
    isSelected: false,
    isCollapsed: false,
    metadata: {},
  };
};

export const createInput = (id: string, inputType: InputType): InputModel => {
  return { id, inputType, value: null, defaultValue: null, canEdit: false };
};

export const generateElement = (elementType: ElementType): ElementModel => {
  const element = createElement(uuid(), elementType);
  switch (elementType) {
    case ELEMENT_TYPE.buff:
      const buff = { ...createInput(uuid(), INPUT_TYPE.buff), defaultValue: '0', canEdit: true };
      return { ...element, columns: 1, items: [...element.items, buff] };

    case ELEMENT_TYPE.clock:
      const clocks = _.times(6, () => ({
        ...createInput(uuid(), INPUT_TYPE.clockSlice),
        defaultValue: false,
        canEdit: true,
      }));
      return { ...element, columns: 1, items: [...element.items, ...clocks], canEdit: true };

    case ELEMENT_TYPE.counter:
      const counter = { ...createInput(uuid(), INPUT_TYPE.counterCurrent), defaultValue: '0', canEdit: true };
      return { ...element, items: [...element.items, counter] };

    case ELEMENT_TYPE.dicePool:
      const dicePool = [
        { ...createInput(uuid(), INPUT_TYPE.diceCount), defaultValue: '1', canEdit: true },
        { ...createInput(uuid(), INPUT_TYPE.diceSides), defaultValue: 20, canEdit: true },
        { ...createInput(uuid(), INPUT_TYPE.diceType), defaultValue: DieType.NUMBER, canEdit: true },
        { ...createInput(uuid(), INPUT_TYPE.diceColor), defaultValue: '', canEdit: true },
      ];
      return { ...element, columns: 1, items: [...element.items, ...dicePool] };

    case ELEMENT_TYPE.dropdown:
      const dropdown = { ...createInput(uuid(), INPUT_TYPE.dropdown), defaultValue: '', canEdit: true };
      return {
        ...element,
        columns: 1,
        items: [...element.items, dropdown],
        metadata: { ...element.metadata, options: [] },
      };

    case ELEMENT_TYPE.field:
      const field = { ...createInput(uuid(), INPUT_TYPE.field), defaultValue: '', canEdit: true };
      return { ...element, columns: 1, items: [...element.items, field] };

    case ELEMENT_TYPE.fieldBuff:
      const fieldBuffField = { ...createInput(uuid(), INPUT_TYPE.field), defaultValue: '', canEdit: true };
      const fieldBuffBuff = { ...createInput(uuid(), INPUT_TYPE.buff), defaultValue: '0', canEdit: true };
      return { ...element, columns: 1, items: [...element.items, fieldBuffField, fieldBuffBuff] };

    case ELEMENT_TYPE.image:
      const image = { ...createInput(uuid(), INPUT_TYPE.image), defaultValue: null };
      return {
        ...element,
        items: [...element.items, image],
        metadata: { ...element.metadata, alignment: ELEMENT_ALIGNMENT.fill },
      };

    case ELEMENT_TYPE.group:
      // TODO: fill out generating a group
      return element;

    case ELEMENT_TYPE.note:
      const note = { ...createInput(uuid(), INPUT_TYPE.note), defaultValue: '', canEdit: true };
      return { ...element, items: [...element.items, note] };

    case ELEMENT_TYPE.reference:
      const reference = { ...createInput(uuid(), INPUT_TYPE.reference), defaultValue: '0', canEdit: true };
      return { ...element, columns: 1, items: [...element.items, reference] };

    case ELEMENT_TYPE.slots:
      const slots = _.times(3, () => ({
        ...createInput(uuid(), INPUT_TYPE.slot),
        defaultValue: false,
        canEdit: true,
      }));
      return { ...element, items: [...element.items, ...slots], canEdit: true };

    case ELEMENT_TYPE.text:
      const heading = { ...createInput(uuid(), INPUT_TYPE.heading), defaultValue: '' };
      const paragraph = { ...createInput(uuid(), INPUT_TYPE.paragraph), defaultValue: '' };
      return {
        ...element,
        items: [...element.items, heading, paragraph],
        metadata: { ...element.metadata, alignment: ELEMENT_ALIGNMENT.left },
      };

    case ELEMENT_TYPE.divider:
    default:
      return element;
  }
};

export const generateHeadingInput = (value) => {
  return { ...createInput(uuid(), INPUT_TYPE.heading), defaultValue: value };
};

export const generateParagraphInput = (value) => {
  return { ...createInput(uuid(), INPUT_TYPE.paragraph), defaultValue: value };
};

export const generateToggleInput = () => {
  return { ...createInput(uuid(), INPUT_TYPE.toggle), defaultValue: false, canEdit: true };
};

export const generateDiceColorInput = () => {
  return { ...createInput(uuid(), INPUT_TYPE.diceColor), defaultValue: '', canEdit: true };
};

export const generateCounterMaxInput = () => {
  return { ...createInput(uuid(), INPUT_TYPE.counterMax), defaultValue: '1', canEdit: true };
};

export const deepCloneGroup = (group, setParentIds = false) => {
  const elementMap = {};
  const inputMap = {};
  group.items.forEach((element) => {
    elementMap[element.id] = uuid();
    element.items.forEach((input) => (inputMap[input.id] = uuid()));
  });
  const newGroup = generateElement(ELEMENT_TYPE.group);
  if (setParentIds) newGroup.parentId = group.id;
  newGroup.canEdit = true;
  newGroup.items = _.cloneDeep(group.items).map((element) => {
    return {
      ...element,
      id: elementMap[element.id],
      parentId: setParentIds ? element.id : null,
      items: element.items.map((input) => {
        return {
          ...input,
          id: inputMap[input.id],
          parentId: setParentIds ? input.id : null,
          value: null,
        };
      }),
      links: element.links.map((link) => {
        const linkElementId = elementMap[link.elementId];
        const linkInputId = inputMap[link.inputId];
        if (!!!link.dice && !!link.elementId && !!linkElementId)
          return { ...link, elementId: linkElementId, inputId: linkInputId };
        else return link;
      }),
    };
  });
  return newGroup;
};
