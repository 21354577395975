import _ from 'lodash';
import { createSelector } from 'reselect';

import COLOR, { NEW_COLOR } from 'models/Color';

import PlaykitSelector from './PlaykitSelector';
import RoomSelector from './RoomSelector';

export default class TableSelector {
  static activeAssetId = (state) => state.table.activeAssetId;

  static getActiveRoomDocument = createSelector(
    [(state) => state.roomDocuments, (state) => state.table.activeAssetId],
    (roomDocuments, guidId) => roomDocuments[guidId]
  );

  static getActiveSheet = createSelector(
    [(state) => state.sheets.models, (state) => state.table.activeSheetId],
    (sheets, id) => sheets[id]
  );

  static getActiveSheetUser = createSelector(
    [RoomSelector.get, (state) => state.roomUsers.models, (state) => state.table.activeSheetUserId],
    (room, users, userId) => room && users[`${room.guid}-${userId}`]
  );

  static getColors = createSelector(
    [RoomSelector.getColors, (state, roomGuid) => PlaykitSelector.getByRoom(state, roomGuid)],
    (roomColors, playkits) => {
      let colors = roomColors;
      if (colors.length === 0) colors = playkits.find((o) => o.colors?.users?.length > 0)?.colors.users;
      return colors || [];
    }
  );

  static getCurrentTab = createSelector(
    (state) => state.table.currentTab,
    (tab) => tab
  );

  static getEntered = (state) => state.table.entered;

  static getPresenceChannel = (state) => state.table.presenceChannel;

  static getPreviewRoom = createSelector(
    (state) => state.table.previewRoom,
    (room) => room
  );

  static getPreviewSheet = createSelector(
    (state) => state.table.previewSheet,
    (sheet) => sheet
  );

  static getPrivateRoomDocument = createSelector(
    [(state) => state.roomDocuments, (state) => state.table.privateAssetId],
    (roomDocuments, guidId) => roomDocuments[guidId]
  );

  static getRoomGuid = createSelector(
    (state) => state.table.roomGuid,
    (guid) => guid
  );

  static getRoomToRemove = createSelector(
    [(state) => state.rooms, (state) => state.table.removeTableGuid],
    (rooms, guid) => (guid ? rooms[guid] : null)
  );

  static getSharedRoomDocument = createSelector(
    [(state) => state.roomDocuments, (state) => state.table.sharedAssetId],
    (roomDocuments, guidId) => roomDocuments[guidId]
  );

  static getUserMenuId = createSelector(
    (state) => state.table.userMenuId,
    (id) => id
  );

  static getRemoveUserId = createSelector(
    (state) => state.table.removeUserId,
    (id) => id
  );

  static getTheme = createSelector(
    [
      RoomSelector.getTheme,
      (state, roomGuid) => PlaykitSelector.getByRoom(state, roomGuid),
      (state) => state.table.theme,
    ],
    (roomTheme, playkits, currentTheme) => {
      let theme = currentTheme;
      if (_.isEmpty(theme)) theme = roomTheme;
      if (_.isEmpty(theme)) theme = playkits.find((o) => !!o.colors.theme)?.colors.theme;
      if (_.isEmpty(theme))
        theme = {
          base: NEW_COLOR.DARK.MID,
          button: NEW_COLOR.PRIMARY.DARK,
          text: NEW_COLOR.DARK.ACCENT,
          accent: COLOR.PURPLE,
        };
      return theme;
    }
  );

  static getViewMode = createSelector(
    (state) => state.table.viewMode,
    (mode) => mode
  );

  static isActiveUserPinned = (state) => state.table.isActiveUserPinned;

  static isAddingSheet = (state) => state.table.isAddingSheet;

  static isObrActive = (state) => state.table.isObrActive;

  static isPlayPanelOpen = (state) => state.table.playPanelContent;

  static getPlayPanelContent = createSelector(
    (state) => state.table.playPanelContent,
    (playPanelContent) => playPanelContent
  );

  static isFocusMode = (state) => state.table.isFocusMode;

  static isOpenDraw = createSelector(
    (state, guid) => state.rooms[guid].settings.openDraw || false,
    (isOpen) => isOpen
  );

  static isPrivateRoll = (state) => state.table.privateRoll;

  static shouldClearAfterRoll = createSelector(
    (state) => state.table.shouldClearAfterRoll,
    (clear) => clear
  );

  static isShowingBuffs = createSelector(
    (state) => state.table.isShowingBuffs,
    (bool) => bool
  );

  static isShowingChat = createSelector(
    (state) => state.table.playPanelContent,
    (playPanelContent) => playPanelContent === 'Chat'
  );

  static isShowingDiceTotal = createSelector(
    (state) => state.table.showDiceTotal,
    (show) => show
  );

  static isShowingDiceTray = createSelector(
    (state) => state.table.playPanelContent,
    (playPanelContent) => playPanelContent === 'Dice'
  );

  static isShowingInvite = createSelector(
    (state) => state.table.isShowingInvite,
    (bool) => bool
  );

  static isShowingManageUsers = createSelector(
    (state) => state.table.isShowingManageUsers,
    (bool) => bool
  );

  static isShowingPing = createSelector(
    (state) => state.table.isShowingPing,
    (bool) => bool
  );

  static isShowingRoomSheetsMenu = createSelector(
    (state) => state.table.isShowingRoomSheetsMenu,
    (show) => show
  );

  static isShowingSheet = createSelector(
    (state) => state.table.showSheet,
    (show) => show
  );

  static isShowingSheetTOC = createSelector(
    (state) => state.table.showSheetTOC,
    (show) => show
  );

  static isShowingSheetMenu = createSelector(
    (state) => state.table.showSheetMenu,
    (show) => show
  );

  static isShowingSheets = createSelector(
    (state) => state.table.showSheets,
    (show) => show
  );

  static isShowingTargets = createSelector(
    (state) => state.table.isShowingTargets,
    (bool) => bool
  );

  static isShowingUserSettings = createSelector(
    (state) => state.table.isShowingUserSettings,
    (show) => show
  );

  static showSettingsModal = createSelector(
    (state) => state.table.showSettingsModal,
    (show) => show
  );

  static showVideoSettingsModal = createSelector(
    (state) => state.table.showVideoSettingsModal,
    (show) => show
  );

  static showCardDrawer = createSelector(
    (state) => state.table.showCardDrawer,
    (show) => show
  );
}
