// @flow
import styles from './Group.module.css';

import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import type { ElementModel, ElementProps } from 'components/Sheet2/types';
import { insertItem, moveItem, removeItem } from 'utilities';
import { deepCloneGroup } from 'utilities/sheet';

import Group from './Group';
import Button from 'components/buttons/Button';
import { ReactComponent as DragIcon } from 'images/icons/DragIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/TrashIcon.svg';

type ModelProps = {
  ...ElementModel,
  items: ElementModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function GroupCollection(props: Props): Node {
  const { colors, element: group, images, isDraft, onChange, readOnly, sectionId, sheetGuid, roomGuid } = props;
  const {
    id,
    metadata: { collection },
  } = group;
  const droppableId = `group-${id}`;
  const [draftCollection, setDraftCollection] = useState([]);

  useEffect(() => {
    if (!collection || collection.length > 0) return;
    const newGroup = deepCloneGroup(group, true);
    const updatedCollection = [newGroup];
    const updatedGroup = { ...group, metadata: { ...group.metadata, collection: updatedCollection } };
    isDraft || readOnly ? setDraftCollection(updatedCollection) : onChange(sheetGuid, sectionId, updatedGroup);
  }, [collection, group, isDraft, onChange, readOnly, sectionId, sheetGuid]);

  const onAddClick = useCallback(
    (index) => {
      const newGroup = deepCloneGroup(group, true);
      const updatedCollection = insertItem(collection, index + 1, newGroup);
      const updatedGroup = { ...group, metadata: { ...group.metadata, collection: updatedCollection } };
      onChange(sheetGuid, sectionId, updatedGroup);
    },
    [collection, group, onChange, sectionId, sheetGuid]
  );

  const onChangeGroup = useCallback(
    (sheetId, sectionId, subgroup) => {
      const updatedCollection = collection.map((o) => (o.id === subgroup.id ? subgroup : o));
      const updatedGroup = { ...group, metadata: { ...group.metadata, collection: updatedCollection } };
      onChange(sheetId, sectionId, updatedGroup);
    },
    [collection, group, onChange]
  );

  const onChangeGroupElement = useCallback(
    (sheetId, sectionId, subgroupId, subelement, callback) => {
      const updatedCollection = collection.map((o) => {
        if (o.id === subgroupId) {
          return { ...o, items: o.items.map((e) => (e.id === subelement.id ? subelement : e)) };
        }
        return o;
      });
      const updatedGroup = { ...group, metadata: { ...group.metadata, collection: updatedCollection } };
      onChange(sheetId, sectionId, updatedGroup);
    },
    [collection, group, onChange]
  );

  const onDeleteClick = useCallback(
    (index) => {
      const updatedCollection = removeItem(collection, index);
      const updatedGroup = { ...group, metadata: { ...group.metadata, collection: updatedCollection } };
      onChange(sheetGuid, sectionId, updatedGroup);
    },
    [collection, group, onChange, sectionId, sheetGuid]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;
      if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index))
        return;
      const updatedGroup = {
        ...group,
        metadata: { ...group.metadata, collection: moveItem(collection, source.index, destination.index) },
      };
      onChange(sheetGuid, sectionId, updatedGroup);
    },
    [collection, group, onChange, sectionId, sheetGuid]
  );

  const renderGroup = useCallback(
    (provided, element, index) => {
      return (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${styles.draggableContainer} ${readOnly ? styles.readOnly : ''}`}
        >
          <div {...provided.dragHandleProps} className={styles.dragHandle}>
            <DragIcon />
          </div>
          <Group
            className={styles.collectionGroup}
            element={element}
            images={images}
            index={index}
            colors={colors}
            sectionId={sectionId}
            sheetGuid={sheetGuid}
            roomGuid={roomGuid}
            readOnly={readOnly}
            onChange={onChangeGroup}
            onChangeGroup={onChangeGroupElement}
          />
          <div className={styles.actions}>
            <Button
              className={`${styles.actionButton} ${styles.groupActionButton}`}
              primaryBackground="rgb(var(--color-sheet-button))"
              color="rgb(var(--color-sheet-text))"
              hoverColor="var(--color-white)"
              onClick={() => onAddClick(index)}
            >
              <span className={styles.actionButtonContent}>
                <PlusIcon />
                Add
              </span>
            </Button>
            <Button
              className={`${styles.actionButton} ${styles.groupActionButton}`}
              primaryBackground="rgb(var(--color-sheet-button))"
              color="rgb(var(--color-sheet-text))"
              hoverColor="var(--color-white)"
              onClick={() => onDeleteClick(index)}
            >
              <span className={styles.actionButtonContent}>
                <TrashIcon />
                Delete
              </span>
            </Button>
          </div>
        </div>
      );
    },
    [
      colors,
      images,
      onAddClick,
      onChangeGroup,
      onChangeGroupElement,
      onDeleteClick,
      readOnly,
      roomGuid,
      sectionId,
      sheetGuid,
    ]
  );

  const groupCollectionRenderClone = useCallback(
    (provided, snapshot, rubric) => {
      const collectionToRender = collection.length === 0 && (isDraft || readOnly) ? draftCollection : collection;
      return renderGroup(provided, collectionToRender[rubric.source.index], rubric.source.index);
    },
    [collection, draftCollection, isDraft, readOnly, renderGroup]
  );

  const collectionToRender = collection.length === 0 && (isDraft || readOnly) ? draftCollection : collection;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} type={droppableId} renderClone={groupCollectionRenderClone}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {collectionToRender.map((element, index) => (
              <Draggable key={element.id} draggableId={`group-${element.id}`} index={index}>
                {(draggableProvided) => renderGroup(draggableProvided, element, index)}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default GroupCollection;
