//@flow
import styles from 'components/layouts/SessionPage.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import ErrorAction from 'store/actions/ErrorAction';
import SessionAction from 'store/actions/SessionAction';
import SessionSelector from 'store/selectors/SessionSelector';
import type { location } from 'types/common';

import requireNoAuth from 'hocs/requireNoAuth';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import SessionPage from 'components/layouts/SessionPage';
import Metatags from 'components/Metatags';

type Props = {
  handleSubmit: (a: any) => void,
  location: location,
  submitFailed: boolean,
};

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = 'Required';
  return errors;
};

function PasswordResetPage(props: Props) {
  const {
    handleSubmit,
    location: { search },
    submitFailed,
  } = props;
  const [showPasswordRule, setShowPasswordRule] = useState(false);
  const dispatch = useDispatch();
  const errors = useSelector(SessionSelector.getPasswordResetError);
  const passwordRuleClassNames = [styles.signUpRule];
  if (showPasswordRule) passwordRuleClassNames.push(styles.activeRule);

  useEffect(() => {
    return () => {
      dispatch(SessionAction.clearRequestedPasswordReset);
    };
  }, [dispatch]);

  const onSubmit = (formValues) => {
    const token = new URLSearchParams(search).get('token');
    if (errors.length > 0) dispatch(ErrorAction.removeAll);
    dispatch(SessionAction.resetPassword({ user: { ...formValues, reset_password_token: token } }));
  };

  const renderInput = useMemo(
    () =>
      ({ input, type, placeholder, meta, pattern }) => {
        return (
          <TextInput
            input={input}
            meta={meta}
            pattern={pattern}
            placeholder={placeholder}
            showError={submitFailed}
            type={type}
            variant="simple"
          />
        );
      },
    [submitFailed]
  );

  return (
    <>
      <Metatags title="Password Reset" />

      <SessionPage title="Role Password Reset">
        <div className={styles.signUpHeader}>
          <h1>Ready to reset your password?</h1>
          <span>Enter in a new password below and then you will be able to log in again.</span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.signUpForm}>
          <div className={styles.fieldContainer}>
            <Field
              component={renderInput}
              name="password"
              type="password"
              placeholder="Password"
              pattern=".{6,}"
              onFocus={(e) => {
                setShowPasswordRule(true);
              }}
            />
            <p className={passwordRuleClassNames.join(' ')}>Your password must be at least 6 characters long.</p>
          </div>
          {errors.length > 0 && <p className={styles.error}>{errors[0]}</p>}
          <Button variant="primary" className={styles.ctaButton} type="submit">
            Reset Password
          </Button>
        </form>
        <div className={styles.footerLink}>
          <Link to={'/login'}>
            Remember your password? <span className={styles.highlight}>Log in here!</span>
          </Link>
        </div>
      </SessionPage>
    </>
  );
}

export default compose(requireNoAuth, reduxForm({ form: 'passwordReset', validate }))(PasswordResetPage);
