import styles from './TableUserDice.module.css';

import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ChannelEvent } from 'models/Channel';
import { DICE_COLOR_LABELS } from 'models/Color';
import DieModel, { DieType } from 'models/Die';
import { RollAction } from 'store/actions';
import { RollSelector, SessionSelector, TableSelector } from 'store/selectors';
import { RoomUserModel } from 'types/common';
import { linearGradient } from 'utilities/color';
import { getFateSymbol, getFateValue } from 'utilities/dice';

import IconButton from 'components/buttons/IconButton';
import { DieIcon } from 'components/DiceRoller';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

interface TableUserDiceProps {
  className?: string;
  colors?: [string, string];
  user: RoomUserModel;
  room: any;
}

export default function TableUserDice({ className, colors, user, room }: TableUserDiceProps) {
  const dispatch = useDispatch();

  const currentUser = useSelector(SessionSelector.currentUser);
  const isSelf = user.userId === currentUser.id;
  const isHost = currentUser.id === room?.userId;

  const dice = useSelector((state) =>
    // @ts-ignore RollSelector is not yet typed
    isSelf ? RollSelector.getDice(state, 'color') : RollSelector.getRollingUserDice(state, user.userId)
  );
  const buff = useSelector((state) =>
    // @ts-ignore RollSelector is not yet typed
    isSelf ? RollSelector.getBuffsTotal(state) : RollSelector.getRollingUserBuffsTotal(state, user.userId)
  );
  const target = useSelector((state) =>
    // @ts-ignore RollSelector is not yet typed
    isSelf ? RollSelector.getTargetsTotal(state) : RollSelector.getRollingUserTargetsTotal(state, user.userId)
  );

  const isShowingTotal = useSelector(TableSelector.isShowingDiceTotal);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const diceTotal = dice.reduce((total, die) => {
    const value = die.hasRolled ? (die.type === DieType.FATE ? getFateValue(die.value) : die.value) : 0;
    return total + value;
  }, 0);
  const total = diceTotal + buff;

  const totalGradient = linearGradient(colors, '180deg');

  const isPrivateRoll = useSelector(TableSelector.isPrivateRoll);
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);

  const onClear = useCallback(() => {
    batch(() => {
      if (isSelf) {
        dispatch(RollAction.removeAllDice());
        dispatch(RollAction.unselectAllModifiers());
      } else {
        dispatch(RollAction.removeAllUserDice(user.userId));
        dispatch(RollAction.removeAllUserModifiers(user.userId));
      }
    });
    if (!isPrivateRoll && presenceChannel)
      presenceChannel.trigger(ChannelEvent.ROLL_COMPLETED, {
        userId: user.userId,
      });
  }, [dispatch, isPrivateRoll, isSelf, presenceChannel, user.userId]);

  return (
    <div
      className={clsx(className, styles.container, isCollapsed && styles.collapsed, dice.length === 0 && styles.hidden)}
    >
      <div className={styles.collapsedIcon} data-role="collapsed-icon">
        <IconButton
          background="transparent"
          color="rgb(var(--color-theme-text))"
          children={<D20Icon />}
          iconSize={24}
          onClick={() => setIsCollapsed(false)}
        />
      </div>
      <div className={styles.slidingContainer} data-role="sliding-container">
        <div className={styles.actions}>
          <IconButton
            buttonSize={20}
            background="transparent"
            color="rgb(var(--color-theme-text))"
            iconSize={12}
            children={isCollapsed ? <VisibleIcon /> : <NotVisibleIcon />}
            onClick={() => setIsCollapsed((collapsed) => !collapsed)}
          />
          {(isSelf || isHost) && (
            <IconButton
              buttonSize={20}
              background="transparent"
              iconSize={8}
              color="rgb(var(--color-theme-text))"
              children={<XIcon />}
              onClick={onClear}
            />
          )}
        </div>
        <div className={clsx(styles.content, isShowingTotal && styles.showTotal)}>
          <div className={styles.equation}>
            {target !== 0 && <Target target={target} />}
            {dice.map((die, i) => (
              <>
                <Die die={die} key={die.id} />
                {dice.length > 1 && i < dice.length - 1 && <div className={styles.operator}>+</div>}
              </>
            ))}
            {buff !== 0 && <Buff buff={buff} />}
          </div>
          {isShowingTotal && <div className={clsx(styles.operator)}>=</div>}
          <div className={styles.total}>
            <div className={styles.gradientBorder} style={{ background: totalGradient }} />
            <div className={styles.value}>{total}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Buff({ buff }: { buff: number }) {
  return (
    <>
      <div className={styles.operator}>{buff > 0 ? '+' : '-'}</div>
      <div className={clsx(styles.buff)}>
        <div className={styles.value}>{Math.abs(buff)}</div>
      </div>
    </>
  );
}

function Target({ target }: { target: number }) {
  return (
    <div className={clsx(styles.target)}>
      <div className={styles.value}>{target}</div>
      <div className={styles.vs}>vs</div>
    </div>
  );
}

function Die({ die }: { die: DieModel }) {
  const isFate = die.type === DieType.FATE;
  const value = isFate ? getFateSymbol(die.value) : die.value;
  const dieLabel = `${die.color ? DICE_COLOR_LABELS[die.color] + ' ' : ''}${die.name} (Value: ${value})`;

  return (
    <div
      aria-label={dieLabel}
      title={dieLabel}
      className={styles.die}
      style={{ '--die-color': die.color || undefined }}
    >
      <DieIcon die={die} className={styles.dieIcon} />
      {die.hasRolled && <div className={clsx(styles.value, isFate && styles.isFate)}>{value}</div>}
    </div>
  );
}
