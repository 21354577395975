// @flow
import styles from './atoms.module.css';

import React from 'react';
import type { Node } from 'react';
import { linearGradient } from 'utilities/color';

type Props = {
  className?: ?string,
  primaryColor?: string,
  secondaryColor?: string,
  type: 'buff' | 'clock' | 'pool',
};

export default function GradientHexagon(props: Props): Node {
  const { className, primaryColor, secondaryColor, type } = props;
  const classNames = [styles.spacerContainer, styles[type]];
  const style = {};
  if (className) classNames.push(className);
  if (primaryColor && secondaryColor) style['background'] = linearGradient([primaryColor, secondaryColor], '180deg');

  return (
    <div className={classNames.join(' ')}>
      <div className={styles.spacerMask}>
        <div style={style} className={styles.spacer} />
      </div>
    </div>
  );
}
