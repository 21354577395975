// @flow

import styles from './PurchaseSuccessModal.module.css';

import React, { useCallback, useEffect } from 'react';

import { isMobile, logEvent } from 'utilities';
import * as fbq from 'utilities/fbq';
import { formatCents } from 'utilities/numbers';

import Button from 'components/buttons/Button';
import IconTitleButton from 'components/buttons/IconTitleButton';
import { TitleModal } from 'components/Modal';
import { getBundleItemIcon } from 'components/ProductDetails/BundleItemIcon';
import { ReactComponent as D20Icon } from 'images/dice/D20.svg';

type Props = {
  onDismissModal: () => void,
  game: any,
  product: any,
  productArtUrl: any,
  organization: string,
  room?: any,
  source: string,
};

function PurchaseSuccessModal({ onDismissModal, game, product, productArtUrl, organization, room, source }: Props) {
  const logCreateNewGameRoomEvent = useCallback(() => {
    logEvent('product - click create room', {
      'product id': product.id,
      'game id': product.gameId,
      'game title': game?.title,
      'source': `${source} page - purchase modal`,
      'is unlocked': product.isUnlocked,
    });
  }, [product?.id, product?.gameId, product?.isUnlocked, game?.title, source]);

  const price = () => {
    switch (source) {
      case 'games':
        return 'FREE - Kickstarter Reward';
      case 'product':
      default:
        return product.price > 0 ? formatCents(product.price) : 'FREE';
    }
  };

  const hasItems = product.itemsList.platform || product.itemsList.download;

  useEffect(() => {
    logEvent('product - purchase', {
      'product id': product.id,
      'game id': game.id,
      'game title': game.title,
    });

    if (fbq.enabled()) {
      fbq.track('Purchase', {
        content_name: product.title,
        content_ids: [game.id, product.guid],
        currency: 'USD',
        value: (product.price / 100).toFixed(2),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TitleModal
      title="Game Purchase Successful"
      onDismiss={onDismissModal}
      actions={
        isMobile && (
          <Button variant="cancel" onClick={onDismissModal}>
            Close
          </Button>
        )
      }
    >
      <div className={styles.content}>
        <p className={styles.text}>
          Thank you for supporting {organization === 'Role, Inc.' ? 'our games' : <strong>{organization}</strong>} on
          Role. Your copy of <strong>{product.title}</strong> has been added to your account.
        </p>
        <div className={styles.body}>
          <div className={styles.image}>
            <img src={productArtUrl} alt={product.title} />
          </div>
          <div className={styles.info}>
            <h4>
              {product.title}
              <br />
              {price()}
            </h4>
            {hasItems && (
              <>
                <h4>Includes:</h4>
                <ul>
                  {product.itemsList.platform &&
                    Object.entries(product.itemsList.platform).map(([item, label], i) => (
                      <li key={i}>
                        {getBundleItemIcon(item)}
                        {label}
                      </li>
                    ))}
                  {product.itemsList.download &&
                    Object.entries(product.itemsList.download).map(([item, label], i) => (
                      <li key={i}>
                        {getBundleItemIcon(item)}
                        {label}
                      </li>
                    ))}
                </ul>
              </>
            )}
          </div>
        </div>
        <div>
          <p className={styles.text}>
            {room ? (
              <>
                Your room <strong>{room.title}</strong> has now been updated with your purchased content!
              </>
            ) : (
              `Your adventure awaits. Invite your party and start playing now!`
            )}
          </p>
        </div>
        <div className={styles.buttons}>
          {product.isActive && !isMobile && (
            <>
              {room && (
                <IconTitleButton to={`/rooms/${room.guid}`}>
                  <>
                    <D20Icon />
                    <span>
                      <span>Latest Room</span>
                      <span>Play Now</span>
                    </span>
                  </>
                </IconTitleButton>
              )}
              <IconTitleButton
                onClick={logCreateNewGameRoomEvent}
                to={`/games/${game?.slug}/${product.slug}/rooms/new`}
                variant="red"
              >
                <>
                  <D20Icon />
                  <span>
                    <span>Owned</span>
                    <span>Start New</span>
                  </span>
                </>
              </IconTitleButton>
            </>
          )}
        </div>
      </div>
    </TitleModal>
  );
}

export default PurchaseSuccessModal;
