import _ from 'lodash'

import RoomSheetAction from 'store/actions/RoomSheetAction';

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action
  if (error) return state

  switch (type) {
    case RoomSheetAction.DELETE:
    case RoomSheetAction.REMOVE:
      return _.omit(state, `${payload.guid}-${payload.id}`)

    case RoomSheetAction.CREATE:
    case RoomSheetAction.FETCH:
    case RoomSheetAction.UPDATE:
      return { ...state, [`${payload.guid}-${payload.id}`]: payload }

    case RoomSheetAction.FETCH_ALL:
      return { ...state, ..._.mapKeys(payload, 'guidId') }

    default:
      return state
  }
}
