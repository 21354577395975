import styles from './TableSheetSelect.module.css';

import { Popover, Portal } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { batch, useDispatch, useSelector } from 'react-redux';

import { PERMISSIONS } from 'constants/room_sheet';
import useWindowSize from 'hooks/useWindowSize';
import { TableAction } from 'store/actions';
import { RoomSheetSelector, RoomUserSelector, SessionSelector, SheetSelector, TableSelector } from 'store/selectors';
import { RoomTab } from 'types/room';
import { getUserColors, stylizeTheme } from 'utilities/color';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as CheckIcon } from 'images/icons/CheckIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as SheetIcon } from 'images/icons/SheetIcon.svg';

export default function TableSheetSelect({ roomGuid, theme, onAddSheet }) {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const currentUser = useSelector(SessionSelector.currentUser);
  const userColors = useSelector((state) => RoomUserSelector.getColors(state, roomGuid, currentUser.id));
  const userSheets = useSelector((state) => SheetSelector.getByRoomAndUser(state, roomGuid, currentUser.id));
  const editorSheets = useSelector((state) => SheetSelector.getEditableByRoomAndUser(state, roomGuid, currentUser.id));
  const viewerSheets = useSelector((state) => SheetSelector.getViewableByRoomAndUser(state, roomGuid, currentUser.id));
  const isActive = useSelector(TableSelector.isShowingRoomSheetsMenu);

  const onClick = useCallback(() => {
    dispatch(TableAction.showRoomSheetsMenu(!isActive));
  }, [dispatch, isActive]);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const onSelectSheet = (sheetId, sheetUserId) => {
    batch(() => {
      dispatch(TableAction.setActiveSheet(sheetId));
      dispatch(TableAction.setActiveSheetUser(sheetUserId));
      if (!size.isMd) dispatch(TableAction.setCurrentTab(RoomTab.Sheets));
    });
  };

  const renderCollection = (title, collection) => {
    return (
      <div className={styles.collection}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.list}>
          {collection.map((sheet) => (
            <SheetItem key={sheet.id} roomGuid={roomGuid} sheet={sheet} onSelect={onSelectSheet} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Popover>
      <IconButton
        className={styles.selectSheetButton}
        color="rgb(var(--color-theme-text))"
        background="rgb(var(--color-theme-button))"
        hoverBackground="rgb(var(--color-theme-accent))"
        label="View User Sheets"
        iconSize={22}
        onClick={onClick}
        children={<SheetIcon />}
        ref={setReferenceElement}
      />

      {isActive && (
        <Portal>
          <Popover.Panel
            static
            className={clsx('box-shadow', styles.popper)}
            ref={setPopperElement}
            style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
            {...attributes.popper}
          >
            <div className={clsx('scrollbars-dark', styles.content)}>
              {userSheets.length > 0 && <Fragment children={renderCollection('Your Sheets', userSheets)} />}
              {editorSheets.length > 0 && <Fragment children={renderCollection('Sheets You Can Edit', editorSheets)} />}
              {viewerSheets.length > 0 && <Fragment children={renderCollection('Sheets You Can View', viewerSheets)} />}
              {userSheets.length + editorSheets.length + viewerSheets.length === 0 && (
                <div className={styles.empty}>
                  <h3>There are no sheets for you to view yet</h3>
                  <p>Add a new sheet to use or share with your party:</p>
                </div>
              )}
            </div>
            <Button
              icon={<PlusIcon />}
              primaryBackground={userColors[0]}
              secondaryBackground={userColors[1]}
              onClick={onAddSheet}
            >
              New Sheet
            </Button>
          </Popover.Panel>
        </Portal>
      )}
    </Popover>
  );
}

function SheetItem({ roomGuid, sheet, onSelect }) {
  const activeSheet = useSelector(TableSelector.getActiveSheet);
  const currentUser = useSelector(SessionSelector.currentUser);
  const roomUser = useSelector((state) => RoomUserSelector.get(state, roomGuid, sheet.userId));
  const roomSheet = useSelector((state) => RoomSheetSelector.get(state, roomGuid, sheet.id));

  if (!roomUser || !roomSheet) return null;

  let status = '';
  if (roomSheet.permissions.viewers?.length > 0) {
    if (roomSheet.permissions.viewers?.[0] === PERMISSIONS.everyone) {
      status += 'Viewers: Everyone';
    } else {
      status += 'Viewers: You + Host';
    }
  } else {
    status += 'Viewers: Only You';
  }
  if (roomSheet.permissions.editors?.length > 0) {
    if (roomSheet.permissions.editors?.[0] === PERMISSIONS.everyone) {
      status += ' · Editors: Everyone';
    } else {
      status += ' · Editors: You + Host';
    }
  } else {
    status += ' · Editors: Only You';
  }
  if (roomSheet.isPrimary) status += ' · Primary';

  return (
    <div key={sheet.id} className={styles.listItem} onClick={() => onSelect(sheet.id, roomUser.userId)}>
      <Avatar
        avatarUrl={sheet.avatarUrl}
        avatarAlt={sheet.name}
        size={36}
        borderColors={getUserColors(roomUser)}
        borderSize={2}
        shape="squircle"
        showPlayerDefault
      />
      <div className={styles.sheetInfo}>
        <div className={styles.sheetName}>{sheet.name}</div>
        {sheet.userId === currentUser.id ? (
          <span className={styles.sheetStatus}>{status}</span>
        ) : (
          <div className={styles.sheetOwner}>{roomUser.displayName}</div>
        )}
      </div>
      {activeSheet && sheet.id === activeSheet.id && (
        <div className={styles.selected}>
          <CheckIcon width={12} />
        </div>
      )}
    </div>
  );
}
