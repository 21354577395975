import styles from './TableChat.module.css';

import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { ChannelEvent } from 'models/Channel';
import { PING_TOKEN } from 'models/Twilio';
import { TableAction, TwilioAction } from 'store/actions';
import { RoomUserSelector, SessionSelector, TableSelector, TwilioSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import Conversation from './Conversation';
import EverythingConversation from './EverythingConversation';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import { DropdownInput } from 'components/inputs';
import LoadingSpinner from 'components/LoadingSpinner';
import { ReactComponent as PingIcon } from 'images/icons/PingIcon.svg';
import { ReactComponent as SendIcon } from 'images/icons/SendIcon.svg';

class TableChat extends Component {
  state = { message: '' };
  sendButtonRef = React.createRef();
  heartbeatTimer = null;

  componentDidMount() {
    const { room, connectChat, createChat, createDiceChat } = this.props;
    connectChat(async () => {
      await Promise.all([createChat(room.guid), createDiceChat(room.guid)]);
      this.setupClient();
    });
  }

  componentDidUpdate(prevProps) {
    const { diceConversation, everyoneConversation, isShowingChat, selectedConversation, selectedConversationName } =
      this.props;
    const { updateUnreadCount } = this.props;

    if (!prevProps.isShowingChat && isShowingChat) {
      if (selectedConversationName !== 'Everything' && selectedConversation && selectedConversation.lastMessage) {
        selectedConversation.updateLastReadMessageIndex(selectedConversation.lastMessage.index);
        updateUnreadCount(selectedConversation.sid, 0);
      } else if (selectedConversationName === 'Everything') {
        if (everyoneConversation && everyoneConversation.lastMessage) {
          everyoneConversation.updateLastReadMessageIndex(everyoneConversation.lastMessage.index);
          updateUnreadCount(everyoneConversation.sid, 0);
        }
        if (diceConversation && diceConversation.lastMessage) {
          diceConversation.updateLastReadMessageIndex(diceConversation.lastMessage.index);
          updateUnreadCount(diceConversation.sid, 0);
        }
      }
      logEvent('table - show chat');
      this.heartbeatTimer = setInterval(this.logHeartbeat, 300000);
    }
    if (prevProps.isShowingChat && !isShowingChat) this.clearHearbeat();
  }

  componentWillUnmount() {
    const { chatClient, conversations } = this.props;
    conversations.forEach((o) => o.off('messageAdded', this.onMessageAdded));
    if (chatClient) {
      chatClient.removeAllListeners('conversationJoined');
      chatClient.removeAllListeners('conversationLeft');
    }
    this.clearHearbeat();
  }

  clearHearbeat = () => {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  };

  logHeartbeat = () => {
    logEvent('table - show chat heartbeat');
  };

  setupClient = async () => {
    const { chatClient, room } = this.props;
    const { removeConversation, updateUnreadCount } = this.props;

    chatClient?.on('conversationJoined', this.setupConversation);

    chatClient?.on('conversationLeft', (conversation) => {
      if (conversation.uniqueName.includes(room.guid)) {
        removeConversation(conversation.sid);
        updateUnreadCount(conversation.sid, 0);
        conversation.off('messageAdded', this.onMessageAdded);
      }
    });

    let subscribedConversations = await chatClient?.getSubscribedConversations();
    subscribedConversations?.items.forEach((conversation) => this.setupConversation(conversation));

    while (subscribedConversations?.hasNextPage) {
      subscribedConversations = await subscribedConversations.nextPage();
      subscribedConversations.items.forEach((conversation) => this.setupConversation(conversation));
    }
  };

  setupConversation = (conversation) => {
    const { room, conversations, addConversation, updateUnreadCount } = this.props;
    if (conversation.uniqueName.includes(room.guid) && !conversations.find((o) => o.sid === conversation.sid)) {
      addConversation(conversation);
      conversation.on('messageAdded', this.onMessageAdded);
      conversation.getUnreadMessagesCount().then((count) => updateUnreadCount(conversation.sid, count));
    }
  };

  onMessageAdded = (message) => {
    const { diceConversation, everyoneConversation, isShowingChat, selectedConversation, selectedConversationName } =
      this.props;
    const { addMessage, incrementUnreadCount } = this.props;
    const { conversation } = message;
    const { sid } = conversation;
    addMessage(sid, message);
    if (
      !isShowingChat ||
      (selectedConversation && selectedConversation.sid !== sid) ||
      (selectedConversationName === 'Everything' && sid !== diceConversation.sid && sid !== everyoneConversation.sid)
    )
      incrementUnreadCount(conversation.sid, 1);
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onMessageKeyDown = (event) => {
    if (event.keyCode !== 13) return;

    if (event.ctrlKey) {
      event.preventDefault();
      this.setState({ message: `${this.state.message}\n` });
    } else if (!event.shiftKey && !event.ctrlKey) {
      event.preventDefault();
      this.sendButtonRef.current.click();
    }
  };

  onMessageSubmit = (event) => {
    event.preventDefault();

    const { message: rawMessage } = this.state;
    const { everyoneConversation, isConnectingToChat, selectedConversation, selectedConversationName } = this.props;
    const message = rawMessage.trim();

    if (isConnectingToChat || message === '') return;

    if (selectedConversationName === 'Everything' && everyoneConversation) {
      everyoneConversation.sendMessage(message);
      logEvent('table - send message', { 'is private': false, 'is dice': false });
    } else if (selectedConversationName !== 'Everything' && selectedConversation) {
      selectedConversation.sendMessage(message);
      logEvent('table - send message', {
        'is private': selectedConversation.uniqueName.includes('Private'),
        'is dice': false,
      });
    }
    this.setState({ message: '' });
  };

  onPingClick = (event) => {
    event.preventDefault();

    const { everyoneConversation, presenceChannel, showPing } = this.props;

    showPing(true);
    if (presenceChannel) presenceChannel.trigger(ChannelEvent.TABLE_PING, {});
    if (everyoneConversation) everyoneConversation.sendMessage(PING_TOKEN);
    logEvent('table - send ping');
  };

  onSelectChange = (event) => {
    const { room } = this.props;
    const { createPrivateChat, selectConversation } = this.props;
    const { value } = event.target;

    if (value.includes('Private')) {
      const id = value.split('::')[1];
      createPrivateChat(room.guid, id);
    }

    selectConversation(value);
  };

  render() {
    const { message } = this.state;
    const {
      conversations,
      currentUser,
      diceConversation,
      everyoneConversation,
      isShowingChat,
      room,
      selectedConversation,
      selectedConversationName,
      unreadCounts,
      users,
      userColors,
      className,
    } = this.props;
    let placeholder = '';
    let unreadCount = 0;
    if (selectedConversationName === 'Everything') {
      placeholder = 'Say something to the Room...';
      unreadCount =
        everyoneConversation && diceConversation
          ? unreadCounts
              .filter((o) => o[0] !== everyoneConversation.sid && o[0] !== diceConversation.sid)
              .reduce((total, o) => total + o[1], 0)
          : 0;
    } else if (selectedConversation) {
      if (selectedConversationName === 'Everyone') {
        placeholder = 'Say something to the Room...';
      } else if (selectedConversationName === 'Dice') {
        placeholder = 'You cannot send messages while viewing the Dice Log.';
      } else {
        const id = selectedConversationName.split('::')[1];
        const name = users.find((o) => o.userId === id).displayName;
        placeholder = `Say something to ${name}...`;
      }
      unreadCount = unreadCounts.filter((o) => o[0] !== selectedConversation.sid).reduce((total, o) => total + o[1], 0);
    }
    const unreadEveryoneConversation = everyoneConversation
      ? unreadCounts.find((o) => o[0] === everyoneConversation.sid)
      : null;
    const everyoneCount = unreadEveryoneConversation ? unreadEveryoneConversation[1] : 0;
    const unreadDiceConversation = diceConversation ? unreadCounts.find((o) => o[0] === diceConversation.sid) : null;
    const diceCount = unreadDiceConversation ? unreadDiceConversation[1] : 0;
    const everythingCount = everyoneCount + diceCount;
    const isLoading =
      (selectedConversationName !== 'Everything' && !selectedConversation) ||
      (selectedConversationName === 'Everything' && (!everyoneConversation || !diceConversation));
    const isDisabled =
      (selectedConversationName !== 'Everything' && !selectedConversation) || selectedConversationName === 'Dice';

    return (
      <div className={clsx(styles.container, className, isShowingChat && styles.isActive)}>
        <div className={styles.header}>
          <h3 className={styles.title}>Chat</h3>
          {unreadCount > 0 && <div className={styles.unreadCount}>{unreadCount}</div>}
          {selectedConversationName && (
            <div className={styles.selectContainer}>
              <DropdownInput
                input={{ id: 'chat-select', value: selectedConversationName, onChange: this.onSelectChange }}
                className={styles.select}
                variant="theme"
              >
                <option value="Everything">Everything{everythingCount > 0 && ` (${everythingCount})`}</option>
                <option value="Everyone">Everyone{everyoneCount > 0 && ` (${everyoneCount})`}</option>
                <option value="Dice">Dice Log{diceCount > 0 && ` (${diceCount})`}</option>
                {users
                  .filter((o) => o.userId !== currentUser.id)
                  .map((user) => {
                    const { userId, displayName } = user;
                    const privateConversation = conversations.find((o) => {
                      return (
                        o.uniqueName.includes(`${room.guid}::Private::${currentUser.id}::${userId}`) ||
                        o.uniqueName.includes(`${room.guid}::Private::${userId}::${currentUser.id}`)
                      );
                    });
                    const unreadConversation = privateConversation
                      ? unreadCounts.find((o) => o[0] === privateConversation.sid)
                      : null;
                    const count = unreadConversation ? unreadConversation[1] : 0;
                    return (
                      <option key={userId} value={`Private::${userId}`}>
                        {displayName}
                        {count > 0 && ` (${count})`}
                      </option>
                    );
                  })}
              </DropdownInput>
            </div>
          )}
          <Button
            onClick={this.onPingClick}
            title="Ping"
            isSmall
            className={styles.pingButton}
            buttonClassName={styles.pingButtonContent}
            primaryBackground={'var(--color-alert-red)'}
          >
            <PingIcon />
          </Button>
        </div>
        {isLoading && (
          <div className={styles.loadingSpinner}>
            <LoadingSpinner color="var(--color-light-text)" />
          </div>
        )}
        {selectedConversationName === 'Everything' && everyoneConversation && diceConversation && (
          <EverythingConversation
            room={room}
            everyoneConversation={everyoneConversation}
            diceConversation={diceConversation}
          />
        )}
        {selectedConversationName !== 'Everything' && selectedConversation && (
          <Conversation room={room} conversation={selectedConversation} />
        )}
        <form onSubmit={this.onMessageSubmit} className={styles.form}>
          <TextareaAutosize
            onChange={this.onMessageChange}
            onKeyDown={this.onMessageKeyDown}
            minRows={1}
            maxRows={10}
            value={message}
            placeholder={placeholder}
            disabled={isDisabled}
            className={clsx('scrollbars-dark', styles.messageInput)}
            style={null}
          />
          {selectedConversationName !== 'Dice' && (
            <IconButton
              children={<SendIcon />}
              type="submit"
              buttonRef={this.sendButtonRef}
              background={`linear-gradient(170deg, ${userColors[0]}, ${userColors[1]})`}
              color="var(--color-white)"
              className={styles.formButton}
            />
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const { guid } = room;
  const currentUser = SessionSelector.currentUser(state);
  const userColors = RoomUserSelector.getColors(state, guid, currentUser.id);

  return {
    currentUser,
    userColors,
    chatClient: TwilioSelector.getChatClient(state),
    conversations: TwilioSelector.getConversations(state),
    diceConversation: TwilioSelector.getDiceConversation(state, guid),
    everyoneConversation: TwilioSelector.getEveryoneConversation(state, guid),
    isConnectingToChat: TwilioSelector.isConnectingToChat(state),
    isShowingChat: TableSelector.isShowingChat(state),
    presenceChannel: TableSelector.getPresenceChannel(state),
    selectedConversation: TwilioSelector.getSelectedConversation(state, guid),
    selectedConversationName: TwilioSelector.getSelectedConversationName(state),
    token: TwilioSelector.getToken(state),
    unreadCounts: TwilioSelector.getUnreadCounts(state),
    users: RoomUserSelector.getAllByRoom(guid)(state),
  };
};

const mapDispatchToProps = {
  addConversation: TwilioAction.addConversation,
  addMessage: TwilioAction.addMessage,
  connectChat: TwilioAction.connectChat,
  createChat: TwilioAction.createChat,
  createDiceChat: TwilioAction.createDiceChat,
  createPrivateChat: TwilioAction.createPrivateChat,
  incrementUnreadCount: TwilioAction.incrementUnreadCount,
  removeConversation: TwilioAction.removeConversation,
  selectConversation: TwilioAction.selectConversation,
  showPing: TableAction.showPing,
  updateUnreadCount: TwilioAction.updateUnreadCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableChat);
