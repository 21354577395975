import _ from 'lodash';
import { AnyAction } from 'redux';

import TableAction from 'store/actions/TableAction';
import { RoomTab, TableViewMode } from 'types/room';

const INITIAL_STATE = {
  activeAssetId: null,
  activeUserId: null, // Pinned user/participant or dominant speaker
  activeSheetId: null,
  activeSheetUserId: null,
  currentTab: RoomTab.Party,
  entered: false,
  isActiveUserPinned: false,
  isAddingSheet: false,
  isObrActive: false,
  isFocusMode: false,
  isShowingBuffs: false,
  isShowingInvite: false,
  isShowingManageUsers: false,
  isShowingPing: false,
  isShowingRoomSheetsMenu: false,
  isShowingTargets: false,
  isShowingUserSettings: false,
  playPanelContent: null,
  presenceChannel: null,
  previewRoom: null,
  previewSheet: null,
  privateRoll: false,
  privateAssetId: null,
  removeUserId: null,
  removeTableGuid: null,
  sharedAssetId: null,
  shouldClearAfterRoll: false,
  showCardDrawer: false,
  showDiceTotal: false,
  showSettingsModal: false,
  showSheet: true,
  showSheetTOC: false,
  showSheetMenu: false,
  showSheets: false,
  showVideoSettingsModal: false,
  theme: {},
  roomGuid: null,
  userIds: [],
  userMenuId: null,
  viewMode: TableViewMode.Grid,
};

const tableReducer = (state = INITIAL_STATE, action: AnyAction) => {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case TableAction.ADD_SHEET:
      return { ...state, isAddingSheet: payload };

    case TableAction.PRIVATE_ROLL:
      return { ...state, privateRoll: payload };

    case TableAction.REMOVE:
      return { ...state, removeTableGuid: payload };

    case TableAction.RESET:
      return INITIAL_STATE;

    case TableAction.SET_ACTIVE_SHEET:
      return { ...state, activeSheetId: payload, showSheets: false, showSheetMenu: false };

    case TableAction.SET_ACTIVE_SHEET_USER:
      return { ...state, activeSheetUserId: payload };

    case TableAction.SET_CURRENT_TAB:
      return { ...state, currentTab: payload };

    case TableAction.SET_ENTERED:
      return { ...state, entered: payload };

    case TableAction.SET_OBR_ACTIVE:
      return { ...state, isObrActive: payload };

    case TableAction.SET_FOCUS_MODE:
      return { ...state, isFocusMode: payload };

    case TableAction.SET_PRESENCE_CHANNEL:
      return { ...state, presenceChannel: payload };

    case TableAction.SET_PREVIEW_ROOM:
      return { ...state, previewRoom: payload };

    case TableAction.SET_PREVIEW_SHEET:
      return { ...state, previewSheet: payload };

    case TableAction.SET_ROOM_GUID:
      return { ...state, roomGuid: payload };

    case TableAction.SET_SHOULD_CLEAR_AFTER_ROLL:
      return { ...state, shouldClearAfterRoll: payload };

    case TableAction.SET_THEME:
      return { ...state, theme: payload };

    case TableAction.SHOW_BUFFS:
      return { ...state, isShowingBuffs: payload };

    case TableAction.SHOW_DICE_TOTAL:
      return { ...state, showDiceTotal: payload };

    case TableAction.SHOW_DICE_TRAY:
      return { ...state, isShowingDiceTray: payload };

    case TableAction.SHOW_INVITE:
      return { ...state, isShowingInvite: payload };

    case TableAction.SHOW_MANAGE_USERS:
      return { ...state, isShowingManageUsers: payload };

    case TableAction.SHOW_PING:
      return { ...state, isShowingPing: payload };

    case TableAction.SHOW_ROOM_SHEETS_MENU:
      return { ...state, isShowingRoomSheetsMenu: payload };

    case TableAction.SHOW_SETTINGS:
      return { ...state, showSettingsModal: payload };

    case TableAction.SHOW_SHEET:
      return { ...state, showSheet: payload };

    case TableAction.SHOW_SHEET_TOC:
      return { ...state, showSheetTOC: payload };

    case TableAction.SHOW_SHEET_MENU:
      return { ...state, showSheetMenu: payload };

    case TableAction.SHOW_SHEETS:
      return { ...state, showSheets: payload };

    case TableAction.SHOW_TARGETS:
      return { ...state, isShowingTargets: payload };

    case TableAction.SHOW_USER_SETTINGS:
      return { ...state, isShowingUserSettings: payload };

    case TableAction.SHOW_VIDEO_SETTINGS:
      return { ...state, showVideoSettingsModal: payload };

    case TableAction.UPDATE_VIEW_MODE:
      return { ...state, viewMode: payload };

    // ========================================
    // USER
    // ========================================

    case TableAction.ADD_USER:
      return { ...state, userIds: _.uniq([...state.userIds, payload]) };

    case TableAction.PIN_ACTIVE_USER:
      return { ...state, isActiveUserPinned: true };

    case TableAction.CONFIRM_REMOVE_USER:
      return { ...state, removeUserId: payload };

    case TableAction.REMOVE_USER:
      return { ...state, userIds: state.userIds.filter((id) => id !== payload) };

    case TableAction.SET_ACTIVE_USER:
      return { ...state, activeUserId: payload };

    case TableAction.SHOW_USER_MENU:
      return { ...state, userMenuId: payload };

    case TableAction.UNPIN_ACTIVE_USER:
      return { ...state, isActiveUserPinned: false };

    // ========================================
    // CARD DRAWER
    // ========================================

    case TableAction.HIDE_CARD_DRAWER:
      return { ...state, showCardDrawer: false };

    case TableAction.SHOW_CARD_DRAWER:
      return { ...state, showCardDrawer: true };

    // ========================================
    // ASSETS
    // ========================================

    case TableAction.SET_ACTIVE_ASSET:
      return { ...state, activeAssetId: payload };

    case TableAction.SET_PRIVATE_ASSET:
      return { ...state, privateAssetId: payload };

    case TableAction.SET_SHARED_ASSET:
      return { ...state, sharedAssetId: payload };

    // ========================================
    // PLAY PANEL
    // ========================================

    case TableAction.SET_PLAY_PANEL_CONTENT:
      return { ...state, playPanelContent: payload };

    default:
      return state;
  }
};

export default tableReducer;
