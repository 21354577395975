// @flow
import styles from './Group.module.css';

import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { remark } from 'remark';
import strip from 'strip-markdown';

import type { ElementModel, ElementProps } from 'components/Sheet2/types';
import { ELEMENT_TYPE, INPUT_TYPE } from 'constants/sheet';

import ElementComponents from 'components/Sheet2/elements/ElementComponents';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';

type ModelProps = {
  ...ElementModel,
  items: ElementModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
  index?: number,
};

function Group(props: Props): Node {
  const {
    className,
    colors,
    element: group,
    images,
    index,
    onChange,
    onChangeGroup,
    readOnly,
    isDraft,
    sectionId,
    sheetGuid,
    roomGuid,
  } = props;
  const { isCollapsed: propIsCollapsed, items: propItems } = group;
  const [elementSpaceList, setElementSpaceList] = useState([]);
  const [groupTitle, setGroupTitle] = useState('Group');
  const [isCollapsed, setIsCollapsed] = useState(propIsCollapsed);

  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isCollapsed) classNames.push(styles.isCollapsed);

  const hasToggleOrLabel = (element) =>
    element.items.some((i) => i.inputType === INPUT_TYPE.toggle || i.inputType === INPUT_TYPE.heading);

  const items = useMemo(() => {
    return index !== undefined && index !== null
      ? propItems.map((element) => {
          return {
            ...element,
            items: element.items.map((item) => {
              if ([INPUT_TYPE.heading, INPUT_TYPE.paragraph].includes(item.inputType)) {
                return { ...item, defaultValue: _.replace(item.defaultValue, /%n/gi, `${index + 1}`) };
              } else {
                return item;
              }
            }),
          };
        })
      : propItems;
  }, [index, propItems]);

  useEffect(() => {
    setIsCollapsed(propIsCollapsed);
  }, [propIsCollapsed, readOnly]);

  useEffect(() => {
    const newElementsSpaceArray = [];
    let prevCol = 0;
    items.forEach((element, index) => {
      if (element.columns === 1 && prevCol === 1) {
        if (hasToggleOrLabel(element)) {
          newElementsSpaceArray.push(element.id);
          if (items[index - 1] && !newElementsSpaceArray.includes(items[index - 1].id))
            newElementsSpaceArray.push(items[index - 1].id);
        } else if (items[index - 1] && newElementsSpaceArray.includes(items[index - 1].id)) {
          newElementsSpaceArray.push(element.id);
        }
      } else {
        hasToggleOrLabel(element) && newElementsSpaceArray.push(element.id);
      }
      prevCol = prevCol === 1 ? 0 : element.columns;
    });
    setElementSpaceList(newElementsSpaceArray);
  }, [items]);

  useEffect(() => {
    (async () => {
      let title = '';
      const item = items[0];
      if (item) {
        const { elementType, items: inputs } = item;
        switch (elementType) {
          case ELEMENT_TYPE.dropdown:
            const dropdownInput = inputs.find((o) => o.inputType === INPUT_TYPE.dropdown);
            title = dropdownInput?.value ?? dropdownInput?.defaultValue ?? '';
            if (title === '') {
              const label = inputs.find((o) => o.inputType === INPUT_TYPE.heading);
              title = label?.value ?? label?.defaultValue ?? '';
            }
            break;
          case ELEMENT_TYPE.field:
          case ELEMENT_TYPE.fieldBuff:
            const fieldInput = inputs.find((o) => o.inputType === INPUT_TYPE.field);
            title = fieldInput?.value ?? fieldInput?.defaultValue ?? '';
            if (title === '') {
              const label = inputs.find((o) => o.inputType === INPUT_TYPE.heading);
              title = label?.value ?? label?.defaultValue ?? '';
            }
            break;
          case ELEMENT_TYPE.note:
            const noteInput = inputs.find((o) => o.inputType === INPUT_TYPE.note);
            title = (noteInput?.value ?? noteInput?.defaultValue ?? '').substring(0, 50);
            title = String(await remark().use(strip).process(title));
            if (title === '') {
              const label = inputs.find((o) => o.inputType === INPUT_TYPE.heading);
              title = label?.value ?? label?.defaultValue ?? '';
            }
            break;
          case ELEMENT_TYPE.text:
            const headingInput = inputs.find((o) => o.inputType === INPUT_TYPE.heading);
            title = headingInput?.value ?? headingInput?.defaultValue ?? '';
            if (title === '') {
              const paragraphInput = inputs.find((o) => o.inputType === INPUT_TYPE.paragraph);
              title = paragraphInput?.value ?? paragraphInput?.defaultValue ?? '';
              title = String(await remark().use(strip).process(title));
            }
            break;
          default:
            const label = inputs.find((o) => o.inputType === INPUT_TYPE.heading);
            title = label?.value ?? label?.defaultValue ?? '';
            break;
        }
      }
      if (title === '') {
        title = 'Group';
        if (index !== undefined && index !== null) title += ` ${index + 1}`;
      }
      setGroupTitle(title);
    })();
  }, [index, items]);

  const onElementChange = useCallback(
    (id, sectionId, element, callback) => {
      if (!onChangeGroup) return;
      onChangeGroup(id, sectionId, group.id, element, callback);
    },
    [group.id, onChangeGroup]
  );

  const onCollapseChange = () => {
    if (readOnly) setIsCollapsed(!isCollapsed);
    else onChange(sheetGuid, sectionId, { ...group, isCollapsed: !isCollapsed });
  };

  return (
    <div className={classNames.join(' ')}>
      {!isCollapsed && (
        <div className={styles.elements}>
          {items.map((element) => {
            const ElementComponent = ElementComponents[element.elementType];
            const allowImages = element.elementType === ELEMENT_TYPE.image;
            return (
              ElementComponent && (
                <ElementComponent
                  key={element.id}
                  roomGuid={roomGuid}
                  sheetGuid={sheetGuid}
                  sectionId={sectionId}
                  element={element}
                  images={allowImages ? images : null}
                  colors={colors}
                  readOnly={readOnly}
                  isDraft={isDraft}
                  onChange={onElementChange}
                  onChangeGroup={onChangeGroup}
                  metaSpace={elementSpaceList.includes(element.id)}
                />
              )
            );
          })}
        </div>
      )}
      {isCollapsed && <div className={styles.label}>{groupTitle}</div>}
      <button className={`button-reset ${styles.collapseButton}`} onClick={onCollapseChange}>
        {isCollapsed ? <NotVisibleIcon /> : <VisibleIcon />}
      </button>
    </div>
  );
}

export default Group;
