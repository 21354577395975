import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import usePrevious from 'hooks/usePrevious';
import { ChannelEvent } from 'models/Channel';
import Die, { DieType } from 'models/Die';
import { RollAction } from 'store/actions';
import { RollSelector, SessionSelector, TableSelector } from 'store/selectors';

export default function useModifierDice(modifier?: any) {
  const dispatch = useDispatch();
  const currentUser = useSelector(SessionSelector.currentUser);
  const isPrivateRoll = useSelector(TableSelector.isPrivateRoll);
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const prevIsSelected = usePrevious(modifier?.isSelected);

  const modifiers = useSelector(RollSelector.getSelectedModifiers);
  const dice = useSelector(RollSelector.getDice);
  const shouldClearAfterRoll = useSelector(TableSelector.shouldClearAfterRoll);
  const [rolledDiceIds, setRolledDiceIds] = useState<string[]>([]);

  const removeDie = useCallback(
    (die: any) => {
      dispatch(RollAction.removeDie(die.id));
      setRolledDiceIds((dice) => dice.filter((id) => id !== die.id));
      if (presenceChannel && currentUser)
        presenceChannel.trigger(ChannelEvent.DIE_REMOVED, {
          userId: currentUser.id,
          dieId: die.id,
        });
    },
    [currentUser, dispatch, presenceChannel]
  );

  const removeModifier = useCallback(
    (modifier: any) => {
      dispatch(RollAction.updateModifier({ ...modifier, isSelected: false }));
      if (presenceChannel && currentUser)
        presenceChannel.trigger(ChannelEvent.MODIFIER_REMOVED, {
          userId: currentUser.id,
          modifierId: modifier.id,
        });
    },
    [currentUser, dispatch, presenceChannel]
  );

  const sendPusher = useCallback(() => {
    if (!modifier) return;
    const dieTemplate = new Die({ sides: modifier.value, type: modifier.diceType || DieType.NUMBER });

    const shouldClear = shouldClearAfterRoll && dice.length > 0 && dice.every((die) => die.hasRolled);
    if (shouldClear) {
      dice.forEach(removeDie);
      modifiers.filter((mod) => modifier.id !== mod.id).forEach(removeModifier);
    }

    _.times(modifier.count, () => {
      const die = _.clone(dieTemplate);
      die.id = uuid();
      die.color = modifier.color;
      setRolledDiceIds((dice) => dice.concat(die.id));
      dispatch(RollAction.addDie(die));
      if (!isPrivateRoll && presenceChannel && currentUser) {
        presenceChannel.trigger(ChannelEvent.DIE_ADDED, {
          userId: currentUser.id,
          die,
        });
      }
    });
  }, [
    currentUser,
    dice,
    dispatch,
    isPrivateRoll,
    modifier,
    modifiers,
    presenceChannel,
    removeDie,
    removeModifier,
    shouldClearAfterRoll,
  ]);

  useEffect(() => {
    // Check if we need to remove any of our dice
    if (modifier) {
      if (modifier.isSelected && !prevIsSelected) {
        sendPusher();
      } else if (!modifier.isSelected && prevIsSelected) {
        for (const die of dice) {
          if (rolledDiceIds.includes(die.id)) removeDie(die);
        }
      }
    }
  }, [dice, modifier, prevIsSelected, removeDie, rolledDiceIds, sendPusher]);
}
