import styles from './Page.module.css'

import React, { Component } from 'react'

class Page extends Component {
  render() {
    const { children, className } = this.props
    const classNames = [styles.content, styles.hasPadding]
    if (className) classNames.push(className)

    return <div className={classNames.join(' ')}>{children}</div>
  }
}

export default Page
