// @flow
import styles from './Markdown.module.css';

import type { Node } from 'react';
import React, { useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';

import remarkBreaks from 'remark-breaks';
import gfm from 'remark-gfm';
import clsx from 'clsx';

type MarkdownProps = {
  children: Node,
  className?: ?string,
};

type HeadingProps = {
  children: Node,
  level: ?number,
};

type LinkProps = {
  children: Node,
  href: ?string,
};

const plugins = [gfm, remarkBreaks];

export default function Markdown({ children, className }: MarkdownProps): Node {
  const renderMarkdownHeading = useCallback((props: HeadingProps) => {
    const { children, level } = props;
    switch (level) {
      case 1:
        return <h4 className={`heading2 ${styles.heading1}`}>{children}</h4>;
      case 2:
        return <h5 className={`heading3 ${styles.heading2}`}>{children}</h5>;
      default:
        return <h6 className={`heading3 ${styles.heading3}`}>{children}</h6>;
    }
  }, []);

  const renderMarkdownLink = useCallback((props: LinkProps) => {
    const { children, href } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }, []);

  const components = useMemo(
    () => ({
      h1: renderMarkdownHeading,
      h2: renderMarkdownHeading,
      h3: renderMarkdownHeading,
      h4: renderMarkdownHeading,
      h5: renderMarkdownHeading,
      h6: renderMarkdownHeading,
      a: renderMarkdownLink,
    }),
    [renderMarkdownHeading, renderMarkdownLink]
  );

  return (
    <ReactMarkdown
      remarkPlugins={plugins}
      components={components}
      children={children}
      className={clsx(styles.container, className)}
    />
  );
}
