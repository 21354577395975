// @flow
import styles from './Reference.module.css';

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import EditIcon from 'components/Sheet2/atoms/EditIcon';
import GradientHexagon from 'components/Sheet2/atoms/GradientHexagon';
import LockedIcon from 'components/Sheet2/atoms/LockedIcon';
import { ReactComponent as ReferenceOneColumnIcon } from 'images/icons/ReferenceOneColumnIcon.svg';
import { ReactComponent as ReferenceTwoColumnIcon } from 'images/icons/ReferenceTwoColumnIcon.svg';

type Props = {
  background: string,
  canEdit: boolean,
  className?: ?string,
  columns: number,
  isBuilding: boolean,
  isSelected: boolean,
  onBlur?: ?() => void,
  onChange: (string) => void,
  onFocus?: ?() => void,
  onClick?: (boolean) => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
  value: string,
};

const REGEX = /^-{0,1}\d*$/;

function Reference(props: Props): Node {
  const {
    background,
    canEdit,
    className,
    columns,
    isBuilding,
    isSelected,
    onBlur: propOnBlur,
    onChange: propOnChange,
    onFocus,
    onClick: propOnClick,
    primaryColor,
    readOnly,
    secondaryColor,
    value,
  } = props;

  const inputRef = useRef(null);
  const hoverTimerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(isBuilding);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    return () => clearTimeout(hoverTimerRef.current);
  }, []);

  useEffect(() => {
    if (inputRef.current && isEditing && !isBuilding) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isBuilding, isEditing]);

  const isTwoColumns = columns === 2;
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isEditing) classNames.push(styles.isEditing);
  if (isHovering) classNames.push(styles.isHovering);
  if (isSelected) classNames.push(styles.isSelected);

  const onBlur = (event) => {
    if (!isBuilding) setIsEditing(false);
    let value = event.currentTarget.value;
    if (value.match(REGEX)) {
      if (value === '' || value === '-') value = '0';
      propOnChange(value);
    }
    if (propOnBlur) propOnBlur();
  };

  const onChange = (event) => {
    if (!event.currentTarget.value.match(REGEX)) return;
    propOnChange(event.currentTarget.value);
  };

  const onClick = () => {
    if (propOnClick) propOnClick(!isSelected);
  };

  const onEditClick = () => setIsEditing((prev) => !prev);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) inputRef.current.blur();
  };

  const onMouseEnter = () => {
    clearTimeout(hoverTimerRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    hoverTimerRef.current = setTimeout(removeHover, 500);
  };

  const removeHover = () => setIsHovering(false);

  return (
    <div className={classNames.join(' ')}>
      <div className={styles.backgroundContainer}>
        <GradientHexagon
          className={styles.gradient}
          icon={isTwoColumns ? ReferenceTwoColumnIcon : ReferenceOneColumnIcon}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <GradientHexagon
          className={styles.background}
          style={{ transform: `scaleY(${isTwoColumns ? 0.92 : 0.925})` }}
          icon={isTwoColumns ? ReferenceTwoColumnIcon : ReferenceOneColumnIcon}
          primaryColor={background}
          secondaryColor={background}
        />
      </div>
      <div className={styles.content} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {((!readOnly && canEdit) || isBuilding) && isEditing ? (
          <input
            ref={inputRef}
            className={styles.input}
            type="text"
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onChange={onChange}
          />
        ) : (
          <button className={`button-reset ${styles.value}`} onClick={onClick}>
            {value}
          </button>
        )}
        {!canEdit && <LockedIcon className={styles.lockedIcon} />}
      </div>
      {!readOnly && canEdit && !isBuilding && (
        <EditIcon
          className={styles.editButton}
          isEditing={isEditing}
          onClick={onEditClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
    </div>
  );
}

Reference.defaultProps = {
  canEdit: false,
  isBuilding: false,
  isSelected: false,
  background: 'var(--color-black)',
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Reference;
