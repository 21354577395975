import { useEffect } from 'react';
import { Room, TwilioError } from 'twilio-video';

export default function useHandleRoomDisconnection(
  room: Room | null,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          console.error(error);
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);
}
