import { batch } from 'react-redux';
import { Dispatch } from 'redux';

import { createAction, handleError } from './utilities';

import GameAction from './GameAction';
import OrganizationAction from './OrganizationAction';
import ProductAction, { processProductResponse } from './ProductAction';
import role from 'apis/role';

export interface RedeemCode {
  id: string;
  ownerType: 'User' | 'Organization';
  ownerId: string;
  code: string;
  isRedeemed: boolean;
  productIds: string[];
}

type FetchCallback = (redeemCode?: RedeemCode) => any;

function processRedeemCodeResponse({ data, included }: { data: any; included: any[] }) {
  const { id, attributes, relationships } = data;
  const productIds = relationships.products.data.map(({ id }: any) => id);

  const redeemCode: RedeemCode = { id, ...attributes, productIds };

  const products: any[] = [];
  const games: any[] = [];
  const organizations: any[] = [];

  for (const include of included ?? []) {
    const { id, type, attributes } = include;
    const obj = { id, ...attributes };

    if (type === 'product') products.push(obj);
    else if (type === 'game') games.push(obj);
    else if (type === 'organization') organizations.push(obj);
  }

  return { redeemCode, products, games, organizations };
}

export default class RedeemCodeAction {
  static FETCH = 'RedeemCodeAction.FETCH';
  static REDEEM = 'RedeemCodeAction.REDEEM';

  static fetch = (code: string, callback?: FetchCallback) => (dispatch: Dispatch) =>
    role.get(`/redeem_codes/${code}`).then(
      (response) => {
        const { redeemCode, products, games, organizations } = processRedeemCodeResponse(response.data);

        batch(() => {
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          callback?.(redeemCode);
        });
      },
      ({ response }) => handleError(dispatch, RedeemCodeAction.FETCH, response)
    );

  static redeem = (code: string, callback?: FetchCallback) => (dispatch: Dispatch) =>
    role.post(`/redeem_codes/${code}/redeem`).then(
      (response) => {
        const { products, games, organizations } = processProductResponse(response.data);
        batch(() => {
          dispatch(createAction(ProductAction.FETCH_ALL, products));
          dispatch(createAction(GameAction.FETCH_ALL, games));
          dispatch(createAction(OrganizationAction.FETCH_ALL, organizations));
        });
        callback?.();
      },
      ({ response }) => handleError(dispatch, RedeemCodeAction.REDEEM, response)
    );
}
