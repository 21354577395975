import { ReactComponent as ImageIcon } from 'images/icons/ImageIcon.svg';
import { ReactComponent as LinkExternalIcon } from 'images/icons/LinkExternalIcon.svg';
import { ReactComponent as LinkIcon } from 'images/icons/LinkIcon.svg';
import { ReactComponent as MapIcon } from 'images/icons/MapIcon.svg';
import { ReactComponent as RulebookIcon } from 'images/icons/RulebookIcon.svg';
import { ReactComponent as SheetIcon } from 'images/icons/SheetIcon.svg';
import { ReactComponent as ThemeIcon } from 'images/icons/ThemeIcon.svg';
import { ReactComponent as TokenIcon } from 'images/icons/TokenIcon.svg';

export enum BundleItem {
  App = 'app',
  Link = 'link',
  Map = 'map',
  PDF = 'pdf',
  Rulebook = 'rulebook',
  Quickstart = 'quickstart',
  Sheet = 'sheet',
  Template = 'template',
  Theme = 'theme',
  Token = 'token',
  Image = 'image',
}

export const getBundleItemIcon = (item: BundleItem) => {
  switch (item) {
    case 'app':
      return <LinkExternalIcon className="bundle-icon" />;
    case 'link':
      return <LinkIcon className="bundle-icon" />;
    case 'map':
      return <MapIcon className="bundle-icon" />;
    case 'pdf':
    case 'rulebook':
    case 'quickstart':
      return <RulebookIcon className="bundle-icon" />;
    case 'sheet':
    case 'template':
      return <SheetIcon className="bundle-icon" />;
    case 'theme':
      return <ThemeIcon className="bundle-icon" />;
    case 'token':
      return <TokenIcon className="bundle-icon" />;
    case 'image':
    default:
      return <ImageIcon className="bundle-icon" />;
  }
};
