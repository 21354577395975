// @flow
import styles from './MenuList.module.css';

import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Switch from 'components/Switch';
import { ReactComponent as CheckIcon } from 'images/icons/CheckIcon.svg';

export type MenuItemProps = {
  label: string,
  isActive?: boolean,
  onClick?: (event: any) => void,
  onDropdownChange?: (option: any) => void,
  onToggle?: (isActive: boolean) => void,
  to?: string,
  url?: string,
  urlCallback?: (event: any) => void,
  download?: any,
  dropdownOptions?: any[],
  dropdownSelected?: any,
};

type Props = {
  className?: ?string,
  isActive?: boolean,
  items: MenuItemProps[],
  variant?: string,
  children?: any,
};

function MenuList({ children, className, isActive, items, variant }: Props): Node {
  return (
    <CSSTransition
      in={isActive}
      mountOnEnter
      unmountOnExit
      timeout={150}
      classNames={{
        enter: styles.containerEnter,
        enterActive: styles.containerEnterActive,
        enterDone: styles.containerEnterDone,
        exit: styles.containerExit,
        exitActive: styles.containerExitActive,
        exitDone: styles.containerExitDone,
      }}
    >
      <nav
        onClick={(e) => e.stopPropagation()}
        className={clsx(styles.container, className, variant === 'theme' && styles.theme)}
      >
        {children}
        {!children &&
          !!items &&
          items.map((item, i) => {
            const {
              label,
              to,
              url,
              urlCallback,
              download,
              isActive,
              onClick,
              onDropdownChange,
              onToggle,
              dropdownOptions,
              dropdownSelected,
            } = item;
            if (onClick) {
              return (
                <button key={i} className={clsx('button-reset', styles.item)} onClick={onClick}>
                  {label}
                </button>
              );
            } else if (onToggle) {
              return (
                <label key={i} className={clsx(styles.item, styles.noHover)}>
                  {label}
                  <Switch className={styles.switch} isActive={isActive} onChange={onToggle} />
                </label>
              );
            } else if (to) {
              return (
                <Link key={i} to={to} className={clsx(styles.item)}>
                  {label}
                </Link>
              );
            } else if (url) {
              return (
                <a
                  className={clsx(styles.item)}
                  download={download}
                  href={url}
                  key={i}
                  onClick={urlCallback}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {label}
                </a>
              );
            } else if (dropdownOptions) {
              return (
                <div key={i} className={clsx(styles.item, styles.dropdown, styles.noHover)}>
                  <div>{label}</div>
                  <Menu as="div" className={styles.dropdownMenu}>
                    <Menu.Button className={clsx('button-reset', styles.dropdownButton)}>
                      {dropdownSelected.label}
                    </Menu.Button>
                    <Menu.Items className={styles.dropdownList}>
                      {dropdownOptions.map((option, j) => (
                        <Menu.Item
                          key={j}
                          as="button"
                          onClick={() => onDropdownChange?.(option)}
                          className={clsx('button-reset', styles.dropdownItem)}
                        >
                          <span className={styles.dropdownLabel}>{option.label}</span>
                          <span className={styles.dropdownDescription}>{option.description}</span>
                          {option.value === dropdownSelected.value && (
                            <span className={styles.dropdownCheck}>
                              <CheckIcon width={12} />
                            </span>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                </div>
              );
            } else {
              return (
                <div key={i} className={clsx(styles.item)}>
                  {label}
                </div>
              );
            }
          })}
      </nav>
    </CSSTransition>
  );
}

MenuList.defaultProps = {
  isActive: false,
};

export default MenuList;
