import styles from './JoinTableModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { ErrorAction, RoomAction, TableAction } from 'store/actions';
import { PlaykitSelector, RoomUserSelector, SessionSelector, TableSelector } from 'store/selectors';
import { randomColor, randomPlaykitColor } from 'utilities';

import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';

class JoinTableModal extends Component {
  inviteLinkRef = React.createRef();

  onSubmit = (formValues) => {
    const {
      currentUser,
      room: { guid, hasPassword },
      join,
      addTableUser,
      removeAllErrors,
      roomColors,
    } = this.props;
    removeAllErrors();
    if (hasPassword && !formValues.password) throw new SubmissionError({ password: 'Required' });
    const firstColor = roomColors.length > 0 ? randomPlaykitColor(roomColors) : randomColor();
    const secondColor = roomColors.length > 0 ? randomPlaykitColor(roomColors, firstColor) : randomColor(firstColor);
    formValues.colors = {};
    roomColors.length > 0
      ? (formValues.colors.hex = [firstColor, secondColor])
      : (formValues.colors.css = [firstColor, secondColor]);
    return join(guid, formValues, () => addTableUser(currentUser.id));
  };

  renderInput = ({ ref, input, type, label, placeholder, meta, readOnly }) => {
    const { submitFailed } = this.props;
    return (
      <label className={styles.inputContainer}>
        {label}
        <TextInput
          ref={ref}
          input={input}
          meta={meta}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          readOnly={readOnly || false}
          showError={submitFailed}
          className={styles.input}
        />
      </label>
    );
  };

  render() {
    const { room, creator, playkit, onDismiss, handleSubmit } = this.props;
    const { hasPassword } = room;

    return (
      <TitleModal title="Welcome" subtitle="Join this room and start playing!" onDismiss={onDismiss}>
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <label className={styles.inputContainer}>
            <span className={styles.label}>
              <strong>Room Name:</strong>
            </span>
            <div className={`heading2 ${styles.title}`}>{room.title}</div>
            {creator && (
              <div className={styles.createdBy}>
                Created by <strong>{creator.username}</strong>
              </div>
            )}
          </label>

          {hasPassword && (
            <Field
              component={this.renderInput}
              name="password"
              type="password"
              placeholder="Enter a password"
              label={
                <span className={styles.label}>
                  <strong>Enter Password:</strong>
                </span>
              }
            />
          )}

          {playkit && (
            <label className={styles.inputContainer}>
              <span className={styles.label}>
                <strong>Game:</strong>
              </span>
              <div className={styles.playkit}>
                <img src={playkit.logoUrl} alt={playkit.title} />
              </div>
            </label>
          )}

          {playkit && (
            <p className={styles.description}>
              This room is pre-populated with sheet templates and characters for <strong>{playkit.title}</strong>. You
              can also add your own custom sheets and assets after you join.
            </p>
          )}
          {!playkit && (
            <p className={styles.description}>
              This room does not have a preset game. You can add any sheets or assets you’d like once you join.
            </p>
          )}
          <Button variant="primary" type="submit" className={styles.button}>
            Join Room
          </Button>
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    room: { guid, userId, playkitIds },
  } = props;
  return {
    currentUser: SessionSelector.currentUser(state),
    creator: RoomUserSelector.get(state, guid, userId),
    playkit: playkitIds ? PlaykitSelector.get(state, playkitIds[0]) : null,
    roomColors: TableSelector.getColors(state, guid),
  };
};

const mapDispatchToProps = {
  addTableUser: TableAction.addUser,
  removeAllErrors: ErrorAction.removeAll,
  join: RoomAction.join,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({ form: 'joinTable' }))(JoinTableModal);
