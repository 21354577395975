import styles from './DiceTray.module.css';

import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { ChannelEvent } from 'models/Channel';
import { DEFAULT_COLORS } from 'models/Color';
import { D100, DieType } from 'models/Die';
import Modifier, { ModifierType } from 'models/Modifier';
import { DICE_LOG_TOKEN } from 'models/Twilio';
import { RollAction, RoomUserAction, TableAction } from 'store/actions';
import {
  RollSelector,
  RoomDocumentSelector,
  RoomUserSelector,
  SessionSelector,
  TableSelector,
  TwilioSelector,
} from 'store/selectors';
import { logEvent } from 'utilities';
import { getFateValue } from 'utilities/dice';

import DiceModifier from './DiceModifier';
import Button from 'components/buttons/Button';
import Die from 'components/DiceRoller/Die';
import GradientBorder from 'components/GradientBorder';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import { ReactComponent as NotVisibleIcon } from 'images/icons/NotVisibleIcon.svg';
import { ReactComponent as VisibleIcon } from 'images/icons/VisibleIcon.svg';

class DiceTray extends Component {
  state = { isEditingBuff: false, isEditingTarget: false };
  containerRef = React.createRef();

  componentDidMount() {
    this.setState({ height: this.containerRef.current.offsetHeight });
    this.props.setPrivateRoll(this.props.userSettings.dicePrivateRoll ?? false);
    this.props.setShouldClearAfterRoll(this.props.userSettings.diceAutoClear ?? false);
    this.props.showTotal(this.props.userSettings.diceShowTotal ?? false);
  }

  componentWillUnmount() {
    this.onClearEverything();
  }

  // Extract out into a DieUtilities class
  // D100 ranges from [0,99], all other dies range [1,MAX]
  getRandomNum = (die) => {
    const value = Math.random() * die.sides;
    return die.sides === D100.sides ? Math.floor(value) : Math.ceil(value);
  };

  doRoll = (dice) => {
    const { buffsTotal, conversation, dice: allDice, isPrivateRoll, targetsTotal } = this.props;
    const { updateDie } = this.props;
    const updatedDice = [];

    if (dice.length === 0) return;

    dice.forEach((die) => {
      const updatedDie = {
        ...die,
        isRolling: true,
        isEnding: false,
        hasRolled: false,
        value: this.getRandomNum(die),
      };
      updateDie(updatedDie);
      updatedDice.push(updatedDie);
    });

    const ids = updatedDice.map((o) => o.id);
    const newDice = allDice.filter((o) => o.hasRolled || ids.includes(o.id));
    updatedDice.forEach((die) => {
      const index = newDice.findIndex((o) => o.id === die.id);
      newDice[index] = die;
    });

    if (conversation && !isPrivateRoll) {
      const data = {
        refs: targetsTotal,
        buffs: buffsTotal,
        dice: newDice.map(({ sides, type, value, color, name }) => ({ sides, type, value, color, name })),
      };
      conversation.sendMessage(`${DICE_LOG_TOKEN}${JSON.stringify(data)}`);
      logEvent('table - send message', { 'is private': false, 'is dice': true });
    }

    for (const die of updatedDice) {
      logEvent('table - die roll', {
        name: die.name,
        sides: die.sides,
        type: die.type,
        value: die.value,
        'is private': isPrivateRoll,
      });
    }
  };

  onAddManualBuff = () => {
    const { manualBuff } = this.props;
    const { addModifier, isShowingTargets, showBuffs, showTargets } = this.props;

    if (isShowingTargets) showTargets(false);
    showBuffs(true);

    if (manualBuff) return;

    const modifier = new Modifier({
      id: uuid(),
      value: 0,
      type: ModifierType.BUFF,
    });
    addModifier(modifier);
  };

  onAddManualTarget = () => {
    const { manualTarget } = this.props;
    const { addModifier, isShowingBuffs, showBuffs, showTargets } = this.props;

    if (isShowingBuffs) showBuffs(false);
    showTargets(true);

    if (manualTarget) return;

    const modifier = new Modifier({
      id: uuid(),
      value: 0,
      type: ModifierType.TARGET,
    });
    addModifier(modifier);
  };

  onClearEverything = () => {
    const { currentUser, isPrivateRoll, presenceChannel } = this.props;
    const { removeAllDice, unselectAllModifiers } = this.props;

    removeAllDice();
    unselectAllModifiers();
    if (!isPrivateRoll && presenceChannel)
      presenceChannel.trigger(ChannelEvent.ROLL_COMPLETED, {
        userId: currentUser.id,
      });
  };

  onDieClick = (die) => {
    const { buffsTotal, conversation, dice: allDice, isPrivateRoll, targetsTotal } = this.props;

    const newDice = allDice.filter((o) => o.hasRolled || o.id === die.id);
    const index = newDice.findIndex((o) => o.id === die.id);
    newDice[index] = die;

    if (conversation && !isPrivateRoll) {
      const data = {
        refs: targetsTotal,
        buffs: buffsTotal,
        dice: newDice.map(({ sides, type, value, color, name }) => ({ sides, type, value, color, name })),
      };
      conversation.sendMessage(`${DICE_LOG_TOKEN}${JSON.stringify(data)}`);
      logEvent('table - send message', { 'is private': false, 'is dice': true });
    }

    for (const die of newDice.filter((die) => !die.hasRolled)) {
      logEvent('table - die roll', {
        name: die.name,
        sides: die.sides,
        type: die.type,
        value: die.value,
        'is private': isPrivateRoll,
      });
    }
  };

  onDieIconClick = (die) => {
    const { currentUser, presenceChannel, isPrivateRoll, removeDie } = this.props;
    removeDie(die.id);
    if (!isPrivateRoll && presenceChannel)
      presenceChannel.trigger(ChannelEvent.DIE_REMOVED, {
        userId: currentUser.id,
        dieId: die.id,
      });
  };

  onRollClick = () => {
    this.doRoll(this.props.dice.filter((o) => !o.hasRolled));
  };

  onRerollClick = () => {
    this.doRoll(this.props.dice);
  };

  onBuffChange = (event) => {
    const { manualBuff, updateModifier } = this.props;
    const { value } = event.target;
    updateModifier({ ...manualBuff, value });
  };

  onBuffBlur = (event) => {
    const { currentUser, isPrivateRoll, manualBuff, presenceChannel } = this.props;
    const { updateModifier } = this.props;
    const parsed = parseInt(event.target.value);
    const value = isNaN(parsed) ? 0 : parsed;
    const modifier = { ...manualBuff, value };
    updateModifier(modifier);

    if (!isPrivateRoll && presenceChannel) {
      if (modifier.value !== 0)
        presenceChannel.trigger(ChannelEvent.MODIFIER_ADDED, {
          userId: currentUser.id,
          modifier: modifier,
        });
      else
        presenceChannel.trigger(ChannelEvent.MODIFIER_REMOVED, {
          userId: currentUser.id,
          modifierId: modifier.id,
        });
    }
  };

  onTargetChange = (event) => {
    const { manualTarget, updateModifier } = this.props;
    const { value } = event.target;
    updateModifier({ ...manualTarget, value });
  };

  onTargetBlur = (event) => {
    const { currentUser, isPrivateRoll, manualTarget, presenceChannel } = this.props;
    const { updateModifier } = this.props;
    const parsed = parseInt(event.target.value);
    const value = isNaN(parsed) ? 0 : parsed;
    const modifier = { ...manualTarget, value };
    updateModifier(modifier);

    if (!isPrivateRoll && presenceChannel) {
      if (modifier.value !== 0)
        presenceChannel.trigger(ChannelEvent.MODIFIER_ADDED, {
          userId: currentUser.id,
          modifier: modifier,
        });
      else
        presenceChannel.trigger(ChannelEvent.MODIFIER_REMOVED, {
          userId: currentUser.id,
          modifierId: modifier.id,
        });
    }
  };

  onToggleTotalClick = () => {
    const { room, currentUser, isShowingTotal, showTotal, updateUserSettings } = this.props;
    const newValue = !isShowingTotal;
    showTotal(newValue);
    if (room && currentUser) updateUserSettings(room.guid, currentUser.id, { diceShowTotal: newValue });
  };

  onShouldClearChange = (checked) => {
    const { room, currentUser, setShouldClearAfterRoll, updateUserSettings } = this.props;
    setShouldClearAfterRoll(checked);
    updateUserSettings(room.guid, currentUser.id, { diceAutoClear: checked });
  };

  onPrivacyChange = (checked) => {
    const { room, currentUser, dice, modifiers, presenceChannel, setPrivateRoll, updateUserSettings } = this.props;

    setPrivateRoll(checked);
    updateUserSettings(room.guid, currentUser.id, { dicePrivateRoll: checked });
    if (presenceChannel) {
      if (checked) {
        presenceChannel.trigger(ChannelEvent.ROLL_COMPLETED, {
          userId: currentUser.id,
        });
      } else {
        dice.forEach((die) =>
          presenceChannel.trigger(ChannelEvent.DIE_ADDED, {
            userId: currentUser.id,
            die,
          })
        );
        modifiers.forEach((modifier) =>
          presenceChannel.trigger(ChannelEvent.MODIFIER_ADDED, {
            userId: currentUser.id,
            modifier,
          })
        );
      }
    }
  };

  renderActions = () => {
    const { dice, colors } = this.props;
    const diceRolled = dice.filter((die) => die.hasRolled);
    const hasRolledAll = dice.length > 0 && diceRolled.length === dice.length;
    const isRolling = dice.some((die) => die.isRolling && !die.hasRolled);

    return (
      <>
        <Button
          variant="cancel"
          color="var(--color-white)"
          hoverColor="var(--color-dark-text)"
          onClick={this.onClearEverything}
          className={styles.clearButton}
        >
          Clear
        </Button>

        <Button
          onClick={hasRolledAll ? this.onRerollClick : this.onRollClick}
          isDisabled={dice.length === 0 || isRolling}
          primaryBackground={colors[0]}
          secondaryBackground={colors[1]}
          className={styles.actionButton}
        >
          Roll
        </Button>
      </>
    );
  };

  renderBuffs = () => {
    const { buffs, buffsTotal, colors, manualBuff } = this.props;
    let manualValue = 0;
    if (manualBuff) {
      const parsed = parseInt(manualBuff.value);
      manualValue = isNaN(parsed) ? 0 : parsed;
    }

    if (buffs.length === 0 && manualValue === 0) {
      return (
        <button onClick={this.onAddManualBuff} className={`button-reset ${styles.addManualModifierButton}`}>
          + Add Buff
        </button>
      );
    }

    return (
      <div className={styles.modifierContainer}>
        <GradientBorder
          onClick={this.onAddManualBuff}
          size={54}
          colors={colors}
          className={styles.modifier}
          style={{ cursor: 'pointer' }}
        >
          <div className={styles.modifierTotal}>{`${buffsTotal > 0 ? '+' : ''}${buffsTotal}`}</div>
        </GradientBorder>
        <div className={`${styles.modifierTitle}`}>Buff</div>
      </div>
    );
  };

  renderBuffsModal = () => {
    const { buffs, buffsTotal, colors, manualBuff } = this.props;
    const { showBuffs } = this.props;
    const onDismiss = () => showBuffs(false);
    let manualValue = 0;
    if (manualBuff) {
      const parsed = parseInt(manualBuff.value);
      manualValue = isNaN(parsed) ? 0 : parsed;
    }
    const grey = 'rgba(255,255,255,0.2)';
    const white = ['var(--color-white)', 'var(--color-white)'];

    return (
      <Modal maxWidth="800px" width="100%" onDismiss={onDismiss}>
        <header className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>Roll Buff</h3>
        </header>
        <div className={styles.modalContent}>
          <div className={`${styles.modifierContainer} ${styles.modalTotal}`}>
            <GradientBorder width={98} height={54} colors={colors} className={styles.modifier}>
              <div className={styles.modifierTotal}>{buffsTotal}</div>
            </GradientBorder>
            <div className={`heading2 is-centered ${styles.modifierTitle}`}>Total</div>
          </div>

          <div className={`${styles.modifierContainer} ${styles.modalColumn}`}>
            <GradientBorder width={98} height={54} color={grey} className={styles.modifier}>
              <div className={styles.modifierTotal}>{buffsTotal - manualValue}</div>
            </GradientBorder>
            <div className={`heading2 is-centered ${styles.modifierTitle}`}>From Sheets</div>
            <div className={styles.modalModifiers}>
              {buffs.map((modifier) => {
                return <DiceModifier key={modifier.id} modifier={modifier} />;
              })}
            </div>
          </div>

          <div className={`${styles.modifierContainer} ${styles.modalColumn}`}>
            <GradientBorder width={98} height={54} colors={white} className={styles.modifier}>
              {manualBuff && (
                <input
                  onBlur={this.onBuffBlur}
                  onChange={this.onBuffChange}
                  className={styles.modifierTotal}
                  value={manualBuff.value}
                />
              )}
            </GradientBorder>
            <div className={`heading2 is-centered ${styles.modifierTitle}`}>Custom</div>
          </div>

          <div className={styles.actions}>
            <Button
              onClick={onDismiss}
              primaryBackground={colors[0]}
              secondaryBackground={colors[1]}
              className={styles.actionButton}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  renderDice = () => {
    const { colors, dice } = this.props;
    return dice.map((die) => {
      return (
        <Die key={die.id} die={die} colors={colors} onDieClick={this.onDieClick} onIconClick={this.onDieIconClick} />
      );
    });
  };

  renderSettings = () => {
    const { isPrivateRoll, shouldClearAfterRoll, previewRoom } = this.props;

    return (
      !previewRoom && (
        <>
          <ToggleSwitch label="Private" checked={isPrivateRoll} onChange={this.onPrivacyChange} variant="theme" />
          <ToggleSwitch
            label="Auto Clear"
            checked={shouldClearAfterRoll}
            onChange={this.onShouldClearChange}
            variant="theme"
          />
        </>
      )
    );
  };

  renderTargets = () => {
    const { colors, manualTarget, targets, targetsTotal } = this.props;
    let manualValue = 0;
    if (manualTarget) {
      const parsed = parseInt(manualTarget.value);
      manualValue = isNaN(parsed) ? 0 : parsed;
    }
    if (targets.length === 0 && manualValue === 0) {
      return (
        <button onClick={this.onAddManualTarget} className={`button-reset ${styles.addManualModifierButton}`}>
          + Add Ref
        </button>
      );
    }

    return (
      <>
        <div className={styles.modifierContainer}>
          <GradientBorder
            onClick={this.onAddManualTarget}
            width={98}
            height={54}
            colors={colors}
            className={styles.modifier}
            style={{ cursor: 'pointer' }}
          >
            <div className={styles.modifierTotal}>{targetsTotal}</div>
          </GradientBorder>
          <div className={`${styles.modifierTitle}`}>Reference</div>
        </div>
        <div className={`${styles.targetsVs}`}>vs</div>
      </>
    );
  };

  renderTargetsModal = () => {
    const { colors, manualTarget, targets, targetsTotal } = this.props;
    const { showTargets } = this.props;
    const onDismiss = () => showTargets(false);
    let manualValue = 0;
    if (manualTarget) {
      const parsed = parseInt(manualTarget.value);
      manualValue = isNaN(parsed) ? 0 : parsed;
    }
    const grey = 'rgba(255,255,255,0.2)';
    const white = ['var(--color-white)', 'var(--color-white)'];

    return (
      <Modal maxWidth="600px" width="100%" onDismiss={onDismiss}>
        <header className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>Roll Reference</h3>
        </header>
        <div className={styles.modalContent}>
          <div className={`${styles.modifierContainer} ${styles.modalTotal}`}>
            <GradientBorder width={98} height={54} colors={colors} className={styles.modifier}>
              <div className={styles.modifierTotal}>{targetsTotal}</div>
            </GradientBorder>
            <div className={`${styles.modifierTitle}`}>Total</div>
          </div>

          <div className={`${styles.modifierContainer} ${styles.modalColumn}`}>
            <GradientBorder width={98} height={54} color={grey} className={styles.modifier}>
              <div className={styles.modifierTotal}>{targetsTotal - manualValue}</div>
            </GradientBorder>
            <div className={`${styles.modifierTitle}`}>From Sheets</div>
            <div className={styles.modalModifiers}>
              {targets.map((modifier) => {
                return <DiceModifier key={modifier.id} modifier={modifier} />;
              })}
            </div>
          </div>

          <div className={`${styles.modifierContainer} ${styles.modalColumn}`}>
            <GradientBorder width={98} height={54} colors={white} className={styles.modifier}>
              {manualTarget && (
                <input
                  onBlur={this.onTargetBlur}
                  onChange={this.onTargetChange}
                  className={styles.modifierTotal}
                  value={manualTarget.value}
                />
              )}
            </GradientBorder>
            <div className={`${styles.modifierTitle}`}>Custom</div>
          </div>

          <div className={styles.actions}>
            <Button
              onClick={onDismiss}
              primaryBackground={colors[0]}
              secondaryBackground={colors[1]}
              className={styles.actionButton}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { buffsTotal, dice, isShowingBuffs, isShowingTargets, isShowingTotal, manualBuff } = this.props;
    const diceRolled = dice.filter((die) => die.hasRolled);
    const hasRolledAll = dice.length > 0 && diceRolled.length === dice.length;
    const numTotal = diceRolled
      .filter((o) => o.type !== DieType.FATE)
      .reduce((total, die) => {
        return total + die.value;
      }, 0);
    const fateTotal = diceRolled
      .filter((o) => o.type === DieType.FATE)
      .reduce((total, die) => {
        return total + getFateValue(die.value);
      }, 0);
    const total = numTotal + fateTotal + buffsTotal;
    const totalButtonCopy = isShowingTotal ? 'Hide Total' : 'Show Total';
    const totalButtonTitle = isShowingTotal ? 'Hide Dice Total' : 'Show Dice Total';

    return (
      <div ref={this.containerRef} className={`${styles.container} ${true ? styles.isShowing : ''}`}>
        {this.renderTargets()}
        {dice.length === 0 ? (
          <div className={styles.empty}>
            <p>Select some dice to start your roll!</p>
          </div>
        ) : (
          <div className={clsx('scrollbars-dark', styles.tray)}>{this.renderDice()}</div>
        )}
        {this.renderBuffs()}

        <div className={`${styles.totalContainer} ${isShowingTotal ? styles.isShowingTotal : ''}`}>
          <div className={styles.totalContent}>
            <GradientBorder
              width={216}
              height={73}
              isRainbow={hasRolledAll}
              color={!hasRolledAll && 'rgb(var(--color-theme-text) / 0.3)'}
              className={`${styles.total} ${hasRolledAll ? styles.hasRolled : ''}`}
            >
              <div className={`heading1 ${styles.totalNum}`}>{diceRolled.length > 0 && total}</div>
            </GradientBorder>
          </div>

          <button
            onClick={this.onToggleTotalClick}
            title={totalButtonTitle}
            aria-label={totalButtonTitle}
            className={`button-reset ${styles.toggleTotalButton}`}
          >
            {isShowingTotal ? <VisibleIcon /> : <NotVisibleIcon />}
            <span>{totalButtonCopy}</span>
          </button>
        </div>
        <div className={styles.actions}>{this.renderActions()}</div>
        <div className={styles.settings}>{this.renderSettings()}</div>

        {isShowingBuffs && this.renderBuffsModal()}
        {isShowingTargets && this.renderTargetsModal()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const currentUser = SessionSelector.currentUser(state);
  const previewRoom = TableSelector.getPreviewRoom(state);
  let colors = DEFAULT_COLORS;
  if (room) {
    colors = RoomUserSelector.getColors(state, room.guid, currentUser.id);
  } else if (previewRoom) {
    colors = RoomDocumentSelector.getPreviewColorsBySlug(state, previewRoom);
  }

  return {
    buffs: RollSelector.getSelectedBuffs(state),
    buffsTotal: RollSelector.getBuffsTotal(state),
    colors,
    conversation: room ? TwilioSelector.getDiceConversation(state, room.guid) : null,
    currentUser,
    dice: RollSelector.getDice(state, 'color'),
    isPrivateRoll: TableSelector.isPrivateRoll(state),
    isShowingBuffs: TableSelector.isShowingBuffs(state),
    isShowingTargets: TableSelector.isShowingTargets(state),
    isShowingTotal: TableSelector.isShowingDiceTotal(state),
    shouldClearAfterRoll: TableSelector.shouldClearAfterRoll(state),
    manualBuff: RollSelector.getManualBuff(state),
    manualTarget: RollSelector.getManualTarget(state),
    modifiers: RollSelector.getSelectedNonDiceModifiers(state),
    presenceChannel: TableSelector.getPresenceChannel(state),
    previewRoom: TableSelector.getPreviewRoom(state),
    targets: RollSelector.getSelectedTargets(state),
    targetsTotal: RollSelector.getTargetsTotal(state),
    userSettings: room && currentUser ? RoomUserSelector.getSettings(state, room.guid, currentUser.id) : {},
  };
};

const mapDispatchToProps = {
  addModifier: RollAction.addModifier,
  privateRoll: TableAction.privateRoll,
  removeDie: RollAction.removeDie,
  removeAllDice: RollAction.removeAllDice,
  setShouldClearAfterRoll: TableAction.setShouldClearAfterRoll,
  setPrivateRoll: TableAction.privateRoll,
  showBuffs: TableAction.showBuffs,
  showTargets: TableAction.showTargets,
  showTotal: TableAction.showDiceTotal,
  unselectAllModifiers: RollAction.unselectAllModifiers,
  updateDie: RollAction.updateDie,
  updateModifier: RollAction.updateModifier,
  updateUserSettings: RoomUserAction.updateSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiceTray);
