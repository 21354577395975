import { createAction, handleError } from './utilities';
import { RoomUserSelector, SessionSelector } from 'store/selectors';

import role from 'apis/role';

export default class RoomUserAction {
  static DELETE = 'RoomUserAction.DELETE';
  static FETCH = 'RoomUserAction.FETCH';
  static FETCH_ALL = 'RoomUserAction.FETCH_ALL';
  static REMOVE = 'RoomUserAction.REMOVE';
  static UPDATE = 'RoomUserAction.UPDATE';
  static UPDATE_SETTINGS = 'RoomUserAction.UPDATE_SETTINGS';
  static UPDATE_SID = 'RoomUserAction.UPDATE_SID';
  static UPDATE_STATUS = 'RoomUserAction.UPDATE_STATUS';

  static delete = (guid, id, callback) => async (dispatch) => {
    return role.delete(`/rooms/${guid}/users/${id}`).then(
      () => {
        dispatch(createAction(RoomUserAction.DELETE, { guid, id }));
        if (callback) callback(guid, id);
      },
      ({ response }) => handleError(dispatch, RoomUserAction.DELETE, response)
    );
  };

  static fetch = (guid, id, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/users/${id}`).then(
      (response) => {
        const { id, attributes } = response.data.data;
        const obj = { id, ...attributes };
        dispatch(createAction(RoomUserAction.FETCH, obj));
        if (callback) callback(obj);
      },
      ({ response }) => handleError(dispatch, RoomUserAction.FETCH, response)
    );
  };

  static fetchAll = (guid, callback) => async (dispatch) => {
    return role.get(`/rooms/${guid}/users`).then(
      (response) => {
        const objs = response.data.data.map((obj) => {
          const { id, attributes } = obj;
          return { id, ...attributes };
        });
        dispatch(createAction(RoomUserAction.FETCH_ALL, objs));
        if (callback) callback(objs);
      },
      ({ response }) => handleError(dispatch, RoomUserAction.FETCH_ALL, response)
    );
  };

  static remove = (guid, id) => {
    return createAction(RoomUserAction.REMOVE, { guid, id });
  };

  static update = (guid, id, formValues, callback) => async (dispatch, getState) => {
    return role
      .patch(`/rooms/${guid}/users/${id}`, { ...formValues, socket_id: SessionSelector.getSocketId(getState()) })
      .then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomUserAction.UPDATE, obj));
          callback?.();
        },
        ({ response }) => handleError(dispatch, RoomUserAction.UPDATE, response)
      );
  };

  static updateSettings = (guid, id, settings, callback) => async (dispatch, getState) => {
    const currentSettings = RoomUserSelector.getSettings(getState(), guid, id);
    const params = {
      room_user: {
        settings: { ...currentSettings, ...settings },
      },
    };
    return role
      .patch(`/rooms/${guid}/users/${id}`, { ...params, socket_id: SessionSelector.getSocketId(getState()) })
      .then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(RoomUserAction.UPDATE_SETTINGS, obj));
          callback?.();
        },
        ({ response }) => handleError(dispatch, RoomUserAction.UPDATE_SETTINGS, response)
      );
  };

  static updateSid = (guid, id, sid) => {
    return createAction(RoomUserAction.UPDATE_SID, { guid, id, sid });
  };

  static updateStatus = (guid, id, status) => {
    return createAction(RoomUserAction.UPDATE_STATUS, { guid, id, status });
  };

  static fetchAllRoomUsers = (guids, callback) => async (dispatch) => {
    guids.forEach((guid) => {
      dispatch(this.fetchAll(guid, callback));
    });
  };
}
