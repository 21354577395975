import styles from './TableUserSettingsModal.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import RoomUserAction from 'store/actions/RoomUserAction';
import TableAction from 'store/actions/TableAction';
import { RoomSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';
import { getUserColors, linearGradient } from 'utilities/color';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import { ReactComponent as CheckIcon } from 'images/icons/CheckIcon.svg';

class TableUserSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedColors: getUserColors(this.props.user) };
  }

  onDismiss = () => {
    this.props.showUserSettings(false);
  };

  onSubmit = (formValues) => {
    const { room, user, update } = this.props;
    const { selectedColors } = this.state;
    if (!_.isEqual(getUserColors(user), selectedColors)) {
      logEvent('table - update my user colors', {
        'table guid': room.guid,
      });
    }
    update(room.guid, user.userId, { room_user: { ...formValues, colors: { hex: [...selectedColors] } } });
    this.onDismiss();
  };

  renderInput = ({ input, type, label, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <label className={styles.inputContainer}>
        {label}
        <TextInput
          input={{ ...input }}
          meta={meta}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          showError={submitFailed}
        />
      </label>
    );
  };

  render() {
    const { handleSubmit, user, userColors } = this.props;
    const { selectedColors } = this.state;
    const filteredUserColors = [...new Set(userColors)];

    return (
      <TitleModal
        title="Your Room Settings"
        actions={
          <>
            <Button variant="cancel" className={styles.button} onClick={this.onDismiss}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(this.onSubmit)} variant="primary" type="submit" className={styles.button}>
              Save
            </Button>
          </>
        }
        onDismiss={this.onDismiss}
      >
        <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
          <Field
            component={this.renderInput}
            name="name"
            type="text"
            label={
              <>
                <span className={styles.label}>
                  <strong>Display Name:</strong>
                </span>
                <span className={styles.description}>
                  This will update your name only at this room. This has no affect on your account username.
                </span>
              </>
            }
            placeholder="Leave blank to display your username"
          />
          <label className={styles.label}>Choose Your User Color:</label>
          <span className={styles.description}>
            User Colors are set by the Host's Room Theme. Choose a combination that fits you!
          </span>
          <div className={styles.colorPicker}>
            <div className={styles.colorSpread}>
              {filteredUserColors.length > 1 ? (
                filteredUserColors.map((color, i) => {
                  const others = filteredUserColors.filter((c) => c !== color);
                  return (
                    <div key={i} className={styles.colorRow}>
                      {others.map((other, j) => {
                        const isSelectedColor = _.isEqual([color, other], selectedColors);
                        return (
                          <div
                            key={`${i}-${j}`}
                            className={clsx(styles.color, isSelectedColor && styles.selectedColorContainer)}
                            onClick={() => this.setState({ selectedColors: [color, other] })}
                            role="button"
                            style={{ background: linearGradient([color, other], '135deg') }}
                          >
                            {isSelectedColor && (
                              <div className={styles.selectedColor}>
                                <CheckIcon />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div>
                  Looks like you're using default user colors. The Room Host can set new user colors in Room Settings.
                </div>
              )}
            </div>
            <div className={styles.colorExampleContainer}>
              <Avatar
                className={styles.avatar}
                avatarUrl={user.avatarUrl}
                avatarAlt="You"
                size={146}
                borderColors={selectedColors}
                showPlayerDefault
                shape={'squircle'}
              />
            </div>
          </div>
        </form>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const currentUser = SessionSelector.currentUser(state);
  const user = RoomUserSelector.get(state, room.guid, currentUser.id);

  return {
    user,
    userColors: RoomSelector.getColors(state, room.guid),
    initialValues: {
      name: user.name,
    },
  };
};

const mapDispatchToProps = {
  showUserSettings: TableAction.showUserSettings,
  update: RoomUserAction.update,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'tableUserSettings' })
)(TableUserSettingsModal);
