import styles from './FTE.module.css';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { TableAction, UserAction } from 'store/actions';
import { GameSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import { TitleModal } from 'components/Modal';
import { ReactComponent as Bubble } from 'images/fte/cr/bubble.svg';
import caduceus from 'images/fte/cr/caduceus.png';
import { ReactComponent as DiscordIcon } from 'images/icons/DiscordIcon.svg';
import { ReactComponent as InviteIcon } from 'images/icons/InviteIcon.svg';
import { ReactComponent as SearchIcon } from 'images/icons/SearchIcon.svg';

export default function CompletedModal() {
  const dispatch = useDispatch();
  const { guid } = useParams();
  const game = useSelector((state) => GameSelector.getByRoom(state, guid));
  const currentUser = useSelector(SessionSelector.currentUser);
  const { gameQuery } = useSelector(SessionSelector.getUserFte);
  const crRoomGuids = process.env.REACT_APP_CR_ROOMS;
  const showCR = crRoomGuids && guid && crRoomGuids.split(',').includes(guid);

  const onDismiss = () => {
    logEvent('fte - completed', {
      'room guid': guid,
      'has game': !!game,
      'game id': game?.id,
      'game title': game?.title,
      'is cr': showCR,
    });
    const payload = {
      user: {
        metadata: { ...currentUser.metadata, fte: { ...currentUser.metadata.fte, showMultiStep: false, step: 0 } },
      },
    };
    dispatch(UserAction.updateJson(currentUser.id, payload));
  };

  const onInviteClick = () => {
    logEvent('fte - invite friends clicked', {
      'room guid': guid,
      'has game': !!game,
      'game id': game?.id,
      'game title': game?.title,
      'is cr': showCR,
    });
    dispatch(TableAction.showInvite(true));
    onDismiss();
  };

  const onTemplateSearchClick = () => {
    logEvent('fte - template search clicked', {
      'room guid': guid,
      'has game': !!game,
      'game id': game?.id,
      'game title': game?.title,
      'is cr': showCR,
      'game query': gameQuery,
    });
  };

  return (
    <TitleModal onDismiss={onDismiss} title="Tutorial Completed" className={styles.modalOverflow}>
      <div className={styles.wrapper}>
        <p className={styles.completedMainCopy}>
          And those are the basics of a room! While you explore your new room in more depth, here are some other helpful
          next steps:
        </p>
        <div className={styles.nextStepsContainer}>
          <div className={styles.nextStep}>
            <SearchIcon />
            <h3>Discover Templates</h3>
            Check out our{' '}
            <Link
              onClick={onTemplateSearchClick}
              to={gameQuery ? `/search?query=${gameQuery}` : `/search`}
              target="_blank"
            >
              Template Search
            </Link>{' '}
            with thousands of user-created templates! Maybe they have some for your favorite game.
          </div>
          <div className={styles.nextStep}>
            <DiscordIcon />
            <h3>Find Community</h3>
            We have a growing{' '}
            <a href="https://discord.gg/HVv5eg3XwG" target="_blank" rel="noopener noreferrer">
              Discord server
            </a>{' '}
            where you can find other people playing and creating on Role!
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div className={styles.nextStep}>
            <InviteIcon />
            <h3>Ready to Play?</h3>
            <a href="javascript:void(0)" onClick={onInviteClick}>
              Invite your friends
            </a>{' '}
            to this room and begin telling your next great story.
          </div>
        </div>
        <div className={styles.completedActions}>
          <Button className={styles.button} onClick={onDismiss} type="button" variant="primary">
            Check out your new room
          </Button>
        </div>
        {showCR && (
          <>
            <img className={clsx(styles.character, styles.caduceus)} src={caduceus} alt="caduceus clay" />
            <blockquote className={clsx(styles.quote, styles.caduceus)}>
              <Bubble />
              <p className={styles.quoteCopy}>
                Life needs things to live...and this room needs some more friends to live!
              </p>
            </blockquote>
          </>
        )}
      </div>
    </TitleModal>
  );
}
