import { createAction } from './utilities';

export default class TableAction {
  static ADD_USER = 'TableAction.ADD_USER';
  static PIN_ACTIVE_USER = 'TableAction.PIN_ACTIVE_USER';
  static CONFIRM_REMOVE_USER = 'TableAction.CONFIRM_REMOVE_USER';
  static REMOVE_USER = 'TableAction.REMOVE_USER';
  static SET_ACTIVE_USER = 'TableAction.SET_ACTIVE_USER';
  static SHOW_USER_MENU = 'TableAction.SHOW_USER_MENU';
  static UNPIN_ACTIVE_USER = 'TableAction.UNPIN_ACTIVE_USER';

  static SET_PLAY_PANEL_CONTENT = 'TableAction.SET_PLAY_PANEL_CONTENT';

  static SET_ACTIVE_ASSET = 'TableAction.SET_ACTIVE_ASSET';
  static SET_PRIVATE_ASSET = 'TableAction.SET_PRIVATE_ASSET';
  static SET_SHARED_ASSET = 'TableAction.SET_SHARED_ASSET';

  static SHOW_ACTIVE_ASSET = 'TableAction.SHOW_ACTIVE_ASSET';
  static SHOW_SHARED_ASSET = 'TableAction.SHOW_SHARED_ASSET';

  static HIDE_CARD_DRAWER = 'TableAction.HIDE_CARD_DRAWER';
  static SHOW_CARD_DRAWER = 'TableAction.SHOW_CARD_DRAWER';

  static SET_FOCUS_MODE = 'TableAction.SET_FOCUS_MODE';

  static ADD_SHEET = 'TableAction.ADD_SHEET';
  static PRIVATE_ROLL = 'TableAction.PRIVATE_ROLL';
  static REMOVE = 'TableAction.REMOVE';
  static RESET = 'TableAction.RESET';
  static SET_ACTIVE_SHEET = 'TableAction.SET_ACTIVE_SHEET';
  static SET_ACTIVE_SHEET_USER = 'TableAction.SET_ACTIVE_SHEET_USER';
  static SET_CURRENT_TAB = 'TableAction.SET_CURRENT_TAB';
  static SET_OBR_ACTIVE = 'TableAction.SET_OBR_ACTIVE';
  static SET_ENTERED = 'TableAction.SET_ENTERED';
  static SET_PRESENCE_CHANNEL = 'TableAction.SET_PRESENCE_CHANNEL';
  static SET_PREVIEW_ROOM = 'TableAction.SET_PREVIEW_ROOM';
  static SET_PREVIEW_SHEET = 'TableAction.SET_PREVIEW_SHEET';
  static SET_ROOM_GUID = 'TableAction.SET_ROOM_GUID';
  static SET_THEME = 'TableAction.SET_THEME';
  static SET_SHOULD_CLEAR_AFTER_ROLL = 'TableAction.SET_SHOULD_CLEAR_AFTER_ROLL';
  static SHOW_BUFFS = 'TableAction.SHOW_BUFFS';
  static SHOW_DICE_TOTAL = 'TableAction.SHOW_DICE_TOTAL';
  static SHOW_DICE_TRAY = 'TableAction.SHOW_DICE_TRAY';
  static SHOW_INVITE = 'TableAction.SHOW_INVITE';
  static SHOW_MANAGE_USERS = 'TableAction.SHOW_MANAGE_USERS';
  static SHOW_PING = 'TableAction.SHOW_PING';
  static SHOW_ROOM_SHEETS_MENU = 'TableAction.SHOW_ROOM_SHEETS_MENU';
  static SHOW_SETTINGS = 'TableAction.SHOW_SETTINGS';
  static SHOW_SHEET = 'TableAction.SHOW_SHEET';
  static SHOW_SHEET_TOC = 'TableAction.SHOW_SHEET_TOC';
  static SHOW_SHEET_MENU = 'TableAction.SHOW_SHEET_MENU';
  static SHOW_SHEETS = 'TableAction.SHOW_SHEETS';
  static SHOW_TARGETS = 'TableAction.SHOW_TARGETS';
  static SHOW_USER_SETTINGS = 'TableAction.SHOW_USER_SETTINGS';
  static SHOW_VIDEO_SETTINGS = 'TableAction.SHOW_VIDEO_SETTINGS';
  static UPDATE_VIEW_MODE = 'TableAction.UPDATE_VIEW_MODE';

  static addSheet = (isAdding) => {
    return createAction(TableAction.ADD_SHEET, isAdding);
  };

  static privateRoll = (isPrivate) => {
    return createAction(TableAction.PRIVATE_ROLL, isPrivate);
  };

  static remove = (guid) => {
    return createAction(TableAction.REMOVE, guid);
  };

  static reset = () => {
    return createAction(TableAction.RESET);
  };

  static setActiveSheet = (id) => {
    return createAction(TableAction.SET_ACTIVE_SHEET, id);
  };

  static setActiveSheetUser = (id) => {
    return createAction(TableAction.SET_ACTIVE_SHEET_USER, id);
  };

  static setCurrentTab = (tab) => {
    return createAction(TableAction.SET_CURRENT_TAB, tab);
  };

  static setEntered = (entered) => {
    return createAction(TableAction.SET_ENTERED, entered);
  };

  static setObrActive = (active) => {
    return createAction(TableAction.SET_OBR_ACTIVE, active);
  };

  static setPresenceChannel = (channel) => {
    return createAction(TableAction.SET_PRESENCE_CHANNEL, channel);
  };

  static setPreviewRoom = (data) => {
    return createAction(TableAction.SET_PREVIEW_ROOM, data);
  };

  static setPreviewSheet = (data) => {
    return createAction(TableAction.SET_PREVIEW_SHEET, data);
  };

  static setRoomGuid = (guid) => {
    return createAction(TableAction.SET_ROOM_GUID, guid);
  };

  static setShouldClearAfterRoll = (clear) => createAction(TableAction.SET_SHOULD_CLEAR_AFTER_ROLL, clear);

  static setTheme = (theme) => createAction(TableAction.SET_THEME, theme);

  static showBuffs = (show) => {
    return createAction(TableAction.SHOW_BUFFS, show);
  };

  static showDiceTotal = (show) => {
    return createAction(TableAction.SHOW_DICE_TOTAL, show);
  };

  static showInvite = (show) => {
    return createAction(TableAction.SHOW_INVITE, show);
  };

  static showManageUsers = (show) => {
    return createAction(TableAction.SHOW_MANAGE_USERS, show);
  };

  static showPing = (show) => {
    return createAction(TableAction.SHOW_PING, show);
  };

  static showRoomSheetsMenu = (show) => {
    return createAction(TableAction.SHOW_ROOM_SHEETS_MENU, show);
  };

  static showSettings = (show) => {
    return createAction(TableAction.SHOW_SETTINGS, show);
  };

  static showSheet = (show) => {
    return createAction(TableAction.SHOW_SHEET, show);
  };

  static showSheetTOC = (show) => createAction(TableAction.SHOW_SHEET_TOC, show);

  static showSheetMenu = (show) => {
    return createAction(TableAction.SHOW_SHEET_MENU, show);
  };

  static showSheets = (show) => {
    return createAction(TableAction.SHOW_SHEETS, show);
  };

  static showTargets = (show) => {
    return createAction(TableAction.SHOW_TARGETS, show);
  };

  static showUserSettings = (show) => {
    return createAction(TableAction.SHOW_USER_SETTINGS, show);
  };

  static showVideoSettings = (show) => {
    return createAction(TableAction.SHOW_VIDEO_SETTINGS, show);
  };

  static updateViewMode = (mode) => {
    return createAction(TableAction.UPDATE_VIEW_MODE, mode);
  };

  // ========================================
  // USER
  // ========================================

  static addUser = (id) => {
    return createAction(TableAction.ADD_USER, id);
  };

  static confirmRemoveUser = (id) => {
    return createAction(TableAction.CONFIRM_REMOVE_USER, id);
  };

  static pinActiveUser = () => {
    return createAction(TableAction.PIN_ACTIVE_USER);
  };

  static removeUser = (id) => {
    return createAction(TableAction.REMOVE_USER, id);
  };

  static setActiveUser = (id) => {
    return createAction(TableAction.SET_ACTIVE_USER, id);
  };

  static setFocusMode = (isFocusMode) => createAction(TableAction.SET_FOCUS_MODE, isFocusMode);

  static showUserMenu = (id) => {
    return createAction(TableAction.SHOW_USER_MENU, id);
  };

  static unpinActiveUser = () => {
    return createAction(TableAction.UNPIN_ACTIVE_USER);
  };

  // ========================================
  // CARD DRAWER
  // ========================================

  static hideCardDrawer = () => {
    return createAction(TableAction.HIDE_CARD_DRAWER);
  };

  static showCardDrawer = () => {
    return createAction(TableAction.SHOW_CARD_DRAWER);
  };

  // ========================================
  // ROOM DOCUMENTS
  // ========================================

  static setActiveRoomDocument = (id) => {
    return createAction(TableAction.SET_ACTIVE_ASSET, id);
  };

  static setPrivateRoomDocument = (id) => {
    return createAction(TableAction.SET_PRIVATE_ASSET, id);
  };

  static setSharedRoomDocument = (id) => {
    return createAction(TableAction.SET_SHARED_ASSET, id);
  };

  // ========================================
  // PLAY PANEL
  // ========================================

  static setPlayPanelContent = (tab) => {
    return createAction(TableAction.SET_PLAY_PANEL_CONTENT, tab);
  };
}
