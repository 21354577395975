import { mapKeys } from 'lodash';

export default class Die {
  color = null;
  isRolling = false;
  isEnding = false;
  hasRolled = false;
  value = -1;

  constructor(die) {
    const sides = die.sides || 0;
    this.id = die.id || null;
    this.color = die.color;
    this.sides = sides;
    this.type = die.type || DieType.NUMBER;
    this.name = die.name || (this.isFate() ? 'DF' : `D${sides}`);
  }

  isFate = () => this.type === DieType.FATE;
}

export const DieType = {
  NUMBER: 'number',
  FATE: 'fate',
};

export const D2 = new Die({ sides: 2, type: DieType.NUMBER });
export const D4 = new Die({ sides: 4, type: DieType.NUMBER });
export const D6 = new Die({ sides: 6, type: DieType.NUMBER });
export const D8 = new Die({ sides: 8, type: DieType.NUMBER });
export const D10 = new Die({ sides: 10, type: DieType.NUMBER });
export const D12 = new Die({ sides: 12, type: DieType.NUMBER });
export const D20 = new Die({ sides: 20, type: DieType.NUMBER });
export const D100 = new Die({ sides: 100, type: DieType.NUMBER });
export const DF = new Die({ sides: 3, type: DieType.FATE });

export const DICE = [D2, D4, D6, D8, D10, D12, D20, D100, DF];

export const DEFAULT_DICE_SETTINGS = mapKeys(
  DICE.map((die) => ({ id: `${die.type}-${die.sides}`, enabled: true, color: '' })),
  'id'
);
