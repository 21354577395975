// @flow
import styles from './MainLayout.module.css';

import type { Node } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

import { SessionSelector } from 'store/selectors';

import NavBar from './NavBar';
import TopBar from './TopBar';

type Props = {
  children?: ?Node,
  navChildren?: ?Node,
  navMenu?: ?Node,
  variant?: string,
};

function MainLayout({ children, navChildren, navMenu, variant }: Props): Node {
  const currentUser = useSelector(SessionSelector.currentUser);

  return (
    <>
      <header className={styles.header}>
        {variant === 'rainbow-only' ? (
          <TopBar rainbowOnly />
        ) : (
          <>
            <TopBar username={currentUser && currentUser.username} />
            <NavBar menu={navMenu}>{navChildren}</NavBar>
            <div className={styles.topBorder} />
          </>
        )}
      </header>
      {children}
    </>
  );
}

export default MainLayout;
