import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useCallbackAsRef from 'hooks/useCallbackAsRef';
import { GameSelector, ProductSelector, RoomSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import { logEvent } from 'utilities';
import usePublications from 'video/hooks/usePublications';
import useRoomState from 'video/hooks/useRoomState';
import useVideoContext from 'video/hooks/useVideoContext';

import { isScreenShareTrack } from 'video/utils';

export default function useTableHeartbeat(roomGuid: string) {
  const currentUser = useSelector(SessionSelector.currentUser);
  // @ts-ignore RoomSelector is not typed yet
  const room = useSelector((state) => RoomSelector.get(state, roomGuid));
  // @ts-ignore GameSelector is not typed yet
  const game = useSelector((state) => GameSelector.get(state, room.gameId));
  // @ts-ignore ProductSelector is not typed yet
  const gameProducts: any[] = useSelector((state) => ProductSelector.getByGame(state, room.gameId) ?? []);
  const users = useSelector(RoomUserSelector.getAllPresentInRoom(room.guid));

  const { room: videoRoom, participants, isSharingScreen } = useVideoContext();
  const videoRoomState = useRoomState();
  const publications = usePublications(videoRoom?.localParticipant);

  const isPublishingVideo =
    publications.find((p) => p.kind === 'video' && !isScreenShareTrack(p))?.isTrackEnabled ?? false;
  const isPublishingAudio = publications.find((p) => p.kind === 'audio')?.isTrackEnabled ?? false;

  const unlockedGameId = currentUser?.unlockedGameIds.includes(room.gameId) ? room.gameId : undefined;
  const unlockedProductIds = useMemo(
    () => _.intersection(currentUser?.unlockedProductIds ?? [], gameProducts?.map((g) => g.id) ?? []),
    [currentUser?.unlockedProductIds, gameProducts]
  );

  const logSessionHeartbeat = useCallbackAsRef(() => {
    logEvent('table - session heartbeat', {
      'total users': users.length,
      'has game': !!room.gameId,
      'game id': room.gameId,
      'product ids': room.productIds,
      'game title': game?.title,
      'game complexity': game?.complexity,
      'game min players': game?.minPlayers,
      'game max players': game?.maxPlayers,
      'game session length': game?.sessionLength,
      'unlocked product ids': unlockedProductIds,
      'unlocked game id': unlockedGameId,
    });
  });

  const logLivePlayHeartBeat = useCallbackAsRef(() => {
    logEvent('table - live play heartbeat', {
      'video enabled': isPublishingVideo,
      'audio enabled': isPublishingAudio,
      'total participants': participants.length + 1, // +1 to include local participant in count
      'has playkit': room.playkitIds.length > 0,
      'playkitIds': room.playkitIds,
      'has game': !!room.gameId,
      'game id': room.gameId,
      'product ids': room.productIds,
      'game title': game?.title,
      'game complexity': game?.complexity,
      'game min players': game?.minPlayers,
      'game max players': game?.maxPlayers,
      'game session length': game?.sessionLength,
      'unlocked product ids': unlockedProductIds,
      'unlocked game id': unlockedGameId,
    });
    if (isSharingScreen) {
      logEvent('table - screensharing');
    }
  });

  /**
   * This is ran once when videoRoom is connected because logLivePlayHeartBeat is a persistent ref to an object and won't trigger
   * a re-render.
   */
  useEffect(() => {
    if (videoRoomState === 'connected') {
      logLivePlayHeartBeat.current();
      let interval = window.setInterval(() => logLivePlayHeartBeat.current(), 300000);
      return () => {
        window.clearInterval(interval);
      };
    }
  }, [logLivePlayHeartBeat, videoRoomState]);

  useEffect(() => {
    logSessionHeartbeat.current();
    let interval = window.setInterval(() => logSessionHeartbeat.current(), 300000);
    return () => {
      window.clearInterval(interval);
    };
  }, [logSessionHeartbeat]);
}
