import styles from './ManagePlayersModal.module.css';

import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';

import { TableAction } from 'store/actions';
import { RoomUserSelector, SessionSelector } from 'store/selectors';

import Button from 'components/buttons/Button';
import GradientDot from 'components/GradientDot';
import { TitleModal } from 'components/Modal';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

function RenderUser({ user, guid, onRemoveClick }) {
  const { userId, name, username } = user;
  const colors = useSelector((state) => RoomUserSelector.getColors(state, guid, userId));

  return (
    <div className={styles.userContainer}>
      <div className={styles.user}>
        <GradientDot colors={colors} className={styles.userDot} />
        <span>
          {username}
          {name && ` / ${name}`}
        </span>
      </div>
      <button onClick={() => onRemoveClick(user)} className={`button-reset ${styles.removeButton}`}>
        <XIcon />
        Remove
      </button>
    </div>
  );
}

class ManagePlayersModal extends Component {
  onDismiss = () => {
    this.props.showManageUsers(false);
  };

  onRemoveClick = (user) => {
    this.props.showManageUsers(false);
    this.props.confirmRemoveUser(user.userId);
  };

  render() {
    const { room, users } = this.props;

    return (
      <TitleModal title="Manage Players" onDismiss={this.onDismiss}>
        <div className={styles.users}>
          {users.map((user) => (
            <RenderUser user={user} key={user.id} guid={room.guid} onRemoveClick={this.onRemoveClick} />
          ))}
        </div>
        <div className={styles.buttons}>
          <Button variant="primary" onClick={this.onDismiss}>
            Done
          </Button>
        </div>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const currentUser = SessionSelector.currentUser(state);
  const users = RoomUserSelector.getAllByRoom(room.guid)(state).filter((o) => o.userId !== currentUser.id);

  return {
    room,
    users,
  };
};

const mapDispatchToProps = {
  showManageUsers: TableAction.showManageUsers,
  confirmRemoveUser: TableAction.confirmRemoveUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlayersModal);
