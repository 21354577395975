import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';

import history from 'browserHistory';

import SessionAction from './SessionAction';

export const createAction = <P, M>(type: string, payload?: P, error = false, meta?: M) => {
  return { type, payload, error, meta };
};

export const createError = ({ message, title }: ReduxErrorStub): ReduxError => {
  return { id: uuid(), message, title };
};

export const handleError = (dispatch: Dispatch, action: string, response: AxiosResponse) => {
  if (response) {
    if (response.status === 401 && action !== SessionAction.SIGN_IN) {
      let path = '/login';
      const to = window.location.pathname;
      const toParam = new URLSearchParams(window.location.search).get('to');
      if (toParam) path += `?to=${encodeURIComponent(toParam)}`;
      else if (!['/', '/login'].includes(to)) path += `?to=${encodeURIComponent(`${to}${window.location.search}`)}`;
      dispatch(createAction(SessionAction.SIGN_OUT));
      history.push(path);
    } else {
      const data = response.data;
      const message = data.message || data;
      dispatch(createAction(action, createError({ message }), true));
    }
  } else {
    dispatch(createAction(action, createError({ message: 'Something went wrong!' }), true));
  }
};
