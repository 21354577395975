// @flow
import styles from './Switch.module.css';

import clsx from 'clsx';
import React from 'react';

type Props = {
  className?: ?string,
  isActive: boolean,
  onChange: (boolean) => void,
};

export default function Switch(props: Props) {
  const { className, isActive, onChange } = props;

  return (
    <span className={clsx(styles.container, className, isActive && styles.isActive, isActive && 'is-active')}>
      <input
        type="checkbox"
        checked={isActive}
        onChange={(e) => onChange(e.currentTarget.checked)}
        className={styles.input}
      />
      <span className={styles.knob} />
    </span>
  );
}
