import _ from 'lodash';

import { DEFAULT_COLORS, GRADIENT_COLORS_HEX } from 'models/Color';

export const conicGradient = (colors) => {
  return `conic-gradient(${colors.join(',')})`;
};

export const linearGradient = (colors, angle = '180deg') => {
  return `linear-gradient(${angle},${colors.join(',')})`;
};

export const radialGradient = (colors) => {
  return `radial-gradient(circle,${colors.join(',')})`;
};

export const hexToRGB = (h) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return `${parseInt(r, 16)} ${parseInt(g, 16)} ${parseInt(b, 16)}`;
};

export const stylizeTheme = (theme, variant) => {
  switch (variant) {
    case 'sheet':
      return {
        '--color-sheet-base': theme.base && hexToRGB(theme.base),
        '--color-sheet-button': theme.button && hexToRGB(theme.button),
        '--color-sheet-text': theme.text && hexToRGB(theme.text),
        '--color-sheet-accent': theme.accent && hexToRGB(theme.accent),
      };
    default:
      return {
        '--color-theme-base': theme.base && hexToRGB(theme.base),
        '--color-theme-button': theme.button && hexToRGB(theme.button),
        '--color-theme-text': theme.text && hexToRGB(theme.text),
        '--color-theme-accent': theme.accent && hexToRGB(theme.accent),
      };
  }
};

export const getUserColors = (user) => {
  if (user?.colors.hex?.length > 0) {
    return user.colors.hex;
  } else if (user?.colors.css?.length > 0) {
    return user.colors.css.map((c) => `var(${c})`);
  } else {
    return DEFAULT_COLORS;
  }
};

export const randomHexColor = (other) => {
  const color = _.sample(GRADIENT_COLORS_HEX);
  return !other || color !== other ? color : randomHexColor(other);
};
