import styles from './inputs.module.css';

import React, { Component } from 'react';

import { linearGradient } from 'utilities/color';

import { AssetImage } from 'components/Asset';
import { ReactComponent as DoubleArrowIcon } from 'images/icons/DoubleArrowIcon.svg';
import { ReactComponent as RoleIcon } from 'images/icons/RoleIcon.svg';

class FileInput extends Component {
  state = { fileUrl: null };
  inputRef = React.createRef();

  onChange = (event) => {
    const { input } = this.props;
    input.onChange(event);
    const file = event.target.files[0];
    this.setState({ fileUrl: file ? URL.createObjectURL(file) : null });
  };

  render() {
    const { fileUrl } = this.state;
    const {
      input: { name, value, onBlur, onFocus },
      meta: { touched, error },
      placeholder,
      placeholderImage,
      accept,
      showError,
      size,
      colors,
      isAsset,
      isDark,
      className,
    } = this.props;
    const hasValue = value && value !== '' && value.length > 0;
    const isImage = hasValue && value[0].type.includes('image/');
    const isAssetImage = isImage && isAsset;
    const hasError = showError && error && touched;
    const classNames = [styles.fileInputContainer];
    if (!error && touched && fileUrl) classNames.push(styles.isValid);
    if (isDark || placeholderImage) classNames.push(styles.isDark);
    if (colors) classNames.push(styles.hasColors);
    if (hasError) classNames.push(styles.isInvalid);
    if (isAssetImage) classNames.push(styles.isAssetImage);
    if (className) classNames.push(className);
    // TODO: Add styles for focusing this input
    return (
      <div className={classNames.join(' ')}>
        <div className={styles.fileContainer} style={{ width: size, height: size }}>
          {colors && <div className={styles.fileBackground} style={{ background: linearGradient(colors) }} />}
          {placeholderImage && (
            <>
              <div className={styles.filePlaceholderImage} style={{ backgroundImage: `url(${placeholderImage})` }} />
              <div className={styles.filePlaceholderImageOverlay} />
            </>
          )}
          <label className={styles.fileLabel}>
            <input
              ref={this.inputRef}
              name={name}
              onChange={this.onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              type="file"
              accept={accept}
              className={styles.fileInput}
            />
            <span className={styles.filePlaceholderContainer}>
              <span className={styles.filePlaceholder}>{placeholder}</span>
              <DoubleArrowIcon className={styles.fileUploadIcon} />
            </span>
            {fileUrl && (
              <span className={styles.filePreview}>
                {!isImage && <RoleIcon />}
                {isImage && !isAsset && <img src={fileUrl} alt="File Preview" />}
                {isImage && isAsset && <AssetImage name="File Preview" url={fileUrl} />}
              </span>
            )}
          </label>
        </div>
        {isAssetImage && (
          <AssetImage
            onClick={() => this.inputRef.current.click()}
            name="File Preview"
            url={fileUrl}
            className={styles.fileAssetImage}
          />
        )}

        {hasValue && !isImage && <div className={styles.fileName}>{value[0].name}</div>}
        {hasError && <div className={`${styles.inputError} ${styles.isFileError}`}>{error}</div>}
      </div>
    );
  }
}

FileInput.defaultProps = {
  size: 90,
  isAsset: false,
  isDark: false,
};

export default FileInput;
