// @flow
import styles from './Note.module.css';

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Markdown from 'components/Sheet2/atoms/Markdown';

type Props = {
  onBlur?: ?() => void,
  onChange: (string) => void,
  onFocus?: ?() => void,
  readOnly: boolean,
  value: ?string,
};

function Note(props: Props): Node {
  const { onBlur: propOnBlur, onChange, onFocus, readOnly, value } = props;

  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // TODO: Figure out a way to be smart about placing the cursor near where you clicked in the box.
    if (inputRef.current && isEditing) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }
  }, [isEditing]);

  const onBlur = (event) => {
    setIsEditing(false);
    onChange(event.currentTarget.value.trim());
    if (propOnBlur) propOnBlur();
  };

  const onClick = () => {
    if (!isEditing) setIsEditing(true);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) inputRef.current.blur();
  };

  return (
    <div className={styles.container} onClick={onClick}>
      {isEditing ? (
        <TextareaAutosize
          ref={inputRef}
          className={styles.input}
          minRows={2}
          value={value}
          placeholder="Enter your notes"
          readOnly={readOnly}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Markdown
          className={`${styles.markdown} ${!value ? styles.isEmpty : ''}`}
          children={value || 'Enter your notes'}
        />
      )}
    </div>
  );
}

Note.defaultProps = {
  readOnly: false,
};

export default Note;
