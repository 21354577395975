import styles from './ThemeEditor.module.css';

import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';

import { DEFAULT_HEX_COLORS, DEFAULT_ROOM_THEME } from 'models/Color';
import { hexToRGB, linearGradient, randomHexColor } from 'utilities/color';

import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

enum ThemeType {
  BASE = 'base',
  BUTTON = 'button',
  TEXT = 'text',
  ACCENT = 'accent',
}

interface themeColors {
  base: string;
  button: string;
  text: string;
  accent: string;
}

interface Props {
  backgroundArtUrl?: string;
  removeBackgroundArt: () => void;
  setBackgroundArtFile: (newUrl: File) => void;
  setThemeColors: (newThemeColors: themeColors) => void;
  setUserColors: (newUserColors: string[]) => void;
  themeColors?: themeColors;
  userColors?: string[];
}

const USER_COLOR_MIN = 2;
const USER_COLOR_LIMIT = 6;

export default function ThemeEditor({
  backgroundArtUrl,
  removeBackgroundArt,
  setBackgroundArtFile,
  setThemeColors,
  setUserColors,
  themeColors,
  userColors,
}: Props) {
  const [isUploading, setIsUploading] = useState(false);

  const onChangeUserColors = (index: number, color?: string) => {
    const newUserColors = [...userColors];
    color ? newUserColors.splice(index, 1, color) : newUserColors.splice(index, 1);
    setUserColors(newUserColors);
  };

  const style = themeColors && {
    '--color-theme-base': themeColors.base && hexToRGB(themeColors.base),
    '--color-theme-button': themeColors.button && hexToRGB(themeColors.button),
    '--color-theme-text': themeColors.text && hexToRGB(themeColors.text),
    '--color-theme-accent': themeColors.accent && hexToRGB(themeColors.accent),
  };

  const clearIconButton = useCallback((onClick) => {
    return (
      <button className={`button-reset ${styles.clearButton}`} onClick={onClick} alt="Clear Color">
        <XIcon />
      </button>
    );
  }, []);

  const renderThemePreview = useCallback(() => {
    const onChangeBackgroundArt = (file?: File) => {
      setIsUploading(true);
      if (file) {
        setBackgroundArtFile(file);
      } else {
        removeBackgroundArt();
      }
      setIsUploading(false);
    };

    return (
      <div className={styles.themePreview}>
        <Avatar
          shape="rectangle"
          avatarUrl={backgroundArtUrl}
          avatarAlt={`Room Background Art`}
          size={200}
          isLoading={isUploading}
          canEdit
          canDelete
          placeholderLabel={
            <>
              Upload
              <br />
              Background Art
            </>
          }
          onChange={(file?: File) => onChangeBackgroundArt(file)}
        />
        <div className={clsx(styles.panel, styles.leftPanel)}>
          <div className={styles.menuContainer}>
            <div className={styles.text} />
            <div className={clsx(styles.menus, styles.iconContainer)}>
              <div className={styles.icon} />
              <div className={styles.icon} />
              <div className={styles.icon} />
            </div>
            <div className={clsx(styles.backButton, styles.iconContainer)}>
              <div className={styles.icon} />
            </div>
          </div>
          <div className={styles.sheetMeta}>
            <div className={styles.iconContainer}>
              <div className={styles.icon} />
            </div>
            <div className={styles.iconContainer}>
              <div className={styles.icon} />
            </div>
          </div>
          <div className={styles.avatar} />
          <div className={styles.sectionHeader} />
          <div className={styles.textArea}>
            <div className={styles.eyecon} />
            <div className={styles.sheetText} />
          </div>
          <div className={styles.stepper}>
            <div className={styles.stepperMinus} />
            <div className={styles.steps} />
            <div className={styles.stepperPlus} />
          </div>
        </div>
        <div className={clsx(styles.panel, styles.rightPanel)}>
          <div className={styles.panelButtons}>
            <div className={styles.userAvatar} />
            <div className={clsx(styles.panelButton, styles.iconContainer)}>
              <div className={styles.icon} />
            </div>
            <div className={clsx(styles.panelButton, styles.iconContainer)}>
              <div className={styles.icon} />
            </div>
            <div className={clsx(styles.panelButton, styles.iconContainer)}>
              <div className={styles.icon} />
            </div>
          </div>
          <div className={styles.menuContainer}>
            <div className={styles.text} />
            <div className={styles.filters} />
          </div>
          <div className={styles.card}>
            <div className={styles.asset} />
            <div className={styles.text} />
            <div className={styles.text} />
          </div>
          <div className={styles.card}>
            <div className={styles.asset} />
            <div className={styles.text} />
            <div className={styles.text} />
          </div>
          <div className={styles.card}>
            <div className={styles.asset} />
            <div className={styles.text} />
            <div className={styles.text} />
          </div>
        </div>
      </div>
    );
  }, [backgroundArtUrl, isUploading, removeBackgroundArt, setBackgroundArtFile]);

  const onChangeTheme = (value: string, type: string) => {
    const newThemeValues = { ...themeColors };
    newThemeValues[type] = value;
    setThemeColors(newThemeValues);
  };

  const filteredUserColors = useMemo(() => {
    return [...new Set(userColors)];
  }, [userColors]);

  return (
    <div className={styles.container} style={style}>
      <div className={styles.theme}>
        <label className={styles.label}>Theme Preview:</label>
        <p className={styles.copy}>
          Upload an image for your room's background; your choice will be displayed in the preview. Ideal image
          resolution: Landscape 1920 x 1080.
        </p>
        {renderThemePreview()}
        <div className={styles.themeColorsHeader}>
          <label className={styles.label}> Theme Colors:</label>
          <Button
            primaryBackground={'var(--color-primary-main)'}
            className={styles.resetButton}
            icon={<XIcon />}
            iconSize="8"
            onClick={() => setThemeColors({ ...DEFAULT_ROOM_THEME })}
            isSmall
          >
            Reset Colors
          </Button>
        </div>
        <p className={styles.copy}>
          Use the four selectors below to pick colors for the different aspects of your room theme; your choices are
          displayed in the preview. Colors can also be chosen using RGB, HSL, or Hex values.
        </p>
        <div className={styles.swatches}>
          {themeColors.base && (
            <div className={styles.themeSwatch} title={themeColors.base}>
              {themeColors.base}
              {clearIconButton(() => onChangeTheme(DEFAULT_ROOM_THEME[ThemeType.BASE], ThemeType.BASE))}
              <input
                type="color"
                value={themeColors.base}
                onChange={(e) => onChangeTheme(e.currentTarget.value, ThemeType.BASE)}
              />
              <div>Base</div>
            </div>
          )}
          {themeColors.button && (
            <div className={styles.themeSwatch} title={themeColors.button}>
              {themeColors.button}
              {clearIconButton(() => onChangeTheme(DEFAULT_ROOM_THEME[ThemeType.BUTTON], ThemeType.BUTTON))}
              <input
                type="color"
                value={themeColors.button}
                onChange={(e) => onChangeTheme(e.currentTarget.value, ThemeType.BUTTON)}
              />
              <div>Button</div>
            </div>
          )}
          {themeColors.text && (
            <div className={styles.themeSwatch} title={themeColors.text}>
              {themeColors.text}
              {clearIconButton(() => onChangeTheme(DEFAULT_ROOM_THEME[ThemeType.TEXT], ThemeType.TEXT))}
              <input
                type="color"
                value={themeColors.text}
                onChange={(e) => onChangeTheme(e.currentTarget.value, ThemeType.TEXT)}
              />
              <div>Text</div>
            </div>
          )}
          {themeColors.accent && (
            <div className={styles.themeSwatch} title={themeColors.accent}>
              {themeColors.accent}
              {clearIconButton(() => onChangeTheme(DEFAULT_ROOM_THEME[ThemeType.ACCENT], ThemeType.ACCENT))}
              <input
                type="color"
                value={themeColors.accent}
                onChange={(e) => onChangeTheme(e.currentTarget.value, ThemeType.ACCENT)}
              />
              <div>Accent</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.userColors}>
        <div className={styles.userColorsHeader}>
          <label className={styles.label}>User Colors:</label>
          <Button
            primaryBackground={'var(--color-primary-main)'}
            className={styles.resetButton}
            icon={<XIcon />}
            iconSize="8"
            onClick={() => setUserColors([...DEFAULT_HEX_COLORS])}
            isSmall
          >
            Reset Colors
          </Button>
        </div>
        <p className={styles.copy}>
          Use the selector below to create the color gradient assigned to each player in the room; you may choose from 2
          to 6 colors. These colors are reflected in their avatar border and all interactive elements of their sheets.
        </p>
        <div className={styles.userColorsContainer}>
          <div className={styles.swatches}>
            {userColors.map((color, i) => (
              <div key={i} className={styles.themeSwatch} title={color}>
                <div>{color}</div>
                {userColors.length > 2 && clearIconButton(() => onChangeUserColors(i))}
                <input type="color" value={color} onChange={(e) => onChangeUserColors(i, e.currentTarget.value)} />
                {i + 1}
              </div>
            ))}
            {userColors.length < USER_COLOR_LIMIT && (
              <IconButton
                className={styles.addColorButton}
                label="Add New Color"
                borderRadius={4}
                background="none"
                buttonSize={50}
                activeBackground="none"
                activeColor="var(--color-dark-accent)"
                color="var(--color-light-main)"
                children={<PlusIcon />}
                onClick={() =>
                  userColors.length < USER_COLOR_MIN
                    ? setUserColors([...DEFAULT_HEX_COLORS])
                    : onChangeUserColors(userColors.length, randomHexColor())
                }
              />
            )}
          </div>
          <div className={styles.colorSpread}>
            {filteredUserColors.map((color, i) => {
              const others = filteredUserColors.filter((c) => c !== color);
              return (
                <div key={i} className={styles.colorRow}>
                  {others.map((other, j) => (
                    <div
                      key={`${i}-${j}`}
                      className={styles.color}
                      style={{ background: linearGradient([color, other], '135deg') }}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
