// @flow
import type { Node } from 'react';
import React, { memo, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import type { ElementModel, ElementProps, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import useModifierIsSelected from 'hooks/useModifierIsSelected';
import useModifierLinks from 'hooks/useModifierLinks';
import useModifierPusher from 'hooks/useModifierPusher';
import { ChannelEvent } from 'models/Channel';
import { RollAction, TableAction } from 'store/actions';
import { RollSelector, RoomSheetSelector, TableSelector } from 'store/selectors';
import { RoomTab } from 'types/room';

import Buff from './Buff';
import Element from 'components/Sheet2/elements/Element';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function BuffElement(props: Props): Node {
  const { colors, element, onChange, readOnly, roomGuid, sectionId, sheetGuid } = props;
  const { id, items, links } = element;

  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));

  const buffInput = items.find((o) => o.inputType === INPUT_TYPE.buff);
  const buffValue = buffInput?.value ?? buffInput?.defaultValue ?? '';

  const dispatch = useDispatch();
  const modifier = useSelector((state) => buffInput && RollSelector.getModifierBySheetAndInput(state, sheetGuid, id));
  const isSelected = useModifierIsSelected(modifier);
  useModifierPusher(modifier);
  useModifierLinks(sheetGuid, modifier, links);

  useEffect(() => {
    const value = parseInt(buffValue);
    if (!isNaN(value) && modifier && modifier.value !== value)
      dispatch(RollAction.updateModifier({ ...modifier, value }));
  }, [buffValue, dispatch, modifier]);

  const onBuffChange = (value) => {
    if (!buffInput) return;
    const updatedItems = items.map((o) => (o.id === buffInput.id ? { ...buffInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onBuffBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onBuffFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onBuffClick = (isSelected) => {
    if (!modifier) return;
    batch(() => {
      dispatch(RollAction.updateModifier({ ...modifier, isSelected }));
      dispatch(TableAction.setPlayPanelContent('Dice'));
    });
  };

  return (
    <Element {...props}>
      {buffInput && (
        <Buff
          value={buffValue}
          canEdit={buffInput.canEdit}
          readOnly={readOnly}
          showSpacer
          isSelected={isSelected}
          primaryColor={colors[0]}
          secondaryColor={colors[1]}
          onBlur={onBuffBlur}
          onFocus={onBuffFocus}
          onClick={onBuffClick}
          onChange={onBuffChange}
        />
      )}
    </Element>
  );
}

export default memo(BuffElement);
