const usdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const formatCents = (cents) => usdFormatter.format(cents / 100);

export const minutesToHours = (minutes) => minutes / 60;

export const secondsToTimestamp = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  return [hours > 0 ? `0${hours}`.slice(-2) : undefined, `0${minutes}`.slice(-2), `0${seconds}`.slice(-2)]
    .filter(Boolean)
    .join(':');
};
