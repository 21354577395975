// @flow
import styles from './Sheet.module.css';

import clsx from 'clsx';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { SheetProps } from './types';
import { ELEMENT_TYPE, INPUT_TYPE } from 'constants/sheet';
import { moveItem } from 'utilities';
import { stylizeTheme } from 'utilities/color';

import CollapsedTableOfContents from './CollapsedTableOfContents';
import ElementComponents from './elements/ElementComponents';
import Section from './Section';
import TableOfContents, { NavItem } from './TableOfContents';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import LoadingSpinner from 'components/LoadingSpinner';
import { ReactComponent as MenuIcon } from 'images/icons/MenuIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

function Main(props: SheetProps): Node {
  const {
    className,
    colors,
    header,
    idKey,
    isCollapsed,
    isDraft,
    model,
    onChange,
    onChangeElement,
    onChangeGroupElement,
    onChangeSection,
    onChangeTOCOpen: propOnChangeTOCOpen,
    onChangeCollapse,
    readOnly,
    roomGuid,
    theme,
    tocMeta,
  } = props;
  const data = isDraft ? model.draftData : model.data;
  const { sections = [] } = data;
  const modelId = model[idKey];
  const isDdbSheet = !!model.ddbId;
  const ddbUrl = `https://www.dndbeyond.com/characters/${model.ddbId ?? ''}`;

  const bodyRef = useRef(null);
  const sectionRefs = useRef({});

  const [isTOCOpen, setIsTOCOpen] = useState(props.isTOCOpen);
  const [activeSectionId, setActiveSectionId] = useState();
  const [elementSpaceList, setElementSpaceList] = useState([]);
  const [dragMeta, setDragMeta] = useState({});
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isCollapsed) classNames.push(styles.isCollapsed);
  if (isTOCOpen) classNames.push(styles.isShowingToc);

  // Manually setting the dependencies because linter cannot auto detect them.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    _.throttle(() => {
      if (sections.length === 0 || !bodyRef.current) return;

      const scrollTop = bodyRef.current.scrollTop || 0;
      let sectionId = sections[0].id;

      sections.forEach(({ id }) => {
        const top = sectionRefs.current[id]?.offsetTop || 0;
        if (scrollTop >= top) sectionId = id;
      });

      if (scrollTop >= bodyRef.current.scrollHeight - bodyRef.current.offsetHeight) {
        sectionId = sections[sections.length - 1].id;
      }

      setActiveSectionId(sectionId);
    }, 50),
    [sections]
  );

  const hasToggleOrLabel = (element) =>
    element.items.some((i) => i.inputType === INPUT_TYPE.toggle || i.inputType === INPUT_TYPE.heading);

  useEffect(() => setIframeLoaded(false), [modelId]);

  useEffect(() => {
    if (isDdbSheet) return;
    const newSectionsSpaceArray = [];
    let prevCol = 0;
    sections.forEach(({ elements }) => {
      elements.forEach((element, index) => {
        if (element.columns === 1 && prevCol === 1) {
          if (hasToggleOrLabel(element)) {
            newSectionsSpaceArray.push(element.id);
            if (elements[index - 1] && !newSectionsSpaceArray.includes(elements[index - 1].id))
              newSectionsSpaceArray.push(elements[index - 1].id);
          } else if (elements[index - 1] && newSectionsSpaceArray.includes(elements[index - 1].id)) {
            newSectionsSpaceArray.push(element.id);
          }
        } else {
          hasToggleOrLabel(element) && newSectionsSpaceArray.push(element.id);
        }
        prevCol = prevCol === 1 ? 0 : element.columns;
      });
    });
    setElementSpaceList(newSectionsSpaceArray);
  }, [isDdbSheet, sections]);

  useEffect(() => setIsTOCOpen(props.isTOCOpen), [props.isTOCOpen]);
  useEffect(() => {
    if (isDdbSheet) return;
    onScroll();
    bodyRef.current.addEventListener('scroll', onScroll);
    return () => bodyRef.current?.removeEventListener('scroll', onScroll);
  }, [isDdbSheet, onScroll]);

  const onChangeTOCOpen = () => {
    const isOpen = !isTOCOpen;
    setIsTOCOpen(isOpen);
    if (propOnChangeTOCOpen) propOnChangeTOCOpen(isOpen);
  };

  const onSectionCollapsedChange = (id: string, isCollapsed: boolean) => {
    const section = data.sections.find((o) => o.id === id);
    if (!section) return;
    onChangeSection(modelId, { ...section, isCollapsed });
  };

  const onSectionDragComplete = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      setDragMeta({});
      const newData = {
        ...data,
        sections: moveItem(data.sections, sourceIndex, destinationIndex),
      };
      const updatedModel = {
        ...model,
      };
      if (isDraft) {
        updatedModel.draftData = newData;
      } else {
        updatedModel.data = newData;
      }
      onChange(updatedModel);
    },
    [data, model, onChange, isDraft]
  );

  const onTOCNavItemClick = (id: string) => {
    const top = sectionRefs.current[id]?.offsetTop || 0;
    bodyRef.current?.scroll({ top: top, behavior: 'smooth' });
  };

  const onCollapsedTOCNavItemClick = (id: string) => {
    if (onChangeCollapse) onChangeCollapse(false);
    onTOCNavItemClick(id);
  };

  return isDdbSheet ? (
    <div className={classNames.join(' ')} style={{ ...(theme && stylizeTheme(theme, 'sheet')) }}>
      <div className={styles.sheet}>
        <div className={styles.content}>
          {header}
          <article className={clsx(styles.body, styles.ddbSheetArticle)}>
            <div className={styles.ddbContentContainer}>
              <div className={styles.ddbIframeContainer}>
                {iframeLoaded ? (
                  <div className={styles.hiddenBlocker}>
                    <div className={styles.blocker} />
                  </div>
                ) : (
                  <LoadingSpinner size={42} />
                )}
                <iframe
                  id="ddbIframe"
                  onLoad={() => setIframeLoaded(true)}
                  src={ddbUrl}
                  title="DDB Sheet"
                  width="100%"
                  height="100%"
                  className={clsx(!iframeLoaded && styles.hiddenIframe)}
                ></iframe>
              </div>
            </div>
            <div className={styles.ddbButtonContainer}>
              <Button
                to={ddbUrl}
                variant="primary"
                isExternalLink
                isLinkAsButton
                className={styles.ddbButton}
                primaryBackground={colors[0]}
                secondaryBackground={colors[1]}
              >
                OPEN BEYOND
              </Button>
            </div>
          </article>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames.join(' ')} style={{ ...(theme && stylizeTheme(theme, 'sheet')) }}>
      <IconButton
        className={[styles.tocButton, isTOCOpen ? styles.isActive : ''].join(' ')}
        color="rgb(var(--color-sheet-text))"
        background="rgb(var(--color-sheet-button))"
        activeBackground="rgb(var(--color-sheet-button))"
        hoverBackground="rgb(var(--color-sheet-accent))"
        label="Open Table of Contents"
        activeLabel="Close Table of Contents"
        isActive={isTOCOpen}
        onClick={onChangeTOCOpen}
        children={<MenuIcon />}
        activeChildren={<XIcon />}
      />
      <TableOfContents className={styles.toc} meta={tocMeta}>
        {sections.map(({ id, title }, index) => {
          return (
            <NavItem
              key={id}
              id={id}
              index={index}
              title={title}
              isActive={id === activeSectionId}
              readOnly={readOnly}
              onClick={onTOCNavItemClick}
              onDrag={setDragMeta}
              dragMeta={dragMeta}
              onDragComplete={onSectionDragComplete}
            />
          );
        })}
      </TableOfContents>
      <div className={styles.sheet}>
        <div className={styles.content}>
          {header}
          <CollapsedTableOfContents
            sections={sections.map(({ id, title }, i) => ({ id, title: title || `Section ${i + 1}` }))}
            colors={colors}
            isActive={isCollapsed}
            onClick={onCollapsedTOCNavItemClick}
          />
          <article className={styles.body}>
            <div ref={(el) => (bodyRef.current = el)} className={styles.sections}>
              {sections.map(({ id, elements, isCollapsed, title }, index) => (
                <Section
                  key={id}
                  refs={sectionRefs}
                  id={id}
                  index={index}
                  title={title}
                  isCollapsed={isCollapsed}
                  isDraft={isDraft}
                  readOnly={readOnly}
                  onCollapseChange={onSectionCollapsedChange}
                  onMoveSection={!isDraft ? onSectionDragComplete : undefined}
                  isLastSection={index === sections.length - 1}
                >
                  {elements.map((element) => {
                    const ElementComponent = ElementComponents[element.elementType];
                    const allowImages =
                      element.elementType === ELEMENT_TYPE.image || element.elementType === ELEMENT_TYPE.group;
                    return (
                      ElementComponent && (
                        <ElementComponent
                          key={element.id}
                          roomGuid={roomGuid}
                          sheetGuid={modelId}
                          sectionId={id}
                          element={element}
                          images={allowImages ? model.images : null}
                          colors={colors}
                          onChange={onChangeElement}
                          onChangeGroup={onChangeGroupElement}
                          readOnly={readOnly}
                          isDraft={isDraft}
                          metaSpace={elementSpaceList.includes(element.id)}
                        />
                      )
                    );
                  })}
                </Section>
              ))}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

Main.defaultProps = {
  isCollapsed: false,
  isDraft: false,
  isTOCOpen: false,
  readOnly: false,
};

export default Main;
