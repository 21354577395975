import React, { Component } from 'react'
import { isMobile } from 'utilities';
import MobileDisabledPage from 'pages/MobileDisabledPage';

export default ChildComponent => {
  class ComposedComponent extends Component {
    render() {
      if (isMobile) return <MobileDisabledPage {...this.props} />
      return <ChildComponent {...this.props} />
    }
  }

  return ComposedComponent
}
