import styles from './TableMediaView.module.css';

import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MediaAction } from 'store/actions';
import { TableSelector } from 'store/selectors';
import type { RoomModel } from 'types/common';
import { MediaMode } from 'types/room';
import useVideoContext from 'video/hooks/useVideoContext';

import TableViewOverlay from './TableViewOverlay';

interface TableAppViewProps {
  className?: string;
  room: RoomModel;
}

export default function TableAppView({ className, room }: TableAppViewProps) {
  const dispatch = useDispatch();
  const { room: videoRoom } = useVideoContext();
  const isFocusMode = useSelector(TableSelector.isFocusMode);

  useEffect(() => {
    return () => {
      dispatch(MediaAction.setMode(MediaMode.None));
    };
  }, [dispatch]);

  return (
    <TableViewOverlay
      className={clsx(styles.isObr, !videoRoom && styles.isDisconnected, isFocusMode && styles.isFocusMode, className)}
      showCloseButton={false}
      showMinimizeButton={false}
      room={room}
    >
      <div className={styles.content}>
        <iframe
          id="tableAppIframe"
          title="Owlbear Rodeo"
          width="100%"
          height="100%"
          src={room.obrUrl}
          allow="attribution-reporting"
        ></iframe>
      </div>
    </TableViewOverlay>
  );
}
