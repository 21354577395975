import axios from 'axios';
import Cookies from 'js-cookie';

// If we don't have a set hostname from our env, just infer the root hostname from the window location
const ROOT_HOSTNAME = process.env.REACT_APP_USE_HOSTNAME
  ? window.location.hostname
  : process.env.REACT_APP_ROOT_HOSTNAME ||
    window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');

const TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_NAME || 'token';

const COOKIE_OPTIONS = { domain: ROOT_HOSTNAME, secure: true, sameSite: 'strict', expires: 30 };

export const getAuthCookie = () => Cookies.get(TOKEN_NAME);
export const setAuthCookie = (token) => {
  if (!token) Cookies.remove(TOKEN_NAME, COOKIE_OPTIONS);
  else Cookies.set(TOKEN_NAME, token, COOKIE_OPTIONS);
};

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

let token = getAuthCookie();
if (!token) {
  const localToken = localStorage.getItem('token');

  if (localToken) {
    token = localToken;
    setAuthCookie(token);
    localStorage.removeItem('token');
  }
}

if (token) headers['Authorization'] = `Bearer ${token}`;

export default axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers,
});
