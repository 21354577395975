import styles from './TableMediaView.module.css';

import clsx from 'clsx';
import { CSSProperties, MouseEventHandler, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { RoomDocumentAction } from 'store/actions';
import TableAction from 'store/actions/TableAction';
import { DocumentSelector, SessionSelector } from 'store/selectors';
import TableSelector from 'store/selectors/TableSelector';

import IconButton from 'components/buttons/IconButton';
import { ReactComponent as MaximizeIcon } from 'images/icons/MaximizeIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import useSelectedParticipant from 'video/VideoProvider/useSelectedParticipant';

interface TableMediaPreviewProps {
  className?: string;
  style?: CSSProperties;
  room?: any;
  roomDocument: any;
}

export default function TableMediaPreview({ className, style, room, roomDocument }: TableMediaPreviewProps) {
  const dispatch = useDispatch();
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  // @ts-ignore DocumentSelector is not yet typed
  const asset = useSelector((state) => DocumentSelector.get(state, roomDocument.id));
  const [, setSelectedParticipant] = useSelectedParticipant();

  const isShared = sharedRoomDocument?.guidId === roomDocument.guidId;

  const onClickClose = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      batch(() => {
        if (isShared) dispatch(TableAction.setSharedRoomDocument(null));
        else dispatch(TableAction.setPrivateRoomDocument(null));
        if (room && isShared) {
          const room_document = { is_shared: false };
          dispatch(RoomDocumentAction.update(room?.guid, asset.id, { room_document, user_id: roomDocument.ownerId }));
        }
      });
    },
    [asset.id, dispatch, isShared, room, roomDocument]
  );

  const onClickMaximize = useCallback(() => {
    batch(() => {
      setSelectedParticipant(null);
      dispatch(TableAction.setActiveRoomDocument(roomDocument.guidId));
    });
  }, [dispatch, roomDocument.guidId, setSelectedParticipant]);

  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const currentUser = useSelector(SessionSelector.currentUser);

  const showCloseButton =
    !!previewRoom ||
    (roomDocument?.ownerType === 'User' && roomDocument?.ownerId === currentUser.id) ||
    room?.userId === currentUser.id ||
    (!isShared && privateRoomDocument?.guidId === roomDocument?.guidId);

  let label = '';
  if (isShared) {
    label = 'Shared';
    if (roomDocument?.ownerType === 'User') {
      if (roomDocument?.ownerId === currentUser.id) {
        label += ' by You';
      } else {
        label += ` by ${roomDocument?.ownerName}`;
      }
    }
  } else {
    label = 'Private';
  }

  return (
    <div className={clsx(styles.previewContainer, className)} style={style}>
      <div className={styles.thumbnail}>
        <img src={asset.fileUrl} alt={`${asset.name} ${asset.type}`} />
      </div>
      <div className={styles.previewLabel}>{label}</div>
      <div className={styles.buttons} onClick={onClickMaximize}>
        <IconButton
          buttonSize={40}
          iconSize={18}
          borderRadius={40}
          color="var(--color-white)"
          background="rgb(var(--color-theme-button))"
          hoverBackground="rgb(var(--color-theme-accent))"
          children={<MaximizeIcon />}
          label="Maximize"
        />
        {showCloseButton && (
          <IconButton
            className={styles.closeButton}
            buttonSize={32}
            iconSize={12}
            borderRadius={40}
            color="var(--color-white)"
            background="rgb(var(--color-theme-button))"
            hoverBackground="rgb(var(--color-theme-accent))"
            children={<XIcon />}
            onClick={onClickClose}
            label={isShared ? 'Stop Sharing' : 'Close'}
          />
        )}
      </div>
    </div>
  );
}
