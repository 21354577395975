import styles from './Card.module.css';

import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';

import { CloseButton } from 'components/buttons/IconButton';

type Props = {
  children?: ReactNode;
  className?: string;
  forceBorder?: boolean;
  isPressed?: boolean;
  onClick?: (event: MouseEvent) => void;
  onDeleteClick?: (event: MouseEvent) => void;
  showClose?: boolean;
};

function Card(props: Props) {
  const { children, className, forceBorder, onDeleteClick, showClose, onClick: propOnClick, isPressed } = props;

  const clickProps = propOnClick
    ? {
        onClick: propOnClick,
        tabIndex: 0,
        role: 'button',
        'aria-pressed': isPressed,
      }
    : {};

  return (
    <div
      className={clsx(
        styles.container,
        className,
        forceBorder && styles.forceBorder,
        propOnClick && styles.clickableCard
      )}
      {...clickProps}
    >
      {showClose && (
        <CloseButton
          color="var(--color-white)"
          activeColor="var(--color-white)"
          background="var(--color-light-main)"
          activeBackground="var(--color-light-dark)"
          className={styles.deleteButton}
          onClick={onDeleteClick}
          buttonSize={24}
        />
      )}
      {children}
    </div>
  );
}

export default Card;
