// @flow
import styles from './buttons.module.css';

import clsx from 'clsx';
import React from 'react';

import Button from './Button';

type CallbackFunction = (SyntheticEvent<any>) => void;
type Props = {
  className?: string,
  children: any,
  isLoading?: boolean,
  isTargetBlank?: boolean,
  onClick?: CallbackFunction,
  to?: string,
  variant?: ?('flat' | 'red' | 'green'),
};

function IconTitleButton({ className, children, isLoading, isTargetBlank, onClick, to, variant }: Props) {
  let primaryBackground, secondaryBackground;
  switch (variant) {
    case 'red':
      primaryBackground = 'var(--color-red)';
      secondaryBackground = 'var(--color-purple)';
      break;
    case 'green':
      primaryBackground = 'var(--color-teal)';
      secondaryBackground = 'var(--color-green)';
      break;
    default:
      primaryBackground = 'var(--color-primary-main)';
      break;
  }

  return (
    <Button
      primaryBackground={primaryBackground}
      secondaryBackground={secondaryBackground}
      to={to}
      isLinkAsButton
      className={clsx(styles.button, className)}
      target={isTargetBlank ? '_blank' : '_self'}
      onClick={onClick}
      isLoading={isLoading}
    >
      <span className={styles.iconTitleButton}>{children}</span>
    </Button>
  );
}

IconTitleButton.defaultProps = {
  variant: 'flat',
};

export default IconTitleButton;
