// @flow
import styles from './atoms.module.css';

import React from 'react';
import type { Node } from 'react';

import { ReactComponent as CheckIcon } from 'images/icons/CheckIcon.svg';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';

type Props = {
  className?: ?string,
  isEditing: boolean,
  onClick: (SyntheticEvent<T>) => void,
  onMouseEnter?: ?(SyntheticEvent<T>) => void,
  onMouseLeave?: ?(SyntheticEvent<T>) => void,
};

export default function EditIcon(props: Props): Node {
  const { className, isEditing, onClick, onMouseEnter, onMouseLeave } = props;
  return (
    <button
      className={`button-reset ${styles.editButton} ${className || ''}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isEditing ? <CheckIcon /> : <PenIcon />}
    </button>
  );
}
