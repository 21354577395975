import { createAction, handleError } from './utilities';

import role from 'apis/role';

export default class PlaykitAction {
  static FETCH_ALL = 'PlaykitAction.FETCH_ALL';
  static FETCH = 'PlaykitAction.FETCH';

  static fetchAll =
    (callback = null) =>
    async (dispatch) => {
      return role.get('/playkits').then(
        (response) => {
          const objs = response.data.data.map((obj) => {
            const { id, attributes } = obj;
            return { id, ...attributes };
          });
          dispatch(createAction(PlaykitAction.FETCH_ALL, objs));
          if (callback) callback(objs);
        },
        ({ response }) => handleError(dispatch, PlaykitAction.FETCH_ALL, response)
      );
    };

  static fetch =
    (id, callback = null) =>
    async (dispatch) => {
      return role.get(`/playkits/${id}`).then(
        (response) => {
          const { id, attributes } = response.data.data;
          const obj = { id, ...attributes };
          dispatch(createAction(PlaykitAction.FETCH, obj));
          if (callback) callback(obj);
        },
        ({ response }) => handleError(dispatch, PlaykitAction.FETCH, response)
      );
    };
}
