import styles from 'room/TableUser/TableUser.module.css';

import clsx from 'clsx';
import { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useResizeObserver from 'hooks/useResizeObserver';
import useWindowSize from 'hooks/useWindowSize';
import { RoomUserSelector, SessionSelector, TableSelector } from 'store/selectors';
import { TableViewMode } from 'types/room';
import useScreenShareParticipant from 'video/hooks/useScreenShareParticipant';
import useVideoContext from 'video/hooks/useVideoContext';

import TableUser from 'room/TableUser/TableUser';

interface TableUsersProps {
  children?: ReactNode;
  previewChildren?: ReactNode;
  className?: string;
  room: any;
}

export default function TableUsers({ children, className, previewChildren, room }: TableUsersProps) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const size = useWindowSize();
  const viewMode: TableViewMode = useSelector(TableSelector.getViewMode);
  const isObrActive = useSelector(TableSelector.isObrActive);
  const users = useSelector(RoomUserSelector.getAllPresentInRoom(room.guid));
  // @ts-ignore RoomUserSelector is not typed yet
  const activeUsers = useSelector((state) => RoomUserSelector.getAllPresentAndActiveInRoom(state, room.guid));
  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);
  const focusMode = useSelector(TableSelector.isFocusMode);

  const containerRef = useRef<HTMLDivElement>(null);

  const { participants, room: videoRoom } = useVideoContext();
  const screenShareParticipant = useScreenShareParticipant();

  let total = activeUsers.length;
  if (sharedRoomDocument) total++;
  if (privateRoomDocument) total++;
  if (screenShareParticipant) total++;

  const forceSpeakerMode = viewMode === TableViewMode.Speaker || !!activeRoomDocument || isObrActive;

  const getMaxWidth = useCallback(() => {
    // Only bother with these calculations if we're in grid mode
    if (forceSpeakerMode) return;
    // https://stackoverflow.com/a/47337678/2748503
    // TODO: If we want different aspect ratio, like 16/9, need to use this algorithm https://stackoverflow.com/a/58734728/2748503 or https://math.stackexchange.com/a/2570649
    // We have to subtract padding because offsetWidth and clientWidth both include padding...
    const containerWidth = (containerRef.current?.offsetWidth ?? 0) - (size.isMd ? 112 : 16);
    const containerHeight = (containerRef.current?.offsetHeight ?? 0) - 72;

    let tileWidth, tileHeight;
    const projectedWidth = Math.ceil(Math.sqrt((total * containerWidth) / containerHeight));
    if (Math.floor((projectedWidth * containerHeight) / containerWidth) * projectedWidth < total)
      tileWidth = containerHeight / Math.ceil((projectedWidth * containerHeight) / containerWidth);
    else tileWidth = containerWidth / projectedWidth;

    const projectedHeight = Math.ceil(Math.sqrt((total * containerHeight) / containerWidth));
    if (Math.floor((projectedHeight * containerWidth) / containerHeight) * projectedHeight < total)
      tileHeight = containerWidth / Math.ceil((containerWidth * projectedHeight) / containerHeight);
    else tileHeight = containerHeight / projectedHeight;
    setTileWidth(Math.floor(Math.max(tileWidth, tileHeight)));
  }, [forceSpeakerMode, size.isMd, total]);

  const [tileWidth, setTileWidth] = useState(480);

  // Recalculate when total changes
  useLayoutEffect(() => {
    getMaxWidth();
  });

  useResizeObserver(containerRef, getMaxWidth);

  return (
    <div
      className={clsx(
        className,
        styles.container,
        forceSpeakerMode ? styles.speaker : styles[viewMode],
        tileWidth - 16 >= 200 && 'is-small-tiles',
        tileWidth - 16 >= 480 && 'is-medium-tiles',
        tileWidth - 16 >= 600 && 'is-large-tiles'
      )}
      ref={containerRef}
      // @ts-ignore CSSProperties doesn't allow for custom props?
      style={{ '--tile-width': `${tileWidth}px` }}
    >
      {children}
      <div className={clsx(styles.scrollContainer, (focusMode || !size.isMd) && styles.focusMode)}>
        {previewChildren}
        {users.map((user) => (
          <TableUser
            key={user.id}
            room={room}
            user={user}
            participant={
              user.userId === currentUser.id
                ? videoRoom?.localParticipant
                : participants.find((p) => p.identity === user.userId)
            }
            isSelf={user.userId === currentUser.id}
            tileWidth={tileWidth}
            totalTiles={total}
          />
        ))}
      </div>
    </div>
  );
}
