import React, { Component } from 'react';
import { connect } from 'react-redux';

import SessionSelector from 'store/selectors/SessionSelector';

export default function HOC(ChildComponent) {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      const { location, isAuthed, history } = this.props;
      const to = encodeURIComponent(`${location.pathname}${location.search}`);
      if (!isAuthed) history.replace(`/login?to=${to}`);
    }

    render() {
      if (!this.props.isAuthed) return null;
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { isAuthed: SessionSelector.isAuthed(state) };
  }

  return connect(mapStateToProps)(ComposedComponent);
}
