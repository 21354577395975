import styles from './dashboard.module.css';

import clsx from 'clsx';
import Cookies from 'js-cookie';
import { mapKeys, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';

import { SHOPIFY_COOKIE_NAME_KEY, SHOPIFY_STORE_SHOP_KEY } from 'constants/shopify';
import { OrganizationAction, ProductAction } from 'store/actions';
import { OrganizationSelector, ProductSelector, SessionSelector } from 'store/selectors';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import { DropdownInput, TextInput } from 'components/inputs';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import TitlePanel from 'components/TitlePanel';

const SPINNER_COLOR: string = 'var(--color-dark-text)';
const SHOPIFY_ORG_IDS = process.env.REACT_APP_SHOPIFY_ORG_IDS || '';

function PartnerPage() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [isLoadingShopify, setIsLoadingShopify] = useState(false);
  const [setupShopfy, setSetupShopfiy] = useState(false);

  const currentUser = useSelector(SessionSelector.currentUser);
  // @ts-ignore
  const org = useSelector((state) => OrganizationSelector.get(state, slug));

  const isOrgUser = currentUser.isAdmin || currentUser.organizationIds.includes(org?.id);
  const shopifyOrgIds = SHOPIFY_ORG_IDS.split(',');
  const shopifyEnabled = currentUser.isAdmin || (org?.id && shopifyOrgIds.includes(org.id));
  const shopifyShop = org && org.shopifyShop;
  const shopifyAuthed = shopifyShop && org.isShopifyAuthed;
  const [shopUrl, setShopUrl] = useState(shopifyShop ?? '');

  useEffect(() => {
    setIsLoading(true);
    // @ts-ignore
    dispatch(OrganizationAction.fetch(slug, () => setIsLoading(false)));
  }, [dispatch, slug]);

  useEffect(() => {
    if (org && !isOrgUser) history.replace('/games');
  }, [history, isOrgUser, org]);

  const onStripeSetupClick = useCallback(() => {
    setIsLoadingStripe(true);
    OrganizationAction.stripeOnboard(org.slug)
      .then((data) => (window.location = data.url))
      .finally(() => setIsLoadingStripe(false));
  }, [org?.slug]);

  const onStripeDashboardClick = useCallback(() => {
    setIsLoadingStripe(true);
    OrganizationAction.stripeLogin(org.slug)
      .then((data) => (window.location = data.url))
      .finally(() => setIsLoadingStripe(false));
  }, [org?.slug]);

  const onShopifyLoginClick = () => {
    let shop = shopUrl.replace('https://', '');
    if (shop.endsWith('/')) shop = shop.slice(0, -1);
    setIsLoadingShopify(true);
    OrganizationAction.shopifyLogin(org.slug, shop).then(({ auth_route, cookie }) => {
      const date = new Date(cookie.expires);
      Cookies.set(SHOPIFY_COOKIE_NAME_KEY, cookie.name, { expires: date, secure: true });
      Cookies.set(cookie.name, cookie.value, { expires: date, secure: true });
      Cookies.remove(SHOPIFY_STORE_SHOP_KEY);
      window.location.href = auth_route;
    });
  };

  return (
    <DashboardLayout>
      {org && (
        <Metatags
          title={`Partner Dashboard - ${org.title}`}
          description="Manage your products, settings, and everything related to distributing on Role."
        />
      )}

      <div className={styles.content}>
        <TitlePanel title="Partner Dashboard" subtitle={org?.title}>
          {isLoading && <LoadingSpinner color={SPINNER_COLOR} />}
          {!isLoading && org && isOrgUser && (
            <div className={styles.partnerCards}>
              <div className={styles.partnerCard}>
                <h3>Stripe</h3>
                <p>
                  We use Stripe to manage your catalog of products and handle transactions with customers on our
                  platform. Through Stripe you will be able to monitor the revenue from your sales and see your payout
                  schedule.{' '}
                  <a href="https://support.stripe.com/express" target="_blank" rel="noreferrer noopener">
                    Learn more about Stripe
                  </a>
                  .
                </p>
                {(!org.isStripeCreated || !org.isStripeSetup) && (
                  <Button
                    className={styles.partnerButton}
                    variant="primary"
                    onClick={onStripeSetupClick}
                    isLoading={isLoadingStripe}
                  >
                    {org.isStripeCreated ? 'Finish Stripe Setup' : 'Start Stripe Setup'}
                  </Button>
                )}
                {org.isStripeSetup && (
                  <Button
                    className={styles.partnerButton}
                    variant="primary"
                    onClick={onStripeDashboardClick}
                    isLoading={isLoadingStripe}
                  >
                    Stripe Dashboard
                  </Button>
                )}
              </div>

              <div className={clsx(styles.partnerCard, styles.isShopify)}>
                <h3>Shopify</h3>
                <p>
                  Setup your Shopify store to automatically redeem products you sell on Role or offer them for discount.
                  {/* shopify-TODO: hook up this faq to a real page <a href="https://www.playrole.com/faq" target="_blank" rel="noreferrer noopener">
                    Learn more about how to use Shopify with Role
                  </a>
                  . */}
                </p>
                {shopifyEnabled ? (
                  shopifyAuthed ? (
                    <ShopifyPanel org={org} />
                  ) : (
                    <>
                      {setupShopfy ? (
                        <div className={styles.shopInput}>
                          <TextInput
                            input={{ value: shopUrl }}
                            placeholder="Enter your Shop URL:"
                            type="text"
                            variant="simple"
                            onChange={(e) => setShopUrl(e.currentTarget.value)}
                          />
                          <Button
                            className={styles.partnerButton}
                            variant="primary"
                            isLoading={isLoadingShopify}
                            onClick={onShopifyLoginClick}
                          >
                            Authenticate with Shopify
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className={styles.partnerButton}
                          variant="primary"
                          onClick={() => setSetupShopfiy(true)}
                        >
                          Setup Shopify
                        </Button>
                      )}
                    </>
                  )
                ) : (
                  <p style={{ marginTop: 20, fontWeight: 'bold' }}>Coming soon!</p>
                )}
              </div>
            </div>
          )}
        </TitlePanel>
      </div>
    </DashboardLayout>
  );
}

export default compose(requireAuth, requireCurrentUser)(PartnerPage);

function ShopifyPanel({ org }: { org: any }) {
  const dispatch = useDispatch();
  const products = useSelector((state) => ProductSelector.getByPublisher(state, org.id));
  const [shopifyProducts, setShopifyProducts] = useState({});
  const [shopProducts, setShopProducts] = useState<any[]>([]);
  const [selectedShopifyProduct, setSelectedShopifyProduct] = useState('');
  const [selectedRoleProduct, setSelectedRoleProduct] = useState('');
  const [selectedRelationship, setSelectedRelationship] = useState('unlock');
  const [selectedDiscount, setSelectedDiscount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    OrganizationAction.shopifyProducts(org.slug).then((data) => setShopProducts(data.products));
    dispatch(
      ProductAction.fetchAll({ organization_id: org.id }, ({ shopifyProducts: sps }) =>
        setShopifyProducts((prev) => ({ ...prev, ...mapKeys(sps, 'id') }))
      )
    );
  }, [dispatch, org.id, org.slug]);

  const onBlurDiscount = () => {
    const discount = isNaN(parseInt(selectedDiscount)) ? 0 : parseInt(selectedDiscount);
    if (discount < 0) setSelectedDiscount('0');
    else if (discount > 100) setSelectedDiscount('100');
  };

  const onAddClick = () => {
    if (selectedShopifyProduct === '' || selectedRoleProduct === '' || selectedRelationship === '') return;
    setIsLoading(true);
    const [shopify_product_id, shopify_product_variant_id] = selectedShopifyProduct
      .split(',')
      .map((id) => parseInt(id));
    const discount = selectedRelationship === 'discount' && selectedDiscount !== '' ? parseInt(selectedDiscount) : null;
    const data = {
      shopify_product: { shopify_product_id, shopify_product_variant_id, connection: selectedRelationship, discount },
    };
    dispatch(
      ProductAction.createShopifyProduct(
        selectedRoleProduct,
        data,
        ({ shopifyProducts: sps }) => {
          setIsLoading(false);
          setError('');
          setShopifyProducts((prev) => ({ ...prev, ...mapKeys(sps, 'id') }));
          setSelectedShopifyProduct('');
          setSelectedRoleProduct('');
          setSelectedRelationship('');
          setSelectedDiscount('');
        },
        ({ data: errorData }) => {
          setIsLoading(false);
          let message = errorData.message ?? 'Something went wrong, please try again.';
          if (message === 'Product has already been taken')
            message = 'The Shopify product you selected is already connected to the selected Role product.';
          setError(message);
        }
      )
    );
  };

  const onDeleteClick = (guid: string, id: string) => {
    dispatch(
      ProductAction.deleteShopifyProduct(guid, id, () => {
        setShopifyProducts((prev) => omit(prev, id));
      })
    );
  };

  return (
    <div className={styles.shopifyContainer}>
      <header>
        <h4>Your Shop</h4>
        <strong>{org.shopifyShop}</strong>
      </header>

      <div className={styles.shopifyContent}>
        <div className={styles.shopifyTableInfo}>
          <h4>Your Products</h4>
          <p>Connect your Shopify and Role products with the steps below:</p>
          <ol>
            <li>Select a Shopify product from your store.</li>
            <li>Select a Role product you want to attach your Shopify product to.</li>
            <li>
              Select what kind of connection they have:
              <ul>
                {/* <li>
                  <strong>Purchase:</strong> Buying the product from your Shopify store, buys the product on Role. Best
                  used when you are selling the Role version of your product on your own store.
                </li> */}
                <li>
                  <strong>Unlock</strong> Buying the product from your Shopify store, includes a free copy of the
                  product from Role. Best used when buying something from your store should include the Role product as
                  part of its bundle.
                </li>
                {/* <li>
                  <strong>Discount:</strong> Buying the product from your Shopify store, includes a coupon to buy the
                  product on Role.
                </li> */}
              </ul>
            </li>
            <li>Click "Add" to save the connection.</li>
          </ol>
        </div>

        <table className={styles.partnerTable}>
          <thead>
            <tr>
              <th>Shopify Product</th>
              <th>Role Product</th>
              <th>Connection</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(shopifyProducts).map((shopifyProduct) => {
              const product = products.find((p) => p.id === shopifyProduct.productId);
              let variant;
              const shopProduct = shopProducts.find((p) => {
                variant = p.variants.find(
                  (v) =>
                    v.productId === shopifyProduct.shopifyProducyId && v.id === shopifyProduct.shopifyProductVariantId
                );
                return variant;
              });
              const variantCount = shopProduct?.variants.length ?? 0;
              if (!product || !shopProduct || !variant) return null;
              return (
                <tr key={shopifyProduct.id}>
                  <td>{variantCount > 1 ? `${shopProduct.title} - ${variant.title}` : shopProduct.title}</td>
                  <td>{product.title}</td>
                  <td style={{ textTransform: 'capitalize' }}>
                    {shopifyProduct.connection}{' '}
                    {shopifyProduct.connection === 'discount' && `${shopifyProduct.discount}%`}
                  </td>
                  <td>
                    <Button
                      primaryBackground="var(--color-purple)"
                      isLoading={isLoading}
                      onClick={() => onDeleteClick(product.guid, shopifyProduct.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <DropdownInput
                  onChange={(val) => setSelectedShopifyProduct(val)}
                  value={selectedShopifyProduct}
                  className={styles.shopifyProductSelect}
                >
                  <option value="">---</option>
                  {shopProducts.map((product) => {
                    const variants = product.variants;
                    const variantCount = variants.length;
                    return variants.map((variant) => (
                      <option key={variant.id} value={`${variant.product_id},${variant.id}`}>
                        {variantCount > 1 ? `${product.title} - ${variant.title}` : product.title}
                      </option>
                    ));
                  })}
                </DropdownInput>
              </td>
              <td>
                <DropdownInput
                  onChange={(val) => setSelectedRoleProduct(val)}
                  value={selectedRoleProduct}
                  className={styles.shopifyProductSelect}
                >
                  <option value="">---</option>
                  {products.map((product) => (
                    <option key={product.guid} value={product.guid}>
                      {product.title}
                    </option>
                  ))}
                </DropdownInput>
              </td>
              <td>
                <div className={styles.shopifyConnection}>
                  <DropdownInput
                    onChange={(val) => setSelectedRelationship(val)}
                    value={selectedRelationship}
                    className={styles.shopifyProductSelect}
                  >
                    <option value="">---</option>
                    {/* <option value="purchase">Purchase</option> */}
                    <option value="unlock">Unlock</option>
                    {/* <option value="discount">Discount</option> */}
                  </DropdownInput>
                  {selectedRelationship === 'discount' && (
                    <div className={styles.shopifyDiscountInput}>
                      <TextInput
                        input={{ value: selectedDiscount }}
                        type="number"
                        min={0}
                        max={100}
                        variant="simple"
                        onChange={(e) => setSelectedDiscount(e.currentTarget.value)}
                        onBlur={onBlurDiscount}
                      />
                      <div className={styles.shopifyPercent}>%</div>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <Button primaryBackground="var(--color-purple)" isLoading={isLoading} onClick={onAddClick}>
                  Add
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {error && <p className={styles.shopifyError}>{error}</p>}
      </div>
    </div>
  );
}
