// @flow
import React, { memo } from 'react';
import type { Node } from 'react';

import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';

import Element from 'components/Sheet2/elements/Element';
import Divider from './Divider';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function DividerElement(props: Props): Node {
  return (
    <Element {...props}>
      <Divider />
    </Element>
  );
}

export default memo(DividerElement);
