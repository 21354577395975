// @flow
import styles from './TitleBar.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React from 'react';

type Props = {
  className?: string,
  leftChildren?: ?Node,
  logoUrl?: ?string,
  rightChildren?: ?Node,
  subtitle?: ?string,
  title: string,
};

function TitleBar({ className, leftChildren, logoUrl, rightChildren, subtitle, title }: Props): Node {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.left}>{leftChildren}</div>
      <div className={styles.center}>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && !logoUrl && <p className={styles.subtitle}>{subtitle}</p>}
        {logoUrl && <img src={logoUrl} alt={subtitle || 'Logo'} className={styles.logo} />}
      </div>
      <div className={styles.right}>{rightChildren}</div>
    </div>
  );
}

export default TitleBar;
