// @flow
import styles from './FieldBuff.module.css';

import type { Node } from 'react';
import React, { memo, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import type { ElementModel, ElementProps, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import useModifierIsSelected from 'hooks/useModifierIsSelected';
import useModifierLinks from 'hooks/useModifierLinks';
import useModifierPusher from 'hooks/useModifierPusher';
import { ChannelEvent } from 'models/Channel';
import { RollAction, TableAction } from 'store/actions';
import { RollSelector, RoomSheetSelector, TableSelector } from 'store/selectors';
import { RoomTab } from 'types/room';

import { Buff } from 'components/Sheet2/elements/BuffElement';
import Element from 'components/Sheet2/elements/Element';
import { Field } from 'components/Sheet2/elements/FieldElement';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function FieldBuffElement(props: Props): Node {
  const { colors, element, onChange, readOnly, roomGuid, sectionId, sheetGuid } = props;
  const { id, items, links } = element;

  const buffInput = items.find((o) => o.inputType === INPUT_TYPE.buff);
  const buffValue = buffInput?.value ?? buffInput?.defaultValue ?? '';
  const fieldInput = items.find((o) => o.inputType === INPUT_TYPE.field);
  const fieldValue = String(fieldInput?.value ?? fieldInput?.defaultValue ?? '');

  const dispatch = useDispatch();
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));
  const modifier = useSelector((state) => buffInput && RollSelector.getModifierBySheetAndInput(state, sheetGuid, id));
  const isSelected = useModifierIsSelected(modifier);
  useModifierPusher(modifier);
  useModifierLinks(sheetGuid, modifier, links);

  useEffect(() => {
    const value = parseInt(buffValue);
    if (!isNaN(value) && modifier && modifier.value !== value)
      dispatch(RollAction.updateModifier({ ...modifier, value }));
  }, [buffValue, dispatch, modifier]);

  const onBuffChange = (value) => {
    if (!buffInput) return;
    const updatedItems = items.map((o) => (o.id === buffInput.id ? { ...buffInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onBuffClick = (isSelected) => {
    if (!modifier) return;
    batch(() => {
      dispatch(RollAction.updateModifier({ ...modifier, isSelected }));
      dispatch(TableAction.setPlayPanelContent('Dice'));
    });
  };

  const onFieldChange = (value) => {
    if (!fieldInput) return;
    const updatedItems = items.map((o) => (o.id === fieldInput.id ? { ...fieldInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  return (
    <Element {...props}>
      <div className={styles.container}>
        {fieldInput && (
          <Field
            className={styles.field}
            value={fieldValue}
            readOnly={readOnly}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onFieldChange}
          />
        )}
        {buffInput && (
          <Buff
            className={styles.buff}
            value={buffValue}
            canEdit={buffInput.canEdit}
            readOnly={readOnly}
            isSelected={isSelected}
            primaryColor={colors[0]}
            secondaryColor={colors[1]}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onBuffClick}
            onChange={onBuffChange}
          />
        )}
      </div>
    </Element>
  );
}

export default memo(FieldBuffElement);
