// @flow
import styles from './MoreMenuButton.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import { stylizeTheme } from 'utilities/color';

import { Popover, Portal } from '@headlessui/react';
import IconButton from 'components/buttons/IconButton';
import type { IconButtonProps } from 'components/buttons/IconButton/IconButton';
import MenuList from 'components/MenuList';
import type { MenuItemProps } from 'components/MenuList/MenuList';
import { ReactComponent as MoreIcon } from 'images/icons/MoreIcon.svg';

type Props = {
  buttonProps?: IconButtonProps,
  className?: ?string,
  isActive: boolean,
  items: MenuItemProps[],
  menuAlignment?: 'top' | 'bottom',
  onClick?: (boolean) => void,
  variant: 'dark' | 'light' | 'theme',
  theme?: any,
};

function MoreMenuButton(props: Props): Node {
  const { buttonProps, className, items, menuAlignment, onClick: propOnClick, variant, theme } = props;

  const [isActive, setIsActive] = useState(props.isActive);
  useEffect(() => setIsActive(props.isActive), [props.isActive]);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: menuAlignment ? menuAlignment : 'top-end',
  });

  let background, color, activeBackground, activeColor;
  if (variant === 'light') {
    background = 'var(--color-dashboard-white)';
    color = 'var(--color-dashboard-main)';
    activeBackground = 'var(--color-dashboard-light)';
    activeColor = 'var(--color-white)';
  }

  const onClick = (event) => {
    setIsActive(!isActive);
    if (propOnClick) propOnClick(event);
  };

  return (
    <Popover className={clsx(styles.container, className, isActive && styles.isActive)}>
      <IconButton
        label="More Options"
        children={<MoreIcon />}
        background={background}
        color={color}
        activeBackground={activeBackground}
        hoverBackground={activeBackground}
        activeColor={activeColor}
        onClick={onClick}
        {...buttonProps}
        ref={setReferenceElement}
      />
      {isActive && (
        <Portal>
          <Popover.Panel
            static
            className={styles.popper}
            ref={setPopperElement}
            style={{ ...(theme && stylizeTheme(theme)), ...popperStyles.popper }}
            {...attributes.popper}
          >
            <MenuList items={items} isActive={isActive} className={clsx(styles.menu)} variant={variant} />
          </Popover.Panel>
        </Portal>
      )}
    </Popover>
  );
}

MoreMenuButton.defaultProps = {
  isActive: false,
  items: [],
  menuAlignment: 'top-end',
  variant: 'dark',
};

export default MoreMenuButton;
