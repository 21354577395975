import _ from 'lodash';
import { createSelector } from 'reselect';

import { FILTER } from 'constants/asset';
import COLOR, { DEFAULT_COLORS, NEW_COLOR } from 'models/Color';
import { DocumentType, filterToType } from 'models/Document';

import GameSelector from './GameSelector';
import SessionSelector from './SessionSelector';

const orderByName = (obj) => obj.name.toLowerCase();

const getThemeBySlug = (docs, slug) =>
  Object.values(docs).find((doc) => doc.guidId.includes(slug) && doc.type.toLowerCase() === DocumentType.THEME);

export default class RoomDocumentSelector {
  static get = createSelector(
    (state, guid, documentId) => state.roomDocuments[`${guid}-${documentId}`],
    (doc) => doc
  );

  static getAllByRoom = createSelector(
    [
      (state) => state.roomDocuments,
      (state) => state.documents,
      (state, guid) => state.rooms[guid],
      (state, guid, category) => category || FILTER.all,
    ],
    (roomDocuments, documents, room, category) =>
      Object.values(roomDocuments).filter((roomDocument) => {
        const type = documents[roomDocument.id]?.type.toLowerCase();
        if (!type || roomDocument?.guid !== room?.guid || type === DocumentType.THEME || type === DocumentType.DICE)
          return false;

        const matchesFilter =
          category === FILTER.all ||
          filterToType(category) === type ||
          (filterToType(category) === DocumentType.IMAGE && type === DocumentType.GIF) ||
          (category === 'assets' && [DocumentType.APP, DocumentType.PDF, DocumentType.LINK].includes(type));

        return matchesFilter;
      })
  );

  static getByRoom = createSelector(
    [
      (state) => state.documents,
      this.getAllByRoom,
      GameSelector.getByRoom,
      SessionSelector.currentUser,
      (_, __, ___, order) => order || 'desc',
    ],
    (documents, filteredRoomDocuments, game, currentUser, order) =>
      _.orderBy(
        filteredRoomDocuments.filter((roomDocument) => {
          const isAdder = roomDocument.ownerType === 'User' && roomDocument.ownerId === currentUser.id;
          return roomDocument.isPublic || isAdder;
        }),
        [(roomDocument) => documents[roomDocument.id]?.gameIds.includes(game?.id), orderByName],
        ['desc', order]
      )
  );

  static getByPreviewSlug = createSelector(
    [
      (state) => state.roomDocuments,
      (state) => state.documents,
      (state, slug) => slug,
      (state, slug, category) => category || FILTER.all,
      (_, __, ___, order) => order || 'desc',
    ],
    (roomDocuments, documents, slug, category, order) => {
      const filteredRoomDocuments = Object.values(roomDocuments).filter((roomDocument) => {
        const type = documents[roomDocument.id]?.type.toLowerCase();
        if (!type || !roomDocument.guidId.includes(slug) || type === DocumentType.THEME || type === DocumentType.DICE)
          return false;

        const matchesCategory =
          category === FILTER.all ||
          filterToType(category) === type ||
          (filterToType(category) === DocumentType.IMAGE && type === DocumentType.GIF) ||
          (category === 'assets' && [DocumentType.APP, DocumentType.PDF, DocumentType.LINK].includes(type));

        return matchesCategory;
      });

      return _.orderBy(filteredRoomDocuments, [orderByName], [order]);
    }
  );

  static getPreviewThemeBySlug = createSelector(
    [(state) => state.roomDocuments, (state, slug) => slug],
    (roomDocuments, slug) => {
      const previewThemeDoc = getThemeBySlug(roomDocuments, slug);

      if (previewThemeDoc) {
        return previewThemeDoc.metadata.theme;
      } else {
        return {
          base: NEW_COLOR.DARK.MID,
          button: NEW_COLOR.PRIMARY.DARK,
          text: NEW_COLOR.DARK.ACCENT,
          accent: COLOR.PURPLE,
        };
      }
    }
  );

  static getPreviewBackgroundBySlug = createSelector(
    [(state) => state.roomDocuments, (state, slug) => slug],
    (roomDocuments, slug) => {
      const previewThemeDoc = getThemeBySlug(roomDocuments, slug);

      return previewThemeDoc?.fileUrl;
    }
  );

  static getPreviewLobbyMusicBySlug = createSelector(
    [(state) => state.roomDocuments, (state, slug) => slug],
    (roomDocuments, slug) => {
      const previewThemeDoc = getThemeBySlug(roomDocuments, slug);

      return previewThemeDoc?.lobbyMusicUrl;
    }
  );

  static getPreviewColorsBySlug = createSelector(
    [(state) => state.roomDocuments, (state, slug) => slug],
    (roomDocuments, slug) => {
      const previewThemeDoc = getThemeBySlug(roomDocuments, slug);

      return previewThemeDoc
        ? [previewThemeDoc.metadata.colors[0], previewThemeDoc.metadata.colors[1]]
        : DEFAULT_COLORS;
    }
  );

  static getByRoomAndUser = createSelector(
    [(state, guid, userId, filter) => this.getAllByRoom(state, guid, filter), (_, __, userId) => userId],
    (roomDocuments, userId) =>
      roomDocuments.filter((roomDocument) => roomDocument.ownerType === 'User' && roomDocument.ownerId === userId)
  );

  static getData = createSelector(
    (state, guid, documentId, ownerId) => state.roomDocuments[`${guid}-${documentId}-${ownerId}`],
    (doc) => {
      if (!doc) return {};
      const { lines: rawLines, tokens } = doc.data;
      const lines = rawLines ? rawLines.map((l) => ({ ...l, points: JSON.parse(l.points) })) : null;
      return {
        lines: lines || [],
        tokens: tokens || [],
      };
    }
  );

  static getCustomDice = createSelector(
    [(state) => state.roomDocuments, (state) => state.documents, (state, guid) => state.rooms[guid]],
    (roomDocuments, documents, room) =>
      Object.values(roomDocuments).filter((roomDocument) => {
        const type = documents[roomDocument.id]?.type.toLowerCase();
        return roomDocument.guid === room?.guid && type === DocumentType.DICE;
      })
  );

  static getActiveCustomDice = createSelector(this.getCustomDice, (roomDocuments) =>
    roomDocuments.filter((roomDocument) => roomDocument.data.enabled ?? true)
  );
}
