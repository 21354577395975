import { DeckCard, Suit } from 'models/Card';

import cards from './standard52';
import JokerRed from 'images/cards/StandardDeck/Joker_0.png';
import JokerBlack from 'images/cards/StandardDeck/Joker_1.png';

const deck = [
  ...cards,
  new DeckCard({ name: 'Joker', suit: Suit.NONE, value: 0, image: JokerRed }),
  new DeckCard({ name: 'Joker', suit: Suit.NONE, value: 14, image: JokerBlack }),
];

export default deck;
