import { AnyAction } from 'redux';

import AudioPlayerAction from 'store/actions/AudioPlayer';

const INITIAL_STATE = {
  guidId: null,
};

export default function audioPlayerReducer(state = INITIAL_STATE, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case AudioPlayerAction.SET_ROOM_DOCUMENT:
      return { ...state, guidId: payload };
    default:
      return state;
  }
}
