// @flow
import styles from './KickstarterSupplement.module.css';

import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ErrorAction, ProductAction } from 'store/actions';
import { ErrorSelector, GameSelector, ProductSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import Button from 'components/buttons/Button';
import { DropdownInput } from 'components/inputs';
import { getBundleItemIcon } from 'components/ProductDetails/BundleItemIcon';
import TitlePanel from 'components/TitlePanel';
import KSGamesHeader from 'images/KSGamesHeader.png';

interface Props {
  options: { guid: string, title: string }[];
  extras: { code: string, inviteUrl: string, email: string, isRedeemed: boolean }[];
  selectProduct: (any) => void;
  selectAll: (boolean) => void;
}

export default function KickstarterSupplement({ options, extras, selectProduct, selectAll }: Props) {
  const dispatch = useDispatch();
  const [supplement, setSupplement] = useState(() => options.find(({ guid }) => guid === 'all')?.guid ?? '');
  const [redeemedGames, setRedeemedGames] = useState(null);
  const [gameArt, setGameArt] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const error = useSelector((state) => ErrorSelector.get(state, ProductAction.REDEEM));
  const hasExtras = extras.length > 0;
  const isAll = supplement === 'all' || hasExtras;
  const selectedProduct = useSelector((state) => ProductSelector.get(state, supplement));
  const selectedGame = useSelector((state) => GameSelector.get(state, selectedProduct?.gameId));

  useEffect(() => {
    if (selectedProduct) setGameArt(selectedProduct.coverArtUrl || selectedGame.coverArtUrl);
  }, [selectedProduct, selectedGame]);

  const onChangeSupplement = useCallback(
    (id) => {
      batch(() => {
        dispatch(ErrorAction.remove(ProductAction.REDEEM));
        if (id) dispatch(ProductAction.fetch(id));
      });
      setSupplement(id);
    },
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      ProductAction.redeem(supplement, ({ products, games }) => {
        if (products?.length > 0) {
          setIsSuccess(true);
          setRedeemedGames(games);
          isAll ? selectAll(true) : selectProduct(selectedProduct);
        }
      })
    );
  }, [dispatch, supplement, selectProduct, selectedProduct, selectAll, isAll]);

  const hasItems = selectedProduct?.itemsList.platform?.length + selectedProduct?.itemsList.download?.length > 0;

  return (
    <div className={styles.wrapper}>
      <TitlePanel title="Kickstarter Backer Reward" subtitle={`Redeem your ${pluralize(isAll ? 2 : 1, 'supplement')}!`}>
        {!isSuccess && (
          <>
            <p className={styles.text}>
              Thank you for believing in Role and backing our Kickstarter campaign. The games are ready for you, and we
              are excited to get you playing them!
            </p>
            {isAll && options.length > 0 && (
              <p className={styles.text}>
                Click "Redeem All" to add them to your library. Once redeemed, you can start a Game Room here on Role as
                well as download a zip of all materials in each supplement.
              </p>
            )}
            {!isAll && (
              <>
                <p className={styles.text}>
                  Select your Kickstarter supplement below and click redeem to add it your library. Once redeemed, you
                  can start a Game Room here on Role as well as download a zip of all materials in the supplement.
                </p>
                <p className={styles.text}>
                  <span className={styles.emphatic}>All selections are final</span> and you will not be able to choose a
                  different supplement once you click redeem. View previews of each supplement below before you make
                  your final choice.
                </p>
              </>
            )}
            <div className={clsx(styles.productSelection, isAll && styles.isAll)}>
              {isAll ? (
                <div className={styles.allArt}>
                  <img src={KSGamesHeader} alt="All Kickstarter Games" />
                </div>
              ) : (
                <>
                  <h3>Select your Kickstarter Game</h3>
                  <div className={styles.coverArt}>
                    {selectedProduct && <img src={gameArt} alt={selectedProduct.title} />}
                  </div>
                </>
              )}
              <div className={styles.rightContent}>
                <div className={styles.form}>
                  <DropdownInput
                    className={clsx(styles.dropdown, isAll && styles.isAll)}
                    onChange={onChangeSupplement}
                    value={supplement}
                  >
                    <option value="">Select your Supplement</option>
                    {options.map(({ guid, title }) => (
                      <option key={guid} value={guid}>
                        {title}
                      </option>
                    ))}
                  </DropdownInput>
                  <Button
                    primaryBackground="var(--color-teal)"
                    secondaryBackground="var(--color-green)"
                    onClick={onSubmit}
                    isDisabled={supplement === '' || options.length === 0}
                  >
                    {isAll ? (options.length === 0 && hasExtras ? 'Redeemed' : 'Redeem All') : 'Redeem'}
                  </Button>
                </div>

                {!!selectedProduct && !isAll && hasItems && (
                  <div className={styles.productInfo}>
                    <h4>Includes:</h4>
                    <ul>
                      {selectedProduct.itemsList.platform &&
                        Object.entries(selectedProduct.itemsList.platform).map(([item, label], i) => (
                          <li key={i}>
                            {getBundleItemIcon(item)}
                            {label}
                          </li>
                        ))}
                      {selectedProduct.itemsList.download &&
                        Object.entries(selectedProduct.itemsList.download).map(([item, label], i) => (
                          <li key={i}>
                            {getBundleItemIcon(item)}
                            {label}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {hasExtras && (
              <div className={styles.extras}>
                <p>
                  Thank you for backing at the Party Pack or Lifetime level! Below are your extra redemption codes and
                  their claim status:
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Invite URL</th>
                      <th>Email</th>
                      <th>Claim Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {extras.map(({ code, inviteUrl, email, isRedeemed }) => (
                      <tr key={code} className={clsx(isRedeemed && styles.isRedeemed)}>
                        <td>{inviteUrl}</td>
                        <td>{email || 'Available'}</td>
                        <td>{isRedeemed ? 'Claimed' : 'Unclaimed'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        {isSuccess && !!redeemedGames && (
          <>
            <h4>Congratulations!</h4>
            {isAll && (
              <p className={clsx(styles.text, styles.success)}>
                You've successfully redeemed{' '}
                <strong>{pluralize(redeemedGames.length, `${redeemedGames.length} Supplement`)}</strong>. They have been
                added to your account and you can start a Game Room here on Role as well as download a zip of all
                materials in each supplement.
              </p>
            )}
            {!isAll && (
              <p className={clsx(styles.text, styles.success)}>
                You've successfully redeemed <strong>{redeemedGames[0].title}</strong>. It has been added to your
                account and you can start a Game Room here on Role as well as download a zip of all materials in the
                supplement.
              </p>
            )}
          </>
        )}
        {error && <p className={clsx(styles.text, styles.error)}>{error.message}</p>}
      </TitlePanel>
    </div>
  );
}
