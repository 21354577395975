export enum TableViewMode {
  Speaker = 'speaker',
  Grid = 'grid',
}

export enum MediaMode {
  None,
  Deleting,
  Drawing,
}

export enum RoomTab {
  Party = 'party',
  Chat = 'chat',
  Sheets = 'sheets',
  Materials = 'materials',
  Dice = 'dice',
  Store = 'store',
}
