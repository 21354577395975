import styles from './dashboard.module.css';

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserAction } from 'store/actions';
import { getFullDate } from 'utilities';
import { formatCents } from 'utilities/numbers';

import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import TitlePanel from 'components/TitlePanel';

const SPINNER_COLOR: string = 'var(--color-dark-text)';

export default function AffiliatePage() {
  const dispatch = useDispatch();
  const { username } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<any>({});
  const [payments, setPayments] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const { lifetimeEarnings, recentEarnings, roomCredits = 0, newUserCredits = 0, returningUserCredits = 0 } = stats;

  useEffect(() => {
    setIsLoading(true);
    UserAction.fetchAffiliateStats(username).then(
      ({ data, included }) => {
        setStats(data.attributes);
        setPayments(included.map((o) => o.attributes) ?? []);
        setIsLoading(false);
      },
      ({ response }) => setError(response.data.message)
    );
  }, [dispatch, username]);

  return (
    <DashboardLayout>
      <Metatags title="Affiliate Dashboard" />
      <div className={styles.content}>
        <div className={styles.partnerCard}>
          <p style={{ marginBottom: 18 }}>
            <strong>Thank you for participating in our Host Affiliate Pilot Program!</strong>
          </p>
          <p style={{ marginBottom: 18 }}>
            As of January 2024, we are ending our affiliate pilot program while we evaluate how we'd like to continue
            and improve our partnerships with GMs. If you have any oustanding earnings we will be paying them out soon.
            If you would like to use your unpaid earnings as credits towards our Role Patron subscription, please email
            us at support@playrole.com.
          </p>
          <p>Thank you for joining us and running games on Role!</p>
        </div>
        <TitlePanel title="Start Playing Games Event" subtitle="February - December 2023">
          {error && <p className={styles.affiliateError}>{error}</p>}
          {isLoading ? (
            <LoadingSpinner color={SPINNER_COLOR} />
          ) : (
            <div className={clsx(styles.partnerCardsContainer)}>
              <div className={clsx(styles.partnerCards, styles.isAffiliate)}>
                <div className={styles.partnerCard}>
                  {recentEarnings > 0 && (
                    <>
                      <h4>Recent Earnings</h4>
                      <div className={styles.affiliateStat}>{`${formatCents(recentEarnings)} USD`}</div>
                    </>
                  )}
                  <h4>Lifetime Earnings</h4>
                  <div className={styles.affiliateStat}>{`${formatCents(lifetimeEarnings)} USD`}</div>
                  <div className={styles.affiliateCopy}>
                    <ul>
                      <li>
                        New Game Room Earnings: {roomCredits} x $10 = ${roomCredits * 10}
                      </li>
                      <li>
                        New Player Earnings: {newUserCredits} x $3 = ${newUserCredits * 3}
                      </li>
                      <li>
                        Returning New Player Earnings: {returningUserCredits} x $3 = ${returningUserCredits * 3}
                      </li>
                    </ul>
                    <p>
                      Have any questions or issues? Email us at{' '}
                      <a href="mailto:support@playrole.com?subject=SPG%20Event">support@playrole.com</a>.
                    </p>
                  </div>
                </div>

                <div className={styles.partnerCard}>
                  <h4>Payments</h4>
                  {payments.length > 0 ? (
                    <table className={styles.partnerTable}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Invoice ID</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments.map((payment) => (
                          <tr key={payment.referenceId}>
                            <td>{getFullDate(new Date(payment.createdAt))}</td>
                            <td>{payment.referenceId}</td>
                            <td>{formatCents(payment.amount)} USD</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>You have not been sent any payments yet.</p>
                  )}
                </div>
              </div>

              <div className={clsx(styles.partnerCards, styles.isAffiliate)}>
                <div className={styles.partnerCard}>
                  <h4>New Game Room Credits</h4>
                  <div className={styles.affiliateStat}>{roomCredits}</div>
                  <div className={styles.affiliateCopy}>
                    <p>
                      You will be paid for each new active game room you create. For a room to qualify as “new & active”
                      the following conditions must be met:
                    </p>
                    <ul>
                      <li>The room is new (no prior play sessions have been run in the room).</li>
                      <li>At least 3 players in the room (You + 2 players).</li>
                      <li>
                        At least 1 new player (one of the players must be a brand-new account who has never played a
                        game on Role before).
                      </li>
                      <li>All players connected to an active audio/video call for at least 30 min.</li>
                    </ul>
                  </div>
                </div>

                <div className={styles.partnerCard}>
                  <h4>New Player Credits</h4>
                  <div className={styles.affiliateStat}>{newUserCredits}</div>
                  <div className={styles.affiliateCopy}>
                    <p>
                      You will be paid a bonus for each new player that signs up for Role via your active game rooms. To
                      qualify for this bonus, the following conditions must be met:
                    </p>
                    <ul>
                      <li>The player has never played a session on Role before.</li>
                      <li>
                        The player is connected to the active audio/video call in the game room for at least 30 min.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={styles.partnerCard}>
                  <h4>Returning New Player Credits</h4>
                  <div className={styles.affiliateStat}>{returningUserCredits}</div>
                  <div className={styles.affiliateCopy}>
                    <p>
                      When you give players a great experience, those players come back! You earn an additional bonus
                      for any new player who returns for their second game session. To qualify for this bonus, the
                      following conditions must be met:
                    </p>
                    <ul>
                      <li>
                        A player must have signed up for a new account and played their first session in a game room
                        created by the you.
                      </li>
                      <li>
                        That same player must then play an additional session (connected to an audio/video call for at
                        least 30 min) <em>anywhere</em> else on the platform.
                      </li>
                      <li>The second session must happen within 60 days of the first.</li>
                      <li>
                        <strong>Important Note:</strong> It does <em>not</em> matter whether the second session is in
                        the same game room as the first. In fact, the player could play their second session with other
                        players or other GMs! As long as the player plays again somewhere on Role, you will receive this
                        bonus.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </TitlePanel>
      </div>
    </DashboardLayout>
  );
}
