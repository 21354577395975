import { createSelector } from 'reselect'

export default class AssetSelector {
  static getAssetToDelete = createSelector(
    [state => state.documents, state => state.asset.deleteAssetId],
    (documents, id) => (id ? documents[id] : null)
  )

  static getAssetMapToEdit = createSelector(
    [state => state.documents, state => state.asset.mapAssetId],
    (documents, id) => (id ? documents[id] : null)
  )

  static getAssetTokenToEdit = createSelector(
    [state => state.documents, state => state.asset.tokenAssetId],
    (documents, id) => (id ? documents[id] : null)
  )

  static getAssetToRename = createSelector(
    [state => state.documents, state => state.asset.renameAssetId],
    (documents, id) => (id ? documents[id] : null)
  )

  static menuAssetId = createSelector(
    state => state.asset.menuAssetId,
    id => id
  )

  static showAddTokenModal = createSelector(
    state => state.asset.showAddTokenModal,
    show => show
  )

  static showLibraryModal = createSelector(
    state => state.asset.showLibraryModal,
    show => show
  )

  static showUploadModal = createSelector(
    state => state.asset.showUploadModal,
    show => show
  )
}
