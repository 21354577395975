import { createAction } from './utilities'

export default class MediaAction {
  static SET_CANVAS_SCALE = 'MediaAction.SET_CANVAS_SCALE'
  static SET_MODE = 'MediaAction.SET_MODE'
  static SET_PEN_COLOR = 'MediaAction.SET_PEN_COLOR'

  static setCanvasScale = scale => {
    return createAction(MediaAction.SET_CANVAS_SCALE, scale)
  }

  static setMode = mode => {
    return createAction(MediaAction.SET_MODE, mode)
  }

  static setPenColor = color => {
    return createAction(MediaAction.SET_PEN_COLOR, color)
  }
}
