// @flow
import styles from './Modal.module.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type {
  ElementModel,
  Link,
  LinkActionType,
  LinkType,
  OnChangeElement,
  OnChangeGroupElement,
} from 'components/Sheet2/types';
import { DICE_ROLLER_TYPE, ELEMENT_TYPE, INPUT_TYPE, LINK_ACTION_TYPE, LINK_TYPE } from 'constants/sheet';
import COLOR from 'models/Color';
import Die, { D20, DICE, DieType } from 'models/Die';
import { SheetTemplateAction } from 'store/actions';
import { DocumentSelector, SheetBuilderSelector, SheetTemplateSelector } from 'store/selectors';
import { findElementPath } from 'utilities/sheet';

import Button from 'components/buttons/Button';
import { DropdownInput } from 'components/inputs';
import DicePool from 'components/Sheet2/elements/DicePoolElement/DicePool';
import { ReactComponent as PenIcon } from 'images/icons/PenIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import CreateDiceModal from 'modals/CreateDiceModal';
import Modal from 'modals/Modal';

type LinkModalProps = {
  actionType: LinkActionType,
  elementId: string,
  groupId: string,
  link: Link,
  linkIndex: number,
  linkType: LinkType,
  onChangeElement: OnChangeElement,
  onChangeGroupElement: OnChangeGroupElement,
  onModalClose: () => void,
  sectionId: string,
  sheetGuid: string,
};

const DEFAULT_DICE = { count: 1, sides: D20.sides, type: D20.type, color: null };

export default function LinkModal({
  actionType,
  elementId,
  groupId,
  link,
  linkIndex,
  linkType,
  onChangeElement,
  onChangeGroupElement,
  onModalClose,
  sectionId,
  sheetGuid,
}: LinkModalProps) {
  const dispatch = useDispatch();
  const sheet = useSelector((state) => SheetTemplateSelector.get(state, sheetGuid));
  const element = useSelector((state) =>
    SheetBuilderSelector.elementSelector(state, { modelId: sheetGuid, sectionId, elementId, groupId })
  );
  const linkableSections = useSelector((state) => {
    if (linkType === LINK_TYPE.toggle) {
      return SheetBuilderSelector.linkableTogglesSelector(state, { modelId: sheetGuid, sectionId, elementId, groupId });
    } else {
      return SheetBuilderSelector.linkableGameplaySelector(state, {
        modelId: sheetGuid,
        sectionId,
        elementId,
        groupId,
      });
    }
  });
  const linkNames = useSelector((state) => SheetBuilderSelector.linkNamesSelector(state, { modelId: sheetGuid }));
  const flatLinks = useSelector((state) =>
    SheetBuilderSelector.flatLinksSelector(state, { modelId: sheetGuid, sectionId, elementId, groupId })
  );
  const customDice = useSelector((state) => DocumentSelector.getCustomDiceByTemplate(state, sheetGuid)).map(
    (doc) => new Die({ name: doc.name, sides: doc.metadata.sides, type: doc.metadata.types })
  );
  const [showCreateModal, setShowCreateModal] = useState(false);

  const isCreate = actionType === LINK_ACTION_TYPE.create;
  const isEdit = actionType === LINK_ACTION_TYPE.edit;
  const isRemove = actionType === LINK_ACTION_TYPE.remove;

  const getToggleIdFromElement = (element: ElementModel) => {
    return element.items.find((i) => i.inputType === INPUT_TYPE.toggle)?.id;
  };

  const hasCurrentLinksOrItself = useCallback(
    (id: string) => {
      const isEditingSelf = link && link.elementId === id;
      const isSelf = elementId === id;
      const linkExists = flatLinks[linkType].includes(id);
      return !isEditingSelf && (isSelf || linkExists);
    },
    [elementId, flatLinks, link, linkType]
  );

  let initialSectionValue,
    initialElementValue = null;

  if (link?.dice) {
    initialSectionValue = DICE_ROLLER_TYPE.value;
    const diceName = DICE.find(
      (o) => o.sides === parseInt(link.dice?.sides ?? D20.sides) && o.type === (link.dice?.type ?? D20.type)
    )?.name;
    initialElementValue = diceName;
  } else {
    const { section: linkSection } = link ? findElementPath(sheet.draftData.sections, link.elementId) : {};
    initialSectionValue =
      linkSection?.id ?? linkableSections.find((o) => o.id === sectionId)?.id ?? linkableSections[0]?.id;
    let initialElement;
    if (linkSection)
      linkSection.elements.forEach((el) => {
        if (el.elementType === ELEMENT_TYPE.group) {
          el.items.forEach((groupEl) => {
            if (link && link.elementId === groupEl.id) initialElement = groupEl;
          });
        } else {
          if (link && link.elementId === el.id) initialElement = el;
        }
      });
    initialElementValue = initialElement?.id;
  }

  const [sectionValue, setSectionValue] = useState(
    linkType === LINK_TYPE.toggle ? initialSectionValue : initialSectionValue || DICE_ROLLER_TYPE.value
  ); // section id
  const [elementValue, setElementValue] = useState(initialElementValue); // element id
  const [diceValue, setDiceValue] = useState(link?.dice ?? DEFAULT_DICE);

  // set up the dropdown options
  const sectionNames = {};
  const elementNames = { '': { value: '---' } };
  if (linkType === LINK_TYPE.gameplay) sectionNames[DICE_ROLLER_TYPE.value] = { value: DICE_ROLLER_TYPE.label };

  linkableSections.forEach(
    (section, index) => (sectionNames[section.id] = { value: section.title || `Section ${index + 1}` })
  );
  if (sectionValue) {
    if (sectionValue === DICE_ROLLER_TYPE.value) {
      DICE.forEach((d) => (elementNames[`${d.sides}_${d.type}`] = { value: d.name }));
      customDice.forEach((d) => (elementNames[`${d.sides}_${d.type}`] = { value: d.name }));
    } else {
      const section = linkableSections.find((section) => section.id === sectionValue);
      if (section) {
        section.elements.forEach(({ id }) => {
          // make sure not to show current links' elements and the element itself
          elementNames[id] = {
            value: linkNames[id],
            disabled: hasCurrentLinksOrItself(id),
          };
        });
      }
    }
  }

  useEffect(() => {
    if (!sectionValue) return;
    if (sectionValue === DICE_ROLLER_TYPE.value) {
      if (link && link.dice) {
        setDiceValue(link.dice);
        setElementValue(`${link.dice.sides}_${link.dice.type}`);
      } else {
        setDiceValue(DEFAULT_DICE);
        setElementValue(`${DEFAULT_DICE.sides}_${DEFAULT_DICE.type}`);
      }
    } else {
      setDiceValue(null);
    }
  }, [hasCurrentLinksOrItself, link, linkableSections, sectionValue]);

  let title, subtitle;
  const linkTypeLabel = linkType === LINK_TYPE.toggle ? 'Toggle Link' : 'Gameplay Link';
  if (isEdit) {
    title = `Edit ${linkTypeLabel}`;
    subtitle = `Edit Current Link: ${linkNames[link.elementId]}`;

    if (link?.dice) {
      const dice =
        DICE.find((o) => o.sides === parseInt(link.dice.sides) && o.type === link.dice.type) ||
        customDice.find((o) => o.sides === parseInt(link.dice.sides) && o.type === link.dice.type);
      const diceLinkName = `${dice.name} x ${link.dice.count}`;
      subtitle = `Edit Current Link: ${diceLinkName}`;
    } else {
      subtitle = `Edit Current Link: ${linkNames[link.elementId]}`;
    }
  } else if (isRemove) {
    title = `Remove ${linkTypeLabel}`;
    if (link?.dice) {
      const diceLinkName =
        link.dice.type === DieType.FATE ? `DF x ${link.dice.count}` : `D${link.dice.sides} x ${link.dice.count}`;
      subtitle = `Are you sure you want to remove Link: ${diceLinkName}?`;
    } else {
      subtitle = `Are you sure you want to remove Link: ${linkNames[link.elementId]}?`;
    }
  } else {
    title = `Add ${linkTypeLabel}`;
    if (linkType === LINK_TYPE.toggle) {
      subtitle = "Link this Element's toggle to another Element's toggle on this sheet.";
    } else {
      subtitle = 'Link this Element to another Element on this sheet or to the dice roller in a room.';
    }
  }

  const handleAddLink = () => {
    let newLink;
    if (!!diceValue) {
      const newDice = {
        count: diceValue.count,
        sides: diceValue.sides,
        type: diceValue.type,
        color: diceValue.color,
      };
      newLink = { dice: newDice };
    } else {
      const targetSection = linkableSections.find((section) => section.id === sectionValue);
      if (!targetSection) return;
      const targetElement = targetSection.elements.find((element) => element.id === elementValue);
      if (!targetElement) return;
      newLink = { elementId: targetElement.id };
      if (linkType === LINK_TYPE.toggle) newLink = { ...newLink, inputId: getToggleIdFromElement(targetElement) };
    }

    const updatedLinks = [...element.links, newLink];
    const updatedElement = { ...element, links: updatedLinks };
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
    onModalClose();
  };

  const handleEditLink = () => {
    const updatedLinks = [...element.links];

    let updatedLink;
    if (!!diceValue) {
      const newDice = {
        count: diceValue.count,
        sides: diceValue.sides,
        type: diceValue.type,
        color: diceValue.color,
      };
      updatedLink = { dice: newDice };
      updatedLinks.splice(linkIndex, 1, updatedLink);
    } else {
      const targetSection = linkableSections.find((section) => section.id === sectionValue);
      if (!targetSection) return;
      const targetElement = targetSection.elements.find((element) => element.id === elementValue);
      if (!targetElement) return;
      updatedLink = { elementId: targetElement.id };
      if (linkType === LINK_TYPE.toggle)
        updatedLink = { ...updatedLink, inputId: getToggleIdFromElement(targetElement) };
      updatedLinks.splice(linkIndex, 1, updatedLink);
    }

    const updatedElement = { ...element, links: updatedLinks };
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
    onModalClose();
  };

  const handleRemoveLink = () => {
    const updatedLinks = [...element.links];
    updatedLinks.splice(linkIndex, 1);
    const updatedElement = { ...element, links: updatedLinks };
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
    onModalClose();
  };

  const onDieChange = (sides, type) => {
    setDiceValue((d) => ({ ...d, sides, type }));
    setElementValue(`${sides}_${type}`);
  };

  const onCreateDiceSubmit = ({ id, metadata: { type, sides } }) => {
    dispatch(
      SheetTemplateAction.createDice(sheetGuid, id, () => {
        setShowCreateModal(false);
        setDiceValue((d) => ({ ...d, sides, type }));
        setElementValue(`${sides}_${type}`);
      })
    );
  };

  return (
    <Modal className={styles.containerDark} onDismiss={onModalClose}>
      <Modal.Header>
        {isCreate && (
          <Modal.Icon width={25} height={25}>
            <PlusIcon />
          </Modal.Icon>
        )}
        {(isEdit || isRemove) && (
          <Modal.Icon>
            <PenIcon />
          </Modal.Icon>
        )}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <p className={styles.copy}>{subtitle}</p>

      {!isRemove && (
        <div className={styles.linkModalInputs}>
          <DropdownInput
            options={sectionNames}
            value={sectionValue}
            onChange={setSectionValue}
            variant="dark"
            className={styles.linkModalDropdown}
          />
          {sectionValue === DICE_ROLLER_TYPE.value ? (
            <div className={styles.linkModalDice}>
              <DicePool
                id="link-modal-dice"
                diceCount={diceValue?.count ?? 1}
                diceSides={Number(elementValue?.split('_')[0] ?? DEFAULT_DICE.sides)}
                diceType={elementValue?.split('_')[1]}
                diceColor={diceValue?.color ?? ''}
                customDice={customDice}
                canEditCount
                canEditDie
                canEditColor
                isBuilding
                primaryColor={COLOR.RED}
                secondaryColor={COLOR.PURPLE}
                onCountChange={(count) => setDiceValue((d) => ({ ...d, count }))}
                onDieChange={onDieChange}
                onColorChange={(color) => setDiceValue((d) => ({ ...d, color }))}
                onAddClick={() => setShowCreateModal(true)}
              />
            </div>
          ) : (
            <DropdownInput
              options={elementNames}
              value={elementValue}
              onChange={setElementValue}
              variant="dark"
              className={styles.linkModalDropdown}
            />
          )}
        </div>
      )}

      <Modal.Actions>
        {isRemove && (
          <Button variant="primary" onClick={handleRemoveLink}>
            Remove Link
          </Button>
        )}
        {(isCreate || isEdit) && (
          <Button variant="primary" onClick={isEdit ? handleEditLink : handleAddLink} isDisabled={!elementValue}>
            {isEdit ? 'Save Link' : 'Add Link'}
          </Button>
        )}
      </Modal.Actions>

      {showCreateModal && <CreateDiceModal onSubmit={onCreateDiceSubmit} onDismiss={() => setShowCreateModal(false)} />}
    </Modal>
  );
}
