// @flow
import React, { memo } from 'react';
import type { Node } from 'react';

import { ELEMENT_ALIGNMENT, INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';

import Element from 'components/Sheet2/elements/Element';
import Image from './Image';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function ImageElement(props: Props): Node {
  const { element, images } = props;
  const { items, metadata } = element;

  const imageInput = items.find((o) => o.inputType === INPUT_TYPE.image);
  const value = imageInput?.defaultValue ?? null;
  const url = value ? images[value] : null;
  const alignment = metadata.alignment || ELEMENT_ALIGNMENT.center;

  return <Element {...props}>{imageInput && <Image url={url} alignment={alignment} />}</Element>;
}

export default memo(ImageElement);
