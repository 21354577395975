import { useLayoutEffect } from 'react';

import useCallbackAsRef from './useCallbackAsRef';

type SingleResizeObserverCallback = (entry: ResizeObserverEntry, observer: ResizeObserver) => void;

const ResizeObserver = typeof window !== 'undefined' && 'ResizeObserver' in window ? window.ResizeObserver : undefined;

// ResizeObserver Singleton
let _resizeObserver: ReturnType<typeof createResizeObserver>;

/**
 * Creates a ResizeObserver singleton that we can subscribe and unsubscribe to
 * @returns
 */
function createResizeObserver() {
  let ticking = false;
  let allEntries: ResizeObserverEntry[] = [];

  const callbacks: Map<any, Array<SingleResizeObserverCallback>> = new Map();

  if (!ResizeObserver) return;

  const observer = new ResizeObserver((entries, observer) => {
    allEntries = allEntries.concat(entries);

    if (!ticking) {
      window.requestAnimationFrame(() => {
        const triggered = new Set<Element>();
        for (const entry of allEntries) {
          if (triggered.has(entry.target)) continue;
          triggered.add(entry.target);
          const cbs = callbacks.get(entry.target);
          cbs?.forEach((cb) => cb(entry, observer));
        }
        allEntries = [];
        ticking = false;
      });
    }
    ticking = true;
  });

  return {
    observer,
    subscribe(target: Element, callback: SingleResizeObserverCallback) {
      observer.observe(target);
      const cbs = callbacks.get(target) ?? [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },
    unsubscribe(target: Element, callback: SingleResizeObserverCallback) {
      const cbs = callbacks.get(target) ?? [];
      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
      } else {
        const cbIndex = cbs.indexOf(callback);
        if (cbIndex >= 0) cbs.splice(cbIndex, 1);
        callbacks.set(target, cbs);
      }
    },
  };
}

const getResizeObserver = () => (!_resizeObserver ? (_resizeObserver = createResizeObserver()) : _resizeObserver);

// https://github.com/jaredLunde/react-hook/blob/master/packages/resize-observer/src/index.tsx
export default function useResizeObserver<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  callback: SingleResizeObserverCallback
) {
  const resizeObserver = getResizeObserver();
  const callbackRef = useCallbackAsRef(callback);

  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetElement = target && 'current' in target ? target.current : target;
    if (!targetElement) return;

    const cb: SingleResizeObserverCallback = (entry, observer) => {
      if (didUnsubscribe) return;
      callbackRef.current(entry, observer);
    };

    resizeObserver?.subscribe(targetElement, cb);

    return () => {
      didUnsubscribe = true;
      resizeObserver?.unsubscribe(targetElement, cb);
    };
  }, [target, resizeObserver, callbackRef]);

  return resizeObserver?.observer;
}
