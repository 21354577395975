// @flow
import type { Node } from 'react';
import React from 'react';

import type { IconButtonProps } from './IconButton';
import IconButton from './IconButton';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

export default function CloseButton(props: IconButtonProps): Node {
  const { label: rawLabel } = props;
  let label = '';
  if (rawLabel) label += ` ${rawLabel}`;

  return <IconButton {...props} label={label} children={<XIcon />} />;
}
