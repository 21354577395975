// @flow
import styles from './Toggle.module.css';
import React from 'react';
import { linearGradient } from 'utilities/color';

type Props = {
  canEdit: boolean,
  className?: ?string,
  onChange: (boolean) => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
  value: boolean,
};

const Toggle = (props: Props) => {
  const { className, onChange: propOnChange, primaryColor, readOnly, secondaryColor, value } = props;

  const classNames = [styles.container];
  if (readOnly) classNames.push(styles.readOnly);
  if (!!value) classNames.push(styles.isActive);
  if (className) classNames.push(className);
  const gradient = linearGradient([primaryColor, secondaryColor], '163deg');

  const onChange = (event) => {
    if (readOnly) return;
    propOnChange(event.currentTarget.checked);
  };

  return (
    <label className={classNames.join(' ')} style={{ '--toggle-active-background': gradient }}>
      <input className={styles.input} type="checkbox" checked={!!value} readOnly={readOnly} onChange={onChange} />
      <span className={styles.dot} />
    </label>
  );
};

Toggle.defaultProps = {
  canEdit: false,
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Toggle;
