import styles from './RoomControls.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFontSize from 'hooks/useFontSize';
import useWindowSize from 'hooks/useWindowSize';
import { TableAction } from 'store/actions';
import { GameSelector, SessionSelector, TableSelector } from 'store/selectors';
import { stylizeTheme } from 'utilities/color';

import IconButton from 'components/buttons/IconButton';
import UserMenu2 from 'components/layouts/UserMenu2';
import TableMenu from 'components/TableMenu';
import TableSheetSelect from 'components/TableSheetSelect';
import { ReactComponent as HidePanelIcon } from 'images/icons/HidePanelIcon.svg';
import { ReactComponent as InviteIcon } from 'images/icons/InviteIcon.svg';

type Props = {
  className?: ?string,
  subtitle?: string,
  room?: ?any,
  theme?: ?any,
  isTOCOpen: boolean,
  isShowingSheet: boolean,
  onToggleClick: () => void,
  onAddSheet?: () => void,
};

function RoomControls({
  className,
  subtitle,
  hostId,
  room,
  theme,
  isTOCOpen,
  isShowingSheet,
  onToggleClick,
  onAddSheet,
}: Props): Node {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const game = useSelector((state) => previewRoom && GameSelector.getBySlug(state, previewRoom));
  const currentUser = useSelector(SessionSelector.currentUser);
  const title = room?.title || game?.title;
  const fontSizes = size.isMd
    ? {
        '25': 20,
        '32': 16,
        '40': 14,
      }
    : {
        '99': 14,
      };
  const nameFontSize = useFontSize(title?.length ?? '', fontSizes);

  return (
    <div
      className={clsx(styles.roomControls, className, isTOCOpen && isShowingSheet && styles.wide)}
      style={theme && stylizeTheme(theme)}
    >
      <div className={styles.title}>
        <h3 title={title} style={{ fontSize: nameFontSize }}>
          {title}
        </h3>
        <h5>{subtitle}</h5>
      </div>
      <div className={styles.actions}>
        <div className={styles.buttons}>
          {currentUser && room && (
            <>
              <TableSheetSelect roomGuid={room.guid} theme={theme} onAddSheet={onAddSheet} />
              <TableMenu roomGuid={room.guid} hostId={room.userId} theme={theme} />
              <IconButton
                className={styles.inviteButton}
                color="rgb(var(--color-theme-text))"
                background="rgb(var(--color-theme-button))"
                hoverBackground="rgb(var(--color-theme-accent))"
                label="Invite"
                iconSize={24}
                onClick={() => dispatch(TableAction.showInvite(true))}
                children={<InviteIcon />}
              />
            </>
          )}
          <IconButton
            className={clsx(styles.togglePanelButton, !isShowingSheet && styles.isCollapsed)}
            color="rgb(var(--color-theme-text))"
            background="rgb(var(--color-theme-button))"
            activeBackground="rgb(var(--color-theme-button))"
            hoverBackground="rgb(var(--color-theme-accent))"
            label="Toggle Panel"
            children={<HidePanelIcon />}
            activeChildren={<HidePanelIcon className={styles.isCollapsedActive} />}
            isActive={!isShowingSheet}
            onClick={onToggleClick}
          />
        </div>
        {currentUser && !size.isMd && <UserMenu2 theme={theme} className={styles.userMenu} />}
      </div>
    </div>
  );
}

export default RoomControls;
