// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';
import { v4 as uuid } from 'uuid';

type Props = {
  className?: ?string,
  style?: ?any,
  icon: ComponentType<any>,
  primaryColor: string,
  secondaryColor: string,
};

export default function GradientHexagon(props: Props): Node {
  const { className, icon, primaryColor, secondaryColor, style } = props;
  const id = uuid();
  const IconComponent = icon;
  return (
    <div className={className} style={style}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor={primaryColor} />
            <stop offset="100%" stopColor={secondaryColor} />
          </linearGradient>
        </defs>
      </svg>
      <IconComponent style={{ width: '100%', height: '100%', '--hexagon-fill': `url(#${id})`, strokeOpacity: 0 }} />
    </div>
  );
}
