import styles from './Card.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import RoomAction from 'store/actions/RoomAction';

import { Suit } from 'models/Card';
import { ReactComponent as HeartIcon } from 'images/cards/HeartIcon.svg';
import { ReactComponent as DiamondIcon } from 'images/cards/DiamondIcon.svg';
import { ReactComponent as SpadeIcon } from 'images/cards/SpadeIcon.svg';
import { ReactComponent as ClubIcon } from 'images/cards/ClubIcon.svg';

class Card extends Component {
  // This is used as a hack to stop the card from flickering the first time it flips over.
  // The front of the won't render if it starts off rotated 180deg. With this we don't rotate
  // the card until the first time it is flipped over.
  hasLoaded = false;

  getIcon = (suit) => {
    switch (suit) {
      case Suit.HEARTS:
        return <HeartIcon />;
      case Suit.DIAMONDS:
        return <DiamondIcon />;
      case Suit.SPADES:
        return <SpadeIcon />;
      case Suit.CLUBS:
        return <ClubIcon />;
      default:
        return;
    }
  };

  onClick = () => {
    const { room, card, updateCard } = this.props;
    card.isFlipped = !card.isFlipped;
    updateCard(room.guid, card);
  };

  onDiscardClick = (event) => {
    event.stopPropagation();
    const { room, card, updateCard } = this.props;
    card.isDrawn = false;
    card.isDiscarded = true;
    card.userId = null;
    card.isFlipped = false;
    card.updatedAt = Date.now();
    updateCard(room.guid, card);
  };

  onRotateClick = (event) => {
    event.stopPropagation();
    const { room, card, updateCard } = this.props;
    card.isRotated = !card.isRotated;
    updateCard(room.guid, card);
  };

  renderIcons = () => {
    const {
      card: { suit, value },
    } = this.props;
    const icons = [];

    if (value <= 10) {
      for (let i = 0; i < value; i++) {
        icons.push(
          <div key={i} className={styles.icon}>
            {this.getIcon(suit)}
          </div>
        );
      }
    }

    return icons;
  };

  render() {
    const { card, canDiscard } = this.props;
    const { name, suit, image, isFlipped, isRotated } = card;
    const isRed = [Suit.HEARTS, Suit.DIAMONDS].includes(suit);
    const classNames = ['box-shadow', styles.container];
    if (!this.hasLoaded) this.hasLoaded = isFlipped;
    if (this.hasLoaded) classNames.push(styles.hasLoaded);
    if (isRotated) classNames.push(styles.isRotated);
    if (isFlipped) classNames.push(styles.isFlipped);
    isRed ? classNames.push(styles.isRed) : classNames.push(styles.isBlack);
    // TODO: use a better means to determin if it's tarot / needs a custom size
    if (!suit && name !== 'Joker') classNames.push(styles.isTarot);

    return (
      <div onClick={this.onClick} className={classNames.join(' ')}>
        <div className={styles.card}>
          <div className={styles.cardFront}>
            {!image && (
              <>
                <div className={`${styles.name} ${styles.isTop}`}>{name}</div>
                <div className={styles.iconsContainer}>
                  <div className={styles.icons}>{this.renderIcons()}</div>
                </div>
                <div className={`${styles.name} ${styles.isBottom}`}>{name}</div>
              </>
            )}
            {image && (
              <div className={styles.cardImage}>
                <img src={image} alt={card.name} />
              </div>
            )}
          </div>

          <div className={styles.cardBack}>
            <div className={styles.cardBackIcon} />
          </div>
        </div>

        <div className={styles.actions}>
          {canDiscard && (
            <div onClick={this.onDiscardClick} className={`${styles.actionButton} ${styles.discardButton}`}>
              X
            </div>
          )}
          <div onClick={this.onRotateClick} className={`${styles.actionButton} ${styles.rotateButton}`}>
            R
          </div>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  canDiscard: false,
};

const mapDispatchToProps = {
  updateCard: RoomAction.updateCard,
};

export default connect(null, mapDispatchToProps)(Card);
