// @flow
import styles from './Image.module.css';

import React from 'react';
import type { Node } from 'react';

import type { ElementAlignment } from 'components/sheet2/types';
import { ReactComponent as ImageIcon } from 'images/icons/ImageIcon.svg';

type ImageProps = {
  alignment: ElementAlignment,
  alt: string,
  url: ?string,
};

function Image({ alignment, alt, url }: ImageProps): Node {
  return (
    <div className={`${styles.container} ${styles[alignment]}`}>
      {url ? (
        <img className={styles.image} src={url} alt={alt} />
      ) : (
        <div className={styles.placeholder}>
          <ImageIcon className={styles.imageIcon} />
        </div>
      )}
    </div>
  );
}

Image.defaultProps = {
  alt: '',
  url: null,
};

export default Image;
