// @flow
import styles from './AssetTray.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ChannelEvent } from 'models/Channel';
import { DEFAULT_COLORS } from 'models/Color';
import { AssetAction, ErrorAction, RoomAction, RoomDocumentAction, TableAction } from 'store/actions';
import {
  ErrorSelector,
  GameSelector,
  RoomDocumentSelector,
  RoomUserSelector,
  SessionSelector,
  TableSelector,
} from 'store/selectors';
import type { RoomModel } from 'types/common';
import { RoomTab } from 'types/room';
import { capitalize } from 'utilities';

import PreviewRoomDocument from './PreviewRoomDocument';
import RoomDocument from './RoomDocument';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import { DropdownInput, TextInput } from 'components/inputs';
import LoadingSpinner from 'components/LoadingSpinner';
import { ReactComponent as FilterIcon } from 'images/icons/AlignCenterIcon.svg';
import { ReactComponent as ObrIcon } from 'images/icons/ObrIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as SearchIcon } from 'images/icons/SearchIcon.svg';
import { ReactComponent as SortDown } from 'images/icons/SortDown.svg';
import { ReactComponent as SortUp } from 'images/icons/SortUp.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

type Props = {
  className?: ?string,
  room: ?RoomModel,
  category: string,
};

const OBR_ORIGIN = process.env.REACT_APP_OBR_ORIGIN;
const OBR_CLIENT_ID = process.env.REACT_APP_OBR_CLIENT_ID;
const SPINNER_COLOR: string = 'var(--color-dark-text)';

function AssetTray(props: Props): Node {
  const { className, room, category } = props;
  const dispatch = useDispatch();
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const ref = useRef(null);
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const currentUser = useSelector(SessionSelector.currentUser);
  const roomGuid = room?.guid;
  const isCurrentUserMember = useSelector(
    (state) => roomGuid && !!RoomUserSelector.get(state, roomGuid, currentUser.id)
  );
  const currentGame = useSelector((state) =>
    roomGuid ? GameSelector.getByRoom(state, roomGuid) : GameSelector.getBySlug(state, previewRoom)
  );
  const theme = useSelector((state) => {
    if (roomGuid) return TableSelector.getTheme(state, roomGuid);
    else if (previewRoom) return RoomDocumentSelector.getPreviewThemeBySlug(state, previewRoom);
  });

  const FilterList = useMemo(() => {
    if (currentGame) {
      return {
        all: 'all',
        game: currentGame?.title,
        mine: 'mine',
        others: 'others',
      };
    } else {
      return {
        all: 'all',
        mine: 'mine',
        others: 'others',
      };
    }
  }, [currentGame]);

  const filterOptions = Object.values(FilterList).map((o) => String(o));

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(FilterList.all);
  const [order, setOrder] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const isPatron = currentUser?.isPatron;
  const hasObrConnected = !!room?.obrUrl;
  const isObrActive = useSelector(TableSelector.isObrActive);
  const obrError = useSelector((state) => ErrorSelector.get(state, RoomAction.UPDATE))?.message?.includes(
    'Obr url already attached'
  );
  const [showPatronModal, setShowPatronModal] = useState(false);

  const roomDocuments = useSelector((state) => {
    if (roomGuid) {
      return RoomDocumentSelector.getByRoom(state, roomGuid, category, order);
    } else if (previewRoom) {
      return RoomDocumentSelector.getByPreviewSlug(state, previewRoom, category, order);
    } else {
      return [];
    }
  });

  const colors = useSelector((state) =>
    roomGuid ? RoomUserSelector.getColors(state, roomGuid, currentUser.id) : DEFAULT_COLORS
  );

  const matchingTitles = useMemo(
    () => roomDocuments.filter((roomDocument) => roomDocument.name.toLowerCase().includes(searchTerm?.toLowerCase())),
    [roomDocuments, searchTerm]
  );
  const matchingGames = useMemo(
    () =>
      roomDocuments.filter((roomDocument) =>
        roomDocument.gameTitles?.find((title) => title.toLowerCase().includes(searchTerm?.toLowerCase()))
      ),
    [roomDocuments, searchTerm]
  );
  const matchingOwners = useMemo(
    () =>
      roomDocuments.filter(
        (roomDocument) =>
          roomDocument.userId !== currentUser?.id &&
          roomDocument.ownerName?.toLowerCase().includes(searchTerm?.toLowerCase())
      ),
    [roomDocuments, searchTerm, currentUser]
  );

  const searchResults = useMemo(
    () => [].concat(matchingTitles, matchingGames, matchingOwners).map((result) => result.id),
    [matchingGames, matchingOwners, matchingTitles]
  );

  const noFilterResults = useMemo(() => {
    const gameDocsLength = roomDocuments.filter((doc) => doc.gameIds.includes(currentGame?.id)).length;

    switch (filter) {
      case currentGame?.title:
        return gameDocsLength === 0;
      case 'custom':
        if (!!gameDocsLength) return roomDocuments.filter((doc) => !doc.gameIds.includes(currentGame?.id)).length === 0;
        break;
      case 'all':
      default:
        return false;
    }
  }, [currentGame, filter, roomDocuments]);

  const emptyHeader = useMemo(() => {
    switch (filter) {
      case 'custom':
      case currentGame?.title:
        return `There are no shared ${filter} ${
          category === 'assets' ? 'books or apps' : category
        } for you to view yet`;
      case 'all':
      default:
        return `You have not added any ${category === 'assets' ? 'books or apps' : category} to your room yet`;
    }
  }, [category, currentGame, filter]);

  const emptyBody = useMemo(() => {
    switch (filter) {
      case 'custom':
      case currentGame?.title:
        return `Share ${
          category === 'assets' ? 'books or apps' : category
        } with your friends around the room by adding them here`;
      case 'all':
      default:
        return `Share ${category === 'assets' ? 'books or apps' : category} with your friends by adding them here`;
    }
  }, [category, currentGame, filter]);

  const showEmpty = useMemo(() => {
    return isSearching ? roomDocuments.length === 0 : roomDocuments.length === 0 || noFilterResults;
  }, [isSearching, noFilterResults, roomDocuments.length]);

  useEffect(() => {
    if (!isCurrentUserMember) return;
    setIsLoading(true);
    dispatch(
      RoomDocumentAction.fetchAll(roomGuid, ({ roomDocuments }) => {
        const sharedDoc = roomDocuments.find((o) => o.isShared);
        if (sharedDoc) {
          batch(() => {
            dispatch(TableAction.setSharedRoomDocument(sharedDoc.guidId));
            dispatch(TableAction.setActiveRoomDocument(sharedDoc.guidId));
          });
        }
        setIsLoading(false);
      })
    );
  }, [dispatch, isCurrentUserMember, roomGuid]);

  const onUploadAssetClick = () => {
    batch(() => {
      dispatch(AssetAction.showMenu(null));
      dispatch(AssetAction.showUpload(true));
    });
  };

  const onSearch = useCallback(
    (e) => {
      setSearchTerm(e.currentTarget.value);
    },
    [setSearchTerm]
  );

  useEffect(() => {
    if (isSearching) document.getElementById(`Search ${category}-input`)?.focus();
  }, [isSearching, category]);

  const onClickObr = () => {
    if (!hasObrConnected && !isPatron) {
      setShowPatronModal(true);
      return;
    }

    const isHost = room.userId === currentUser.id;

    if (!hasObrConnected) {
      if (!isHost) {
        alert('Only the host can connect Owlbear Rodeo to the room.');
        return;
      }

      const url = new URL(OBR_ORIGIN);
      url.pathname = '/embed/access';
      url.searchParams.append('client_id', OBR_CLIENT_ID);
      const width = 450;
      const height = 650;
      const left = window.screenX + window.outerWidth / 2 - width / 2;
      const top = window.screenY + window.outerHeight / 2 - height / 2;
      window.open(
        url.href,
        '_blank',
        `width=${width},height=${height},left=${left},top=${top},menubar=0,toolbar=0,location=0,personalbar=0,status=0`
      );
      return;
    }

    if (isObrActive) {
      dispatch(TableAction.setObrActive(false));
      if (isHost) presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: false });
    } else {
      dispatch(TableAction.setObrActive(true));
      dispatch(TableAction.setCurrentTab(RoomTab.Party));
      if (isHost) presenceChannel.trigger(ChannelEvent.OBR_ACTIVE, { active: true });
    }
  };

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      <div className={clsx(styles.content, !previewRoom && styles.uploadButtonContent)}>
        <div className={styles.header}>
          <div className={styles.heading}>
            <h3 className={styles.title}>{category === 'assets' ? 'Books & Apps' : category || 'Materials'}</h3>
            {!previewRoom && (
              <div className={styles.buttons}>
                <IconButton
                  className={styles.filterButton}
                  color="rgb(var(--color-theme-text))"
                  background="rgb(var(--color-theme-button))"
                  hoverBackground="rgb(var(--color-theme-accent))"
                  activeBackground="rgb(var(--color-theme-accent))"
                  label="Filter"
                  iconSize={18}
                  onClick={() => setIsSearching(false)}
                  children={<FilterIcon />}
                  isActive={!isSearching}
                />
                <IconButton
                  className={styles.searchButton}
                  color="rgb(var(--color-theme-text))"
                  background="rgb(var(--color-theme-button))"
                  hoverBackground="rgb(var(--color-theme-accent))"
                  activeBackground="rgb(var(--color-theme-accent))"
                  label="Search"
                  iconSize={24}
                  onClick={() => setIsSearching(true)}
                  children={<SearchIcon className={styles.searchIcon} />}
                  isActive={isSearching}
                />
              </div>
            )}
          </div>
          <div className={clsx(styles.filter, !isSearching && !previewRoom && styles.active)}>
            <DropdownInput
              options={filterOptions}
              value={filter}
              onChange={setFilter}
              className={styles.select}
              variant="theme"
            />
            <IconButton
              className={styles.orderButton}
              color="rgb(var(--color-theme-text))"
              background="rgb(var(--color-theme-button))"
              hoverBackground="rgb(var(--color-theme-accent))"
              label={order ? 'Sort Descending' : 'Sort Ascending'}
              iconSize={18}
              onClick={() => setOrder(!order)}
            >
              {order ? <SortDown /> : <SortUp />}
            </IconButton>
          </div>
          <div className={clsx(styles.search, isSearching && !previewRoom && styles.active)}>
            <SearchIcon className={styles.searchIcon} width={24} />
            <TextInput
              input={{ value: searchTerm }}
              meta={{}}
              type="text"
              placeholder={`Search ${category === 'assets' ? 'books & apps' : category || 'materials'}`}
              onChange={onSearch}
              className={styles.searchField}
              variant="theme"
            />
            <IconButton
              className={clsx(styles.clearButton, searchTerm.length === 0 && styles.hidden)}
              color="rgb(var(--color-theme-text))"
              background="transparent"
              hoverBackground="transparent"
              activeBackground="transparent"
              activeHover="transparent"
              label="Clear"
              iconSize={12}
              onClick={() => setSearchTerm('')}
              children={<XIcon />}
            />
          </div>
        </div>

        {roomDocuments.length > 0 && (
          <div
            className={clsx(
              'scrollbars-dark',
              styles.assets,
              category === 'assets' && styles.longAssets,
              filter === currentGame?.title && !isSearching
                ? styles.filterAssetsGame
                : styles[`filterAssets${capitalize(filter)}`],
              isSearching && styles.searchResults
            )}
          >
            <div className={clsx(styles.empty, (!isSearching || searchResults.length > 0) && styles.hidden)}>
              <h4 className={styles.emptyHeader}>No results for "{searchTerm}"</h4>
              <p className={styles.emptyBody}>Try searching for something else</p>
            </div>
            {roomDocuments.map((roomDocument) =>
              previewRoom ? (
                <PreviewRoomDocument
                  key={roomDocument.id}
                  previewDocument={roomDocument}
                  className={clsx(styles.asset, category === 'tokens' && styles.isToken)}
                  style={{ order: searchResults.indexOf(roomDocument.id) }}
                  theme={theme}
                />
              ) : (
                <RoomDocument
                  key={roomDocument.guidId}
                  roomDocument={roomDocument}
                  roomGuid={roomGuid}
                  className={clsx(
                    styles.asset,
                    searchResults.includes(roomDocument.id) && isSearching && styles.searchResult,
                    category === 'tokens' && styles.isToken,
                    category === 'audio' && styles.isAudio,
                    !isSearching &&
                      (roomDocument.gameIds.includes(currentGame?.id)
                        ? styles.isGame
                        : roomDocument.userId === currentUser.id
                        ? styles.isMine
                        : styles.isOthers)
                  )}
                  style={{ order: searchResults.indexOf(roomDocument.id) }}
                  theme={theme}
                />
              )
            )}
          </div>
        )}
        {!previewRoom && (
          <div className={styles.actions}>
            <Button
              primaryBackground={colors[0]}
              secondaryBackground={colors[1]}
              className={styles.uploadButton}
              icon={<PlusIcon />}
              onClick={onUploadAssetClick}
            >
              Add New {categoryToButtonLabel(category)}
            </Button>
            {!category && (
              <IconButton
                onClick={onClickObr}
                children={<ObrIcon />}
                buttonSize={42}
                iconSize={26}
                label="Owlbear Rodeo"
                borderRadius={!isObrActive ? 40 : 14}
                background="rgb(var(--color-theme-button))"
                hoverBackground="var(--color-orange)"
                activeBackground="var(--color-orange)"
                activeHover="rgb(var(--color-theme-accent))"
                activeLabel="Close Owlbear Rodeo"
                activeColor="var(--color-white)"
                color={isObrActive ? 'rgb(var(--color-theme-accent)))' : 'rgb(var(--color-theme-text))'}
                isActive={isObrActive}
                className={styles.obrButton}
              />
            )}
          </div>
        )}

        {!isLoading && showEmpty && (
          <div className={styles.empty}>
            <h4 className={styles.emptyHeader}>{emptyHeader}</h4>
            <p className={styles.emptyBody}>{emptyBody}</p>
          </div>
        )}

        {isLoading && <LoadingSpinner color={SPINNER_COLOR} />}
      </div>
    </div>
  );
}

function categoryToButtonLabel(category: string) {
  switch (category) {
    case 'assets':
      return 'Book/App';
    case 'audio':
      return 'Audio';
    default:
      return category?.slice(0, -1) || 'Asset';
  }
}

export default AssetTray;
