import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

export interface ModalProps {
  className?: string;
  children: ReactNode;
  onDismiss?: () => void;
}

export default function Modal({ className, children, onDismiss }: ModalProps) {
  const portalRef = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    portalRef.current = document.querySelector<HTMLElement>('#modal');
    document.body.style.overflow = 'rui-hidden';
    setMounted(true);

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return mounted && portalRef.current
    ? createPortal(
        <div className="rui-flex rui-items-center rui-justify-center rui-fixed rui-inset-0 rui-bg-black/80 rui-z-[9999]">
          <div
            className={clsx(
              'rui-w-full rui-max-h-[90%] rui-flex rui-relative rui-bg-role-dark-200 rui-rounded-2xl rui-border-2 rui-border-black/5',
              className
            )}
          >
            <div className="rui-w-full rui-h-[200px] rui-absolute rui-z-0 rui-rounded-tl-inherit rui-rounded-tr-inherit rui-overflow-hidden">
              <div className="rui-w-full rui-h-full rui-bg-role-green-city rui-bg-cover rui-bg-[center_75%]" />
              <div className="rui-w-full rui-h-full rui-absolute rui-top-0 rui-left-0 rui-bg-gradient-to-b rui-from-role-dark-200/70 rui-to-role-dark-200" />
            </div>
            <button
              className={clsx(
                'button-reset',
                'rui-w-8 rui-h-8 rui-flex rui-items-center rui-justify-center rui-absolute rui-top-5 rui-right-5 rui-z-20 rui-rounded-lg rui-bg-role-dark-300/30 rui-transition-colors',
                'hover:rui-bg-role-dark-300'
              )}
              onClick={onDismiss}
            >
              <XIcon className="rui-w-[10px] rui-h-[10px] rui-fill-role-light-100" />
            </button>
            <div
              className={clsx(
                'rui-w-full rui-pt-10 rui-pb-8 rui-px-2 rui-flex rui-flex-col rui-relative rui-z-10 rui-rounded-inherit',
                'sm:rui-px-10'
              )}
            >
              {children}
            </div>
          </div>
        </div>,
        portalRef.current
      )
    : null;
}

Modal.Header = function ModalHeader({ children, className }: { children: ReactNode; className?: string }) {
  return <header className={clsx('rui-mb-5 rui-text-center', className)}>{children}</header>;
};

Modal.Title = function ModalTitle({
  children,
  subtitle,
  className,
}: {
  children: ReactNode;
  subtitle?: string;
  className?: string;
}) {
  return (
    <div className={clsx(className)}>
      {subtitle && (
        <div className="rui-text-role-light-200 rui-text-sm rui-font-bold rui-uppercase rui-tracking-widest rui-indent-tracking-widest">
          {subtitle}
        </div>
      )}
      <h2 className="rui-text-2xl rui-font-title rui-font-bold rui-tracking-widest rui-indent-tracking-widest">
        {children}
      </h2>
    </div>
  );
};

Modal.Icon = function ModalIcon({
  children,
  width = 20,
  height = 20,
  offset,
  className,
}: {
  children: ReactNode;
  width?: number;
  height?: number;
  offset?: number;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'rui-w-[50px] rui-h-[50px] rui-mx-auto rui-mb-4 rui-flex rui-items-center rui-justify-center rui-bg-role-dark-300 rui-rounded-full',
        className
      )}
    >
      <div
        className="[&_svg]:rui-w-full [&_svg]:rui-h-full [&_path]:rui-fill-role-light-200"
        style={{ width, height, marginTop: offset }}
      >
        {children}
      </div>
    </div>
  );
};

Modal.Body = function ModalBody({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <div className={clsx('rui-flex rui-flex-col rui-overflow-y-auto rui-scrollbars-dark', className)}>{children}</div>
  );
};

Modal.Actions = function ModalActions({ children, className }: { children: ReactNode; className?: string }) {
  return <footer className={clsx('rui-mt-12 rui-flex rui-justify-center rui-gap-3', className)}>{children}</footer>;
};
