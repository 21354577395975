import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from 'browserHistory';
import { RoomAction, RoomUserAction } from 'store/actions';
import { RoomUserSelector, SessionSelector } from 'store/selectors';

import Button from 'components/buttons/Button';
import TableCard from 'components/cards/TableCard';
import { TitleModal } from 'components/Modal';

class RemoveTableModal extends Component {
  componentDidMount() {
    const {
      room: { guid },
      fetchUsers,
    } = this.props;
    fetchUsers(guid);
  }

  onRemoveClick = () => {
    const {
      currentUser,
      room: { guid, userId },
      deleteRoom,
      leaveRoom,
      fetchTable,
      onDismiss,
    } = this.props;
    history.push('/rooms');
    userId === currentUser.id
      ? deleteRoom(guid, () => {
          onDismiss();
        })
      : leaveRoom(guid, () => {
          fetchTable(guid);
          onDismiss();
        });
  };

  render() {
    const { currentUser, room, users, onDismiss } = this.props;
    const isOwned = room.userId === currentUser.id;
    const title = isOwned ? 'Delete This Room?' : 'Leave This Room?';
    const subtitle = isOwned
      ? 'This will permanently delete the room for you and all players who have joined it. Sheets and assets will remain saved to each person’s account.'
      : 'This will remove your sheets and assets from the room. This does not delete the room for other players.';
    const buttonCopy = isOwned ? 'Delete' : 'Leave';

    return (
      <TitleModal
        title={title}
        subtitle={subtitle}
        actions={
          <>
            <Button variant="cancel" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.onRemoveClick}>
              {buttonCopy}
            </Button>
          </>
        }
        onDismiss={onDismiss}
      >
        <div style={{ padding: '0 10px' }}>
          <TableCard room={room} users={users} />
        </div>
      </TitleModal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { room } = props;
  const getAllByRoom = RoomUserSelector.getAllByRoom(room.guid);

  return {
    currentUser: SessionSelector.currentUser(state),
    users: getAllByRoom(state),
  };
};

const mapDispatchToProps = {
  deleteRoom: RoomAction.delete,
  fetchTable: RoomAction.fetch,
  fetchUsers: RoomUserAction.fetchAll,
  leaveRoom: RoomAction.leave,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveTableModal);
