import styles from './PatronFeatureModal.module.css';

import Modal from './Modal';
import { ReactComponent as ObrIcon } from 'images/icons/ObrIcon.svg';

export default function ObrRoomInUseModal({ onDismiss }: { onDismiss?: () => void }) {
  return (
    <Modal className={styles.container} onDismiss={onDismiss}>
      <Modal.Header>
        <Modal.Icon width={25} height={25}>
          <ObrIcon />
        </Modal.Icon>
        <Modal.Title>Owlbear Rodeo room already in use</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <div>
          <p>The Owlbear Rodeo room you are attempting to use is already associated with a different Role game room.</p>
          <p>
            To use this OBR room here, first go to your other Role room and disconnect it from the Room Settings menu.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
