// @flow
import styles from './Stepper.module.css';

import React, { useRef } from 'react';
import type { Node } from 'react';

import { ReactComponent as MinusIcon } from 'images/icons/MinusIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';

type Props = {
  canEditCurrent: boolean,
  canEditMax: boolean,
  className?: ?string,
  isCompact: boolean,
  minValue: number,
  onChange: (string[]) => void,
  onCurrentBlur?: ?() => void,
  onCurrentFocus?: ?() => void,
  readOnly: boolean,
  values: string[],
};

const REGEX = /^\d*$/;

function Stepper(props: Props): Node {
  const {
    canEditCurrent,
    canEditMax,
    className,
    isCompact,
    minValue,
    onChange,
    onCurrentBlur: propOnCurrentBlur,
    onCurrentFocus,
    readOnly,
    values,
  } = props;

  const currentInputRef = useRef(null);
  const maxInputRef = useRef(null);

  const hasMax = values.length === 2;
  const parsedCurrentValue = parseInt(values[0]);
  const parsedMaxValue = hasMax ? parseInt(values[1]) : -1;
  const hasCurrentValue = !isNaN(parsedCurrentValue);
  const hasMaxValue = hasMax && !isNaN(parsedMaxValue);
  const isMinimum = hasCurrentValue && parsedCurrentValue <= minValue;
  const isMaximum = hasMaxValue && parsedCurrentValue >= parsedMaxValue;

  const classNames = [styles.container];
  if (isCompact) classNames.push(styles.isCompact);
  if (className) classNames.push(className);

  const onAddClick = (event) => {
    let num = 0;
    if (hasMaxValue) {
      if (hasCurrentValue) {
        if (parsedCurrentValue < parsedMaxValue) num = parsedCurrentValue + 1;
        else num = parsedMaxValue;
      } else {
        num = minValue;
      }
    } else {
      num = hasCurrentValue ? parsedCurrentValue + 1 : minValue;
    }
    const updatedValues = [`${num}`];
    if (hasMax) updatedValues.push(values[1]);
    onChange(updatedValues);
  };

  const onCurrentBlur = (event) => {
    if (event.currentTarget.value.match(REGEX)) {
      const updatedValues = [event.currentTarget.value || `${minValue}`];
      if (hasMax) updatedValues.push(values[1]);
      const parsed = parseInt(updatedValues[0]);
      if (hasMaxValue && parsed > parsedMaxValue) updatedValues[0] = `${parsedMaxValue}`;
      if (parsed < minValue) updatedValues[0] = `${minValue}`;
      onChange(updatedValues);
    }
    if (propOnCurrentBlur) propOnCurrentBlur();
  };

  const onCurrentChange = (event) => {
    if (!event.currentTarget.value.match(REGEX)) return;
    const updatedValues = [event.currentTarget.value];
    if (hasMax) updatedValues.push(values[1]);
    onChange(updatedValues);
  };

  const onCurrentKeyDown = (event) => {
    if (event.keyCode === 13) currentInputRef.current.blur();
  };

  const onMaxBlur = (event) => {
    if (!event.currentTarget.value.match(REGEX)) return;
    const updatedValues = [values[0], event.currentTarget.value || '0'];
    const parsed = parseInt(updatedValues[1]);
    if (parsed < parsedCurrentValue) updatedValues[0] = `${parsed}`;
    onChange(updatedValues);
  };

  const onMaxChange = (event) => {
    if (!event.currentTarget.value.match(REGEX)) return;
    const updatedValues = [values[0], event.currentTarget.value];
    onChange(updatedValues);
  };

  const onMaxKeyDown = (event) => {
    if (event.keyCode === 13) maxInputRef.current.blur();
  };

  const onSubtractClick = (event) => {
    const num = !hasCurrentValue ? minValue : parsedCurrentValue > minValue ? parsedCurrentValue - 1 : minValue;
    const updatedValues = [`${num}`];
    if (hasMax) updatedValues.push(values[1]);
    onChange(updatedValues);
  };

  return (
    <div className={classNames.join(' ')}>
      <button
        className={`button-reset ${styles.subtractButton}`}
        disabled={!canEditCurrent || readOnly || isMinimum}
        onClick={onSubtractClick}
      >
        <MinusIcon />
      </button>
      <input
        ref={currentInputRef}
        className={styles.input}
        type="text"
        value={values[0]}
        readOnly={readOnly}
        disabled={!canEditCurrent}
        onBlur={onCurrentBlur}
        onFocus={onCurrentFocus}
        onChange={onCurrentChange}
        onKeyDown={onCurrentKeyDown}
      />
      {hasMax && (
        <>
          <span className={styles.slash}>/</span>
          <input
            ref={maxInputRef}
            className={`${styles.input} ${!canEditMax ? styles.isLocked : ''}`}
            type="text"
            value={values[1]}
            readOnly={readOnly}
            disabled={!canEditMax}
            onBlur={onMaxBlur}
            onChange={onMaxChange}
            onKeyDown={onMaxKeyDown}
          />
        </>
      )}
      <button
        className={`button-reset ${styles.addButton}`}
        disabled={!canEditCurrent || readOnly || isMaximum}
        onClick={onAddClick}
      >
        <PlusIcon />
      </button>
    </div>
  );
}

Stepper.defaultProps = {
  canEditCurrent: false,
  canEditMax: false,
  isCompact: false,
  minValue: 0,
  readOnly: false,
};

export default Stepper;
