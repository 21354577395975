// @flow
import type { Node } from 'react';
import React, { memo, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import type { ElementModel, ElementProps, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';
import useModifierDice from 'hooks/useModifierDice';
import useModifierIsSelected from 'hooks/useModifierIsSelected';
import useModifierLinks from 'hooks/useModifierLinks';
import useModifierPusher from 'hooks/useModifierPusher';
import { ChannelEvent } from 'models/Channel';
import Die from 'models/Die';
import { RollAction, TableAction } from 'store/actions';
import { DocumentSelector, RollSelector, RoomSheetSelector, TableSelector } from 'store/selectors';

import DicePool from './DicePool';
import Element from 'components/Sheet2/elements/Element';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function DicePoolElement(props: Props): Node {
  const { colors, element, onChange, readOnly, roomGuid, sectionId, sheetGuid, isDraft } = props;
  const { id, items, links } = element;

  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));
  const customDice = useSelector((state) =>
    isDraft
      ? DocumentSelector.getCustomDiceByTemplate(state, sheetGuid)
      : DocumentSelector.getCustomDiceBySheet(state, sheetGuid)
  ).map((doc) => new Die({ name: doc.name, sides: doc.metadata.sides, type: doc.metadata.types }));

  const diceCountInput = items.find((o) => o.inputType === INPUT_TYPE.diceCount);
  const diceSidesInput = items.find((o) => o.inputType === INPUT_TYPE.diceSides);
  const diceTypeInput = items.find((o) => o.inputType === INPUT_TYPE.diceType);
  const diceColorInput = items.find((o) => o.inputType === INPUT_TYPE.diceColor);

  const diceCountValue = String(diceCountInput?.value ?? diceCountInput?.defaultValue ?? '');
  const diceSidesValue = Number(diceSidesInput?.value ?? (diceSidesInput?.defaultValue || '20'));
  const diceTypeValue = String(diceTypeInput?.value ?? diceTypeInput?.defaultValue ?? '');
  const diceColorValue = String(diceColorInput?.value ?? diceColorInput?.defaultValue ?? '');

  const dispatch = useDispatch();
  const modifier = useSelector(
    (state) => diceSidesInput && RollSelector.getModifierBySheetAndInput(state, sheetGuid, id)
  );
  const isSelected = useModifierIsSelected(modifier);
  useModifierPusher(modifier);
  useModifierDice(modifier);
  useModifierLinks(sheetGuid, modifier, links);

  useEffect(() => {
    const count = parseInt(diceCountValue);
    if (!modifier || isNaN(count)) return;

    if (
      modifier.count !== count ||
      modifier.value !== diceSidesValue ||
      modifier.diceType !== diceTypeValue ||
      modifier.color !== diceColorValue
    )
      dispatch(
        RollAction.updateModifier({
          ...modifier,
          count,
          value: diceSidesValue,
          diceType: diceTypeValue,
          color: diceColorValue,
        })
      );
  }, [diceColorValue, diceCountValue, diceSidesInput, diceSidesValue, diceTypeValue, dispatch, modifier]);

  const onCountChange = (value) => {
    if (!diceCountInput) return;
    const updatedItems = items.map((o) => (o.id === diceCountInput.id ? { ...diceCountInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onCountBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onCountFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onClick = (isSelected) => {
    if (!modifier) return;
    batch(() => {
      dispatch(RollAction.updateModifier({ ...modifier, isSelected }));
      dispatch(TableAction.setPlayPanelContent('Dice'));
    });
  };

  const onDieChange = (sides, type) => {
    if (!diceSidesInput || !diceTypeInput) return;
    const updatedItems = items.map((o) => {
      if (o.id === diceSidesInput.id) return { ...diceSidesInput, value: sides };
      else if (o.id === diceTypeInput.id) return { ...diceTypeInput, value: type };
      else return o;
    });
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onDieColorChange = (color: string) => {
    if (!diceColorInput) return;
    const updatedItems = items.map((o) => (o.id === diceColorInput.id ? { ...o, value: color } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  return (
    <Element {...props}>
      {diceCountInput && diceSidesInput && diceTypeInput && (
        <DicePool
          diceCount={diceCountValue}
          diceSides={diceSidesValue}
          diceType={diceTypeValue}
          diceColor={diceColorValue}
          customDice={customDice}
          canEditColor={diceColorInput?.canEdit}
          canEditCount={diceCountInput.canEdit}
          canEditDie={diceSidesInput.canEdit}
          readOnly={readOnly}
          isSelected={isSelected}
          primaryColor={colors[0]}
          secondaryColor={colors[1]}
          onClick={onClick}
          onCountBlur={onCountBlur}
          onCountFocus={onCountFocus}
          onCountChange={onCountChange}
          onDieChange={onDieChange}
          onColorChange={onDieColorChange}
        />
      )}
    </Element>
  );
}

export default memo(DicePoolElement);
