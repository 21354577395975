import styles from './TableBar.module.css';

import clsx from 'clsx';
import { ChangeEvent, useCallback } from 'react';

import { DICE_COLOR_LABELS, DICE_COLORS } from 'models/Color';

interface DiceColorPickerProps {
  id?: string;
  className?: string;
  defaultValue?: string;
  onChange?(color: string): void;
  size?: number;
}

export default function DiceColorPicker({
  id = 'dice-tray',
  className,
  defaultValue,
  onChange: propsOnChange,
  size,
}: DiceColorPickerProps) {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      propsOnChange?.(event.currentTarget.value);
    },
    [propsOnChange]
  );

  return (
    <fieldset className={clsx(styles.colorSwatch, className)}>
      {DICE_COLORS.map((color, i) => (
        <input
          key={i}
          style={{ backgroundColor: color || 'rgb(var(--color-theme-text))', width: size, height: size }}
          name={`${id}-dice-color`}
          title={DICE_COLOR_LABELS[color]}
          aria-label={DICE_COLOR_LABELS[color]}
          type="radio"
          value={color}
          checked={color === defaultValue}
          onChange={onChange}
        />
      ))}
    </fieldset>
  );
}
