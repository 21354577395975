// @flow
import React, { memo } from 'react';
import type { Node } from 'react';

import { INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';

import Element from 'components/Sheet2/elements/Element';
import Dropdown from './Dropdown';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function DropdownElement(props: Props): Node {
  const { element, onChange, readOnly, sectionId, sheetGuid } = props;
  const { items, metadata } = element;

  const dropdownInput = items.find((o) => o.inputType === INPUT_TYPE.dropdown);
  const value: string = String(dropdownInput?.value ?? dropdownInput?.defaultValue ?? '');
  const options = metadata.options ?? [];

  const onDropdownChange = (value) => {
    if (!dropdownInput) return;
    const updatedItems = items.map((o) => (o.id === dropdownInput.id ? { ...dropdownInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  return (
    <Element {...props}>
      {dropdownInput && <Dropdown value={value} options={options} readOnly={readOnly} onChange={onDropdownChange} />}
    </Element>
  );
}

export default memo(DropdownElement);
