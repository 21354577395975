import styles from './Tag.module.css';

import clsx from 'clsx';
import { CSSProperties, HTMLAttributeAnchorTarget, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface TagProps {
  backgroundColor?: CSSProperties['backgroundColor'];
  className?: string;
  color?: CSSProperties['color'];
  title?: string;
  to?: string;
  target?: HTMLAttributeAnchorTarget;
  children?: ReactNode;
}

export default function Tag({ backgroundColor, className, color, title, to, target, children }: TagProps) {
  const TagBody = useCallback(
    () => (
      <span className={clsx(styles.tag, className)} title={title}>
        <span className={styles.tagTitle} style={{ color, backgroundColor }}>
          {children || title}
        </span>
      </span>
    ),
    [backgroundColor, className, color, title, children]
  );

  if (to)
    return (
      <Link className={styles.link} to={to} target={target}>
        <TagBody />
      </Link>
    );

  return <TagBody />;
}
