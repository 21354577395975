// @flow
import type { Node } from 'react';
import type { BuilderProps, ElementProps } from 'components/Sheet2/types';

import BuffElement from './BuffElement';
import ClockElement from './ClockElement';
import CounterElement from './CounterElement';
import DicePoolElement from './DicePoolElement';
import DividerElement from './DividerElement';
import DropdownElement from './DropdownElement';
import FieldElement from './FieldElement';
import FieldBuffElement from './FieldBuffElement';
import GroupElement from './GroupElement';
import ImageElement from './ImageElement';
import NoteElement from './NoteElement';
import ReferenceElement from './ReferenceElement';
import SlotsElement from './SlotsElement';
import TextElement from './TextElement';

import { BuffBuilder } from './BuffElement';
import { ClockBuilder } from './ClockElement';
import { CounterBuilder } from './CounterElement';
import { DicePoolBuilder } from './DicePoolElement';
import { DividerBuilder } from './DividerElement';
import { DropdownBuilder } from './DropdownElement';
import { FieldBuilder } from './FieldElement';
import { FieldBuffBuilder } from './FieldBuffElement';
import { GroupBuilder } from './GroupElement';
import { ImageBuilder } from './ImageElement';
import { NoteBuilder } from './NoteElement';
import { ReferenceBuilder } from './ReferenceElement';
import { SlotsBuilder } from './SlotsElement';
import { TextBuilder } from './TextElement';

const ElementComponents: { [string]: (props: ElementProps) => Node } = {
  buff: BuffElement,
  clock: ClockElement,
  counter: CounterElement,
  'dice-pool': DicePoolElement,
  divider: DividerElement,
  dropdown: DropdownElement,
  field: FieldElement,
  'field-buff': FieldBuffElement,
  group: GroupElement,
  image: ImageElement,
  note: NoteElement,
  reference: ReferenceElement,
  slots: SlotsElement,
  text: TextElement,
};

export default ElementComponents;

const BuilderComponents: { [string]: (props: BuilderProps) => Node } = {
  buff: BuffBuilder,
  clock: ClockBuilder,
  counter: CounterBuilder,
  'dice-pool': DicePoolBuilder,
  divider: DividerBuilder,
  dropdown: DropdownBuilder,
  field: FieldBuilder,
  'field-buff': FieldBuffBuilder,
  group: GroupBuilder,
  image: ImageBuilder,
  note: NoteBuilder,
  reference: ReferenceBuilder,
  slots: SlotsBuilder,
  text: TextBuilder,
};

export { BuilderComponents };
