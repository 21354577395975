// @flow
import styles from './AdvancedOptionsPanel.module.css';

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import type { InputType, OnChangeElement, OnChangeGroupElement } from 'components/Sheet2/types';
import { ELEMENT_TYPE, INPUT_TYPE, LIBRARY, LINK_ACTION_TYPE, LINK_TYPE } from 'constants/sheet';
import { DICE_COLOR_LABELS } from 'models/Color';
import Die, { DICE, DieType } from 'models/Die';
import { DocumentSelector, SheetBuilderSelector } from 'store/selectors';
import { removeItem } from 'utilities';
import { generateCounterMaxInput, generateToggleInput } from 'utilities/sheet';

import Button from 'components/buttons/Button';
import { CloseButton } from 'components/buttons/IconButton';
import Switch from 'components/Switch';
import { ReactComponent as DuplicateIcon } from 'images/icons/DuplicateIcon.svg';
import { ReactComponent as LinkIcon } from 'images/icons/LinkIcon.svg';
import { ReactComponent as LockedIcon } from 'images/icons/LockedIcon.svg';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';

type AdvancedOptionsPanelProps = {
  elementId: string,
  groupId?: string,
  onClose: () => void,
  onChangeElement: OnChangeElement,
  onChangeGroupElement: OnChangeGroupElement,
  renderLinkModal: (content?: {}) => void,
  sectionId: string,
  sheetGuid: string,
};

const optionsList = {
  toggle: [
    ELEMENT_TYPE.buff,
    ELEMENT_TYPE.clock,
    ELEMENT_TYPE.counter,
    ELEMENT_TYPE.dicePool,
    ELEMENT_TYPE.divider,
    ELEMENT_TYPE.dropdown,
    ELEMENT_TYPE.field,
    ELEMENT_TYPE.fieldBuff,
    ELEMENT_TYPE.image,
    ELEMENT_TYPE.note,
    ELEMENT_TYPE.reference,
    ELEMENT_TYPE.slots,
    ELEMENT_TYPE.text,
  ],
  gameplayLink: [ELEMENT_TYPE.buff, ELEMENT_TYPE.fieldBuff, ELEMENT_TYPE.dicePool, ELEMENT_TYPE.reference],
  lock: [
    ELEMENT_TYPE.buff, // lock input.buff
    ELEMENT_TYPE.clock, // lock element
    ELEMENT_TYPE.counter, // lock input.counterMax
    ELEMENT_TYPE.dicePool, // lock input.diceSides || lock input.diceCount
    ELEMENT_TYPE.fieldBuff, // lock input.buff
    ELEMENT_TYPE.reference, // lock input.reference
    ELEMENT_TYPE.slots, // lock element
  ],
  duplication: [ELEMENT_TYPE.group],
  maxPointerCounter: [ELEMENT_TYPE.counter],
};

/**
TODO:
- [BUG] when deleting a toggle on one element / another element, need to update links on other connected elements
  (perhaps bi-directional links would solve for this); below, we will currently check if the inputId is still in 
  the toggles list and show if it is. it still has to be deleted.
 */

function AdvancedOptionsPanel({
  elementId,
  groupId,
  onClose,
  onChangeElement,
  onChangeGroupElement,
  renderLinkModal,
  sectionId,
  sheetGuid,
}: AdvancedOptionsPanelProps) {
  const element = useSelector((state) =>
    SheetBuilderSelector.elementSelector(state, { modelId: sheetGuid, sectionId, elementId, groupId })
  );
  const customDice = useSelector((state) => DocumentSelector.getCustomDiceByTemplate(state, sheetGuid)).map(
    (doc) => new Die({ name: doc.name, sides: doc.metadata.sides, type: doc.metadata.types })
  );
  const linkNames = useSelector((state) => SheetBuilderSelector.linkNamesSelector(state, { modelId: sheetGuid }));
  const { elementType, items, links } = element;
  const libraryItem = LIBRARY[elementType];
  const toggleInput = items.find((item) => item.inputType === INPUT_TYPE.toggle);
  const counterMaxInput = items.find((item) => item.inputType === INPUT_TYPE.counterMax);

  // showFlags
  const showToggle = optionsList.toggle.includes(elementType);
  const showGameplayLink = optionsList.gameplayLink.includes(elementType);
  let showLock = optionsList.lock.includes(elementType);
  if (elementType === ELEMENT_TYPE.counter && !items.find((i) => i.inputType === INPUT_TYPE.counterMax))
    showLock = false;
  let poolCount,
    poolDie,
    poolColor = false;
  if (elementType === ELEMENT_TYPE.dicePool) {
    poolCount = !items.find((i) => i.inputType === INPUT_TYPE.diceCount)?.canEdit;
    poolDie = !items.find((i) => i.inputType === INPUT_TYPE.diceSides)?.canEdit;
    poolColor = !items.find((i) => i.inputType === INPUT_TYPE.diceColor)?.canEdit;
  }
  const showDuplication = optionsList.duplication.includes(elementType);
  const showMaxPointerCounter = optionsList.maxPointerCounter.includes(elementType);

  const getDiceLinkName = (dice) => {
    const die =
      DICE.find((o) => o.sides === parseInt(dice.sides) && o.type === dice.type) ||
      customDice.find((o) => o.sides === parseInt(dice.sides) && o.type === dice.type);
    return `${dice.color ? DICE_COLOR_LABELS[dice.color] + ' ' : ''}${die.name} x ${dice.count}`;
  };

  // This will delete the toggle input and should delete all toggle links
  // TODO (danny): prompt confirm with user since this should also remove toggle links
  const onAddToggleChange = (isOn: boolean) => {
    let updatedItems;
    if (isOn) {
      const newToggleInput = { ...generateToggleInput() };
      updatedItems = [...items, newToggleInput];
    } else {
      const toggleInputIndex = items.findIndex((item) => item.inputType === INPUT_TYPE.toggle);
      updatedItems = removeItem(items, toggleInputIndex);
    }
    const updatedElement = { ...element, items: updatedItems };
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
  };

  const onLockChange = (isOn: boolean, type?: InputType) => {
    let updatedElement;
    if (type) {
      const updatedInput = { ...items.find((i) => i.inputType === type), canEdit: !isOn };
      const updatedItems = items.map((i) => (i.id === updatedInput.id ? updatedInput : i));
      updatedElement = { ...element, items: updatedItems };
    } else {
      updatedElement = { ...element, canEdit: !isOn };
    }
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
  };

  const onDuplicationChange = (isOn: boolean) => {
    const updatedMetadata = { ...element.metadata, collection: isOn ? [] : null };
    const updatedElement = { ...element, metadata: updatedMetadata };
    onChangeElement(sheetGuid, sectionId, updatedElement);
  };

  const onMaxPointCounterChange = (isOn: boolean) => {
    let updatedItems;
    if (isOn) {
      const currentInput = items.find((i) => i.inputType === INPUT_TYPE.counterCurrent);
      const maxInput = {
        ...generateCounterMaxInput(),
        defaultValue: currentInput.defaultValue ?? '1',
      };
      updatedItems = [...items, maxInput];
    } else {
      const maxInputIndex = items.findIndex((item) => item.inputType === INPUT_TYPE.counterMax);
      updatedItems = removeItem(items, maxInputIndex);
    }
    const updatedElement = { ...element, items: updatedItems };
    if (groupId) onChangeGroupElement(sheetGuid, sectionId, groupId, updatedElement);
    else onChangeElement(sheetGuid, sectionId, updatedElement);
  };

  const renderLockSwitch = () => {
    let activeState = !element.canEdit;
    let lockChangeHandler = onLockChange;

    switch (elementType) {
      case ELEMENT_TYPE.buff: // lock input.buff
      case ELEMENT_TYPE.fieldBuff: // lock input.buff
        const buffInput = items.find((i) => i.inputType === INPUT_TYPE.buff);
        activeState = !buffInput.canEdit;
        lockChangeHandler = (isOn) => onLockChange(isOn, INPUT_TYPE.buff);
        break;
      case ELEMENT_TYPE.counter: // lock input.counterMax
        const counterMaxInput = items.find((i) => i.inputType === INPUT_TYPE.counterMax);
        activeState = !counterMaxInput.canEdit;
        lockChangeHandler = (isOn) => onLockChange(isOn, INPUT_TYPE.counterMax);
        break;
      case ELEMENT_TYPE.reference: // lock input.reference
        const referenceInput = items.find((i) => i.inputType === INPUT_TYPE.reference);
        activeState = !referenceInput.canEdit;
        lockChangeHandler = (isOn) => onLockChange(isOn, INPUT_TYPE.reference);
        break;
      case ELEMENT_TYPE.clock: // lock element
      case ELEMENT_TYPE.slots: // lock element
      default:
        break;
    }
    return <Switch className={styles.switch} isActive={activeState} onChange={lockChangeHandler} />;
  };

  return (
    <div className={styles.advancedOptionsPanel}>
      <header>
        <h4 className={styles.headerHeading}>{libraryItem.title} Options</h4>
        <div className={styles.headerDescription}>{libraryItem.description}</div>
        <CloseButton
          color="var(--color-white)"
          activeColor="var(--color-white)"
          background="rgb(var(--color-theme-button))"
          activeBackground="rgb(var(--color-theme-accent))"
          className={styles.closeButton}
          onClick={onClose}
        />
      </header>

      <div className={styles.advancedOptions}>
        {/* TOGGLES */}
        {showToggle && (
          <div className={styles.advancedOption}>
            <div className={styles.labelBar}>
              <label>
                <Switch className={styles.switch} isActive={!!toggleInput} onChange={onAddToggleChange} />
                <span className={styles.optionLabel}>Add Toggle</span>
              </label>
              <div className={styles.iconToggle} />
            </div>
            <p className={styles.description}>
              Use Toggles as check-boxes for players to mark on their sheets. Toggles can be linked to other Toggles for
              group selections!
            </p>

            {!!toggleInput && (
              <>
                <div className={styles.labelBar}>
                  <span className={styles.optionLabel}>Add Toggle Link</span>
                  <LinkIcon />
                </div>
                <p className={styles.description}>
                  Connect this Toggle to other Toggles on this sheet. When a player selects this Toggle, all linked
                  Toggles will be selected as well. Use this to create connected / automated actions across your
                  character sheets. Note: Toggles can ONLY be linked to other Toggles.
                </p>
                <div className={styles.linksArea}>
                  {links.map((link, i) => {
                    if (link.inputId && link.elementId && !link.dice) {
                      return (
                        <Fragment key={i}>
                          <span className={styles.linkWrapper}>
                            <span
                              className={styles.link}
                              onClick={() =>
                                renderLinkModal({
                                  actionType: LINK_ACTION_TYPE.edit,
                                  link: link,
                                  linkIndex: i,
                                  linkType: LINK_TYPE.toggle,
                                })
                              }
                            >
                              <CloseButton
                                color="var(--color-white)"
                                showLabelOnHover={false}
                                background="rgb(var(--color-theme-button))"
                                activeBackground="rgb(var(--color-theme-accent))"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  renderLinkModal({
                                    actionType: LINK_ACTION_TYPE.remove,
                                    link: link,
                                    linkIndex: i,
                                    linkType: LINK_TYPE.toggle,
                                  });
                                }}
                              />
                              <span className={styles.linkName}>{linkNames[link.elementId]}</span>
                            </span>
                          </span>
                          <span className={styles.linkBuffer}>+</span>
                        </Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Button
                    className={styles.addButton}
                    primaryBackground="rgb(var(--color-theme-accent))"
                    color="var(--color-white)"
                    icon={<PlusIcon />}
                    onClick={() =>
                      renderLinkModal({
                        actionType: LINK_ACTION_TYPE.create,
                        linkType: LINK_TYPE.toggle,
                      })
                    }
                  >
                    Add Link
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
        {/* GAMEPLAY LINKS */}
        {showGameplayLink && (
          <div className={styles.advancedOption}>
            <div className={styles.labelBar}>
              <span className={styles.optionLabel}>Add Gameplay Link</span>
              <LinkIcon />
            </div>
            <p className={styles.description}>
              Connect this Element to other Elements on this sheet. When a player selects this Element, all linked
              Elements will be selected as well. Use this to create connected/automated actions across your character
              sheets. You can even link this Input to specific dice!
            </p>
            <div className={styles.linksArea}>
              {links.map((link, i) => {
                if ((!link.inputId && link.elementId) || link.dice) {
                  return (
                    <Fragment key={i}>
                      <span className={styles.linkWrapper}>
                        <span
                          className={styles.link}
                          onClick={() =>
                            renderLinkModal({
                              actionType: LINK_ACTION_TYPE.edit,
                              link: link,
                              linkIndex: i,
                              linkType: LINK_TYPE.gameplay,
                            })
                          }
                        >
                          <CloseButton
                            color="var(--color-white)"
                            showLabelOnHover={false}
                            background="rgb(var(--color-theme-button))"
                            activeBackground="rgb(var(--color-theme-accent))"
                            onClick={(e) => {
                              e.stopPropagation();
                              renderLinkModal({
                                actionType: LINK_ACTION_TYPE.remove,
                                link: link,
                                linkIndex: i,
                                linkType: LINK_TYPE.gameplay,
                              });
                            }}
                          />
                          <span className={styles.linkName}>
                            {link.dice ? getDiceLinkName(link.dice) : linkNames[link.elementId]}
                          </span>
                        </span>
                      </span>
                      <span className={styles.linkBuffer}>+</span>
                    </Fragment>
                  );
                } else {
                  return null;
                }
              })}
              <Button
                className={styles.addButton}
                primaryBackground="rgb(var(--color-theme-accent))"
                color="var(--color-white)"
                icon={<PlusIcon />}
                onClick={() =>
                  renderLinkModal({
                    actionType: LINK_ACTION_TYPE.create,
                    linkType: LINK_TYPE.gameplay,
                  })
                }
              >
                Add Link
              </Button>
            </div>
          </div>
        )}
        {/* LOCKS */}
        {showLock && (
          <div className={styles.advancedOption}>
            {elementType === ELEMENT_TYPE.dicePool ? (
              <>
                <div className={styles.labelBar}>
                  <span className={styles.optionLabel}>Lock Values</span>
                  <LockedIcon />
                </div>
                <div className={styles.labelBar}>
                  <label>
                    <Switch
                      className={styles.switch}
                      isActive={poolColor}
                      onChange={(isOn) => onLockChange(isOn, INPUT_TYPE.diceColor)}
                    />
                    <span className={styles.optionLabel}>Lock Dice Pool Color</span>
                  </label>
                </div>
                <p className={styles.description}>
                  If the color is locked, it cannot be edited by the player. Use this when a dice pool color is
                  consistent and should not be changed in your game.
                </p>
                <div className={styles.labelBar}>
                  <label>
                    <Switch
                      className={styles.switch}
                      isActive={poolCount}
                      onChange={(isOn) => onLockChange(isOn, INPUT_TYPE.diceCount)}
                    />
                    <span className={styles.optionLabel}>Lock Dice Pool Count</span>
                  </label>
                </div>
                <p className={styles.description}>
                  If a value is locked, it cannot be edited by the player. Use this when a stat is consistent and cannot
                  be changed in your game.
                </p>
                <div className={styles.labelBar}>
                  <label>
                    <Switch
                      className={styles.switch}
                      isActive={poolDie}
                      onChange={(isOn) => onLockChange(isOn, INPUT_TYPE.diceSides)}
                    />
                    <span className={styles.optionLabel}>Lock Dice Pool Die</span>
                  </label>
                </div>
                <p className={styles.description}>
                  If a value is locked, it cannot be edited by the player. Use this when a stat is consistent and cannot
                  be changed in your game.
                </p>
              </>
            ) : (
              <>
                <div className={styles.labelBar}>
                  <label>
                    {renderLockSwitch()}
                    <span className={styles.optionLabel}>Lock Value</span>
                  </label>
                  <LockedIcon />
                </div>
                <p className={styles.description}>
                  If a value is locked, it cannot be edited by the player. Use this when a stat is consistent and cannot
                  be changed in your game.
                </p>
              </>
            )}
          </div>
        )}
        {/* DUPLICATION */}
        {showDuplication && (
          <div className={styles.advancedOption}>
            <div className={styles.labelBar}>
              <label>
                <Switch
                  className={styles.switch}
                  isActive={!!element.metadata.collection}
                  onChange={onDuplicationChange}
                />
                <span className={styles.optionLabel}>Duplication</span>
              </label>
              <DuplicateIcon />
            </div>
            <p className={styles.description}>
              Allow players to easily duplicate this entire Group as many times as they need! Use this when players need
              to add additional elements to their sheet as they play (ex: adding inventory items, new skills, spells
              etc.)
            </p>
          </div>
        )}
        {/* MAX POINT COUNTER */}
        {showMaxPointerCounter && (
          <div className={styles.advancedOption}>
            <div className={styles.labelBar}>
              <label>
                <Switch className={styles.switch} isActive={!!counterMaxInput} onChange={onMaxPointCounterChange} />
                <span className={styles.optionLabel}>Add Max Point Counter</span>
              </label>
              <div className={styles.iconCounterMax}></div>
            </div>
            <p className={styles.description}>Add a visual indicator to track your max counter</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdvancedOptionsPanel;
