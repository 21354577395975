// @flow
import styles from './Clock.module.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import * as d3 from 'd3';
import { v4 as uuid } from 'uuid';

import EditIcon from 'components/Sheet2/atoms/EditIcon';
import Spacer from 'components/Sheet2/atoms/Spacer';
import Stepper from 'components/Sheet2/atoms/Stepper';

type Props = {
  canEdit: boolean,
  className?: ?string,
  isBuilding: boolean,
  items: boolean[],
  onChange: (number, boolean) => void,
  onCountBlur?: ?() => void,
  onCountChange: (string[]) => void,
  onCountFocus?: ?() => void,
  primaryColor: string,
  readOnly: boolean,
  secondaryColor: string,
};

const BORDER_SIZE = 3;
const MAX_SLICES = 12;

function Clock(props: Props): Node {
  const {
    canEdit,
    className,
    isBuilding,
    items,
    onChange: propOnChange,
    onCountBlur,
    onCountChange: propOnCountChange,
    onCountFocus,
    primaryColor,
    readOnly,
    secondaryColor,
  } = props;

  const contentRef = useRef(null);
  const hoverTimerRef = useRef(null);
  const [count, setCount] = useState(items.length);
  const [isEditing, setIsEditing] = useState(isBuilding);
  const [isHovering, setIsHovering] = useState(false);

  const classNames = [styles.container];
  if (isEditing) classNames.push(styles.isEditing);
  if (isHovering) classNames.push(styles.isHovering);
  if (readOnly) classNames.push(styles.readOnly);
  if (className) classNames.push(className);

  const onChange = useCallback(
    (slice) => {
      if (readOnly) return;
      propOnChange(slice.index, !slice.data.value);
    },
    [propOnChange, readOnly]
  );

  const onCountChange = (values) => {
    setCount(values[0]);
    if (!isNaN(parseInt(values[0]))) propOnCountChange(values);
  };

  useEffect(() => {
    return () => clearTimeout(hoverTimerRef.current);
  }, []);

  useEffect(() => setCount(items.length), [items.length]);

  useEffect(() => {
    if (!contentRef.current) return;
    const { clientWidth: width, clientHeight: height } = contentRef.current;
    const gradientId = uuid();
    const pieData = items.map((o) => ({ size: 100.0 / items.length, value: o }));
    d3.select(contentRef.current).select('svg').remove();
    const svg = d3.select(contentRef.current).append('svg').attr('width', width).attr('height', height);
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '50%')
      .attr('y1', '0%')
      .attr('x2', '50%')
      .attr('y2', '100%');
    gradient.append('stop').attr('offset', '0%').attr('stop-color', primaryColor);
    gradient.append('stop').attr('offset', '100%').attr('stop-color', secondaryColor);
    svg
      .append('circle')
      .attr('r', width / 2)
      .attr('cx', width / 2)
      .attr('cy', height / 2)
      .attr('fill', `url(#${gradientId})`);
    const pie = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)
      .style('stroke', 'var(--color-black)')
      .style('stroke-width', BORDER_SIZE);
    const arcGenerator = d3
      .arc()
      .innerRadius(0)
      .outerRadius(width / 2 - BORDER_SIZE / 2);
    const pieGenerator = d3.pie().value((d) => d.size)(pieData);
    pie
      .selectAll()
      .data(pieGenerator)
      .enter()
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (slice) => (slice.data.value ? 'transparent' : 'rgba(0,0,0,0.6)'))
      .on('click', (e, slice) => onChange(slice));
  }, [items, onChange, primaryColor, secondaryColor]);

  const onEditClick = () => {
    if (isEditing) {
      setIsEditing(false);
      removeHover();
    } else {
      setIsEditing(true);
    }
  };

  const onMouseEnter = () => {
    clearTimeout(hoverTimerRef.current);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    hoverTimerRef.current = setTimeout(removeHover, 500);
  };

  const removeHover = () => setIsHovering(false);

  return (
    <div className={classNames.join(' ')}>
      {((canEdit && !readOnly) || isBuilding) && isEditing && (
        <Stepper
          className={styles.stepper}
          values={[`${count}`, `${MAX_SLICES}`]}
          minValue={1}
          canEditCurrent
          isCompact
          onCurrentBlur={onCountBlur}
          onCurrentFocus={onCountFocus}
          onChange={onCountChange}
        />
      )}
      <div className={styles.clockContent} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Spacer type="clock" className={styles.spacer} primaryColor={primaryColor} secondaryColor={secondaryColor} />
        <div className={styles.clock}>
          <div ref={contentRef} className={styles.content}></div>
        </div>
        {canEdit && !readOnly && !isBuilding && (
          <EditIcon
            className={styles.editButton}
            isEditing={isEditing}
            onClick={onEditClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </div>
    </div>
  );
}

Clock.defaultProps = {
  canEdit: false,
  isBuilding: false,
  primaryColor: 'var(--color-dark-accent)',
  readOnly: false,
  secondaryColor: 'var(--color-dark-accent)',
};

export default Clock;
