// @flow
import React, { memo } from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';
import Buff from './Buff';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function BuffBuilder(props: Props) {
  const { colors, element, onChange, sectionId, sheetGuid } = props;
  const { items } = element;

  const buffInput = items.find((item) => item.inputType === INPUT_TYPE.buff);
  const buffValue = buffInput?.defaultValue ?? '';

  const onBuffChange = (value) => {
    if (!buffInput) return;
    const updatedItems = items.map((o) => (o.id === buffInput.id ? { ...buffInput, defaultValue: value } : o));
    const updatedElement = { ...element, items: updatedItems };
    onChange(sheetGuid, sectionId, updatedElement);
  };

  return (
    <Builder {...props}>
      <Buff
        value={buffValue}
        showSpacer
        canEdit={buffInput?.canEdit}
        isBuilding={true}
        primaryColor={colors[0]}
        secondaryColor={colors[1]}
        onChange={onBuffChange}
      />
    </Builder>
  );
}

const MemoizedBuffBuilder = memo(BuffBuilder);
MemoizedBuffBuilder.displayName = 'MemoizedBuffBuilder';

export default MemoizedBuffBuilder;
