import styles from './RoomLogo.module.css';

import React from 'react';
import { Link } from 'react-router-dom';

import { VERSION } from 'models/Constants';

import { ReactComponent as RoleLogoHorizontal } from 'images/RoleLogo_Horizontal.svg';

function RoomLogo() {
  return (
    <div className={styles.watermark}>
      <Link to="/dashboard">
        <RoleLogoHorizontal className={styles.logo} />
      </Link>
      <div className={styles.text}>
        Early Access
        <a
          className={styles.version}
          href="https://www.playrole.com/changelog"
          target="_blank"
          rel="noopener noreferrer"
        >
          v{VERSION}
        </a>
      </div>
    </div>
  );
}

export default RoomLogo;
