import _ from 'lodash';
import { Action, AnyAction } from 'redux';

import ErrorAction from 'store/actions/ErrorAction';

const INITIAL_STATE: Record<string, ReduxError & Action<string>> = {};

const reducer = (state = INITIAL_STATE, action: AnyAction) => {
  const { type, payload, error } = action;
  switch (type) {
    case ErrorAction.REMOVE:
      return _.omit(state, payload);
    case ErrorAction.REMOVE_ALL:
      return INITIAL_STATE;
    default:
      if (error) return { ...state, [type]: { type, ...payload } };
      return state;
  }
};

export default reducer;
