import styles from './Panel.module.css'

import React, { Component } from 'react'

class Panel extends Component {
  render() {
    const { children, className, contentClassName } = this.props
    return (
      <div className={`box-shadow ${styles.container} ${className || ''}`}>
        <div className={styles.gradient} />
        <div className={`${styles.content} ${contentClassName || ''}`}>{children}</div>
      </div>
    )
  }
}

export default Panel
