import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import TitleBar from 'components/layouts/TitleBar';
import Metatags from 'components/Metatags';
import Panel from 'components/Panel';

class NotFoundPage extends Component {
  render() {
    return (
      <MainLayout navChildren={<TitleBar title="Whoops! 404 Page Not Found" />}>
        <Metatags title="404 Page Not Found" />
        <div style={{ maxWidth: 500, margin: '30px auto 0', paddingTop: 'var(--layout-header-height)' }}>
          <Panel>
            <p style={{ fontSize: 20 }}>
              There is nothing here! <Link to="/">Head back home</Link>.
            </p>
          </Panel>
        </div>
      </MainLayout>
    );
  }
}

export default NotFoundPage;
