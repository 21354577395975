import styles from './GradientBorder.module.css';

import React, { Component } from 'react';

import { linearGradient } from 'utilities/color';

class GradientBorder extends Component {
  render() {
    // If `colors` is present it's assumed there are 2 items.
    const { children, size, width, height, colors, color, isRainbow, className, style, ...restProps } = this.props;

    let containerStyle = { width: size, height: size, ...style };
    if (width && height) containerStyle = { ...containerStyle, width, height, borderRadius: height / 2 };
    if (colors) containerStyle = { ...containerStyle, background: linearGradient(colors) };
    if (color) containerStyle = { ...containerStyle, background: color };

    const classNames = ['box-shadow', styles.container];
    if (className) classNames.push(className);
    if (isRainbow) classNames.push(styles.isRainbow);

    return (
      <div {...restProps} className={classNames.join(' ')} style={containerStyle}>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

export default GradientBorder;
