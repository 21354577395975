// @flow
import React, { memo } from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';

import { INPUT_TYPE } from 'constants/sheet';
import type { ElementProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { ChannelEvent } from 'models/Channel';
import { RoomSheetSelector, TableSelector } from 'store/selectors';

import Element from 'components/Sheet2/elements/Element';
import Field from './Field';

type ModelProps = {
  ...ElementModel,
  items: InputModel[],
};

type Props = {
  ...ElementProps,
  element: ModelProps,
};

function FieldElement(props: Props): Node {
  const { element, onChange, readOnly, roomGuid, sectionId, sheetGuid } = props;
  const { id, items } = element;

  const presenceChannel = useSelector(TableSelector.getPresenceChannel);
  const roomSheet = useSelector((state) => (roomGuid ? RoomSheetSelector.get(state, roomGuid, sheetGuid) : null));

  const fieldInput = items.find((o) => o.inputType === INPUT_TYPE.field);
  const fieldValue = String(fieldInput?.value ?? fieldInput?.defaultValue ?? '');

  const onFieldChange = (value) => {
    if (!fieldInput) return;
    const updatedItems = items.map((o) => (o.id === fieldInput.id ? { ...fieldInput, value } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onFieldBlur = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_BLURRED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  const onFieldFocus = () => {
    if (readOnly || !roomGuid || !roomSheet || !presenceChannel) return;
    if (roomSheet.permissions.editors?.length > 0)
      presenceChannel.trigger(ChannelEvent.SHEET_INPUT_FOCUSED, {
        sheetId: sheetGuid,
        inputId: id,
      });
  };

  return (
    <Element {...props}>
      {fieldInput && (
        <Field
          value={fieldValue}
          readOnly={readOnly}
          onBlur={onFieldBlur}
          onFocus={onFieldFocus}
          onChange={onFieldChange}
        />
      )}
    </Element>
  );
}

export default memo(FieldElement);
