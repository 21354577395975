import _ from 'lodash';

import RoomAction from 'store/actions/RoomAction';
import RoomUserAction from 'store/actions/RoomUserAction';

const INITIAL_STATE = {
  models: {},
  statuses: {},
};

export default function roomUserReducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  if (error) return state;

  switch (type) {
    case RoomUserAction.DELETE:
    case RoomUserAction.REMOVE:
      return {
        ...state,
        models: _.omit(state.models, `${payload.guid}-${payload.id}`),
        statuses: _.omit(state.statuses, `${payload.guid}-${payload.id}`),
      };

    case RoomAction.JOIN:
    case RoomUserAction.FETCH:
    case RoomUserAction.UPDATE:
    case RoomUserAction.UPDATE_SETTINGS:
      return { ...state, models: { ...state.models, [payload.id]: payload } };

    case RoomUserAction.FETCH_ALL:
      return { ...state, models: { ...state.models, ..._.mapKeys(payload, 'id') } };

    case RoomUserAction.UPDATE_SID:
      const guidId = `${payload.guid}-${payload.id}`;
      return { ...state, models: { ...state.models, [guidId]: { ...state.models[guidId], sid: payload.sid } } };

    case RoomUserAction.UPDATE_STATUS:
      return { ...state, statuses: { ...state.statuses, [`${payload.guid}-${payload.id}`]: payload.status } };

    default:
      return state;
  }
}
