import { useEffect } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

import useCallbackAsRef from 'hooks/useCallbackAsRef';

import useVideoContext from './useVideoContext';

type ParticipantCallback = (participant: RemoteParticipant) => void;
/**
 * Because of the way that twilio has typed their events and callbacks
 * we can't forward a nice generic handler without just ignoring typing.
 *
 * So just make a separate hook per event. We don't use them that often anyway, so it's fine?
 * @param callback
 */
export function useParticipantConnected(callback: ParticipantCallback) {
  const { room } = useVideoContext();
  const callbackRef = useCallbackAsRef(callback);

  useEffect(() => {
    if (room) {
      const cb = callbackRef.current;
      room.on('participantConnected', cb);
      return () => {
        room.off('participantConnected', cb);
      };
    }
  }, [callbackRef, room]);
}

export function useParticipantDisconnected(callback: ParticipantCallback) {
  const { room } = useVideoContext();
  const callbackRef = useCallbackAsRef(callback);

  useEffect(() => {
    if (room) {
      const cb = callbackRef.current;
      room.on('participantDisconnected', cb);
      return () => {
        room.off('participantDisconnected', cb);
      };
    }
  }, [callbackRef, room]);
}
