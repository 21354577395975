// @flow
import styles from './MenuList.module.css';

import type { Node } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';

import { stylizeTheme } from 'utilities/color';

type Props = {
  bottom?: number,
  children?: Node,
  left?: number,
  right?: number,
  top?: number,
  theme?: ?any,
};

export default function MenuListPortal({ children, top, bottom, left, right, theme }: Props): Node {
  const style = theme && stylizeTheme(theme);

  return ReactDOM.createPortal(
    <div className={styles.portal} style={{ ...style, top, bottom, left, right }}>
      {children}
    </div>,
    document.getElementById('popup-menu')
  );
}
