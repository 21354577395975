import styles from './TablePing.module.css';

import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import useTimeout from 'hooks/useTimeout';
import useUnmount from 'hooks/useUnmount';
import TableAction from 'store/actions/TableAction';

import PingAudio from 'audio/PingAudio.mp3';
import { ReactComponent as PingIcon } from 'images/icons/PingIcon.svg';

interface Props {
  className?: string;
}

export default function TablePing({ className }: Props) {
  const audioRef = useRef<HTMLAudioElement>();
  const dispatch = useDispatch();

  const stopPing = useCallback(() => {
    dispatch(TableAction.showPing(false));
  }, [dispatch]);

  useTimeout(stopPing, 3500);
  useUnmount(stopPing);

  useEffect(() => {
    const audio = document.createElement('audio');
    audio.src = PingAudio;

    const clickToPlay = () => {
      audio.play();
    };

    const onCanPlay = () => {
      audio.play().catch((e) => {
        window.addEventListener('click', clickToPlay, { once: true });
      });
    };

    audio.addEventListener('canplay', onCanPlay, { once: true });
    window.addEventListener('touchstart', clickToPlay, { once: true });

    audioRef.current = audio;
    return () => {
      audio.removeEventListener('canplay', onCanPlay);
      window.removeEventListener('click', clickToPlay);
      window.removeEventListener('touchstart', clickToPlay);
      audio.pause();
      audio.remove();
      audioRef.current = undefined;
    };
  }, []);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div className={styles.glow} />
      <div className={`box-shadow ${styles.content}`}>
        <div>
          <PingIcon />
          <span>!!PING!!</span>
        </div>
      </div>
    </div>
  );
}
