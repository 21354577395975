import { useEffect, useState } from 'react';
import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';

// TODO: Make types trickle down to the return (if we have a VideoTrackPublication then return type should be LocalVideoTrack | RemoteVideoTrack specifically)
type TrackPublication = LocalTrackPublication | RemoteTrackPublication;
export default function useTrack<T extends TrackPublication>(publication?: T) {
  const [track, setTrack] = useState(publication?.track ?? undefined);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication?.track ?? undefined);

    if (publication) {
      const removeTrack = () => setTrack(undefined);

      publication.on('subscribed', setTrack);
      publication.on('unsubscribed', removeTrack);
      return () => {
        publication.off('subscribed', setTrack);
        publication.off('unsubscribed', removeTrack);
      };
    }
  }, [publication]);

  return track;
}
