// @flow
import styles from './Text.module.css';

import React, { memo, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { ELEMENT_ALIGNMENT, INPUT_TYPE } from 'constants/sheet';

import { Builder } from 'components/Sheet2/elements/Element';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

export function TextBuilder(props: Props) {
  const { element, onChange, sectionId, sheetGuid } = props;
  const { items, metadata } = element;

  const headingRef = useRef(null);
  const paragraphRef = useRef(null);

  const headingInput = items.find((item) => item.inputType === INPUT_TYPE.heading);
  const paragraphInput = items.find((item) => item.inputType === INPUT_TYPE.paragraph);

  const hasToggle = !!items.find((item) => item.inputType === INPUT_TYPE.toggle);
  const heading = headingInput?.defaultValue ?? '';
  const paragraph = paragraphInput?.defaultValue ?? '';
  const alignment = metadata.alignment ?? ELEMENT_ALIGNMENT.left;

  const onHeadingBlur = (event) => {
    if (!headingInput) return;
    const defaultValue = event.currentTarget.value.trim();
    const updatedItems = items.map((o) => (o.id === headingInput.id ? { ...headingInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onHeadingChange = (event) => {
    if (!headingInput) return;
    const defaultValue = event.currentTarget.value;
    const updatedItems = items.map((o) => (o.id === headingInput.id ? { ...headingInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onHeadingKeyDown = (event) => {
    if (event.keyCode === 13) headingRef.current.blur();
  };

  const onParagraphBlur = (event) => {
    if (!paragraphInput) return;
    const defaultValue = event.currentTarget.value.trim();
    const updatedItems = items.map((o) => (o.id === paragraphInput.id ? { ...paragraphInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onParagraphChange = (event) => {
    if (!paragraphInput) return;
    const defaultValue = event.currentTarget.value;
    const updatedItems = items.map((o) => (o.id === paragraphInput.id ? { ...paragraphInput, defaultValue } : o));
    onChange(sheetGuid, sectionId, { ...element, items: updatedItems });
  };

  const onParagraphKeyDown = (event) => {
    if (event.keyCode === 13 && event.metaKey) paragraphRef.current.blur();
  };

  return (
    <Builder {...props}>
      <div className={`${styles.container} ${hasToggle ? styles.hasToggle : ''}`}>
        <div className={styles.builderInputContainer}>
          <div className={styles.builderBorder} />
          <TextareaAutosize
            ref={headingRef}
            className={`${styles.heading} ${styles.builderInput} ${styles[alignment]}`}
            value={heading}
            placeholder="Enter your heading"
            minRows={1}
            maxRows={3}
            onBlur={onHeadingBlur}
            onChange={onHeadingChange}
            onKeyDown={onHeadingKeyDown}
          />
        </div>
        <div className={styles.builderInputContainer}>
          <div className={styles.builderBorder} />
          <TextareaAutosize
            ref={paragraphRef}
            className={`${styles.paragraph} ${styles.builderInput} ${styles[alignment]}`}
            value={paragraph}
            placeholder="Enter your paragraph text"
            minRows={1}
            maxRows={10}
            onBlur={onParagraphBlur}
            onChange={onParagraphChange}
            onKeyDown={onParagraphKeyDown}
          />
        </div>
      </div>
    </Builder>
  );
}

const MemoizedTextBuilder = memo(TextBuilder);
MemoizedTextBuilder.displayName = 'MemoizedTextBuilder';

export default MemoizedTextBuilder;
