import styles from './EditProfilePage.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import ErrorAction from 'store/actions/ErrorAction';
import UserAction from 'store/actions/UserAction';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import { FileInput, TextInput } from 'components/inputs';
import MainLayout from 'components/layouts/MainLayout';
import TitleBar from 'components/layouts/TitleBar';
import Metatags from 'components/Metatags';
import Panel from 'components/Panel';

class EditProfilePage extends Component {
  state = { saveSuccess: false };

  isInvalidUsername = () => {
    const { errors } = this.props;
    return (
      errors.length > 0 &&
      errors.filter(([key, val]) => key === 'username' && Array.isArray(val) && val[0].includes('invalid')).length > 0
    );
  };

  isInvalidPassword = () => {
    const { errors } = this.props;
    return (
      errors.length > 0 &&
      errors.filter(([key, val]) => key === 'password' && Array.isArray(val) && val[0].includes('too short')).length > 0
    );
  };

  onSubmit = (formValues) => {
    const { currentUser, removeAllErrors, updateUser } = this.props;
    this.setState({ saveSuccess: false });
    removeAllErrors();
    return updateUser(currentUser.id, formValues, () => this.setState({ saveSuccess: true }));
  };

  renderInput = ({ input, type, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <TextInput
        input={input}
        meta={meta}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        showError={submitFailed}
        className={styles.input}
      />
    );
  };

  renderUploadInput = ({ input, placeholder, meta }) => {
    const { avatarUrl, submitFailed } = this.props;
    return (
      <FileInput
        input={input}
        meta={meta}
        placeholder={placeholder}
        placeholderImage={avatarUrl}
        accept="image/jpeg, image/jpg, image/png, image/gif"
        showError={submitFailed}
        size={144}
        className={styles.fileInput}
      />
    );
  };

  render() {
    const { saveSuccess } = this.state;
    const { handleSubmit } = this.props;

    return (
      <MainLayout navChildren={<TitleBar title="Profile Settings" />}>
        <Metatags title="Profile Settings" />

        <div className={styles.container}>
          <Panel className={styles.panel}>
            <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
              <Field component={this.renderInput} name="email" type="text" placeholder="Email Address" />
              <Field component={this.renderInput} name="username" type="text" placeholder="Username" />
              {this.isInvalidUsername() && (
                <p className={styles.message}>
                  Your username must be between 2-32 characters. Only letters, numbers, underscores, and periods
                  allowed.
                </p>
              )}
              <Field component={this.renderInput} name="password" type="password" placeholder="Change Password" />
              {this.isInvalidPassword() && (
                <p className={styles.message}>Your password must be at least 6 characters long.</p>
              )}
              <Field
                component={this.renderUploadInput}
                name="avatar"
                type="file"
                placeholder={
                  <span>
                    Profile
                    <br />
                    Picture
                  </span>
                }
              />
              {saveSuccess && <p className={styles.success}>Profile successfully updated!</p>}
              <Button variant="primary" type="submit" className={styles.button}>
                Save
              </Button>
            </form>
          </Panel>
        </div>
      </MainLayout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.username || values.username.trim() === '') errors.username = 'Required';
  if (!values.email || values.email.trim() === '') errors.email = 'Required';
  return errors;
};

const mapStateToProps = (state, props) => {
  const {
    currentUser: { email, username, avatarUrl },
  } = props;
  const serverError = state.errors[UserAction.UPDATE];

  return {
    errors: serverError ? Object.entries(serverError.message.errors) : [],
    avatarUrl,
    initialValues: {
      email,
      username,
    },
  };
};

const mapDispatchToProps = {
  removeAllErrors: ErrorAction.removeAll,
  updateUser: UserAction.update,
};

export default compose(
  requireAuth,
  requireCurrentUser,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'editProfile', validate, enableReinitialize: true })
)(EditProfilePage);
