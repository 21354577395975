import styles from './PreviewTableView.module.css';

import clsx from 'clsx';
import { CSSProperties, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Document from 'models/Document';
import { DocumentSelector, RoomDocumentSelector, TableSelector } from 'store/selectors';

import GameUpsell from 'components/GameUpsell/GameUpsell';
import RoomLogo from 'components/RoomLogo';
import TableMediaView from 'room/TableMediaView';
import TableAppView from 'room/TableMediaView/TableAppView';
import LobbyMusic from 'room/TableLobby/LobbyMusic';

function PreviewTableView() {
  const activeRoomDocument = useSelector(TableSelector.getActiveRoomDocument);
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  // @ts-ignore DocumentSelector is not yet typed
  const activeDoc = useSelector((state) => DocumentSelector.get(state, activeRoomDocument?.id));
  const previewBackground = useSelector(
    // @ts-ignore RoomDocumentSelector is not yet typed
    (state) => previewRoom && RoomDocumentSelector.getPreviewBackgroundBySlug(state, previewRoom)
  );
  // @ts-ignore RoomDocumentSelector is not yet typed
  const lobbyMusicUrl = useSelector((state) => RoomDocumentSelector.getPreviewLobbyMusicBySlug(state, previewRoom));
  const isShowingBuffs = useSelector(TableSelector.isShowingBuffs);
  const isShowingTargets = useSelector(TableSelector.isShowingTargets);
  const activeAsset = useMemo(() => activeDoc && new Document(activeDoc), [activeDoc]);
  const style = useMemo(() => {
    const newStyles: CSSProperties = {};
    if (previewBackground) {
      newStyles.backgroundImage = `url("${previewBackground}")`;
    }
    return newStyles;
  }, [previewBackground]);
  const renderAsset = useCallback(() => {
    if (activeAsset && activeAsset.isApp()) {
      return <TableAppView asset={activeAsset} />;
    } else {
      return <TableMediaView asset={activeAsset} />;
    }
  }, [activeAsset]);

  return (
    <div className={clsx(styles.container, 'scrollbars-dark')} style={style}>
      <div className={styles.viewMode}>
        {activeAsset ? (
          <div className={clsx(styles.assetContainer, styles.viewModeContent)}>
            <div className={styles.asset}>{renderAsset()}</div>
          </div>
        ) : (
          <>
            <RoomLogo />
            <GameUpsell>
              <LobbyMusic url={lobbyMusicUrl} />
            </GameUpsell>
          </>
        )}
      </div>
      <div id="table-modal" className={clsx((isShowingBuffs || isShowingTargets) && styles.modal)} />
    </div>
  );
}

export default PreviewTableView;
