// @flow
import styles from './Modal.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';

type Props = {
  children?: Node,
  maxWidth?: string,
  onDismiss: () => void,
  width?: string,
  className?: string,
};

function Modal(props: Props): Node {
  const { children, maxWidth, onDismiss, width, className } = props;
  const backgroundRef = useRef(null);

  const onBackgroundClick = (event) => {
    if (backgroundRef.current && event.target === backgroundRef.current) onDismiss();
  };

  return ReactDOM.createPortal(
    <div ref={backgroundRef} className={styles.background} onClick={onBackgroundClick}>
      <div className={clsx('box-shadow', styles.container)} style={{ maxWidth, width }}>
        <div className={clsx(styles.content, className)}>
          <button onClick={onDismiss} className={`button-reset ${styles.closeButton}`}>
            <XIcon />
          </button>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default Modal;
