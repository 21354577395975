import { createSelector } from 'reselect'

export default class SheetTemplateBuilderSelector {
  static addAfterBlockId = createSelector(
    state => state.sheetTemplateBuilder.addAfterBlockId,
    id => id
  )

  static addAfterSectionId = createSelector(
    state => state.sheetTemplateBuilder.addAfterSectionId,
    id => id
  )

  static addBlockSectionId = createSelector(
    state => state.sheetTemplateBuilder.addBlockSectionId,
    id => id
  )

  static addInputBlockId = createSelector(
    state => state.sheetTemplateBuilder.addInputBlockId,
    id => id
  )

  static addInputSectionId = createSelector(
    state => state.sheetTemplateBuilder.addInputSectionId,
    id => id
  )

  static addLinkBlockId = createSelector(
    state => state.sheetTemplateBuilder.addLinkBlockId,
    id => id
  )

  static addLinkInputId = createSelector(
    state => state.sheetTemplateBuilder.addLinkInputId,
    id => id
  )

  static addLinkIsToggle = createSelector(
    state => state.sheetTemplateBuilder.addLinkIsToggle,
    id => id
  )

  static addLinkSectionId = createSelector(
    state => state.sheetTemplateBuilder.addLinkSectionId,
    id => id
  )

  static advancedBlockId = createSelector(
    state => state.sheetTemplateBuilder.advancedBlockId,
    id => id
  )

  static advancedInputId = createSelector(
    state => state.sheetTemplateBuilder.advancedInputId,
    id => id
  )

  static advancedSectionId = createSelector(
    state => state.sheetTemplateBuilder.advancedSectionId,
    id => id
  )

  static deleteBlockSectionId = createSelector(
    state => state.sheetTemplateBuilder.deleteBlockSectionId,
    id => id
  )

  static deleteBlockId = createSelector(
    state => state.sheetTemplateBuilder.deleteBlockId,
    id => id
  )

  static deleteSectionId = createSelector(
    state => state.sheetTemplateBuilder.deleteSectionId,
    id => id
  )

  static deleteInputBlockId = createSelector(
    state => state.sheetTemplateBuilder.deleteInputBlockId,
    id => id
  )

  static deleteInputId = createSelector(
    state => state.sheetTemplateBuilder.deleteInputId,
    id => id
  )

  static deleteInputSectionId = createSelector(
    state => state.sheetTemplateBuilder.deleteInputSectionId,
    id => id
  )

  static editLinkIndex = createSelector(
    state => state.sheetTemplateBuilder.editLinkIndex,
    index => index
  )

  static isAddingBlock = createSelector(
    state => state.sheetTemplateBuilder.isAddingBlock,
    bool => bool
  )

  static isAddingInput = createSelector(
    state => state.sheetTemplateBuilder.isAddingInput,
    bool => bool
  )

  static isAddingLink = createSelector(
    state => state.sheetTemplateBuilder.isAddingLink,
    bool => bool
  )

  static isAddingSection = createSelector(
    state => state.sheetTemplateBuilder.isAddingSection,
    bool => bool
  )

  static isDeletingBlock = createSelector(
    state => !!state.sheetTemplateBuilder.deleteBlockId,
    bool => bool
  )

  static isDeletingInput = createSelector(
    state => !!state.sheetTemplateBuilder.deleteInputId,
    bool => bool
  )

  static isDeletingSection = createSelector(
    state => !!state.sheetTemplateBuilder.deleteSectionId,
    bool => bool
  )

  static isEditingLink = createSelector(
    state => state.sheetTemplateBuilder.isEditingLink,
    bool => bool
  )

  static isShowingAdvancedBlockOptions = createSelector(
    state =>
      !!state.sheetTemplateBuilder.advancedSectionId &&
      !!state.sheetTemplateBuilder.advancedBlockId &&
      !!!state.sheetTemplateBuilder.advancedInputId,
    bool => bool
  )

  static isShowingAdvancedInputOptions = createSelector(
    state =>
      !!state.sheetTemplateBuilder.advancedSectionId &&
      !!state.sheetTemplateBuilder.advancedBlockId &&
      !!state.sheetTemplateBuilder.advancedInputId,
    bool => bool
  )

  static isShowingAdvancedSectionOptions = createSelector(
    state =>
      !!state.sheetTemplateBuilder.advancedSectionId &&
      !!!state.sheetTemplateBuilder.advancedBlockId &&
      !!!state.sheetTemplateBuilder.advancedInputId,
    bool => bool
  )

  static isShowingPublishConfirmation = createSelector(
    state => state.sheetTemplateBuilder.isShowingPublishConfirmation,
    bool => bool
  )

  static isShowingPublished = createSelector(
    state => state.sheetTemplateBuilder.isShowingPublished,
    bool => bool
  )

  static isShowingRename = createSelector(
    state => state.sheetTemplateBuilder.isShowingRename,
    bool => bool
  )

  static isShowingSettingsMenu = createSelector(
    state => state.sheetTemplateBuilder.isShowingSettingsMenu,
    bool => bool
  )
}
