//@flow
import styles from './TableSheet.module.css';

import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import { DEFAULT_COLORS } from 'models/Color';
import { TableAction } from 'store/actions/';
import { RoomDocumentSelector, SheetTemplateSelector, TableSelector } from 'store/selectors';

import RoomControls from 'components/RoomControls';
import Sheet from 'components/Sheet2';
import PreviewSheetCreator from 'components/TableSheet/PreviewSheetCreator';

type Props = {
  isLoading: boolean,
  isTOCOpen: boolean,
  onChangeTOCOpen: any,
};

const sheetDisplayName = '(THIS COULD BE YOU!)';

function PreviewRoomSheet({ isLoading, isTOCOpen, onChangeTOCOpen }: Props) {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const previewSheet = useSelector(TableSelector.getPreviewSheet);
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const theme = useSelector((state) => previewRoom && RoomDocumentSelector.getPreviewThemeBySlug(state, previewRoom));
  const previewColors = useSelector((state) =>
    previewRoom ? RoomDocumentSelector.getPreviewColorsBySlug(state, previewRoom) : DEFAULT_COLORS
  );

  const templateForPreviewSheet = useSelector((state) => {
    let previewGuidSlug;
    if (previewSheet) {
      previewGuidSlug = previewSheet.isTemplate
        ? previewSheet.sheet.guidSlug
        : previewSheet.sheet.sheetTemplateGuidSlug;
    }
    if (previewGuidSlug) {
      return SheetTemplateSelector.get(state, previewGuidSlug);
    } else {
      return null;
    }
  });
  const isShowingSheet = useSelector(TableSelector.isShowingSheet);
  const isShowingSheets = useSelector(TableSelector.isShowingSheets);

  useEffect(() => {
    if (!previewSheet) onChangeTOCOpen(false);
  }, [onChangeTOCOpen, previewSheet]);

  const noop = useCallback(() => {}, []);
  const onToggleClick = useCallback(() => {
    dispatch(TableAction.showSheet(!isShowingSheet));
  }, [dispatch, isShowingSheet]);

  return (
    <div
      className={`box-shadow ${styles.container} ${!isShowingSheet ? styles.hideSheet : ''} ${
        isShowingSheets ? styles.isShowingSheets : ''
      }`}
    >
      <div className={styles.noise} />

      <div className={styles.content}>
        {size.isMd && (
          <RoomControls
            theme={theme}
            onToggleClick={onToggleClick}
            isTOCOpen={isTOCOpen}
            isShowingSheet={isShowingSheet}
          />
        )}

        {previewSheet && (
          <Sheet
            className={clsx(styles.sheet, !isShowingSheet && styles.hideSheet, styles.tableSheet)}
            theme={theme}
            colors={previewColors}
            model={previewSheet.sheet}
            tocMeta={
              templateForPreviewSheet && {
                name: templateForPreviewSheet.name,
                username: templateForPreviewSheet.ownerName,
              }
            }
            userName={sheetDisplayName}
            isPreview
            isCollapsed={!isShowingSheet}
            isTOCOpen={isTOCOpen}
            readOnly
            onAddSheet={noop}
            onChange={noop}
            onChangeSection={noop}
            onChangeElement={noop}
            onChangeGroupElement={noop}
            onChangeAvatar={noop}
            onChangeCollapse={onToggleClick}
            onChangeTOCOpen={onChangeTOCOpen}
            onMenuClick={noop}
            onSheetClick={noop}
          />
        )}
        {!previewSheet && (
          <PreviewSheetCreator isLoading={isLoading} className={clsx(!isShowingSheet && styles.hide, 'tableSheet')} />
        )}
      </div>
    </div>
  );
}

export default PreviewRoomSheet;
