export const enabled = () => {
  return !!window.fbq;
};

export const pageView = () => {
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const track = (name: string, options = {}) => {
  window.fbq('track', name, options);
};
