const COLOR = {
  RED: '#ff006b',
  ORANGE: '#fcb249',
  GREEN: '#abe14d',
  TEAL: '#46ccb1',
  PURPLE: '#8f81f1',
  PINK: '#ff9aeb',

  BLACK: '#000000',
  DARK_GREY: '#111111',
  GREY: '#242424',
  TEXT_DARK_GREY: '#333333',
  TEXT_GREY: '#656565',
  TEXT_LIGHT_GREY: '#b4b4b4',
  LIGHT_GREY: '#e6e6e6',
  WHITE: '#ffffff',

  CORAL_RED: '#fc4444',
  WARNING: '#e9da78',
};

export default COLOR;

// New Color Palette
export const NEW_COLOR = {
  MAIN: {
    DARK: '#222239',
    PRIMARY: '#6a61b2',
    SECONDARY: '#ff006b',
    LIGHT: '#ff006b',
    ACCENT: '#46ccb1',
  },
  DARK: {
    DARK: '#14141d',
    MID: '#1a1a26',
    MAIN: '#222239',
    LIGHT: '#3b3a50',
    ACCENT: '#7c7998',
  },
  PRIMARY: {
    DARK: '#272742',
    MAIN: '#6a61b2',
    LIGHT: '#8f81f1',
    ACCENT: '#bcb3f7',
  },
  SECONDARY: {
    DARK: '#66002b',
    MAIN: '#ff006b',
    LIGHT: '#ff66a6',
    ACCENT: '#ff9aeb',
  },
  LIGHT: {
    DARK: '#656565',
    MAIN: '#b4b4b4',
    LIGHT: '#e6e6e6',
  },
  ACCENT: {
    DARK: '#153d35',
    MAIN: '#46ccb1',
    LIGHT: '#b3f7f3',
    ACCENT: '#fcb249',
  },
};

export const DASHBOARD_COLOR = {
  DARK: '#3e3d47',
  MID: '#686677',
  MAIN: '#9592ab',
  LIGHT: '#bab8c7',
  LIGHT_GREY: '#e0dfe5',
  WHITE: '#ecebf1',
};

export const CSS_COLORS = {
  '--color-red': COLOR.RED,
  '--color-orange': COLOR.ORANGE,
  '--color-green': COLOR.GREEN,
  '--color-teal': COLOR.TEAL,
  '--color-purple': COLOR.PURPLE,
  '--color-pink': COLOR.PINK,

  '--color-blue': COLOR.PURPLE, // This is for posterity for RoomUser colors

  '--color-black': COLOR.BLACK,
  '--color-dark-grey': COLOR.DARK_GREY,
  '--color-grey': COLOR.GREY,
  '--color-text-dark-grey': COLOR.TEXT_DARK_GREY,
  '--color-text-grey': COLOR.TEXT_GREY,
  '--color-text-light-grey': COLOR.TEXT_LIGHT_GREY,
  '--color-light-grey': COLOR.LIGHT_GREY,
  '--color-white': COLOR.WHITE,

  '--color-dark-text': COLOR.DARK_GREY,
  '--color-light-text': COLOR.WHITE,
  '--color-sheet-input-editing': COLOR.ORANGE,

  // NEW COLORS

  '--color-main-dark': NEW_COLOR.MAIN.DARK,
  '--color-main-primary': NEW_COLOR.MAIN.PRIMARY,
  '--color-main-secondary': NEW_COLOR.MAIN.SECONDARY,
  '--color-main-light': NEW_COLOR.MAIN.LIGHT,
  '--color-main-accent': NEW_COLOR.MAIN.ACCENT,

  '--color-dark-dark': NEW_COLOR.DARK.DARK,
  '--color-dark-mid': NEW_COLOR.DARK.MID,
  '--color-dark-main': NEW_COLOR.DARK.MAIN,
  '--color-dark-light': NEW_COLOR.DARK.LIGHT,
  '--color-dark-accent': NEW_COLOR.DARK.ACCENT,

  '--color-primary-dark': NEW_COLOR.PRIMARY.DARK,
  '--color-primary-main': NEW_COLOR.PRIMARY.MAIN,
  '--color-primary-light': NEW_COLOR.PRIMARY.LIGHT,
  '--color-primary-accent': NEW_COLOR.PRIMARY.ACCENT,

  '--color-secondary-dark': NEW_COLOR.SECONDARY.DARK,
  '--color-secondary-main': NEW_COLOR.SECONDARY.MAIN,
  '--color-secondary-light': NEW_COLOR.SECONDARY.LIGHT,
  '--color-secondary-accent': NEW_COLOR.SECONDARY.ACCENT,

  '--color-light-dark': NEW_COLOR.LIGHT.DARK,
  '--color-light-main': NEW_COLOR.LIGHT.MAIN,
  '--color-light-light': NEW_COLOR.LIGHT.LIGHT,

  '--color-accent-dark': NEW_COLOR.ACCENT.DARK,
  '--color-accent-main': NEW_COLOR.ACCENT.MAIN,
  '--color-accent-light': NEW_COLOR.ACCENT.LIGHT,
  '--color-accent-accent': NEW_COLOR.ACCENT.ACCENT,

  '--color-dashboard-dark': DASHBOARD_COLOR.DARK,
  '--color-dashboard-mid': DASHBOARD_COLOR.MID,
  '--color-dashboard-main': DASHBOARD_COLOR.MAIN,
  '--color-dashboard-light': DASHBOARD_COLOR.LIGHT,
  '--color-dashboard-light-grey': DASHBOARD_COLOR.LIGHT_GREY,
  '--color-dashboard-white': DASHBOARD_COLOR.WHITE,

  '--color-alert-red': COLOR.CORAL_RED,
  '--color-warning': COLOR.WARNING,

  // Role Kit 2023
  '--color-light-100': '#ffffff',
  '--color-light-200': '#c1baf7',
  '--color-light-300': '#49447a',

  '--color-dark-100': '#7c7998',
  '--color-dark-200': '#272742',
  '--color-dark-300': '#1a1a26',
};

export const GRADIENT_COLORS = [
  '--color-red',
  '--color-orange',
  '--color-green',
  '--color-teal',
  '--color-purple',
  '--color-pink',
];

export const GRADIENT_COLORS_HEX = [COLOR.RED, COLOR.ORANGE, COLOR.GREEN, COLOR.TEAL, COLOR.PURPLE, COLOR.PINK];

export const RAINBOW_CSS = [
  'var(--color-red)',
  'var(--color-orange)',
  'var(--color-green)',
  'var(--color-teal)',
  'var(--color-purple)',
  'var(--color-pink)',
  'var(--color-red)',
];

export const DEFAULT_COLORS = Object.freeze(['var(--color-red)', 'var(--color-purple)']);

export const DEFAULT_HEX_COLORS = Object.freeze([COLOR.RED, COLOR.PURPLE]);

export const DEFAULT_ROOM_THEME = Object.freeze({
  'base': NEW_COLOR.DARK.MID,
  'button': NEW_COLOR.PRIMARY.DARK,
  'text': NEW_COLOR.DARK.ACCENT,
  'accent': COLOR.PURPLE,
});

export const DICE_COLORS = ['', COLOR.TEAL, COLOR.RED, COLOR.GREEN, COLOR.PURPLE, COLOR.ORANGE, COLOR.WHITE] as const;

export const DICE_COLOR_LABELS = {
  '': 'Default',
  [COLOR.TEAL]: 'Blue',
  [COLOR.RED]: 'Red',
  [COLOR.GREEN]: 'Green',
  [COLOR.PURPLE]: 'Purple',
  [COLOR.ORANGE]: 'Orange',
  [COLOR.WHITE]: 'White',
} as const;
