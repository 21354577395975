import { createSelector } from 'reselect';

import { DICE_COLORS } from 'models/Color';
import { ModifierType } from 'models/Modifier';

import RoomUserSelector from './RoomUserSelector';

export default class RollSelector {
  static getDie = createSelector(
    (state, id) => state.roll.dice[id],
    (die) => die
  );

  static getDice = createSelector([(state) => state.roll.dice, (state, sortKey) => sortKey], (stateDice, sortKey) => {
    const dice = Object.values(stateDice);
    if (sortKey)
      return dice.sort((a, b) => {
        if (sortKey === 'color') return DICE_COLORS.indexOf(a.color) - DICE_COLORS.indexOf(b.color);
        if (typeof a[sortKey] === 'string') return a[sortKey].localeCompare(b[sortKey]);
        return a[sortKey] - b[sortKey];
      });
    return dice;
  });

  static getModifier = createSelector(
    (state, id) => state.roll.modifiers[id],
    (modifier) => modifier
  );

  static getModifierBySheetAndInput = createSelector(
    (state, sheetId, inputId) =>
      Object.values(state.roll.modifiers).find((o) => o.sheetId === sheetId && o.inputId === inputId),
    (modifier) => modifier
  );

  static getModifiers = createSelector(
    (state) => state.roll.modifiers,
    (modifiers) => Object.values(modifiers)
  );

  static getSelectedModifiers = createSelector(RollSelector.getModifiers, (modifiers) =>
    modifiers.filter((o) => o.isSelected || (!o.sheetId && !o.inputId))
  );

  static getSelectedNonDiceModifiers = createSelector(RollSelector.getSelectedModifiers, (modifiers) =>
    modifiers.filter((o) => o.type !== ModifierType.DICE)
  );

  static getBuffs = createSelector(RollSelector.getModifiers, (modifiers) =>
    modifiers.filter((o) => o.type === ModifierType.BUFF)
  );

  static getSelectedBuffs = createSelector(RollSelector.getBuffs, (modifiers) => modifiers.filter((o) => o.isSelected));

  static getManualBuff = createSelector(
    (state, id) => state.roll.modifiers,
    (modifiers) => Object.values(modifiers).find((o) => o.type === ModifierType.BUFF && !o.sheetId && !o.inputId)
  );

  static getBuffsTotal = createSelector([RollSelector.getSelectedBuffs, this.getManualBuff], (modifiers, manualBuff) =>
    modifiers.reduce(
      (total, modifier) => {
        return total + modifier.value;
      },
      manualBuff && !isNaN(parseInt(manualBuff.value)) ? parseInt(manualBuff.value) : 0
    )
  );

  static getTargets = createSelector(RollSelector.getModifiers, (modifiers) =>
    modifiers.filter((o) => o.type === ModifierType.TARGET)
  );

  static getSelectedTargets = createSelector(RollSelector.getTargets, (modifiers) =>
    modifiers.filter((o) => o.isSelected)
  );

  static getManualTarget = createSelector(
    (state, id) => state.roll.modifiers,
    (modifiers) => Object.values(modifiers).find((o) => o.type === ModifierType.TARGET && !o.sheetId && !o.inputId)
  );

  static getTargetsTotal = createSelector(
    [RollSelector.getSelectedTargets, this.getManualTarget],
    (modifiers, manualTarget) =>
      modifiers.reduce(
        (total, modifier) => {
          return total + modifier.value;
        },
        manualTarget && !isNaN(parseInt(manualTarget.value)) ? parseInt(manualTarget.value) : 0
      )
  );

  // ========================================
  // ROLLING USERS
  // ========================================

  static getRollingUsers = (guid) => {
    const getAllByRoom = RoomUserSelector.getAllByRoom(guid, 'name');
    return createSelector([(state) => state.roll.userRolls, getAllByRoom], (rolls, users) => {
      const userIds = Object.keys(rolls);
      return users.filter(({ id }) => {
        const roll = rolls[id] || {};
        const dice = Object.values(roll.dice || {});
        const modifiers = Object.values(roll.modifiers || {});
        return userIds.includes(id) && (dice.length > 0 || modifiers.length > 0);
      });
    });
  };

  static getRollingUserDice = createSelector(
    (state, userId) => state.roll.userRolls[userId],
    (roll) => (roll ? Object.values(roll.dice || {}) : [])
  );

  static getRollingUserModifiers = createSelector(
    (state, userId) => state.roll.userRolls[userId],
    (roll) => (roll ? Object.values(roll.modifiers || {}) : [])
  );

  static getRollingUserBuffs = createSelector(RollSelector.getRollingUserModifiers, (modifiers) =>
    modifiers.filter((o) => o.type === ModifierType.BUFF)
  );

  static getRollingUserBuffsTotal = createSelector(RollSelector.getRollingUserBuffs, (modifiers) =>
    modifiers.reduce((total, modifier) => {
      return total + modifier.value;
    }, 0)
  );

  static getRollingUserTargets = createSelector(RollSelector.getRollingUserModifiers, (modifiers) =>
    modifiers.filter((o) => o.type === ModifierType.TARGET)
  );

  static getRollingUserTargetsTotal = createSelector(RollSelector.getRollingUserTargets, (modifiers) =>
    modifiers.reduce((total, modifier) => {
      return total + modifier.value;
    }, 0)
  );

  static isShowingUserRoll = createSelector(
    (state, userId) => state.roll.userRolls[userId],
    (roll) => (roll ? roll.isShowing : false)
  );
}
