import styles from './UploadAssetModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import Document, { DocumentType } from 'models/Document';
import AssetAction from 'store/actions/AssetAction';
import ErrorAction from 'store/actions/ErrorAction';
import DocumentAction from 'store/actions/DocumentAction';
import SessionAction from 'store/actions/SessionAction';
import ErrorSelector from 'store/selectors/ErrorSelector';
import SessionSelector from 'store/selectors/SessionSelector';
import { logEvent } from 'utilities';

import Button from 'components/buttons/Button';
import { FileInput, RadioInput, TextInput } from 'components/inputs';
import { TitleModal } from 'components/Modal';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';
import { ReactComponent as UploadIcon } from 'images/icons/UploadIcon.svg';
import PatronFeatureModal from 'modals/PatronFeatureModal';

class UploadAssetModal extends Component {
  state = { isLoading: false };

  onFileChange = (event, newValue, previousValue) => {
    const { formValues, change } = this.props;
    const prevFile = previousValue && previousValue[0];
    let prevFileName = null;
    if (prevFile) {
      const lastIndex = prevFile.name.lastIndexOf('.');
      if (lastIndex > 0) prevFileName = prevFile.name.substr(0, lastIndex);
    }
    const newFile = newValue[0];
    const name = formValues.name;
    const isCustomName = (!prevFile && name) || (prevFileName && prevFileName !== name);
    if (!isCustomName && newFile) {
      const lastIndex = newFile.name.lastIndexOf('.');
      if (lastIndex > 0) change('name', newFile.name.substr(0, lastIndex));
    }
  };

  onSubmit = (formValues) => {
    const { createDocument, assetEditMap, assetEditToken, fetchCurrentUser, onDismiss, onComplete } = this.props;

    this.setState({ isLoading: true });
    return createDocument(formValues, (obj) => {
      const doc = new Document(obj);
      onDismiss();
      fetchCurrentUser();
      if (onComplete) onComplete(doc);
      if (doc.isMap()) {
        assetEditMap(doc.id);
      } else if (doc.isToken()) {
        assetEditToken(doc.id);
      }
      logEvent('asset - uploaded', { type: doc.type });
    }).catch((error) => {
      this.setState({ isLoading: false });
      throw error;
    });
  };

  renderInput = ({ input, type, placeholder, meta }) => {
    const { submitFailed } = this.props;
    return (
      <TextInput
        input={input}
        meta={meta}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        showError={submitFailed}
        className={styles.input}
      />
    );
  };

  renderLibraryButton = () => {
    const { isLoading } = this.state;
    return (
      <div className={styles.library}>
        <p className="heading2 is-centered">Already have the asset you need?</p>
        <Button
          variant="secondary"
          isLoading={isLoading}
          onClick={() => this.props.assetLibrary(true)}
          className={styles.libraryButton}
          icon={<PlusIcon />}
        >
          Add From My Library
        </Button>
      </div>
    );
  };

  renderRadioInput = ({ input, label }) => {
    const { formValues } = this.props;
    const file = formValues?.file?.[0];
    return (
      <RadioInput
        disabled={file?.type.startsWith('audio')}
        input={input}
        label={label}
        allowUnselect
        className={styles.radioInput}
      />
    );
  };

  renderUploadInput = ({ input, placeholder, meta }) => {
    const { submitFailed } = this.props;

    return (
      <>
        <FileInput
          input={input}
          meta={meta}
          placeholder={placeholder}
          accept="image/*, application/pdf, audio/wav, audio/x-wav, audio/vnd.wave, audio/wave, audio/mpeg, audio/MPA, audio/mpa-robust, audio/mp4"
          showError={submitFailed}
          size={144}
          isAsset
          className={`${styles.fileInput}`}
        />
      </>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { room, uploadLimitError, onDismiss, handleSubmit, errorRemove } = this.props;

    return (
      <>
        <TitleModal
          title="Add New Asset"
          subtitle="Upload custom images, PDFs, maps, and tokens for use in your adventures!"
          onDismiss={onDismiss}
        >
          <form onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
            <Field
              component={this.renderUploadInput}
              onChange={this.onFileChange}
              name="file"
              type="file"
              placeholder={
                <>
                  Upload
                  <br />
                  Asset
                </>
              }
            />
            <div className={styles.actions}>
              <Field component={this.renderInput} name="name" type="text" placeholder="Asset Name" />
              <div className={styles.options}>
                <p>
                  <strong>Optional:</strong> Is this asset a map or token?
                </p>
                <Field
                  component={this.renderRadioInput}
                  name="doc_type"
                  type="radio"
                  label="Map"
                  value={DocumentType.MAP}
                />
                <Field
                  component={this.renderRadioInput}
                  name="doc_type"
                  type="radio"
                  label="Token"
                  value={DocumentType.TOKEN}
                />
              </div>
              <p className={styles.help}>
                <a href="https://youtu.be/DPIYdV_aBG0?t=839" target="_blank" rel="noopener noreferrer">
                  Curious how Maps & Tokens work?
                </a>
              </p>
              <Button
                variant="primary"
                type="submit"
                isLoading={isLoading}
                icon={<UploadIcon style={{ marginTop: '-2px' }} />}
              >
                Upload
              </Button>

              {room && this.renderLibraryButton()}
            </div>
          </form>
        </TitleModal>
        {uploadLimitError && (
          <PatronFeatureModal
            title="You've Reached Your Data Cap!"
            including="10gb of storage"
            onDismiss={() => errorRemove(DocumentAction.CREATE)}
          />
        )}
      </>
    );
  }
}

UploadAssetModal.defaultProps = {
  isToken: false,
};

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.trim() === '') errors.name = 'Required';
  if (!values.file || (values.file && !values.file[0])) errors.file = 'Required';
  return errors;
};

const mapStateToProps = (state, props) => {
  const currentUser = SessionSelector.currentUser(state);
  const isPatron = currentUser?.isPatron;
  const uploadError = ErrorSelector.get(state, DocumentAction.CREATE)?.message;
  const uploadLimitError =
    !isPatron && (uploadError?.includes('reached your max') || uploadError?.includes('enough space'));
  return {
    currentUser: currentUser,
    uploadLimitError: uploadLimitError,
    formValues: getFormValues('uploadAsset')(state),
    initialValues: {
      doc_type: props.isToken ? DocumentType.TOKEN : '',
    },
  };
};

const mapDispatchToProps = {
  assetEditMap: AssetAction.editMap,
  assetEditToken: AssetAction.editToken,
  assetLibrary: AssetAction.showLibrary,
  assetUpload: AssetAction.showUpload,
  createDocument: DocumentAction.create,
  fetchCurrentUser: SessionAction.fetchCurrentUser,
  errorRemove: ErrorAction.remove,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'uploadAsset', validate })
)(UploadAssetModal);
