import amplitude from 'amplitude-js';
import _ from 'lodash';

import { GRADIENT_COLORS } from 'models/Color';

const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

export const logEvent = (event: string, properties?: Record<string, any>) => {
  if (!amplitudeKey) return;
  amplitude.getInstance().logEvent(event, properties);
};

type PropertyValue = string | number | boolean | object | any[];
export const setUserProperty = (property: string, value: PropertyValue, once = false) => {
  if (!amplitudeKey) return;
  let identify;
  if (once) identify = new amplitude.Identify().setOnce(property, value);
  else identify = new amplitude.Identify().set(property, value);
  amplitude.getInstance().identify(identify);
};

export const addUserProperty = (property: string, value: string | number) => {
  if (!amplitudeKey) return;
  const identify = new amplitude.Identify().add(property, value);
  amplitude.getInstance().identify(identify);
};

export const isTouch = (() => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  return navigator.maxTouchPoints > 2;
})();

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const randomIntBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const clamp = (number: number, min: number, max: number) => {
  return Math.min(Math.max(number, min), max);
};

export const getDate = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(2, 2)}`;
};

export const getDateAndTime = (date: Date) =>
  date.toLocaleString('default', {
    day: 'numeric',
    month: '2-digit',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });

export const getFullDate = (date: Date) => {
  return date.toLocaleString('default', { year: 'numeric', month: 'short', day: 'numeric' });
};

export const createLinearGradientCSS = (colors: [string, string]) => {
  return `linear-gradient(180deg, var(${colors[0]}), var(${colors[1]})`;
};

export const randomCard = (cards: any[]) => {
  if (cards.length === 0) return null;
  const i = randomIntBetween(0, cards.length - 1);
  return cards[i];
};

export const randomColor = (other: string): string => {
  const color = GRADIENT_COLORS[_.random(GRADIENT_COLORS.length - 1)];
  return !other || color !== other ? color : randomColor(other);
};

export const randomPlaykitColor = (playkitColors: string[], other?: string): string => {
  const color = playkitColors[_.random(playkitColors.length - 1)];
  const correctedColors = playkitColors.filter((color) => color !== other);
  return !other || color !== other ? color : randomPlaykitColor(correctedColors);
};

export const swapPositions = <T>(arr: T[], index1: number, index2: number) => {
  const swappedArr = [...arr];
  swappedArr[index1] = arr[index2];
  swappedArr[index2] = arr[index1];
  return swappedArr;
};

export const pluralize = (num: number, word: string, plural = word + 's') => {
  return [1, -1].includes(Number(num)) ? word : plural;
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const insertItem = <T>(array: T[], index: number, item: T) => {
  const newArray = array.slice();
  newArray.splice(index, 0, item);
  return newArray;
};

export const removeItem = <T>(array: T[], index: number) => {
  return array.filter((_, i) => i !== index);
};

export const moveItem = <T>(array: T[], sourceIndex: number, destinationIndex: number) => {
  const newArray = array.slice();
  const item = newArray[sourceIndex];
  newArray.splice(sourceIndex, 1);
  newArray.splice(destinationIndex, 0, item);
  return newArray;
};

export const updateItem = <T>(array: T[], index: number, item: T) => {
  return array.map((obj, i) => {
    if (i !== index) return obj;
    return {
      ...obj,
      ...item,
    };
  });
};

export function nextUrl(path?: `/${string}`) {
  return process.env.REACT_APP_NEXT_URL + (path ?? '');
}
