import { useEffect, useRef } from 'react';

/**
 * Always saves the latest value of a thing to a ref and returns the ref.
 * This lets us bypass dep arrays and prevent unecessary rerenders.
 * @param value
 * @returns
 */
export default function useLatest<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}
