// @flow
import styles from './dashboard.module.css';

import clsx from 'clsx';
import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import history from 'browserHistory';
import { FILTER, FILTERS } from 'constants/rooms';
import useQuery from 'hooks/useQuery';
import { RoomAction, RoomUserAction, TableAction } from 'store/actions';
import { RoomSelector, TableSelector } from 'store/selectors';
import { pluralize } from 'utilities';

import requireAuth from 'hocs/requireAuth';
import requireCurrentUser from 'hocs/requireCurrentUser';

import Button from 'components/buttons/Button';
import TableCard from 'components/cards/TableCard';
import CreateTableModal from 'components/CreateTableModal';
import FilterBar from 'components/FilterBar';
import DashboardLayout from 'components/layouts/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Metatags from 'components/Metatags';
import RemoveTableModal from 'components/RemoveTableModal';
import TitlePanel from 'components/TitlePanel';
import { ReactComponent as PlusIcon } from 'images/icons/PlusIcon.svg';

const SPINNER_COLOR = 'var(--color-dark-text)';

type Props = {
  isCreatingTable: boolean,
};

function TablesPage({ isCreatingTable }: Props): Node {
  const dispatch = useDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(FILTER.all);
  const [order, setOrder] = useState(true);
  const filteredRooms = useSelector((state) => RoomSelector.filter(filter, order ? 'asc' : 'desc')(state));
  const createdRooms = useSelector(RoomSelector.getAllCreated);
  const joinedRooms = useSelector(RoomSelector.getAllJoined);
  const roomToRemove = useSelector(TableSelector.getRoomToRemove);
  const filteredRoomsCount = filteredRooms.length;
  const createdRoomsCount = createdRooms.length;
  const joinedRoomsCount = joinedRooms.length;
  const roomsCount = createdRoomsCount + joinedRoomsCount;

  const query = useQuery();

  useEffect(() => {
    setIsLoading(true);
    batch(() => {
      dispatch(
        RoomAction.fetchAll((rooms) => {
          setIsLoading(false);
          setHasFetched(true);
          const guids = rooms.map((o) => o.guid);
          guids.forEach((guid) => dispatch(RoomUserAction.fetchAll(guid)));
        })
      );
    });
  }, [dispatch]);

  const onCreateTableModalDismiss = useCallback(() => history.push('/rooms'), []);

  const onCreateTableClick = useCallback(() => history.push('/rooms/new'), []);

  const onRemoveTableModalDismiss = useCallback(() => dispatch(TableAction.remove(false)), [dispatch]);

  return (
    <DashboardLayout>
      <Metatags title="Your Rooms" />

      <div className={clsx(styles.content, styles.hasSidebar)}>
        <TitlePanel
          title="Your Rooms"
          subtitle={
            createdRoomsCount > 0 &&
            `You've created ${createdRoomsCount} ${pluralize(
              createdRoomsCount,
              'room'
            )} and joined ${joinedRoomsCount} ${pluralize(joinedRoomsCount, 'room')}`
          }
          headerChildren={
            <Button
              className={styles.mobileTableButton}
              variant="primary"
              onClick={onCreateTableClick}
              icon={<PlusIcon />}
            >
              New Room
            </Button>
          }
        >
          <FilterBar
            filters={FILTERS}
            current={filter}
            order={order}
            onFilterChange={setFilter}
            onOrderChange={setOrder}
            className={styles.filterBar}
          />
          {roomsCount > 0 && (
            <div className={styles.cards}>
              {filteredRooms.map((room) => (
                <TableCard key={room.guid} room={room} showActions showClose />
              ))}
            </div>
          )}
          {hasFetched && filteredRoomsCount === 0 && [FILTER.all, FILTER.created].includes(filter) && (
            <div className={styles.empty}>
              <h4 className={styles.emptyHeader}>You have not created any rooms yet</h4>
              <p className={styles.emptyBody}>To have fun playing RPGs on Role, start by creating a room below</p>
              <Button variant="primary" className={styles.emptyCta} onClick={onCreateTableClick} icon={<PlusIcon />}>
                Create Room
              </Button>
            </div>
          )}
          {hasFetched && filteredRoomsCount === 0 && filter === FILTER.joined && (
            <div className={styles.empty}>
              <h4 className={styles.emptyHeader}>You have not joined any rooms yet</h4>
              <p className={styles.emptyBody}>To have fun playing RPGs on Role, start by creating a room below</p>
              <Button variant="primary" className={styles.emptyCta} onClick={onCreateTableClick} icon={<PlusIcon />}>
                Create Room
              </Button>
            </div>
          )}
          {isLoading && roomsCount === 0 && <LoadingSpinner color={SPINNER_COLOR} />}
        </TitlePanel>
      </div>

      {isCreatingTable && <CreateTableModal playkitId={query.get('playkit')} onDismiss={onCreateTableModalDismiss} />}
      {roomToRemove && <RemoveTableModal room={roomToRemove} onDismiss={onRemoveTableModalDismiss} />}
    </DashboardLayout>
  );
}

TablesPage.defaultProps = {
  isCreatingTable: false,
};

export default compose(requireAuth, requireCurrentUser)(TablesPage);
