// @flow
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ChannelEvent } from 'models/Channel';
import { SessionSelector, TableSelector } from 'store/selectors';

export default function useModifierPusher(modifier) {
  const currentUser = useSelector(SessionSelector.currentUser);
  const isPrivateRoll = useSelector(TableSelector.isPrivateRoll);
  const presenceChannel = useSelector(TableSelector.getPresenceChannel);

  const sendPusher = useCallback(() => {
    if (!modifier || isPrivateRoll || !presenceChannel || !currentUser) return;
    if (modifier.isSelected) {
      presenceChannel.trigger(ChannelEvent.MODIFIER_ADDED, {
        userId: currentUser.id,
        modifier: modifier,
      });
    } else {
      presenceChannel.trigger(ChannelEvent.MODIFIER_REMOVED, {
        userId: currentUser.id,
        modifierId: modifier.id,
      });
    }
  }, [currentUser, isPrivateRoll, modifier, presenceChannel]);

  useEffect(() => {
    if (modifier) sendPusher();
  }, [modifier, sendPusher]);
}
