import _ from 'lodash'
import UserAction from 'store/actions/UserAction';

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action
  if (error) return state

  switch (type) {
    case UserAction.FETCH:
      return { ...state, [payload.id]: payload }

    case UserAction.UPDATE:
      return { ...state, [payload.id]: _.pick(payload, ['id', 'username', 'avatarUrl']) }

    default:
      return state
  }
}
