import _ from 'lodash';
import { createSelector } from 'reselect';

import RoomSelector from './RoomSelector';

const orderByUpdatedAt = (obj) => obj.updatedAt;

export default class PlaykitSelector {
  static get = createSelector(
    (state, id) => state.playkits[id],
    (playkit) => playkit
  );

  static getAll = createSelector(
    (state) => state.playkits,
    (playkits) => _.orderBy(Object.values(playkits), [orderByUpdatedAt], ['desc'])
  );

  static getByRoom = createSelector([this.getAll, RoomSelector.get], (playkits, room) => {
    if (!room) return [];
    return playkits.filter((o) => room.playkitIds.includes(o.id));
  });
}
