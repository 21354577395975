import _ from 'lodash'
import DocumentAction from 'store/actions/DocumentAction';

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, error } = action
  if (error) return state

  switch (type) {
    case DocumentAction.CREATE:
    case DocumentAction.FETCH:
    case DocumentAction.UPDATE:
      return { ...state, [payload.id]: payload }

    case DocumentAction.DELETE:
    case DocumentAction.REMOVE:
      return _.omit(state, payload)

    case DocumentAction.FETCH_ALL:
      return { ...state, ..._.mapKeys(payload, 'id') }

    default:
      return state
  }
}
