// @flow
import styles from './ElementLibrary.module.css';

import React, { memo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { LIBRARY, LIBRARY_TYPE } from 'constants/sheet';

import TabsBar from 'components/TabsBar';
import { ReactComponent as DragNDropIcon } from 'images/icons/DragNDropIcon.svg';
import { ReactComponent as InfoIcon } from 'images/icons/InfoIcon.svg';

const LIBRARY_ITEMS = Object.values(LIBRARY);

export const ElementLibraryItem = ({ children, item, isDragging, style }) => (
  <div className={`${styles.builderRep} ${isDragging ? styles.isDragging : ''}`} style={style}>
    <span className={styles.builderName}>{item.title}</span>
    {children}
  </div>
);

const TABS = ['General', 'Gameplay'];

function ElementLibrary() {
  const [isGeneral, setIsGeneral] = useState(true);

  const renderDraggableItem = (index, item) => {
    return (
      <Draggable key={index} draggableId={item.id} index={index}>
        {(provided) => (
          <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
            <ElementLibraryItem provided={provided} item={item} isDragging={false} style={null}>
              <DragNDropIcon className={styles.dragIcon} />
              <div className={styles.infoIcon}>
                <div className={styles.infoTooltip}>{item.tooltip}</div>
                <InfoIcon />
              </div>
            </ElementLibraryItem>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className={styles.elementLibrary}>
      <header>
        <h4>Elements Library</h4>
        <div className={styles.instruction}>Drag elements from here into your template.</div>
        <TabsBar
          variant="theme"
          tabs={TABS}
          currentTab={TABS[isGeneral ? 0 : 1]}
          tabWidth={162}
          onChange={(tab) => setIsGeneral(tab === TABS[0])}
        />
      </header>

      {LIBRARY_ITEMS.filter((o) => o.libraryType === (isGeneral ? LIBRARY_TYPE.general : LIBRARY_TYPE.gameplay)).map(
        (item, index) => renderDraggableItem(index, item)
      )}
    </div>
  );
}

const MemoizedElementLibrary = memo(ElementLibrary);
MemoizedElementLibrary.displayName = 'MemoizedElementLibrary';

export default MemoizedElementLibrary;
