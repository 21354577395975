import { useCallback, useState } from 'react';
import { LocalVideoTrack, TwilioError } from 'twilio-video';

import useVideoContext from './useVideoContext';
import { isScreenShareTrack } from 'video/utils';

type ErrorCallback = (error: TwilioError) => void;

function defaultErrorCallback(error: TwilioError) {
  console.dir(error);
}

export default function useLocalVideoToggle(errorCallback: ErrorCallback = defaultErrorCallback) {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track): track is LocalVideoTrack => track.kind === 'video' && !isScreenShareTrack(track)
  );
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleVideoEnabled = useCallback(
    (forceRepublish = false) => {
      if (!isPublishing) {
        if (videoTrack) {
          const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant?.emit('trackUnpublished', localTrackPublication);
          removeLocalVideoTrack();
        }

        if (!videoTrack || forceRepublish) {
          setIsPublishing(true);
          return getLocalVideoTrack()
            .then((track) => localParticipant?.publishTrack(track, { priority: 'low' }))
            .catch(errorCallback)
            .finally(() => {
              setIsPublishing(false);
            });
        }
      }
    },
    [getLocalVideoTrack, errorCallback, isPublishing, localParticipant, removeLocalVideoTrack, videoTrack]
  );

  return [!!videoTrack, toggleVideoEnabled] as const;
}
