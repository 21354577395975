import styles from './PlayerSafetyModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import GeneralAction from 'store/actions/GeneralAction';

import Button from 'components/buttons/Button';
import { TitleModal } from 'components/Modal';

class PlayerSafetyModal extends Component {
  onDismiss = () => {
    this.props.showPlayerSafety(false);
  };

  render() {
    return (
      <TitleModal
        title="Player Safety"
        subtitle="Everyone deserves a welcoming, positive, safe space to play. If you’d like to learn more, check out our recommended list of safety systems created by various professionals across the role playing community:"
        actions={
          <Button variant="cancel" onClick={this.onDismiss} className={styles.closeButton}>
            Close
          </Button>
        }
        onDismiss={this.onDismiss}
      >
        <div className={styles.container}>
          <h4 className={styles.heading}>
            <a href="http://tinyurl.com/x-card-rpg" target="_blank" rel="noopener noreferrer">
              The X-card
            </a>
          </h4>
          <div className={styles.author}>by John Stavropoulos</div>
          <p className={styles.copy}>
            The X-card is a tool that can be used during play to edit out any content anyone is uncomfortable with. When
            the X-card is used (by tapping on or showing a physical card, saying “X” out loud, or typing “X” into chat),
            the room simply edits out what has been X-carded.
          </p>

          <h4 className={styles.heading}>
            <a
              href="https://web.archive.org/web/20181229182758/http://www.gamingaswomen.com/posts/2013/01/finding-my-o-with-the-x-card/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The O-card
            </a>
          </h4>
          <div className={styles.author}>by Kira Magrann</div>
          <p className={styles.copy}>
            The O-card is a tool that can be used in tandem with the X-card. The O-card is used (by tapping on it,
            saying “O” out loud, or typing “O” into chat) for anyone to explicitly state that they are ok with what’s
            currently going on during play and want more of the content, and the room can lean into it.
          </p>

          <h4 className={styles.heading}>
            <a href="https://www.gauntlet-rpg.com/blog/stars-and-wishes" target="_blank" rel="noopener noreferrer">
              Stars and Wishes
            </a>
          </h4>
          <div className={styles.author}>by Lu Quade</div>
          <p className={styles.copy}>
            Stars and Wishes is an after-play tool that can be used to gather forward-facing feedback from everyone in
            the room. At the end of a session, everyone including the facilitator offers Stars (something that they
            loved about the game) and Wishes (something that they would like to see happen in a future session).
          </p>

          <div className={styles.author}>Learn More</div>
          <p className={styles.copy}>
            These are only a few possible safety tools and systems that you can use in your room, and it’s important to
            find the one(s) that work for you. Learn more about these safety tools and other alternatives in the TTRPG
            Safety Toolkit (
            <a href="https://bit.ly/ttrpgsafetytoolkit" target="_blank" rel="noopener noreferrer">
              bit.ly/ttrpgsafetytoolkit
            </a>
            ).
          </p>
        </div>
      </TitleModal>
    );
  }
}

const mapDispatchToProps = {
  showPlayerSafety: GeneralAction.showPlayerSafety,
};

export default connect(null, mapDispatchToProps)(PlayerSafetyModal);
