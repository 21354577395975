// @flow
import React from 'react';

import type { BuilderProps, ElementModel, InputModel } from 'components/Sheet2/types';
import { LIBRARY } from 'constants/sheet';

import { ReactComponent as DragIcon } from 'images/icons/DragIcon.svg';
import { ReactComponent as XIcon } from 'images/icons/XIcon.svg';
import Divider from './Divider';

import styles from 'components/Sheet2/elements/Element/Builder.module.css';

type Props = {
  ...BuilderProps,
  element: {
    ...ElementModel,
    items: InputModel[],
  },
};

function DividerBuilder(props: Props) {
  const {
    className,
    element: { id, elementType },
    onRemove,
    provided,
    sectionId,
    setInnerRef,
  } = props;
  const classNames = [styles.container, styles.twoColumns, styles.isDivider];
  if (className) classNames.push(className);

  return (
    <div
      {...provided.draggableProps}
      ref={(el) => setInnerRef(el, id, provided.innerRef)}
      className={classNames.join(' ')}
    >
      <header className={styles.header}>
        <div {...provided.dragHandleProps} className={styles.dragHandle}>
          <DragIcon />
        </div>
        <div className={styles.elementType}>{LIBRARY[elementType].title}</div>
      </header>
      <div className={styles.dividerElement}>
        <Divider />
      </div>
      <button className={`button-reset ${styles.removeButton}`} onClick={() => onRemove(sectionId, id)}>
        <XIcon />
      </button>
    </div>
  );
}

export default DividerBuilder;
