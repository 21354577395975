import { createSelector } from 'reselect'
import { CardView } from 'models/Card';

const cards = state => (state.cards.currentDeck ? state.cards.decks[state.cards.currentDeck].cards : [])
const currentView = state => state.cards.currentView

const cardSort = (a, b) => (a.updatedAt >= b.updatedAt ? 1 : -1)

export default class CardSelector {
  static isShuffling = createSelector(
    state => state.cards.isShuffling,
    isShuffling => isShuffling
  )

  static getCards = createSelector(cards, cards =>
    Object.values(cards)
      .sort(cardSort)
      .filter(c => !c.isDrawn && !c.isDiscarded)
  )

  static getCurrentDeck = createSelector(
    state => state.cards.currentDeck,
    id => id
  )

  static getCurrentView = createSelector([currentView], view => view)

  static getDeck = createSelector(
    (state, id) => state.cards.decks[id],
    deck => deck
  )

  static getDeckCards = createSelector(cards, models => Object.values(models))

  static getDecks = createSelector(
    state => state.cards.decks,
    decks => Object.values(decks)
  )

  static getDiscardedCards = createSelector(cards, cards =>
    Object.values(cards)
      .sort(cardSort)
      .filter(c => c.isDiscarded)
  )

  static getDrawnCards = createSelector([cards, currentView, state => state.session.user], (cards, view, user) => {
    switch (view) {
      case CardView.TABLE:
        return Object.values(cards)
          .sort(cardSort)
          .filter(c => c.isDrawn && !c.userId)
      case CardView.PLAYER:
        return Object.values(cards)
          .sort(cardSort)
          .filter(c => c.isDrawn && c.userId === user.id)
      default:
        return []
    }
  })

  static getTemplates = createSelector(
    state => state.cards.templates,
    templates => Object.values(templates)
  )
}
