import { merge, orderBy } from 'lodash';
import { createSelector } from 'reselect';

import { FILTER } from 'constants/rooms';
import { DEFAULT_CARDS_SETTINGS } from 'models/Card';
import { DEFAULT_DICE_SETTINGS } from 'models/Die';

const rooms = (state) => state.rooms;
const currentUser = (state) => state.session.user;
const orderByTitle = (obj) => obj.title.toLowerCase();

export default class RoomSelector {
  static get = (state, guid) => state.rooms[guid];

  static getAll = createSelector(rooms, (rooms) => orderBy(Object.values(rooms), ['createdAt'], ['desc']));

  static getAllCreated = createSelector([rooms, currentUser], (rooms, currentUser) =>
    orderBy(
      Object.values(rooms).filter((r) => r.userId === currentUser.id),
      ['createdAt'],
      ['desc']
    )
  );

  static getAllJoined = createSelector([rooms, currentUser], (rooms, currentUser) =>
    orderBy(
      Object.values(rooms).filter((r) => r.userId !== currentUser.id && r.userIds.includes(currentUser.id)),
      ['createdAt'],
      ['desc']
    )
  );

  static getLatestRoomByGameId = createSelector(
    (state, gameId) => {
      return Object.values(state.rooms).filter((r) => r.gameId === gameId);
    },
    (gameRooms) => (gameRooms.length > 0 ? orderBy(gameRooms, ['lastPlayedAt'], ['desc'])?.[0] : null)
  );

  static getBySheet = createSelector(
    (state, sheetId) => {
      const guids = Object.values(state.roomSheets)
        .filter((o) => o.id === sheetId)
        .map((o) => o.guid);
      return Object.values(state.rooms).filter((o) => guids.includes(o.guid));
    },
    (rooms) => orderBy(Object.values(rooms), [orderByTitle], ['asc'])
  );

  static getColors = createSelector(this.get, (room) => room?.colors || []);

  static getTheme = createSelector(this.get, (room) => room?.theme || {});

  static getBackground = createSelector(this.get, (room) => room?.backgroundArtUrl);

  static getLobbyMusicUrl = createSelector(this.get, (room) => room?.lobbyMusicUrl);

  static getDiceSettings = createSelector(this.get, (room) => {
    const settings = room?.settings.dice ?? {};
    return merge(DEFAULT_DICE_SETTINGS, settings);
  });

  static getCardsSettings = createSelector(this.get, (room) => {
    const settings = room?.settings.cards ?? {};
    return merge(DEFAULT_CARDS_SETTINGS, settings);
  });

  static filter = (filterType, filterOrder) => {
    return createSelector([rooms, currentUser], (rooms, currentUser) => {
      const filtered = Object.values(rooms).filter((o) => {
        switch (filterType) {
          case FILTER.all:
            return o.userIds.includes(currentUser.id);
          case FILTER.created:
            return o.userId === currentUser.id;
          case FILTER.joined:
            return o.userId !== currentUser.id && o.userIds.includes(currentUser.id);
          default:
            return false;
        }
      });
      return orderBy(filtered, [orderByTitle], [filterOrder]);
    });
  };
}
