import styles from './GradientDot.module.css';

import React, { Component } from 'react';

import { linearGradient } from 'utilities/color';

const DEFAULTS = ['var(--color-text-light-grey)', 'var(--color-text-light-grey)'];

class GradientDot extends Component {
  render() {
    const { colors, className } = this.props;
    return (
      <div
        className={`${styles.container} ${className || ''}`}
        style={{ background: linearGradient(colors || DEFAULTS) }}
      />
    );
  }
}

export default GradientDot;
