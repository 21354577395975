// @flow
import styles from './Button.module.css';

import { forwardRef, Node } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { linearGradient } from 'utilities/color';

import LoadingSpinner from 'components/LoadingSpinner';

type OnClick = (SyntheticEvent<any>) => void;
export interface ButtonProps {
  border?: ?string;
  buttonRef?: any;
  children?: Node;
  className?: ?string;
  color?: ?string;
  download?: boolean | string;
  onClick?: OnClick;
  hasHoverEffect?: boolean;
  hoverBorder?: ?string;
  hoverColor?: ?string;
  hoverPrimaryBackground?: ?string;
  hoverSecondaryBackground?: ?string;
  icon?: Node;
  iconSize?: string;
  isExternalLink?: ?boolean;
  isSimpleIcon?: ?boolean;
  isDisabled?: boolean;
  isLinkAsButton?: ?boolean;
  isLoading?: boolean;
  primaryBackground?: ?string;
  secondaryBackground?: ?string;
  target?: '_blank' | '_self';
  to?: string;
  type?: ?('submit' | 'reset' | 'button');
  isSmall?: boolean;
  variant?: ?('cancel' | 'knockout' | 'primary' | 'secondary');
}

function Button(props: ButtonProps, ref): Node {
  const {
    border,
    buttonRef,
    children,
    className,
    color,
    download,
    hasHoverEffect = true,
    hoverBorder,
    hoverColor,
    hoverPrimaryBackground,
    hoverSecondaryBackground,
    icon,
    iconSize = 12,
    isExternalLink,
    isDisabled,
    isLinkAsButton,
    isLoading,
    isSimpleIcon,
    onClick: propOnClick,
    primaryBackground,
    secondaryBackground,
    target,
    to,
    type = 'button',
    isSmall,
    variant,
  } = props;

  const classNames = ['button-reset', styles.container];
  if (className) classNames.push(className);
  if (isLoading) classNames.push(styles.isLoading);
  if (hasHoverEffect && variant !== 'cancel' && !isDisabled) classNames.push(styles.hasHoverEffect);
  if (to && !isLinkAsButton) classNames.push(styles.isLink);

  const iconContentClassNames = [styles.iconContent];
  if (isSimpleIcon) iconContentClassNames.push(styles.isSimpleIcon);

  const style = {};

  if (variant) {
    classNames.push(styles[variant]);

    switch (variant) {
      case 'secondary':
        style['--button-background'] = linearGradient(['var(--color-teal)', 'var(--color-purple)'], '170deg');
        break;
      case 'cancel':
        style['--button-background'] = 'transparent';
        style['--button-border'] = '2px solid var(--color-light-main)';
        style['--button-hover-background'] = 'var(--color-light-light)';
        style['--button-hover-border'] = '2px solid var(--color-light-light)';
        color ? (style['--button-color'] = color) : (style['--button-color'] = 'var(--color-text-grey)');
        hoverColor
          ? (style['--button-hover-color'] = hoverColor)
          : (style['--button-hover-color'] = 'var(--color-text-grey)');
        break;
      case 'knockout':
        break;
      case 'primary':
      default:
        style['--button-background'] = linearGradient(['var(--color-red)', 'var(--color-purple)'], '170deg');
        break;
    }
  }

  if (primaryBackground) style['--button-background'] = primaryBackground;
  if (secondaryBackground)
    style['--button-background'] = linearGradient([primaryBackground, secondaryBackground], '170deg');
  if (border) style['--button-border'] = border;
  if (color) style['--button-color'] = color;

  if (hoverPrimaryBackground) style['--button-hover-background'] = hoverPrimaryBackground;
  if (hoverSecondaryBackground)
    style['--button-hover-background'] = linearGradient([hoverPrimaryBackground, hoverSecondaryBackground], '170deg');
  if (hoverBorder) style['--button-hover-border'] = hoverBorder;
  if (hoverColor) style['--button-hover-color'] = hoverColor;

  if (isSimpleIcon) {
    style['--icon-color'] = color;
    style['--icon-background'] = 'transparent';
  }

  if (iconSize) style['--icon-size'] = iconSize + 'px';

  if (isDisabled) {
    style['--button-background'] = 'var(--color-text-light-grey)';
    style['--button-cursor'] = 'default';
  }

  if (isSmall) {
    style['--button-height'] = '24px';
    style['--button-font-size'] = '12px';
    style['--button-padding'] = '14px';
    style['--button-border-radius'] = '8px';
  }

  const onClick = (event) => {
    if (isDisabled || isLoading) return;
    if (propOnClick) propOnClick(event);
  };

  const { current: Component } = React.useRef(to ? (isExternalLink ? 'a' : Link) : 'button');

  return (
    <Component
      ref={buttonRef || ref}
      download={download}
      className={classNames.join(' ')}
      style={style}
      onClick={onClick}
      to={isExternalLink ? undefined : to}
      href={isExternalLink ? to : undefined}
      type={to ? undefined : type}
      target={target ? target : isExternalLink ? '_blank' : '_self'}
    >
      {isLoading && <LoadingSpinner className={styles.loadingSpinner} size={20} />}
      <span className={styles.content}>
        {icon ? (
          <span className={styles.iconContainer}>
            <span className={styles.icon}>{icon}</span>
            <span className={iconContentClassNames.join(' ')}>{children}</span>
          </span>
        ) : (
          children
        )}
      </span>
    </Component>
  );
}

export default forwardRef(Button);
