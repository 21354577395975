import styles from './TableMediaView.module.css';

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MediaAction, RoomDocumentAction, TableAction } from 'store/actions';
import { SessionSelector, TableSelector } from 'store/selectors';
import type { DocumentModel, RoomModel } from 'types/common';
import { MediaMode } from 'types/room';
import useRoomState from 'video/hooks/useRoomState';

import TableViewOverlay from './TableViewOverlay';

interface TableAppViewProps {
  asset: DocumentModel;
  roomDocument?: any;
  className?: string;
  room?: RoomModel;
}

export default function TableAppView({ asset, roomDocument, className, room }: TableAppViewProps) {
  const dispatch = useDispatch();
  const previewRoom = useSelector(TableSelector.getPreviewRoom);
  const currentUser = useSelector(SessionSelector.currentUser);
  const sharedRoomDocument = useSelector(TableSelector.getSharedRoomDocument);
  const privateRoomDocument = useSelector(TableSelector.getPrivateRoomDocument);
  useEffect(() => {
    return () => {
      dispatch(MediaAction.setMode(MediaMode.None));
    };
  }, [dispatch]);

  const isShared = sharedRoomDocument && sharedRoomDocument.id === asset.id;

  const showCloseButton =
    !!previewRoom ||
    asset.userId === currentUser.id ||
    room?.userId === currentUser.id ||
    (!isShared && privateRoomDocument && privateRoomDocument.id === asset.id);

  const onCloseClick = useCallback(() => {
    dispatch(TableAction.setActiveRoomDocument(null));
    if (privateRoomDocument && privateRoomDocument.id === asset.id) dispatch(TableAction.setPrivateRoomDocument(null));
    if (sharedRoomDocument && sharedRoomDocument.id === asset.id) dispatch(TableAction.setSharedRoomDocument(null));
    if (roomDocument?.isShared) {
      const room_document = { is_shared: false };
      dispatch(RoomDocumentAction.update(room?.guid, asset.id, { room_document, user_id: roomDocument?.ownerId }));
    }
  }, [asset.id, dispatch, privateRoomDocument, room?.guid, roomDocument, sharedRoomDocument]);

  const roomState = useRoomState();

  if (!asset || (!previewRoom && !currentUser)) return null;

  return (
    <TableViewOverlay
      className={className}
      onCloseClick={onCloseClick}
      showCloseButton={showCloseButton}
      showMinimizeButton={!previewRoom && roomState !== 'disconnected'}
      room={room}
    >
      <div className={styles.content}>
        <iframe id="tableAppIframe" title={asset.name} width="100%" height="100%" src={asset.externalUrl!}></iframe>
      </div>
    </TableViewOverlay>
  );
}
