//@flow
import styles from './TableCard.module.css';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from 'browserHistory';
import { TableAction } from 'store/actions';
import { PlaykitSelector, RoomUserSelector, SessionSelector } from 'store/selectors';
import type { RoomModel } from 'types/common';
import { getDate } from 'utilities';

import Card from './Card';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';

type Props = {
  room: RoomModel,
  showActions: boolean,
  showClose: boolean,
};

function TableCardAvatar({ userId, guid, avatarUrl }) {
  const colors = useSelector((state) => RoomUserSelector.getColors(state, guid, userId));

  return (
    <Avatar
      className={styles.avatar}
      avatarUrl={avatarUrl ?? null}
      borderColors={colors}
      borderSize={2}
      size={24}
      showPlayerDefault
    />
  );
}

function TableCard(props: Props) {
  const { room, showActions, showClose } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => SessionSelector.currentUser(state));
  const users = useSelector((state) => RoomUserSelector.getAllByRoom(room.guid, 'username', room.userId)(state));
  const playkits = useSelector((state) => PlaykitSelector.getByRoom(state, room.guid));
  const backgroundImage = room.backgroundArtUrl || playkits.find((o) => !!o.backgroundArt)?.backgroundArt;
  const style = {};
  if (backgroundImage) style.backgroundImage = `url("${backgroundImage}")`;

  const onDeleteClick = () => {
    dispatch(TableAction.remove(room.guid));
  };

  const onPlayClick = () => {
    history.push(`/rooms/${props.room.guid}`);
  };

  return (
    <Card
      className={styles.tableCardWrapper}
      forceBorder={!showActions}
      showClose={showClose}
      onDeleteClick={onDeleteClick}
    >
      {currentUser.id === room.userId && <div className={styles.ownerLabel}>Your Room</div>}
      <div className={styles.header} style={style}>
        <div className={styles.tableInfo}>
          <h3 className={styles.title}>{room.title}</h3>
          {room.game && <span className={styles.game}>{room.game}</span>}
        </div>
        {room.lastPlayedAt && (
          <div className={styles.meta}>
            <h5 className={styles.tableDate}>{getDate(new Date(room.lastPlayedAt))}</h5>
            <span className={styles.tablePlayedLabel}>played</span>
          </div>
        )}
        <div className={styles.tableAction}>
          {showActions && (
            <Button
              className={styles.playButton}
              primaryBackground={'var(--color-white)'}
              color={'var(--color-light-dark)'}
              onClick={onPlayClick}
              hoverColor={'var(--color-white)'}
              hoverPrimaryBackground={'var(--color-accent-main)'}
            >
              Play
            </Button>
          )}
        </div>
      </div>
      <div className={styles.party}>
        <h4 className={styles.partyHeading}>Party</h4>
        {users && (
          <ul className={styles.partyList}>
            {users.map((user, index) => {
              return (
                <li key={user.id} className={styles.partyMember}>
                  <TableCardAvatar userId={user.userId} guid={room.guid} avatarUrl={user.avatarUrl} />
                  <div className={styles.label}>
                    {room.userId === user.userId && <div className={styles.hostLabel}>host</div>}
                    {user.username}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Card>
  );
}

TableCard.defaultProps = {
  showActions: false,
  showClose: false,
};

export default TableCard;
